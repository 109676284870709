import React, { useState } from "react";
import { DefaultButton, PrimaryButton } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import EdiModal from "../../../../common/components/ediModal/ediModal";
import { IUser } from "../../../../common/interfaces/IUser";
import { useCurrentWorkflowLab } from "../../../hooks/useCurrentWorkflowLab";
import { IWorkFlowLabMembersModalProps } from "./workflowLabMembersModal.types";
import { workflowLabApi } from "../../../services/workFlowLab/workFlowLab.api";
import { AddMembersPageType, WorkFlowLabRoleId } from "../../../../utilities/constants";
import { AddMembersPage, MembersPageData } from "../../../../common/components/modalPages/addMembersPage";
import { WorkflowLabMembership } from "../../../../models/workflowLabMembership";

export const WorkFlowLabMembersModalBase = (props: IWorkFlowLabMembersModalProps) => {
  const { t } = useTranslation(['workflowLabUsersSettings', 'common']);
  const currentWorkFlowLab = useCurrentWorkflowLab();
  const [data, setData] = useState<MembersPageData>({
    isValid: false,
    selectedPeople: [],
  });
  const [addMembersBtnDisabled, setAddMembersBtnDisabled] = useState<boolean>(false);

  const addMembers = async () => {
    if (!currentWorkFlowLab) return;
    setAddMembersBtnDisabled(true);

    const memberships: WorkflowLabMembership[] = data.selectedPeople.map(
      (member) => ({
        userId: member.id,
        workflowLabRoleId: member.workFlowLabRoleId as WorkFlowLabRoleId ?? WorkFlowLabRoleId.User,
      })
    );

    try {
      await workflowLabApi.addMembers(currentWorkFlowLab.id, memberships);
      props.operationEnded(true);
    } catch (error) {
      props.operationEnded(false);
    }
  };

  const footer = (
    <React.Fragment>
      <DefaultButton
        text={t("common:cancel")}
        style={{ marginRight: "10px" }}
        onClick={() => props.operationEnded(false)}
      />
      <PrimaryButton
        text={t("common:continue")}
        onClick={addMembers}
        disabled={data.selectedPeople.length === 0 || addMembersBtnDisabled}
      />
    </React.Fragment>
  );

  const getUsers = async (keyword: string, currentUsers: IUser[]) => {
    if (currentWorkFlowLab && keyword.length > 2) {
      return await workflowLabApi.getWorkflowLabAvailableMembers({
        workflowLabId: currentWorkFlowLab.id,
        pageNumber: 0,
        pageSize: 20,
        keyword: keyword,
        userIds: currentUsers.map((x) => x.id),
      });
    }
    return [];
  };

  return (
    <EdiModal
      title={`${t("updateMembership")}  ${currentWorkFlowLab?.name}`}
      width={600}
      height={600}
      isOpen={props.isOpen}
      showCloseIcon={true}
      onCloseClick={() => props.operationEnded(false)}
      body={
        <AddMembersPage
          onDataChange={setData}
          initialState={{ selectedPeople: data.selectedPeople, isValid: true }}
          //translationNamespace="membersPageForAddWorkFlowLabMembers"
          getUsers={(keyword: string) => getUsers(keyword, data.selectedPeople)}
          modalType={AddMembersPageType.WorkFlowLab}
          enableAllowFolderToggle={false}
          enableRoleSelection={true}
        />
      }
      footer={footer}
    ></EdiModal>
  );
};