import { IStyleFunction } from "@fluentui/react";
import { IEditLabelUserModalPropsStyles, IEditLabelUserModalStyles} from "./editLabelUserModal.types";

export const EditLabelUserModalGlobalClassNames = {
    root: 'ewe-workflowlab-create-label',
    footerButtons: 'ewe-workflowlab-create-label-footerButtons',
    errorMessage: 'ewe-workflowlab-error-message',
    footerContainer: 'ewe-workflowlab-footer-container'
}

export const getStyles: IStyleFunction<IEditLabelUserModalPropsStyles, IEditLabelUserModalStyles> = (props: IEditLabelUserModalPropsStyles): IEditLabelUserModalStyles => {
    const { palette } = props.theme!; // eslint-disable-line @typescript-eslint/no-non-null-assertion
    return {
        root: [
            EditLabelUserModalGlobalClassNames.root,
            {
                  // CSS PROPS AND SELECTORS
            }
        ]
        ,footerButtons: [
            EditLabelUserModalGlobalClassNames.footerButtons,
            {
                'margin-left': '10px'
            }
        ],
        errorMessage: [
            EditLabelUserModalGlobalClassNames.errorMessage,
            {
                color: palette.redDark,
                alignSelf: 'center',
                marginRight: 'auto'
            }
        ],
        footerContainer: [
            EditLabelUserModalGlobalClassNames.footerContainer,
            {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end'
            }
        ]
    };
}