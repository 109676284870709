/*eslint-disable sonarjs/no-duplicate-string */
import { FontSizes, FontWeights, IDetailsHeaderStyleProps, IDetailsHeaderStyles, IDetailsListStyleProps, IDetailsListStyles, IDetailsRowStyleProps, IDetailsRowStyles, IStyleFunction } from "@fluentui/react";
import { IWorkflowSummaryPropsStyles, IWorkflowSummaryStyles } from "./workflowSummary.types";

export const WorkflowSummaryGlobalClassNames = {
    root: 'edi-workflow-details-root',
    extended: 'edi-workflow-details-maincontent-extended',
    summaryWrapper:'edi-workflow-summary-wrapper'
}

export const getStyles: IStyleFunction<IWorkflowSummaryPropsStyles, IWorkflowSummaryStyles> = (props: IWorkflowSummaryPropsStyles): IWorkflowSummaryStyles => {
    const { palette } = props.theme!; // eslint-disable-line @typescript-eslint/no-non-null-assertion
    return {
        root: [
            WorkflowSummaryGlobalClassNames.root,
            {
                height: '100%',
                padding: '15px 25px'
            }
        ],
        mainContent: {
            position: 'relative',
            height: '100%',
            maxHeight: 'inherit',
        },
        extended: {
            height: 'calc(100% - 270px)',
        },
        title: {
            fontSize: FontSizes.size18,
            fontWeight: FontWeights.bold,
            paddingBottom: 10
        },
        accordionContent: {
            height: '100%',
            padding: '0px 15px 17px 17px',
        },
        documentUrl: {
            textDecoration: 'underline',
            color: palette.themePrimary,
            fontWeight: 'bold',
            cursor: 'pointer'
        },
        documentBold: {
            textDecoration: 'underline',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
            //fontWeight: 'bold',
        },
        titleContainer: {
            height: '60px',
            overflow: 'auto',
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
            justifyContent: 'space-between'
        },
        closeBtn: {
            display: 'flex',
            justifyContent: 'flex-end',
            marginRight: '17px'
        },
        step: {
            paddingBottom: '0px',
            paddingTop: '0px !important',
            height: '100%'
        },
        exportWorkflowLabel: {
            cursor: 'pointer',
            textDecoration: 'underline',
            color: palette.themePrimary,
            fontDize: '12px',
            // display: 'flex',
            // flexDirection: 'row-reverse'
        },
        exportWorkflow: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center'
        },
        spinner: { marginRight: '0.3em' },
        errorMessage: {
            color: palette.redDark,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
        },
        emptyList: {
            position: 'absolute',
            top: '60px',
            width: '150px',
            left: 'calc(50% - 88px)'
        },
        tooltip: {
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            cursor: 'context-menu'
        },
        tooltipContent: {
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            cursor: 'pointer',
        },
        summaryWrapper:[WorkflowSummaryGlobalClassNames.summaryWrapper,
        {
            textOverflow:'ellipsis',
            overflow:'hidden',
            width:'100%',
            display:'inline-block',
        }],
        subComponentStyles: {
            accordion: {
                header: {
                    paddingLeft: '0px',
                },
                title: {
                    fontWeight: FontWeights.bold,
                }
            },
            detailsList: (props: IDetailsListStyleProps): IDetailsListStyles => {
                return {
                    root: {
                    },
                    headerWrapper: {
                    },
                    contentWrapper: {
                    },
                    focusZone: {}
                }
            },
            detailsHeader: (props: IDetailsHeaderStyleProps): IDetailsHeaderStyles => {
                return {
                    root: {
                        borderBottom: '0px'
                    },
                    cellIsCheck: {},
                    check: {},
                    cellWrapperPadded: {},
                    cellIsActionable: {},
                    cellIsEmpty: {},
                    cellSizer: {},
                    cellSizerStart: {},
                    cellSizerEnd: {},
                    cellIsResizing: {},
                    cellIsGroupExpander: {},
                    collapseButton: {},
                    checkTooltip: {},
                    sizingOverlay: {},
                    dropHintCircleStyle: {},
                    dropHintCaretStyle: {},
                    dropHintLineStyle: {},
                    dropHintStyle: {},
                    accessibleLabel: {},
                }
            },
            detailsRow: (props: IDetailsRowStyleProps): IDetailsRowStyles => {
                return {
                    root: {
                        borderBottom: '0px',
                        selectors: {
                            ':hover': {
                                backgroundColor: 'unset'
                            }
                        },
                    },
                    cell: { alignSelf: 'center', textOverflow: 'unset', paddingBottom: '0px', paddingTop: '0px !important', height: '100%' },
                    cellAnimation: {},
                    cellPadded: {},
                    cellMeasurer: {},
                    cellUnpadded: {},
                    check: {},
                    checkCell: {},
                    checkCover: {},
                    fields: {},
                    isMultiline: {},
                    isRowHeader: {},
                }
            },
        }
    };
}