import { styled } from "@fluentui/react";
import { getStyles } from "./removeAssociationModal.styles";
import { IRemoveAssociationModalPropsStyles, IRemoveAssociationModalStyles, IRemoveAssociationModalProps } from "./removeAssociationModal.types";
import { RemoveAssociationModalBase as RemoveAssociationModalComponent } from "./removeAssociationModal.base";

const RemoveAssociationModal = styled<IRemoveAssociationModalProps, IRemoveAssociationModalPropsStyles, IRemoveAssociationModalStyles>(
    RemoveAssociationModalComponent,
    getStyles,
    undefined,
    { scope: 'RemoveAssociationModal' }
);

export default RemoveAssociationModal;