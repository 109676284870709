import React, {  useMemo, useState} from "react";
import { IPeopleSelectorBaseProps } from "./peopleSelector.types";
import { PrimaryButton, NormalPeoplePicker, IPersonaProps, IBasePickerSuggestionsProps, DetailsList, DetailsRow, SelectionMode, IColumn, PersonaSize, Persona, IconButton, IBasePickerStyles, mergeStyleSets, useTheme } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { mapper } from "../../../../utilities/mapper";
import { IUser } from "../../../../common/interfaces/IUser";
import { IExtPersonaProps } from "../../../../models/User";
import { labelApi } from "../../../services/label/label.api";

export type MembersPageData = {
    isValid: boolean;
    selectedPeople: IUser[];
}

export const PeopleSelectorBase = (props: IPeopleSelectorBaseProps) => {    
    const theme = useTheme();
    const { t } = useTranslation(['common']);
    const [pickerBuffer, setPickerbuffer] = useState<IExtPersonaProps[]>([]);
    const [data, setData] = useState<MembersPageData>({isValid: false, selectedPeople: props.defaultPeople || []});

    const availableColumns = () : IColumn[] => {
        return [
            { key: 'persona',  name: 'persona', minWidth: 120, onRender: renderMemberPersona },
            { key: 'delete',  name: 'delete', minWidth: 20, maxWidth: 100, onRender: renderDeletePersona }
        ];
    };

    const renderMemberPersona = (item: IUser) => {
        const mapped = mapUserToPersona(item);
        return <Persona {...mapped} size={PersonaSize.size40} coinSize={32} />
    };

    const deletePerson = (item: IUser) => {
        const newState = { ...data };
        const rimasti = newState.selectedPeople.filter(i => i.id !== item.id);
        newState.selectedPeople = rimasti;
        setData(newState);
        if(props.onChangeSelectedUsers)
            props.onChangeSelectedUsers(newState.selectedPeople);
    }

    const renderDeletePersona = (user: IUser) => {
        return <IconButton iconProps={{ iconName: 'Cancel' }} onClick={() => deletePerson(user)}></IconButton>
    };

    const classNames = mergeStyleSets({
        disclaimerZone: {
            display: 'flex',
            flexDirection: 'column',
            marginBottom: 30
        },
        peoplePickerZone: {
            display: 'flex',
            marginBottom: '20px'
        },
        peoplePickerSuggestions: {
            padding: '8px 0'
        },
        peoplePickerSuggestionItem: {
            '::after': {
                display: 'none',                 
            },
            ".is-suggested": {
                background: theme.palette.neutralQuaternary
            }
        },
        detailsListRow: {
            background: 'transparent !important',
        },
        checkboxAlign: {
            height: '100%', 
            alignItems: 'center', 
            ".ms-Checkbox-label": {
                alignItems: 'center', 
                height: '32px'
            }, 
            ".ms-Checkbox-text": {
                wordBreak: 'break-word',
                whiteSpace: 'pre-wrap', 
                fontSize: '13px', 
                lineHeight: '12px'
            }, 
        }
    })

    const suggestionOptions: IBasePickerSuggestionsProps = {
        showRemoveButtons: false,
        suggestionsClassName: classNames.peoplePickerSuggestions,
        suggestionsItemClassName: classNames.peoplePickerSuggestionItem,
        loadingText: t('common:loading'),
        noResultsFoundText: t('noResults'),
    };
    
    const peoplePickerStyles = useMemo((): IBasePickerStyles => ({
        input: {
            backgroundColor: 'transparent'
        },
        text: {
            border: '1px solid rgb(240, 240, 240) !important',
            borderRadius: 2,
            backgroundColor: 'rgb(240, 240, 240)',
        },
        itemsWrapper: {
            ".ms-PickerPersona-container": {
                background: 'rgb(245, 245, 245)',
                border: "1px solid rgb(245, 245, 245)",
                ':hover': {
                    background: 'rgba(98, 100, 167, 0.2)',
                    border: "1px solid rgba(98, 100, 167, 0.2)",
                }
            },
        },
        root: {},
        screenReaderText: {}
    }), []);
    
    const searchForAvailableMembers = async (filter: string, selectedItems?: IPersonaProps[]) => {
        return labelApi.searchWorkFlowLabUsers(props.workflowLabId, filter, props.hasAuditor ? props.hasAuditor : false)
        .then((result) => {
            const selectedIds = data.selectedPeople.map(i => i.id)
            .concat(pickerBuffer.map(u => u.userData.id));            
            const filteredUsers = result.filter(u => !selectedIds.includes(u.id));
            return filteredUsers.map(u => mapUserToPersona(u));
        })
    }

    const mapUserToPersona = (user: IUser): IExtPersonaProps => {
        return {
            ...mapper.mapUserToPersona(user),
            userData: user
        }
    }

    const addPeopleToPickerBuffer = (people?: IPersonaProps[]) => {
        const converted: IExtPersonaProps[] | undefined = people?.map(p => p as IExtPersonaProps);
        setPickerbuffer(converted ?? []);
    }

    const movePickerBufferToMembers = () => {
        const newState = { ...data };
 
        newState.selectedPeople = newState.selectedPeople.concat(pickerBuffer.map(user => user.userData as IUser));
        newState.isValid = newState.selectedPeople.length > 0;

        if(props.onChangeSelectedUsers)
            props.onChangeSelectedUsers(newState.selectedPeople);
        setData(newState);
        setPickerbuffer([]);
    }

    return (
        <>
            {!props.visualizeMode && 
                <div className={classNames.peoplePickerZone}>
                    <NormalPeoplePicker
                        styles={peoplePickerStyles}
                        onResolveSuggestions={searchForAvailableMembers}
                        resolveDelay={500}
                        pickerSuggestionsProps={suggestionOptions}
                        selectedItems={pickerBuffer}
                        onChange={addPeopleToPickerBuffer}
                    />
                    <PrimaryButton text={t("common:add")} onClick={movePickerBufferToMembers} disabled={pickerBuffer.length === 0} />
                </div>}
            <div>
                <DetailsList
                    key={data.selectedPeople.length}
                    items={data.selectedPeople}
                    columns={availableColumns()}
                    selectionMode={SelectionMode.none}
                    isHeaderVisible={false}
                    onRenderRow={props => props ? <DetailsRow {...props} className={classNames.detailsListRow} /> : null}
                />
            </div>
        </>
    );
}