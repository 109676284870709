import { styled } from "@fluentui/react";
import { UploadUserLabelModalBase } from "./uploadUserLabelModal.base";
import { IUploadUserLabelModalProps, IUploadUserLabelModalPropsStyles, IUploadUserLabelModalStyles } from "./uploadUserLabelModal.types";
import { getStyles } from "./uploadUserLabelModal.styles";

const UploadUserLabelModal = styled<IUploadUserLabelModalProps, IUploadUserLabelModalPropsStyles, IUploadUserLabelModalStyles>(
    UploadUserLabelModalBase,
    getStyles,
    undefined,
    { scope: 'UploadUserLabelModal' }
);

export default UploadUserLabelModal;