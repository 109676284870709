import { IStyleFunction } from "@fluentui/react"
import { ICreateWorkflowPropsStyles, ICreateWorkflowStyles } from "./createWorkflow.types"

export const CreateWorkflowGlobalClassNames = {
    root: 'ewe-createworkflow-root',
    buttonsContainer: 'ewe-createworkflow-buttonsContainer',
    requiredContainer: 'ewe-createworkflow-requiredContainer',
    requiredSign: 'ewe-createworkflow-requiredSign'

}

export const getStyles: IStyleFunction<ICreateWorkflowPropsStyles, ICreateWorkflowStyles> = (props: ICreateWorkflowPropsStyles): ICreateWorkflowStyles => {
     const { palette } = props.theme!; // eslint-disable-line @typescript-eslint/no-non-null-assertion
    return {
        root: [CreateWorkflowGlobalClassNames.root, {
                
        }],
        requiredSign:[CreateWorkflowGlobalClassNames.requiredSign,{
            color:palette.redDark,
        }],
        buttonsContainer:[CreateWorkflowGlobalClassNames.buttonsContainer,{
            display:'flex',
            flexDirection:'row',
            gap: 5,
            justifyContent: 'flex-end'
        }],
        requiredContainer:[CreateWorkflowGlobalClassNames.requiredContainer,{
            display:'flex',
            flexDirection:'row',
            gap: 5,
            justifyContent: 'space-between',
            alignItems:'center',
            color: palette.redDark
        }],
    }
};

