import { IStyleFunction } from "@fluentui/react"
import { IStepStatusPropsStyles, IStepStatusStyles } from "./stepStatus.types"

export const StepStatusGlobalClassNames = {
    root: 'edi-step-status-root'
}

export enum StepCircleStatus {
    IdleAndPrevRefused = 0,
    IdleOrPendingAndPrevNotRefused = 1,
    Approved = 2,
    Refused = 3
}

const stepRelativeStatus = [
    'grey',
    'white',
    'green',
    'red'
];

export const getStyles: IStyleFunction<IStepStatusPropsStyles, IStepStatusStyles> = (props: IStepStatusPropsStyles): IStepStatusStyles => {
    const { palette } = props.theme!; // eslint-disable-line @typescript-eslint/no-non-null-assertion
    return {
        root: [
            StepStatusGlobalClassNames.root,
            {
            }
        ],
        stepStatus: {
            selectors: {
                ':after': props.lastStep ? '' : {
                    top: '17px', position: 'absolute', border: '0.5px solid #999', backgroundColor: palette.green, borderColor: palette.neutralPrimary, width: '0', height: '100em', display: 'block', content: "''", left: '50%', zIndex: 1, marginLeft: '-0.5px'
                }
            },
            cursor: 'pointer',
            position: 'relative',
            border: '2px solid #999',
            borderColor: palette.neutralPrimary,
            borderRadius: '100%',
            width: '15px',
            height: '15px',
            textAlign: 'center',
            marginBottom: '22px',
            backgroundColor: stepRelativeStatus[props.status],
            zIndex: 2,
        },
        tooltip: {
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            cursor: 'context-menu'
        },
        stepName: {
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            cursor: 'pointer',
        },
        rowMargin: {
            marginBottom: '2em'
        },
        approverSection: {
            marginLeft: '0.5em'
        },
        approverInitialLettersSection: {
            marginRight: '5px',
            marginLeft: '5px',
            width: '30px',
            height: '30px'
        },
        approverInitialLetters: {
            backgroundColor: palette.themeTertiary,
            fontWeight: 'bold',
            color: palette.themePrimary,
            width: '30px',
            height: '30px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 8,
        }
    };
}