import React, { useState } from "react";
import { classNamesFunction, DefaultButton, IconButton, PrimaryButton, Spinner, SpinnerSize } from "@fluentui/react";
import { IEditLabelUserModalStyles, IEditLabelUserModalProps, IEditLabelUserModalPropsStyles } from "./editLabelUserModal.types";
import { useTranslation } from "react-i18next";
import { labelApi } from "../../../services/label/label.api";
import EdiModal from "../../../../common/components/ediModal/ediModal";
import PeopleSelector from "../../common/peopleSelector/peopleSelector";
import { IUser } from "../../../../common/interfaces/IUser";
import { useAsyncApi, useOnMount } from "../../../../utilities/hooks";
import { ErrorDetails } from "../../../../modules/apiClient/apiClient";
const getClassNames = classNamesFunction<IEditLabelUserModalPropsStyles, IEditLabelUserModalStyles>();

export const EditLabelUserModalBase = (props: IEditLabelUserModalProps) => {
    const classNames = getClassNames(props.styles, 
        { 
            theme: props.theme, 
            className: props.className });
    const { t } = useTranslation(['workflowLabManageUsers', 'common']);
    const users: IUser[] = [];
    const [editLabelUserModal, setEditLabelUserModal] = useState(false);
    const [selectedUsers, setSelectedUsers] = useState(users);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [loading, setLoading] = useState(false);
    
    useOnMount(() => {
       if(props.defaultPeople)
            setSelectedUsers(props.defaultPeople); 
    });

    const editLabelUsersModalBody = () => {
        return <div>
            <PeopleSelector 
                workflowLabId={props.workFlowLabId} 
                readonly={false} 
                visualizeMode={false} 
                onChangeSelectedUsers={(users) => setSelectedUsers(users)}
                defaultPeople={selectedUsers}
                hasAuditor={true}
                />
        </div>
    } 

    const addUsers = useAsyncApi({
        func: async () => {
            try {
                setLoading(true);
                    await labelApi.updateLabelUsers(props.labelId, selectedUsers.map(i => i.id));
                    if (props.onComplete)
                        props.onComplete();
            } catch (er) {
                const error: ErrorDetails = er as ErrorDetails;
                if (error.code === 400) {
                    setErrorMessage(t('errorMessageUserLabels'));
                }
            } finally{
                setLoading(false);
            }
        }

    });

    const editLabelUsersModalFooter = () => {
        return (
            <div className={classNames.footerContainer}>
                {errorMessage && <span className={classNames.errorMessage}>{errorMessage}</span>}
                {loading && <Spinner style={{marginRight: 5}} size={SpinnerSize.medium}></Spinner>}
                <DefaultButton
                    onClick={() => {setSelectedUsers(props.defaultPeople);setEditLabelUserModal(false)}} 
                    style={{marginRight:'10px'}}
                >
                    {t('common:leave')}
                </DefaultButton>
                <PrimaryButton
                    onClick={addUsers.execute}
                >
                    {t('common:confirm')}
                </PrimaryButton>
            </div>
        )
    } 

    return (
        <>
            <IconButton 
                    iconProps={{ iconName: 'EditContact' }} 
                    title={t('editContact')}
                    onClick={(e) => {
                        e.stopPropagation();
                        setEditLabelUserModal(true);
                    }
                }/>
            <EdiModal
                title={t("workflowLabManageUsers:updateLabelUsers")}
                width={600}
                height={600}
                isOpen={editLabelUserModal}
                showCloseIcon={true}
                onCloseClick={() => {setSelectedUsers(props.defaultPeople); setEditLabelUserModal(false)}}
                body={editLabelUsersModalBody()}
                footer={editLabelUsersModalFooter()}
            />
        </>
    );
}