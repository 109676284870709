import { IStyleFunction } from "@fluentui/react";
import { IAssociateCategoryModalPropsStyles, IAssociateCategoryModalStyles} from "./associateCategoryModal.types";

export const AssociateCategoryModalGlobalClassNames = {
    root: 'ewe-workflowlab-associate-category',
    footerButtons: 'ewe-workflowlab-associate-category-footerButtons',
}

export const getStyles: IStyleFunction<IAssociateCategoryModalPropsStyles, IAssociateCategoryModalStyles> = (props: IAssociateCategoryModalPropsStyles): IAssociateCategoryModalStyles => {
    return {
        root: [
            AssociateCategoryModalGlobalClassNames.root,
            {
                  // CSS PROPS AND SELECTORS
            }
        ]
        ,footerButtons: [
            AssociateCategoryModalGlobalClassNames.footerButtons,
            {
                'margin-left': '10px'
            }
        ]
    };
}