import * as msal from '@azure/msal-browser';
import { RedirectRequest } from '@azure/msal-browser';
import * as Config from './config';

class WebAuthService {
    private request: RedirectRequest;
    private msalClient: msal.PublicClientApplication;
    private tenantId: string;
    private userObjectId?: string;
    private userPrincipalName?: string;
    private userName?: string;
    private userDepartment?: string;
    private userCompanyname?: string;
    private userOnpremisessamaccountname?: string;

    public get currentUserId() {
        return this.userObjectId;
    }

    public get currentUserPrincipalName() {
        return this.userPrincipalName;
    }

    public get currentUserName() {
        return this.userName;
    }

    public get currentDepartment() {
        return this.userDepartment;
    }

    public get currentCompanyName() {
        return this.userCompanyname;
    }

    public get currentOnPremisessamAccountName() {
        return this.userOnpremisessamaccountname;
    }

    constructor() {
        const config = Config.environment[window.location.host];

        const msalConfig: msal.Configuration = {
            auth: {
                clientId: config.clientId,
                authority: Config.authority,
                redirectUri: config.redirectUri
            },
            cache: {
                cacheLocation: "localStorage",
                storeAuthStateInCookie: false,
            }
        };

        this.request = {
            scopes: config.scopes
        }

        this.msalClient = new msal.PublicClientApplication(msalConfig);
        this.tenantId = Config.tenantId;
    }

    async init() {
        const response = await this.msalClient.handleRedirectPromise();

        if (response?.account && this.isValidAccount(response.account))
            return true;

        return this.trySetAccount();
    }

    isValidAccount(account: msal.AccountInfo) {
        return account.tenantId === this.tenantId;
    }

    isLoggedIn() {
        if (!this.userObjectId)
            return false;

        const account = this.msalClient.getActiveAccount();
        return account !== null && this.isValidAccount(account);
    }

    login() {
        try { this.msalClient.loginRedirect(this.request); }
        catch (err) { console.log(err); }
    }

    async getAccessToken() {
        this.request.account = this.msalClient.getAccountByLocalId(this.userObjectId ?? "") ?? undefined;

        try {
            const resp = await this.msalClient.acquireTokenSilent(this.request);
            return resp?.accessToken;
        }
        catch (error) {
            return null;
        }
    }

    trySetAccount() {
        const accounts = this.msalClient.getAllAccounts();
        const validAccount = accounts.find(account => account.tenantId === this.tenantId);

        if (validAccount) {
            this.msalClient.setActiveAccount(validAccount);
            this.userObjectId = validAccount.localAccountId;
            this.userPrincipalName = validAccount.username;
            this.userName = validAccount.name;
            this.userDepartment = validAccount?.idTokenClaims?.["user.department"] as string | undefined;
            this.userCompanyname = validAccount?.idTokenClaims?.["user.companyname"] as string | undefined;
            this.userOnpremisessamaccountname = validAccount?.idTokenClaims?.["user.samaccountname"] as string | undefined;
            return true;
        }

        return false;
    }

    async tryGetAccessToken() {
        let token = await this.getAccessToken();
        if (token)
            return [true, token];

        try {
            const response = await this.msalClient.loginPopup(this.request);
            token = response.accessToken;

            if (token) {
                this.trySetAccount();
                return [true, token];
            }
        }
        catch (error) {
            return [false, error];
        }

        return [false, ""];
    }
}

export default new WebAuthService();