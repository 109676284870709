import { styled } from "@fluentui/react";
import { AssociateLabelModalBase } from "./associateLabelModal.base";
import { getStyles } from "./associateLabelModal.styles";
import { IAssociateLabelModalProps, IAssociateLabelModalPropsStyles, IAssociateLabelModalStyles } from "./associateLabelModal.types";

const AssociateLabelModal = styled<IAssociateLabelModalProps, IAssociateLabelModalPropsStyles, IAssociateLabelModalStyles>(
    AssociateLabelModalBase,
    getStyles,
    undefined,
    { scope: 'AssociateLabelModal' }
);

export default AssociateLabelModal;