import { IStyleFunction } from "@fluentui/react"
import { IAddDisjointmentPropsStyles, IAddDisjointmentStyles } from "./addDisjointment.types"

export const AddDisjointmentGlobalClassNames = {
    root: 'edi-add-steps-root',
    addContainer: 'edi-add-steps-addContainer',
    addLabel: 'edi-add-steps-addLabel',
    labelDisable: 'ewe-add-disable',
    showProduct:'ewe-edi-showProduct',
    spinner: 'ewe-edi-spinner',
    spinnerLabel: 'ewe-edi-spinnerLabel',
}

export const getStyles: IStyleFunction<IAddDisjointmentPropsStyles, IAddDisjointmentStyles> = (props: IAddDisjointmentPropsStyles): IAddDisjointmentStyles => {
    const { palette } = props.theme!; //eslint-disable-line @typescript-eslint/no-non-null-assertion

    return {
        root: [
            AddDisjointmentGlobalClassNames.root,
            {
                display:'flex',
                flexFlow: 'column',
                height: '100%',
                overflowY: 'auto',
                justifyContent: 'space-between'
                // CSS PROPS AND SELECTORS
            }
        ],
        addContainer:[AddDisjointmentGlobalClassNames.addContainer,{
            display:'flex',
            justifyContent: 'space-between'
        }],
        addLabel:[AddDisjointmentGlobalClassNames.addLabel,{
            cursor:'pointer',
            textDecoration:'underline',
            color: palette.themePrimary
        }],
        labelDisable: [AddDisjointmentGlobalClassNames.labelDisable, {
            opacity: '0.3', 
                cursor: 'default',
                pointerEvents: 'none',
                'div:first-child': {
                    opacity: '0.3', 
                }
        }],
        showProduct: [AddDisjointmentGlobalClassNames.showProduct, {
            position: 'relative',
            bottom: '60',
            paddingBottom: '10',
            display:'flex',
            whiteSpace:'nowrap'
        }],
        spinnerLabel: [AddDisjointmentGlobalClassNames.spinnerLabel, {
            fontSize: 24,
            fontWeight: 600,
            textAlign: 'center'
        }],
        spinner: [AddDisjointmentGlobalClassNames.spinner, {
            display: 'flex',
            height: '100%',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            '> :not(:last-child)': {
                marginBottom: 20
            }
        }],
    };
}