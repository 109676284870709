import { IStyleFunction } from "@fluentui/react";
import { IAssociateLabelModalPropsStyles, IAssociateLabelModalStyles } from "./associateLabelModal.types";

export const AssociateLabelModalGlobalClassNames = {
    root: 'ewe-associateLabelModal-root',
    divBodyContainer: 'ewe-associateLabelModal-divBodyContainer',
    loaderContainer: 'ewe-associateLabelModal-loaderContainer',
    selectAllContainer: 'ewe-associateLabelModal-selectAllContainer',
    selectAllButton: 'ewe-associateLabelModal-selectAllButton',
    labelCategoriesContainer: 'ewe-associateLabelModal-labelCategoriesContainer',
    labelBox: 'ewe-associateLabelModal-labelBox',
    detailsList: 'ewe-associateLabelModal-detailsList',
    spanCategories: 'ewe-associateLabelModal-spanCategories',
    footerContainer: 'ewe-associateLabelModal-footerContainer',
    spanErrorContainer: 'ewe-associateLabelModal-spanErrorContainer',
    customCheckbox: 'ewe-associateLabelModal-customCheckbox',
    checkBoxContainer: 'ewe-associateLabelModal-checkBoxContainer'
}

export const getStyles: IStyleFunction<IAssociateLabelModalPropsStyles, IAssociateLabelModalStyles> = (props: IAssociateLabelModalPropsStyles): IAssociateLabelModalStyles => {
    const { palette } = props.theme! //eslint-disable-line @typescript-eslint/no-non-null-assertion
    return {
        root: [
            AssociateLabelModalGlobalClassNames.root,
            {
                // CSS PROPS AND SELECTORS
            }
        ],
        divBodyContainer: [
            AssociateLabelModalGlobalClassNames.divBodyContainer,
            {
                width: '100%',
                height: '100%',
                overflow: 'hidden'
            }
        ],
        loaderContainer: [
            AssociateLabelModalGlobalClassNames.loaderContainer,
            {
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center'
            }
        ],
        selectAllContainer: [
            AssociateLabelModalGlobalClassNames.selectAllContainer,
            {
                display: 'flex',
                justifyContent: 'flex-end',
                margin: '5px 5px'
            }
        ],
        selectAllButton: [
            AssociateLabelModalGlobalClassNames.selectAllButton,
            {
                color: palette.themePrimary,
                padding: 0,
                ':active': {
                    backgroundColor: palette.neutralLight
                },
                ':hover':{
                    color: palette.black
                }
            }
        ],
        labelCategoriesContainer: [
            AssociateLabelModalGlobalClassNames.selectAllButton,
            {
                display: 'flex',
                flexDirection: 'column'
            }
        ],
        labelBox: [
            AssociateLabelModalGlobalClassNames.labelBox,
            {
                width: '160px',
                overflow: 'hidden',
                height: '25px',
                padding: '3px 5px'
            }
        ],
        detailsList: [
            AssociateLabelModalGlobalClassNames.detailsList,
            {
                backgroundColor: palette.white
            }
        ],
        spanCategories: [
            AssociateLabelModalGlobalClassNames.spanCategories,
            {
                margin: '5px 0px',
                textOverflow:'ellipsis',
                overflow:'hidden',
                fontSize: 12
            }
        ],
        footerContainer: [
            AssociateLabelModalGlobalClassNames.footerContainer,
            {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end'
            }
        ],
        spanErrorContainer: [
            AssociateLabelModalGlobalClassNames.spanErrorContainer,
            {
                color: palette.redDark,
                alignSelf: 'center',
                marginRight: 'auto'
            }
        ],
        customCheckbox: [
            AssociateLabelModalGlobalClassNames.customCheckbox,
            {
                selectors: {
                    ' .ms-Checkbox-checkbox': {
                        borderRadius: '50%'
                    }
                }                
            }
        ],
        checkBoxContainer: [
            AssociateLabelModalGlobalClassNames.checkBoxContainer,
            {
                backgroundColor: palette.white,
                display: 'flex',
                alignItems: 'center'   
            }
        ]
    };
}