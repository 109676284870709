import { DigitalSignature } from "../../../models/digitalSignature";
import { apiClient } from "../../../modules/apiClient/apiClient";
import { svcPaths } from "../../../modules/apiClient/config";
import { GenerateOTP } from "./digitalSignature.contracts";

class DigitalSignatureApiClass {
    async getDigitalSignature() {
        const response = await apiClient.get<DigitalSignature>(`${svcPaths.workflow}/digitalsignature/getcertificates`);
        return response.data;
    }

    async geterateOTP(request: GenerateOTP) {
        const response = await apiClient.post<boolean>(`${svcPaths.workflow}/digitalsignature/generateotp`, request);
        return response.data;
    }
    
}

export const digitalSignatureApi = new DigitalSignatureApiClass();