import { styled } from "@fluentui/react";
import { LabelListBase } from "./labelList.base";
import { getStyles } from "./labelList.styles";
import { ILabelListProps, ILabelListPropsStyles, ILabelListStyles } from "./labelList.types";

const LabelList = styled<ILabelListProps, ILabelListPropsStyles, ILabelListStyles>(
    LabelListBase,
    getStyles,
    undefined,
    { scope: 'LabelList' }
);

export default LabelList;