import { IStyleFunction } from "@fluentui/react"
import { IMultiStepModalPropsStyles, IMultiStepModalStyles } from "../../../common/components/multiStepModal/multiStepModal.types"
import { ICreateWorkflowLabModalPropsStyles, ICreateWorkflowLabModalStyles } from "./createWorkflowLabModal.types"

export const getStyles: IStyleFunction<ICreateWorkflowLabModalPropsStyles, ICreateWorkflowLabModalStyles> = (props: ICreateWorkflowLabModalPropsStyles): ICreateWorkflowLabModalStyles => {
    return {
        subComponentStyles: {
            ediModal: (props: IMultiStepModalPropsStyles): IMultiStepModalStyles => {
                return {
                    body: {},
                    bodyContent: {},
                    title: {},
                    closeIcon: {},
                    container: {},
                    content: {},
                    footer: {
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    },
                    header: {},
                    subComponentStyles: {
                        iconButton: {}
                    },
                    subTitle: {}
                }
            }
        }
    }
}