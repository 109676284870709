import { ErrorsType } from "./ErrorsType";

export enum callsList {
    deleteWorkflow = 0,
    saveWorkflow=1,
    synchWorkflowLab=2,
    saveDocument=3
}

export type Call = {
    type: number;
    nameOperation: string;
    errors?: ErrorsType[];
}