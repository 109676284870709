import { DefaultEffects, IStyleFunction } from "@fluentui/react"
import { IWorkflowDashboardContainerPropsStyles, IWorkflowDashboardContainerStyles } from "./workflowDashboardContainer.types";

export const WorkflowDashboardContainerGlobalClassNames = {
    root: 'edi-dashboard-container-root',
    title: 'edi-dashboard-container-title',
    error: 'edi-dashboard-container-error',
    empty: 'edi-dashboard-container-empty',
    spinner: 'edi-dashboard-container-spinner',
    stackItem: 'edi-dashboard-container-stack-item',
    flexCenter: 'edi-dashboard-container-flex-center',
    refreshButtonContainer: 'edi-dashboard-container-refresh-button-container',
    refreshButton: 'edi-dashboard-container-refresh-button',
    doughnutTotal: 'edi-dashboard-container-item-absolute'
}

export const getStyles: IStyleFunction<IWorkflowDashboardContainerPropsStyles, IWorkflowDashboardContainerStyles> = (props: IWorkflowDashboardContainerPropsStyles): IWorkflowDashboardContainerStyles => {
    const { palette } = props.theme!;//eslint-disable-line @typescript-eslint/no-non-null-assertion
    return {
        root: [
            WorkflowDashboardContainerGlobalClassNames.root,
            {
                height: '82vh',
                overflowY: 'auto',
                overflowX: 'hidden'
            }
        ],
        title: [
            WorkflowDashboardContainerGlobalClassNames.title,
            {
                fontWeight: 'bold',
                fontSize: '21px',
                padding: 15,
            }
        ],
        error: [
            WorkflowDashboardContainerGlobalClassNames.error,
            {
                color: palette.redDark,
                fontWeight: 'bold',
                height: '100%'
            }
        ],
        empty: [
            WorkflowDashboardContainerGlobalClassNames.empty,
            {
                fontWeight: 'bold',
                height: '100%'
            }
        ],
        spinner: [
            WorkflowDashboardContainerGlobalClassNames.spinner,
            {
                width: '100%',
                height: '100%',
                flexDirection: 'column'
            }
        ],
        stackItem: [
            WorkflowDashboardContainerGlobalClassNames.stackItem,
            {
                boxShadow: DefaultEffects.elevation8,
                padding: 15,
                background: palette.white
            }
        ],
        flexCenter: [
            WorkflowDashboardContainerGlobalClassNames.flexCenter,
            {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative'
            }
        ],
        refreshButtonContainer: [
            WorkflowDashboardContainerGlobalClassNames.refreshButtonContainer,
            {
                display: "flex",
                position: 'absolute',
                right: 0,
                margin: '10px 30px',
                fontSize: '18px',
                cursor: 'pointer'
            }
        ],
        refreshButton: [
            WorkflowDashboardContainerGlobalClassNames.refreshButton,
            {
                transform: 'rotate(90deg)'
            }
        ],
        doughnutTotal: [
            WorkflowDashboardContainerGlobalClassNames.doughnutTotal,
            {
                position: 'absolute',
                userSelect: 'none',
                top: '43.5%',
                fontSize: '18px',
                fontWeight: 'bold'
            }
        ],
        subComponentStyles: {
            iconButton: {
                root: {
                    color: palette.black,
                },
                iconHovered: {
                    textShadow: `0 0 1px ${palette.black}`,
                    color: palette.themePrimary
                },
                rootExpanded: {
                    backgroundColor: 'transparent',
                    color: palette.themePrimary,
                    textShadow: 'rgb(37 36 35) 0px 0px 1px'
                },
                rootPressed: {
                    backgroundColor: 'transparent',

                },
                rootHovered: {
                    backgroundColor: 'transparent',
                },
                rootDisabled: {
                    backgroundColor: 'transparent'
                }
            }
        }
    };
}