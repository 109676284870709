/*eslint-disable sonarjs/cognitive-complexity */
/*eslint-disable @typescript-eslint/no-explicit-any */
/*eslint-disable sonarjs/no-duplicate-string */

import React, { useEffect, useState } from "react";
import { classNamesFunction, Dropdown, Icon, IDropdownOption, Link, PrimaryButton, Stack, StackItem, TextField, TooltipHost } from "@fluentui/react";
import { IWorkflowApprovalModalPropsStyles, IWorkflowApprovalModalStyles, IWorkflowApprovalModalProps, DropdownType } from "./workflowApprovalModal.types";
import MultiStepModal from "../../../../../common/components/multiStepModal/multiStepModal";
import { useTranslation } from "react-i18next";
import TeamsImage from "../../../../../common/components/teamsImage/teamsImage";
import { ImageName } from "../../../../../common/components/teamsImage/teamsImage.types";
import { useOnMount } from "../../../../../utilities/hooks";
import { digitalSignatureApi } from "../../../../services/digitalSignature/digitalSignature.api";
import { workFlowStepApi } from "../../../../services/workflowstep/workflowstep.api";
import { ErrorsType } from "../../../../../common/types/ErrorsType";
import { DigitalSignatureCertificate } from "../../../../../models/digitalSignature";
import { ApproveWorkFlowStep, ApproveWorkFlowStepType } from "../../../../services/workflowstep/workflowstep.contracts";
import TeamsSpinner from "../../../../../common/components/teamsSpinner/teamsSpinner";

const getClassNames = classNamesFunction<IWorkflowApprovalModalPropsStyles, IWorkflowApprovalModalStyles>();

enum ApprovalModalStep {
    start = 0,
    waiting = 1,
    success = 2,
    fail = 3
}
export const WorkflowApprovalModalBase = (props: IWorkflowApprovalModalProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const { palette } = props.theme!; //eslint-disable-line @typescript-eslint/no-non-null-assertion
    const [currentStep, setCurrentStep] = useState<ApprovalModalStep>(ApprovalModalStep.start);
    const [userMessageError, setUserMessageError] = useState('');
    const [pinMessageError, setPinMessageError] = useState('');
    const [userIdSelected, setUserIdSelected] = useState('');
    const [pinSelected, setPinSelected] = useState('');
    const [otpSelected, setOtpSelected] = useState('');
    const [certificateSelected, setCertificateSelected] = useState<IDropdownOption>();
    const [otpRequestPending, setOtpRequestPending] = useState(false);
    const [isOtpDisabled, setIsOtpDisable] = useState(false);
    const [certificates, setCertificates] = useState<DigitalSignatureCertificate[]>();
    const [isLoading, setIsLoading] = useState(false);

    const { t } = useTranslation(['workflowApprovalModal', 'common']);

    const [certificatesMapped, setCertificateMapped] = useState<IDropdownOption<DropdownType>[]>([]);

    const getIcon = (
        <Icon
            styles={{ root: { marginLeft: '5px', color: palette.black, paddingTop: 5, cursor: 'context-menu' } }}
            iconName="info"
        />
    )

    useOnMount(() => {
        setUserMessageError('');
        setPinMessageError('');
        GetUserIDAndCertificate();
    });

    useEffect(() => {
        const temp: IDropdownOption<DropdownType>[] = [];
        certificates?.map(x => temp.push({ key: temp.length, text: x.certificateType + ' - ' + x.dn }))
        setCertificateMapped(temp)
        setCertificateSelected(temp[0])
    }, [certificates]);


    const GetUserIDAndCertificate = async () => {

        setIsLoading(true);
        try {
            const result = await digitalSignatureApi.getDigitalSignature();
            setCertificates(result.certificates);
            setUserIdSelected(result.userId);
        }
        catch (er: any) {
            const error: ErrorsType = er as ErrorsType;
            switch (error.code) {
                case 403: setUserMessageError(t('notPermissions')); break;
                case 404: setUserMessageError(t('notFound')); break;
                case 422: setUserMessageError(t('Unprocessable')); break;
                default: setUserMessageError(t('common:genericErrorApi')); break;
            }
        } finally {
            setIsLoading(false);
        }
    }

    const onOtpRequestClick = async () => {
        setIsOtpDisable(true)
        setTimeout(() => { setIsOtpDisable(false) }, 10000);
        if (certificateSelected && certificates) {
            const result = await digitalSignatureApi.geterateOTP({ pin: pinSelected, certificate: certificates[Number(certificateSelected.key)] });
            if (result) {
                setPinMessageError("");
                setOtpRequestPending(true);
            }
            else { setPinMessageError(t('wrongRequest')) }
        }
        else {
            setPinMessageError(t('certificateNotFound'))
        }

    }

    const approveStep = async () => {
        try {
            if (certificateSelected && certificates) {
                setCurrentStep(ApprovalModalStep.waiting)
                const request: ApproveWorkFlowStep = {
                    approve: ApproveWorkFlowStepType.Approved,
                    comments: props.description,
                    certificate: certificates[Number(certificateSelected.key)],
                    pin: pinSelected,
                    otp: otpSelected,
                    isUploadFile: props.isUploadFile,
                    fileSource: props.fileSource,
                    teamId: props.teamsData?.file?.teamId,
                    teamFileId: props.teamsData?.file?.uniqueId,
                    ediFileId: props.ediData?.file?.id
                }
                await workFlowStepApi.ApproveWorkFlowStep(request, props.stepId, props.localData);
                setCurrentStep(ApprovalModalStep.success);
            }
        }
        catch (er: any) {
            setCurrentStep(ApprovalModalStep.fail)
        }
    }

    const firstModalBody = (
        <Stack verticalAlign="center" tokens={{ childrenGap: 10 }} className={classNames.root}>
            <>
                {certificatesMapped.length === 0 ?
                    <>
                        <StackItem>
                            <StackItem align="end" style={{ display: 'flex', alignItems: 'center' }} >
                                {`${t('firsStepDescription')}`}
                            </StackItem>
                        </StackItem>
                    </> :
                    <>
                        <StackItem >
                            <StackItem align="end" style={{ display: 'flex', alignItems: 'center' }} >
                                {`User ID`}
                                <span style={{ fontSize: '14px', color: palette.red, marginLeft: 10 }}>
                                    {userMessageError}
                                </span>
                            </StackItem>
                            <StackItem align="end" style={{ marginTop: 5 }} >
                                <TextField
                                    styles={classNames.subComponentStyles.textFieldStyle}
                                    value={userIdSelected}
                                    disabled
                                    onChange={(_, value) => { setUserIdSelected(value || '') }}
                                />
                            </StackItem>
                        </StackItem>

                        <StackItem>
                            <StackItem align="end" style={{ display: 'flex', alignItems: 'center' }} >
                                <span style={{ marginRight: 10 }}>{`${t('certificate')}`}</span>
                            </StackItem>
                            <StackItem align="end" style={{ marginTop: 5, display: 'flex', alignItems: 'center' }} >
                                <Dropdown
                                    options={certificatesMapped}
                                    selectedKey={certificateSelected?.key}
                                    onChange={(_, option) => setCertificateSelected(option)}
                                    defaultSelectedKey={certificatesMapped[0].key}
                                    defaultValue={certificatesMapped[0].text}
                                    styles={{ root: { width: '100%', marginRight: 10 } }}
                                />
                            </StackItem>
                        </StackItem>

                        <StackItem>
                            <StackItem align="end" style={{ display: 'flex', alignItems: 'center' }} >
                                {`Pin`}
                                <span style={{ fontSize: '14px', color: palette.red, marginLeft: 10 }}>
                                    {pinMessageError}
                                </span>
                            </StackItem>
                            <StackItem align="end" style={{ marginTop: 5, display: 'flex', alignItems: 'center' }} >
                                <TextField
                                    placeholder={t('insertPin')}
                                    value={pinSelected}
                                    maxLength={8}
                                    onChange={(_, value) => { setPinSelected(value || '') }}
                                    type="password"
                                    styles={classNames.subComponentStyles.textFieldStyle}
                                    canRevealPassword
                                />
                                <PrimaryButton disabled={isOtpDisabled || pinSelected.trim().length !== 8 || isNaN(Number(pinSelected))} onClick={() => onOtpRequestClick()} style={{ marginLeft: '15px' }} >{t('otpRequest')}</PrimaryButton>
                            </StackItem>
                        </StackItem>

                        {otpRequestPending ?
                            <StackItem>
                                <StackItem align="end" style={{ display: 'flex', alignItems: 'center' }} >
                                    {`${t('insertOtp')}`}
                                    <TooltipHost content={t('signatureTooltip')}>
                                        {getIcon}
                                    </TooltipHost>
                                </StackItem>
                                <StackItem align="end" style={{ marginTop: 5, width: '100%' }} >
                                    <TextField
                                        placeholder={t('insertOtp')}
                                        styles={classNames.subComponentStyles.textFieldStyle}
                                        value={otpSelected}
                                        maxLength={2000}
                                        onChange={(_, value) => { setOtpSelected(value || '') }}
                                        type="password"
                                        canRevealPassword
                                    />
                                </StackItem>
                            </StackItem>
                            : ''}
                    </>}
            </>
        </Stack>
    );

    const firstModalFooter = (
        <div>
            {certificatesMapped.length > 0 ?
                <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div style={{ fontSize: '14px', color: palette.red, marginLeft: 10 }}>
                        {''}
                    </div>
                    <div>
                        <PrimaryButton disabled={otpSelected.trim().length === 0} onClick={() => approveStep()} style={{ margin: '0px 4px' }} >{t('common:done')}</PrimaryButton>
                    </div>
                </div> :
                <div>
                    <PrimaryButton onClick={() => props.closeModal()} style={{ margin: '0px 4px' }} >{t('common:close')}</PrimaryButton>
                </div>
            }
        </div>

    );

    const waitingSignatureBody = (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100%', overflow: 'hidden' }}>
            <TeamsImage imageName={ImageName.Sandglass} style={{ flexBasis: '50%', transform: 'rotate(15deg)', flexShrink: 0, height: '70%' }}
                fullContainer />
            <div style={{ fontSize: '24px', fontWeight: 700, marginBottom: '5px' }}>{t('signatureInProgress')}</div>
            <div style={{ fontSize: '20px', fontWeight: 600, color: palette.neutralPrimary }}>{t('signatureDescription')}</div>
            <div style={{ fontSize: '20px', fontWeight: 600, color: palette.neutralPrimary }}>{t('secondSignatureDescription')}</div>
        </div>
    );

    const successSignatureBody = (
        <div style={{ display: 'flex', flexDirection: 'column', fontSize: '24px', fontWeight: 600, alignItems: 'center', height: '100%' }}>
            <TeamsImage imageName={ImageName.WellDone} style={{ flexBasis: '50%', flexShrink: 0 }}
                fullContainer scale={0.7} />
            {t('signatureSuccess')}
            <PrimaryButton
                text={t('common:done')}
                allowDisabledFocus
                onClick={() => { props.closeModal(); props.onReload() }}
                styles={{ root: { padding: '0px 80px', marginTop: '20px' } }}
            />
        </div>
    );

    const failureSignatureBody = (
        <div style={{ display: 'flex', flexDirection: 'column', fontSize: '24px', fontWeight: 600, alignItems: 'center', height: '100%' }}>
            <TeamsImage imageName={ImageName.Oops2} style={{ flexBasis: '50%', flexShrink: 0 }}
                fullContainer scale={0.7} />
            {t('common:genericErrorApi')}
            <PrimaryButton
                text={t('common:retry')}
                allowDisabledFocus
                onClick={() => { setOtpSelected(""); setCurrentStep(ApprovalModalStep.start) }}
                styles={{ root: { padding: '0px 80px', marginTop: '20px' } }}
            />
        </div>
    );

    const firstStepTitle = (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            {`${t("firstStepTitle")}`}
            <TooltipHost content={
                <>
                    <span style={{ marginRight: 5 }}>{`${t('firsStepInfoDescription')}`}</span>
                    <Link target="_blank" href="https://trustedservices.eni.com/RAweb/" underline>{`${t('digitalSignature')}`} </Link>
                </>
            }>
                {getIcon}
            </TooltipHost>
        </div>
    )
    const steps = [
        {
            title: firstStepTitle,
            body: isLoading ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}><TeamsSpinner /></div> : firstModalBody,
            footer: firstModalFooter
        }, {
            bodyOnly: true,
            body: waitingSignatureBody
        },
        {
            bodyOnly: true,
            body: successSignatureBody
        },
        {
            bodyOnly: true,
            body: failureSignatureBody
        },
    ]

    return (
        <MultiStepModal
            isOpen={true}
            width={600}
            height={600}
            showCloseIcon={currentStep === ApprovalModalStep.start}
            onCloseClick={props.closeModal}
            activeStep={currentStep}
            steps={steps}
            animateInitialStep
        />
    );
}