import { IStyleFunction } from "@fluentui/react"
import { IAddStepsPropsStyles, IAddStepsStyles } from "./addSteps.types"

export const AddStepsGlobalClassNames = {
    root: 'edi-add-steps-root',
    addContainer: 'edi-add-steps-addContainer',
    addLabel: 'edi-add-steps-addContainer',
    labelDisable: 'ewe-add-disable'
}

export const getStyles: IStyleFunction<IAddStepsPropsStyles, IAddStepsStyles> = (props: IAddStepsPropsStyles): IAddStepsStyles => {
    const { palette } = props.theme!; //eslint-disable-line @typescript-eslint/no-non-null-assertion

    return {
        root: [
            AddStepsGlobalClassNames.root,
            {
                display:'flex',
                flexFlow: 'column',
                height: '100%',
                overflowY: 'auto'
                // CSS PROPS AND SELECTORS
            }
        ],
        addContainer:[AddStepsGlobalClassNames.addContainer,{
            textAlign:'right',
            padding: 10,
        }],
        addLabel:[AddStepsGlobalClassNames.addLabel,{
            cursor:'pointer',
            textDecoration:'underline',
            color: palette.themePrimary
        }],
        labelDisable: [AddStepsGlobalClassNames.labelDisable , {
            opacity: '0.3', 
                cursor: 'default',
                pointerEvents: 'none',
                'div:first-child': {
                    opacity: '0.3', 
                }
        }]
    };
}