/*eslint-disable sonarjs/no-duplicate-string */

import { Stack, StackItem, PrimaryButton } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import TeamsSpinner from "../../../common/components/teamsSpinner/teamsSpinner";
import { ErrorsType } from "../../../common/types/ErrorsType";
import { WorkFlow } from "../../../models/WorkFlow";
import currentUser from "../../../modules/authentication/currentUser";
import { useNavigator } from "../../hooks/useNavigator";
import { workflowApi } from "../../services/workflow.api";
import WorkflowDetails from "../common/workflowDetails/workflowDetails";

export const WorkFlowDetailsPageBase = () => {
    const [workFlow, setWorkFlow] = useState<WorkFlow>();
    const [reload, setReload] = useState(false);
    const { t } = useTranslation(['common']);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const navigator = useNavigator();
    const id = navigator.selectedworkflowId;

    const getWorkflow = async () => {
        setLoading(true);
        setErrorMessage("");
        try {
            const element = await workflowApi.getWorkflowById(+id);

            setWorkFlow(element);
            setReload(false);
        }
        catch (er) {
            const error: ErrorsType = er as ErrorsType;
            switch (error.code) {
                case 404: setErrorMessage(t('common:notFound')); break;
                case 403: setErrorMessage(t('notPermissions')); break;
                case 500: setErrorMessage(t('common:genericErrorApi')); break;
                default: setErrorMessage(t('common:genericErrorApi')); break;
            }
        }
        finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        getWorkflow();
    }, [id, reload === true]); //eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (reload) getWorkflow();
    }, [id,reload]); //eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {loading &&
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                    <TeamsSpinner />
                </div>
            }
            {!loading && errorMessage !== '' &&
                            <Stack style={{
                                height: '100%',
                                padding: '15px 25px'
                            }}>
                                <StackItem>
                                    <div style={{
                                        height: '60px',
                                        overflow: 'auto',
                                        display: 'flex',
                                        alignItems: 'center',
                                        flexWrap: 'wrap',
                                        justifyContent: 'flex-end',
                                    }}>
                                        <div style={{
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                            marginRight: '17px'
                                        }}>
                                            <PrimaryButton onClick={() => { navigator.goBack(); }} >{t('common:close')}</PrimaryButton>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                        {errorMessage}
                                    </div>
                                </StackItem>
                            </Stack>
            }
            {   !loading && errorMessage === '' && workFlow && 
                <WorkflowDetails
                    translationName="workflowDetails"
                    onReload={() => setReload(true)} 
                    workFlow={workFlow} 
                    isOwner={workFlow.creator?.id === currentUser.currentUserId}
                />
            }
        </>
    );
}