import { styled } from "@fluentui/react";
import { getStyles } from "./editLabelUserModal.styles";
import { IEditLabelUserModalPropsStyles, IEditLabelUserModalStyles, IEditLabelUserModalProps } from "./editLabelUserModal.types";
import { EditLabelUserModalBase} from "./editLabelUserModal.base";

const EditLabelUserModal = styled<IEditLabelUserModalProps, IEditLabelUserModalPropsStyles, IEditLabelUserModalStyles>(
    EditLabelUserModalBase,
    getStyles,
    undefined,
    { scope: 'EditLabelUserModal' }
);

export default EditLabelUserModal;