import { apiClient } from "../../modules/apiClient/apiClient";
import { svcPaths } from "../../modules/apiClient/config";
import QueryString from "qs";
import { TemplateFilters} from "../../models/templateView";
import { CreateWorkflowRequest } from "./workflow.contracts";
import { WorkFlowTemplate } from "../../models/workflowTemplate";
import { UpdateTemplateRequest } from "./template.contracts";

class TemplateApiClass {
    
    async getTemplates(request: TemplateFilters) {
        const response = await apiClient.get<WorkFlowTemplate[]>(`${svcPaths.workflow}/template`, {
            params: {
                pageNumber: request.pageNumber,
                pageSize: request.pageSize,
                orderBy: request.orderBy,
                isAscending: request.isAscending,
                workFlowLabId: request.workflowLabId,
                searchBy: request.searchBy,
            },
            paramsSerializer: (params) => QueryString.stringify(params, { arrayFormat: 'repeat' }),
        })
        return response.data;
    }

    async getTemplate(templateId: number) {
        const response = await apiClient.get<WorkFlowTemplate>(`${svcPaths.workflow}/template/${templateId}`);
        return response.data;
    }

    async createWorkflowTemplate(request: CreateWorkflowRequest, file: File) {
        const formdata: FormData = new FormData();
        formdata.append('File', file);
        formdata.append('jsonCommand', JSON.stringify(request));

        const response = await apiClient.post(`${svcPaths.workflow}/template/create`, formdata);
        return response.data;
    }

    async isWorkflowTemplateNameAvailable(name: string, workflowLabId: number) {
        const payload = { name: name, workflowLabId: workflowLabId };
        const response = await apiClient.post<boolean>(`${svcPaths.workflow}/template/check-name`, payload);
        return response.data;
    }

    async updateTemplate(request: UpdateTemplateRequest, file: File) {
        const formdata: FormData = new FormData();
        formdata.append('File', file);
        formdata.append('jsonCommand', JSON.stringify(request));

        const response = await apiClient.put(`${svcPaths.workflow}/template/update`, formdata);
        return response.data;
    }

    async deleteTemplate(id: number) {
        const response = await apiClient.delete(`${svcPaths.workflow}/template/${id}`);
        return response.data;
    }

    async getTemplateFilePreviewUrl(id: number) {
        const response = await apiClient.get(`${svcPaths.workflow}/template/${id}/previewTemplate`);
        return response.data;
    }
}

export const templateApi = new TemplateApiClass();