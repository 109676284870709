import { IStyleFunction } from "@fluentui/react"
import { IWorkflowDisjointedGraphPropsStyles, IWorkflowDisjointedGraphStyles } from "./workflowDisjointedGraph.types"

export const WorkflowDisjointedGraphContainerGraphGlobalClassNames = {
    root: 'ewe-workflow-disjointed-graph-root',
    doughnutTotal: 'ewe-workflow-disjointed-doughnut-total'
}

export const getStyles: IStyleFunction<IWorkflowDisjointedGraphPropsStyles, IWorkflowDisjointedGraphStyles> = (props: IWorkflowDisjointedGraphPropsStyles): IWorkflowDisjointedGraphStyles => {
    return {
        root: [
            WorkflowDisjointedGraphContainerGraphGlobalClassNames.root,
            {
                // CSS PROPS AND SELECTORS
            }
        ],
        doughnutTotal: [
            WorkflowDisjointedGraphContainerGraphGlobalClassNames.doughnutTotal,
            {
                position: 'absolute',
                userSelect: 'none',
                fontSize: '18px',
                top: '50%',
            }
        ]
    };
}