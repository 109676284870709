import { styled } from "@fluentui/react";
import { getStyles } from "./teamNavigation.styles";
import { ITeamNavigationPropsStyles, ITeamNavigationStyles, ITeamNavigationProps } from "./teamNavigation.types";
import { TeamNavigationBase } from "./teamNavigation.base";

const TeamNavigation = styled<ITeamNavigationProps, ITeamNavigationPropsStyles, ITeamNavigationStyles>(
    TeamNavigationBase,
    getStyles,
    undefined,
    { scope: 'TeamNavigation' }
);

export default TeamNavigation;