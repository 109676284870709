import { styled } from "@fluentui/react";
import { getStyles } from "./selectLabelUser.styles";
import { ISelectLabelUserPropsStyles, ISelectLabelUserStyles, ISelectLabelUserProps } from "./selectLabelUser.types";
import { SelectLabelUserBase } from "./selectLabelUser.base";

const SelectLabelUser = styled<ISelectLabelUserProps, ISelectLabelUserPropsStyles, ISelectLabelUserStyles>(
    SelectLabelUserBase,
    getStyles,
    undefined,
    { scope: 'SelectLabelUser' }
);

export default SelectLabelUser;