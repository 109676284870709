/*eslint-disable sonarjs/cognitive-complexity */
import React, { useState } from "react";
import { classNamesFunction, PrimaryButton, SearchBox, Spinner, SpinnerSize, Stack, StackItem } from "@fluentui/react";
import { IWorkflowLabCategoryViewStyles, IWorkflowLabCategoryViewProps, IWorkflowLabCategoryViewPropsStyles } from "./workflowLabCategoryView.types";
import { useOnMount } from "../../../../utilities/hooks";
import { useTranslation } from "react-i18next";
import { useCurrentWorkflowLab } from "../../../hooks/useCurrentWorkflowLab";
import Accordion from "../../common/accordion/accordion";
import TeamsImage from "../../../../common/components/teamsImage/teamsImage";
import { ImageName } from "../../../../common/components/teamsImage/teamsImage.types";
import { CategoryViewItem } from "../../../../models/categoryView";
import LabelList from "../labelList/labelList";
import { categoryApi } from "../../../services/category/category.api"
import { GetCategoryRequest } from "../../../services/category/category.contracts";
import CreateCategoryModal from "../createCategoryModal/createCategoryModal";
import EditCategoryModal from "../workflowLabCategoryLabelView/editCategoryModal/editCategoryModal";
import AssociateCategoryModal from "../associateCategoryModal/associateCategoryModal";
import DeleteCategoryModal from "./deleteCategoryModal/deleteCategoryModal";

const getClassNames = classNamesFunction<IWorkflowLabCategoryViewPropsStyles, IWorkflowLabCategoryViewStyles>();
export const WorkflowLabCategory = (props: IWorkflowLabCategoryViewProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const { t } = useTranslation(['workflowLabManageUsers', 'common']);
    const selectedWorkflowLab = useCurrentWorkflowLab();
    let isMaster=true;
    if(selectedWorkflowLab?.workflowLabMaster){
        isMaster=false;
    }
    const items: CategoryViewItem[] = [];

    const [currentItems, setCurrentItems] = useState(items);
    const [createCategoryModalIsOpen, setCreateCategoryModalIsOpen] = useState(false)
    const [loadingCategories, setLoadingCategories] = useState(false);
    const [canLoadMore, setCanLoadMore] = useState(false);
    const pageSize = 20;
    const [pageNumber, setPageNumber] = useState(0);
    const [searchBy, setSearchBy] = useState('');

    useOnMount(() => {
        getCategories(searchBy);
    });

    const getCategories = async (keyword: string, reset = false) => {
        setLoadingCategories(true);
        const pNumber = reset ? 0 : pageNumber;
        if (!selectedWorkflowLab) return;

        const request: GetCategoryRequest = {
            workflowLabId: selectedWorkflowLab.id,
            pageNumber: pNumber,
            pageSize: pageSize,
            keyword: keyword,
            categoryIdsToExclude:[]
        }
        await categoryApi.getCategories(request)
            .then((elements) => {
                if (reset)
                    setCurrentItems(elements);
                else
                    setCurrentItems(currentItems.concat(elements));
                if (elements.length >= pageSize) {
                    setCanLoadMore(true);
                    setPageNumber(pNumber + 1);
                }
                else {
                    setCanLoadMore(false);
                }
            })
            .finally(() => {                
                setLoadingCategories(false);
            });
    }

    const printCustomHeader = (category: CategoryViewItem) => {
        if(!selectedWorkflowLab?.workflowLabMaster)
        return (
            <>
                <Stack horizontal horizontalAlign="end" style={{ position: "absolute", right: '0px' }}>
                    <StackItem>
                        <EditCategoryModal
                            Id={category.id}
                            name={category.name}
                            workFlowLabId={selectedWorkflowLab ? selectedWorkflowLab.id : 0}
                            onComplete={() => getCategories(searchBy, true)}
                        />
                    </StackItem>
                    <StackItem>
                        <AssociateCategoryModal
                            categoryId={category.id}
                            name={category.name}
                            defaultLabels={category.labels}
                            workFlowLabId={selectedWorkflowLab ? selectedWorkflowLab.id : 0}
                            onComplete={() => getCategories(searchBy, true)}
                        />
                    </StackItem>
                    <StackItem>
                        <DeleteCategoryModal
                            Id={category.id}
                            onComplete={() => getCategories(searchBy, true)}
                        />
                    </StackItem>
                </Stack>
            </>
        )
        
    }

    // eslint-disable-next-line
    const onScroll = (event: any) => {
        // visible height + pixel scrolled >= total height 
        if ((canLoadMore && !loadingCategories) && event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight - 10) {
            getCategories(searchBy);
        }
    }

    return (
        <React.Fragment>
            <Stack className={classNames.root}>
                <Stack horizontal>
                    <Stack.Item style={{ marginRight: '30px' }}>
                        <SearchBox
                            defaultValue={searchBy}
                            className={classNames.searchBar}
                            placeholder={t('searchCategory')}
                            onChange={(_, newValue) => {
                                setSearchBy(newValue || '');
                            }}
                            onSearch={() => {
                                getCategories(searchBy, true);
                            }}
                            onClear={() => {
                                getCategories('', true);
                            }}
                        />
                    </Stack.Item>
                    
                    <Stack.Item>
                    { !selectedWorkflowLab?.workflowLabMaster
                        &&
                        <PrimaryButton iconProps={{ iconName: 'Add', style: { height: 'auto' } }} onClick={() => setCreateCategoryModalIsOpen(true)}>{t('newCategory')}</PrimaryButton>}
                    </Stack.Item>
                </Stack>
                <Stack className={classNames.accordionStack}>
                    {
                        <div className={classNames.detailListContainer} id={"categoriesListZone"}>
                            <>
                                {!loadingCategories && currentItems.length === 0 &&
                                    <div className={classNames.containerEmptyList}>
                                        <TeamsImage imageName={ImageName.EmptySearch} caption={t('common:emptySearch')} />
                                    </div>
                                }
                                <div onScroll={onScroll} id="accordion-scrollable" style={{ height: 'calc(100% - 80px)', overflow: 'overlay', paddingRight: '15px' }}>
                                    {
                                        (loadingCategories && currentItems.length < pageSize) ?
                                            <Spinner style={{ marginTop: '200px' }} size={SpinnerSize.large} /> : currentItems.map((category, i) => {
                                                const categoryName = `${category.name}`;
                                                return (
                                                    <Accordion
                                                        customHeader={printCustomHeader(category)}
                                                        styles={{root:{'.edi-accordion-title':{width: '80%', overflow: 'hidden', textOverflow: 'ellipsis'}}}}
                                                        title={categoryName}
                                                        key={i}
                                                        opened={i === 0 ? true : false}
                                                    >
                                                        <LabelList
                                                            category={category}
                                                            labels={category.labels ?? []}
                                                            onComplete={() => getCategories(searchBy, true)}
                                                            master={isMaster}
                                                        />
                                                    </Accordion>
                                                );
                                            }
                                            )
                                    }
                                </div>

                            </>
                        </div>

                    }
                </Stack>
            </Stack>
            <CreateCategoryModal
                workFlowLabId={selectedWorkflowLab ? selectedWorkflowLab.id : 0}
                isOpen={createCategoryModalIsOpen}
                onClose={() => setCreateCategoryModalIsOpen(false)}
                onComplete={() => getCategories(searchBy, true)}
                theme={props.theme}
            />
        </React.Fragment>
    );
}