import { styled } from "@fluentui/react";
import { getStyles } from "./singleDisjointment.styles";
import { ISingleDisjointmentPropsStyles, ISingleDisjointmentStyles, ISingleDisjointmentProps } from "./singleDisjointment.types";
import { SingleDisjointmentBase } from "./singleDisjointment.base";

const singleDisjointment = styled<ISingleDisjointmentProps, ISingleDisjointmentPropsStyles, ISingleDisjointmentStyles>(
    SingleDisjointmentBase,
    getStyles,
    undefined,
    { scope: 'SingleStep' }
);

export default singleDisjointment;