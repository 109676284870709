import { styled } from "@fluentui/react";
import { IWorkflowLabManageTemplatesProps, IWorkflowLabManageTemplatesPropsStyles, IWorkflowLabManageTemplatesStyles } from "./workflowLabManageTemplates.types";
import { getStyles } from "./workflowLabManageTemplates.styles";
import { WorkflowLabManageTemplates } from "./workflowLabManageTemplates.base";

const WorkflowLabManageTemplatesPage = styled<IWorkflowLabManageTemplatesProps, IWorkflowLabManageTemplatesPropsStyles, IWorkflowLabManageTemplatesStyles>(
    WorkflowLabManageTemplates,
    getStyles,
    undefined,
    { scope: 'WorkflowLabManageUsers' }
);

export default WorkflowLabManageTemplatesPage;