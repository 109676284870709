import React from "react";
import { Panel, PanelType } from "@fluentui/react";
import { IPanelProps } from "./panel.types";


export const PanelBase = (props: IPanelProps) => {

    return (
        <Panel
            headerText={props.headerTitle || ""}
            isOpen={props.isOpen}
            onDismiss={props.onDismiss}
            closeButtonAriaLabel="Close"
            onDismissed={props.onDismissed}
            styles={props.styles}
            isLightDismiss={props.isLightDismiss}
            type={props.smallPanel ? PanelType.smallFixedFar : props.largePanel && !props.customWidth ? PanelType.large : PanelType.custom}
            customWidth={props.customWidth}
        >
            <div>
                {props.content}
            </div>
        </Panel>
    );
}