import { IStyleFunction } from "@fluentui/merge-styles";
import { IPickerWrapperCustomPropsStyles, IPickerWrapperCustomStyles } from "./pickerWrapper.types";

export const pickerWrapperGlobalClassNames = {
    root: 'picker-wrapper-root',
}

export const getStyles: IStyleFunction<IPickerWrapperCustomPropsStyles, IPickerWrapperCustomStyles> = (props: IPickerWrapperCustomPropsStyles): IPickerWrapperCustomStyles => {

    return {
        root: [pickerWrapperGlobalClassNames.root, {
            display: 'flex',
            background: 'rgba(37, 36, 35, 0.05)',
            width: 'fit-content',
            alignItems: "center",
            borderRadius: '15px',
            padding: '3px',
            paddingLeft: 0,
            height: '24px',
            margin: '1px'
        }]
    }
}