/*eslint-disable sonarjs/cognitive-complexity */
import React, { useEffect, useState } from "react";
import { classNamesFunction, Dropdown, IconButton, IDropdownOption, ITag, Label, Shimmer} from "@fluentui/react";
import { ISingleDisjointmentPropsStyles, ISingleDisjointmentStyles, ISingleDisjointmentProps } from "./singleDisjointment.types";
import { useTranslation } from "react-i18next";
import { useWorkflowState } from "../../../../../workflowStore";
import { IExtTagItemProps } from "../../../../common/labelsSelector/labelsSelector.types";
import { GetLabelAssociatedWithCategory } from "../../../../../services/category/category.contracts";
import { categoryApi } from "../../../../../services/category/category.api";
import { CategoryLabelItem } from "../../../../../../models/categoryView";
import { mapper } from "../../../../../../utilities/mapper";
import AdvancedDropdown from "../../../../../../common/components/advancedDropdown/advancedDropdown";

const getClassNames = classNamesFunction<ISingleDisjointmentPropsStyles, ISingleDisjointmentStyles>();

export const SingleDisjointmentBase = (props: ISingleDisjointmentProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const { t } = useTranslation(['createWorkflow', 'common']);
    const [categoryId, setCategoryId] = useState(props.disjointment.category.id);
    const [loading, setLoading] = useState<boolean>(false);
    const [categoryLabelItem, setcategoryLabelItem] = useState<IDropdownOption[]>([]);
    const [labels, setLabels] = useState<IExtTagItemProps[]>([]);
    const [initialSelectedLabels, setInitialSelectedLabels] = useState<number[]>();
    const currentWorkflowLab = useWorkflowState();

    const searchForAvailableLabels = async (id: number) => {
        
        setLoading(true);
        const request: GetLabelAssociatedWithCategory = {
            workflowLabId: currentWorkflowLab.workflowList.selectedWorkflowLab,
            categoryId: id
        }

        try 
        {
            const result = await categoryApi.GetLabelsAssociatedWithCategory(request);
            const dropdownLabels = result.map(l => mapLabelToDropOpt(l));
            const labelTag = result.map(l => mapLabelToTag(l));
            setLabels(labelTag)
            setcategoryLabelItem(dropdownLabels);

            if (props.disjointment.labels.length === 0){
                setInitialSelectedLabels(dropdownLabels.map(l => l.key as number));   
            }      
            
            else 
                setInitialSelectedLabels(props.disjointment.category?.labels?.map(l => l.id));            
                
        } finally{
            setLoading(false);
        }
    }

    useEffect(() => {
        if(categoryId !== 0)
            searchForAvailableLabels(categoryId)
     }, [categoryId]); //eslint-disable-line react-hooks/exhaustive-deps

    const mapLabelToDropOpt = (label: CategoryLabelItem): IDropdownOption => {
        return {
            ...mapper.mapLabelToDropdownOption(label)
        }
    }

    const mapLabelToTag = (label: CategoryLabelItem): IExtTagItemProps => {
        return {
            ...mapper.mapLabelToTag(label),
            labelData: label
        }
    }

    const movePickerBufferToLabels = (labels: ITag[]) => {
        const converted = labels.map(p => p as IExtTagItemProps);        
        props.onUpdateLabels(converted.map(label => label.labelData as CategoryLabelItem));        
    }

    const movePickerBufferToDisjointmentCategory = (id: number) => {
        const target = props.categories.find(x => x.id === id)
        if (target)
            props.onUpdateCategory(target);
    }

    const getLabelsByIDs = (keys: number[]) =>{
        const selectedLabels = labels.filter(l => keys.includes(l.key as number));
        movePickerBufferToLabels(selectedLabels)
    }

    return (
        <div className={classNames.root}>
            <Label>{t('selectDisjointmentCategory.dropdownCategory')}</Label>
            <p className= {classNames.subTitle}>{t('selectDisjointmentCategory.dropdownSubCategory')}</p>
            
            {props.loading 
                ? <Shimmer className={classNames.shimmer}/> 
                : <Dropdown
                    className={classNames.singleDisjoinemntItem}
                    selectedKey={categoryId}
                    options={props.categoryOptions}
                    styles={{
                        errorMessage: {
                            position: 'absolute'
                        }
                    }}
                    onChange={async (_, option) => {
                        if (!option)
                            return;
                        setCategoryId(option.key as number);
                        movePickerBufferToLabels([]);
                        movePickerBufferToDisjointmentCategory(option.key as number);
                    }}
                    placeholder={t('addSteps.dropdownPlaceholder')}
                  />
            }

            <p className= {classNames.subTitle}>{t('selectDisjointmentCategory.dropdownSubLabel')}</p>

            {props.loading 
                ? <Shimmer className={classNames.shimmer}/> 
                : !props.visualizeMode &&
                    <div className={classNames.labelsPickerZone}>
                        <AdvancedDropdown
                            label=''
                            options={categoryLabelItem}
                            multiselect={true}
                            placeholder={t('common:multiselectPlaceholder')}
                            disabled={categoryId === 0 || loading ? true : false}
                            selectedItems={initialSelectedLabels}
                            onChangeItems={(keys) => getLabelsByIDs(keys)}
                        />
                    </div>
            }

            <div className={classNames.iconBarContainer}>
                <IconButton
                    disabled={props.numberDisjointments === 1}
                    iconProps={{ iconName: 'Delete' }}
                    onClick={() => props.deleteItem(props.disjointment)}
                />
            </div>

        </div>
    );
}