import { generatePath } from "react-router-dom";
import { AppRoutes, AppTab } from "../../app/utilities/routes";

export enum WorkflowTab {
    workflow = 'workflows',
    dashboard = 'dashboard'
}

export enum WorkflowLabSettingsTab {
    main = 'main',
    users = 'manageusers'
}
export enum WorkflowLabTemplateTab {
    main = 'main',
    template = 'managetemplate'
}

class WorkflowLabRoutesClass {
    public workflowLabs = AppRoutes.generateRouteForTab(AppTab.workflow);
    public workflowLab = `${this.workflowLabs}/:workflowLabId(\\d+)`;
    public workflowDetails = `${this.workflowLab}/${WorkflowTab.workflow}/:workflowId(\\d+)`;
    public workflowLabTab = `${this.workflowLab}/:tab(${WorkflowTab.dashboard}|${WorkflowTab.workflow})`;
    
    public workflowLabTabOptional = `${this.workflowLab}/:tab(${WorkflowTab.dashboard}|${WorkflowTab.workflow})?`; 

    generateWorkflowLabPath(workflowLabId: number) {
        return generatePath(`${this.workflowLabs}/:workflowLabId`, { workflowLabId: workflowLabId });
    }

    generateWorkflowLabTabPath(workflowLabId: number, tab: WorkflowTab) {
        return `${this.generateWorkflowLabPath(workflowLabId)}/${tab}`;
    }
    
    generateWorkflowDetailsPath(workflowLabId: number, workflowId: number) {
        return `${this.generateWorkflowLabPath(workflowLabId)}/${WorkflowTab.workflow}/${workflowId}`;
    }
    
    generateArchiveSettingsTabPath(workflowLabId: number, tab: WorkflowLabSettingsTab) {
        return `${this.generateWorkflowLabPath(workflowLabId)}/settings/${tab}`;
    }

    generateWorkflowLabTemplateTabPath(workflowLabId: number, tab: WorkflowLabTemplateTab) {
        return `${this.generateWorkflowLabPath(workflowLabId)}/settings/${tab}`;
    }
}

export const WorkflowLabRoutes = new WorkflowLabRoutesClass();