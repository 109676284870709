import { styled } from "@fluentui/react";
import { getStyles } from "./editLabelModal.styles";
import { IEditLabelModalPropsStyles, IEditLabelModalStyles, IEditLabelModalProps } from "./editLabelModal.types";
import { EditLabelModalBase} from "./editLabelModal.base";

const EditLabelModal = styled<IEditLabelModalProps, IEditLabelModalPropsStyles, IEditLabelModalStyles>(
    EditLabelModalBase,
    getStyles,
    undefined,
    { scope: 'EditLabelModal' }
);

export default EditLabelModal;