import { styled } from "@fluentui/react";
import { getStyles } from "./createLabelModal.styles";
import { ICreateLabelModalPropsStyles, ICreateLabelModalStyles, ICreateLabelModalProps } from "./createLabelModal.types";
import { CreateLabelModalBase } from "./createLabelModal.base";

const CreateLabelModal = styled<ICreateLabelModalProps, ICreateLabelModalPropsStyles, ICreateLabelModalStyles>(
    CreateLabelModalBase,
    getStyles,
    undefined,
    { scope: 'CreateLabelModal' }
);

export default CreateLabelModal;