export const Constants = {
    MAX_WORKFLOW_NAME: 150,
    MAX_WORKFLOW_DESCRIPTION: 255,
    MAX_LENGTH_WORKFLOWLAB_NAME: 150,
    MAX_LENGTH_CATEGORY_NAME: 180,
    MAX_LENGTH_LABEL_NAME: 180, 
    MAX_LENGHT_LABEL_DESCRIPTION: 255,
    MAX_LENGHT_25: 25,
    PAGE_SIZE: 50,
    MAX_INTEGER: 2147483647,
    MAX_WORKFLOW_STEP_NAME: 150,
    MAX_APPROVAL_COMMENTS: 250,

}

export enum AddMembersPageType {
    WorkflowLab = 0
}

export enum AddLabelsPageType {
    Category = 0
}

