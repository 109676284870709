import React, { useState } from "react";
import { classNamesFunction, Stack } from "@fluentui/react";
import { ILabelListProps, ILabelListPropsStyles, ILabelListStyles } from "./labelList.types";
import IconTag from "../../../../common/components/iconTag/iconTag";
import RemoveAssociationModal from "../workflowLabCategoryView/removeAssociationModal/removeAssociationModal";
import { useTranslation } from "react-i18next";

const getClassNames = classNamesFunction<ILabelListPropsStyles, ILabelListStyles>();

export const LabelListBase = (props: ILabelListProps) => {

    const [isModalOpen, setIsOpenModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState(0);
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const { t } = useTranslation(['labelList']);

    const onDisassociate = (id: number) => {
        setSelectedItem(id);
        setIsOpenModal(true);
    }


    const profileEntriesRender = () => {
        return (
            <>
                {props.labels.map((label, ind) => {
                    return (
                        <div key={ind} style={{display:'flex',alignItems:'center'}}>
                            <IconTag
                                key={ind+1}
                                label={label.name} 
                                tooltip 
                                labelClassName={classNames.labelBox}
                                className={classNames.iconTagLabel}
                                cancel={props.master}
                                onCancel={() => onDisassociate(label.id)}
                            />
                        </div>
                )})}
            </>
        )
    }

    return (     
        <Stack className={classNames.root} tokens={{ childrenGap: 20, padding: 15 }}>
            <Stack horizontalAlign={'center'} className={classNames.tagPanel}>
                <Stack.Item style={{ marginTop: 10, height: '100%', display: 'flex' }} >
                </Stack.Item>
                {props.labels.length !== 0 
                    ?
                        <Stack className={classNames.containerTag}>
                            {profileEntriesRender()}
                        </Stack> 
                    : 
                        <Stack styles={{root:{fontWeight: 600}}}>{t('labelList:emptyLabelList')}</Stack>
                }     
            </Stack>     
            {isModalOpen && 
                <RemoveAssociationModal 
                    IdLabel={selectedItem}
                    category={props.category}
                    isOpen={true}
                    onComplete={props.onComplete}
                    onClose={() => {setSelectedItem(0);setIsOpenModal(false)}}
                />
            }             
        </Stack>  
    );
}