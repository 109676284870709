import { styled } from "@fluentui/react";
import { WorkflowDisjointedGraphContainerBase } from "./workflowDisjointedGraphContainer.base";
import { getStyles } from "./workflowDisjointedGraphContainer.styles";
import { IWorkflowDisjointedGraphContainerProps, IWorkflowDisjointedGraphContainerPropsStyles, IWorkflowDisjointedGraphContainerStyles } from "./workflowDisjointedGraphContainer.types";

const WorkflowDisjointedGraphContainer = styled<IWorkflowDisjointedGraphContainerProps, IWorkflowDisjointedGraphContainerPropsStyles, IWorkflowDisjointedGraphContainerStyles>(
    WorkflowDisjointedGraphContainerBase,
    getStyles,
    undefined,
    { scope: 'WorkflowDisjointedGraphContainer' }
);

export default WorkflowDisjointedGraphContainer;