import { styled } from "@fluentui/react";
import { RequestFiltersBase } from "./filtersDropdown.base";
import { getStyles } from "./filtersDropdown.styles";
import { IRequestFiltersPropsStyles, IRequestFiltersStatusStyles, IRequestFiltersProps } from "./filtersDropdown.types";

const RequestFilters = styled<IRequestFiltersProps, IRequestFiltersPropsStyles, IRequestFiltersStatusStyles>(
    
    RequestFiltersBase,
    getStyles,
    undefined,
    { scope: 'RequestFilters' }
);

export default RequestFilters;