import { styled } from "@fluentui/react";
import { getStyles } from "./workflowLabCategoryView.styles";
import { IWorkflowLabCategoryViewPropsStyles, IWorkflowLabCategoryViewStyles, IWorkflowLabCategoryViewProps } from "./workflowLabCategoryView.types";
import { WorkflowLabCategory } from "./workflowLabCategoryView.base";

const WorkflowLabCategoryView = styled<IWorkflowLabCategoryViewProps, IWorkflowLabCategoryViewPropsStyles, IWorkflowLabCategoryViewStyles>(
    WorkflowLabCategory,
    getStyles,
    undefined,
    { scope: 'WorkflowLabCategory' }
);

export default WorkflowLabCategoryView;