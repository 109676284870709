/*eslint-disable @typescript-eslint/no-explicit-any */

import { mergeStyleSets } from "@fluentui/react"
import React, { useEffect } from "react"
import TeamsSpinner from "../teamsSpinner/teamsSpinner";
import { useAsyncApi, useOnMount } from "../../../utilities/hooks";
import { WorkFlowTemplate } from "../../../models/workflowTemplate";

export interface ILoadingTemplatePageProps {
    action: (request: any) => Promise<any>;
    onSuccess?: (response: any) => void;
    onError?: (error: any) => void;
    request: any;
    label: string;
}

const classNames = mergeStyleSets({
    container: {
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        '> :not(:last-child)': {
            marginBottom: 20
        }
    },
    label: {
        fontSize: 24,
        fontWeight: 600,
        textAlign: 'center'
    },
    errorButtons: {
        display: 'flex',
        justifyContent: 'space-around',
        alignSelf: 'stretch'
    }
})

export const LoadingTemplatePage = (props: ILoadingTemplatePageProps) => {
    const { loading, execute, value, error } = useAsyncApi<any, WorkFlowTemplate>({
        func: async (): Promise<WorkFlowTemplate> => { 
            return await props.action(props.request);   
        }
    });

    useOnMount(() => {
        execute(props.request);
    });

    useEffect(() => {
        if(props.onSuccess && value){
            value.labelsCategory = value.labelsCategory.filter(l => l.labelCategory.category !== null)
            props.onSuccess(value)
        }

        if(props.onError && error){
            props.onError(error)
        }

    }, [value, error, props, loading])

    return (
        <div className={classNames.container}>
            {loading && !value &&
                <>
                    <TeamsSpinner />
                    <span className={classNames.label}>{props.label}</span>
                </>
            }
        </div>
    )
}