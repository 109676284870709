import { styled } from "@fluentui/react";
import { WorkflowCustomFilterModalBase } from "./WorkflowCustomFilterModal.base";
import { getStyles } from "./WorkflowCustomFilterModal.styles";
import { IWorkflowCustomFilterModalProps, IWorkflowCustomFilterModalPropsStyles, IWorkflowCustomFilterModalStyles } from "./WorkflowCustomFilterModal.types";

const WorkflowCustomFilterModal = styled<IWorkflowCustomFilterModalProps, IWorkflowCustomFilterModalPropsStyles, IWorkflowCustomFilterModalStyles>(
    WorkflowCustomFilterModalBase,
    getStyles,
    undefined,
    { scope: 'WorkflowCustomFilterModal' }
);

export default WorkflowCustomFilterModal;