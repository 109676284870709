/* eslint-disable sonarjs/cognitive-complexity */
import React, { useCallback, useState } from "react";
import { classNamesFunction, ConstrainMode, IColumn, Icon, SelectionMode, ShimmeredDetailsList, Stack, StackItem } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import TeamsImage from "../../../../../../common/components/teamsImage/teamsImage";
import { ImageName } from "../../../../../../common/components/teamsImage/teamsImage.types";
import { FileShort, ListFile } from "../../../../../../models/fileShort";
import teamsAuthService from "../../../../../../modules/authentication/teamsAuthService";
import webAuthService from "../../../../../../modules/authentication/webAuthService";
import { useAsyncApi, useOnMount } from "../../../../../../utilities/hooks";
import { workflowApi } from "../../../../../services/workflow.api";
import { ITeamNavigationProps, ITeamNavigationPropsStyles, ITeamNavigationStyles } from "./teamNavigation.types";

const getClassNames = classNamesFunction<ITeamNavigationPropsStyles, ITeamNavigationStyles>();

export const TeamNavigationBase = (props: ITeamNavigationProps) => {
    const { t } = useTranslation(['teamNavigation', 'common']);
    const [teamsList, setTeamsList] = useState<ListFile>({ files: [], skipToken: undefined });

    const { loading, error, execute: getTeams, value: items } = useAsyncApi<void, ListFile>({
        func: async (): Promise<ListFile> => { 
            const response = await workflowApi.getPagedListFromSPO('', '', 20, ""); 
            setTeamsList(response);
             
            return response;
        }
    });

    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className, isEmptyList: !items || items?.files.length === 0 });

    useOnMount(() => {
        getTeams();
    });

    const emptyTeamsList = useCallback(() => {
        if (loading || (items?.files.length !== 0))
            return null;

        return (
            <div className={classNames.emptyFolder}>
                <TeamsImage
                    imageName={ImageName.EmptyFolderDrop}
                    fullContainer
                    caption={t("common:emptyFolder")}
                />
            </div>
        )
    }, [classNames.emptyFolder, items, t, loading]);

    const columns : IColumn[] = [   
    {
        key: 'file-type',
        name: 'File Type',
        iconName: 'Page',
        isIconOnly: true,
        fieldName: '',
        minWidth: 20,
        maxWidth: 20,
        onRender: function getItemCell(item: FileShort) {
            return <span> <Icon iconName="TeamsLogo16" /> </span>
        }
    }, 
    {
        key: 'Name',
        name: t("name"),
        fieldName: 'Name',
        minWidth: 100,
        maxWidth: 350,
        isRowHeader: true,
        isResizable: true,
        onRender: function getItemName(item: FileShort) {        
            return (
                <span
                    onClick={() => {props.onTeamSelected(item)}}
                    style={{ cursor: 'pointer'  }}                    
                >
                    {item.name}
                </span>
            )
        }
    }];

    return (
        <div className={classNames.root} id={"modalListZone"}>
            {error &&
                <TeamsImage
                    imageName={ImageName.Error1}
                    scale={0.3}
                    fullContainer
                    caption={t("common:genericErrorApi")}
                />
            }
            {!error &&
                <>
                    <Stack horizontal>
                        <StackItem grow={2}> 
                        <div className={classNames.breadcrumbContainer}>

                            <div className={classNames.iconContainer}>
                                <Icon styles={{ root: { fontSize: '18px' } }} iconName="home" />
                            </div>
                            <div className={classNames.nameContainer}> 
                            {window.self !== window.top ? teamsAuthService.currentUserName  : webAuthService.currentUserName}                            </div>                           
                        </div>                
                        </StackItem>
                    </Stack>


                    <div id={"SPOfolderContentDetailsListZone"} style={{ height: '360px', overflow: 'overlay' }}>
                        <ShimmeredDetailsList
                            enableShimmer={loading}
                            items={teamsList.files ?? []}
                            columns={columns}
                            getKey={(item: FileShort) => item && `${item.uniqueId}`}
                            selectionMode={SelectionMode.none}
                            constrainMode={ConstrainMode.unconstrained}
                            onRenderDetailsFooter={emptyTeamsList}
                            onShouldVirtualize={() => false}
                            compact={true}
                            isHeaderVisible={false}
                            styles={classNames.subComponentStyles.shimmeredDetailsList}
                        />
                    </div>
                </>
            }
        </div>
    );
}