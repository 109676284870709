import { FontSizes, FontWeights, IDetailsListStyles, IDetailsRowStyleProps, IDetailsRowStyles, IDropdownStyles, ILabelStyles, IScrollablePaneStyles, IStyleFunction } from "@fluentui/react"
import { IWorkflowLabMembersSettingsPropsStyles, IWorkflowLabMembersSettingsStyles } from "./workflowLabMembersSettings.types"

export const WorkflowLabMembersSettingsGlobalClassNames = {
    root: 'edi-workflowLab-members-settings-root',
    title: 'edi-workflowLab-members-title',
    titleIcon: 'edi-workflowLab-members-title-icon',
    titleContent: 'edi-workflowLab-members-title-content',
    upperSection: 'edi-workflowLab-members-upper-section',
    searchBox: 'edi-workflowLab-members-search-box',
    lowerSection: 'edi-workflowLab-members-lower-section',
    headerRow: 'edi-workflowLab-members-header-row',
    loaderContainer: 'edi-workflowLab-members-loader-container',
    labelList: 'edi-workflowLab-members-labelList',
    tagCounter: 'edi-workflowLab-members-settings-tagCounter',
    labelBox: 'edi-workflowLab-members-settings-labelBox',
    teamsImage: 'edi-workflowLab-members-settings-teamsImage',
    containerTag: 'edi-workflowLab-members-settings-containerTag'
}

export const getStyles: IStyleFunction<IWorkflowLabMembersSettingsPropsStyles, IWorkflowLabMembersSettingsStyles> = (props: IWorkflowLabMembersSettingsPropsStyles): IWorkflowLabMembersSettingsStyles => {
    const { semanticColors, palette } = props.theme!; //eslint-disable-line @typescript-eslint/no-non-null-assertion

    return {
        root: [
            WorkflowLabMembersSettingsGlobalClassNames.root,
            {
                height: '100%',
                padding: '15px 25px'
            }
        ],
        title: [
            WorkflowLabMembersSettingsGlobalClassNames.title,
            {
                fontSize: FontSizes.size18,
                fontWeight: FontWeights.semibold,
                paddingBottom: 10
            }
        ],
        titleIcon: [
            WorkflowLabMembersSettingsGlobalClassNames.titleIcon,
            {
                padding: 7,
                border: '1.5px solid',
                borderRadius: '50%',
                fontSize: 26
            }
        ],
        titleContent: [
            WorkflowLabMembersSettingsGlobalClassNames.titleContent,
            {
                paddingLeft: 10
            }
        ],
        upperSection: [
            WorkflowLabMembersSettingsGlobalClassNames.upperSection,
            {
                paddingTop: 10,
                paddingBottom: 10
            }
        ],
        searchBox: [
            WorkflowLabMembersSettingsGlobalClassNames.searchBox,
            {
                minWidth: 450,
                paddingLeft: 8,
                iconContainer: {
                    display: 'none'
                }
            }
        ],
        lowerSection: [
            WorkflowLabMembersSettingsGlobalClassNames.lowerSection,
            {
                position: 'relative'
            }
        ],
        headerRow: [
            WorkflowLabMembersSettingsGlobalClassNames.headerRow,
            {
                ':hover': {
                    backgroundColor: 'transparent'
                },
                ':active': {
                    backgroundColor: 'transparent'
                }
            }
        ],
        labelList: [
            WorkflowLabMembersSettingsGlobalClassNames.labelList,
            {
                display: 'flex',
                alignItems: "center",
                flexDirection: 'horizontal',
                selectors: {
                    ' .icontag-container': {
                        marginLeft: '10px'
                    },
                    ' .edi-icon-tag-root': {
                        padding: '0 13px'
                    }
                }
            }
        ],
        containerTag: [
            WorkflowLabMembersSettingsGlobalClassNames.containerTag,
            {
            display: 'flex', 
            height: '100%', 
            alignItems: 'center',
            flexFlow: 'row wrap',
            gap: '5px 10px' 
            }
        ], 
        loaderContainer: [
            WorkflowLabMembersSettingsGlobalClassNames.loaderContainer,
            {
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
                /*
                height: '85vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
                */
            }
        ],
        teamsImage: [
            WorkflowLabMembersSettingsGlobalClassNames.teamsImage,
            {
                width: '50%',
                height: '50%'
            }
        ],
        tagCounter: [
            WorkflowLabMembersSettingsGlobalClassNames.tagCounter,
            {
                marginLeft: '10px !important'
            }
        ],
        labelBox: [
            WorkflowLabMembersSettingsGlobalClassNames.labelBox,
            {
                width: '100px',
                overflow: 'hidden',
                height: '25px',
                padding: '3px 0'
            }
        ],
        remainingIconTagContainer: {
            padding: '0 5px',
            width: '100%',
            textAlign: 'left',
            justifyContent: 'start',
            color: palette.themePrimary
        },
        subComponentStyles: {
            addMembersButtonStyle: {
                icon: {
                    height: 'auto'
                }
            },
            list: (): IDetailsListStyles => {
                return {
                    root: {
                        // selectors: {
                        //     '& [role=grid]': {
                        //         display: 'flex',
                        //         flexDirection: 'column',
                        //         alignItems: 'start',
                        //         maxHeight: '65vh'
                        //     }
                        // }
                    },
                    headerWrapper: {
                        //flex: '0 0 auto',
                        '> .ms-DetailsHeader': {
                            paddingTop: 0,
                            borderBottomColor: 'transparent'
                        }
                    },
                    contentWrapper: {
                        // flex: '1 1 auto',
                        // overflowY: 'overlay',
                        // overflowX: 'hidden',
                        // width: '100%'
                    },
                    focusZone: {}
                }
            },
            row: (props: IDetailsRowStyleProps): IDetailsRowStyles => {
                return {
                    root: {
                        backgroundColor: palette.white,
                        ':hover': {
                            backgroundColor: palette.white
                        }
                    },
                    cell: {},
                    cellAnimation: {},
                    cellPadded: {},
                    cellMeasurer: {},
                    cellUnpadded: {},
                    check: {},
                    checkCell: {},
                    checkCover: {},
                    fields: {},
                    isMultiline: {},
                    isRowHeader: {},
                }
            },
            scrollablePane: (): IScrollablePaneStyles => {
                return {
                    root: {},
                    contentContainer: {
                        width: '100%',
                        paddingRight: 17,
                        overflowY: 'overlay'
                    },
                    stickyAbove: {},
                    stickyBelow: {},
                    stickyBelowItems: {}
                }
            },
            rolesDropdown: (): IDropdownStyles => {
                return {
                    root: {},
                    label: {},
                    dropdown: {
                        '::after': {
                            display: 'none'
                        }
                    },
                    title: {
                        backgroundColor: palette.neutralLight
                    },
                    caretDownWrapper: {},
                    caretDown: {},
                    errorMessage: {},
                    dropdownItemsWrapper: {},
                    dropdownItems: {},
                    dropdownItem: {},
                    dropdownItemSelected: {},
                    dropdownItemDisabled: {},
                    dropdownItemSelectedAndDisabled: {},
                    dropdownItemHidden: {},
                    dropdownOptionText: {},
                    dropdownDivider: {},
                    dropdownItemHeader: {},
                    panel: {},
                    callout: {},
                    subComponentStyles: {
                        label: {},
                        multiSelectItem: {},
                        panel: {}
                    }
                }
            },
            iconButton: {
                root: {
                    paddingRight: 10,
                },
                icon: {
                    color: palette.black
                },
                iconDisabled: {
                    color: semanticColors.bodyText
                },
                rootHovered: {
                    background: 'transparent'
                },
                rootPressed: {
                    background: 'transparent'
                },
                rootDisabled: {
                    background: 'transparent',
                    cursor: 'not-allowed'
                }
            },
            templatesCountCircle: (): ILabelStyles => {
                return {
                    root: {
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '50%',
                        width: '30px',
                        height: '30px',
                        fontSize: '12px',
                        backgroundColor: semanticColors.variantBorder,
                        color: palette.themePrimary,
                    }
                }
            }
        }
    };
}