import { IStyleFunction } from "@fluentui/react"
import { IEditLabelModalPropsStyles, IEditLabelModalStyles} from "./editLabelModal.types"

export const EditLabelModalGlobalClassNames = {
    root: 'ewe-workflowlab-edit-label',
    footerButtons: 'ewe-workflowlab-edit-label-footerButtons',
    errorMessage: 'ewe-workflowlab-edit-label-error-message',
    footerContainer: 'ewe-workflowlab-edit-label-footer-container',
}

export const getStyles: IStyleFunction<IEditLabelModalPropsStyles, IEditLabelModalStyles> = (props: IEditLabelModalPropsStyles): IEditLabelModalStyles => {
    const { palette } = props.theme!; // eslint-disable-line @typescript-eslint/no-non-null-assertion
    return {
        root: [
            EditLabelModalGlobalClassNames.root,
            {
                  // CSS PROPS AND SELECTORS
            }
        ]
        ,footerButtons: [
            EditLabelModalGlobalClassNames.footerButtons,
            {
                'margin-left': '10px'
            }
        ],
        errorMessage: [
            EditLabelModalGlobalClassNames.errorMessage,
            {
                color: palette.redDark,
                alignSelf: 'center',
                marginRight: 'auto'
            }
        ],
        footerContainer: [
            EditLabelModalGlobalClassNames.footerContainer,
            {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end'
            }
        ]
    };
}