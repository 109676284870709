import { IRawStyle } from "@fluentui/react"

export interface IHistory {
    push: (url: string) => void
}

export interface ILocation {
    pathname: string;
    search: string;
}

export enum FormItemType {
    Text = 0,
    MultiText = 1,
    Dropdown = 2,
    DatePicker = 3,
    Numeric = 4,
    TextCurrency = 5
}

export interface IGroup {
    count: number;
    key: string;
    name: string;
    startIndex: number;
    level?: number;
    isCollapsed?: boolean;
    children?: IGroup[];
}

export enum WorkflowUserRoleId {
    User = 0,
    WorkflowLabCreator = 1,
    PlatformAdmin = 2
}

export enum AddMembersPageType {
    WorkFlowLab = 0
}

export enum WorkFlowLabRoleId {
    Owner = 0,
    Supervisor = 1,
    User = 2,
    Auditor = 3
}

export const multiLineEllipsis = (num: number): IRawStyle => {
    return {
        display: '-webkit-box',
        '-webkit-line-clamp': `${num}`,
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden'
    }
}

export const FileFormatExtension = [
    {extension:".doc",  key:"application/msword"},
    {extension:".docx", key:"application/vnd.openxmlformats-officedocument.wordprocessingml.document"},
    {extension:".dotx", key:"application/vnd.openxmlformats-officedocument.wordprocessingml.template"},
    {extension:"docm",  key:"application/vnd.ms-word.document.macroEnabled.12"},
    {extension:"dotm",  key:"application/vnd.ms-word.template.macroEnabled.12"},
    {extension:".xlsx", key:"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"},
    {extension:".xltx", key:"application/vnd.openxmlformats-officedocument.spreadsheetml.template"},
    {extension:".xlsm", key:"application/vnd.ms-excel.sheet.macroEnabled.12" },
    {extension:".xltm", key:"application/vnd.ms-excel.template.macroEnabled.12" },
    {extension:".xlsb", key:"application/vnd.ms-excel.sheet.binary.macroEnabled.12"},
    {extension:".pptx", key:"application/vnd.openxmlformats-officedocument.presentationml.presentation"},
    {extension:".ppsx", key:"application/vnd.openxmlformats-officedocument.presentationml.slideshow"},
    {extension:".potx", key:"application/vnd.openxmlformats-officedocument.presentationml.template"},
    {extension:".pptm", key:"application/vnd.ms-powerpoint.presentation.macroEnabled.12" },
    {extension:".ppsm", key:"application/vnd.ms-powerpoint.slideshow.macroEnabled.12" },
    {extension:".potm", key:"application/vnd.ms-powerpoint.template.macroEnabled.12" },
    {extension:".ppt",  key:"application/vnd.ms-powerpoint" },
    {extension:".pdf",  key:"application/pdf" },
    {extension:".msg",  key:"application/octet-stream" }
];

export enum FileType {
    Doc = 0,
    Docx = 1,
    Dotx = 2,
    Docm = 3,
    Dotm = 4,
    Xlsx = 5,
    Xltx = 6,
    Xlsm = 7,
    Xltm = 8,
    Xlsb = 9,
    Pptx = 10,
    Ppsx = 11,
    Potx = 12,
    Pptm = 13,
    Ppsm = 14,
    Potm = 15,
    Ppt = 16,
    Pdf = 17,
    Msg = 18
}

export const SharePointDocumentPathSuffixes = {
    Edit: '&allowtyping=true&wddownloadbutton=false',
    ViewOnly: '&action=embedview&allowtyping=false&wddownloadbutton=false'
};

export const FileExtensions = {
    Pdf: ".pdf"
}