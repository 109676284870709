/*eslint-disable sonarjs/cognitive-complexity*/
import React, { useCallback, useState } from "react";
import { classNamesFunction, DefaultButton, Icon, IconButton, IStyleFunctionOrObject, ITextFieldStyleProps, ITextFieldStyles, keyframes, PrimaryButton, Spinner, SpinnerSize, TextField } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { IEditCategoryModalProps, IEditCategoryModalPropsStyles, IEditCategoryModalStyles } from "./editCategoryModal.types";
import { Constants } from "../../../../../models/constants";
import EdiModal from "../../../../../common/components/ediModal/ediModal";
import { Helpers } from "../../../../../utilities/helpers";
import { categoryApi } from "../../../../services/category/category.api";
import { useCurrentWorkflowLab } from "../../../../hooks/useCurrentWorkflowLab";

const getClassNames = classNamesFunction<IEditCategoryModalPropsStyles, IEditCategoryModalStyles>();

export const EditCategoryModalBase = (props: IEditCategoryModalProps) => {
    const classNames = getClassNames(props.styles, { 
        theme: props.theme, 
        className: props.className 
    });
    const { t } = useTranslation(['workflowLabManageUsers','common']);
    const [currentName, setCurrentName] = useState<string | undefined>(props.name);
    const [editCategoryModal, setEditCategoryModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [validationIcon, setValidationIcon] = useState('Error');
    const { palette } = props.theme!; // eslint-disable-line @typescript-eslint/no-non-null-assertion
    const currentWorkflowLab = useCurrentWorkflowLab();

    const onNameValidationResult = useCallback((error: string | JSX.Element, value: string | undefined) => {
        setValidationIcon(error ? 'Error' : 'Completed');
    }, []);

    const validateCategoryName = useCallback(async (workflowLabId: number | undefined, value: string) => {
        if (!value)
            return `${t('common:fieldRequired')}`;

        setValidationIcon('ProgressRingDots');

        try {

            if (props.name === value) {
                setValidationIcon('Completed');
                return "";
            }
            
            const [isValid] = await Promise.all([categoryApi.isCategoryNameAvailable(currentWorkflowLab? currentWorkflowLab.id : 0, value), Helpers.delay(500)]);
            return isValid ? "" : t('common:nameNotAvailable');
            
        }
        catch {
            return t('common:genericErrorCheckName');
        }
    }, [t, props.name, currentWorkflowLab]);
    
    const setupParameter = () => {
        return (isLoading ? 
                    <div style={{display: 'flex', justifyContent: 'center', height: 'inherit'}}>
                        <Spinner style={{alignSelf: 'center'}} size={SpinnerSize.medium} />
                    </div> : 
                    <div>
                        <TextField
                                label={t('common:name')}
                                placeholder={t('addName')}
                                deferredValidationTime={250}
                                onGetErrorMessage={(name:string) => validateCategoryName(currentWorkflowLab? currentWorkflowLab.id:0, name)}
                                required
                                autoFocus
                                autoComplete={"off"}
                                styles={styles}
                                onNotifyValidationResult={onNameValidationResult}
                                onChange={(_, newValue) => setCurrentName(newValue)}
                                defaultValue={currentName}
                                maxLength={Constants.MAX_LENGTH_LABEL_NAME}
                                onRenderSuffix={() => <Icon iconName={validationIcon} />}
                            />                        
                    </div>)
    }

    const styles: IStyleFunctionOrObject<ITextFieldStyleProps, ITextFieldStyles> = () => {
        return {
            root: {
                marginBottom: '30px'
            },
            errorMessage: {
                position: 'absolute'
            },
            suffix: [
                { background: 'transparent', },
                validationIcon === 'Error' && { color: palette.redDark },
                validationIcon === 'Completed' && { color: palette.greenDark },
                validationIcon === 'ProgressRingDots' && {
                    '> i': {
                        animation: `${keyframes({ to: { transform: 'rotate(360deg)' } })} 1s linear infinite`
                    }
                }
            ]
        }
    }

    const editCategory = () => {
        setIsLoading(true);
        if(!currentName) return;
        categoryApi.updateCategory(props.Id, currentName)
            .then(props.onComplete)
            .finally(() => setIsLoading(false));      
        setEditCategoryModal(false); 
    }

    const modalFooter = () => {
        return (
            <div>
                <>
                    <DefaultButton
                        onClick={()=> {setCurrentName(props.name); setEditCategoryModal(false)}}
                        style={{marginRight:'10px'}}
                    >
                        {t('common:cancel')}
                    </DefaultButton>
                    <PrimaryButton 
                        disabled={validationIcon !== "Completed"}
                        onClick={editCategory}
                    >
                        {t('common:commands.edit')}
                    </PrimaryButton>
                </>
            </div>
            )
    }
    
    return (
        <>
        <IconButton 
                    iconProps={{ iconName: 'Edit' }} 
                    title={`${t('editCategory')}`}
                    onClick={(e) => {
                        e.stopPropagation();
                        setEditCategoryModal(true);
                    }
                }/>
            <EdiModal
                className={classNames.root}
                title={`${t('editCategory')}`}
                isOpen={editCategoryModal}
                width={600}
                height={600}
                showCloseIcon={true}
                onCloseClick={() => {
                    setEditCategoryModal(false);
                    setCurrentName(props.name);
                }}
                body={setupParameter()}
                footer={modalFooter()}
                styles={
                    {
                        body: {
                            overflow: 'unset'
                        }
                    }
                }
            />
        </>
    );
}