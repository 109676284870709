import { FontSizes, FontWeights, IStyleFunction } from "@fluentui/react"
import { IWorkflowLabManageTemplatesPropsStyles, IWorkflowLabManageTemplatesStyles } from "./workflowLabManageTemplates.types";

export const WorkflowLabManagTemplatesGlobalClassNames = {
    root: 'ewe-workflowlab-manage-templates-root',
    pageTitle: 'ewe-workflowlab-manage-templates-title',
    pageTitleBox: 'ewe-workflowlab-manage-templates-title-box',
    templatesview: 'ewe-workflowlab-manage-templates-userview-btn',
    pivot: 'ewe-workflowlab-manage-templates-pivot',
    titleIcon : 'ewe-workflowlab-manage-templates-titleicon',
    titleContent : 'ewe-workflowlab-manage-templates-titlecontent'
}

export const getStyles: IStyleFunction<IWorkflowLabManageTemplatesPropsStyles, IWorkflowLabManageTemplatesStyles> = (props: IWorkflowLabManageTemplatesPropsStyles): IWorkflowLabManageTemplatesStyles => {
    const { semanticColors } = props.theme!; // eslint-disable-line @typescript-eslint/no-non-null-assertion
    return {
        root: [
            WorkflowLabManagTemplatesGlobalClassNames.root,
            {
                height: '100%',
                position: 'relative',
                selectors: {
                    '::before': {
                        background: `linear-gradient(to right,transparent,${semanticColors.menuDivider})`,
                        content: "''",
                        opacity: '0.2',
                        pointerEvents: 'none',
                        position: 'absolute',
                        right: 0,
                        width: '0.8rem',
                        height: '100%',
                        zIndex: '2'
                    }
                }
            }
        ]
        ,
        pageTitleBox:[
            WorkflowLabManagTemplatesGlobalClassNames.pageTitleBox,
            {
                margin: '0 30px'
            }
        ],
        pageTitle:[
            WorkflowLabManagTemplatesGlobalClassNames.pageTitle,
            {
                fontSize: FontSizes.size18,
                fontWeight: FontWeights.semibold,
                paddingBottom: 10,
                padding: '15px'
            }
        ],
        titleIcon: [
            WorkflowLabManagTemplatesGlobalClassNames.titleIcon,
            {
                padding: 7,
                border: '1.5px solid',
                borderRadius: '50%',
                fontSize: 26
            }
        ],
        titleContent: [
            WorkflowLabManagTemplatesGlobalClassNames.titleContent,
            {
                paddingLeft: 10
            }
        ],
        templatesview:[
            WorkflowLabManagTemplatesGlobalClassNames.templatesview,
            {
                height: 'calc(100% - 150px)',
            }
        ],
        pivot:[
            WorkflowLabManagTemplatesGlobalClassNames.pivot,
            {
                height: '100%',
                selectors: {
                    ' .ms-Pivot-link': {
                        width: '200px'
                    },
                    ' div[role=tabpanel]':{
                        marginTop: '20px',
                        height: '100%'
                    }
                }
            }
        ],
    };
}