import { styled } from "@fluentui/react";
import { WorkflowDisjointedGraphBase } from "./workflowDisjointedGraph.base";
import { getStyles } from "./workflowDisjointedGraph.styles";
import { IWorkflowDisjointedGraphProps, IWorkflowDisjointedGraphPropsStyles, IWorkflowDisjointedGraphStyles } from "./workflowDisjointedGraph.types";

const WorkflowDisjointedGraph = styled<IWorkflowDisjointedGraphProps, IWorkflowDisjointedGraphPropsStyles, IWorkflowDisjointedGraphStyles>(
    WorkflowDisjointedGraphBase,
    getStyles,
    undefined,
    { scope: 'WorkflowDisjointedGraph' }
);

export default WorkflowDisjointedGraph;