import React, { useEffect, useState } from "react";
import { classNamesFunction } from "@fluentui/react";
import { IAddStepsPropsStyles, IAddStepsStyles, IAddStepsProps } from "./addSteps.types";
import SingleStep from "./singleStep/singleStep";
import { useOnMount } from "../../../../../utilities/hooks";
import { useTranslation } from "react-i18next";
import { WorkFlowStep, WorkFlowStepRelativeStatus, WorkFlowStepStatus, WorkFlowStepTypes } from "../../../../../models/WorkFlow";
import { WorkFlowApprover } from "../../../../../models/workflowApprover";
import { AvailableApproversAndLabels } from "../../../../services/workflow.contracts";

const getClassNames = classNamesFunction<IAddStepsPropsStyles, IAddStepsStyles>();
export type ThirdPageData = {
    digitalSignEnabled: boolean,
    steps: WorkFlowStep[]
}

export const AddStepsBase = (props: IAddStepsProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const [data, setData] = useState<ThirdPageData>(props.initialState);
    const [key, setKey] = useState(0);
    const { t } = useTranslation(['createWorkflow', 'common']);

    useOnMount(() => {
        if (data.steps.length === 0)
            addStep();
    })

    useEffect(() => { props.onWorkflowDataChange(data) }, [data, props]);

    const changeName = (newName: string, id: number) => {
        const copy = [...data.steps];
        copy[id].name = newName;
        setData({ ...data, steps: copy });
    }

    const onChangeType = (newType: WorkFlowStepTypes, id: number) => {
        const copy = [...data.steps];
        copy[id].type = newType;
        setData({ ...data, steps: copy });
    }

    const onUpdateApprovers = (users: WorkFlowApprover[] | AvailableApproversAndLabels[], id: number) => {
        const copy = [...data.steps];
        if(!props.disjointed) {
            copy[id].approvers = users as WorkFlowApprover[];
        }
        if(props.disjointed) {
            copy[id].usersAndLabels = users as AvailableApproversAndLabels[];
        }
        setData({ ...data, steps: copy });
    }

    const getSteps = () => {
        return <div key={key}>{data.steps.map((step, ind) => {
            return (
                <SingleStep
                    isPdf={props.fileType}
                    digitalSignEnabled={data.digitalSignEnabled}
                    key={ind}
                    moveItem={(item: WorkFlowStep, up: boolean) => moveItem(item, up)}
                    deleteItem={(item: WorkFlowStep) => deleteItem(item)}
                    disabled={[step.order === 0, step.order === data.steps.length - 1, data.steps.length === 1]}
                    step={step}
                    updateApprovers={(approvers) => onUpdateApprovers(approvers, step.order)}
                    onChangeName={(newName) => changeName(newName, step.order)}
                    onChangeType={(newType) => onChangeType(newType, step.order)}
                    disjointed={props.disjointed}
                />
            )
        })}</div>
    }

    const reload = (newOrderedList: WorkFlowStep[]) => {
        newOrderedList.forEach((element, ind) => {
            element.id = ind;
            element.order = ind;
        });
        setData({ ...data, steps: [...newOrderedList] });
        setKey(key + 1)
    }

    const deleteItem = (item: WorkFlowStep) => {
        const list = [...data.steps];
        const newList = list.filter(element => element !== item);
        reload(newList);
    }

    const moveItem = (item: WorkFlowStep, up: boolean) => {
        const list = [...data.steps];
        if (up) {
            const previousItem = list[item.id - 1];
            list[item.id - 1] = item;
            list[item.id] = previousItem
        }
        else {
            const nextItem = list[item.id + 1];
            list[item.id + 1] = item;
            list[item.id] = nextItem;
        }
        reload(list);
    }


    const addStep = () => {
        const step: WorkFlowStep = {
            name: "",
            approvedOn: new Date(),
            approvers: [],
            comment: "",
            createdOn: new Date(),
            id: data.steps.length === 0 ? 0 : data.steps[data.steps.length - 1].id + 1,
            lastUpdatedOn: new Date(),
            order: data.steps.length === 0 ? 0 : data.steps[data.steps.length - 1].order + 1,
            status: WorkFlowStepStatus.ToBeApproved,
            type: WorkFlowStepTypes.OnlyOneApprover,
            relativeStatus: WorkFlowStepRelativeStatus.Idle,
            workFlowId: -1,
            involvedPeople: []
        }
        setData({ ...data, steps: data.steps.concat(step) });
    }

    return (
        <div className={classNames.root} >
            {getSteps()}
            <div className={classNames.addContainer}>
                <span onClick={addStep} className={`${classNames.addLabel} ${props.maxSteps && data.steps.length >= props.maxSteps ? classNames.labelDisable : ''}`}>{t('addSteps.addStep')}</span>
            </div>
        </div>
    );
}