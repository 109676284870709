/*eslint-disable sonarjs/cognitive-complexity */
import React, { useState } from "react";
import { classNamesFunction, DetailsHeader, DetailsList, DetailsRow, IColumn, SelectionMode, Spinner, SpinnerSize} from "@fluentui/react";
import { useTranslation } from "react-i18next";
import EdiModal from "../../../../../common/components/ediModal/ediModal";
import { IWorkflowStepHistoryProps, IWorkflowStepHistoryPropsStyles, IWorkflowStepHistoryStyles } from "./workflowStepHistory.types";
import { WorkFlowStepHistory } from "../../../../../models/WorkFlow";
import { useOnMount } from "../../../../../utilities/hooks";
import { workFlowStepApi } from "../../../../services/workflowstep/workflowstep.api";
import TeamsImage from "../../../../../common/components/teamsImage/teamsImage";
import { ImageName } from "../../../../../common/components/teamsImage/teamsImage.types";

const getClassNames = classNamesFunction<IWorkflowStepHistoryPropsStyles, IWorkflowStepHistoryStyles>();

export const WorkflowStepHistoryBase = (props: IWorkflowStepHistoryProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const { t } = useTranslation(['stepHistory', 'common']);
    const [stepHistories, setStepHistories] = useState<WorkFlowStepHistory[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    useOnMount(() => {
        getStepHistories();
    });

    const getStepHistories = async () => {
        if(!props.workflow)
            return

        setLoading(true);
        try{
            const result = await workFlowStepApi.getWorkflowStepHistory(props.workflow?.id);
            setStepHistories(result);
        }finally{
            setLoading(false);
        }
    }

    const columns : IColumn[] = [
        {
            key: 'step',
            name: t('column.step'),
            ariaLabel: 'step',
            fieldName: 'step',
            minWidth: 110,
            maxWidth: 110,
            isCollapsible: true,
            isResizable: false,
            onRender: function getItemType(item: WorkFlowStepHistory) {
                return <div className={classNames.workflowStepHistoryNameWrapper}>                    
                    <span>{item.step.name}</span>
                </div>
            }
        },
        {
            key: 'approver',
            name: t('column.approver'),
            ariaLabel: 'approver',
            fieldName: 'approver',
            minWidth: 140,
            maxWidth: 140,
            isCollapsible: true,
            isResizable: false,
            onRender: function getItemType(item: WorkFlowStepHistory) {
                return <div className={classNames.workflowStepHistoryNameWrapper}>                    
                    <span>{item.approver.firstName + " " + item.approver.lastName}</span>
                </div>
            }
        },
        {
            key: 'actionType',
            name: t('column.actionType'),
            ariaLabel: 'actionType',
            fieldName: 'actionType',
            minWidth: 140,
            maxWidth: 140,
            isCollapsible: true,
            isResizable: false,
            onRender: function getItemType(item: WorkFlowStepHistory) {
                return <div className={classNames.workflowStepHistoryNameWrapper}>                    
                    <span>{t(`actions.${item.actionType}`)}</span>
                </div>
            }
        },
        {
            key: 'comment',
            name: t('column.comment'),
            ariaLabel: 'comment',
            fieldName: 'comment',
            minWidth: 100,
            maxWidth: 100,
            isCollapsible: true,
            isResizable: false,
            onRender: function getItemType(item: WorkFlowStepHistory) {
                return <div className={classNames.workflowStepHistoryNameWrapper}>                    
                    <span>{item.comment}</span>
                </div>
            }
        },
    ];  

    const emptyList = () =>{
        if (stepHistories.length !== 0)
            return null;
        return (
            <TeamsImage styles={{ img: { width: '35%' } }} imageName={ImageName.EmptyFolderDrop} caption={t('common:emptyList')} />
        )
    };
    
    const body = () => {
        return <div className={loading ? classNames.spinnerStyle: undefined}> 
        {loading ? <Spinner size={SpinnerSize.large}/> :
            <DetailsList
                items={stepHistories}
                columns={columns}
                selectionMode={SelectionMode.none}
                styles={classNames.subComponentStyles.detailsList}
                onRenderDetailsHeader={props => props ? <DetailsHeader {...props} styles={classNames.subComponentStyles.detailsHeader} /> : null}
                onRenderRow={props => props ? <DetailsRow id={props.id} {...props} styles={classNames.subComponentStyles.detailsRow} /> : null}
                onRenderDetailsFooter={emptyList}
            />
        }
        </div>
    }

    return (
        <EdiModal
            body={body()}
            isOpen={props.isOpen}
            height={'90%'}
            width={'60%'}
            onCloseClick={() => props.onClose()}
            showCloseIcon
            title={t('history')}
            subtitle={t('historyModalSubtitile')}
        />
    );
}