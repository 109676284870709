import { classNamesFunction, Stack } from "@fluentui/react";
import React, { useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import TeamsImage from "../../../common/components/teamsImage/teamsImage";
import { ImageName } from "../../../common/components/teamsImage/teamsImage.types";
import { useOnMount } from "../../../utilities/hooks";
import { AppRoutes, AppTab } from "../../utilities/routes";
import { IHeaderProps, IHeaderPropsStyles, IHeaderStyles } from "./header.types";
const getClassNames = classNamesFunction<IHeaderPropsStyles, IHeaderStyles>();

export const HeaderBase = (props: IHeaderProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const location = useLocation();
    const history = useHistory();
    const [lastRoutes, setLastRoutes] = useState({
        [AppTab.workflow]: AppRoutes.generateRouteForTab(AppTab.workflow),
    });

    const selectedTab = useMemo(() => AppRoutes.getSelectedTab(location.pathname), [location.pathname]);

    useOnMount(() => {
        AppRoutes.isHome(location.pathname) && history.replace(AppRoutes.generateRouteForTab(AppTab.workflow))
    });

    useEffect(() => {
        if (!selectedTab || lastRoutes[selectedTab] === location.pathname)
            return;
        setLastRoutes({ ...lastRoutes, [selectedTab]: location.pathname })
    }, [location.pathname, selectedTab, lastRoutes]);

    return (
        <Stack horizontal className={classNames.root}>
            <Stack.Item className={classNames.title} disableShrink>
                <TeamsImage imageName={ImageName.EdiAppLogoPNG} styles={{ img: { borderRadius: 4 } }} />
                <h2>EWE</h2>
            </Stack.Item>
        </Stack>
    );
}