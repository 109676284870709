import { styled } from "@fluentui/react";
import { getStyles } from "./teamsSpinner.styles";
import { ITeamsSpinnerPropsStyles, ITeamsSpinnerStyles, ITeamsSpinnerProps } from "./teamsSpinner.types";
import { TeamsSpinnerBase } from "./teamsSpinner.base";

const TeamsSpinner = styled<ITeamsSpinnerProps, ITeamsSpinnerPropsStyles, ITeamsSpinnerStyles>(
    TeamsSpinnerBase,
    getStyles,
    undefined,
    { scope: 'TeamsSpinner' }
);

export default TeamsSpinner;