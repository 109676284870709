import { IStyleFunction } from "@fluentui/react"
import { IEdiFilePropsStyles, IEdiFileStyles } from "./ediFile.types"

export const EdiFileGlobalClassNames = {
    root: 'edi-Teams-file-root'
}

export const getStyles: IStyleFunction<IEdiFilePropsStyles, IEdiFileStyles> = (props: IEdiFilePropsStyles): IEdiFileStyles => {
    return {
        root: [
            EdiFileGlobalClassNames.root,
            {
               // marginBottom: 20
            }
        ]
    };
}