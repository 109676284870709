import { IStyleFunction } from "@fluentui/react"
import { ICreateLabelModalPropsStyles, ICreateLabelModalStyles } from "./createLabelModal.types"

export const CreateLabelModalGlobalClassNames = {
    root: 'ewe-workflowlab-create-label',
    footerButtons: 'ewe-workflowlab-create-label-footerButtons',
    errorMessage: 'ewe-workflowlab-error-message',
    footerContainer: 'ewe-workflowlab-footer-container'
}

export const getStyles: IStyleFunction<ICreateLabelModalPropsStyles, ICreateLabelModalStyles> = (props: ICreateLabelModalPropsStyles): ICreateLabelModalStyles => {
    const { palette } = props.theme!; // eslint-disable-line @typescript-eslint/no-non-null-assertion
    return {
        root: [
            CreateLabelModalGlobalClassNames.root,
            {
                  // CSS PROPS AND SELECTORS
            }
        ],
        footerButtons: [
            CreateLabelModalGlobalClassNames.footerButtons,
            {
                'margin-left': '10px'
            }
        ],
        errorMessage: [
            CreateLabelModalGlobalClassNames.errorMessage,
            {
                color: palette.redDark,
                alignSelf: 'center',
                marginRight: 'auto'
            }
        ],
        footerContainer: [
            CreateLabelModalGlobalClassNames.footerContainer,
            {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end'
            }
        ]
    };
}