import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { WorkFlowRelativeStatus, WorkflowViewType } from "../../models/WorkflowEnum";
import {  WorkflowLabShort } from "../../models/WorkflowShort";
import { Helpers } from "../../utilities/helpers";
import { workflowApi } from "../services/workflow.api";
import { GetWorkFlowsDashboardRequest, GetWorkFlowsRequest } from "../services/workflow.contracts";
import { useWorkflowSelector } from "../workflowStore";

const initialFilters: GetWorkFlowsRequest = {
  pageNumber: 0,
  pageSize: 20,
  orderBy: "ExpirationDate",
  isAscending: true,
  workflowLabId: 0,
  isCreator: false,
  searchBy: undefined,
  status: [WorkFlowRelativeStatus.Approved, WorkFlowRelativeStatus.Ongoing, WorkFlowRelativeStatus.Refused, WorkFlowRelativeStatus.ToMe],
  creationDateFrom: undefined,
  creationDateTo: undefined
}

export type WorkflowListState = {
  selectedWorkflowLab: number;
  filters: GetWorkFlowsRequest;
  workflowLabSelectedChoiceGroup: WorkflowViewType;
  workflowsLab: {
    workflowsLab: Record<number, WorkflowLabShort>;
    workflowLabIds: number[];
    isLoading: boolean;
    isError: boolean;
    authError: boolean;
  },
  isRenameWorkflowModalOpen?: boolean;
};

const initialState: WorkflowListState = {
  selectedWorkflowLab: 0,
  filters: initialFilters,
  workflowLabSelectedChoiceGroup: WorkflowViewType.received,
  workflowsLab: {
    workflowsLab: {},
    workflowLabIds: [],
    isLoading: false,
    isError: false,
    authError: false
  },
  isRenameWorkflowModalOpen: false
};

// async thunk method

export const getWorkflowsLab = createAsyncThunk('workflow/get-workflowlab-list',
  async () => {
    return await workflowApi.getWorkflowLabList();
  }
)

export const deleteWorkflow = createAsyncThunk('workflow/delete-workflows',
  async (request: number) => {
    return await workflowApi.deleteWorkflow(request);
  }
)

export const getWorkflowDashboard = createAsyncThunk('workflow/GetWorkFlowDashBoard',
async (request: {body: GetWorkFlowsDashboardRequest, workflowLabId: number, searchKeyword: string, datefrom: string, dateto:string}) => {
  request.body.workflowLabId = request.workflowLabId;
  request.body.searchBy = request.searchKeyword;
  request.body.dateFrom = request.datefrom;
  request.body.dateTo = request.dateto;
  return await workflowApi.getWorkflowDashboard(request.body);
})
// slice

const workflowListSlice = createSlice({
  name: "workflow-list",
  initialState,
  reducers: {
    updateIdSelectedWorkflowLab: (state: WorkflowListState, action: PayloadAction<number>) => {
      state.selectedWorkflowLab = action.payload;
    },
    setAuthError :(state: WorkflowListState, action: PayloadAction<boolean>)=>{
      state.workflowsLab.authError=action.payload;
    },
    resetFilters: (state: WorkflowListState) => {
      state.filters = initialFilters;
    }, 
    updateFilters: (state: WorkflowListState, action: PayloadAction<GetWorkFlowsRequest>) => {
      state.filters = action.payload
    },
    selectChoiceGroup: (state: WorkflowListState, action: PayloadAction<WorkflowViewType>) => {
      state.workflowLabSelectedChoiceGroup = action.payload
    },
    showRenameWorkflowModal: (state: WorkflowListState, action: PayloadAction<boolean>) => {
      state.isRenameWorkflowModalOpen = action.payload
    }  
  },
  extraReducers: (builder) => {
    builder.addCase(getWorkflowsLab.pending, (state, action) => {
      state.workflowsLab.isError = false;
      state.workflowsLab.isLoading = true;
    });

    builder.addCase(getWorkflowsLab.fulfilled, (state, action) => {
      state.workflowsLab.workflowsLab = Helpers.normalize(action.payload, (workflowLab) => workflowLab.id);
      state.workflowsLab.workflowLabIds = action.payload.map(workflowLab => workflowLab.id);
      state.workflowsLab.isError = false;
      state.workflowsLab.isLoading = false;
    });

    builder.addCase(getWorkflowsLab.rejected, (state, action) => {
      state.workflowsLab.isError = true;
      state.workflowsLab.isLoading = false;
    });

  }
});

export const { updateIdSelectedWorkflowLab, updateFilters, resetFilters, setAuthError, selectChoiceGroup, showRenameWorkflowModal } = workflowListSlice.actions;
export default workflowListSlice.reducer;
export const useWorkflowList = () => useWorkflowSelector((state) => state.workflowList);