import { WorkFlowLabRoleId } from "../utilities/constants";
import { CategoryLabelItem, CategoryViewItem } from "./categoryView";
import { AvailableApproversAndLabels } from "../workflow/services/workflow.contracts";
import { User } from "./User";
import { WorkFlowApprover } from "./workflowApprover";
import { WorkFlowRelativeStatus } from "./WorkflowEnum";
import { ApproveWorkFlowStepType } from "../workflow/services/workflowstep/workflowstep.contracts";

export enum WorkFlowTypes {
    Approval = 0,
    //Verify = 0 non fa differenze per il momento, utilizzeremo solo approval 
}

export enum WorkFlowStatus {
    Approved = 0,
    Refused = 1,
    Ongoing = 2,
    NotStarted = 3
}

export enum WorkFlowStepTypes {
    OnlyOneApprover = 0,
    OnlyOneSignedApprover = 1,
    EveryoneMustApprove = 2
}

export enum WorkFlowStepStatus {
    Idle = 0,
    ToBeApproved = 1,
    Approved = 2,
    Refused = 3
}

export enum WorkFlowStepRelativeStatus {
    Idle = 0,
    ToBeApproved = 1,
    Approved = 2,
    Refused = 3,
    ToMe = 4
}

export enum WorkFlowApproverStatus {
    ToBeApproved = 0,
    Approved = 1,
    Refused = 2
}

export type WorkFlowDisjointment = {
    id: number;
    category: CategoryViewItem;
    labels: CategoryLabelItem[];
}

export type WorkFlowStep = {
    id: number;
    workFlowId: number;
    name: string;
    type: WorkFlowStepTypes ;
    status: WorkFlowStepStatus;
    relativeStatus: WorkFlowStepRelativeStatus;
    order: number;
    createdOn: Date;
    lastUpdatedOn: Date;
    approvedOn: Date;
    comment: string;
    approvers: WorkFlowApprover[];
    documents?: WorkFlowStepDocument[];
    involvedPeople: User[];
    usersAndLabels?: AvailableApproversAndLabels[];

}

export type WorkFlowStepHistory = {
    id: number;
    step: WorkFlowStep;
    workFlowId: number;
    workflowStepId: number;
    userId: number;
    approver: User;
    status: WorkFlowStepStatus;
    actionType: ApproveWorkFlowStepType;
    comment: string;
    createdOn: Date;
    lastUpdatedOn: Date;
}

export type WorkFlowStepDocument = {
    id: number;
    workFlowStepId: number;
    workflowApproverId: string;
    document: string;
    createdOn: Date;
    lastUpdatedOn: Date;
    workFlowApprover: User;
}

export type WorkFlow = {
    id: number;
    activityName?: string;
    name: string;
    description?: string;
    type: WorkFlowTypes;
    status?: WorkFlowStatus;
    relativeStatus?: WorkFlowRelativeStatus;
    createdOn?: Date;
    lastUpdatedOn?: Date;
    creator?: User;
    documentName?: string; 
    steps: WorkFlowStep[];
    involvedPeople: WorkFlowApprover[];
    currentStepId?: number;
    isDocumentEditable?: boolean;
    ediFileId?: number;
    isDocumentAttachable?: boolean;
}

export type WorkFlowSplitByStatus = {
    status: WorkFlowRelativeStatus;
    count: number;
}

export type WorkflowLab = {
    id: number;
    name: string;
    description: string;
    currentUserRoleId: WorkFlowLabRoleId;
    workflowLabMasterId?:number;
}