import { IWorkflowOverviewPropsStyles, IWorkflowOverviewStyles } from "./workFlowOverview.types"
import { DetailsRowGlobalClassNames, IChoiceGroupStyles, IDetailsColumnStyleProps, IDetailsColumnStyles, IDetailsListStyleProps, IDetailsListStyles, IDetailsRowStyleProps, IDetailsRowStyles, ISearchBoxStyles, IStackStyles, IStyleFunction, ITooltipHostStyles } from "@fluentui/react";


export const WorkflowOverviewGlobalClassNames = {
    root: 'edi-work-flow-list-root',
    emptyList: 'edi-work-flow-list-emptyList',
    detailListContainer: 'edi-work-flow-list-container',
    centeredContainer: 'edi-work-flow-list-centered-container',
    workflowOverviewNameWrapper: 'edi-work-flow-list-workflow-list-name-wrapper',
    workflowOverviewNameWrapperTag: 'edi-work-flow-list-workflow-list-name-wrapper-tag',
    workflowRow: 'edi-work-flow-list-workflow-row',
    searchBoxStackItem: 'edi-work-flow-list-searchbox-stackitem',
    requestButtonStackItem: 'edi-work-flow-list-request-button-stackitem',
    dropDownFilters: 'edi-work-flow-list-filters-dropdown',
    pageTitle: 'edi-work-flow-list-page-title',
    pageTitleBox: 'edi-work-flow-list-page-title-box',
    searchBox: 'edi-work-flow-list-searchbox-stackitem',
    errorImage: 'edi-work-flow-image-error',
    groupHeader: 'edi-work-flow-groupHeader',
    selectionDisjoimted: 'edi-work-flow-selection-disjointed',
    groupAction:'edi-work-flow-groupAction',
}

export const getStyles: IStyleFunction<IWorkflowOverviewPropsStyles, IWorkflowOverviewStyles> = (props: IWorkflowOverviewPropsStyles): IWorkflowOverviewStyles => {
    const { palette, semanticColors } = props.theme!; // eslint-disable-line @typescript-eslint/no-non-null-assertion
    return {
        root: [
            WorkflowOverviewGlobalClassNames.root,
            {
                visibility: 'hidden'
            }
        ],
        load: {
            display: 'none'
        },
        groupHeader:[
            WorkflowOverviewGlobalClassNames.groupHeader,
            {
                minWidth: '200px !important',
                maxWidth: 200,
            }
        ],
        groupAction:[
            WorkflowOverviewGlobalClassNames.groupAction,
            {
                display: 'flex',
                width: '80%',
                justifyContent: 'flex-end',
                alignItems: 'center'
            }
        ],
        detailListContainer: [
            WorkflowOverviewGlobalClassNames.detailListContainer,
            {
                marginRight: '30px',
                marginTop: '15px',
                marginBottom: '15px',
                height: '100%',
                '> .ms-Viewport': {
                    height: '100%'
                }
            }],
        emptyList: [
            WorkflowOverviewGlobalClassNames.emptyList,
            {
                position: 'absolute',
                top: '60px',
                width: '300px',
                left: 'calc(50% - 150px)'
            }],
        activityName: {
            padding: '0 2px',
            color: palette.black,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            cursor: 'pointer',
            selectors: {
                ':hover': {
                    textDecoration: 'underline'
                }
            }
        },
        centeredContainer: [
            WorkflowOverviewGlobalClassNames.centeredContainer,
            {
                height: '85vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }
        ],
        workflowOverviewNameWrapper: [
            WorkflowOverviewGlobalClassNames.workflowOverviewNameWrapper,
            {
                position: 'relative',
                paddingRight: 30,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            }
        ],
        workflowOverviewNameWrapperTag: [
            WorkflowOverviewGlobalClassNames.workflowOverviewNameWrapperTag,
            {
                display: 'block !important',
                '> .ms-Label': {
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    textAlign: 'center'
                }                
            }
        ],
        selectionDisjoimted: [
            WorkflowOverviewGlobalClassNames.selectionDisjoimted,
            {
                color:'rgb(70, 78, 184)',
                textDecoration: 'underline'            
            }
        ],
        workflowRow: [
            WorkflowOverviewGlobalClassNames.workflowRow,
            {
                cursor: 'pointer',
                ':hover': {
                    textDecoration: 'underline'
                }
            }
        ],
        searchBoxStackItem: [
            WorkflowOverviewGlobalClassNames.searchBoxStackItem,
            {
                margin: '0 30px'
            }
        ],
        requestButtonStackItem: [
            WorkflowOverviewGlobalClassNames.requestButtonStackItem,
            {
                margin: '0 5px'
            }
        ],
        dropDownFilters: [
            {
                width: 150,            
            }
        ],
        pageTitleBox:[
            {
            margin: '0 30px'
            }
        ],
        pageTitle:[
            {
                fontSize: '20px'
        }
        ],
        yellowPersonaStyle: {
            backgroundColor: palette.yellowLight,
            color: palette.yellowDark,
        },
        greenPersonaStyle: {
            backgroundColor: palette.greenLight,
            color: semanticColors.bodyText,
        },
        menuIconWrapper: {
            position: 'absolute',
            top: 0,
            right: 0,
            height: '100%',
            color: 'inherit',
            visibility: 'hidden',
            [`.${DetailsRowGlobalClassNames.root}:hover &`]: {
                visibility: 'visible'
            }
        },
        deleteButtonContainer: {
            display: 'flex',
            alignItems: 'flex-end'
        },
        deleteFooterContainer: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        deleteErrorMessageStyle: {
            color: palette.redDark,
            fontSize: '12px'
        },
        errorImage: [
            WorkflowOverviewGlobalClassNames.errorImage,
            {
                width: '100%',
                display:'flex',
                flexDirection: 'column',
                alignItems: 'center'
            }
        ],
        subComponentStyles: {
            detailsList: (props: IDetailsListStyleProps): IDetailsListStyles => {
                return {
                    root: {
                        '& [role="grid"]': {
                            display: 'flex',
                            flexDirection: 'column',
                        }
                    },
                    headerWrapper: {
                        flex: '0 0 auto'
                    },
                    contentWrapper: {
                        flexGrow: '1',
                        flexShrink: '1',
                        flexBasis: 'calc(100vh - 350px)',
                        overflowY: 'auto',
                        overflowX: 'hidden',
                        '@media(max-width: 730px)': { // 798 - 68
                            overflowX: 'auto' // show horizontal scrollbar when list is not shrinkable anymore
                        }
                    },
                    focusZone: {},
                }
            },
            column: (props: IDetailsColumnStyleProps): IDetailsColumnStyles => {
                return {
                    root: {},
                    gripperBarVerticalStyle: {},
                    cellTooltip: {},
                    cellTitle: {
                        cursor: 'pointer'
                    },
                    cellName: {},
                    iconClassName: {},
                    nearIcon: {},
                    accessibleLabel: {},
                    sortIcon: {},
                    filterChevron: {},
                    borderAfterDropping: {},
                    noBorderAfterDropping: {},
                    borderWhileDragging: {},
                    noBorderWhileDragging: {},
                }
            },
            detailsRow: (props: IDetailsRowStyleProps): IDetailsRowStyles => {
                return {
                    root: {
                    },
                    cell: { alignSelf: 'center', textOverflow: 'unset', cursor: 'pointer' },
                    cellAnimation: {},
                    cellPadded: {},
                    cellMeasurer: {},
                    cellUnpadded: {},
                    check: {},
                    checkCell: {},
                    checkCover: {},
                    fields: {},
                    isMultiline: {},
                    isRowHeader: {},
                }
            },
            tooltipStyle: (): ITooltipHostStyles => {
                return {
                    root: {
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        color: palette.themePrimary,
                        cursor: 'context-menu'
                    }
                }
            },
            choiceGroupStyles: (): IChoiceGroupStyles => {
                return {
                    root: {
                    },
                    flexContainer: {
                        display: 'flex',
                        gap: '10px'
                    }
                }
            },
            searchBoxStyles: (): ISearchBoxStyles => {
                return {
                    root: {
                        width: '300px'
                    }
                }
            },
            searchAndSendStyles: (): IStackStyles => {
                return{
                    root: {
                        flexWrap: 'wrap',
                        alignItems: 'end'
                    }
                }
            },
            iconButton: {
                root: {
                    height: '100%',
                    float: 'right'
                },
                iconHovered: {
                    textShadow: `0 0 1px ${palette.black}`
                },
                menuIcon: {
                    display: 'none !important'
                },
                rootExpanded: {
                    backgroundColor: 'inherit'
                },
                rootPressed: {
                    backgroundColor: 'inherit'
                },
                rootHovered: {
                    backgroundColor: 'inherit',
                }
            },
            deleteDialogFooterContainer: {
                actions: {
                    margin: '8px 0 0'
                }
            },
            saveDialogFooterContainer: {
                actions: {
                    margin: '10px 0 0'
                }
            },
            deletePrimaryButtonDisabled: {
                root: {
                    selectors: {
                        ':disabled': {
                            backgroundColor: palette.neutralQuaternary,
                            color: palette.neutralTertiary,
                            pointerEvents: 'none'
                        },
                    }
                },
            }
        }
    };
}