import { Dropdown, IDropdownOption, IDropdownStyleProps, IDropdownStyles, IStyleFunctionOrObject } from "@fluentui/react";
import React, { useMemo } from "react";
import { useAsyncApi } from "../../../../../utilities/hooks";
import currentUser from "../../../../../modules/authentication/currentUser";
import { User } from "../../../../../models/User";
import { WorkFlowLabRoleId } from "../../../../../utilities/constants";
import { workflowLabApi } from "../../../../services/workFlowLab/workFlowLab.api";


export interface IMemberRoleDropdownProps {
    workflowLabId: number;
    member: User;
    totalCount: number;
    dropdownStyles: IStyleFunctionOrObject<IDropdownStyleProps, IDropdownStyles>;
    onRoleChangeCompleted: () => void;
}

export const AvailableRoles: IDropdownOption[] = [
    { key: WorkFlowLabRoleId.Owner, text: "Owner" },
    { key: WorkFlowLabRoleId.Supervisor, text: "Supervisor"},
    { key: WorkFlowLabRoleId.User, text: "User", selected: true },
    { key: WorkFlowLabRoleId.Auditor, text: "Auditor", disabled: true},
]

export const MemberRoleDropdown = (props: IMemberRoleDropdownProps) => {
    const { member, dropdownStyles, workflowLabId, onRoleChangeCompleted } = props;
    const disabled = useMemo(() => member.id === currentUser.currentUserId || member.workFlowLabRoleId === WorkFlowLabRoleId.Auditor,
    [member.id, member.workFlowLabRoleId]);

    const updateMemberRole = useAsyncApi<{ member: User, newRoledId: WorkFlowLabRoleId }, void>({
        func: async (request) => {
            await workflowLabApi.updateWorkflowLabMemberRole(workflowLabId, request.member.id, request.newRoledId);
        }
    });

    return (
        <Dropdown
            disabled={disabled || updateMemberRole.loading}
            styles={dropdownStyles}
            defaultSelectedKey={member.workFlowLabRoleId}
            options={AvailableRoles}
            onChange={async (_, option) => {
                if (!option)
                    return;

                await updateMemberRole.execute({ member, newRoledId: (option.key as WorkFlowLabRoleId) });

                if (!updateMemberRole.error)
                    onRoleChangeCompleted();
            }}
        />);
}