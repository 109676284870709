import { IStyle, IStyleFunctionOrObject, ITheme } from "@fluentui/react";
import { FileShort } from "../../../../models/fileShort";
import { WorkFlowDisjointment } from "../../../../models/WorkFlow";

export interface ICreateWorkflowProps {
    theme?: ITheme;
    styles?: IStyleFunctionOrObject<ICreateWorkflowPropsStyles, ICreateWorkflowStyles>;
    className?: string;
    onCloseClick?: () => void;
    onSuccess: () => void
    // ADD COMPONENT PROPS HERE
}

export interface ICreateWorkflowPropsStyles {
    theme?: ITheme;
    styles?: IStyleFunctionOrObject<ICreateWorkflowPropsStyles, ICreateWorkflowStyles>;
    className?: string;
    // ADD STYLE PROPS HERE FOR CONDITIONAL CSS GENERATION
}

export interface ICreateWorkflowStyles {
    root: IStyle;
    buttonsContainer: IStyle;
    requiredContainer: IStyle;
    requiredSign: IStyle;
}

export enum CreateWorkflowEnum {
    setProprietiesStep,
    loadTemplate,
    addDisjointmentLabelsStep,
    setFileSourceStep,
    localUploadStep,
    teamsUploadStep,
    ediUploadStep,
    addStepsStep,
    overviewStep,
    creatingStep
}

export enum FileSource {
    local = 0,
    edi = 1,
    teams = 2,
    sharepoint = 3
}

export type FirstPageData = {
    name: string;
    description?: string;
    isValid: boolean;
    expireDate?: Date;
    templateSelected?: number;
    disjointed?: boolean;
    isFileStepAttachable?: boolean;
}

export type AltSecondPageData = {
    disjointments: WorkFlowDisjointment[];
}

export type SecondPageData = {
    preSelectedFileUrl?: string;
    file: FileShort | undefined;
    isPdf: boolean;
}