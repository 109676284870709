import i18n, { Resource } from 'i18next';
import { initReactI18next } from 'react-i18next';
import { en } from './en/en';
import { en as nationsEN } from './en/nations';
import { it } from './it/it';
import { it as nationsIT } from './it/nations';

export const resources: Resource = {
  en: { ...en, ...nationsEN },
  it: { ...it, ...nationsIT }
} as const;

export const initI18Next = (locale?: string) => {
  i18n.use(initReactI18next).init({
    lng: locale,
    fallbackLng: "en",
    resources,
    debug: false
  });
}