/*eslint-disable sonarjs/cognitive-complexity */
/*eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useMemo, useState } from "react";
import { classNamesFunction, ActionButton, CommandBarButton, DefaultButton, Icon, IconButton, IIconProps, IStackTokens, Label, PrimaryButton, Spinner, SpinnerSize, Stack, StackItem, TextField, TooltipHost } from "@fluentui/react";
import { IWorkflowApprovalStepPropsStyles, IWorkflowApprovalStepStyles, IWorkflowApprovalStepProps } from "./workflowApprovalStep.types";
import { useTranslation } from "react-i18next";
import { workFlowStepApi } from "../../../../services/workflowstep/workflowstep.api";
import { ApproveWorkFlowStep, CheckStepDocumentNameRequest, ApproveWorkFlowStepType, UploadFileEnum } from "../../../../services/workflowstep/workflowstep.contracts";
import WorkflowApprovalModal from "../workflowApprovalModal/workflowApprovalModal";
import { ErrorsType } from "../../../../../common/types/ErrorsType";
import { WorkFlowStepTypes } from "../../../../../models/WorkFlow";
import FileIconCell from "../../../../../common/components/fileIconCell/fileIconCell";
import { Helpers } from "../../../../../utilities/helpers";
import ChoiceInputSource from "../../../workflowOverview/createWorkflow/choiceInputSource/choiceInputSource";
import MultiStepModal from "../../../../../common/components/multiStepModal/multiStepModal";
import LocalFile from "../../../workflowOverview/createWorkflow/localFile/localFile";
import TeamsFile from "../../../workflowOverview/createWorkflow/teamsFile/teamsFile";
import EdiFile from "../../../workflowOverview/createWorkflow/ediFile/ediFile";
import { FileSource } from "../../../workflowOverview/createWorkflow/createWorkflow.types";
import { SecondPageData } from "../../../workflowLabManageTemplate/createTemplateModal/createTemplateModal.types";
import { IModalStepData } from "../../../../../common/components/multiStepModal/multiStepModal.types";
import { Constants } from "../../../../../models/constants";
import EdiModal from "../../../../../common/components/ediModal/ediModal";

const getClassNames = classNamesFunction<IWorkflowApprovalStepPropsStyles, IWorkflowApprovalStepStyles>();

export const WorkflowApprovalStepBase = (props: IWorkflowApprovalStepProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const { palette } = props.theme!; //eslint-disable-line @typescript-eslint/no-non-null-assertion
    const { t } = useTranslation(['workflowApprovalStep', 'common']);
    const [description, setDescription] = useState('');
    const [showApprovalModal, setShowApprovalModal] = useState(false);
    const [showRejectModal, setShowRejectModal] = useState(false);
    const [errorMessageFooter, setErrorMessageFooter] = useState('');
    const [disableApproveButton, setDisableApproveButton] = useState(false)
    const [loading, setLoading] = useState(false);
    const currentStepDetail = props.steps.find(s => s.step.id === props.workflowStep.id);

    const [activeStep, setActiveStep] = useState<UploadFileEnum>(UploadFileEnum.setFileSourceStep);
    const [file, setFile] = useState<File[]>([]);
    const [fileValid, setFileValid] = useState(false);
    const [teamsPageData, setTeamsPageData] = useState<SecondPageData>({ isPdf: false, file: undefined });
    const [ediPageData, setEdiPageData] = useState<SecondPageData>({ isPdf: false, file: undefined });
    const [fileSource, setFileSource] = useState<FileSource | undefined>(undefined);
    const [fileSourceTemp, setFileSourceTemp] = useState<FileSource | undefined>(undefined);

    const [fileUpload, setFileUpload] = useState<string | undefined>(undefined);
    const [isUploadFile, setIsUploadFile] = useState(false);
    const [enableUploadFileButton, setEnableUploadFileButton] = useState(props.isDocumentAttachable);
    const [showUploadModal, setShowUploadModal] = useState(false);
    const uploadIcon: IIconProps = { iconName: 'Upload' };
    const cancelIcon: IIconProps = { iconName: 'Cancel' };

    const propertiesGap: IStackTokens = {
        childrenGap: 10,
        padding: 10,
    };

    const approveStep = async (approve: ApproveWorkFlowStepType) => {
        setLoading(true);
        setDisableApproveButton(true);
        try {
            const request: ApproveWorkFlowStep = {
                approve: approve,
                comments: description,
                isUploadFile: isUploadFile,
                fileSource: fileSource ?? undefined,
                teamId: teamsPageData.file?.teamId,
                teamFileId: teamsPageData.file?.uniqueId,
                ediFileId: ediPageData.file?.id,
            }
            await workFlowStepApi.ApproveWorkFlowStep(request, props.workflowStep.id, file[0]);
            setErrorMessageFooter('');
            setFileUpload(undefined);
            setIsUploadFile(false);
            setFileSource(undefined);
            setEnableUploadFileButton(false);
            setShowRejectModal(false);
            props.onReload();
        }
        catch (er: any) {
            const error: ErrorsType = er as ErrorsType;
            switch (error.code) {
                case 403: setErrorMessageFooter(t('notPermissions')); break;
                case 404: setErrorMessageFooter(t('notFound')); break;
                case 422: setErrorMessageFooter(t('unprocessable')); break;
                default: setErrorMessageFooter(t('common:genericErrorApi')); break;
            }
        }
        finally {
            setDisableApproveButton(false);
            setLoading(false);
        }
    }

    const checkWorkFlowStepDocumentName = async (document: string) => {
        const request: CheckStepDocumentNameRequest = {
            workflowStepId: props.workflowStep.id,
            documentName: document
        }
        const response : boolean = await workFlowStepApi.IsWorkFlowStepDocumentNameAvailable(request);

        if(!response)
        {
            setErrorMessageFooter(t('errorUploadFile'));
            setFileUpload(undefined);
            setIsUploadFile(false);
            setFileSource(undefined);
        }
        else
        {
            setErrorMessageFooter('');
            setFileUpload(document);
            setIsUploadFile(true);
        }
    }

    const nextUploadModal = (fileSource?: FileSource) => {
        if(activeStep === UploadFileEnum.setFileSourceStep && fileSource !== undefined) {
            if (fileSource === FileSource.local)
                return setActiveStep(UploadFileEnum.localUploadStep);

            if (fileSource === FileSource.teams)
                return setActiveStep(UploadFileEnum.teamsUploadStep);

            if (fileSource === FileSource.edi)
                return setActiveStep(UploadFileEnum.ediUploadStep);            
        }
    }

    const previousUploadModal = useCallback(() => {
        switch (activeStep) {
            case UploadFileEnum.setFileSourceStep:
                setShowUploadModal(false);
                return;

            case UploadFileEnum.localUploadStep:
            case UploadFileEnum.ediUploadStep:
            case UploadFileEnum.teamsUploadStep:
                return setActiveStep(UploadFileEnum.setFileSourceStep);
            
            default: return () => undefined;
        }
    }, [activeStep])

    const onUploadFile = useCallback(() => {
        setShowUploadModal(false);
        setActiveStep(UploadFileEnum.setFileSourceStep);
        setFileSource(fileSourceTemp);

        let document: string | undefined;
        switch(activeStep){
            case UploadFileEnum.localUploadStep:
                document = file[0]?.name;
                break;
            case UploadFileEnum.ediUploadStep:
                document = ediPageData.file?.name;
                break;
            case UploadFileEnum.teamsUploadStep:
                document = teamsPageData.file?.name;
                break;
        }

        checkWorkFlowStepDocumentName(document ?? '');
    }, [activeStep, file, teamsPageData.file, ediPageData]) //eslint-disable-line react-hooks/exhaustive-deps

    const footerUploadFile = useMemo(() => {
        return (
            <StackItem className={classNames.buttonsContainer}>
                <DefaultButton
                    text={t('common:previousButton')}
                    disabled={false}                    
                    onClick={() => previousUploadModal()}
                />
                <PrimaryButton
                    text={t('uploadFile')}
                    disabled={
                        (activeStep === UploadFileEnum.localUploadStep && !fileValid) ||
                        (activeStep === UploadFileEnum.teamsUploadStep && teamsPageData.file?.name === undefined) ||
                        (activeStep === UploadFileEnum.ediUploadStep && ediPageData.file === undefined)
                    }
                    onClick={(e) => {
                        e.stopPropagation();
                        onUploadFile();
                    }}
                />
            </StackItem>
        );
    }, [classNames.buttonsContainer, activeStep, onUploadFile, previousUploadModal, teamsPageData.file, ediPageData, fileValid, t])

    const footerInputSource = useMemo(() => {
        return (
            <StackItem className={classNames.buttonsContainer}>
                <PrimaryButton
                    text={t('common:close')}
                    disabled={false}                    
                    onClick={() => setShowUploadModal(false)}
                />
            </StackItem>
        );
    }, [classNames.buttonsContainer, t])

    const getMultiStepModal: IModalStepData[] = [
        {
            body: <ChoiceInputSource templateId={0} onElementChoice={(value) => { nextUploadModal(value); setFileSourceTemp(value); }} />,
            footer: footerInputSource
        },
        {
            body: <LocalFile onFileUploadMetadata={setFile} onValidateFile={setFileValid} onlyReadable={true} />,
            footer: footerUploadFile
        },
        {
            body: <TeamsFile initialState={teamsPageData} onWorkflowDataChange={setTeamsPageData} onlyReadable={true} />,
            footer: footerUploadFile
        },
        {
            body: <EdiFile initialState={ediPageData} onWorkflowDataChange={setEdiPageData} onlyReadable={true} />,
            footer: footerUploadFile
        },
    ];

    const rejectBody = (
        <div className={classNames.actionContainer}>
          {t("workflowApprovalStep:confirmReject")}
        </div>
      );
    
      const rejectFooter = (
        <div className={classNames.buttonsContainer}>
          <DefaultButton
            style={{ margin: '0px 4px' }}
            text={t("common:cancel")}
            onClick={() => {setShowRejectModal(false);}}
            disabled={loading}
          />
          <PrimaryButton 
            style={{ margin: '0px 4px' }} 
            disabled={description.trim().length === 0 || disableApproveButton}
            onClick={() => approveStep(ApproveWorkFlowStepType.Rejected)}>{t('common:continue')}
          </PrimaryButton>
        </div>
      );

    return (
        <>
            <Stack verticalAlign="center" tokens={propertiesGap} className={classNames.root}>
                <StackItem>
                    <Stack horizontal tokens={{ childrenGap: 10 }}>
                        <StackItem align="end" style={{ width: '100%' }}>
                            <StackItem align="end" className={classNames.requiredActionTitle}>
                                <span className={classNames.titleWrapper}>{`${t('title')}: ${props.workflowStep.name}`}</span>
                                <StackItem className={classNames.containerUpload}>
                                    <StackItem className={classNames.errorFileUpload}>{errorMessageFooter}</StackItem>
                                    {isUploadFile && <Label className={classNames.labelFileUpload}>{fileUpload}</Label>}
                                    {isUploadFile && <FileIconCell fileExtension={Helpers.getFileExtension(fileUpload ?? '')}/>}
                                    {isUploadFile &&
                                        <IconButton iconProps={cancelIcon} title={t('common:delete')} onClick={() => {setFileUpload(undefined); setIsUploadFile(false); setFileSource(undefined)}}/>}
                                    {enableUploadFileButton &&
                                        <CommandBarButton className={classNames.uploadFile} iconProps={uploadIcon} text={t('uploadFile')} onClick={() => {setShowUploadModal(true)}}/>}
                                </StackItem>
                            </StackItem>
                            <StackItem align="end" style={{ width: '100%', marginBottom: 5 }} >
                                {props.workflowStep.type === WorkFlowStepTypes.OnlyOneSignedApprover ? t('onlyOneSignedApproverDesctioption') : props.workflowStep.type === WorkFlowStepTypes.OnlyOneApprover ? t('onlyOneApproverDesctioption') : t('everyoneMustApproveApproverDesctioption')}
                            </StackItem>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                {t('secondDesctioption')}
                                <TooltipHost content={t('tooltipDescription')}>
                                    <Icon
                                        styles={{ root: { marginLeft: '5px', color: palette.black, paddingTop: 5, cursor: 'context-menu' } }}
                                        iconName="info"
                                    />
                                </TooltipHost>
                            </div>
                            <StackItem align="end" style={{ marginTop: 5, width: '100%' }}>
                                <TextField
                                    multiline
                                    placeholder={t('commentPlaceholder')}
                                    value={description}
                                    resizable={false}
                                    styles={{
                                        field: { color: palette.neutralPrimary, 'input': { '&::placeholder': { color: palette.neutralPrimary } } }
                                    }}
                                    style={{ minHeight: '50px', maxHeight: '50px' }}
                                    maxLength={Constants.MAX_APPROVAL_COMMENTS}
                                    onChange={(_, value) => { setDescription(value || '') }}
                                />
                            </StackItem>
                        </StackItem>
                    </Stack>
                </StackItem>

                <StackItem>
                    <Stack horizontal tokens={{ childrenGap: 10 }} >
                        <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'space-between' }}>
                            <div  className={classNames.actionContainer}>
                                <ActionButton
                                    className={description.trim().length === 0 || currentStepDetail?.prevStep === undefined || currentStepDetail?.prevStep.type === WorkFlowStepTypes.OnlyOneSignedApprover || disableApproveButton ? undefined : classNames.stepBackButton}
                                    disabled={description.trim().length === 0 || currentStepDetail?.prevStep === undefined || currentStepDetail?.prevStep.type === WorkFlowStepTypes.OnlyOneSignedApprover || disableApproveButton}
                                    onClick={() => approveStep(ApproveWorkFlowStepType.StepBack)}
                                >
                                    {t('stepBackButton')}
                                </ActionButton>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    {loading && <Spinner size={SpinnerSize.large} />}
                                    <TooltipHost content={t('rejectToolTip')}>
                                        <DefaultButton style={{ margin: '0px 4px' }} disabled={description.trim().length === 0 || disableApproveButton} onClick={() => setShowRejectModal(true)}>{t('reject')}</DefaultButton>
                                    </TooltipHost>
                                    <PrimaryButton disabled={disableApproveButton} onClick={() => { props.workflowStep.type === WorkFlowStepTypes.OnlyOneSignedApprover ? setShowApprovalModal(true) : approveStep(ApproveWorkFlowStepType.Approved) }} style={{ margin: '0px 4px' }} >{props.workflowStep.type === WorkFlowStepTypes.OnlyOneSignedApprover ? t('signedApprove') : t('approve')}</PrimaryButton>
                                </div>
                            </div>
                        </div>
                    </Stack>
                </StackItem>
            </Stack>

            {showUploadModal &&
            <MultiStepModal
                activeStep={activeStep}
                steps={getMultiStepModal}
                isOpen={true}
                width={650}
                height={750}
                showCloseIcon={true}
                onCloseClick={() => {setShowUploadModal(false); setActiveStep(UploadFileEnum.setFileSourceStep)}}
                title={t('titleUploadModal')}
                subtitle={t('subtitleUploadModal')}
            />}

            {showApprovalModal &&
            <WorkflowApprovalModal 
                onReload={() => props.onReload()}
                description={description}
                closeModal={() => setShowApprovalModal(false)}
                stepId={props.workflowStep.id}
                isUploadFile={isUploadFile}
                fileSource={fileSource}
                localData={file[0]}
                teamsData={teamsPageData}
                ediData={ediPageData}
            />}
    
            {showRejectModal &&
            <EdiModal
                isOpen={true}
                showCloseIcon={true}
                onCloseClick={() => setShowRejectModal(false)}
                title={t("workflowApprovalStep:reject")}
                body={rejectBody}
                footer={rejectFooter}
                width={400}
                height={255}
            />
        }
        </>
    )

}