/*eslint-disable sonarjs/cognitive-complexity */
import { ActionButton, ChoiceGroup, classNamesFunction, DefaultButton, DefaultPalette, DetailsList, DetailsRow, Dialog, DialogFooter, Dropdown, IChoiceGroupOption, IColumn, Icon, IconButton, IDropdownOption, IDropdownStyles, IGroup, IIconProps, Label, PrimaryButton, SearchBox, SelectionMode, Spinner, SpinnerSize, Stack, TooltipHost } from "@fluentui/react";
import { nanoid } from "@reduxjs/toolkit";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import IconTag from "../../../common/components/iconTag/iconTag";
import MemberList from "../../../common/components/memberList/memberList";
import TeamsImage from "../../../common/components/teamsImage/teamsImage";
import { ImageName } from "../../../common/components/teamsImage/teamsImage.types";
import { PersonaDetails } from "../../../common/models/PersonaDetails";
import { Call, callsList } from "../../../common/types/Call";
import { ErrorsType } from "../../../common/types/ErrorsType";
import { UserShort } from "../../../models/UserShort";
import { WorkFlowRelativeStatus, WorkflowViewType } from "../../../models/WorkflowEnum";
import { WorkflowShort } from "../../../models/WorkflowShort";
import currentUser from "../../../modules/authentication/currentUser";
import { WorkFlowLabRoleId } from "../../../utilities/constants";
import { Helpers } from "../../../utilities/helpers";
import { useOnMount } from "../../../utilities/hooks";
import { insertCall, setCall } from "../../features/callNotification";
import { resetFilters, selectChoiceGroup, setAuthError, updateFilters, useWorkflowList } from "../../features/workflowList";
import { useCurrentWorkflowLab } from "../../hooks/useCurrentWorkflowLab";
import { useNavigator } from "../../hooks/useNavigator";
import { workflowApi } from "../../services/workflow.api";
import { workflowDisjointedApi } from "../../services/workflowDisjointed/workflowDisjointed.api";
import { useWorkflowDispatch, useWorkflowState } from "../../workflowStore";
import CreateWorkflow from "./createWorkflow/createWorkflow";
import FiltersDropdown from "./filtersDropdown/filtersDropdown";
import WorkflowDisjointed from "./workflowDisjointed/workflowDisjointed";
import { IWorkflowOverviewProps, IWorkflowOverviewPropsStyles, IWorkflowOverviewStyles } from "./workFlowOverview.types";
import CopyFileOnEdiModal from "./copyOnEdiModal/CopyFileOnEdiModal";

const getClassNames = classNamesFunction<IWorkflowOverviewPropsStyles, IWorkflowOverviewStyles>();
const pageSize = 20;

export const WorkflowOverviewBase = (props: IWorkflowOverviewProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const { t } = useTranslation(['workflowOverview', 'common']);
    const [reload, setReload] = useState(false);
    const [deleteModalShown, setDeleteModalShown] = useState(false);
    const [copyOnEdiModalShown, setCopyOnEdiModalShown] = useState(false);
    const [createWorkflow, setCreateWorkflow] = useState(false);
    const addIcon: IIconProps = { iconName: 'Add', style: { height: 'auto' } };
    const [errorMessage, setErrorMessage] = useState<string>(''); //eslint-disable-line @typescript-eslint/no-unused-vars
    const [selectedWorkFlow, setSelectedWorkflow] = useState<WorkflowShort>();
    const [choiceDisjointed, setChoiceDisjointed] = useState<boolean>(false);
    const [listLoading, setListLoading] = useState(false);
    const [noMore, setNoMore] = useState(false);
    const [workFlows, setWorkFlows] = useState<WorkflowShort[]>([]);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [groups, setGroups] = useState<IGroup[]>();
    const [index, setIndex] = useState<number>(0);
    const navigator = useNavigator();
    const selectedWorkflowLab = useCurrentWorkflowLab();
    const filters = useWorkflowState().workflowList.filters;
    const dispatch = useWorkflowDispatch();
    const [genericError] = useState('common:genericErrorApi');
    const [authError] = useState('workflowOverview:notPermissions');
    const choiceGroupSelected = useWorkflowList();

    const options: IDropdownOption[] = [
        { key: WorkFlowRelativeStatus.Approved, text: t(`status.${WorkFlowRelativeStatus.Approved}`), selected: true },
        { key: WorkFlowRelativeStatus.Refused, text: t(`status.${WorkFlowRelativeStatus.Refused}`), selected: true },
        { key: WorkFlowRelativeStatus.Ongoing, text: t(`status.${WorkFlowRelativeStatus.Ongoing}`), selected: true },
        { key: WorkFlowRelativeStatus.ToMe, text: t(`status.${WorkFlowRelativeStatus.ToMe}`), selected: true }
    ];

    useOnMount(() => {
        registerEvent();
    });

    const registerEvent = useCallback(() => {
        const listElm = choiceDisjointed ? document.querySelector('#workFlowsListZoneDisjointed .ms-DetailsList-contentWrapper') : document.querySelector('#workFlowsListZone .ms-DetailsList-contentWrapper');
        listElm && listElm.addEventListener('scroll', () => {
            if (listElm.scrollTop + listElm.clientHeight >= listElm.scrollHeight - 50) {
                const button = document.getElementById("click");
                button && button.click();
            }
        });
    }, [choiceDisjointed]); //eslint-disable-line react-hooks/exhaustive-deps

    const loadMore = useCallback(() => {
        if (listLoading || noMore)
            return;

        dispatch(updateFilters({
            ...filters,
            pageNumber: filters.pageNumber + 1
        }));
    }, [listLoading, noMore]); //eslint-disable-line react-hooks/exhaustive-deps

    const errorFunc = (er: unknown) => {
        const error: ErrorsType = er as ErrorsType;
        if (error.code === 403) { setErrorMessage(t(authError)); dispatch(setAuthError(true)); }
        else { setErrorMessage(t(genericError)); }
    }

    const getWorkFlowList = async (loadMore?: boolean) => {
        if (!selectedWorkflowLab)
            return;

        setListLoading(true);
        setErrorMessage('');
        try {
            const [elements] =
                await Promise.all([
                    await workflowApi.getWorkflows({
                        ...filters,
                        workflowLabId: selectedWorkflowLab.id,
                        searchBy: filters.searchBy,
                        status: filters.status,
                        creationDateFrom: filters.creationDateFrom,
                        creationDateTo: filters.creationDateTo,
                        pageNumber: filters.pageNumber ?? 0,
                        isCreator: choiceGroupSelected.workflowLabSelectedChoiceGroup === WorkflowViewType.created,
                        isAscending: filters.isAscending,
                        orderBy: filters.orderBy,
                    })]);

            if (elements.length < pageSize) {
                setNoMore(true);
            }
            if (filters.pageNumber === 0) {
                setNoMore(false);
                setWorkFlows(elements);
            }
            else {
                setWorkFlows(workFlows.concat(elements));
            }
        }
        catch (er) {
            errorFunc(er);
        }
        finally {
            setListLoading(false);
        }
    }

    const getWorkFlowDisjointedList = async (loadMore?: boolean) => {
        if (!selectedWorkflowLab)
            return;

        setListLoading(true);
        setErrorMessage('');
        try {
            const [elements] =
                await Promise.all([
                    await workflowDisjointedApi.getWorkflowsDisjointed({
                        ...filters,
                        workflowLabId: selectedWorkflowLab.id,
                        searchBy: filters.searchBy,
                        status: filters.status,
                        creationDateFrom: filters.creationDateFrom,
                        creationDateTo: filters.creationDateTo,
                        pageNumber: filters.pageNumber ?? 0,
                        isCreator: choiceGroupSelected.workflowLabSelectedChoiceGroup === WorkflowViewType.created,
                        isAscending: filters.isAscending,
                        orderBy: filters.orderBy,
                        index: filters.pageNumber === 0 ? 0 : index
                    })]);

            setIndex(elements.finalIndex);
            if (elements.groupsInformations.length < pageSize) {
                setNoMore(true);
            }
            if (filters.pageNumber === 0) {
                setNoMore(false);
                setGroups(elements.groupsInformations)
                setWorkFlows(elements.workFlowsDisjointed);
            }
            else {
                setGroups(groups?.concat(elements.groupsInformations));
                setWorkFlows(workFlows.concat(elements.workFlowsDisjointed));
            }
        }
        catch (er) {
            errorFunc(er);
        }
        finally {
            setListLoading(false);
        }
    }

    const deleteWorkflow = async () => {
        setDeleteLoading(true);
        const deleteWorkflowCall: Call = {
            type: callsList.deleteWorkflow,
            nameOperation: t('deleteWorkflowOperation'),
            errors: [
                { code: 403, message: t('notPermissions') },
                { code: 500, message: t('common:deleteGenericErrorApi') }
            ]
        }
        const id = nanoid();
        const payload = { requestId: id, notification: deleteWorkflowCall }
        dispatch(insertCall(payload));

        try {
            await workflowApi.deleteWorkflow(selectedWorkFlow?.id ?? 0);
            const successPayload = { requestId: id, success: true, message: t('deletedWorkflow') }
            dispatch(setCall(successPayload));
            reloadList();
        }
        catch (error) {
            const failurePayload = { requestId: id, success: false, message: t(genericError) }
            dispatch(setCall(failurePayload));
        }
        finally {
            setDeleteModalShown(false);
            setDeleteLoading(false);
        }
    }

    const setDropDownStateValue = () => {
        const allFilters = [WorkFlowRelativeStatus.Approved, WorkFlowRelativeStatus.Ongoing, WorkFlowRelativeStatus.Refused, WorkFlowRelativeStatus.ToMe];
        dispatch(updateFilters({ ...filters, pageNumber: 0, status: allFilters }));
    }

    useEffect(() => {
        choiceDisjointed ? getWorkFlowDisjointedList() : getWorkFlowList();
    }, [filters, reload]); //eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        reloadList();
    }, [choiceDisjointed]); //eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (selectedWorkflowLab?.roleId === WorkFlowLabRoleId.Auditor)
            dispatch(selectChoiceGroup(WorkflowViewType.created));
        else
            dispatch(selectChoiceGroup(WorkflowViewType.received));
        reloadList();
    }, [selectedWorkflowLab]); //eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setDropDownStateValue();
    }, [selectedWorkflowLab?.id]); //eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (choiceDisjointed === true && choiceGroupSelected.workflowLabSelectedChoiceGroup === WorkflowViewType.received) {
            setChoiceDisjointed(false)
        } else {
            dispatch(updateFilters({
                ...filters,
                pageNumber: 0
            }));
        }
    }, [choiceGroupSelected.workflowLabSelectedChoiceGroup]); //eslint-disable-line react-hooks/exhaustive-deps

    const mapToPersonaDetails = (users: UserShort[] | null): PersonaDetails[] => {

        const filteredUser = users === null ? [] : users.filter(u => u !== null);
        return filteredUser.map(a => {
            return { firstName: a.firstName, lastName: a.lastName, email: '' } as PersonaDetails;
        });
    }

    const deleteDisjointed = (workFlow: WorkflowShort) => {
        setDeleteModalShown(true);
        setSelectedWorkflow(workFlow);
    }

    const reloadList = () => {
        setWorkFlows([]);
        dispatch(resetFilters());
        setReload(!reload);
    }

    const coolreloadList = () => {
        setWorkFlows([]);
        setReload(!reload);
    }

    useEffect(() => {
        if (!listLoading) registerEvent()
    }, [listLoading]); //eslint-disable-line react-hooks/exhaustive-deps

    const sortByColumn = useCallback((_: React.MouseEvent, column: IColumn) => {
        dispatch(updateFilters({
            ...filters,
            pageNumber: 0,
            orderBy: column.fieldName,
            isAscending: !filters.isAscending
        }));
    }, [dispatch, filters]);

    const sortByColumnDisjointed = (column: IColumn) => {
        console.log();
        dispatch(updateFilters({
            ...filters,
            pageNumber: 0,
            orderBy: column.fieldName,
            isAscending: !filters.isAscending
        }));
    }

    const columns = useMemo(() => {
        let result: IColumn[] = [
            {
                key: 'name',
                name: t('column.name'),
                ariaLabel: 'name',
                isResizable: true,
                fieldName: 'Name',
                minWidth: 100,
                maxWidth: 140,
                isCollapsible: true,
                isSorted: filters.orderBy === 'Name',
                isSortedDescending: !filters.isAscending,
                onColumnClick: (_, column) => sortByColumn(_, column),
                onRender: function getItemName(item: WorkflowShort) {
                    return <div className={classNames.workflowOverviewNameWrapper}>
                        {item.workflowDisjointedId ?
                            <Icon iconName={"Stack"} style={{ marginRight: 6 }} />
                            : null}
                        <span>{item.name}</span>
                    </div>
                }
            },
        ];

        if (choiceGroupSelected.workflowLabSelectedChoiceGroup === WorkflowViewType.created) {
            result = [...result, ...[{
                key: 'template',
                name: t('column.template'),
                ariaLabel: 'template',
                isResizable: true,
                fieldName: 'Template',
                minWidth: 100,
                maxWidth: 100,
                isCollapsible: true,
                onRender: function getItemName(item: WorkflowShort) {
                    return item.templateName ?
                        <TooltipHost content={item.templateName}>
                            <IconTag
                                label={item.templateName}
                                className={classNames.workflowOverviewNameWrapperTag}
                                hiddenIcon={true}
                                styles={{ root: { backgroundColor: props.theme?.palette['magenta'], maxHeight: 60, maxWidth: 120 } }}
                            />
                        </TooltipHost>
                        : <></>
                },
            }]];
        }

        const leftColumns: IColumn[] = [
            {
                key: 'status',
                name: t('column.status'),
                ariaLabel: 'status',
                fieldName: 'status',
                minWidth: 130,
                maxWidth: 130,
                isCollapsible: true,
                isResizable: true,
                onRender: function getItemType(item: WorkflowShort) {
                    return <div className={classNames.workflowOverviewNameWrapper}>
                        <span>{t(`status.${item.relativeStatus}`)}</span>
                    </div>
                }
            },
            {
                key: 'createdOn',
                name: t("column.createdOn"),
                ariaLabel: 'createdOn',
                fieldName: 'CreatedOn',
                minWidth: 100,
                maxWidth: 100,
                isResizable: true,
                isCollapsible: true,
                isSorted: filters.orderBy === 'CreatedOn',
                isSortedDescending: !filters.isAscending,
                onColumnClick: (_, column) => sortByColumn(_, column),
                onRender: function getItemType(item: WorkflowShort) {
                    return <span>{Helpers.getShortDate(item.createdOn)}</span>
                }
            },
            {
                key: 'requestedBy',
                name: t("column.requestedBy"),
                ariaLabel: 'requestedBy',
                fieldName: 'requestedBy',
                minWidth: 80,
                maxWidth: 80,
                isResizable: true,
                isCollapsible: true,
                onRender: function getItemDate(item: WorkflowShort) {
                    return <MemberList
                        memberRounded={true}
                        members={mapToPersonaDetails([item.creator])}
                        sliceLength={1}
                    />
                }
            },
            {
                key: 'assignedTo',
                name: t("column.assignedTo"),
                ariaLabel: 'assignedTo',
                fieldName: 'assignedTo',
                minWidth: 70,
                maxWidth: 70,
                isResizable: true,
                isCollapsible: true,
                onRender: function getItemFrom(item: WorkflowShort) {
                    return <MemberList
                        members={mapToPersonaDetails(item.assignedTo ?? null)}
                        sliceLength={1}
                        memberRounded={true}
                    />;
                }
            },
            {
                key: 'expirationDate',
                name: t("column.expirationDate"),
                ariaLabel: 'expirationDate',
                fieldName: 'ExpirationDate',
                minWidth: 100,
                maxWidth: 100,
                isResizable: true,
                isCollapsible: true,
                isSorted: filters.orderBy === 'ExpirationDate',
                isSortedDescending: !filters.isAscending,
                onColumnClick: (_, column) => sortByColumn(_, column),
                onRender: function getItemType(item: WorkflowShort) {
                    return <span>{Helpers.getShortDate(item.expirationDate ?? null)}</span>
                }
            },
            {
                key: 'delete',
                name: '',
                ariaLabel: 'delete',
                fieldName: 'Delete',
                minWidth: 20,
                maxWidth: 20,
                isResizable: true,
                isCollapsible: true,
                onRender: function getItemType(item: WorkflowShort) {
                    if (choiceGroupSelected.workflowLabSelectedChoiceGroup !== 'received' && item.creatorId === currentUser.currentUserId && selectedWorkflowLab?.roleId !== WorkFlowLabRoleId.Auditor)
                        return (
                            <IconButton
                                iconProps={{ iconName: "Delete" }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setDeleteModalShown(true)
                                        ; setSelectedWorkflow(item)
                                }}
                            />)

                }
            },
            {
                key: 'save',
                name: '',
                ariaLabel: 'save',
                fieldName: 'Save',
                minWidth: 20,
                maxWidth: 20,
                isResizable: true,
                isCollapsible: true,
                onRender: function getItemType(item: WorkflowShort) {
                    if (choiceGroupSelected.workflowLabSelectedChoiceGroup !== 'received' &&
                        item.relativeStatus === WorkFlowRelativeStatus.Approved &&
                        (item.creatorId === currentUser.currentUserId ||  selectedWorkflowLab?.roleId === WorkFlowLabRoleId.Owner) && !item.ediFileId &&
                        selectedWorkflowLab?.roleId !== WorkFlowLabRoleId.Auditor)
                        return (
                            <IconButton
                                iconProps={{ iconName: "Share" }}
                                text="Save to EDI"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setSelectedWorkflow(item);
                                    setCopyOnEdiModalShown(true);
                                }}
                            />
                        )

                }
            }
        ]

        return [...result, ...leftColumns];
    }, [choiceGroupSelected.workflowLabSelectedChoiceGroup, classNames, filters, sortByColumn, t, props.theme?.palette, selectedWorkflowLab?.roleId]);

    const emptyList = useCallback(() => {
        if (workFlows.length !== 0)
            return null;
        return (
            <div className={classNames.emptyList}>
                <TeamsImage styles={{ img: { width: '60%' } }} imageName={ImageName.EmptyFolderDrop} caption={t('common:emptyList')} />
            </div>
        )
    }, [workFlows.length, t, classNames.emptyList]);

    const choiceGroupOptions = (): IChoiceGroupOption[] => {
        const received = { key: WorkflowViewType.received, text: t('receivedRequests') };
        const sent = { key: WorkflowViewType.created, text: t('sentRequests') };
        let choices: IChoiceGroupOption[] = [];

        switch (selectedWorkflowLab?.roleId) {
            case WorkFlowLabRoleId.Owner:
            case WorkFlowLabRoleId.Supervisor:
                choices = [received, sent];
                break;
            case WorkFlowLabRoleId.User:
                choices = [received];
                break;
            case WorkFlowLabRoleId.Auditor:
                choices = [sent];
                break;
            default:
                choices = [received];
                break;
        }

        return choices;
    }

    const onChoiceGroupSelect = (option: IChoiceGroupOption | undefined): void => {
        if (option?.key !== undefined) dispatch(selectChoiceGroup(option?.key as WorkflowViewType));
    }

    const dropdownStyles: Partial<IDropdownStyles> = {
        dropdown: {
            width: 160,
            height: 33,
            backgroundColor: DefaultPalette.white
        },
        title: {
            fontWeight: 600,
            backgroundColor: DefaultPalette.white,
            border: '1px solid rgb(225, 223, 221)'
        },
        dropdownItemSelected: {
            backgroundColor: DefaultPalette.white
        }
    };

    return (
        <>
            {createWorkflow &&
                <CreateWorkflow
                    onCloseClick={() => setCreateWorkflow(false)}
                    onSuccess={() => { setCreateWorkflow(false); reloadList() }}
                />
            }
            <div className={classNames.pageTitleBox}>
                <h1 className={classNames.pageTitle}>{t('title')}</h1>
            </div>
            <Stack horizontal styles={classNames.subComponentStyles.searchAndSendStyles}>
                <Stack.Item className={classNames.searchBoxStackItem}>
                    <SearchBox
                        disabled={errorMessage === t(authError)}
                        styles={classNames.subComponentStyles.searchBoxStyles}
                        placeholder={t('search')}
                        onSearch={(newValue) => {
                            dispatch(updateFilters({
                                ...filters,
                                pageNumber: 0,
                                searchBy: newValue || ''
                            }));
                        }}
                        onClear={() => {
                            dispatch(updateFilters({
                                ...filters,
                                pageNumber: 0,
                                searchBy: ''
                            }));
                        }}
                    />
                </Stack.Item>

                {selectedWorkflowLab?.roleId !== WorkFlowLabRoleId.User &&
                    selectedWorkflowLab?.roleId !== WorkFlowLabRoleId.Auditor &&
                    <Stack.Item className={classNames.requestButtonStackItem}>
                        <PrimaryButton
                            text={t("workflowOverview:startRequest")}
                            iconProps={addIcon}
                            disabled={errorMessage === t(authError)}
                            onClick={() => setCreateWorkflow(true)}
                        />
                    </Stack.Item>
                }
                <div style={{ display: 'flex', justifyContent: 'flex-end', marginLeft: 'auto' }}>
                    <Stack.Item style={{ paddingLeft: 10, paddingRight: 10 }}>
                        <Label>{t('filterByCreationDate')}</Label>
                        <FiltersDropdown
                            disabled={errorMessage === t(authError)}
                            onReset={() => setWorkFlows([])}
                        />
                    </Stack.Item>

                    {selectedWorkflowLab?.roleId !== WorkFlowLabRoleId.Auditor &&
                        <Stack.Item style={{ paddingLeft: 10, paddingRight: 10 }}>
                            <Label>{t('filterByStatus')}</Label>
                            <Dropdown
                                disabled={errorMessage === t(authError)}
                                styles={dropdownStyles}
                                multiSelect={true}
                                options={options}
                                selectedKeys={filters.status}
                                onChange={(_, newValue) => {
                                    if (filters.status) {
                                        let result = [];
                                        if (newValue?.selected) {
                                            result = [...filters.status, newValue.key as WorkFlowRelativeStatus];
                                        }
                                        else {
                                            result = filters.status.filter(s => s !== newValue?.key as WorkFlowRelativeStatus);
                                        }
                                        dispatch(updateFilters({ ...filters, pageNumber: 0, status: result }));
                                    }
                                }}
                                placeholder={t('selectOptions')}
                            />
                        </Stack.Item>
                    }
                </div>
            </Stack>
            <div style={{ marginLeft: '30px', marginTop: '20px' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <ChoiceGroup
                        defaultSelectedKey={choiceGroupSelected.workflowLabSelectedChoiceGroup}
                        selectedKey={choiceGroupSelected.workflowLabSelectedChoiceGroup}
                        options={choiceGroupOptions()}
                        disabled={listLoading || errorMessage !== ''}
                        onChange={(_, option) => onChoiceGroupSelect(option)}
                        styles={classNames.subComponentStyles.choiceGroupStyles}
                    />
                    {choiceGroupSelected.workflowLabSelectedChoiceGroup === WorkflowViewType.created ?
                        <div style={{ marginRight: 20 }}>
                            <ActionButton
                                className={choiceDisjointed ? undefined : classNames.selectionDisjoimted}
                                disabled={false}
                                onClick={() => {
                                    setChoiceDisjointed(false);
                                }}
                            >
                                {t('seeAll')}
                            </ActionButton>
                            <span>|</span>
                            <ActionButton
                                className={choiceDisjointed ? classNames.selectionDisjoimted : undefined}
                                disabled={false}
                                onClick={() => {
                                    setChoiceDisjointed(true);
                                }}
                            >
                                {t('seeDisjointed')}
                            </ActionButton>
                        </div>
                        : null}
                </div>
                {(listLoading && workFlows.length === 0) || (listLoading && filters.pageNumber === 0) ?
                    <div className={classNames.centeredContainer}>
                        <Spinner size={SpinnerSize.large} />
                    </div>
                    : errorMessage !== '' ?
                        <div className={classNames.errorImage}>
                            {errorMessage === t(authError) ? <TeamsImage
                                style={{ width: '200px' }}
                                imageName={ImageName.Forbidden}
                                caption={errorMessage}
                            /> : <TeamsImage
                                imageName={ImageName.Error1}
                                style={{ width: '200px' }}
                                caption={errorMessage}
                            />}
                        </div>
                        : choiceDisjointed ?
                            <div className={classNames.detailListContainer} id={"workFlowsListZoneDisjointed"}>
                                <WorkflowDisjointed
                                    workflows={workFlows}
                                    groups={groups}
                                    deleteDisjointed={(workflow) => deleteDisjointed(workflow)}
                                    sortByColumnDisjointed={(column) => sortByColumnDisjointed(column)}
                                    reloadList={() => reloadList()}
                                />
                                <div className={classNames.load}>
                                    <PrimaryButton id={noMore ? "noClick" : "click"} onClick={loadMore} />
                                </div>
                            </div>
                            :
                            <div className={classNames.detailListContainer} id={"workFlowsListZone"}>
                                <div style={{ height: 'calc(100% - 10px)', overflow: 'overlay' }}>
                                    <DetailsList
                                        items={workFlows}
                                        columns={columns}
                                        selectionMode={SelectionMode.none}
                                        isHeaderVisible={true}
                                        getKey={((item: WorkflowShort) => item.id.toString())}
                                        setKey="none"
                                        styles={classNames.subComponentStyles.detailsList}
                                        onRenderRow={props => props ? (
                                            <div
                                                onClick={() => navigator.goToWorkflowDetails(props.item.id)}
                                                style={{ cursor: 'pointer' }}
                                            >
                                                <DetailsRow
                                                    {...props}
                                                    styles={classNames.subComponentStyles.detailsRow}
                                                />
                                            </div>
                                        ) : null}
                                        onRenderDetailsFooter={emptyList}
                                    />
                                </div>
                                <div className={classNames.load}>
                                    <PrimaryButton id={noMore ? "noClick" : "click"} onClick={loadMore} />
                                </div>
                            </div>
                }
        </div >
            { deleteModalShown &&
            <Dialog
                isOpen={deleteModalShown}
                dialogContentProps={{
                    title: t('deleteTitle'),
                    showCloseButton: !deleteLoading,
                    onDismiss: () => { setDeleteModalShown(false); },
                    subText: t('deleteMessage'),
                }}

            >
                {errorMessage && <span style={{ marginLeft: '55%' }}>{errorMessage}</span>}
                <div className={classNames.deleteFooterContainer}>
                    <div className={classNames.deleteButtonContainer}>
                        {!errorMessage && deleteLoading && <Spinner size={SpinnerSize.large}></Spinner>}
                        <DialogFooter styles={classNames.subComponentStyles.deleteDialogFooterContainer}>
                            <DefaultButton
                                text={t('common:undoEliminate')}
                                disabled={deleteLoading}
                                onClick={() => setDeleteModalShown(false)}
                            />
                            <PrimaryButton
                                onClick={() => deleteWorkflow()}
                                styles={classNames.subComponentStyles.deletePrimaryButtonDisabled()} text={t('common:confirmEliminate')}
                                disabled={deleteLoading}
                            />
                        </DialogFooter>
                    </div>
                </div>
            </Dialog>
},
    { copyOnEdiModalShown &&
        <CopyFileOnEdiModal
            initialState={{
                folder: 0
            }}
            selectedWorkfows={workFlows.filter(x => x.id === selectedWorkFlow?.id)}
            selectedDocuments={[]}
            reloadList={() => coolreloadList()}
            saveModalShown={copyOnEdiModalShown}
            closeModal={() => { setCopyOnEdiModalShown(false); }}
        />
            }
        </>
    );
}