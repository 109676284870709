import { styled } from "@fluentui/react";
import { PickerWrapperBase } from "./pickerWrapper.base";
import { getStyles } from "./pickerWrapper.style";
import { IPickerWrapperCustomProps, IPickerWrapperCustomPropsStyles, IPickerWrapperCustomStyles } from "./pickerWrapper.types";

const PickerWrapper = styled<IPickerWrapperCustomProps, IPickerWrapperCustomPropsStyles, IPickerWrapperCustomStyles>(
    PickerWrapperBase,
    getStyles,
    undefined,
    { scope: 'PickerWrapper' }
);

export default PickerWrapper;