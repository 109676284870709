/*eslint-disable sonarjs/cognitive-complexity */
/*eslint-disable sonarjs/no-identical-functions */
import { classNamesFunction, DetailsList, DetailsRow, IColumn, IconButton, PrimaryButton, SearchBox, SelectionMode, Spinner, SpinnerSize, Stack } from "@fluentui/react";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useBoolean, useConst } from '@fluentui/react-hooks';
import MemberList from "../../../../common/components/memberList/memberList";
import TeamsImage from "../../../../common/components/teamsImage/teamsImage";
import { ImageName } from "../../../../common/components/teamsImage/teamsImage.types";
import { ErrorsType } from "../../../../common/types/ErrorsType";
import { TemplateFilters} from "../../../../models/templateView";
import { WorkFlowTemplate } from "../../../../models/workflowTemplate";
import { Helpers } from "../../../../utilities/helpers";
import { useOnMount } from "../../../../utilities/hooks";
import { useCurrentWorkflowLab } from "../../../hooks/useCurrentWorkflowLab";
import { templateApi } from "../../../services/template.api";
import { mapToPersonaDetails } from "../../../utilities/workflowUtilities";
import CreateTemplateModal from "../createTemplateModal/createTemplateModal";
import { CreateTemplateModalEnum } from "../createTemplateModal/createTemplateModal.types";
import DeleteTemplateModal from "../deleteTemplateModal/deleteTemplateModal";
import { IWorkflowLabTemplatesSettingsProps, IWorkflowLabTemplatesSettingsPropsStyles, IWorkflowLabTemplatesSettingsStyles } from "./workflowLabTemplatesSettings.types";
import { cloneDeep } from "lodash";

const getClassNames = classNamesFunction<IWorkflowLabTemplatesSettingsPropsStyles, IWorkflowLabTemplatesSettingsStyles>();
const pageSize = 20;

export const WorkflowLabTemplatesSettingsBase = (props: IWorkflowLabTemplatesSettingsProps) => {
  const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
  const { t } = useTranslation(["workflowLabTemplateSettings", "templatesOverview","common"]);
  const currentworkflowLabId = useCurrentWorkflowLab()?.id //eslint-disable-line @typescript-eslint/no-non-null-assertion
  const [memberSearchKeyword, setMemberSearchKeyword] = useState('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const currentworkflowLab = useCurrentWorkflowLab(); //eslint-disable-line @typescript-eslint/no-non-null-assertion  
  const [reload, setReload] = useState(false);
  const [listLoading, setListLoading] = useState(false);
  const [currentTemplateLoading, setcurrentTemplateLoading] = useState<boolean | undefined>(undefined);
  const [noMore, setNoMore] = useState(false);
  const [overview, setOverview] = useState(false);
  const [templates, setTemplates] = useState<WorkFlowTemplate[]>([]);
  const [openingModalStep, setOpeningModalStep] = useState<CreateTemplateModalEnum>(CreateTemplateModalEnum.setProprietiesStep);
  const today = useConst(new Date(Date.now()));
  const defaultTemplate : WorkFlowTemplate = {id: 0, name: '', description: '', steps: [], workflowLabId: 0, createdOn: today, disjointed: false, labelsCategory: []};
  const [currentTemplate, setCurrentTemplate] = useState<WorkFlowTemplate>(defaultTemplate);
  const [filters, setFilters] = useState<TemplateFilters>({pageNumber: 0, pageSize: 20, orderBy: 'Name', isAscending: true, workflowLabId: currentworkflowLabId ?? 0, searchBy: undefined});

  useOnMount(() => {
    registerEvent();
  });

const getTemplatesList = async (keyword: string, loadMore?: boolean) => {
    if (!currentworkflowLab)
        return;

    setListLoading(true);
    setErrorMessage('');
    try {
        const [elements] =
            await Promise.all([
                await templateApi.getTemplates({ ...filters, 
                    workflowLabId: currentworkflowLab.id,
                    searchBy: keyword,
                    pageNumber: filters.pageNumber ?? 0,
                    isAscending: filters.isAscending,
                    orderBy: filters.orderBy,
                })]); 
                
        if (elements.length < pageSize) {
            setNoMore(true);
        }
        if (filters.pageNumber === 0) {
            setNoMore(false);
            setTemplates(elements);
        }
        else {
            setTemplates(templates.concat(elements));
        }
    }
    catch (er) {
        const error: ErrorsType = er as ErrorsType;
        if (error.code === 403) { setErrorMessage(t('notPermissions')); }
        else { setErrorMessage(t('common:genericErrorApi')); }
    }
    finally {
        setListLoading(false);
    }
}

const getTemplate = async (item : WorkFlowTemplate) => {
    if (!currentworkflowLab)
        return;
        setcurrentTemplateLoading(true);
    try
    {
        const res = await templateApi.getTemplate(item.id);
        setCurrentTemplate(cloneDeep(res));
    }
    catch
    {
        setErrorMessage('something went wrong')
    }
    finally
    {
        setcurrentTemplateLoading(false)
    }
}

const registerEvent = useCallback(() => {
    const listElm = document.querySelector('#TemplateListZone .ms-DetailsList-contentWrapper');
    listElm && listElm.addEventListener('scroll', () => {
        if (listElm.scrollTop + listElm.clientHeight >= listElm.scrollHeight - 50) {
            const button = document.getElementById("click");
            button && button.click();
        }
    });
}, []);

  const emptyList = useCallback(() => {
    if (templates.length !== 0)
        return null;
    return (
        <div className={classNames.emptyList}>
            <TeamsImage styles={{ img: { width: '60%' } }} imageName={ImageName.EmptyFolderDrop} caption={t('common:emptyList')} />
        </div>
    )
}, [templates.length, t, classNames.emptyList]);

  const loadMore = useCallback(()=> {
    if (listLoading || noMore)
        return;
        
        setFilters({...filters, pageNumber: (filters.pageNumber ?? 0) + 1})
}, [listLoading, noMore]); //eslint-disable-line react-hooks/exhaustive-deps

useEffect(() => {
    getTemplatesList('');
}, [filters, reload]); //eslint-disable-line react-hooks/exhaustive-deps

useEffect(() => {
    if (!listLoading) registerEvent()
}, [listLoading]); //eslint-disable-line react-hooks/exhaustive-deps

const reloadList = () => {
    setTemplates([]);
    setFilters({pageNumber: 0, pageSize: 20, orderBy: 'Name', isAscending: true, workflowLabId: currentworkflowLabId ?? 0, searchBy: undefined});
    setReload(!reload);
}

const setInfoData = (item : WorkFlowTemplate) => {
    setOverview(true);
    setOpeningModalStep(CreateTemplateModalEnum.overviewStep);
    showTemplateModal();
    getTemplate(item);   
}

const setEditData = (item : WorkFlowTemplate) => {
    setOverview(false);
    setOpeningModalStep(CreateTemplateModalEnum.setProprietiesStep);
    showTemplateModal();
    getTemplate(item);   
}

const [isTemplateModalOpen, { setTrue: showTemplateModal, setFalse: hideTemplateModal }] = useBoolean(false);

const columns: IColumn[] = [
    {
        key: 'name',
        name: t('templatesOverview:column.name'),
        ariaLabel: 'name',
        isResizable: true,
        fieldName: 'Name',
        minWidth: 160,
        maxWidth: 190,
        isCollapsible: true,
        onRender: function getItemName(item: WorkFlowTemplate) {
            return <div className={classNames.templateNameWrapper}>
                <span>{item.name}</span>
            </div>
        }
    },
    {
        key: 'description',
        name: t('templatesOverview:column.description'),
        ariaLabel: 'description',
        isResizable: true,
        fieldName: 'Description',
        minWidth: 170,
        maxWidth: 200,
        isCollapsible: true,
        onRender: function getItemName(item: WorkFlowTemplate) {
            return <span title={item.description}>{item.description}</span>
        }
    },
    {
        key: 'documentname',
        name: t('templatesOverview:column.documentName'),
        ariaLabel: 'documentname',
        isResizable: true,
        fieldName: 'DocumentName',
        minWidth: 170,
        maxWidth: 200,
        isCollapsible: true,
        onRender: function getItemName(item: WorkFlowTemplate) {
            return <span>{item.documentName}</span>
        }
    },            
    {
        key: 'createdBy',
        name: t("templatesOverview:column.createdBy"),
        ariaLabel: 'createdBy',
        fieldName: 'createdBy',
        minWidth: 80,
        maxWidth: 80,
        isResizable: true,
        isCollapsible: true,
        onRender: function getItemDate(item: WorkFlowTemplate) {
            if(item.creator) return <MemberList memberRounded={true} members={mapToPersonaDetails([item.creator])} sliceLength={1} />
        }
    },
    {
        key: 'createdOn',
        name: t("templatesOverview:column.createdOn"),
        ariaLabel: 'createdOn',
        fieldName: 'CreatedOn',
        minWidth: 110,
        maxWidth: 200,
        isResizable: true,
        isCollapsible: true,
        onRender: function getItemType(item: WorkFlowTemplate) {
            return <span>{Helpers.getShortDate(item.createdOn)}</span>
        }
    },
    {
        key: 'info',
        name: '',
        ariaLabel: 'info',
        fieldName: 'Info',
        minWidth: 20,
        maxWidth: 20,
        isResizable: true,
        isCollapsible: true,
        onRender: function getItemType(item: WorkFlowTemplate) {
            return (
                <IconButton
                    iconProps={{iconName: "Info"}}
                    onClick={() => {
                        setInfoData(item);
                    }
                }
              />)
        }
    },
    {
        key: 'edit',
        name: '',
        ariaLabel: 'edit',
        fieldName: 'Edit',
        minWidth: 20,
        maxWidth: 20,
        isResizable: true,
        isCollapsible: true,
        onRender: function getItemType(item: WorkFlowTemplate) {
            return (
              <IconButton
                iconProps={{iconName: "Edit"}}
                onClick={() => {
                        setEditData(item);
                    }
                }
              />)            
        }
    },
    {
        key: 'delete',
        name: '',
        ariaLabel: 'delete',
        fieldName: 'Delete',
        minWidth: 20,
        maxWidth: 20,
        isResizable: true,
        isCollapsible: true,
        onRender: function getItemType(item: WorkFlowTemplate) {
            return (
                <DeleteTemplateModal
                        Id={item.id}
                        onComplete={() => {
                            getTemplatesList('');
                            reloadList();}}
                    />
            )
        }
    },
];

  return (
    <React.Fragment>
      <Stack className={classNames.root}>
        <Stack horizontal>
          <Stack.Item style={{ marginRight: '30px' }}>
            <SearchBox
              key={currentworkflowLab?.id}
              placeholder={t("searchBox")}
              className={classNames.searchBox}
              onChange={(_, value) => setMemberSearchKeyword(value || '')}
               onSearch={() => {
                getTemplatesList(memberSearchKeyword);
                }}
                onClear={() => {
                getTemplatesList('');
                }}
              value={memberSearchKeyword}
            />
          </Stack.Item>
          <Stack.Item>
            <PrimaryButton
              iconProps={{ iconName: "Add" }}
              styles={classNames.subComponentStyles.addTemplatesButtonStyle()}
              text={t("addTemplates")}
              onClick={() => {
                    showTemplateModal();
                    setOverview(false);
                    setOpeningModalStep(CreateTemplateModalEnum.setProprietiesStep);
                    setCurrentTemplate(defaultTemplate);
                }
            }
            />
          </Stack.Item>
        </Stack>   
            {(listLoading && templates.length === 0) || (listLoading && filters.pageNumber === 0) ?
                    <div className={classNames.centeredContainer}>
                        <Spinner size={SpinnerSize.large} />
                    </div>
                    : errorMessage !== '' ?
                        <div className={classNames.centeredContainer}>
                            <TeamsImage
                                imageName={ImageName.Error1}
                                caption={errorMessage}
                            /> 
                        </div>
                        :
                        <div className={classNames.detailListContainer} id={"TemplateListZone"}>
                                    <div style={{ height: 'calc(100% - 10px)', overflow: 'overlay' }}>
                                        <DetailsList
                                            items={templates}
                                            columns={columns}
                                            selectionMode={SelectionMode.none}
                                            isHeaderVisible={true}
                                            getKey={((item: WorkFlowTemplate) => item.id.toString())}
                                            setKey="none"
                                            styles={classNames.subComponentStyles.detailsList}
                                            onRenderRow={props => props ? <DetailsRow {...props} styles={classNames.subComponentStyles.detailsRow} /> : null}
                                            onRenderDetailsFooter={emptyList}
                                        /> 
                                    </div>
                                    <div className={classNames.load}>
                                        <PrimaryButton id={noMore ? "noClick" : "click"} onClick={loadMore} /> 
                                    </div>                       
                        </div>
                }
        </Stack>

        {isTemplateModalOpen && 
            <CreateTemplateModal
                template={currentTemplate}
                modalStep={openingModalStep}
                onSuccess={() => {hideTemplateModal(); reloadList()}}
                onCloseClick={() => hideTemplateModal()}
                isOpen={isTemplateModalOpen}
                overview={overview}
                loadingInfo={currentTemplateLoading}
            />
        }
    </React.Fragment>
  );
}
