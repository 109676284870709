/*eslint-disable sonarjs/cognitive-complexity */
import React, { useState } from "react";
import { classNamesFunction, DefaultButton, IContextualMenuItem, IContextualMenuProps, Label, Stack } from "@fluentui/react";
import { useConst } from '@fluentui/react-hooks';
import _ from "lodash";
import { Doughnut } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { IGraphWorkflowStatusProps, IGraphWorkflowStatusPropsStyles, IGraphWorkflowStatusStyles } from "./graphWorkflowStatus.types";
import WorkflowCustomFilterModal from "../workflowCustomFilterModal/WorkflowCustomFilterModal";
import { useOnMount } from "../../../../utilities/hooks";
import { WorkFlowSplitByStatus } from "../../../../models/WorkFlow";
import { WorkFlowRelativeStatus } from "../../../../models/WorkflowEnum";
import { useWorkflowState } from "../../../workflowStore";
import { workflowApi } from "../../../services/workflow.api";
import TeamsSpinner from "../../../../common/components/teamsSpinner/teamsSpinner";
import { useCurrentWorkflowLab } from "../../../hooks/useCurrentWorkflowLab";
import { WorkFlowLabRoleId } from "../../../../utilities/constants";


const getClassNames = classNamesFunction<IGraphWorkflowStatusPropsStyles, IGraphWorkflowStatusStyles>();

export const GraphWorkflowStatusBase = (props: IGraphWorkflowStatusProps)  => { 
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className }); //eslint-disable-line @typescript-eslint/no-unused-vars
    const { palette, semanticColors } = props.theme!; // eslint-disable-line @typescript-eslint/no-non-null-assertion
    const { t } = useTranslation(['workflow', 'dashboard']);

    const [globalLoading, setGlobalLoading] = useState<boolean>(true);
    const [receivedWorkflows, setReceivedWorkflows] = useState<Array<WorkFlowSplitByStatus>>();
    const [sentWorkflows, setSentWorkflows] = useState<Array<WorkFlowSplitByStatus>>();

    const [showCustomFilterModal, setShowCustomFilterModal] = useState<boolean>(false);
    const [oldContextualButtonKey, setOldContextualButtonKey] = useState<string>('');
    const defaultTextKey='lastMonth';
    const [contextualButtonKey, setContextualButtonKey] = useState<string>(props.filterKey?props.filterKey:'filters.'+defaultTextKey);
    const [contextualButtonText, setContextualButtonText] = useState<string>(props.filterKey?t('filters.'+props.filterKey):t('filters.'+defaultTextKey));
    const lastMonthDate = new Date(new Date(Date.now()).setMonth(new Date(Date.now()).getMonth() - 1));
    const lastWeekDate = new Date(new Date(Date.now()).setDate(new Date(Date.now()).getDate() - 7));

    const today = useConst(new Date(Date.now()));
    const selectedWorkflowLab = useWorkflowState().workflowList.selectedWorkflowLab;
    const currentWorkflowLab = useCurrentWorkflowLab();

    useOnMount(() => {
        if (props) {
            setSentWorkflows(props.initialSentWorkflows);
            setReceivedWorkflows(props.initialReceivedWorkflows);
            if (contextualButtonKey === 'custom') {
                onFilter(props.oldFrom,props.oldTo)
            }
            else{
                onFilter(contextualButtonKey === 'lastMonth' ? lastMonthDate : contextualButtonKey === 'lastWeek' ? lastWeekDate : undefined, undefined);
            }
            setGlobalLoading(true);
        }
    });

    const onContextualClick = (item: IContextualMenuItem | undefined): void => {
        if (!item) return
        setOldContextualButtonKey(contextualButtonKey);
        setContextualButtonKey(item.key ?? '');
        setContextualButtonText(item.text ?? '');
        if (item.key === 'custom') {
            setShowCustomFilterModal(true);
            setGlobalLoading(true);
        } else 
            onFilter(item.key === 'lastMonth' ? lastMonthDate : item.key === 'lastWeek' ? lastWeekDate : undefined, undefined);
        props.setFilterKey(item.key??'');
    };

    const onModalCustomFilterDismiss = (): void => {
        //rieseguo il filtro sul vecchio
        setContextualButtonKey(oldContextualButtonKey);
        setContextualButtonText(t(`filters.${oldContextualButtonKey}`));
        onFilter(oldContextualButtonKey === 'lastMonth' ? lastMonthDate : oldContextualButtonKey === 'lastWeek' ? lastWeekDate : undefined, undefined);
        props.setFilterKey(defaultTextKey);
    };

    const onFilter = async (from: Date | undefined, to: Date | undefined): Promise<void> => {
        if (!globalLoading)
            setGlobalLoading(true);

        if (showCustomFilterModal)
            setShowCustomFilterModal(false);
    
        const toDate = (to ?? today).toISOString();
        const fromDate = (from ?? today).toISOString();
        if (!from && !to) {
            const result = await workflowApi.getWorkflowDashboard({workflowLabId: selectedWorkflowLab, searchBy: undefined});
            setReceivedWorkflows(result.workflowReceived);
            setSentWorkflows(result.workflowSent);
            setGlobalLoading(false);

            return;
        }
        if (!selectedWorkflowLab) {
            setGlobalLoading(false);
            return;
        }

        const result = await workflowApi.getWorkflowDashboard({workflowLabId: selectedWorkflowLab, searchBy: undefined, dateFrom: fromDate, dateTo: toDate });
        setReceivedWorkflows(result.workflowReceived);
        setSentWorkflows(result.workflowSent);
        setGlobalLoading(false);

    };


    const receivedWorkflowsData = {
        labels: [t(`status.${WorkFlowRelativeStatus.ToMe}`), t(`status.${WorkFlowRelativeStatus.Approved}`), t(`status.${WorkFlowRelativeStatus.Refused}`), t(`status.${WorkFlowRelativeStatus.Ongoing}`)],
        datasets: [
            {
                data: [
                    _.sum(receivedWorkflows?.filter(a => a.status === WorkFlowRelativeStatus.ToMe).map(r => r.count)),
                    _.sum(receivedWorkflows?.filter(a => a.status === WorkFlowRelativeStatus.Approved).map(r => r.count)),
                    _.sum(receivedWorkflows?.filter(a => a.status === WorkFlowRelativeStatus.Refused).map(r => r.count)),
                    _.sum(receivedWorkflows?.filter(a => a.status === WorkFlowRelativeStatus.Ongoing).map(r => r.count))
                ],
                backgroundColor: [palette.themePrimary, palette.green, palette.redDark, palette.yellow],
                borderColor: 'transparent',
                cutout: '70%'

            },
        ],
    };

    const sentWorkflowsData = {
        labels: [t(`status.${WorkFlowRelativeStatus.ToMe}`), t(`status.${WorkFlowRelativeStatus.Approved}`), t(`status.${WorkFlowRelativeStatus.Refused}`), t(`status.${WorkFlowRelativeStatus.Ongoing}`)],
        datasets: [
            {
                data: [
                    _.sum(sentWorkflows?.filter(a => a.status === WorkFlowRelativeStatus.ToMe).map(r => r.count)),
                    _.sum(sentWorkflows?.filter(a => a.status === WorkFlowRelativeStatus.Approved).map(r => r.count)),
                    _.sum(sentWorkflows?.filter(a => a.status === WorkFlowRelativeStatus.Refused).map(r => r.count)),
                    _.sum(sentWorkflows?.filter(a => a.status === WorkFlowRelativeStatus.Ongoing).map(r => r.count))
                ],
                backgroundColor: [palette.themePrimary, palette.green, palette.redDark, palette.yellow],
                borderColor: 'transparent',
                cutout: '70%'
            },
        ],
    };
    
    const sentWorkflowsDoughnutOptions = {
        maintainAspectRatio: false,
        responsive: true,
        layout: {
            padding: 0
        },
        plugins: {
            legend: {
                display: false,
                labels: {
                    color: semanticColors.bodyText
                },
            },
            title: {
                display: true,
                text: t('sentWorkflowRequests'),
                //color: '#808080',
                //color: semanticColors.bodyText
            },
        },

        tooltip: {
            callbacks: {
                afterLabel: (context: any) => { //eslint-disable-line @typescript-eslint/no-explicit-any
                    if (!sentWorkflows) return;
                    const dataIndex: number = context.dataIndex;
                    const workFlowRelativeStatus = dataIndex === 0 ? WorkFlowRelativeStatus.ToMe : dataIndex - 1 as WorkFlowRelativeStatus;

                    const sentWorkflowsCount = sentWorkflows.filter(a => a.status === workFlowRelativeStatus);
                    if (sentWorkflowsCount.length > 0)
                        return `${((sentWorkflowsCount[0].count / _.sum(sentWorkflows.map(s => s.count))) * 100).toFixed(2)}%`;
                }
            }
        },
    };
    
    const receivedWorkflowsDoughnutOptions = {
        maintainAspectRatio: false,
        responsive: true,
        layout: {
            padding: 0
        },
        plugins: {
            legend: {
                display: false,
                labels: {
                    color: semanticColors.bodyText
                }
            },
            title: {

                display: true,
                text: t('receivedWorkflowRequests'),
                color: semanticColors.bodyText,
                //color: '#808080',
                style: 'italic',
            },
        },
        tooltip: {
            callbacks: {
                afterLabel: (context: any) => { //eslint-disable-line @typescript-eslint/no-explicit-any                        
                    if (!receivedWorkflows) return;
                    const dataIndex: number = context.dataIndex;
                    const workFlowRelativeStatus = dataIndex === 0 ? WorkFlowRelativeStatus.ToMe : dataIndex - 1 as WorkFlowRelativeStatus;

                    const receivedWorkflowsCount = receivedWorkflows.filter(a => a.status === workFlowRelativeStatus);
                    if (receivedWorkflowsCount.length > 0)
                        return `${((receivedWorkflowsCount[0].count / _.sum(receivedWorkflows.map(r => r.count))) * 100).toFixed(2)}%`;
                }
            }
        }
    };

    const getContextualMenuProps: IContextualMenuProps = {
        items: [
            {
                key: 'all',
                text: t('filters.all'),
                onClick: (_, item) => onContextualClick(item)
            },
            {
                key: 'lastMonth',
                text: t('filters.lastMonth'),
                onClick: (_, item) => onContextualClick(item)
            },
            {
                key: 'lastWeek',
                text: t('filters.lastWeek'),
                onClick: (_, item) => onContextualClick(item)
            },
            {
                key: 'custom',
                text: t('filters.custom'),
                onClick: (_, item) => onContextualClick(item)
            },
        ]
    };

    const renderLegend = <div className={classNames.legendContainer}>
        <div className={classNames.legendItem}>
            <span className={classNames.legendCheck} style={{ backgroundColor: palette.themePrimary }}></span>
            <span>{t(`status.${WorkFlowRelativeStatus.ToMe}`)}</span>
        </div>
        <div className={classNames.legendItem}>
            <span className={classNames.legendCheck} style={{ backgroundColor: palette.green }}></span>
            <span>{t(`status.${WorkFlowRelativeStatus.Approved}`)}</span>
        </div>
        <div className={classNames.legendItem}>
            <span className={classNames.legendCheck} style={{ backgroundColor: palette.redDark }}></span>
            <span>{t(`status.${WorkFlowRelativeStatus.Refused}`)}</span>
        </div>
        <div className={classNames.legendItem}>
            <span className={classNames.legendCheck} style={{ backgroundColor: palette.yellow }}></span>
            <span>{t(`status.${WorkFlowRelativeStatus.Ongoing}`)}</span>
        </div>
    </div>;

    return (
        <React.Fragment>
        <Stack style={{ height: '100%', position: 'relative' }} horizontal tokens={{ childrenGap: 20, padding: 0 }}>
            <Stack.Item className={classNames.stackLeft}>
                {globalLoading && <TeamsSpinner />}

                {!globalLoading && receivedWorkflows && _.sum(receivedWorkflows.map(r => r.count)) > 0 &&
                    <React.Fragment>
                        <span className={classNames.doughnutTotal}>
                            {_.sum(receivedWorkflows.map(r => r.count))}
                            </span>
                        <Doughnut data={receivedWorkflowsData} options={receivedWorkflowsDoughnutOptions} />
                    </React.Fragment>
                }

                {!globalLoading && (!receivedWorkflows || _.sum(receivedWorkflows.map(r => r.count)) === 0) && 
                <Label className={classNames.emptyGraph}>{t('dashboard:empty')}</Label>}

            </Stack.Item>

            { (currentWorkflowLab?.roleId === WorkFlowLabRoleId.Owner || currentWorkflowLab?.roleId === WorkFlowLabRoleId.Supervisor) && <Stack.Item className={classNames.stackRight}>
                {globalLoading && <TeamsSpinner />}

                {!globalLoading && sentWorkflows && _.sum(sentWorkflows.map(s => s.count)) > 0 &&
                    <React.Fragment>
                        <span className={classNames.doughnutTotal}>{_.sum(sentWorkflows.map(s => s.count))}</span>
                        <Doughnut data={sentWorkflowsData} options={sentWorkflowsDoughnutOptions} />
                    </React.Fragment>
                }

                {!globalLoading && (!sentWorkflows || _.sum(sentWorkflows.map(s => s.count)) === 0) && <Label className={classNames.emptyGraph}>{t('dashboard:empty')}</Label>}
            </Stack.Item>}

            {currentWorkflowLab?.roleId === WorkFlowLabRoleId.User&& <Stack.Item className={classNames.stackRight}>
            <React.Fragment>
                        <span className={classNames.doughnutTotal}></span>
                    </React.Fragment>
            </Stack.Item>
            }
            
            <Stack.Item style={{ width: '50%', height: '100%' }}>
                {renderLegend}
            </Stack.Item>

            <Stack.Item className={classNames.filterContainer}>
                <span>{t('filters.filterCreationDate')}</span>
                <DefaultButton
                    id="filterButton"
                    text={contextualButtonText}
                    style={{ boxShadow: 'unset' }}
                    menuProps={getContextualMenuProps}
                />
            </Stack.Item>
        </Stack>
        {showCustomFilterModal && <WorkflowCustomFilterModal
            isOpen={showCustomFilterModal}
            // target={"filterButton"}
            onDismiss={() => onModalCustomFilterDismiss()}
            onFilter={(from, to) =>{
                onFilter(from, to)
                props.setOldFrom(from??new Date());
                props.setOldTo(to??new Date());
            }}
        />}
    </React.Fragment>
    );
} 