import { styled } from "@fluentui/react";
import { LabelsSelectorBase } from "./labelsSelector.base";
import { getStyles } from "./labelsSelector.styles";
import { ILabelsSelectorBaseProps, ILabelsSelectorBasePropsStyles, ILabelsSelectorBaseStyles } from "./labelsSelector.types";

const LabelsSelector = styled<ILabelsSelectorBaseProps, ILabelsSelectorBasePropsStyles, ILabelsSelectorBaseStyles>(
    LabelsSelectorBase, 
    getStyles,
    undefined,
    { scope: 'LabelsSelector'}
)

export default LabelsSelector;