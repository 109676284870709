import { styled } from "@fluentui/react";
import { getStyles } from "./workflowSummary.styles";
import { IWorkflowSummaryPropsStyles, IWorkflowSummaryStyles, IWorkflowSummaryProps } from "./workflowSummary.types";
import { WorkflowSummaryBase } from "./workflowSummary.base";

const WorkflowSummary = styled<IWorkflowSummaryProps, IWorkflowSummaryPropsStyles, IWorkflowSummaryStyles>(
    WorkflowSummaryBase,
    getStyles,
    undefined,
    { scope: 'WorkflowSummary' }
);

export default WorkflowSummary;