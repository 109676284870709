import React, { useState } from "react";
// eslint-disable-next-line react-hooks/exhaustive-deps
import { classNamesFunction, Icon, IconButton, IIconProps, Stack, StackItem } from "@fluentui/react";
import FileUploader from "../../../../../../common/components/fileUploader/fileUploader";
import TeamsImage from "../../../../../../common/components/teamsImage/teamsImage";
import { ImageName } from "../../../../../../common/components/teamsImage/teamsImage.types";
import { useTranslation } from "react-i18next";
import { IFirstPageProps, IFirstPagePropsStyles, IFirstPageStyles } from "./firstPage.types";
import { Helpers } from "../../../../../../utilities/helpers";
const getClassNames = classNamesFunction<IFirstPagePropsStyles, IFirstPageStyles>();

export const FirstPageBase = (props: IFirstPageProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const { t } = useTranslation(['uploadUserLabelModal', 'common']);
    const [fileAccepted, setFileAccepted] = useState(false);
    const enablefileTypes = ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"];
   
    const initialIconProps: IIconProps = {
        iconName: 'Cancel'
    }
    const isFileAccepted = (result?: boolean) => {
        if (result) {
            setFileAccepted(result);
            props.fileAccepted(false);
        }
        else {
            setFileAccepted(true);
            props.fileAccepted(true);
        }
    }

    const onFileUploadMetadata = (files: File[]) => {
        props.fileUploadedCallback(files);
        if (files[0]) {
            if (Helpers.convertToMB(files[0].size) > 100 || !enablefileTypes.includes(files[0].type)) {
                props.fileAccepted(true);

            } else {
                props.fileAccepted(false);
            }
        }
    }

    return (
        <div className={classNames.contentContainer}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ width: '90%' }}>
                    <FileUploader
                        showFileNameOnly={true}
                        removeBackground={true}
                        multiUpload={false}
                        showMessages={false}
                        showMessageError={fileAccepted}
                        isFileAccepted={isFileAccepted}
                        fileTypes={enablefileTypes}
                        isFileDraggable={false}
                        maxNumFilesUploadable={1}
                        labelToPrint={t('buttonForUploadFile')}
                        onFileUploadMetadata={onFileUploadMetadata}
                        isDisable={false}
                        multiple={false}
                        reset={props.reset}
                    >
                    </FileUploader>
                </div>
                {props.canRemovefile && <IconButton
                    styles={classNames.subComponentStyles.iconButton()}
                    onClick={() => {
                        props.fileAccepted(false); 
                        props.removeFile();
                    }}
                    iconProps={initialIconProps}
                />}
            </div>
            <Stack horizontal>
                <StackItem> <Icon iconName={'info'} className={classNames.iconResized} /></StackItem>
                <StackItem className={classNames.root}>
                    <Stack>
                        <StackItem>
                            <div style={{ marginTop: 15 }}>
                                <span>{t('firstPhrase')}</span>
                            </div>
                        </StackItem>
                        <StackItem>
                            <div style={{ marginTop: 5 }}>
                                <span>{t('secondPhrase')}</span>
                            </div>
                        </StackItem>
                        <StackItem>
                            <div style={{ marginTop: 5 }}>
                                <span>{t('thirdPhrase')}</span>
                            </div>
                        </StackItem>
                        <StackItem>
                            <div style={{ marginTop: 5 }}>
                                <span>{t('fourthPhrase')}</span>
                            </div>
                        </StackItem>
                        <StackItem>
                            <div style={{ marginTop: 5 }}>
                                <span>{t('firstColumnPhrase')}</span>
                            </div>
                        </StackItem>
                        <StackItem>
                            <div style={{ marginTop: 5 }}>
                                <span>{t('secondoColumnPhrase')}</span>
                            </div>
                        </StackItem>
                        <StackItem>
                            <div style={{ marginTop: 5 }}>
                                <span>{t('thirdColumnPhrase')}</span>
                            </div>
                        </StackItem>
                        <StackItem>
                            <div style={{ marginTop: 5 }}>
                                <span>{t('fourthColumnPhrase')}</span>
                            </div>
                        </StackItem>
                        <StackItem>
                            <div style={{ marginTop: 5 }}>
                                <span>{t('fifthColumnPhrase')}</span>
                            </div>
                        </StackItem>

                        <StackItem>
                            <TeamsImage
                                imageName={ImageName.UploadUsersLabels}
                                scale={0.5}
                                fullContainer
                                style={{
                                    marginTop: "10px",
                                    alignItems: "flex-start"
                                }}
                            />
                        </StackItem>
                    </Stack>
                </StackItem>
            </Stack>
        </div>
    );
}