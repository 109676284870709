import { classNamesFunction, DefaultButton, Dialog, DialogFooter, PrimaryButton } from "@fluentui/react";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import MultiStepModal from "../../../common/components/multiStepModal/multiStepModal";
import { IUser } from "../../../common/interfaces/IUser";
import { AddMembersPageType } from "../../../models/constants";
import { User } from "../../../models/User";
import { workflowApi } from "../../services/workflow.api";
import { AddMembersPage, MembersPageData } from "../common/modalPages/addMembersPage";
import { ICreateWorkflowLabModalProps, ICreateWorkflowLabModalPropsStyles, ICreateWorkflowLabModalStyles } from "./createWorkflowLabModal.types";
import { CreatingWorkflowLabPage } from "./pages/creatingWorkflowLabPage";
import { CreateWorkflowLabFirstPage, FirstPageData } from "./pages/firstPage";

enum CreateWorkflowLabStep {
    setName = 0,
    addMembers = 1,
    creatingWorkflowLab = 2
}

const getClassNames = classNamesFunction<ICreateWorkflowLabModalPropsStyles, ICreateWorkflowLabModalStyles>();

export const CreateWorkflowLabModalBase = (props: ICreateWorkflowLabModalProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const [currentStep, setCurrentStep] = useState(CreateWorkflowLabStep.setName);
    const [isMutating, setIsMutating] = useState(false);
    const [firstPageData, setFirstPageData] = useState<FirstPageData>({ name: "", isValid: false});
    const [secondPageData, setSecondPageData] = useState<MembersPageData>({ selectedPeople: [], isValid: false });
    const [showDialog, setShowDialog] = useState(false);
    const { t } = useTranslation(['createWorkflowLabModal', 'common']);

    const firstPageFooter = useMemo(() => {
        return (
            <>
                <span></span>
                <PrimaryButton
                    text={t('common:nextButton')}
                    disabled={isMutating || !firstPageData.isValid}
                    onClick={() => setCurrentStep(CreateWorkflowLabStep.addMembers)}
                />
            </>
        );
    }, [firstPageData.isValid, t, isMutating]);

    const secondPageFooter = useMemo(() => (
        <>
            <label
                onClick={() => !isMutating && setCurrentStep(CreateWorkflowLabStep.setName)}
                style={{ cursor: 'pointer' }}
            >
                {t('common:previousButton')}
            </label>
            <PrimaryButton
                text={t('common:nextButton')}
                disabled={isMutating|| !secondPageData.isValid}
                onClick={() => { setCurrentStep(CreateWorkflowLabStep.creatingWorkflowLab); }}
            />
        </>
    ), [secondPageData.isValid, t, isMutating]);

    const getUsers = async (keyword: string, currentUsers: IUser[]) => {
        if (keyword.length > 2) {
            return await workflowApi.getUsers({
                pageNumber: 0,
                pageSize: 20,
                keyword: keyword,
                userIds: currentUsers.map(x => x.id)
            });
        }
        return []
    }

    const steps = [
        {
            title: `${t('firstStepTitle')}`,
            body: <CreateWorkflowLabFirstPage theme={props.theme} onWorkflowLabDataChange={setFirstPageData} initialState={firstPageData} />,
            footer: firstPageFooter
        },
        {
            title: `${t('secondStepTitle')} "${firstPageData.name}"`,
            body: <AddMembersPage onDataChange={setSecondPageData} initialState={secondPageData} 
            getUsers={(keyword: string) => getUsers(keyword, secondPageData.selectedPeople)}
            translationNamespace="membersPageForCreateWorkflowLab" modalType={AddMembersPageType.WorkflowLab} enableRoleSelection={true} />,
            footer: secondPageFooter
        },
        {
            bodyOnly: true,
            body: <CreatingWorkflowLabPage onClose={props.onClose} initialState={
                { name: firstPageData.name, members: secondPageData.selectedPeople as User[] }
            } />
        },
    ]

    return (
        <>
            <MultiStepModal
                styles={classNames.subComponentStyles.ediModal}
                isOpen={true}
                width={600}
                height={600}
                showCloseIcon={currentStep !== CreateWorkflowLabStep.creatingWorkflowLab}
                onCloseClick={() => currentStep === CreateWorkflowLabStep.addMembers ? setShowDialog(true) : props.onClose && props.onClose()}
                activeStep={currentStep}
                steps={steps}
                onStepChange={setIsMutating}
                animateInitialStep
            />
            <Dialog
                hidden={!showDialog}
                dialogContentProps={{ title: `${t('stopCreating')}` }}
            >
                <DialogFooter>
                    <DefaultButton onClick={() => setShowDialog(false)} text={t('common:dialogButtonCancel')} />
                    <PrimaryButton onClick={props.onClose} text={t('common:dialogButtonConfirm')} />
                </DialogFooter>
            </Dialog>
        </>
    );
}