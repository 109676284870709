import { IStyleFunction } from "@fluentui/react"
import { IRenameWorkflowLabModalPropsStyles, IRenameWorkflowLabModalStyles } from "./renameWorkflowLabModal.types"

export const getStyles: IStyleFunction<IRenameWorkflowLabModalPropsStyles, IRenameWorkflowLabModalStyles> = (props: IRenameWorkflowLabModalPropsStyles): IRenameWorkflowLabModalStyles => {
    const { palette } = props.theme!; //eslint-disable-line @typescript-eslint/no-non-null-assertion
    return {
        root: {
        },
        innerContent: {
            display: 'flex',
            alignItems: 'baseline',
            flexDirection: 'column'
        },
        firstFooterContainer: {
            display: 'flex',
            justifyContent: 'flex-end',
        },
        errorMessage: {
            color: palette.redDark,
            fontSize: '12px',
            height: '20px'
        },
        subComponentStyles: {
            textField: {
                errorMessage: {
                    position: 'absolute'
                },                
                root: {
                    minHeight: '55px',
                    overflow: 'hidden',
                    width: '100%'
                }
            }
        }
    }
}