import { classNamesFunction, DefaultButton, Dialog, DialogFooter, IconButton, PrimaryButton, Spinner, SpinnerSize, Stack } from "@fluentui/react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ErrorsType } from "../../../../../../common/types/ErrorsType";
import { User } from "../../../../../../models/User";
import { WorkFlowLabRoleId } from "../../../../../../utilities/constants";
import { useAsyncApi } from "../../../../../../utilities/hooks";
import { workflowLabApi } from "../../../../../services/workFlowLab/workFlowLab.api";
import authService from "../../../../../../modules/authentication/teamsAuthService";
import { IRemoveMemberButtonProps, IRemoveMemberButtonPropsStyles, IRemoveMemberButtonStyles } from "./removeMemberButton.types";

const getClassNames = classNamesFunction<IRemoveMemberButtonPropsStyles, IRemoveMemberButtonStyles>();

export const RemoveMemberButtonBase = (props: IRemoveMemberButtonProps) => {
    const classNames = getClassNames(props.styles, 
        { 
            theme: props.theme, 
            className: props.className });
            
    const { workflowLabId, member, buttonStyles, onRemoveMemberCompleted } = props;
    const { t } = useTranslation('workflowLabUsersSettings');
    const [deleteModalShown, setDeleteModalShown] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const removeMember = useAsyncApi({
        func: async (member: User) => {
            setDeleting(true);
            try {
                await workflowLabApi.deleteMember(workflowLabId, member.id);
                setDeleting(false);
            } catch (er) {
                const error: ErrorsType = er as ErrorsType;
                if (error.code === 422) {
                    setErrorMessage(t('errorRemoveMembers'));
                }
            }
            if (errorMessage === '')
                onRemoveMemberCompleted();
        }
    });

    if (member.workFlowLabRoleId === WorkFlowLabRoleId.Owner)
        return null;

    const disabled = member.id === authService.currentUserId;

    return (
        <div>
            <Stack horizontal verticalAlign={'baseline'}>
                {/* {member.hasActivities
                    ? <TooltipHost content={t('errorRemoveMembers')} delay={TooltipDelay.zero}>
                        <IconButton
                            disabled
                            styles={buttonStyles}
                            iconProps={{ iconName: "Cancel" }}
                        />
                    </TooltipHost> */}
                    {/* : */}
                     <IconButton
                        disabled={disabled || removeMember.loading}
                        styles={buttonStyles}
                        iconProps={{ iconName: "Cancel" }}
                        onClick={() => setDeleteModalShown(true)}                    
                    />
                    
            </Stack>
            {deleteModalShown && <Dialog
                isOpen={deleteModalShown}
                dialogContentProps={{
                    title: t('deleteTitle'),
                    showCloseButton: true,
                    onDismiss: () => { setDeleteModalShown(false);},
                    subText: t('deleteMessage'),
                }}
            >
                <div className={classNames.deleteFooterContainer}>
                    <div className={classNames.deleteButtonContainer}>
                        {!errorMessage && deleting && <Spinner size={SpinnerSize.large}></Spinner>}
                        <DialogFooter styles={classNames.subComponentStyles.deleteDialogFooterContainer}>
                            <DefaultButton disabled={deleting} onClick={() => setDeleteModalShown(false)} text={t('undoEliminate')} />
                            <PrimaryButton disabled={deleting} styles={classNames.subComponentStyles.deletePrimaryButtonDisabled()} onClick={() => removeMember.execute(member)} text={t('confirmEliminate')} />
                        </DialogFooter>
                    </div>
                </div>
            </Dialog>}
        </div>
    );
}

