/* eslint-disable sonarjs/cognitive-complexity */
import React, { useEffect, useState } from "react";
import { classNamesFunction, DetailsList, DetailsRow, FontIcon, IColumn, Icon, IconButton, MessageBar, MessageBarType, SelectionMode, Stack, StackItem, TooltipHost } from "@fluentui/react";
import { IFileUploaderPropsStyles, IFileUploaderStyles, IFileUploaderProps, IFileToUpload, UploadStatus, UploadErrors } from "./fileUploader.types";
import Dropzone, { IFileWithMeta, StatusValue } from "react-dropzone-uploader";
import UploadButton from "../uploadButton/uploadButton";
import FileIconCell from "../fileIconCell/fileIconCell";
import { Helpers } from "../../../utilities/helpers";
import { useTranslation } from "react-i18next";
import { useOnMount } from "../../../utilities/hooks";
import _ from "lodash";

const getClassNames = classNamesFunction<IFileUploaderPropsStyles, IFileUploaderStyles>();

export const FileUploaderBase = (props: IFileUploaderProps) => {

    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const [pickerErrorMessage, setPickerErrorMessage] = useState("");//eslint-disable-line @typescript-eslint/no-unused-vars
    const [loading, setLoading] = useState<boolean>(false); //eslint-disable-line @typescript-eslint/no-unused-vars
    const [items, setItems] = useState<IFileToUpload[]>([]);
    const [tempItems, setTempItems] = useState<IFileToUpload[]>([]);
    const [countFilesLoaded, setCountFilesLoaded] = useState<number>(0);
    const { palette } = props.theme!; //eslint-disable-line @typescript-eslint/no-non-null-assertion
    const { t } = useTranslation(['uploadFileModal', 'common']);
    const labelToPrint = props.labelToPrint ? props.labelToPrint : t('dropzone.button');

    useEffect(() => {
        if (props.onFileUploadMetadata) {
            props.onFileUploadMetadata(items.map(item => item.file as File));
        } 
    }, [items, props.onFileUploadMetadata]) //eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (props.reset === true) {
            setItems([]);
            setTempItems([]);
        }
    }, [props.reset]) //eslint-disable-line react-hooks/exhaustive-deps


    const mapToUploadFile = (inputFile: File): IFileToUpload => {
        return {
            fileName: inputFile.name,
            fileType: inputFile.type,
            fileSize: inputFile.size,
            fileUploadStatus: UploadStatus.ToUpload,
            file: inputFile,
            isValid: true,
            id: _.uniqueId(),
        };
    }

    const removeFile = (item: IFileToUpload) => {
        const copyItems = items.slice()
        const index = _.findIndex(copyItems, ['id', item.id]);
        if (index !== -1) {
            item.isValid = false;
            copyItems[index] = item;
            setItems(copyItems);
            if (item.fileUploadStatus === UploadStatus.Error) {
                setCountFilesLoaded(countFilesLoaded => countFilesLoaded - 1);
            }
        }
        else {
            const copyTempItems = tempItems.slice()
            const index = _.findIndex(copyTempItems, ['id', item.id]);
            item.isValid = false;
            copyTempItems[index] = item;
            setTempItems(copyTempItems);
        }

        if (props.onFileUpdated)
            props.onFileUpdated(items.filter(x => x.id !== item.id));

        if (props.onFileUploadMetadata) {
            const newItems = items.filter(x => x.id !== item.id)
            setItems(newItems)
            props.onFileUploadMetadata(newItems.map(x => x.file as File))
        }

    }

    const addFiles = (inputFiles: File[]) => {
        if (!props.multiple) {
            setItems([]);
            setTimeout(() => {
                const fileToUpload = mapToUploadFile(inputFiles[0]);
                setItems([fileToUpload]);
            }, 10);
        }
        else {
            if (loading) {
                const newTempItems = tempItems.slice()
                _.forEach(inputFiles, function (file, index) {
                    newTempItems.push(
                        mapToUploadFile(file));
                });

                setTempItems(newTempItems);
            }
            else {
                const newItems = items.slice()
                _.forEach(inputFiles, function (file) {
                    newItems.push(
                        mapToUploadFile(file));
                });
                
                setItems(newItems);
            }
        }
    }

    useEffect(() => {
        if (loading === false && tempItems.length > 0) {
            const newItems = items.slice()
            setItems(newItems.concat(tempItems));
            setTempItems([]);
        }
    }, [loading]); //eslint-disable-line react-hooks/exhaustive-deps


    const iconRender = (item: IFileToUpload) => {   
        if (Helpers.convertToMB(item.fileSize) > 100) {
            return (
                <TooltipHost content={t(`${UploadErrors.TooLarge}`)} id={item.id} styles={{ root: { display: 'flex', alignItems: 'center' } }}>
                    <FontIcon iconName="StatusErrorFull" aria-describedby={item.id} style={{ fontSize: 16, color: palette.red, cursor: 'help' }} />
                </TooltipHost>
            )
        }
        if (!props.fileTypes.includes(item.fileType)) {
            return (
                <TooltipHost content={t(`${UploadErrors.MimeTypeNotAllowed}`)} id={item.id} styles={{ root: { display: 'flex', alignItems: 'center' } }}>
                    <FontIcon iconName="StatusErrorFull" aria-describedby={item.id} style={{ fontSize: 16, color: palette.red, cursor: 'help' }} />
                </TooltipHost>
            )
        }

        return <FontIcon iconName="CompletedSolid" style={{ fontSize: 16, color: palette.green }} />;
    }

    const getColumns = (): IColumn[] => {
        let valueToReturn: IColumn[] =
            [
                {
                    key: 'column1',
                    name: 'fileStatus',
                    minWidth: 10,
                    maxWidth: 10,
                    onRender: function columnRender(item: IFileToUpload) {                        
                        return iconRender(item)
                    }
                },
                {
                    key: 'column2',
                    name: 'fileType',
                    minWidth: 20,
                    maxWidth: 20,
                    onRender: function columnRender(item: IFileToUpload) {
                        const extIndex = item.fileName.lastIndexOf('.');
                        const ext = extIndex !== -1 ? item.fileName.substring(extIndex) : "";
                        return <FileIconCell fileExtension={ext} />
                    },
                },
                {
                    key: 'column3',
                    name: 'fileName',
                    isResizable: true,
                    minWidth: 170,
                    maxWidth: 190,
                    onRender: function columnRender(item: IFileToUpload) {
                        return <span>{item.fileName}</span>
                    },
                }
            ];

        if (!props.showFileNameOnly)
            valueToReturn = valueToReturn.concat(
                [
                    {
                        key: 'column4',
                        name: 'fileSize',
                        minWidth: 60,
                        maxWidth: 60,
                        onRender: function columnRender(item: IFileToUpload) {
                            return <span>{Helpers.formatSize(item.fileSize)}</span>
                        },
                    },
                    {
                        key: 'column0',
                        name: 'deleteFile',
                        minWidth: 10,
                        maxWidth: 10,
                        onRender: function columnRender(item: IFileToUpload) {
                            return (
                                (item.fileUploadStatus === UploadStatus.Error || item.fileUploadStatus === UploadStatus.ToUpload) &&
                                <div style={{ textAlign: 'center' }}>
                                    <IconButton
                                        iconProps={{ iconName: "Cancel" }}
                                        style={{ height: 18, color: palette.black }}
                                        onClick={() => removeFile(item)}
                                    />
                                </div>
                            )
                        },
                    }
                ]
            );

        if (props.disableUpdatedIcon) {
            valueToReturn = valueToReturn.filter(x => x.name !== 'fileStatus')
        }

        return valueToReturn;
    };

    const dropzoneContent = (suffix: string) => (
        <div key={`content_${suffix}`} className={classNames.dropzoneContent}>
            <Icon iconName="CloudUpload" style={{ fontSize: 80 }} />
            <span>{t('dropzone.msg1')}</span>
            <span>{t('dropzone.msg2')}</span>
            <UploadButton
                acceptedFile={props.fileTypes}
                isDisabled={props.isDisable || items.length > props.maxNumFilesUploadable - 1}
                uploadFile={(files: File[]) => addFiles(files)}
                label={labelToPrint}
                multiple={props.multiple}
            />
        </div>
    );

    useOnMount(() => {
        // Per caricare i documenti al rendering del componente
        const selectedItem = props.fileOnInit;
        if (selectedItem) {
            addFiles([selectedItem]);
        }
    });

    const handleChangeStatus = (meta: IFileWithMeta, status: StatusValue, files: IFileWithMeta[]) => {
        if (status === 'done' && (!props.isDisable || items.length < props.maxNumFilesUploadable - 1)) {
            addFiles([meta.file]);
            _.remove(files, function (file) {
                return file.file.name === meta.file.name //Clean files saved in dropzone state
            });
        }
    }

    return (
        <>
            <Stack horizontal className={props.isFileDraggable ? classNames.body : ''} style={{ alignItems: "center" }}>
                <StackItem className={classNames.leftContainer} shrink={false} grow={false}>
                    {props.isFileDraggable ? <Dropzone
                        classNames={{ dropzone: classNames.dropzoneBorders }}
                        onChangeStatus={handleChangeStatus}
                        inputContent={dropzoneContent("noData")}
                        inputWithFilesContent={dropzoneContent("withContent")}
                        styles={{
                            input: { display: 'none' },
                            preview: { display: 'none' }
                        }}
                        multiple={props.multiple}
                    /> : <UploadButton
                        acceptedFile={props.fileTypes}
                        isDisabled={props.isDisable || items.length > props.maxNumFilesUploadable - 1}
                        uploadFile={addFiles}
                        label={labelToPrint}
                        multiple={props.multiple}
                    />}
                </StackItem>

                <StackItem align="start" className={classNames.rightContainer} grow>
                    {props.showMessages ?
                        <div className={classNames.disclaimers}>
                            {!props.multiple ?
                                <MessageBar messageBarType={pickerErrorMessage ? MessageBarType.error : MessageBarType.info}>
                                    {t(pickerErrorMessage || "picker.info")}
                                </MessageBar>
                                :
                                <MessageBar messageBarType={MessageBarType.info}>
                                    {t("picker.info")}
                                </MessageBar>
                            }
                        </div> : null
                    }
                    {props.showMessageError ?
                        <MessageBar messageBarType={MessageBarType.error} styles={{ root: { display: 'flex', alignItems: 'center' } }} >
                            {t(pickerErrorMessage)}
                        </MessageBar>
                        :
                        <div className={classNames.fileList}>
                            {props.multiple &&
                                <span style={!loading ? { padding: 12 } : {}} className={classNames.textParagraph}>
                                    {!loading && countFilesLoaded === 0 ? '' : !loading && countFilesLoaded > 0 ? `${countFilesLoaded} ${t('uploadedText')}` : `${t('uploadingText')} ${countFilesLoaded} ${t('of')} ${_.filter(items, ['isValid', true]).concat(_.filter(tempItems, ['isValid', true])).length}`}
                                </span>
                            }
                            <DetailsList
                                items={_.filter(items, ['isValid', true]).concat(_.filter(tempItems, ['isValid', true]))}
                                styles={{
                                    contentWrapper: {
                                        width: '100%',
                                        maxHeight: '100%',
                                        overflowY: 'auto',
                                        overflowX: 'hidden'
                                    },
                                    root: {},
                                    focusZone: {},
                                    headerWrapper: {}
                                }}
                                columns={getColumns()}
                                setKey="uploadFiles"
                                isHeaderVisible={false}
                                getKey={(item: IFileToUpload) => item?.id}
                                selectionMode={SelectionMode.none}
                                onRenderRow={props => props ? <DetailsRow {...props} styles={classNames.subComponentStyles.detailsRow} /> : null}
                            />
                        </div>
                    }
                </StackItem>
            </Stack>
        </>
    );
}