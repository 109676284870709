/*eslint-disable sonarjs/no-duplicate-string */
import { WorkFlowApproverStatus, WorkFlowStepRelativeStatus, WorkFlowStepStatus, WorkFlowStepTypes } from "../../../models/WorkFlow";
import { UploadErrors } from "../../../common/components/fileUploader/fileUploader.types";
import { WorkFlowRelativeStatus } from "../../../models/WorkflowEnum";
import { ApproveWorkFlowStepType } from "../../../workflow/services/workflowstep/workflowstep.contracts";

export const it = {
    authentication: {
        errorMessage1: 'Si è verificato un errore durante l\'autenticazione.',
        errorMessage2: 'Controlla che siano stati dati i permessi per l\'apertura dei popup.',
        accessDenied: "Accesso all'applicativo non consentito. Contattare l'amministratore per farsi abilitare"
    },
    workflowOverview: {
        title: 'Panoramica richieste',
        notPermissions: "Accesso negato",
        startRequest: 'Crea Richiesta',
        deletedWorkflow: 'Workflow cancellato con successo',
        deleteWorkflowOperation: 'Cancella Workflow',
        search: 'Cerca richieste',
        seeAll: 'Vedi tutti',
        seeDisjointed: 'Vedi solo disgiunti',
        delete:'Cancella',
        save:'Salva',
        column: {
            name: 'Nome Richiesta',
            template: 'Modello usato',
            status: 'Stato',
            createdOn: 'Data creazione',
            requestedBy: 'Richiesta da',
            assignedTo: 'In carico a',
            members: 'Utenti coinvolti',
            expirationDate: 'Data di scadenza'
        },
        filterByCreationDate: 'Data creazione:',
        filterByStatus: 'Stato:',
        selectOptions: 'Seleziona opzioni',
        status: {
            [WorkFlowRelativeStatus.Ongoing]: 'In corso',
            [WorkFlowRelativeStatus.Approved]: 'Approvati',
            [WorkFlowRelativeStatus.Refused]: 'Rifiutati',
            [WorkFlowRelativeStatus.ToMe]: 'Assegnati a me',
        },
        receivedRequests: 'Workflow ricevuti',
        sentRequests: 'Workflow inviati',
        deleteTitle: 'Cancellare?',
        deleteMessage: 'Sicuro di voler cancellare questo workflow?',
        deleteMessageDisjointed: 'Sicuro di voler cancellare questo workflow multiplo?',
        saveFileTitle:'Copia su EDI',
        saveFileSubTitle:'Copia il file del workflow in una cartella di EDI',
        saveFilesSubTitle:'Copia i files del workflow disgiunto in una cartella di EDI',
        saveDocsSubTitle: 'Copia il file dei documenti in una cartella di EDI',

    },
    copyOnEdi: {
        saveWorkflowButton:'Copiare qui',
        savedWorkflow:'Copiato con successo',
        savedWorkflowsSuccess:'Copiati con successo: ',
        savedWorkflowsError:' Copiatura fallita: ',
        saveWorkflowOperation:'Operazione copiatura',
        savedDocuments:'Documenti copiati:',
        errorDocs: 'falliti:',
        saveDocumentsOperation: 'Copiatura documenti addizionali',
        abort:'Interrompere',
        endMessage: 'Upload pronto'
    },
    workflowDisjointed:{
        urges: 'Sollecita'
    },
    templatesOverview:{
        column: {
            name: 'Nome Modello',
            createdBy: 'Creato da',
            createdOn: 'Data Creazione',
            action: 'Azioni',            
            description: 'Descrizione',
            documentName: 'Nome Allegato',
        },
    },
    common: {
        search: "Cerca",
        nameNotAvailable: "Nome non disponibile",
        genericErrorApi: "Qualcosa è andato storto",
        unAuthorized: "Non autorizzato",
        notFound: "Non sono riuscito a trovare quello che stavi cercando... Forse è stato cancellato oppure non hai l'accesso",
        fieldRequired: 'Campo richiesto',
        nextButton: 'Avanti',
        previousButton: 'Indietro',
        leave: 'Esci',
        close: 'Chiudi',
        abort: 'Annulla',
        save: 'Salva',
        done: 'Fatto',
        dialogButtonCancel: 'No, torna indietro',
        dialogButtonConfirm: 'Si, annulla',
        dialogButtonConfirmClose: 'Si, chiudi',
        confirmEliminate: 'Sì, cancella',
        undoEliminate: 'No, torna indietro',
        deletetitle: 'Cancellare?',
        delete: 'Cancella',
        confirm: 'Conferma',
        confirmSave: 'Sicuro di voler salvare?',
        dialogButtonConfirmDeleting: 'Si, cancella',
        name: 'Nome',
        description: 'Descrizione',
        titleLab: 'Titolo',
        add: 'Aggiungi',
        field: 'Campo',
        loading: 'Caricamento...',
        niceWork: 'Ottimo lavoro!',
        tryAgain: 'Riprova',
        create: 'Crea',
        continue: 'Continua',
        cancel: 'Cancella',
        update: 'Aggiorna',
        authErrorTitle: "Errore d'autenticazione",
        operationFailed: 'Operazione fallita',
        notifications: 'Notifiche',
        authErrorMessage: "Per favore permetti i pop-ups nelle tue impostazioni browser e riprova.",
        retry: 'Riprova',
        preposition: ' di ',
        genericErrorCheckName: "Errore durante il controllo del nome",
        emptyList: 'La lista è vuota',
        emptyFolder: 'La cartella è vuota',
        excelCel: 'Cella Excel',
        isInvalid: 'è invalido:',
        excelFormatError: 'Formato file Excel invalido:',
        EmptyFile: 'Foglio Excel vuoto',
        ColumnsPosition: 'Solo le prime due colonne devono essere riempite',
        CellRequired: 'valore cella richiesto',
        RegexNotMatched: 'formato errato',
        fileNotFound: 'File non trovato',
        labelsshort: 'Etichette',
        commands: {
            open: 'Apri',
            openInBrowser: 'Apri nel browser',
            createLink: 'Copia collegamento',
            copyDocumentId: 'Copia ID Documento',
            viewTag: "Visualizza Tag",
            download: 'Scarica',
            delete: 'Cancella',
            manageUsers: 'Gestisci Utenti',
            template: 'Modelli di Worflow',
            renameWF: 'Rinomina Workflow',
            edit: 'Modifica',
            move: 'Sposta',
            copy: 'Copia'
        },
        statusLabel: {
            ongoing: "In corso",
            completed: "Completato",
            error: "Errore"
        },
        zoomIn: 'Ingrandisci',
        zoomOut: 'Diminuisci',
        reset: 'Riposiziona',
        manageUsers: 'Gestisci Utenti',
        manageTempalte: "Gestisci Modelli di Workflow",
        renameWF: 'Rinomina Workflow',
        viewUsers: "Visualizza Utenti",
        emptySearch: 'Nessun risultato trovato',
        role: 'Ruolo',
        labels: "Etichette associate con l'utente",
        error: 'Errore',
        apply: 'Applica',
        selectAll: 'Seleziona Tutto',
        deselectAll: 'Deseleziona Tutto',
        warning: 'Attenzione',
        savingError: "Errore durante il salvataggio. Per favore riprova.",
        noResults: 'Nessun risultato',
        gotIt: 'Fatto',
        multiselectPlaceholder: 'Seleziona una o piu opzioni',
        dropDownPlaceholder: 'Seleziona un opzione',
        title: 'I tuoi Workflow Lab',
        requestSent:'Richiesta inviata con successo',
        loadingTemplate: 'Caricamento Template',
        errorCode403: 'Permessi insufficienti per l\'operazione',
        errorCode404: 'Archivio non trovato',
        errorCode409: 'Nome già utilizzato'
    },
    deleteLabelModal: {
        confirmMessage: 'Sicuro di voler cancellare questa etichetta?',
    },
    deleteTemplateModal:{
        confirmMessage: 'Sicuro di voler cancellare questo modello?',
    },
    deleteCategoryModal:{
        delete: 'Cancellare?',
        confirmMessage: 'Sicuro di voler cancellare questa categoria?'
    },
    removeAssociationModal:{
        removeAssociationLabel: 'Rimuovere etichetta da questa categoria',
        confirmMessage: "Sicuro di voler rimuovere l'etichetta selezionata da questa categoria?"
    },
    toolbar: {
        overview: 'Overview',
        dashboard: 'Dashboard',
        workflow: 'Modelli',
        graphs: 'Questionari',
    },
    notificationPanel: {
        emptyPlaceHolder: 'Nessun elemento presente',
        title: 'Informazioni',
    },
    workflowLabManageUsers: {
        newLabel: 'Nuova Etichetta',
        userview: 'Vista per Utenti',
        labelview: 'Vista per Etichette',
        categoryview: 'Vista per Categorie',
        export: 'Esporta dati', 
        import: 'Importa dati',
        synch: 'Sincronizza dati',
        labelLinkedToUsers: 'Etichette associate all\'utente',
        assignUserLabels: 'Assegna etichette all\'utente',
        updateLabelUsers: 'Assegna utenti a etichetta',
        search: 'Cerca etichetta',
        editContact: 'Modifica Membri',
        newCategory: 'Nuova Categoria',
        searchLabel: 'Cerca etichetta',
        category: 'Categorie: ',
        searchCategory: 'Cerca categoria',
        editLabel: 'Modifica Etichetta',
        editCategory: 'Modifica Categoria',
        editCategoryLabels: 'Assegna etichette a categoria',
        errorMessageEditLabel: "Nome etichetta non valido",
        errorMessageUserLabels: "Massimo 100 etichette per utente",
        synchCompleted:'Sincronizzazione completata',
        synchWorkflowLabOperation:'Sincronizza workflow lab',
        syncConfirmTitle: 'Sincronizzazione',
        syncConfirmSubTitle: 'Sei sicuro di voler procedere?',
        syncCancelLabel: 'No',
        syncConfirmLabel: 'Si'
    },    
    workflowLabManageTemplates:{
        templatesview: 'Modelli',
        search: 'Cerca etichetta',
    },
    workflowLabCategory: {
        newCategory: 'Crea nuova categoria',
        search: 'Cerca Categoria',
    },
    labelList: {
        emptyLabelList: 'Nessuna etichetta associata'
    },
    workFlowSummary: {
        label: 'Utenti Coinvolti',
        exportDisjoint: "Vedi dettagli riceventi Workflow",
        categoriesDisjoint : "Categoria di disambiguazione:",
        noApproverFound : "Alcuni approvatori non trovati",
    },
    workflowDetails: {
        info: {
            status: {
                [WorkFlowRelativeStatus.Ongoing]: 'Workflow in corso e sono stato coinvolto negli steps iniziati fino ad ora, ma non ho da fare nessuna operazione o Workflow in corso e sono il workflow richiedente',
                [WorkFlowRelativeStatus.Approved]: 'Workflow completato con successo',
                [WorkFlowRelativeStatus.Refused]: 'Workflow terminato con un rifiuto',
                [WorkFlowRelativeStatus.ToMe]: 'Workflow con una richiesta di azione a mio carico',
            },
            secondLineStatus: {
                [WorkFlowRelativeStatus.Ongoing]: 'Clicca sul documento allegato, per vedere il contenuto e/o fare cambiamenti',
                [WorkFlowRelativeStatus.Approved]: 'Clicca sul documento allegato per vedere il contenuto',
                [WorkFlowRelativeStatus.Refused]: 'Clicca sul documento allegato per vedere il contenuto',
                [WorkFlowRelativeStatus.ToMe]: 'Clicca sul documento allegato, per vedere il contenuto e/o fare cambiamenti',
            },
            title: 'Informazioni',
            workflowname: 'Nome Richiesta',
            document: 'Documento Allegato',
            createdBy: 'Richiedente',
            stepApprover:'Approvatore'
        },
        sendReminder: 'Invio sollecito',
        history: 'Cronologia',
        action: {
            title: 'Azione Richiesta',
        },
        iter: {
            title: 'Processo approvativo',
            column: {
                step: 'Step',
                iter: 'Iter',
                status: 'Stato',
                rules: 'Regole',
                actionHistory: 'Storico Azioni',
                comments: 'Commenti',
                approvers: "Approvatori"
            },
            userActionWithDate: 'il',
        },
        document:{
            title: 'Documenti Allegati',
            column:{
                documentName:'Documento',
                stepName:'Step',
                approverName:'Caricato da',
            },
        },
        documentEditable: 'Il documento può essere modificato',
        documentNotEditable: 'Il documento non può essere modificato',
        editApprovers: 'Modifica approvatori',
        userCommented: 'ha commentato: ',
        copyAdditional: 'Copia documenti aggiuntivi su EDI',
        copyPartialAdditional: 'Copia documenti rimanenti su EDI',
        exportReport: 'Esporta report'
    },
    stepHistory:{
        history: 'Cronologia',
        historyModalSubtitile: 'Cronologia delle azione compiute su questo Workflow',
        column: {
            step: "Step",
            approver: "Approvatore",
            status: "Stato Step",
            actionType: "Azione",
            comment: "Commento",
        },
        status: {
            [WorkFlowStepStatus.Idle]: 'Da iniziare',
            [WorkFlowStepStatus.ToBeApproved]: 'In Corso',
            [WorkFlowStepStatus.Approved]: 'Approvato',
            [WorkFlowStepStatus.Refused]: 'Rifiutato',
        },
        actions: {
            [ApproveWorkFlowStepType.Approved]: "Approvato",
            [ApproveWorkFlowStepType.Rejected]: "Rifiutato",
            [ApproveWorkFlowStepType.StepBack]: "Step mandato indietro",
        }
    },
    ediLinkModal: {
        title: 'Link per EDI',
        button: 'Apri in EDI',
        genericError: 'Qualcosa è andato storto',
        notPermissionsError: 'Accesso non autorizzato',
        notFoundError: 'File non trovato',
    },
    stepStatus: {
        title: 'STEP #{{order}}:',
        userAction: '',
        status: {
            [WorkFlowStepRelativeStatus.Idle]: '',
            [WorkFlowStepRelativeStatus.ToBeApproved]: 'In Corso',
            [WorkFlowStepRelativeStatus.Approved]: 'Approvato',
            [WorkFlowStepRelativeStatus.Refused]: 'Rifiutato',
            [WorkFlowStepRelativeStatus.ToMe]: 'In Corso',
        },
        approverStatus: {
            [WorkFlowApproverStatus.ToBeApproved]: 'In Corso',
            [WorkFlowApproverStatus.Approved]: 'Approvato',
            [WorkFlowApproverStatus.Refused]: 'Rifiutato',
        },
        type: {
            [WorkFlowStepTypes.OnlyOneApprover]: 'Solo un approvatore',
            [WorkFlowStepTypes.OnlyOneSignedApprover]: 'Solo un approvatore con firma',
            [WorkFlowStepTypes.EveryoneMustApprove]: 'Tutti devono approvare',
        }
    },
    workflowLabUsersSettings: {
        searchBox: 'Cerca membro',
        addMembers: 'Aggiungi membri',
        errorRemoveMembers: 'Il membro non può essere rimosso perchè è presente in almeno un workflow lab.',
        updateMembership: "Aggiorna membership di ",
        createAtLeastOneTemplate: 'Crea almeno un modello per questo workflow lab',
        templateSettings: {
            title: 'Rendi visibili i seguenti tipi di progetto:'
        },
        deleteTitle: 'Cancellare?',
        deleteMessage: "Sicuro di voler rimuovere l'utente?",
        confirmEliminate: 'Si, cancella',
        undoEliminate: 'No, torna indietro',
        setLabels: 'Aggiungi etichetta',
        LabelSettings: {
            title: 'Etichette'
        },
        errorMessage: 'Massimo 100 etichette'
    },
    workflowLabTemplateSettings: {
        searchBox: 'Cerca modello',
        addTemplates: 'Aggiungi modello',  
        deleteTitle: 'Cancellare?',
        deleteMessage: 'Sicuro di voler rimuovere il modello?',
        confirmEliminate: 'Si, cancella',
        undoEliminate: 'No, torna indietro'
    },
    workflowApprovalStep: {
        title: 'Step',
        everyoneMustApproveApproverDesctioption: "Per questo step è prevista l'approvazione di tutti",
        onlyOneApproverDesctioption: "Per questo step è prevista almeno un'approvazione",
        onlyOneSignedApproverDesctioption: 'Per questo step è prevista la firma digitale',
        secondDesctioption: 'Lascia un commento',
        tooltipDescription: 'Il commento sarà visibile a tutti gli approvatori del workflow. In caso di rifiuto il commento è obbligatorio',
        approve: 'Approva',
        signedApprove: 'Approva con firma',
        reject: 'Rifiuta e annulla workflow',
        rejectToolTip: 'Scrivi un commento per rifiutare. Si noti che cliccando questo tasto il workflow viene bloccato irrevocabilmente, pertanto si prega di contattare preventivamente il creatore del workflow',
        commentPlaceholder: 'Inserisci un commento',
        notPermissions: "Non hai il permesso di approvare",
        notFound: 'Step non trovato',
        unprocessable: 'La richiesta non è processabile',
        uploadFile: 'Carica File',
        titleUploadModal: 'Carica un File',
        subtitleUploadModal: 'Scegli un file da allegare allo step corrente',
        stepBackButton: 'Indietro',
        errorUploadFile: 'Errore, Nome file già esistente',
        confirmReject: 'Sei sicuro di rifiutare e chiudere permanentemente questo workflow?'
    },
    workflowApprovalModal: {
        firstStepTitle: 'Firma il documento',
        userIdToolTipDescrioption: 'User ID',
        pinToolTipDescrioption: 'User ID',
        certificate: 'Seleziona un certificato',
        otpRequest: 'Richiesta OTP',
        insertOtp: 'Inserisci OTP',
        signatureInProgress: 'Firma in corso',
        signatureDescription: 'Stiamo apportando la firma sul documento. ',
        secondSignatureDescription: "Attendi qualche istante.",
        signatureSuccess: 'Firma apposta con successo',
        firsStepDescription: 'Non risulta alcun certificato di firma associato alla tua utenza',
        firsStepInfoDescription: 'Per maggiori informazioni',
        digitalSignature: 'firma digitale',
        insertPin: 'Inserisci Pin',
        insertUser: 'Inserisci User ID',
        notPermissions: "Non hai i permessi per accedere alla risorsa",
        notFound: 'Step non trovato',
        unprocessable: 'La richiesta non è processabile',
        signatureTooltip: 'Inserisci il codice OTP che ti è stato inviato via email',
        pinTooltip: 'Inserire un pin con 8 numeri',
        wrongRequest: 'Pin o certificato non sono validi',
        certificateNotFound: 'Certificato non trovato',
        editText: 'Le modifiche non saranno applicate ai progetti già inseriti'
    },
    createActivity: {
        low: "Bassa",
        medium: "Media",
        high: "Alta",
        title: 'Pianifica progetto',
        activityName: 'Nome progetto',
        activityNamePlaceholder: 'Inserisci Nome progetto',
        description: 'Descrizione',
        startDate: "Data Inizio",
        selectDay: 'Seleziona un giorno',
        endDate: 'Data Scadenza',
        selectBudget: 'Assegna un budget',
        priority: 'Priorità',
        toActivityLeader: 'Assegna ad un Project Leader',
        activityTemplate: 'Modello di progetto',
        messageForActivity: "Il project leader riceverà una notifica per l'assegnazione e l'automatica creazione di un Teams",
        analysisAlreadyExist: 'Nome progetto è già stato usato',
        endMessage: 'Il progetto è stato creato, è in corso la creazione del team',
        end: 'Ok',
        error: 'Si è verificato un errore durante la creazione del progetto. Attendi qualche minuto e riprova',
        dataPickerStrings: {
            "closeButtonAriaLabel": "Chiudi il datapicker",
            "dayMarkedAriaLabel": "segnato",
            "days": ['Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdi', 'Sabato', 'Domenica'],
            "goToToday": "Vai ad oggi",
            "invalidInputErrorMessage": "Formato non valido",
            "isRequiredErrorMessage": "Campo obbligatorio",
            "monthPickerHeaderAriaLabel": "{0}, cambia anno",
            "shortMonths": ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic'],
            "months": ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'],
            "shortDays": ['D', 'L', 'M', 'M', 'G', 'V', 'S']
        },
        leadersPlaceholder: 'Inizia a digitare un nome',
        minLengthAlert: "Inserisci almeno 3 caratteri",
        noResults: "Nessuna corrispondenza trovata",
        creatingActivity: 'Creazione progetto in corso...'
    },
    createWorkflow: {
        title: 'Inizia richiesta',
        subtitle: 'Inizia una richiesta di workflow',
        selectDocument: 'Seleziona il documento per il workflow',
        selectDocumentTooltip: 'Attenzione! Selezionare un file con un formato diverso da PDF non ti permette di selezionare la regola "Approvazione con firma digitale"',
        noResults: 'Nessun risultato',
        setProperties: {
            nameLabel: 'Nome richiesta',
            descriptionLabel: 'Descrizione richiesta',
            endDate: 'Data di scadenza',
            selectDay: 'Seleziona un giorno',
            namePlaceholder: 'Nome',
            descriptionPlaceholder: 'Descrizione',
            selectTemplate: 'Vuoi utilizzare un modello creato?',            
            workflowOption: 'Opzioni di workflow',
            disjointed: 'Workflow disgiunti',
            toggle: 'Selezionando questa opzione, verranno create più istanze di workflow',
            templatePlaceholder:'Seleziona una template',
            isDocumentAttachable: 'Possibilità di allegare un documento in risposta',
            toggleUpload: 'Permetterai il caricamento di documenti in risposta agli step',
        },
        choiceInputSource: {
            teams: 'Teams',
            teamsSubtitle: 'Carica un documento da teams',
            local: 'Locale',
            localSubtitle: 'Carica un documento dal tuo dispositivo',
            edi: 'EDI',
            ediSubtitle: 'Carica un documento da EDI',
        },
        localFile: {
            subtitle: 'Carica un file',
            text: 'Inserisci il documento da sottoporre al Workflow',
            tooltip: 'Ad oggi, EWE non può gestire file più grandi di 100 MB.'
        },
        addSteps: {
            title: "Definizione del Workflow ",
            namePlaceholder: "Inserisci un nome...",
            nameLabel: "Nome Step",
            dropdownLabel: "Seleziona la regola di approvazione",
            dropdownPlaceholder: "Seleziona...",
            peoplePlaceholder: 'Inizia a scrivere un nome',
            approvers: "Approvatori Step",
            noResult: "Nessun risultato",
            addStep: "Aggiungi step",
            minLengthAlert: "Digita almeno tre caratteri",
            maxOneApprover: "Al massimo un approvatore",
            errorType: "La regola selezionata può essere utilizzata solo con i file pdf"
        },
        addDisjointment:{
            addDisjointment:"Aggiungi categoria"
        },
        workflowTypes: {
            onlyOneApprover: "Almeno uno deve approvare",
            everyoneMustApprove: "Tutti devono approvare",
            onlyOneSignedApprover: "Approva con firma digitale"
        },
        columns: {
            name: "Nome",
            createdOn: "Creato in",
            createdBy: "Creato da"
        },
        toggle: {
            label: "Opzioni File",
            on: "Il documento può essere modificato",
            off: "Il documento non può essere modificato",
            error: "File non modificabile"
        },
        creatingWorkflow: 'Creazione Workflow',
        checkDeleteWorkflow: {
            title: "Vuoi cancellare la richiesta di Workflow?",
            message: "Se chiudi la finestra senza completare la creazione del workflow, i dettagli da te inseriti andranno persi. Vuoi cancellare questa creazione di workflow?",
        },
        selectDisjointmentCategory:{
            dropdownCategory:"Disambigua l'invio di questa richiesta di workflow",
            dropdownSubCategory:"Seleziona dall'elenco per la quale disambiguare la richiesta da avviare",
            dropdownSubLabel:"Seleziona i valori dalla categoria per per cui effettuare il workflow disgiunto",
            labelwarning:"Istanze workflow: "
        }
    },
    uploadFileModal: {
        title: 'Carica',
        endMessage: 'Stiamo analizzando i tuoi documenti. Presto scoprirai i risultati!',
        subtitle: "Il caricamento di così tanti file può richiedere tanto tempo. Ti suggeriamo di aprire l'applicazione EWE in una nuova finestra per prevenire una perdita di dati.",
        [UploadErrors.LengthOutOfRange]: 'Dimensione file non permessa',
        [UploadErrors.NameInvalid]: 'Nome file invalido',
        [UploadErrors.MimeTypeNotAllowed]: 'Tipo di file non permesso',
        [UploadErrors.InvalidFileSignature]: 'Controllo tipo di file fallito',
        [UploadErrors.TooLarge]: "Dimensione file eccede il limite (100MB)",
        uploadFileAlreadyExists: "Un file con lo stesso nome esiste già",
        uploadGenericError: 'Errore durante il caricamento del file',
        uploadConflictError: 'File già esistente',
        uploadUnprocessableError: 'Limite capacità di memorizzazione raggiunto. ',
        invalidExcel: "Il file non è formattato correttamente. Segui le istruzioni qui sopra e riprova.",
        uploadingText: 'Caricamento file',
        of: 'di',
        uploadedText: 'file caricati',
        picker: {
            fileAlreadyUploaded: "File già caricato",
            fileTypeNotAllowed: "Tipo di file non permesso",
            info: "Tipi di file permessi: pdf, doc, docx, pptx, xlsx, ppt.",
            disclaimer: 'Se chiudi questa finestra, il caricamento potrebbe interrompersi.'
        },
        dropzone: {
            msg1: "Rilascia un file qui",
            msg2: "o",
            button: 'Esplora'
        },
        footer: {
            abort: 'Annulla',
            confirm: 'Continua',
            end: 'Fatto',
            back: 'Indetro',
        },
        capacity: {
            used: 'Spazio usato : ',
            total: 'Spazio totale : ',
            free: 'Spazio libero : ',
        },
        detailsList:{
            documentName:'Nome',
            documentTag:'Tag'
        },
        tags:'Scelta Tag'
    },
    membersPageForAddWorkFlowLabMembers: {
        description1: "Inizia a digitare un nome da aggiungere al tuo WorkFlowLab.",
        description2: "Puoi aggiungere persone di Eni (*@eni.com o *@external.eni.com) che hanno già EWE sul loro Teams.",
        minLengthAlert: "Digita almeno tre caratteri",
        noResults: "Nessuna persona corrisponde alla specifica ricerca"
    },
    dashboard: {
        workflowStatus: {
            title: 'Stato Workflow',
            titleDisjointed: 'Stato Workflow Disgiunti'
        },
        empty: "Non ci sono dati",
        exportReport: 'Esporta report'
    },
    workflow: {
        status: {
            [WorkFlowRelativeStatus.Ongoing]: 'In corso',
            [WorkFlowRelativeStatus.Approved]: 'Approvati',
            [WorkFlowRelativeStatus.Refused]: 'Rifiutati',
            [WorkFlowRelativeStatus.ToMe]: "Assegnati a me",
        },
        sentWorkflowRequests: 'Richieste di Workflow inviate',
        receivedWorkflowRequests: 'Richieste di Workflow ricevute',
        filters: {
            filterCreationDate: 'Data creazione:',
            filterExpirationDate: 'Data scadenza:',
            filterCreatedBy: 'Creato da:',
            filterBy: 'Filtra per:',
            all: 'Tutto',
            lastMonth: 'Ultimo mese',
            lastWeek: 'Ultima settimana',
            nextMonth: 'Prossimo mese',
            nextWeek: 'Prossima settimana',
            custom: 'Personalizzato',
            startDate: 'Da',
            endDate: 'A',
            selectaDay: 'Seleziona un Giorno'
        }
    },
    archiveEDINavigation: {
        errorForbidden: "Non hai accesso a EDI"
    },
    modalEditApprovers: {
        description1: "Cambia gli approvatori degli step",
        description2: "Puoi solamente selezionare un nuovo approvatore quando ne rimuovi uno esistente",
        minLengthAlert: "Digita almeno tre caratteri.",
        noResults: "Nessuna persona corrisponde con la parola chiave specificata"
    },
    workflowLabList: {
        createNew: 'Crea un WorkFlow Lab',
    },
    createWorkflowLabModal: {
        namePlaceholder: 'Inserisci il titolo del Workflow Lab',
        descriptionPlaceholder: 'Inserisci la descrizione del Workflow Lab',
        firstStepTitle: 'Crea un Workflow Lab',
        genericErrorCheckName: 'Controllo Errore Generico',
        stopCreating: 'Ferma la Creazione del Workflow Lab',
        secondStepTitle: 'Aggiungi membri al Workflow Lab',
        creatingWorkflowLab: 'Creazione del Workflow Lab',
        ofWorkflowLab: ' con la creazione del Workflow Lab',
    },
    createLabelModal: {
        addName: "Inserisci il nome dell'etichetta",
        createLabel: "Crea etichetta",
        errorMessage: "Nome etichetta non valido",
    },
    createCategoryModal: {
        addName: "Inserisci il nome della categoria",
        createCategory: "Crea categoria",
    },
    labelsPageForAddCategoryLabels: {
        description1: "Inizia a digitare un nome di etichetta da aggiungere al tuo WorkFlowLab.",
        description2: "Puoi aggiungere etichette che non siano già associate a una categoria.",
        minLengthAlert: "Digita almeno tre caratteri",
        noResults: "Nessun etichetta corrisponde alla specifica ricerca"
    },
    uploadUserLabelModal: {
        notPermissions: "Non hai i permessi",
        notFound: "WorkflowLab non trovato",
        ColumnsError : "Errore numero di colonne",
        UserNotFound : "Utente non trovato",        
        DuplicateUsers : "Utenti duplicati",        
        RoleNotFound : "Ruolo non trovato",
        CanNotChangeYourRole: "Non puoi cambiare il tuo ruolo",
        LabelNameNotValid: "Alcuni nomi delle etichette non sono validi",
        AuditorHasNoLabels: "Il ruolo Auditor non può avere delle etichette associate",
        AuditorCannotChangeRole: "Non puoi cambiare il ruolo Auditor agli utenti",
        firstPageTitle: 'Importa dati',
        firstPageSubHeader: 'Importazione massiva di utenti, etichette e categorie',
        secondPage : 'Stiamo aggiornando gli Utenti e le Etichette,  l\'operazione richiederà qualche minuto',
        end: 'OK',
        buttonForUploadFile: 'Carica',
        firstPhrase: 'Istruzioni per una corretta compilazione del file',
        secondPhrase: 'Il file deve essere in formato Excel',
        thirdPhrase:  'Ti suggeriamo di partire dal file di export e modificare i dati mantenendo la stessa struttura.', 
        fourthPhrase: 'Altrimenti la struttura del file deve avere:',
        firstColumnPhrase: '- una riga di intestazione (FirstName, LastName, Email, Role, Labels, categorie)',
        secondoColumnPhrase:'- nelle prime tre colonne Nome, Cognome e la Mail dell\' utente ',
        thirdColumnPhrase:'- nella quarta colonna, il Ruolo (Proprietario, Supervisore o Utente)',
        fourthColumnPhrase: '- nella quinta colonna le semplici Etichette (separate dalla virgola)',
        fifthColumnPhrase: '- dalla sesta colonna, le Etichette che si riferiscono alle catergorie (separate dalla virgola)'
    },
    createTemplateModal: {
        title: 'Modello di Workflow',
        subtitle: 'Crea un modello di workflow',
        subtitle2: 'Modifica un modello di workflow',
        selectDocument: 'Seleziona il documento per il modello di workflow (opzionale)', 
        selectDocumentTooltip: 'Attenzione! Selezionare un file con un formato diverso da PDF non ti permette di selezionare la regola "Approvazione con firma digitale"',
        setProperties: {
            nameLabel: 'Nome modello',
            descriptionLabel: 'Descrizione modello',
            namePlaceholder: 'Nome',
            descriptionPlaceholder: 'Descrizione',
            workflowOption: 'Opzioni di workflow',
            disjointed: 'Workflow disgiunti',
            toggle: 'Selezionando questa opzione, verranno create più istanze di workflow',
        },
        choiceInputSource: {
            teams: 'Teams',
            teamsSubtitle: 'Carica un documento da teams',
            local: 'Locale',
            localSubtitle: 'Carica un documento dal tuo dispositivo',
            edi: 'EDI',
            ediSubtitle: 'Carica un documento da EDI',
        },
        localFile: {
            subtitle: 'Carica un file',
            text: 'Inserisci il documento da sottoporre al Modello di Workflow',
            tooltip: 'Ad oggi, EWE non può gestire file più grandi di 100 MB.'
        },
        addSteps: {
            title: "Definizione del Modello di Workflow ",
            namePlaceholder: "Inserisci un nome...",
            nameLabel: "Nome Step",
            dropdownLabel: "Seleziona la regola di approvazione",
            dropdownPlaceholder: "Seleziona...",
            peoplePlaceholder: 'Inizia a scrivere un nome',
            approvers: "Approvatori Step",
            noResult: "Nessun risultato",
            addStep: "Aggiungi step",
            minLengthAlert: "Digita almeno tre caratteri",
            maxOneApprover: "Al massimo un approvatore",
            errorType: "La regola selezionata può essere utilizzata solo con i file pdf"
        },
        workflowTypes: {
            onlyOneApprover: "Almeno uno deve approvare",
            everyoneMustApprove: "Tutti devono approvare",
            onlyOneSignedApprover: "Approva con firma digitale"
        },
        columns: {
            name: "Nome",
            createdOn: "Creato in",
            createdBy: "Creato da"
        },
        toggle:{
            label: "Opzioni File",
            on : "Il documento può essere modificato",
            off : "Il documento non può essere modificato",
            error : "File non modificabile"
        }, 
        creatingWorkflow:'Creazione Modello di Workflow',
        updatingTemplate:'Modifica Modello di Workflow',
        checkDeleteWorkflowTemplate: {
            title: "Vuoi cancellare la creazione del Modello di Workflow?",
            message: "Se chiudi la finestra senza completare la creazione del Modello di workflow, i dettagli da te inseriti andranno persi. Vuoi cancellare questa creazione del modello di workflow?",
        },
        checkDeleteEditTemplate: {
            title: "Vuoi cancellare la modifica del Modello di Workflow?",
            message: "Se chiudi la finestra senza completare la modifica del Modello di workflow, i dettagli da te inseriti andranno persi. Vuoi cancellare questa modifica del modello di workflow?",
        }
    },
    workflowTemplateDetails: {
        info: {
            status: {
                [WorkFlowRelativeStatus.Ongoing]: 'Modello di Workflow in corso e sono stato coinvolto negli steps iniziati fino ad ora, ma non ho da fare nessuna operazione o Workflow in corso e sono il workflow richiedente',
                [WorkFlowRelativeStatus.Approved]: 'Modello di Workflow completato con successo',
                [WorkFlowRelativeStatus.Refused]: 'Modello di Workflow terminato con un rifiuto',
                [WorkFlowRelativeStatus.ToMe]: 'Modello di Workflow con una richiesta di azione a mio carico',
            },
            secondLineStatus: {
                [WorkFlowRelativeStatus.Ongoing]: 'Clicca sul documento allegato, per vedere il contenuto e/o fare cambiamenti',
                [WorkFlowRelativeStatus.Approved]: 'Clicca sul documento allegato per vedere il contenuto',
                [WorkFlowRelativeStatus.Refused]: 'Clicca sul documento allegato per vedere il contenuto',
                [WorkFlowRelativeStatus.ToMe]: 'Clicca sul documento allegato, per vedere il contenuto e/o fare cambiamenti',
            },
            title: 'Informazioni',
            workflowname: 'Nome Modello',
            document: 'Documento Allegato',
            createdBy: 'Richiedente',
            stepApprover:'Approvatore'
        },
        sendReminder: 'Invio sollecito',
        action: {
            title: 'Azione Richiesta',
        },
        iter: {
            title: 'Processo approvativo',
            column: {
                step: 'Step',
                iter: 'Iter',
                status: 'Stato',
                rules: 'Regole',
                actionHistory: 'Storico Azioni',
                comments: 'Commenti',
                approvers: "Approvatori"
            },
            userActionWithDate: 'il',
        },
        documentEditable: 'Il documento può essere modificato',
        documentNotEditable: 'Il documento non può essere modificato',
        editApprovers: 'Modifica approvatori',
        userCommented: 'ha commentato: '
    },
}
