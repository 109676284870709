import { styled } from "@fluentui/react";
import { getStyles } from "./workflowDashboardContainer.styles";
import { IWorkflowDashboardContainerPropsStyles, IWorkflowDashboardContainerStyles, IWorkflowDashboardContainerProps } from "./workflowDashboardContainer.types";
import { WorkflowDashboardContainerBase } from "./workflowDashboardContainer.base";

const WorkflowDashboardContainer = styled<IWorkflowDashboardContainerProps, IWorkflowDashboardContainerPropsStyles, IWorkflowDashboardContainerStyles>(
    WorkflowDashboardContainerBase,
    getStyles,
    undefined,
    { scope: 'WorkflowDashboardContainer' }
);

export default WorkflowDashboardContainer;