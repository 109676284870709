/*eslint-disable sonarjs/cognitive-complexity */
import React, { useEffect, useState } from "react";
import { classNamesFunction, IconButton, keyframes, Label, Stack } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { useAsyncApi } from "../../../utilities/hooks";
import { WorkFlowSplitByStatus } from "../../../models/WorkFlow";
import { workflowApi } from "../../services/workflow.api";
import { ErrorsType } from "../../../common/types/ErrorsType";
import TeamsSpinner from "../../../common/components/teamsSpinner/teamsSpinner";
import TeamsImage from "../../../common/components/teamsImage/teamsImage";
import GraphWorkflowStatus from "./graphWorkflowStatus/graphWorkflowStatus";
import { ImageName } from "../../../common/components/teamsImage/teamsImage.types";
import { WorkflowStatusGraphResponse } from "../../../models/WorkflowDashboard";
import { IWorkflowDashboardContainerProps, IWorkflowDashboardContainerPropsStyles, IWorkflowDashboardContainerStyles } from "./workflowDashboardContainer.types";
import WorkflowDisjointedGraphContainer from "./workflowDisjointedGraphContainer/workflowDisjointedGraphContainer";
import { User } from "../../../models/User";
import { workflowLabApi } from "../../services/workFlowLab/workFlowLab.api";
import { WorkFlowLabRoleId } from "../../../utilities/constants";
import { useCurrentWorkflowLab } from "../../hooks/useCurrentWorkflowLab";
import { GetWorkFlowsDashboardDisjointRequest, WorkFlowsDashboardDisjointResponse } from "../../services/workflowDisjointed/workflowDisjointed.contracts";
import { workflowDisjointedApi } from "../../services/workflowDisjointed/workflowDisjointed.api";
import { ActionButton, Spinner, SpinnerSize } from "@fluentui/react";
import { initReactI18next } from "react-i18next";
import i18n from 'i18next';

const getClassNames = classNamesFunction<IWorkflowDashboardContainerPropsStyles, IWorkflowDashboardContainerStyles>();

export const WorkflowDashboardContainerBase = (props: IWorkflowDashboardContainerProps) => {

    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const [sentWorkflowsStatus, setSentWorkflowsStatus] = useState<Array<WorkFlowSplitByStatus>>([]);
    const [receivedWorkflowsStatus, setReceivedWorkflowsStatus] = useState<Array<WorkFlowSplitByStatus>>([]);
    const [errorWorkflowsStatus, setErrorWorkflowsStatus] = useState<string>("");
    const [refresh, setRefresh] = useState<boolean>(true);
    const [globalLoading, setGlobalLoading] = useState(true);
    const selectedWorkflowLab = useCurrentWorkflowLab(); 
    const [requestDashboardDisjointed, setRequestDashboardDisjointed] = useState<GetWorkFlowsDashboardDisjointRequest>();
    const { t } = useTranslation(['dashboard', 'activity', 'common']);
    const genericErrorApiTraslate = t('common:genericErrorApi');
    const genericEmptyGraph = t('empty');
    const [filterKey,setFilterKey]=useState('lastMonth');
    const [oldTo,setOldTo]=useState(new Date());
    const [oldFrom,setOldFrom]=useState(new Date());

    const retryToRefresh = () => {
        _.delay(() => setRefresh(true), 500);
      }

    useEffect(() => {
        if (selectedWorkflowLab && refresh) {
            getWorkflowsStatus();
            loadWorkflowLabOwners();
            if(selectedWorkflowLab.roleId !== WorkFlowLabRoleId.User && requestDashboardDisjointed)
                loadDisjointedDashboard(requestDashboardDisjointed);
            setRefresh(false);
        }
        else if (refresh)
            retryToRefresh()
    }, [refresh, selectedWorkflowLab]) //eslint-disable-line react-hooks/exhaustive-deps

    const { execute: loadWorkflowLabOwners, value: workflowLabOwners, loading: loadingOwners } = useAsyncApi<void, User[]>({
        keepResultsWhileReloading: true,
        func: async () => {
            if (!selectedWorkflowLab) 
                return []

            const workflowLabMembers = await workflowLabApi.getWorkflowLabMembers({
                id: selectedWorkflowLab?.id,
                keyword: ''
            });
            return workflowLabMembers.filter(w => w.workFlowLabRoleId !== WorkFlowLabRoleId.User);
        },
    });

    const { execute: loadDisjointedDashboard, 
            value: disjointedDashboardData, 
            loading: loadingDisjointedDashboard 
        } = useAsyncApi<GetWorkFlowsDashboardDisjointRequest, WorkFlowsDashboardDisjointResponse[]>({
        keepResultsWhileReloading: true,
        func: async (request: GetWorkFlowsDashboardDisjointRequest) => {
            request.workflowLabId = selectedWorkflowLab?.id;
            return await workflowDisjointedApi.getWorkflowDashboardDisjointed(request);
        },
    });

    const { execute: getWorkflowsStatus, loading: loadingGetWorkflowsStatus } = useAsyncApi<void, WorkflowStatusGraphResponse | undefined>({
        func: async (): Promise<WorkflowStatusGraphResponse | undefined> => { 
            try {
                const today = new Date(Date.now());
                const lastMonthDate = new Date(new Date(Date.now()).setMonth(new Date(Date.now()).getMonth() - 1));                
                const fromDate = lastMonthDate.toISOString();
                const toDate = today.toISOString();

                const result = await workflowApi.getWorkflowDashboard(
                    {
                        workflowLabId: selectedWorkflowLab?.id, 
                        searchBy: undefined, 
                        dateFrom: fromDate, 
                        dateTo: toDate 
                    });
                setReceivedWorkflowsStatus(result.workflowReceived);
                setSentWorkflowsStatus(result.workflowSent);
                return result;
            }
            catch (er) {
                const error: ErrorsType = er as ErrorsType;
                if (error.code === 403) {
                    setErrorWorkflowsStatus(t("errors.errorCode403"));
                }
                else if (error.code === 422) {
                    setErrorWorkflowsStatus(t("errors.errorCode422"));
                } else {
                    setErrorWorkflowsStatus(genericErrorApiTraslate);
                }
                return undefined;
            }
        }
    });

    useEffect(() => {
        if (!(loadingGetWorkflowsStatus || refresh))
            setGlobalLoading(false);
    }, [loadingGetWorkflowsStatus, refresh]);

    const { execute: generateWorkflowsExport, loading: generatingExport } = useAsyncApi<void, void>({
        func: async () => {
            await workflowApi.exportWorkflowMonitoringData(selectedWorkflowLab?.id, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                , i18n.use(initReactI18next).language, selectedWorkflowLab?.name + '');
    }
    });

    return (
        <React.Fragment>
            {!selectedWorkflowLab && <div className={`${classNames.spinner} ${classNames.flexCenter}`}><TeamsImage imageName={ImageName.Oops1} /></div>}
            {selectedWorkflowLab &&
            <React.Fragment>
                <div style={{ height: 50 }}>
                <Stack horizontal>
                <Stack.Item style={{display: 'flex'}}>
                    <div className={classNames.refreshButtonContainer}>
                    { (selectedWorkflowLab.roleId === WorkFlowLabRoleId.Owner || selectedWorkflowLab.roleId === WorkFlowLabRoleId.Auditor) && <Stack.Item style={{display: 'flex'}}>
                        {(generatingExport) && <Spinner size={SpinnerSize.small} />}
                        <ActionButton
                            disabled={generatingExport}
                            onClick={() => generateWorkflowsExport()}
                            text={t('exportReport')}
                        />
                    </Stack.Item>
                    }
                        <IconButton
                            disabled={refresh || globalLoading}
                            className={classNames.refreshButton}
                            styles={classNames.subComponentStyles.iconButton()}
                            style={refresh ? { animation: `${keyframes({ to: { transform: 'rotate(360deg)' } })} 1s linear infinite` } : {}}
                            iconProps={{ iconName: "Refresh" }}
                            onClick={() => { setRefresh(true); setGlobalLoading(true); }}
                        />
                    
                    </div>
                    </Stack.Item>
                    </Stack>
                </div>
            
                    {/* MAIN STACK */}
                    <Stack className={classNames.root} tokens={{ childrenGap: 20, padding: 15 }}>

                        <Stack horizontal>
                            <Stack.Item style={{ width: '100%', height: 370 }} className={classNames.stackItem}>
                                <div className={classNames.title}>{t('workflowStatus.title')}</div>
                                <div style={{ height: 300 }}>
                                    {globalLoading && <div className={`${classNames.spinner} ${classNames.flexCenter}`}><TeamsSpinner /></div>}
                                    {!globalLoading && errorWorkflowsStatus === "" && (receivedWorkflowsStatus || sentWorkflowsStatus) &&
                                        <GraphWorkflowStatus
                                            initialSentWorkflows={sentWorkflowsStatus}
                                            initialReceivedWorkflows={receivedWorkflowsStatus} 
                                            filterKey={filterKey}
                                            setFilterKey={(filter)=>setFilterKey(filter)}
                                            oldFrom={oldFrom}
                                            oldTo={oldTo}
                                            setOldFrom={(oldFrom)=>setOldFrom(oldFrom)}
                                            setOldTo={(oldTo)=>setOldTo(oldTo)}
                                        />
                                    }
                                    {!errorWorkflowsStatus && !globalLoading && !receivedWorkflowsStatus && !sentWorkflowsStatus &&
                                        <Label className={`${classNames.empty} ${classNames.flexCenter}`}>{genericEmptyGraph}</Label>}
                                    {errorWorkflowsStatus && !globalLoading && <Label className={`${classNames.error} ${classNames.flexCenter}`}>{errorWorkflowsStatus}</Label>}
                                </div>
                            </Stack.Item>
                        </Stack>

                        { selectedWorkflowLab.roleId !== WorkFlowLabRoleId.User && <Stack horizontal>
                            <Stack.Item style={{ width: '100%', height: 370 }} className={classNames.stackItem}>
                                <div className={classNames.title}>{t('workflowStatus.titleDisjointed')}</div>
                                <div style={{ height: 300 }}>
                                    <WorkflowDisjointedGraphContainer 
                                        users={workflowLabOwners}
                                        dataRequest={requestDashboardDisjointed}
                                        onChange={(data) => {
                                            setRequestDashboardDisjointed(data);
                                            loadDisjointedDashboard(data);
                                        }}
                                        dataDashboard={disjointedDashboardData ?? []}
                                        loadingDashboard={loadingDisjointedDashboard}
                                        loadingUsers={loadingOwners}
                                    />
                                    {errorWorkflowsStatus && !loadingDisjointedDashboard && <Label className={`${classNames.error} ${classNames.flexCenter}`}>{errorWorkflowsStatus}</Label>}
                                </div>
                            </Stack.Item>
                        </Stack>}
                        
                    </Stack>
                </React.Fragment>
            }
        </React.Fragment>
    );
}