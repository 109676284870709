import { IStyleFunction } from "@fluentui/react"
import { ITeamsFilePropsStyles, ITeamsFileStyles } from "./teamsFile.types"

export const TeamsFileGlobalClassNames = {
    root: 'edi-Teams-file-root'
}

export const getStyles: IStyleFunction<ITeamsFilePropsStyles, ITeamsFileStyles> = (props: ITeamsFilePropsStyles): ITeamsFileStyles => {
    return {
        root: [
            TeamsFileGlobalClassNames.root,
            {
               // marginBottom: 20
            }
        ]
    };
}