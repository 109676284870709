import React, { useState } from "react";
import { classNamesFunction, ContextualMenu, Icon, IContextualMenuItem, INavLinkGroup, Nav } from "@fluentui/react";
import { IWorkflowLabListPropsStyles, IWorkflowLabListStyles, IWorkflowLabListProps } from "./workflowLabList.types";
import { useWorkflowDispatch } from "../../workflowStore";
import { useTranslation } from "react-i18next";
import TitleListSection from "../../../common/components/titleListSection/titleListSection";
import NavigationContainer from "../../../common/components/navigationContainer/navigationContainer";
import { getWorkflowsLab, updateIdSelectedWorkflowLab, useWorkflowList } from "../../features/workflowList";
import TeamsImage from "../../../common/components/teamsImage/teamsImage";
import { ImageName } from "../../../common/components/teamsImage/teamsImage.types";
import { WorkflowLabShort } from "../../../models/WorkflowShort";
import { useOnMount } from "../../../utilities/hooks";
import { useNavigator } from "../../hooks/useNavigator";
import CreateWorkflowLabModal from "../createWorkflowLabModal/createWorkflowLabModal";
import currentUser from "../../../modules/authentication/currentUser";
import { WorkflowUserRoleId } from "../../../utilities/constants";
import { getWorkflowLabActions } from "../../utilities/workflowUtilities";

const getClassNames = classNamesFunction<IWorkflowLabListPropsStyles, IWorkflowLabListStyles>();
export const WorkflowLabListBase = (props: IWorkflowLabListProps) => {
    const canUserCreateWorkflowLab = currentUser.getUserRoles?.includes(WorkflowUserRoleId.WorkflowLabCreator);
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const { t } = useTranslation(['workflowLabList', 'common']);
    const { workflowsLab, selectedWorkflowLab } = useWorkflowList();

    const [openedMenuId, setOpenedMenuId] = useState<number>();
    
    const [createWorkflowLabModal, setCreateWorkflowLabModal] = useState(false);

    const dispatch = useWorkflowDispatch();
    const navigator = useNavigator();

    useOnMount(() => {
        dispatch(getWorkflowsLab())
        .unwrap()
        .then((wfLabs) => {
            if (!wfLabs) return;
            let wfLabSelected = wfLabs.find((wfLab) => wfLab.id === navigator.selectedWorkFlowLabId)?.id;

            if (!wfLabSelected && wfLabs.length > 0) {
                wfLabSelected = wfLabs[0].id;
            }

            if (wfLabSelected !== undefined && props.isWorkflowDeepLink){
                dispatch(updateIdSelectedWorkflowLab(wfLabSelected));
            }
            
            if (wfLabSelected !== undefined && !props.isWorkflowDeepLink){
                navigator.changeWorkflowLab(wfLabSelected);
            }
        })
    });

    const getContextualMenuItems = (workflowLab: WorkflowLabShort): IContextualMenuItem[] => {
        return getWorkflowLabActions(workflowLab).map(
            (action) => ({
                key: action.label,
                text: t(action.label),
                iconProps: { iconName: action.icon },
                onClick: () => action.onClick(workflowLab, navigator, dispatch)        
            })
        );        
    };

    const mapWorkflowLab = (workflowLab: WorkflowLabShort) => {
        return {
            key: workflowLab.id.toString(),
            name: workflowLab.name,
            url: "",
            workflowLab: workflowLab
        };
    };

    const workflowLabNavLinkGroup: INavLinkGroup = {
        links:
            workflowsLab.workflowLabIds
                .map((id) => workflowsLab.workflowsLab[id])
                .map(mapWorkflowLab) ?? [],
    };

    const renderWorkflowList = (groups: INavLinkGroup[]) => {
        return (
            //workflow = workflowlab => da sistemare
            workflowLabNavLinkGroup.links.length === 0 
                ?
                    <div className={classNames.emptyFolderContainer}>
                        <TeamsImage styles={{ img: { width: '60%' } }} imageName={ImageName.EmptyFolderDrop} caption={t('common:emptyList')} />
                    </div> 
                :
                    <Nav
                        styles={classNames.subComponentStyles.nav}
                        groups={groups}
                        onLinkClick={(_, item) => navigator.changeWorkflowLab(parseInt(item?.key ?? '0'))}
                        selectedKey={selectedWorkflowLab.toString()}
                        onRenderLink={(link) => 
                            {
                                return(<>
                                <div className={classNames.renderLinkStyle}>{link?.name}</div>
                                {getContextualMenuItems(link?.workflowLab).length > 0 && 
                                    <Icon
                                        id={`more_${link?.workflowLab.id}`}
                                        iconName="More"
                                        className={classNames.moreButton}
                                        styles={classNames.subComponentStyles.moreIcon}
                                        style={openedMenuId === link?.workflowLab.id ? { visibility: 'visible' } : undefined}
                                        onClick={ev => {
                                            navigator.selectedWorkFlowLabId?.toString() === link?.key && ev.stopPropagation();
                                            setOpenedMenuId(link?.workflowLab.id);
                                        }}
                                    />
                                }
                                {openedMenuId === link?.workflowLab.id  &&
                                    <ContextualMenu
                                        items={getContextualMenuItems(link?.workflowLab)}
                                        target={`#more_${link?.workflowLab.id}`}
                                        onDismiss={() => setOpenedMenuId(undefined)}
                                    />
                                }                            
                            </>)
                        }}
                    />
        );
    };

    return (
        <>
            <div className={classNames.root}>
                <TitleListSection
                    title={t('common:title')}
                    isReloading={workflowsLab.isLoading}
                    refreshButtonHandler={() => dispatch(getWorkflowsLab())}
                />
                <NavigationContainer
                    isError={workflowsLab.isError}
                    isLoading={workflowsLab.isLoading}
                    canUserCreateResource={canUserCreateWorkflowLab}
                    createResourceText={t('createNew')}
                    onClickFooterButton={() => setCreateWorkflowLabModal(true)}
                    iconName={"FormLibraryMirrored"}
                >
                    <>
                        {workflowLabNavLinkGroup.links.length > 0 && renderWorkflowList([workflowLabNavLinkGroup])}
                    </>
                </NavigationContainer>
            </div>
            {createWorkflowLabModal && <CreateWorkflowLabModal onClose={() => setCreateWorkflowLabModal(false)} />}
        </>
    );
}