/*eslint-disable sonarjs/cognitive-complexity*/
import React, { useCallback, useEffect, useState } from "react";
import { classNamesFunction, Icon, IconButton, IDropdownOption, Label, Stack, TextField, Toggle, TooltipHost } from "@fluentui/react";
import { ISetPropertiesPropsStyles, ISetPropertiesStyles, ISetPropertiesProps } from "./setProperties.types";
import { useTranslation } from "react-i18next";
import { Constants } from "../../../../../models/constants";
import DatePicker from "../../../../../common/components/datePicker/datePicker";
import { useConst } from '@fluentui/react-hooks';
import { FirstPageData } from "../createWorkflow.types";
import { templateApi } from "../../../../services/template.api";
import { Helpers } from "../../../../../utilities/helpers";
import { useWorkflowState } from "../../../../workflowStore";
import AdvancedDropdown from "../../../../../common/components/advancedDropdown/advancedDropdown";

const getClassNames = classNamesFunction<ISetPropertiesPropsStyles, ISetPropertiesStyles>();

export const SetPropertiesBase = (props: ISetPropertiesProps) => {
    const today = useConst(new Date(Date.now()));
    const tommorrow = () => {
        const result = new Date(today);
        result.setDate(today.getDate() + 1);
        return result;
    };

    const [data, setData] = useState<FirstPageData>(props.initialState);
    const [validationIcon, setValidationIcon] = useState(' ');
    const selectedWorkflowLab = useWorkflowState().workflowList.selectedWorkflowLab;
    const displayDatePicker = props.isTemplate ? false : true;

    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className, validationIcon: validationIcon });
    const { t } = useTranslation([props.translationName, 'common']);

    const onNameValidationResult = useCallback((error: string | JSX.Element, value: string | undefined) => {
        setData({ ...data, isValid: (!error && (data.expireDate !== undefined || !displayDatePicker) ), name: value?.trim() ?? ""});
        setValidationIcon(error ? ' ' : 'Completed');
    }, [data, displayDatePicker]);

    useEffect(() => props.onWorkflowDataChange(data), [data, props]);

    const validateWorkflowName = useCallback(async (value: string) => {
        if (!value || value.trim() === "")
            return `${t(' ')}`;
        setValidationIcon('ProgressRingDots');

        try {
            if(!displayDatePicker){
                if (props.initialState.name === value) {
                    setValidationIcon('Completed');
                    return "";
                }
                const [isValid] = await Promise.all([templateApi.isWorkflowTemplateNameAvailable(value, selectedWorkflowLab), Helpers.delay(500)]);
                return isValid ? "" : t('common:nameNotAvailable');
            }
        }
        catch {
            return t('common:genericErrorCheckName');
        }
    }, [t, displayDatePicker, selectedWorkflowLab]); //eslint-disable-line react-hooks/exhaustive-deps

    const iconWithTooltip = (translationName: string) => {
        return(
            <TooltipHost content={t(translationName)} id={'aas'}>
                <IconButton
                    aria-label={'more info'}
                    iconProps={{ iconName: 'Info' }}
                />
            </TooltipHost>
        );
    }

    return (
        <>
            <div className={classNames.formItem}>
                <TextField
                    label={t('setProperties.nameLabel')}
                    maxLength={Constants.MAX_WORKFLOW_NAME}
                    placeholder={t('setProperties.namePlaceholder')}
                    onGetErrorMessage={validateWorkflowName}
                    required
                    defaultValue={data.name.trim()}
                    autoFocus
                    autoComplete={"off"}
                    styles={classNames.subComponentStyles.textField}
                    onNotifyValidationResult={onNameValidationResult}
                    onChange={(_, newValue) => setData({ ...data, name: newValue?.trim() || "", isValid: !newValue && (data.expireDate !== undefined)  })}
                    onRenderSuffix={() => <Icon iconName={validationIcon} />}
                />
            </div>
            <div className={classNames.formItem}>
                <TextField
                    label={t('setProperties.descriptionLabel')}
                    maxLength={Constants.MAX_WORKFLOW_DESCRIPTION}
                    resizable={false}
                    rows={4}
                    multiline
                    defaultValue={data.description?.trim()}
                    placeholder={t('setProperties.descriptionPlaceholder')}
                    onChange={(_, newValue) => setData({ ...data, description: newValue?.trim() || "" })}
                />
            </div>
            <div className={classNames.datePicketDropdownGroup}>
                {displayDatePicker && <div className={classNames.dropdownItem}>
                    <DatePicker
                        isRequired
                        key={"endDate"}
                        dateCallback={(newValue) => {
                            setData({...data, expireDate: newValue, isValid: (data.name.trim() !== "" && newValue !== undefined)});
                        }}
                        minDate={tommorrow()}
                        placeholder={t('setProperties.selectDay')}
                        label={t('setProperties.endDate')}
                        inputValue={data.expireDate}
                    />
                </div>}
                {displayDatePicker &&
                <AdvancedDropdown
                    key={data.templateSelected?.toString()}
                    selectedItems={data.templateSelected ? [data.templateSelected]: []}
                    multiselect={false}
                    label={t('setProperties.selectTemplate')}
                    placeholder={t('common:dropDownPlaceholder')}
                    options={
                        props.template?.map(t => {
                        return {
                            key: t.id,
                            text: t.name
                        }
                    }) as IDropdownOption[] }
                    disabled={props.loadingTemplates ?? false}
                    onChangeItem={(key) => setData({...data, templateSelected: key as number, disjointed: props.template?.find(t => t.id === key)?.disjointed})}
                    onCancelSelection={() => setData({...data, templateSelected: undefined})}
                />
                }
            </div>
            <Stack style={{marginTop: 10}}>
                <Label>{t('setProperties.workflowOption')}</Label>
                <Toggle
                    className={classNames.toggle}
                    label={
                        <div className={classNames.toggleItems}>
                            {t('setProperties.disjointed')}
                            {iconWithTooltip('setProperties.toggle')}
                        </div>}
                    inlineLabel
                    onText=' '
                    offText=' '
                    checked={data.disjointed}
                    disabled={data.templateSelected ? true : false}
                    onChange={(_, checked) => { setData({ ...data, disjointed: checked }); }}
                />
                {displayDatePicker &&
                <Toggle
                    className={classNames.toggle}
                    label={
                        <div className={classNames.toggleItems}>
                            {t('setProperties.isDocumentAttachable')}
                            {iconWithTooltip('setProperties.toggleUpload')}
                        </div>}
                    inlineLabel
                    onText=' '
                    offText=' '
                    checked={data.isFileStepAttachable}
                    onChange={(_, checked) => setData({ ...data, isFileStepAttachable: checked })}
                />}
            </Stack>
        </>
    )
}