import { styled } from "@fluentui/react";
import { EdiLinkModalBase } from "./ediLinkModal.base";
import { getStyles } from "./ediLinkModal.styles";
import { IEdiLinkModalProps, IEdiLinkModalPropsStyles, IEdiLinkModalStyles } from "./ediLinkModal.types";

const EdiLinkModal = styled<IEdiLinkModalProps, IEdiLinkModalPropsStyles, IEdiLinkModalStyles>(
    EdiLinkModalBase,
    getStyles,
    undefined,
    { scope: 'CopyLinkModal' }
);

export default EdiLinkModal;