/*eslint-disable sonarjs/cognitive-complexity*/
import React, { useEffect, useState } from "react";
import { classNamesFunction, Icon, Stack, StackItem, Toggle, TooltipHost } from "@fluentui/react";
import { ITeamsFilePropsStyles, ITeamsFileStyles, ITeamsFileProps } from "./teamsFile.types";
import { useTranslation } from "react-i18next";
import SPOFolderContent from "./SPOfolderContent/SPOfolderContent";
import { FileShort } from "../../../../../models/fileShort";
import TeamNavigation from "./SPOShowTeams/teamNavigation";
import { isPdf } from "../../../../utilities/workflowUtilities";

const getClassNames = classNamesFunction<ITeamsFilePropsStyles, ITeamsFileStyles>();
export type SecondPageData = {
    file: FileShort | undefined;
    isPdf: boolean;
}

export const TeamsFileBase = (props: ITeamsFileProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const { t } = useTranslation(['createWorkflow', 'common']);
    const [data, setData] = useState<SecondPageData>(props.initialState);
    const [showTeams, setShowTeams] = useState(true);
    const [currentTeam, setCurrentTeam] = useState<FileShort | undefined>(undefined);
    const [isEditable, setIsEditable] = useState(false);
    const [isPDF, setIsPDF] = useState(false);

    function _onChange(ev: React.MouseEvent<HTMLElement>, checked?: boolean) {
        if (checked !== undefined) {
            if (isPDF) {
                setIsEditable(false);
                if(props.onFileEdit)
                    props.onFileEdit(false);
            } else {
                setIsEditable(checked);
                if(props.onFileEdit)
                    props.onFileEdit(checked);
            }
        }
    }
    const updateData = (newFile: FileShort) => {
        if (newFile === undefined) {
            setIsPDF(false);
        }
        if (isPdf(newFile)) {
            setIsEditable(false);
            if(props.onFileEdit)
                props.onFileEdit(false);
            setIsPDF(true);
        } else {
            setIsPDF(false);
            if(props.onFileEdit)
                props.onFileEdit(isEditable);
        }

        setData({ isPdf: newFile && isPdf(newFile), file: { ...newFile, teamId: currentTeam?.uniqueId } });
    }

    const onLevelUp = () => {
        setCurrentTeam(undefined);
        setShowTeams(true);
    }

    const onLevelDown = (team: FileShort) => {
        setShowTeams(false);
        setCurrentTeam(team);
    }

    useEffect(() => props.onWorkflowDataChange(data), [data, props]);

    return (
        <div>
            <Stack className={classNames.root} horizontal>
                <StackItem style={{ marginRight: 5 }}>{t('selectDocument')}</StackItem>
                <TooltipHost content={t('selectDocumentTooltip')}>
                    <Icon
                        styles={{ root: { marginTop: 2, cursor: 'pointer' } }}
                        iconName="Info"
                    />
                </TooltipHost>
            </Stack>
            <Stack>
                {showTeams
                    ?
                    <TeamNavigation onTeamSelected={onLevelDown} />
                    :
                    <SPOFolderContent
                        isError={(err) => console.log(err)}
                        maxNumFiles={1}
                        disableFolderSelection
                        enabledFileTypes={['.doc', '.docx', '.pdf', '.xlsx', '.pptx', '.ppt']}
                        onSelectionChange={(newFile: FileShort[]) => updateData(newFile[0])}
                        selectAllEnabled={false}
                        selection={data.file ? [data.file] : []}
                        onLevelUp={onLevelUp}
                        selectedTeam={currentTeam}
                    />
                }
            </Stack>
            <Stack>
                <Toggle hidden={props.onlyReadable ?? false} disabled={isPDF} checked={isEditable} label={<div>{t("toggle.label")}</div>} onText={t("toggle.on")} offText={t("toggle.off")} onChange={_onChange} />
            </Stack>
        </div>
    );
}