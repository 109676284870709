import { mergeStyleSets } from "@fluentui/merge-styles";
import * as microsoftTeams from "@microsoft/teams-js";
import React, { useEffect } from 'react';
import TeamsSpinner from "../../../common/components/teamsSpinner/teamsSpinner";
import TeamsAuthService from "../../../modules/authentication/teamsAuthService";

const classNames = mergeStyleSets({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%'
  }
});

function LoginPopup() {
  useEffect(() => {
    if (!microsoftTeams) {
      return;
    }

    microsoftTeams.initialize(() => {
      microsoftTeams.getContext(async (context) => {
        if (!context) {
          microsoftTeams.authentication.notifyFailure("Unexpected failure - missing Teams context");
          return;
        }

        if (!TeamsAuthService.isLoggedIn()) {
          TeamsAuthService.login();
          return;
        }

        TeamsAuthService.getAccessToken()
          .then((response) => {
            if (!response) {
              microsoftTeams.authentication.notifyFailure("Unexpected failure - missing response");
              return;
            }

            microsoftTeams.authentication.notifySuccess(response);
          })
          .catch((error) => {
            console.log(error);
            microsoftTeams.authentication.notifyFailure(error);
          });
      });
    });
  }, []);

  return (
    <div className={classNames.root}>
      <TeamsSpinner />
    </div>
  );
}

export default LoginPopup;