/*eslint-disable sonarjs/no-duplicate-string */
import { WorkFlowApproverStatus, WorkFlowStepRelativeStatus, WorkFlowStepStatus, WorkFlowStepTypes } from "../../../models/WorkFlow";
import { UploadErrors } from "../../../common/components/fileUploader/fileUploader.types";
import { WorkFlowRelativeStatus } from "../../../models/WorkflowEnum";
import { ApproveWorkFlowStepType } from "../../../workflow/services/workflowstep/workflowstep.contracts";

export const en = {
    authentication: {
        errorMessage1: 'Something went wrong.',
        errorMessage2: 'Check browser permissions in order to allow popups and retry.',
        accessDenied: 'Access not allowed. Contact the administrator to be enabled'
    },
    workflowOverview: {
        title: 'Requests Overview',
        notPermissions: "Access denied",
        startRequest: 'Start request',
        deletedWorkflow: 'Workflow deleted successfully',
        deleteWorkflowOperation: 'Delete Workflow',
        search: 'Search request',
        seeAll: 'See all',
        seeDisjointed: 'See only multiple WFs',
        delete:'Delete',
        save:'Save',
        column: {
            name: 'Request Name',
            template: 'Template used',
            status: 'Status',
            createdOn: 'Creation Date',
            requestedBy: 'Requested By',
            assignedTo: 'Assigned To',
            members: 'Users Involved',
            expirationDate: 'Expiration Date'
        },
        filterByCreationDate: 'Creation date:', 
        filterByStatus: 'Status:',
        selectOptions: 'Select options',
        status: {
            [WorkFlowRelativeStatus.Ongoing]: 'Ongoing',
            [WorkFlowRelativeStatus.Approved]: 'Approved',
            [WorkFlowRelativeStatus.Refused]: 'Refused',
            [WorkFlowRelativeStatus.ToMe]: 'Assigned to me',
        },
        receivedRequests: 'Received Workflows',
        sentRequests: 'Sent Workflows',
        deleteTitle: 'Delete?',
        deleteMessage: 'Are you sure to delete this workflow?',
        deleteMessageDisjointed: 'Are you sure to delete this multiple workflow?',
        saveFileTitle:'Copy to EDI',
        saveFileSubTitle:'Copy the workflow file to an EDI folder',
        saveFilesSubTitle:'Copy the workflow disjointed files to an EDI folder',
        saveDocsSubTitle: 'Copy the document(s) to an EDI folder',
    },
    copyOnEdi: {
        saveWorkflowButton:'Copy here',
        savedWorkflow:'Copied successfully',
        savedWorkflowsSuccess:'Copied successfully: ',
        savedWorkflowsError:'Copy failed: ',
        saveWorkflowOperation:'Copy operation',
        savedDocuments: 'Documents copied successfully: ',
        errorDocs: 'failures:',
        saveDocumentsOperation: 'Additional documents copy',
        uploadMessage: 'Ready for upload',
        endMessage: 'Upload in progress',
        upload:'Upload'
    },
    workflowDisjointed:{
        urges: 'Reminder'
    },
    templatesOverview:{
        column: {
            name: 'Template Name',
            createdBy: 'Created By',
            createdOn: 'Creation Date',
            action: 'Actions',
            description: 'Description',
            documentName: 'Document Name',
        },
    },
    common: {
        nameNotAvailable: "Name not available",
        genericErrorApi: "Something went wrong",
        unAuthorized: "Unathroized",
        notFound: "Could not find what you're looking for... May has been deleted or you have no access to it",
        fieldRequired: 'Required field',
        nextButton: 'Next',
        previousButton: 'Back',
        leave: 'Leave',
        close: 'Close',
        abort: 'Cancel',
        confirmEliminate: 'Yes, delete',
        undoEliminate: 'No, go back',
        save: 'Save',
        done: 'Done',
        dialogButtonCancel: 'No, go back',
        dialogButtonConfirm: 'Yes, cancel',
        deletetitle: 'Delete?',
        delete: 'Delete',
        dialogButtonConfirmClose: 'Yes, close',
        confirm: 'Confirm',
        confirmSave: 'Are you sure you want to save?',
        dialogButtonConfirmDeleting: 'Yes, delete',
        name: 'Name',
        description: 'Description',
        titleLab:'Title',
        add: 'Add',
        field: 'Field',
        loading: 'Loading...',
        niceWork: 'Nice work!',
        tryAgain: 'Try again',
        create: 'Create',
        continue: 'Continue',
        cancel: 'Cancel',
        update: 'Update',
        authErrorTitle: 'Authentication error',
        operationFailed: 'Operation failed',
        notifications: 'Notifications',
        authErrorMessage: "Please allow pop-ups from your browser settings and try again.",
        retry: 'Retry',
        preposition: ' of ',
        genericErrorCheckName: "Error during checking name",
        emptyList: 'The list is empty',
        emptyFolder: 'The folder is empty',
        excelCel: 'Excel cell',
        isInvalid: 'is invalid:',
        excelFormatError: 'Invalid Excel file format:',
        EmptyFile: 'excel sheet is empty',
        ColumnsPosition: 'only the first two columns must be filled',
        CellRequired: 'cell value is required',
        RegexNotMatched: 'wrong format',
        fileNotFound: 'File not found',
        labelsshort: 'Labels',
        commands: {
            open: 'Open',
            openInBrowser: 'Open in browser',
            createLink: 'Create link',
            copyDocumentId: 'Copy ID Document',
            viewTag: "View Tag",
            download: 'Download',
            delete: 'Delete',
            manageUsers: 'Manage Users',
            template: 'Workflow Template',
            renameWF: 'Rename Workflow',
            edit: 'Edit',
            move: 'Move',
            copy: 'Copy'
        },
        statusLabel: {
            ongoing: "Ongoing",
            completed: "Completed",
            error: "Error"
        },
        zoomIn: 'Zoom in',
        zoomOut: 'Zoom out',
        reset: 'Reset',
        manageUsers: "Manage Users",
        manageTempalte: "Manage Workflow Template",
        renameWF: 'Rename Workflow',
        viewUsers: "View Users",
        emptySearch: 'No results found',
        role: 'Role',
        labels: 'Labels associated with the user',
        error: 'Error',
        apply: 'Apply',
        search: 'Search',
        selectAll: 'Select All',
        deselectAll: 'Deselect All',
        warning: 'Warning',
        savingError: "Error while saving. Please try again.",
        noResults: 'No result',
        gotIt: 'Got it',
        multiselectPlaceholder: 'Select options',
        dropDownPlaceholder: 'Select an option',
        title: 'Your Workflows Lab',
        requestSent: 'Request sent successfully',
        loadingTemplate: 'Loading Template',
        errorCode403: 'Insufficient priviledges',
        errorCode404: 'Item not found',
        errorCode409: 'Name already taken'        
    },
    deleteLabelModal:{
        confirmMessage: 'Are you sure to delete this label?',
    },
    deleteTemplateModal:{
        confirmMessage: 'Are you sure to delete this template?',
    },
    deleteCategoryModal:{
        delete: 'Delete?',
        confirmMessage: 'Are you sure to delete this category?'
    },
    removeAssociationModal:{
        removeAssociationLabel: 'Remove label from category',
        confirmMessage: 'Are you sure to remove this label from this category?'
    },
    toolbar: {
        overview: 'Overview',
        dashboard: 'Dashboard',
        workflow: 'Models',
        graphs: 'Surveys',        
    },
    notificationPanel: {
        emptyPlaceHolder: 'No Items',
        title: 'Information',
    },
    workflowLabManageUsers:{
        newLabel: 'New Label',
        newCategory: 'New Category',
        userview: 'User View',
        labelview: 'Label View',
        categoryview: 'Category View',
        labelLinkedToUsers: 'User labels',
        assignUserLabels: 'Assign labels to user',
        updateLabelUsers: 'Update label users',
        search: 'Search label',
        editContact: 'Edit Members',
        export: 'Export data', 
        import: 'Import data',
        synch: 'Synchronize data',
        searchLabel: 'Search label',
        category:'Categories: ',
        searchCategory: 'Search category',
        editLabel: 'Edit Label',
        editCategory: 'Edit Category',
        editCategoryLabels: 'Update Labels',
        errorMessageEditLabel: "Invalid label name",
        errorMessageUserLabels: "Max 100 labels per user",
        synchCompleted:'Synchronization completed',
        synchWorkflowLabOperation:'Synchronize Workflow Lab',
        syncConfirmTitle: 'Synchronization',
        syncConfirmSubTitle: 'Are you sure you want to proceed?',
        syncCancelLabel: 'No',
        syncConfirmLabel: 'Yes'
    },
    workflowLabManageTemplates:{
        templatesview: 'Templates',
        search: 'Search label',
    },
    workflowLabCategory:{
        newCategory: 'New Category',
        search: 'Search Category',
    },
    workFlowSummary: {
        label: 'Involved users',
        exportDisjoint: "See recipients workflow details",
        categoriesDisjoint : "Disjunction category:",
        noApproverFound : "Some approvers not found",
    },
    workflowDetails: {
        info: {
            status: {
                [WorkFlowRelativeStatus.Ongoing]: 'Workflow in progress and I have been involved in the steps started up to now, but I don\'t have to do any operations or Workflow in progress and I am the workflow requestor',
                [WorkFlowRelativeStatus.Approved]: 'Workflow completed successfully',
                [WorkFlowRelativeStatus.Refused]: 'Workflow ended with a refusal',
                [WorkFlowRelativeStatus.ToMe]: 'Workflow with request for action in charge of me',
            },
            secondLineStatus: {
                [WorkFlowRelativeStatus.Ongoing]: 'Click on the attached document to view content and/or make changes',
                [WorkFlowRelativeStatus.Approved]: 'Click on the attached document to view content',
                [WorkFlowRelativeStatus.Refused]: 'Click on the attached document to view content',
                [WorkFlowRelativeStatus.ToMe]: 'Click on the attached document to view content and/or make changes',
            },
            title: 'Info',
            workflowname: 'Request name',
            document: 'Attached document',
            createdBy: 'Applicant', 
            stepApprover: 'Approver'           
        },
        sendReminder:'Send Reminder',
        history: 'History',
        action: {
            title: 'Required Action',
        },
        iter:{
            title: 'Approval process',
            column:{
                step:'Step',
                iter:'Iter',
                status:'Status',
                rules:'Rules',
                actionHistory:'Action History',
                comments:'Comments',                
                approvers: "Approvers"
            },
            userActionWithDate: 'on',
        },
        document:{
            title: 'Attached Documents',
            column:{
                documentName:'Document',
                stepName:'Step',
                approverName:'Uploaded by',
            },
        },
        documentEditable: 'The document can be edited', 
        documentNotEditable: 'The document cannot be edited',
        editApprovers: 'Edit approvers',
        userCommented: 'commented: ',
        copyAdditional: 'Copy additional documents to EDI',
        copyPartialAdditional: 'Copy remaining documents to EDI',
        exportReport: 'Export report'
    },
    stepHistory:{
        history: 'History',
        historyModalSubtitile: 'History of the actions made on this workflow',
        column: {
            step: "Step",
            approver: "Approver",
            status: "Step Status",
            actionType: "Action",
            comment: "Comment"
        },
        status: {
            [WorkFlowStepStatus.Idle]: 'Not Started',
            [WorkFlowStepStatus.ToBeApproved]: 'Pending',
            [WorkFlowStepStatus.Approved]: 'Approved',
            [WorkFlowStepStatus.Refused]: 'Refused',
        },
        actions: {
            [ApproveWorkFlowStepType.Approved]: "Approved",
            [ApproveWorkFlowStepType.Rejected]: "Rejected",
            [ApproveWorkFlowStepType.StepBack]: "Sent step back",
        }
    },
    ediLinkModal: {
        title: 'Link to EDI',
        button: 'Open in EDI',
        genericError: 'Something went wrong',
        notPermissionsError: 'Not authorized access',
        notFoundError: 'File not found',
    },
    stepStatus: {
        title: 'STEP #{{order}}:',
        userAction: '',
        status: {
            [WorkFlowStepRelativeStatus.Idle]: '',
            [WorkFlowStepRelativeStatus.ToBeApproved]: 'Pending',
            [WorkFlowStepRelativeStatus.Approved]: 'Approved',
            [WorkFlowStepRelativeStatus.Refused]: 'Refused',
            [WorkFlowStepRelativeStatus.ToMe]: 'Pending',
        },
        approverStatus: {
            [WorkFlowApproverStatus.ToBeApproved]: 'Pending',
            [WorkFlowApproverStatus.Approved]: 'Approved',
            [WorkFlowApproverStatus.Refused]: 'Refused',
        },
        type: {
            [WorkFlowStepTypes.OnlyOneApprover]: 'Only one approver',
            [WorkFlowStepTypes.OnlyOneSignedApprover]: 'Only one approver with signature',
            [WorkFlowStepTypes.EveryoneMustApprove]: 'Everyone must approve',
        }
    },
    workflowLabUsersSettings: {
        searchBox: 'Search member',
        addMembers: 'Add members',
        errorRemoveMembers: 'Member cannot be removed as it is present in at least one portfolio project.',
        updateMembership: "Update membership of ",
        createAtLeastOneTemplate: 'Create at least one template for this portfolio',
        templateSettings: {
            title: 'Make the following types of projects visible:'
        },        
        deleteTitle: 'Delete?',
        deleteMessage: 'Are you sure you want to remove the user?',
        confirmEliminate: 'Yes, delete',
        undoEliminate: 'No, go back',
        setLabels: 'Add label',
        LabelSettings:{
            title: 'Labels'
        },
        errorMessage: 'Max 100 labels'
    },
    workflowLabTemplateSettings: {
        searchBox: 'Search template',
        addTemplates: 'Add template',    
        deleteTitle: 'Delete?',
        deleteMessage: 'Are you sure you want to remove the template?',
        confirmEliminate: 'Yes, delete',
        undoEliminate: 'No, go back'
    },
    workflowApprovalStep: {
        title: 'Step',
        everyoneMustApproveApproverDesctioption: "Everyone's approval is required for this step",
        onlyOneApproverDesctioption: 'At least one approval is required for this step',
        onlyOneSignedApproverDesctioption: 'A digital signature is required for this step',
        secondDesctioption: 'Text a comment',
        tooltipDescription: 'The comment will be visible to all approvers of the workflow. In case of refusal, the comment is mandatory',
        approve: 'Approve',
        signedApprove: 'Approve with signature',
        reject: 'Reject and cancel workflow',
        rejectToolTip: 'Text a comment to reject. Note that clicking this button will irrevocably lock the workflow, so please contact the workflow creator in advance',
        commentPlaceholder: 'Text a comment',
        notPermissions: "You aren't among the members",
        notFound: 'Step not found',
        unprocessable: 'Request is unprocessable',
        uploadFile: 'Upload File',
        titleUploadModal: 'Upload a File',
        subtitleUploadModal: 'Choose a file to attached to the current step',
        stepBackButton: 'Step Back',
        errorUploadFile: 'Error, File name already exists',
        confirmReject: 'Are you sure you want to reject and permanently close this workflow?'
    },
    workflowApprovalModal: {
        firstStepTitle: 'Sign the document',
        userIdToolTipDescrioption: 'User ID',
        pinToolTipDescrioption: 'User ID',
        certificate: 'Select a certificate',
        otpRequest: 'Request OTP',
        insertOtp: 'Insert OTP',
        signatureInProgress: 'Signature in progress',
        signatureDescription: 'We are signing the document.',
        secondSignatureDescription: "Please wait a few seconds.",
        signatureSuccess: 'Successfully signed',
        firsStepDescription: 'There is no signing certificate associated with your user',
        firsStepInfoDescription: 'For more information',
        digitalSignature: 'digital signature',
        insertPin: 'Insert Pin',
        insertUser: 'Insert User ID',
        notPermissions: "Access denied for user",
        notFound: 'Step not found',
        unprocessable: 'Request is unprocessable',
        signatureTooltip: 'Insert the OTP code that was sent to you via email',
        pinTooltip: 'Insert a pin with 8 numbers',
        wrongRequest: 'Pin or certificate are not valid',
        certificateNotFound: 'Certificate not found',
        editText: 'Changes will not be applied to the projects already entered'
    },
    createActivity: {
        low: "Low",
        medium: "Medium",
        high: "High",
        title: 'Plan projects',
        activityName: 'Project name',
        activityNamePlaceholder: 'Type a new project name',
        description: 'Description',
        startDate: "Start date",
        selectDay: 'Select a day',
        endDate: 'Due date',
        selectBudget: 'Assign a budget',
        priority: 'Priority',
        toActivityLeader: 'Assign to a Project Leader',
        activityTemplate: 'Project template',
        messageForActivity: "The project leader will receive a notification for the assignment and automatic creation of a Teams",
        analysisAlreadyExist: 'Project name already exist',
        endMessage: 'The project has been created, the team is being created',
        end: 'Ok',
        error: 'An error occurred while creating the project. Wait a few minutes and try again',
        dataPickerStrings: {
            "closeButtonAriaLabel": "Close date picker",
            "dayMarkedAriaLabel": "Checked",
            "days": ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
            "goToToday": "Go to today",
            "invalidInputErrorMessage": "Invalid date format",
            "isRequiredErrorMessage": "Required field",
            "monthPickerHeaderAriaLabel": "{0}, change year",
            "shortMonths": ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            "months": ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
            "shortDays": ['S', 'M', 'T', 'W', 'T', 'F', 'S']
        },
        leadersPlaceholder: 'Start typing a name',
        minLengthAlert: "Type at least three characters",
        noResults: "No people match the specified keyword",
        creatingActivity: 'Create project...'
    },
    labelList:{
        emptyLabelList:'No labels associated'
    },
    createWorkflow: {
        title: 'Start request',
        subtitle: 'Start a workflow request',
        selectDocument: 'Select the document to workflow',
        selectDocumentTooltip: 'Warning! Selecting a file with a format other than PDF does not allow you to choose the "Approval with digital signature" rule',
        noResults: 'No result',
        setProperties: {
            nameLabel: 'Request name',
            descriptionLabel: 'Request description',
            endDate: 'Expiration date',
            selectDay: 'Select a day',
            namePlaceholder: 'Name',
            descriptionPlaceholder: 'Description',
            selectTemplate: 'Do you want to use a created template?',
            workflowOption: 'Workflow option',
            disjointed: 'Multiple workflows',
            toggle: 'By selecting this option, you will create multiple workflow',
            templatePlaceholder:'Select a template',
            isDocumentAttachable: 'Possibility to attach a document in response',
            toggleUpload: 'You will allow upload of other documents in steps',
        },
        choiceInputSource: {
            teams: 'Teams',
            teamsSubtitle: 'Upload a document from teams',
            local: 'Local',
            localSubtitle: 'Upload a document from your device',
            edi: 'EDI',
            ediSubtitle: 'Upload a document from EDI',
        },
        localFile: {
            subtitle: 'Upload file',
            text: 'Enter the document to be submitted to Workflow',
            tooltip: 'As of now, EWE cannot handle files heavier than 100 MB.'
        },
        addSteps: {
            title: "Definition of Workflow ",
            namePlaceholder: "Enter name...",
            nameLabel: "Step name",
            dropdownLabel: "Select the approval rule",
            dropdownPlaceholder: "Select...",
            peoplePlaceholder: 'Start typing a name',
            approvers: "Step approvers",
            noResult: "No result",
            addStep: "Add step",
            minLengthAlert: "Type at least three characters",
            maxOneApprover: "Max one approver",
            errorType: "The selected rule can only be used for pdf files"
        },
        addDisjointment:{
            addDisjointment:"Add Category"
        },
        workflowTypes: {
            onlyOneApprover: "At least one must approve",
            everyoneMustApprove: "Everyone must approve",
            onlyOneSignedApprover: "Approve with digital sign"
        },
        columns: {
            name: "Name",
            createdOn: "Created on",
            createdBy: "Created by"
        },
        toggle:{
            label: "File Options",
            on : "The document can be edited",
            off : "The document can't be edited",
            error : "Uneditable file"
        }, 
        creatingWorkflow:'Creating Workflow',
        checkDeleteWorkflow: {
            title: "Do you want to cancel the Workflow request?",
            message: "If you close the window without completing workflow creation, the details you entered will be lost. Do you want to cancel this workflow creation?",
        },
        selectDisjointmentCategory:{
            dropdownCategory:"Disambiguate this workflow request",
            dropdownSubCategory:"Select from the list the category to disambiguate the request",
            dropdownSubLabel:"Select the category values to make the disjointed workflow",
            labelwarning:"Workflow instances: ",
        }
    },
    uploadFileModal: {
        title: 'Upload',
        endMessage: 'We are analyzing your documents. You will soon find out the result!',
        subtitle: 'The upload of so many files can take a long time. We suggest to open the EWE application in a new window to prevent a data loss.',
        [UploadErrors.LengthOutOfRange]: 'Minimum file size not allowed',
        [UploadErrors.NameInvalid]: 'Invalid file name',
        [UploadErrors.MimeTypeNotAllowed]: 'File type not allowed',
        [UploadErrors.InvalidFileSignature]: 'File type check failed',
        [UploadErrors.TooLarge]: "File size exceeds limit (100MB)",
        uploadFileAlreadyExists: "A file with same name already exists",
        uploadGenericError: 'Error while uploading file',
        uploadConflictError: 'File already exists',
        uploadUnprocessableError: 'Archive capacity limit reached. ',
        invalidExcel: "The file is not formatted correctly. Follow instructions above and try again.",
        uploadingText: 'Uploading file', 
        of: 'of',
        uploadedText: 'uploaded files', 
        picker: {
            fileAlreadyUploaded: "File already uploaded",
            fileTypeNotAllowed: "File type not allowed",
            info: "File types allowed: pdf, doc, docx, pptx, xlsx, ppt.",
            disclaimer: 'If you close this window, the upload process may be interrupted.'
        },
        dropzone: {
            msg1: "Drop a file here",
            msg2: "or",
            button: 'Explore'
        },
        footer: {
            abort: 'Abort',
            confirm: 'Continue',
            end: 'Got it',
            back: 'Back',
        },
        capacity: {
            used: 'Used Space : ',
            total: 'Total Space : ',
            free: 'Free Space : ',
        },
        detailsList:{
            documentName:'Name',
            documentTag:'Tag'
        },
        tags:'Choose tag'
    },
    membersPageForAddWorkFlowLabMembers: {
        description1: "Start typing a name to add to your WorkFlowLab.",
        description2: "You can add Eni people (*@eni.com or *@external.eni.com) who already have EWE on their Teams.",
        minLengthAlert: "Type at least three characters",
        noResults: "No people match the specified keyword"
    },
    dashboard: {
        workflowStatus:{
            title:'Workflows Status',
            titleDisjointed: 'Workflow Multiple Status'
        },
        empty:"There is no data",
        exportReport: 'Export report'
    },
    workflow: {
        status: {
            [WorkFlowRelativeStatus.Ongoing]: 'Ongoing',
            [WorkFlowRelativeStatus.Approved]: 'Approved',
            [WorkFlowRelativeStatus.Refused]: 'Refused',
            [WorkFlowRelativeStatus.ToMe]: 'Assigned to me',
        },
        sentWorkflowRequests: 'Sent Workflow requests',
        receivedWorkflowRequests: 'Received Workflow requests',
        filters: {
            filterCreationDate: 'Creation date:',
            filterExpirationDate: 'Expiration date:',
            filterCreatedBy: 'Created by:',
            filterBy: 'Filter by:',
            all: 'All',
            lastMonth: 'Last month',
            lastWeek: 'Last week',
            nextMonth: 'Next month',
            nextWeek: 'Next week',
            custom: 'Custom',
            startDate: 'From',
            endDate: 'To',
            selectaDay:'Select a Day'
        }
    },
    archiveEDINavigation: {
        errorForbidden: "You don't have access on EDI"
    },
    modalEditApprovers: {
        description1: "Change step approvers",
        description2: "You can only select a new approver when you remove an existing one",
        minLengthAlert: "Type at least three characters.",
        noResults: "No people match the specified keyword"
    },
    workflowLabList: {
        createNew:'Create a WorkFlow Lab',
    },
    createWorkflowLabModal:{
        namePlaceholder:'Insert Workflow Lab Title',
        descriptionPlaceholder:'Insert Workflow Lab Description',
        firstStepTitle:'Create a Workflow Lab',
        genericErrorCheckName:'Generic Error Check',
        stopCreating:'Stop Creating Workflow Lab',
        secondStepTitle: 'Add members to Workflow Lab',
        creatingWorkflowLab: 'Creating Workflow Lab',
        ofWorkflowLab: ' with Workflow Lab Creation',
    },
    createLabelModal: {
        createLabel: "Create label",
        addName: "Add label name",
        errorMessage: "Invalid label name",
    },
    createCategoryModal: {
        createCategory: "Create category",
        addName: "Add category name"
    },
    labelsPageForAddCategoryLabels: {
        description1: "Start typing a name of a Label to add to your Category.",
        description2: "You can add Labels which are not already associated to a Category.",
        minLengthAlert: "Type at least three characters",
        noResults: "No label match the specified keyword"
    },
    uploadUserLabelModal: {
        notPermissions: "You don't have permissions",
        notFound: "WorkflowLab not found",
        ColumnsError : "Error on the number of columns",
        UserNotFound : "User not Found",        
        DuplicateUsers : "Duplicate Users",
        RoleNotFound : "Role Not Found",
        CanNotChangeYourRole: "You can't change your role",
        LabelNameNotValid: "Some labels name are not valid",
        AuditorHasNoLabels: "Auditor Role can't have Labels associate",
        AuditorCannotChangeRole: "Cannot change Auditor Role",
        firstPageTitle: 'Import Data',
        firstPageSubHeader: 'Massive upload of users, labels and categories',
        secondPage : 'We are updating Users and Labels, the operation will take a few minutes',
        end: 'OK',
        buttonForUploadFile: 'Upload',
        firstPhrase: 'Instructions for a correct compilation of the file',
        secondPhrase: 'The file must be Excel format',
        thirdPhrase:  'We suggest starting from the export file and modifying the data while maintaining the same structure.', 
        fourthPhrase: 'Otherwise the file structure must have:',
        firstColumnPhrase:'- a header row (FirstName, LastName, Email, Role, Labels, categories)',
        secondoColumnPhrase:'- in the first three columns, the Users\'s Name, Surname and email ',
        thirdColumnPhrase:'- in the fourth column, the Role (Owner, Supervisor or User)',
        fourthColumnPhrase: '- in the fifth column the simple Labels (separated by commas)',
        fifthColumnPhrase: '- from the sixth column the labels referenced to the categories (separated by commas)'      
    }, 
    createTemplateModal: {
        title: 'Workflow Template',
        subtitle: 'Create a workflow template',
        subtitle2: 'Edit a workflow template',
        selectDocument: 'Select the document to workflow template (optional)', 
        selectDocumentTooltip: 'Warning! Selecting a file with a format other than PDF does not allow you to choose the "Approval with digital signature" rule',
        setProperties: {
            nameLabel: 'Template name',
            descriptionLabel: 'Template description',
            namePlaceholder: 'Name',
            descriptionPlaceholder: 'Description',
            workflowOption: 'Workflow option',
            disjointed: 'Multiple workflows',
            toggle: 'By selecting this option, you will create multiple workflow',
        },
        choiceInputSource: {
            teams: 'Teams',
            teamsSubtitle: 'Upload a document from teams',
            local: 'Local',
            localSubtitle: 'Upload a document from your device',
            edi: 'EDI',
            ediSubtitle: 'Upload a document from EDI',
        },
        localFile: {
            subtitle: 'Upload file',
            text: 'Enter the document to be submitted to Workflow Template',
            tooltip: 'As of now, EWE cannot handle files heavier than 100 MB.'
        },
        addSteps: {
            title: "Definition of Workflow Template ",
            namePlaceholder: "Enter name...",
            nameLabel: "Step name",
            dropdownLabel: "Select the approval rule",
            dropdownPlaceholder: "Select...",
            peoplePlaceholder: 'Start typing a name',
            approvers: "Step approvers",
            noResult: "No result",
            addStep: "Add step",
            minLengthAlert: "Type at least three characters",
            maxOneApprover: "Max one approver",
            errorType: "The selected rule can only be used for pdf files"
        },
        workflowTypes: {
            onlyOneApprover: "At least one must approve",
            everyoneMustApprove: "Everyone must approve",
            onlyOneSignedApprover: "Approve with digital sign"
        },
        columns: {
            name: "Name",
            createdOn: "Created on",
            createdBy: "Created by"
        },
        toggle:{
            label: "File Options",
            on : "The document can be edited",
            off : "The document can't be edited",
            error : "Uneditable file"
        }, 
        creatingWorkflow:'Creating Workflow Template',
        updatingTemplate:'Editing Workflow Template',
        checkDeleteWorkflowTemplate: {
            title: "Do you want to cancel the Workflow Template creation?",
            message: "If you close the window without completing workflow template creation, the details you entered will be lost. Do you want to cancel this workflow template creation?",
        },
        checkDeleteEditTemplate: {
            title: "Do you want to cancel the Workflow Template edit?",
            message: "If you close the window without completing workflow template edit, the details you entered will be lost. Do you want to cancel this workflow template edit?",
        }
    },
    workflowTemplateDetails: {
        info: {
            status: {
                [WorkFlowRelativeStatus.Ongoing]: 'Workflow Template in progress and I have been involved in the steps started up to now, but I don\'t have to do any operations or Workflow in progress and I am the workflow requestor',
                [WorkFlowRelativeStatus.Approved]: 'Workflow Template completed successfully',
                [WorkFlowRelativeStatus.Refused]: 'Workflow Template ended with a refusal',
                [WorkFlowRelativeStatus.ToMe]: 'Workflow Template with request for action in charge of me',
            },
            secondLineStatus: {
                [WorkFlowRelativeStatus.Ongoing]: 'Click on the attached document to view content and/or make changes',
                [WorkFlowRelativeStatus.Approved]: 'Click on the attached document to view content',
                [WorkFlowRelativeStatus.Refused]: 'Click on the attached document to view content',
                [WorkFlowRelativeStatus.ToMe]: 'Click on the attached document to view content and/or make changes',
            },
            title: 'Info',
            workflowname: 'Template name',
            document: 'Attached document',
            createdBy: 'Applicant',
            stepApprover: 'Approver' 
        },
        sendReminder:'Send Reminder',
        action: {
            title: 'Required Action',
        },
        iter:{
            title: 'Approval process',
            column:{
                step:'Step',
                iter:'Iter',
                status:'Status',
                rules:'Rules',
                actionHistory:'Action History',
                comments:'Comments',                
                approvers: "Approvers"
            },
            userActionWithDate: 'on',
        }, 
        documentEditable: 'The document can be edited', 
        documentNotEditable: 'The document cannot be edited',
        editApprovers: 'Edit approvers',
        userCommented: 'commented: '
    },
}
