import { IStyleFunction } from "@fluentui/react"
import { IConfirmModalPropsStyles, IConfirmModalStyles } from "./confirmModal.types";

export const ConfirmModalClassNames = {
    root: 'ewe-workflowlab-confirm-modal',
    footerButtons: 'ewe-workflowlab-confirm-footerButtons',
    confirmButtonContainer: 'ewe-workflowlab-confirm-buttonContainer',
    confirmFooterContainer: 'ewe-workflowlab-confirm-footerContainer',
    confirmDialogFooterContainer: 'ewe-workflowlab-confirm-dialogFooterContainer',
}

export const getStyles: IStyleFunction<IConfirmModalPropsStyles, IConfirmModalStyles> = (props: IConfirmModalPropsStyles): IConfirmModalStyles => {
    const { palette } = props.theme!; // eslint-disable-line @typescript-eslint/no-non-null-assertion
    return {
        root: [
            ConfirmModalClassNames.root,
            {
                position: 'relative',
            }
        ],
        footerButtons: [
            ConfirmModalClassNames.footerButtons,
            {
                marginLeft: '100px'
            }
        ],
        confirmButtonContainer: [
            ConfirmModalClassNames.confirmButtonContainer,
            {
                display: 'flex',
                alignItems: 'flex-end'
            }
        ],
        confirmFooterContainer: [
            ConfirmModalClassNames.confirmFooterContainer,
            {
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center'
            }
        ],
        confirmDialogFooterContainer: [
            ConfirmModalClassNames.confirmDialogFooterContainer,
            {
                margin: '8px 0 0'
            }
        ],
        subComponentStyles: { 
            confirmPrimaryButtonDisabled: {
                root: {
                    selectors: {
                        ':disabled': {
                            backgroundColor: palette.neutralQuaternary,
                            color: palette.neutralTertiary,
                            pointerEvents: 'none'
                        },
                    }
                },
            }
        }
    }
};