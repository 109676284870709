import { styled } from "@fluentui/react";
import { getStyles } from "./archiveEDINavigation.styles";
import { IArchiveEDINavigationPropsStyles, IArchiveEDINavigationStyles, IArchiveEDINavigationProps } from "./archiveEDINavigation.types";
import { ArchiveEDINavigationBase } from "./archiveEDINavigation.base";

const ArchiveEDINavigation = styled<IArchiveEDINavigationProps, IArchiveEDINavigationPropsStyles, IArchiveEDINavigationStyles>(
    ArchiveEDINavigationBase,
    getStyles,
    undefined,
    { scope: 'ArchiveEDINavigation' }
);

export default ArchiveEDINavigation;