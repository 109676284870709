import { AxiosRequestConfig, CancelToken } from "axios";
import qs from "qs";
import { LabelAssociate } from "../../../models/labelView";
import { User } from "../../../models/User";
import { WorkflowLabMembership } from "../../../models/workflowLabMembership";
import { apiClient } from "../../../modules/apiClient/apiClient";
import { svcPaths } from "../../../modules/apiClient/config";
import { WorkFlowLabRoleId } from "../../../utilities/constants";
import { GetWorkflowLabAvailableMembersRequest, GetWorkflowLabMembersRequest } from "./workFlowLab.contracts";

class WorkflowLabApiClass {


    async getWorkflowLabMembers(request: GetWorkflowLabMembersRequest, cancelToken?: CancelToken) {
        const response = await apiClient.get<User[]>(`${svcPaths.workflow}/workflowLab/${request.id}/members`, {
            params: {
                keyword: request.keyword
            },
            cancelToken: cancelToken
        });
        return response.data;
    }

    async updateWorkflowLabMemberRole(workflowLabId: number, userId: string, newRole: WorkFlowLabRoleId) {
        const payload = { newRoleId: newRole };
        const response = await apiClient.put(`${svcPaths.workflow}/workflowLab/${workflowLabId}/members/${userId}`, payload);
        return response.data;
    }

    async deleteMember(workflowLabId: number, userId: string) {
        const response = await apiClient.delete(`${svcPaths.workflow}/workflowLab/${workflowLabId}/members/${userId}`);
        return response.data;
    }

    async getWorkflowLabAvailableMembers(request: GetWorkflowLabAvailableMembersRequest, cancelToken?: CancelToken) {
        const response = await apiClient.get<User[]>(`${svcPaths.workflow}/workflowLab/${request.workflowLabId}/available-members`, {
            params: {
                keyword: request.keyword,
                pageNumber: request.pageNumber,
                pageSize: request.pageSize,
                excludeCurrentUser: true,
                userIds: request.userIds
            },
            paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
            cancelToken: cancelToken
        });
        return response.data;
    }

    async addMembers(workflowLabId: number, members: WorkflowLabMembership[]) {
        const payload = { id: workflowLabId, members };
        const response = await apiClient.post(`${svcPaths.workflow}/workflowLab/${workflowLabId}/members`, payload);
        return response.data;
    }

    async UserLabelsWithScope(WorkflowLabId: number | undefined, UserId: string, Keyphrase: string)
    {
        const response = await apiClient.get<LabelAssociate[]>(`${svcPaths.workflow}/label/wflablabelsuser`, {
            params:{
                workflowlabid: WorkflowLabId,
                userid: UserId,
                keyphrase: Keyphrase
            }
        })
        return response.data;
    }

    async BulkNewLabelsUser(newLabelsIds: number[], UserId: string, WorkflowLabId: number | undefined)
    {
        const response = await apiClient.put(`${svcPaths.workflow}/label/bulklabelsuser`, {
            labelIds: newLabelsIds,
            userid: UserId,
            workflowlabid: WorkflowLabId,
        })
        return response.data;
    }

    async uploadFile(workflowLabId: number, file: File){
        const formdata: FormData = new FormData();
        formdata.append('File', file);

        const response = await apiClient.post(`${svcPaths.workflow}/workflowLab/${workflowLabId}/updateworkflowlabfromexcel`, formdata);
        
        return response.data; 
    }

    async synchSlaveMaster(workflowLabId: number){

        const response = await apiClient.post(`${svcPaths.workflow}/workflowLab/${workflowLabId}/syncMaster`);
        
        return response.data; 
    }
    
    async validateUploadFile(workflowLabId: number, file: File){
        const formdata: FormData = new FormData();
        formdata.append('File', file);

        const response = await apiClient.post(`${svcPaths.workflow}/workflowLab/${workflowLabId}/validateUpdateWorkflowLabFromExcel`, formdata);
        
        return response.data; 
    }

    async export(workflowLabId: number, type: string, filename: string, onDownloadProgress?: (progress: number) => void) {
        const config: AxiosRequestConfig = {
            responseType: 'arraybuffer',
        }
        const response = await apiClient.get(`${svcPaths.workflow}/workflowLab/${workflowLabId}/export`, config);
        const url = window.URL.createObjectURL(new Blob([response.data], { type: type }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
    }

    async renameWorkflowLab(workflowLabId: number, name: string) {
        const payload = { name: name, Id: workflowLabId };
        const response = await apiClient.post(`${svcPaths.workflow}/workflowLab/${workflowLabId}/name`, payload);
        return response.data
    }
}

export const workflowLabApi = new WorkflowLabApiClass();