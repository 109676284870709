import { styled } from "@fluentui/react";
import { getStyles } from "./workflowLabManageUsersLabelView.styles";
import { IWorkflowLabManageUsersLabelViewPropsStyles, IWorkflowLabManageUsersLabelViewStyles, IWorkflowLabManageUsersLabelViewProps } from "./workflowLabManageUsersLabelView.types";
import { WorkflowLabManageUsers } from "./workflowLabManageUsersLabelView.base";

const WorkflowLabManageUsersLabelView = styled<IWorkflowLabManageUsersLabelViewProps, IWorkflowLabManageUsersLabelViewPropsStyles, IWorkflowLabManageUsersLabelViewStyles>(
    WorkflowLabManageUsers,
    getStyles,
    undefined,
    { scope: 'WorkflowLabManageUsers' }
);

export default WorkflowLabManageUsersLabelView;