import React from "react";
import { classNamesFunction, Icon, IconButton, Label, Stack, TooltipDelay, TooltipHost } from "@fluentui/react";
import { IIconTagPropsStyles, IIconTagStyles, IIconTagProps } from "./iconTag.types";

const getClassNames = classNamesFunction<IIconTagPropsStyles, IIconTagStyles>();

export const IconTagBase = (props: IIconTagProps) => {
  const classNames = getClassNames(props.styles, {
    theme: props.theme,
    className: props.className,
    noBackground: props.noBackground
  });

  const onDeleteClick = () => {
    if (props.onCancel)
      props.onCancel();
  }

  const renderTag = () => {
    return (
      <Stack
        horizontal verticalAlign='center' horizontalAlign='center'
        className={props.className ? `${classNames.root} ${props.className}` : classNames.root}
      >
        {!props.hiddenIcon && <Icon iconName={props.iconName || "Tag"} className={props.iconClassName} />}
        <Label
          className={props.labelClassName}
          styles={classNames.subComponentStyles.templateContainer}
          onClick={props.onClick}
        >
          {props.label}
        </Label>
        {props.cancel
          ?
          <IconButton
            style={{ color: 'white' }}
            iconProps={{ iconName: "Cancel" }}
            onClick={onDeleteClick}
          />
          : null
        }
      </Stack>
    );
  };

  return (
    <>
      {props.tooltip ? (
        <TooltipHost id={'iconTag'} content={props.label} delay={TooltipDelay.zero}
          style={{ display: props.tooltip ? "initial" : "none" }}>
          {renderTag()}
        </TooltipHost>
      ) : (<>{renderTag()}</>)}
    </>
  );
};
