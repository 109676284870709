import React from "react";
import { ImageName, ITeamsImageProps, ITeamsImagePropsStyles, ITeamsImageStyles } from "./teamsImage.types";
import EmptyFolderDrop from './images/empty_folder_drop.svg';
import EmptySearch from './images/empty_search.svg';
import WellDone from './images/well-done.svg';
import Oops1 from './images/oops1.svg';
import Oops2 from './images/oops2.svg';
import StillThere from './images/stillthere.svg';
import Error1 from './images/error1.svg';
import Error2 from './images/error2.svg';
import Error3 from './images/error3.svg';
import SearchNoResults from './images/search_noresults.svg';
import SurprisedWoman from './images/surprised_woman.svg';
import Forbidden from './images/forbidden.png';
import Sandglass from './images/sandglass.svg';
import EdiAppLogo from './images/ediAppLogo.svg';
import EdiAppLogoPNG from './images/ediAppLogoPNG.png';
import InProgress from './images/inProgress.svg';
import TeamsImage from './images/teams.svg';
import GlossaryCheckInfoIt from './images/glossary_check_excel_it.png';
import GlossaryCheckInfoEn from './images/glossary_check_excel_en.png';
import UploadUsersLabels from './images/upload_users_labels.png';
import { classNamesFunction } from "@fluentui/utilities";

const getClassNames = classNamesFunction<ITeamsImagePropsStyles, ITeamsImageStyles>();

export const TeamsImageBase = (props: ITeamsImageProps) => {
    const classNames = getClassNames(props.styles, {
        theme: props.theme,
        className: props.className,
        scale: props.scale ?? 1,
        fullContainer: props.fullContainer ?? false
    });

    let data;
    switch (props.imageName) {
        case ImageName.WellDone: data = WellDone; break;
        case ImageName.EmptyFolderDrop: data = EmptyFolderDrop; break;
        case ImageName.Oops1: data = Oops1; break;
        case ImageName.Oops2: data = Oops2; break;
        case ImageName.StillThere: data = StillThere; break;
        case ImageName.Error1: data = Error1; break;
        case ImageName.Error2: data = Error2; break;
        case ImageName.Error3: data = Error3; break;
        case ImageName.EmptySearch: data = EmptySearch; break;
        case ImageName.SearchNoResult: data = SearchNoResults; break;
        case ImageName.SurprisedWoman: data = SurprisedWoman; break;
        case ImageName.Forbidden: data = Forbidden; break;
        case ImageName.Sandglass: data = Sandglass; break;
        case ImageName.EdiAppLogo: data = EdiAppLogo; break;
        case ImageName.InProgress: data = InProgress; break;
        case ImageName.GlossaryCheckInfoIt: data = GlossaryCheckInfoIt; break;
        case ImageName.GlossaryCheckInfoEn: data = GlossaryCheckInfoEn; break;
        case ImageName.TeamsImage: data = TeamsImage; break;
        case ImageName.UploadUsersLabels: data = UploadUsersLabels; break;
        case ImageName.EdiAppLogoPNG: data = EdiAppLogoPNG; break;
        default: return <div>WRONG IMAGE</div>;
    }

    return (
        <div className={classNames.root} style={props.style}>
            <img alt={""} src={data} className={classNames.img} />
            {props.caption && <span className={classNames.caption}>{props.caption}</span>}
        </div>
    );
}