import { BasePeoplePicker, FontWeights, IBasePickerProps, IBasePickerStyles, IBreadcrumbStyleProps, IBreadcrumbStyles, IButtonProps, IButtonStyles, ICalloutContentStyleProps, ICalloutContentStyles, IComboBoxProps, IComboBoxStyles, ICommandBarStyleProps, ICommandBarStyles, IContextualMenuItemProps, IContextualMenuItemStyleProps, IContextualMenuItemStyles, IContextualMenuStyleProps, IContextualMenuStyles, IDetailsColumnProps, IDetailsColumnStyles, IDetailsHeaderProps, IDetailsHeaderStyles, IDetailsRowProps, IDetailsRowStyles, IDialogContentProps, IDialogContentStyles, IDialogFooterProps, IDialogFooterStyles, IDocumentCardProps, IDocumentCardStyles, IDropdownStyleProps, IDropdownStyles, IModalProps, IModalStyles, INavStyleProps, INavStyles, IPanelProps, IPanelStyles, IPeoplePickerItemSuggestionProps, IPeoplePickerItemSuggestionStyles, IPivotStyleProps, IPivotStyles, ISpinnerStyleProps, ISpinnerStyles, ISuggestionsItemStyleProps, ISuggestionsItemStyles, ISuggestionsStyleProps, ISuggestionsStyles, ITextFieldProps, ITextFieldStyles, ITooltipStyleProps, ITooltipStyles } from '@fluentui/react';
import { color } from '../../utilities/color';

const standardShadow = "0 2px 4px -0.75px rgba(0,0,0,.1)";

export const comboBoxStyles = (props: IComboBoxProps): IComboBoxStyles => {
  const { palette } = props.theme!; // eslint-disable-line @typescript-eslint/no-non-null-assertion

  return {
    container: {},
    label: {},
    labelDisabled: {},
    rootDisallowFreeForm: {},
    errorMessage: {},
    optionsContainerWrapper: {},
    screenReaderText: {},
    divider: {
      margin: "4px 0",
      height: "2px",
      backgroundColor: palette.neutralLight,
      border: "none"
    },
    root: {
      borderRadius: "3px",
      paddingLeft: "12px",
      borderWidth: "0 0 2px 0",
      borderStyle: "solid",
      borderColor: "transparent",
      backgroundColor: palette.neutralLight,
      color: palette.black,
      minWidth: "auto",
      paddingTop: "1px",
      selectors: {
        "::after": {
          display: "none"
        },
        "&.is-open": {
          borderWidth: "0 0 2px 0",
          borderColor: "transparent",
          borderBottomColor: palette.themePrimary,
          borderBottomRightRadius: "2px",
          borderBottomLeftRadius: "2px"
        },
        ".ms-Button--icon": {
          width: "34px",
          backgroundColor: "transparent",
          position: "absolute",
          right: "-1px",
          selectors: {
            ":hover": {
              color: "inherit",
              backgroundColor: "transparent"
            }
          }
        },
        ".ms-Button-icon": {
          color: palette.neutralPrimary,
          fontSize: "12px",
          fontWeight: "bold",
          position: "relative",
          top: "2px",
          selectors: {
            ".icons-default-fill": {
              fill: palette.neutralPrimary
            }
          }
        }
      }
    },
    rootError: {
      borderBottomRightRadius: "2px",
      borderBottomLeftRadius: "2px",
      borderWidth: "0 0 2px 0"
    },
    input: {
      backgroundColor: "transparent",
      height: "30px",
      lineHeight: "30px",
      color: palette.black,
      selectors: {
        "::placeholder": {
          color: palette.neutralDark
        },
        ":-ms-input-placeholder": {
          color: palette.neutralDark
        }
      }
    },
    inputDisabled: {
      color: palette.black
    },
    rootPressed: {
      borderWidth: "0 0 2px 0",
      borderColor: "transparent",
      borderBottomColor: palette.themePrimary,
      borderBottomRightRadius: "2px",
      borderBottomLeftRadius: "2px"
    },
    rootFocused: {
      borderWidth: "0 0 2px 0",
      borderColor: "transparent",
      borderBottomColor: palette.themePrimary,
      borderBottomRightRadius: "2px",
      borderBottomLeftRadius: "2px",
      selectors: {
        input: {
          selectors: {
            "::placeholder": {
              color: palette.neutralTertiary
            },
            ":-ms-input-placeholder": {
              color: palette.neutralTertiary
            }
          }
        }
      }
    },
    rootHovered: {
      borderColor: "transparent",
      selectors: {
        ".ms-Button": {
          backgroundColor: "transparent"
        },
        ".ms-ComboBox-Input": {
          color: "inherit"
        }
      }
    },
    rootDisabled: {
      opacity: 0.36,
      borderColor: "transparent",
      selectors: {
        input: {
          color: palette.neutralDark
        },
        ".ms-Button-icon": {
          color: palette.neutralDark
        }
      }
    },
    callout: {
      selectors: {
        ".ms-Callout-main": {
          paddingTop: "8px",
          paddingBottom: "8px"
        },
        ".is-checked": {
          backgroundColor: palette.neutralQuaternary,
          color: palette.black
        },
        ".ms-Fabric--isFocusVisible &": {
          selectors: {
            ".is-checked": {
              backgroundColor: "transparent",
              selectors: {
                ":after": {
                  borderColor: palette.white,
                  outline: `1px solid ${palette.black}`
                }
              }
            }
          }
        }
      }
    },
    header: {
      padding: "0 8px",
      color: palette.neutralDark
    },
    optionsContainer: {
      selectors: {
        ".ms-Button": {
          padding: "4px 16px",
          minHeight: "32px",
          height: "32px",
          whiteSpace: "noWrap",
          backgroundColor: "transparent",
          color: palette.neutralDark,
          selectors: {
            ":hover": {
              backgroundColor: palette.neutralLight,
              color: palette.black
            },
            ":focus": {
              backgroundColor: palette.neutralTertiaryAlt,
              color: palette.black
            },
            ":active": {
              backgroundColor: palette.neutralTertiaryAlt,
              color: palette.black
            },
            ":hover:active": {
              backgroundColor: `${palette.neutralTertiaryAlt} !important`
            }
          }
        },
        ".ms-ComboBox-optionText": {
          overflow: "visible"
        },
        ".ms-Checkbox-text": {
          color: "inherit"
        },
        ".ms-Checkbox:hover": {
          selectors: {
            ".ms-Checkbox-text": {
              color: "inherit",
              selectors: {
                ":hover": {
                  color: "inherit"
                }
              }
            }
          }
        },
        ".is-disabled": {
          color: palette.neutralTertiary
        }
      }
    }
  }
}

export const commandBarStyles = (props: ICommandBarStyleProps): ICommandBarStyles => {
  const { palette, semanticColors } = props.theme;

  return {
    root: {
      height: 40,
      backgroundColor: semanticColors.bodyBackground,
      borderTop: `1px solid ${palette.neutralTertiaryAlt}`,
      borderBottom: `1px solid ${palette.neutralTertiaryAlt}`,
      selectors: {
        ".icons-filled": {
          display: "none"
        },
        ".icons-unfilled": {
          display: "block"
        },
        svg: {
          height: 40,
          margin: "0 -9px",
          width: "32px"
        },
        "svg, .icons-default-fill": {
          fill: palette.neutralDark
        },
        ".ms-Button-menuIcon, .ms-Button-icon": {
          color: palette.neutralDark,
          fontSize: "14px",
          minWidth: "14px",
          lineHeight: "40px",
          height: "40px"
        },
        ".ms-Button-menuIcon": {
          fontSize: "12px",
          minWidth: "12px",
          lineHeight: "40px",
          height: "40px",
          marginLeft: "6px",
          marginTop: "1px",
          selectors: {
            svg: {
              width: "20px",
              height: "38px",
              margin: "0 -4px"
            }
          }
        },
        ".ms-Button--commandBar": {
          border: "1px solid transparent"
        },
        ".ms-CommandBarItem-link": {
          textDecoration: "none",
          border: "1px solid transparent",
          color: palette.neutralDark
        },
        "button, a": {
          marginRight: "16px",
          minWidth: "auto"
        },
        button: {
          height: "100%"
        },
        ".ms-OverflowSet-item:last-child button,  .ms-OverflowSet-item:last-child a": {
          marginRight: "0"
        },
        "a, a:link, a:visited, a:hover, button, button:active, button:hover": {
          backgroundColor: "transparent",
          color: palette.neutralDark
        },
        "button:hover": {
          color: palette.themePrimary,
          selectors: {
            "svg, .icons-default-fill": {
              fill: palette.themePrimary
            },
            ".ms-Button-menuIcon, .ms-Button-icon": {
              fontWeight: "bold",
              color: palette.themePrimary,
              backgroundColor: "transparent"
            },
            ".icons-filled": {
              display: "block"
            },
            ".icons-unfilled": {
              display: "none"
            },
            "[data-icon-name='StatusErrorFull']": {
              fontWeight: 400,
              color: palette.redDark
            }
          }
        },
        ".ms-Fabric--isFocusVisible &": {
          selectors: {
            ".ms-Button:focus": {
              selectors: {
                ":after": {
                  borderColor: palette.white,
                  outline: `1px solid ${palette.black}`
                }
              }
            }
          }
        },
        ".is-expanded": {
          color: palette.themePrimary,
          selectors: {
            "svg, .icons-default-fill": {
              fill: palette.themePrimary
            },
            ".ms-Button-menuIcon, .ms-Button-icon": {
              fontWeight: "bold",
              color: palette.themePrimary,
              backgroundColor: "transparent"
            },
            ".icons-filled": {
              display: "block"
            },
            ".icons-unfilled": {
              display: "none"
            }
          }
        },
        "a:hover": {
          color: palette.themePrimary,
          selectors: {
            "svg, .icons-default-fill": {
              fill: palette.themePrimary
            },
            ".ms-Button-menuIcon, .ms-Button-icon": {
              fontWeight: "bold",
              color: palette.themePrimary,
              backgroundColor: "transparent"
            },
            ".icons-filled": {
              display: "block"
            },
            ".icons-unfilled": {
              display: "none"
            }
          }
        },
        ".ms-CommandBar-overflowButton": {
          minWidth: "auto",
          selectors: {
            ".ms-Button-menuIcon": {
              margin: 0,
              fontSize: "16px"
            }
          }
        },
        "[data-icon-name='StatusErrorFull']": {
          fontWeight: 400,
          color: palette.redDark
        }
      }
    },
    secondarySet: {
      selectors: {
        ".ms-Button-icon": {
          width: "auto"
        }
      }
    }
  }
}

export const commandBarButtonStyles = (props: IContextualMenuItemProps): IContextualMenuItemStyles => {
  const { palette } = props.theme!; // eslint-disable-line @typescript-eslint/no-non-null-assertion

  return {
    root: {
      outline: "transparent",
      position: "relative",
      selectors: {
        "::-moz-focus-inner": {
          border: "0"
        },
        ".ms-Fabric--isFocusVisible &:focus:after": {
          content: "\"\"",
          position: "absolute",
          left: 3,
          top: 3,
          bottom: 3,
          right: 3,
          border: `1px solid ${palette.white}`,
          outline: `1px solid ${palette.neutralSecondary}`,
          zIndex: 1,
          selectors: {}
        }
      }
    }
  }
}


export const compoundButtonStyles = (props: IButtonProps): IButtonStyles => {
  const { palette } = props.theme!; // eslint-disable-line @typescript-eslint/no-non-null-assertion

  return {
    root: {
      borderRadius: "2px",
      boxShadow: standardShadow,
      backgroundColor: palette.white,
      borderColor: palette.neutralTertiaryAlt,
      borderWidth: "1px",
      color: palette.black,
      minHeight: "auto",
      padding: "12px",
      selectors: {
        ".ms-Button-icon": {
          color: "inherit"
        },
        ".icons-default-fill": {
          fill: "inherit"
        },
        svg: {
          width: "32px",
          height: "32px",
          padding: "6px",
          display: "block",
          boxSizing: "content-box",
          borderRadius: "3px",
          fill: palette.white,
          background: palette.themePrimary
        },
        ".icons-unfilled": {
          display: "none"
        },
        ".icons-filled": {
          display: "block"
        },
        ".ms-Fabric--isFocusVisible &": {
          selectors: {
            ":focus::after": {
              outline: "none",
              borderRadius: "2px",
              borderColor: palette.white,
              borderWidth: "1px",
              borderStyle: "solid",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0
            },
            ":focus": {
              borderColor: palette.black
            }
          }
        },
        "&.ms-Button--compoundPrimary": {
          backgroundColor: palette.themePrimary,
          borderColor: palette.themePrimary,
          boxShadow: standardShadow,
          color: palette.white,
          selectors: {
            svg: {
              fill: palette.themePrimary,
              background: palette.white
            }
          }
        },
        ".ms-Button-description": {
          color: "inherit !important"
        }
      }
    },
    rootHovered: {
      selectors: {
        "&.ms-Button--compound:hover": {
          backgroundColor: palette.neutralQuaternary,
          borderColor: palette.neutralTertiary,
          color: palette.black,
          selectors: {
            svg: {
              fill: palette.white,
              background: palette.themePrimary
            }
          }
        },
        "&.ms-Button--compoundPrimary:hover": {
          backgroundColor: palette.themeDark,
          borderColor: palette.themeDark,
          color: palette.white,
          selectors: {
            svg: {
              fill: palette.themePrimary
            }
          }
        }
      }
    },
    rootPressed: {
      boxShadow: "none",
      selectors: {
        "&.ms-Button--compound:active": {
          backgroundColor: palette.neutralTertiaryAlt,
          borderColor: palette.neutralTertiary,
          color: palette.black,
          selectors: {
            svg: {
              fill: palette.white,
              background: palette.themePrimary
            }
          }
        },
        "&.ms-Button--compoundPrimary:active": {
          backgroundColor: palette.themeDark,
          borderColor: palette.themeDark,
          color: palette.white,
          selectors: {
            svg: {
              fill: palette.themePrimary
            }
          }
        }
      }
    },
    rootDisabled: {
      backgroundColor: palette.neutralQuaternary,
      color: palette.neutralTertiary,
      borderColor: "transparent",
      boxShadow: "none",
      selectors: {
        svg: {
          fill: palette.neutralQuaternary,
          background: palette.neutralTertiary
        },
        "&.ms-Button--compoundPrimary.is-disabled": {
          backgroundColor: palette.neutralQuaternary,
          borderColor: palette.neutralQuaternary,
          color: palette.neutralTertiary,
          boxShadow: "none",
          selectors: {
            svg: {
              fill: palette.neutralQuaternary,
              background: palette.neutralTertiary
            }
          }
        }
      }
    },
    rootChecked: {
      backgroundColor: palette.neutralTertiaryAlt,
      borderColor: palette.neutralTertiary,
      color: palette.black,
      selectors: {
        "&.ms-Button--compoundPrimary.is-checked": {
          backgroundColor: palette.themeDarker,
          borderColor: palette.themeDarker,
          color: palette.white
        }
      }
    },
    rootCheckedHovered: {
      selectors: {
        "&.ms-Button--compoundPrimary.is-checked:hover": {
          backgroundColor: palette.themeDark,
          borderColor: palette.themeDark,
          color: palette.white
        }
      }
    },
    rootCheckedDisabled: {
      backgroundColor: palette.neutralQuaternary,
      color: palette.neutralTertiary,
      borderColor: "transparent",
      boxShadow: "none",
      selectors: {
        "&.ms-Button--compoundPrimary.is-checked.is-disabled": {
          backgroundColor: palette.neutralQuaternary,
          borderColor: palette.neutralQuaternary,
          color: palette.neutralTertiary,
          boxShadow: "none"
        }
      }
    },
    flexContainer: {
      alignItems: "center"
    },
    textContainer: {
      flexShrink: "1"
    },
    icon: {
      width: "auto",
      height: "auto",
      marginRight: "12px"
    },
    description: {
      color: "inherit",
      fontSize: "14px",
      opacity: 0.84
    }
  }
}

export const defaultButtonStyles = (props: IButtonProps): IButtonStyles => {
  const { palette } = props.theme!; // eslint-disable-line @typescript-eslint/no-non-null-assertion

  return {
    root: {
      borderRadius: "2px",
      backgroundColor: palette.white,
      borderColor: palette.neutralTertiaryAlt,
      color: palette.black,
      boxShadow: standardShadow,
      cursor: "pointer",
      minWidth: "96px",
      padding: "0 16px",
      selectors: {
        ".icons-default-fill": {
          fill: palette.black
        },
        ".icons-filled": {
          display: "none"
        }
      }
    },
    rootHovered: {
      backgroundColor: palette.neutralQuaternary,
      borderColor: palette.neutralTertiary,
      color: palette.black,
      selectors: {
        "svg, .icons-default-fill": {
          fill: palette.black
        },
        ".ms-Fabric--isFocusVisible &": {
          selectors: {
            ":focus::after": {
              outline: "none",
              borderRadius: "2px",
              borderColor: palette.white,
              borderWidth: "1px",
              borderStyle: "solid",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0
            },
            ":focus": {
              borderColor: palette.black
            }
          }
        }
      }
    },
    rootPressed: {
      backgroundColor: "#e1dfdd",
      borderColor: palette.neutralTertiary,
      boxShadow: "none",
      color: palette.black
    },
    rootChecked: {
      backgroundColor: "#e1dfdd",
      borderColor: palette.neutralTertiary,
      color: palette.black,
      selectors: {
        "svg, .icons-default-fill": {
          fill: palette.black
        },
        ".ms-Fabric--isFocusVisible &": {
          selectors: {
            ":focus::after": {
              outline: "none",
              borderRadius: "2px",
              borderColor: palette.white,
              borderWidth: "1px",
              borderStyle: "solid",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0
            },
            ":focus": {
              borderColor: palette.black
            }
          }
        }
      }
    },
    rootCheckedHovered: {
      selectors: {
        "svg, .icons-default-fill": {
          fill: palette.black
        },
        ".ms-Fabric--isFocusVisible &": {
          selectors: {
            ":focus::after": {
              outline: "none",
              borderRadius: "2px",
              borderColor: palette.white,
              borderWidth: "1px",
              borderStyle: "solid",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0
            },
            ":focus": {
              borderColor: palette.black
            }
          }
        }
      }
    },
    rootDisabled: {
      backgroundColor: palette.neutralQuaternary,
      color: palette.neutralTertiary,
      borderColor: "transparent",
      boxShadow: "none",
      selectors: {
        "svg, .icons-default-fill": {
          fill: palette.neutralTertiary
        },
        ".ms-Fabric--isFocusVisible &": {
          selectors: {
            ":focus::after": {
              outline: "none",
              borderRadius: "2px",
              borderColor: palette.white,
              borderWidth: "1px",
              borderStyle: "solid",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0
            },
            ":focus": {
              borderColor: palette.black
            }
          }
        }
      }
    },
    rootFocused: {
      boxShadow: standardShadow,
      selectors: {
        "svg, .icons-default-fill": {
          fill: palette.black
        },
        ".ms-Fabric--isFocusVisible &": {
          selectors: {
            ":focus::after": {
              outline: "none",
              borderRadius: "2px",
              borderColor: palette.white,
              borderWidth: "1px",
              borderStyle: "solid",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0
            },
            ":focus": {
              borderColor: palette.black
            }
          }
        }
      }
    },
    icon: {
      height: "32px",
      selectors: {
        svg: {
          height: "32px",
          width: "32px",
          marginRight: "-9px",
          marginLeft: "-9px"
        }
      }
    },
    menuIcon: {
      selectors: {
        svg: {
          height: "28px",
          width: "28px",
          marginRight: "-8px",
          marginLeft: "-8px",
          marginTop: "-9px",
          marginBottom: "-9px"
        }
      }
    },
    splitButtonMenuButton: {
      borderTopRightRadius: "2px",
      borderBottomRightRadius: "2px",
      borderWidth: "1px",
      borderStyle: "solid",
      background: palette.white,
      borderLeft: 0,
      color: palette.black,
      marginLeft: 0,
      selectors: {
        ".icons-filled": {
          display: "none"
        },
        ":hover": {
          backgroundColor: "rgba(37, 36, 35, 0.05)",
          color: palette.black
        },
        ":active": {
          backgroundColor: "#e1dfdd",
          color: palette.black
        },
        ".ms-Button-icon": {
          color: "inherit",
          fontWeight: 700
        }
      }
    },
    splitButtonContainer: {
      selectors: {
        ".ms-Button": {
          borderColor: "#e1dfdd",
          backgroundClip: "padding-box"
        },
        ".ms-Button--default": {
          borderRight: 0,
          borderTopRightRadius: "0px",
          borderBottomRightRadius: "0px"
        },
        ".ms-Button--default + .ms-Button": {
          boxShadow: standardShadow
        },
        ".ms-Button--primary": {
          borderRight: 0,
          borderTopRightRadius: "0px",
          borderBottomRightRadius: "0px",
          borderColor: "transparent",
          background: palette.themePrimary,
          color: palette.white,
          boxShadow: standardShadow,
          selectors: {
            ":hover": {
              borderColor: "transparent",
              background: palette.themeDark,
              color: palette.white
            },
            ":active": {
              borderColor: "transparent",
              background: palette.themeDarker,
              color: palette.white
            },
            "svg, .icons-default-fill": {
              fill: palette.white
            }
          }
        },
        ".ms-Button--primary + .ms-Button": {
          borderColor: "transparent",
          background: palette.themePrimary,
          color: palette.white,
          boxShadow: "0 2px 4px -0.75px rgba(0,0,0,.25)",
          selectors: {
            ":hover": {
              borderColor: "transparent",
              background: palette.themeDark,
              color: palette.white
            },
            ":active": {
              borderColor: "transparent",
              background: palette.themeDarker,
              color: palette.white
            },
            "svg, .icons-default-fill": {
              fill: palette.white
            }
          }
        },
        ".ms-Button--primary ~ span[class*='css-']": {
          top: 0,
          bottom: 0,
          backgroundColor: "#e1dfdd"
        },
        ".ms-Button--primary + .ms-Button[aria-expanded='true']": {
          background: palette.themeDarker,
          color: palette.white
        },
        ":hover": {
          selectors: {
            ".ms-Button": {
              borderColor: palette.neutralTertiary
            },
            ".ms-Button--primary": {
              borderColor: "transparent"
            },
            ".ms-Button--primary + .ms-Button": {
              borderColor: "transparent"
            }
          }
        },
        ":active": {
          selectors: {
            ".ms-Button": {
              borderColor: palette.neutralTertiary,
              boxShadow: "none"
            },
            ".ms-Button--primary": {
              borderColor: "transparent"
            },
            ".ms-Button--primary + .ms-Button": {
              borderColor: "transparent"
            }
          }
        }
      }
    },
    splitButtonContainerFocused: {
      selectors: {
        ".ms-Fabric--isFocusVisible &": {
          selectors: {
            ":focus::after": {
              outline: "none",
              borderRadius: "3px",
              borderColor: palette.black,
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              boxShadow: "#fff 0 0 0 1px inset"
            },
            ":focus": {
              selectors: {
                ".ms-Button": {
                  backgroundColor: "#e1dfdd",
                  color: palette.black
                },
                ".ms-Button ~ span[class*='css-']": {
                  backgroundColor: palette.white
                },
                ".ms-Button--primary": {
                  backgroundColor: palette.themeDark,
                  color: palette.white
                },
                ".ms-Button--primary + .ms-Button": {
                  backgroundColor: palette.themeDark,
                  color: palette.white
                }
              }
            }
          }
        }
      }
    },
    splitButtonMenuButtonExpanded: {
      background: "#e1dfdd",
      color: palette.black,
      borderColor: palette.neutralTertiary
    },
    splitButtonDivider: {
      top: 1,
      bottom: 1,
      width: 1,
      backgroundColor: "#e1dfdd"
    },
    description: {
      color: "inherit"
    }
  }
}

export const dialogContentStyles = (props: IDialogContentProps): IDialogContentStyles => {
  const { palette } = props.theme!; // eslint-disable-line @typescript-eslint/no-non-null-assertion

  return {
    title: {
      fontSize: "18px",
      fontWeight: 700,
      padding: "27px 54px 8px 32px",
      lineHeight: "24px",
      color: palette.black
    },
    topButton: {
      padding: "23px 22px 4px 0",
      fontSize: "14px",
      selectors: {
        ".ms-Dialog-button": {
          color: palette.neutralDark
        },
        ".ms-Dialog-button:hover": {
          color: palette.themePrimary,
          backgroundColor: "transparent",
          selectors: {
            i: {
              fontWeight: 700
            }
          }
        },
        ".ms-Dialog-button:active": {
          color: palette.themePrimary
        }
      }
    },
    inner: {
      padding: "1px 32px 32px 32px",
      color: palette.neutralDark,
      selectors: {
        ".ms-Label": {
          color: palette.neutralDark
        },
        ".ms-Button--primary": {
          color: palette.white,
          backgroundColor: palette.themePrimary
        },
        ".ms-Button--primary:hover": {
          color: palette.white,
          backgroundColor: palette.themeDark
        },
        ".ms-Button--primary:active": {
          color: palette.white,
          backgroundColor: palette.themeDarker
        },
        ".ms-Button--primary:focus": {
          color: palette.white,
          backgroundColor: palette.themeDarker
        }
      }
    },
    innerContent: {
      paddingTop: "4px",
      marginBottom: "20px",
      selectors: {
        ":last-child": {
          marginBottom: 0
        }
      }
    },
    subText: {
      color: palette.neutralDark,
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "20px",
      paddingTop: 0
    },
    content: {},
    header: {},
    button: {},
  }
}

export const dialogFooterStyles = (props: IDialogFooterProps): IDialogFooterStyles => {
  return {
    actions: {
      margin: "16px 0 0"
    },
    actionsRight: {},
    action: {}
  }
}

export const documentCardStyles = (props: IDocumentCardProps): IDocumentCardStyles => {
  const { palette } = props.theme!; // eslint-disable-line @typescript-eslint/no-non-null-assertion

  return {
    root: {
      borderRadius: "3px",
      backgroundColor: palette.neutralLighterAlt,
      borderColor: palette.neutralQuaternary,
      borderStyle: "solid",
      borderWidth: "1px",
      boxShadow: standardShadow,
      selectors: {
        ":hover": {
          backgroundColor: palette.neutralQuaternary
        },
        ":hover::after": {
          display: "none"
        },
        ":focus": {
          borderColor: palette.black
        },
        ".ms-DocumentCardTitle": {
          color: palette.black,
          fontSize: "14px",
          height: "20px",
          lineHeight: "20px"
        }
      }
    }
  }
}

export const modalStyles = (props: IModalProps): IModalStyles => {
  const { palette } = props.theme!; // eslint-disable-line @typescript-eslint/no-non-null-assertion

  return {
    root: {
      backgroundColor: "none",
      selectors: {
        ".ms-Overlay": {
          backgroundColor: color.hexToRgba(palette.black, "0.75")
        }
      }
    },
    main: {
      backgroundColor: palette.white,
      borderRadius: "3px",
      color: palette.black,
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: "#e8e7e6",
      boxShadow: "0 16px 18px -4px rgba(0,0,0,.1)",
      minWidth: "400px !important",
      minHeight: "116px !important"
    },
    scrollableContent: {},
    layer: {},
    keyboardMoveIconContainer: {},
    keyboardMoveIcon: {},
  }
}

export const panelStyles = (props: IPanelProps): IPanelStyles => {
  const { palette } = props.theme!; // eslint-disable-line @typescript-eslint/no-non-null-assertion

  return {
    main: {
      selectors: {
        "&.ms-Panel-main": {
          borderWidth: 0,
          borderStyle: "solid",
          borderColor: palette.black
        },
        ".ms-Checkbox": {
          paddingTop: 12,
          paddingBottom: 0,
          selectors: {
            ".ms-Persona": {
              paddingLeft: 4,
              paddingRight: 4,
              paddingTop: 0,
              paddingBottom: 0
            }
          }
        },
        ".ms-ProgressIndicator-itemName": {
          color: palette.black
        },
        ".ms-ProgressIndicator-itemDescription > span": {
          color: palette.black
        },
        ".ms-Link": {
          color: palette.themePrimary,
          selectors: {
            ":hover": {
              color: palette.themePrimary
            }
          }
        },
        ".ms-Checkbox-label img": {
          display: "block"
        },
        ".ms-TilesList-grid .ms-ProgressIndicator-itemDescription [class*='description']": {
          color: palette.neutralPrimary
        },
        ".ms-TilesList-grid [class*='error'] .ms-ProgressIndicator-itemDescription [class*='description']": {
          color: palette.redDark
        },
        ".ms-BasePicker-input": {
          backgroundColor: palette.white
        },
        "div .ms-BasePicker-text": {
          border: `1px solid ${palette.black}`
        }
      }
    },
    overlay: {
      backgroundColor: color.hexToRgba(palette.black, "0.75")
    },
    closeButton: {
      color: palette.black,
      selectors: {
        "&:hover": {
          color: palette.themePrimary,
          backgroundColor: "transparent",
          selectors: {
            i: {
              fontWeight: 700
            }
          }
        },
        "&:active": {
          color: palette.themePrimary,
          backgroundColor: "transparent",
          selectors: {
            i: {
              fontWeight: 700
            }
          }
        }
      }
    },
    commands: {
      paddingTop: "10px",
      paddingBottom: "20px"
    },
    content: {
      color: palette.black,
      selectors: {
        ".ms-Button--icon": {
          selectors: {
            i: {
              color: palette.black
            },
            ":hover": {
              backgroundColor: "transparent",
              selectors: {
                i: {
                  fontWeight: "bold",
                  color: palette.themePrimary
                }
              }
            },
            ":active": {
              backgroundColor: "transparent"
            }
          }
        }
      }
    },
    headerText: {
      color: palette.black
    },
    root: {},
    hiddenPanel: {},
    contentInner: {},
    scrollableContent: {},
    navigation: {},
    header: {},
    footer: {},
    footerInner: {},
    subComponentStyles: {
      closeButton: {}
    }
  }
}

export const primaryButtonStyles = (props: IButtonProps): IButtonStyles => {
  const { palette } = props.theme!; // eslint-disable-line @typescript-eslint/no-non-null-assertion

  return {
    root: {
      backgroundColor: palette.themePrimary,
      color: palette.white,
      boxShadow: "0 2px 4px -0.75px rgba(0,0,0,.25)",
      borderColor: palette.themePrimary,
      selectors: {
        "svg, .icons-default-fill": {
          fill: palette.white
        }
      }
    },
    rootHovered: {
      backgroundColor: palette.themeDark,
      borderColor: palette.themeDark,
      color: palette.white,
      selectors: {
        "svg, .icons-default-fill": {
          fill: palette.white
        }
      }
    },
    rootPressed: {
      backgroundColor: palette.themeDarker,
      borderColor: palette.themeDarker,
      color: palette.white,
      selectors: {
        "svg, .icons-default-fill": {
          fill: palette.white
        }
      }
    },
    rootChecked: {
      backgroundColor: palette.themeDarker,
      borderColor: palette.themeDarker,
      color: palette.white,
      selectors: {
        "svg, .icons-default-fill": {
          fill: palette.white
        }
      }
    },
    rootCheckedHovered: {
      backgroundColor: palette.themeDark,
      borderColor: palette.themeDark,
      color: palette.white,
      selectors: {
        "svg, .icons-default-fill": {
          fill: palette.white
        }
      }
    },
    rootDisabled: {
      backgroundColor: palette.neutralQuaternary,
      borderColor: palette.neutralQuaternary,
      color: palette.neutralTertiary,
      selectors: {
        "svg, .icons-default-fill": {
          fill: palette.neutralTertiary
        }
      }
    },
    rootFocused: {
      backgroundColor: palette.themePrimary,
      borderColor: palette.themePrimary,
      borderRadius: "2px",
      color: palette.white,
      selectors: {
        "svg, .icons-default-fill": {
          fill: palette.white
        }
      }
    }
  }
}






export const tooltipStyles = (props: ITooltipStyleProps) : ITooltipStyles =>{
  const { palette } = props.theme!; // eslint-disable-line @typescript-eslint/no-non-null-assertion
  return{
    root:{},
    content:{},
    subText: {
      color: palette.themePrimary
    }
  }
}

export const dropdownStyles = (props: IDropdownStyleProps): IDropdownStyles => {
  const { palette } = props.theme!; // eslint-disable-line @typescript-eslint/no-non-null-assertion

  return {
    root: {},
    label: {},
    dropdown: {
      selectors: {
        ':focus': {
          '::after': {
            border: 'none',
            borderBottom: '2px solid ' + palette.themePrimary
          }
        }
      }
    },
    title: {
      border: 'none',
      backgroundColor: palette.neutralLight //'rgb(240,240,240)'
    },
    caretDownWrapper: {},
    caretDown: {},
    errorMessage: {},
    dropdownItemsWrapper: {},
    dropdownItems: {},
    dropdownItem: {},
    dropdownItemSelected: {},
    dropdownItemDisabled: {},
    dropdownItemSelectedAndDisabled: {},
    dropdownItemHidden: {},
    dropdownOptionText: {},
    dropdownDivider: {},
    dropdownItemHeader: {},
    panel: {},
    callout: {},
    subComponentStyles: {
      label: {},
      multiSelectItem: {},
      panel: {}
    }
  }
}

export const spinnerStyles = (props: ISpinnerStyleProps): ISpinnerStyles => {
  return {
    circle: {
      // borderColor: props.theme.palette.themePrimary, To Check
    },
  };
};

export const suggestionsPeoplePickerStyles = (props: ISuggestionsStyleProps): ISuggestionsStyles => {
  return {
    suggestionsContainer: {
      maxHeight: "40vh",
    },
    forceResolveButton: {},
    noSuggestions: {},
    root: {},
    title: {},
    searchForMoreButton: {},
    subComponentStyles: {
      spinner: {},
    },
    suggestionsAvailable: {},
  };
};

export const suggestionsItemStyles = (props: ISuggestionsItemStyleProps): ISuggestionsItemStyles => {
  const { palette } = props.theme!; // eslint-disable-line @typescript-eslint/no-non-null-assertion
  return {
    root: {},
    itemButton: {
      selectors: {
        ":hover": {
          background: palette.themeLight,
        },
      },
    },
    closeButton: {},
  };
};

export const navStyles = (props: INavStyleProps): INavStyles => {
  const { isExpanded } = props;
  const { semanticColors, palette, spacing, fonts } = props.theme;

  return {
    root: {
      padding: "5px 0",
      background: semanticColors.menuItemBackgroundPressed,
      fontSize: spacing.s1,
      ".ms-Nav-link": {
        height: "28px",
      },
      height: "100%",
      selectors: {
        ".is-selected button": {
          //backgroundColor: palette.neutralLighterAlt
          backgroundColor: palette.white,
          ":hover": {
            backgroundColor: palette.white,
          },
        },
      },
    },
    linkText: {},
    link: {
      padding: "0 10px",
      selectors: {
        ".ms-Nav-compositeLink:hover &": {
          backgroundColor: palette.neutralLighterAlt,
          color: palette.black,
        },
        ":active": {
          ".ms-Button-flexContainer": {
            position: "unset",
          },
        },
      },
    },
    compositeLink: {},
    chevronButton: {
      fontSize: fonts.medium.fontSize,
      color: semanticColors.infoIcon,
      paddingTop: 0,
      paddingBottom: 0,
      margin: 0,
      selectors: {
        ":hover": {
          backgroundColor: palette.neutralLighterAlt,
          color: palette.black,
        },
      },
    },
    chevronIcon: [
      {
        fontSize: fonts.xSmall.fontSize,
        transform: "rotate(-90deg)",
      },
      isExpanded && {
        transform: "rotate(0deg)",
        top: "5px",
      },
    ],
    navItems: {},
    navItem: {},
    group: {},
    groupContent: {
      marginBottom: "0px",
    },
  };
};

export const pivotStyles = (props: IPivotStyleProps): IPivotStyles => {
  return {
    root: {},
    link: {
      paddingLeft: 0,
      paddingRight: 0,
      marginLeft: 8,
      marginRight: 8,

      "&:hover": {
        backgroundColor: "inherit",
        color: props.theme?.palette.black,
      },
      "&:active": {
        backgroundColor: "inherit",
      },

      "&::before": {
        transition: "none",
        height: 3,
      },

      "&:not(.is-selected):hover::before": {
        backgroundColor: "#bdbdbd",
        right: 0,
        left: 0,
      },
    },
    linkIsSelected: {
      cursor: "default",
      color: props.theme.palette.black,

      "&:hover": {
        cursor: "default",
      },

      "&::before": {
        right: 0,
        left: 0,
      },
    },
    linkContent: {},
    text: {
      fontSize: 14,
    },
    count: {},
    icon: {},
    linkInMenu: {},
    overflowMenuButton: {},
  };
};

export const contextualMenuStyles = (props: IContextualMenuStyleProps): IContextualMenuStyles => {
  return {
    root: {
      marginTop: 1,
    },
    container: {},
    header: {},
    list: {
      padding: "8px 0",
    },
    title: {},
    subComponentStyles: {
      callout: (props: ICalloutContentStyleProps): ICalloutContentStyles => {
        return {
          root: {},
          container: {},
          beak: {},
          beakCurtain: {},
          calloutMain: {
            boxShadow: "0 0.2rem 1.6rem 0 rgb(37 36 35 / 30%)",
          },
        };
      },
      menuItem: (
        props: IContextualMenuItemStyleProps
      ): IContextualMenuItemStyles => {
        return {
          root: {
            paddingLeft: 8,
            paddingRight: 32,
            color: "#424242",
          },
          icon: {
            color: '#424242'
          }
        }
      }
    }
  }
}

export const breadcrumbStyles = (props: IBreadcrumbStyleProps): IBreadcrumbStyles => {
  const { palette, semanticColors } = props.theme;

  return {
    root: {
      margin: "8px 0px 0px",
      paddingLeft: "24px",
      paddingRight: "24px",
      lineHeight: "20px"
    },
    list: {},
    listItem: {
    },
    chevron: {},
    overflow: {},
    overflowButton: {},
    itemLink: {
      fontSize: "unset",
      color: semanticColors.buttonText,
      lineHeight: "40px",
      height: "40px",
      fontWeight: "400",
      selectors: {
        ':hover': {
          color: palette.themePrimary,
          textDecoration: "none",
          cursor: "pointer",
          background: "none"
        },
        ':last-child': {
          fontWeight: "700 !important",
          color: semanticColors.buttonText
        },
        ':active': {
          color: palette.themePrimary + " !important",
          textDecoration: "none",
          cursor: "pointer",
          background: "none !important"
        },
      }
    },
    item: {
      fontSize: "unset",
      color: semanticColors.buttonText,
      lineHeight: "40px",
      height: "40px",
      fontWeight: "400",
      selectors: {
        ':last-child': {
          fontWeight: "700 !important",
          color: semanticColors.buttonText
        },
      }
    },
  }
}

export const detailsRowStyles = (props: IDetailsRowProps): IDetailsRowStyles => {
  const { fonts, semanticColors, palette } = props.theme!; // eslint-disable-line @typescript-eslint/no-non-null-assertion

  return {
    root: {
      backgroundColor: semanticColors.bodyBackground,
      selectors: {
        ':hover': {
          backgroundColor: semanticColors.listItemBackgroundHovered
        },
        '&.is-selected': {
          backgroundColor: semanticColors.listItemBackgroundChecked,
          borderBottom: `1px solid ${palette.neutralLighter}`,
        },
        '&.is-selected:not(:hover)': {
          backgroundColor: palette.white,
        }
      }
    },
    cell: {
      fontSize: fonts.medium.fontSize,
      color: palette.black
    },
    cellAnimation: {},
    cellUnpadded: {},
    cellPadded: {},
    checkCell: {},
    isRowHeader: {
      color: palette.black,
      fontWeight: fonts.medium.fontWeight
    },
    isMultiline: {},
    fields: {},
    cellMeasurer: {},
    checkCover: {},
    check: {}
  }
}

export const detailsColumnStyles = (props: IDetailsColumnProps): IDetailsColumnStyles => {
  const { palette } = props.theme!; // eslint-disable-line @typescript-eslint/no-non-null-assertion

  return {
    root: {
      height: '32px'
    },
    cellName: {
      fontSize: '12px',
      fontWeight: '400'
    },
    cellTitle: {
      color: palette.black
    },
    sortIcon: {
      color: palette.black
    },
    gripperBarVerticalStyle: {},
    cellTooltip: {},
    iconClassName: {},
    nearIcon: {},
    accessibleLabel: {},
    filterChevron: {},
    borderAfterDropping: {},
    noBorderAfterDropping: {},
    borderWhileDragging: {},
    noBorderWhileDragging: {}
  }
}

export const detailsHeaderStyles = (props: IDetailsHeaderProps): IDetailsHeaderStyles => {
  const { palette } = props.theme!; // eslint-disable-line @typescript-eslint/no-non-null-assertion

  return {
    root: {
      height: '32px',
      lineHeight: '32px',
      borderBottomColor: palette.neutralQuaternary
    },
    cellIsCheck: {
      height: '32px'
    },
    check: {},
    cellWrapperPadded: {},
    cellIsActionable: {},
    cellIsEmpty: {},
    cellSizer: {},
    cellSizerStart: {},
    cellSizerEnd: {},
    cellIsResizing: {},
    cellIsGroupExpander: {},
    collapseButton: {},
    checkTooltip: {},
    sizingOverlay: {},
    dropHintCircleStyle: {},
    dropHintCaretStyle: {},
    dropHintLineStyle: {},
    dropHintStyle: {},
    accessibleLabel: {},
  }
}

export const textFieldStyles = (props: ITextFieldProps): ITextFieldStyles => {
  const { palette } = props.theme!; // eslint-disable-line @typescript-eslint/no-non-null-assertion

  return {
    root: {
      marginBottom: 1
    },
    fieldGroup: {
      border: 'none',
      color: palette.black,
      backgroundColor: palette.neutralLighter,
      '::after': {
        borderTop: 'none',
        borderLeft: 'none',
        borderRight: 'none',
        borderRadius: 0,
        width: '100%',
        inset: '0px'
      }
    },
    prefix: {},
    suffix: {},
    field: {
      '::placeholder': {
        color: palette.black
      },
      color: palette.black
    },
    icon: {},
    description: {},
    wrapper: {},
    errorMessage: {},
    revealButton: {},
    revealSpan: {},
    revealIcon: {},
    subComponentStyles: {
      label: {
        root: {
          fontWeight: FontWeights.regular,
          color: palette.black
        }
      }
    }
  }
}

export const peoplePickerStyles = (props: IBasePickerProps<BasePeoplePicker>): IBasePickerStyles => {
  const { palette } = props.theme!; // eslint-disable-line @typescript-eslint/no-non-null-assertion

  return {
    root: {
      width: "100%",
      marginRight: "20px",
    },
    text: {
      borderLeft: "none",
      borderRight: "none",
      borderTop: "none",
      borderRadius: 0,
      selectors: {
        "::after": {
          borderLeft: "none",
          borderRight: "none",
          borderTop: "none",
          borderRadius: 0,
        },
      },
    },
    input: {
      color: palette.black,
      backgroundColor: palette.neutralLighter
    },
    itemsWrapper: {
      ".ms-PickerPersona-container": {
        //border: "1px solid" + palette.neutralSecondary,
        //background: "transparent",
        // marginBottom: "5px",
        // ".ms-TooltipHost": {
        //   color: palette.themePrimary,
        // },
      },
    },
    screenReaderText: {}
  };
};

export const peoplePickerItemSuggestionStyles = (props: IPeoplePickerItemSuggestionProps): IPeoplePickerItemSuggestionStyles => {
  return {
    root: {
      ":hover": {
        backgroundColor: '#f3f2f1'
      }
    },
    personaWrapper: {},
    subComponentStyles: {
      persona: {}
    }
  }
}