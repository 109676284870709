import { IStyleFunction } from "@fluentui/react"
import { IEditCategoryModalPropsStyles, IEditCategoryModalStyles} from "./editCategoryModal.types"

export const EditCategoryModalGlobalClassNames = {
    root: 'ewe-workflowlab-edit-category',
    footerButtons: 'ewe-workflowlab-edit-category-footerButtons',
}

export const getStyles: IStyleFunction<IEditCategoryModalPropsStyles, IEditCategoryModalStyles> = (props: IEditCategoryModalPropsStyles): IEditCategoryModalStyles => {
    return {
        root: [
            EditCategoryModalGlobalClassNames.root,
            {
                 // CSS PROPS AND SELECTORS
            }
        ]
        ,footerButtons: [
            EditCategoryModalGlobalClassNames.footerButtons,
            {
                'margin-left': '10px'
            }
        ]
    };
}