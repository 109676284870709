export type FileShort = {
    id: number;
    name: string;
    mimeType: string;
    uniqueId: string;
    sharepointSite: string;
    url: string;
    isFolder: boolean;
    createdOn: string; 
    createdBy: string;
    isTeam: boolean;
    teamId?: string;
    rootFolderId?: number;
    isArchive?: boolean;
    size?: number;
    extension: string;
    currentUserRoleId: ArchiveRoleId;
    metadataShortJsonSchema: any  //eslint-disable-line @typescript-eslint/no-explicit-any
}

export enum ArchiveRoleId {
    Owner,
    Contributor,
    Reader,
    Architect,
    Professional,
    Uploader
}

export type ListFile = {
    files: FileShort[],
    skipToken: string | null | undefined
}