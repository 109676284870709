import { DefaultButton, mergeStyleSets, PrimaryButton } from "@fluentui/react"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import TeamsImage from "../../../../common/components/teamsImage/teamsImage";
import { ImageName } from "../../../../common/components/teamsImage/teamsImage.types";
import TeamsSpinner from "../../../../common/components/teamsSpinner/teamsSpinner";
import { User } from "../../../../models/User";
import { useOnMount } from "../../../../utilities/hooks";
import { useWorkflowDispatch } from "../../../workflowStore";
import {CreateWorkflowLabRequest} from "../../../services/workflow.contracts"
import {getWorkflowsLab} from "../../../features/workflowList";
import { workflowApi } from "../../../services/workflow.api";
import { Helpers } from "../../../../utilities/helpers";
import { WorkFlowLabRoleId } from "../../../../utilities/constants";


export type CreatingWorkflowLabPageData = {
    name?: string;
    members: User[];
}

export interface ICreateWorkflowLabFirstPageProps {
    initialState: CreatingWorkflowLabPageData;
    onClose?: () => void;
}

const classNames = mergeStyleSets({
    container: {
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        '> :not(:last-child)': {
            marginBottom: 20
        }
    },
    label: {
        fontSize: 24,
        fontWeight: 600,
        textAlign: 'center'
    },
    errorButtons: {
        display: 'flex',
        justifyContent: 'space-around',
        alignSelf: 'stretch'
    }
})

export const CreatingWorkflowLabPage = (props: ICreateWorkflowLabFirstPageProps) => {
    const [success, setSuccess] = useState<boolean | undefined>(undefined);
    const [creating, setCreating] = useState(false);
    const [errorCount, setErrorCount] = useState(0);
    const dispatch = useWorkflowDispatch();
    const { t } = useTranslation(['createWorkflowLabModal', 'common','createActivity']);

    useOnMount(() => void createWorkflowLab());

    useEffect(() => { 
        success && 
            dispatch(getWorkflowsLab()) 
    }, [dispatch, success])

    const createWorkflowLab = async () => {
        if (success)
            return;

        setCreating(true);
        setSuccess(undefined);

        const data = props.initialState;

        const request: CreateWorkflowLabRequest = {
            name: data.name ?? "",
            members: data.members.map(member => ({
                userId: member.id,
                WorkflowLabRoleId: member.workFlowLabRoleId ?? WorkFlowLabRoleId.User
            }))
        }

    try {await Promise.all([workflowApi.createWorkflowLab(request), Helpers.delay(500)]); setSuccess(true);}
        catch { setErrorCount(errorCount + 1); setSuccess(false); }
        finally { setCreating(false); }
    }

    return (
        <div className={classNames.container}>
            {creating && !success &&
                <>
                    <TeamsSpinner />
                    <span className={classNames.label}>{t('creatingWorkflowLab')}</span>
                </>
            }
            {!creating && success &&
                <>
                    <TeamsImage imageName={ImageName.WellDone} style={{ width: '80%' }} />
                    <span className={classNames.label}>{t('common:niceWork')}</span>
                    <PrimaryButton text={t('common:leave')} onClick={props.onClose} style={{ flex: '0 0 auto' }} />
                </>
            }
            {!creating && success === false &&
                <>
                    <div className={classNames.label}>{t('common:genericErrorApi')}{t('ofWorkflowLab')}</div>
                    <div className={classNames.errorButtons}>
                        {errorCount < 3 && <DefaultButton text={t('common:tryAgain')} onClick={createWorkflowLab} />}
                        <PrimaryButton text={t('common:leave')} onClick={props.onClose} />
                    </div>
                </>
            }
        </div>
    )
}