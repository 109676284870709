import { IStyleFunction } from "@fluentui/react"
import { IWorkflowDisjointedGraphContainerPropsStyles, IWorkflowDisjointedGraphContainerStyles } from "./workflowDisjointedGraphContainer.types"

export const WorkflowDisjointedGraphContainerGlobalClassNames = {
    root: 'workflow-disjointer-graph-container-modal-root',
    legendContainer: 'workflow-disjointer-graph-container-legend-container',
    legendItem: 'workflow-disjointer-graph-container-legend-item',
    legendCheck: 'workflow-disjointer-graph-container-legend-check',
    filterContainer: 'workflow-disjointer-graph-container-container',
    rightContainer: 'workflow-disjointer-graph-container-right-container',
    filterItem: 'workflow-disjointer-graph-container-filter-item',
    centerItem: 'workflow-disjointer-graph-container-filter-item'
}

export const getStyles: IStyleFunction<IWorkflowDisjointedGraphContainerPropsStyles, IWorkflowDisjointedGraphContainerStyles> = (props: IWorkflowDisjointedGraphContainerPropsStyles): IWorkflowDisjointedGraphContainerStyles => {
    return {
        root: [
            WorkflowDisjointedGraphContainerGlobalClassNames.root,
            {
                // CSS PROPS AND SELECTORS
            }
        ],
        legendContainer: [
            WorkflowDisjointedGraphContainerGlobalClassNames.legendContainer,
            {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                height: '100%',
                flexWrap: 'wrap',
                '@media(max-width: 1410px)': {
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    alignContent: 'center',
                    rowGap: 10
                }

            }
        ],
        legendItem: [
            WorkflowDisjointedGraphContainerGlobalClassNames.legendItem,
            {
                display: 'flex',
                alignItems: 'center',
                columnGap: '7px',
                marginLeft: 10,             
                fontSize: 13,   
                '@media(max-width: 1410px)': { 
                    margin: 0
                }
            }
        ],
        legendCheck: [
            WorkflowDisjointedGraphContainerGlobalClassNames.legendCheck,
            {
                height: 7,
                maxHeight: 7,
                width: 7,
                maxWidth: 7,
            }
        ],
        filterContainer: [
            WorkflowDisjointedGraphContainerGlobalClassNames.filterContainer,
            {
                display: 'flex',
                justifyContent: 'center',
                flexWrap: 'wrap',
            }
        ],
        rightContainer: [
            WorkflowDisjointedGraphContainerGlobalClassNames.rightContainer,
            {
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                marginLeft: 'auto'
            }
        ],
        filterItem: [
            WorkflowDisjointedGraphContainerGlobalClassNames.filterItem, 
            {
                display: 'flex',
                flexDirection: 'column',
                paddingBottom: '10px',
                marginLeft: '10px'
            }
        ],
        centerItem: [
            WorkflowDisjointedGraphContainerGlobalClassNames.centerItem,
            {
                width: '100%',
                display: 'flex',
                alignItems: 'center', 
                justifyContent: 'center'
            }
        ]
    };
}