import { styled } from "@fluentui/react";
import { getStyles } from "./workflowLabList.styles";
import { IWorkflowLabListPropsStyles, IWorkflowLabListStyles, IWorkflowLabListProps } from "./workflowLabList.types";
import { WorkflowLabListBase } from "./workflowLabList.base";

const WorkflowLabList = styled<IWorkflowLabListProps, IWorkflowLabListPropsStyles, IWorkflowLabListStyles>(
    WorkflowLabListBase,
    getStyles,
    undefined,
    { scope: 'WorkflowLabList' }
);

export default WorkflowLabList;