import { FontWeights, IStyleFunction } from "@fluentui/react"
import { ILocalFilePropsStyles, ILocalFileStyles } from "./localFile.types"

export const LocalFileGlobalClassNames = {
    root: 'edi-local-file-root',
    subtitle: 'edi-local-file-subtitle',
    text: 'edi-local-file-text',
    iconContainer: 'edi-local-icon-container'
}

export const getStyles: IStyleFunction<ILocalFilePropsStyles, ILocalFileStyles> = (props: ILocalFilePropsStyles): ILocalFileStyles => {
    const { palette, fonts } = props.theme!; //eslint-disable-line @typescript-eslint/no-non-null-assertion
    
    return {
        root: [
            LocalFileGlobalClassNames.root, {
               height: '80%'
            }
        ],
        subTitle: [
            LocalFileGlobalClassNames.subtitle, 
            fonts.medium,
            {
               fontWeight: FontWeights.semibold,
               color: palette.neutralPrimary,
               fontSize: '14px',
               marginBottom: '5px'
            }
            
        ],
        text: [
            LocalFileGlobalClassNames.text, 
            fonts.medium,
            {
               color: palette.neutralPrimary,
               fontSize: '14px',
               marginBottom: '10px',
               display: 'flex'
            }
        ],
        iconContainer: [
            LocalFileGlobalClassNames.iconContainer,{
                height: 'auto',
                width: 'auto',
                alignItems: 'center',
                display: 'flex',
                marginLeft: '10px'
            }
        ]
    };
}