import { styled } from "@fluentui/react";
import { getStyles } from "./renameWorkflowLabModal.styles";
import { IRenameWorkflowLabModalProps, IRenameWorkflowLabModalPropsStyles, IRenameWorkflowLabModalStyles } from "./renameWorkflowLabModal.types";
import { RenameWorkflowLabModalBase } from "./renameWorkflowLabModal.base";

const RenameWorkflowLabModal = styled<IRenameWorkflowLabModalProps, IRenameWorkflowLabModalPropsStyles, IRenameWorkflowLabModalStyles>(
    RenameWorkflowLabModalBase,
    getStyles,
    undefined,
    { scope: 'RenameWorkflowLabModal' }
);

export default RenameWorkflowLabModal;