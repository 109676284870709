import { styled } from "@fluentui/react";
import { WorkflowLabTemplatesSettingsBase } from "./workflowLabTemplatesSettings.base";
import { getStyles } from "./workflowLabTemplatesSettings.styles";
import { IWorkflowLabTemplatesSettingsProps, IWorkflowLabTemplatesSettingsPropsStyles, IWorkflowLabTemplatesSettingsStyles } from "./workflowLabTemplatesSettings.types";

const WorkflowLabTemplatesSettings = styled<IWorkflowLabTemplatesSettingsProps, IWorkflowLabTemplatesSettingsPropsStyles, IWorkflowLabTemplatesSettingsStyles>(
    WorkflowLabTemplatesSettingsBase,
    getStyles,
    undefined,
    { scope: 'WorkflowLabTemplatesSettings' }
);

export default WorkflowLabTemplatesSettings;