import { styled } from "@fluentui/react";
import { getStyles } from "./deleteLabelModal.styles";
import { IDeleteLabelModalPropsStyles, IDeleteLabelModalStyles, IDeleteLabelModalProps } from "./deleteLabelModal.types";
import { DeleteLabelModalBase } from "./deleteLabelModal.base";

const DeleteLabelModal = styled<IDeleteLabelModalProps, IDeleteLabelModalPropsStyles, IDeleteLabelModalStyles>(
    DeleteLabelModalBase,
    getStyles,
    undefined,
    { scope: 'DeleteLabelModal' }
);

export default DeleteLabelModal;