import { PartialTheme } from '@fluentui/react';
import {
  breadcrumbStyles,
  comboBoxStyles, commandBarButtonStyles, commandBarStyles, compoundButtonStyles,
  contextualMenuStyles,
  defaultButtonStyles, detailsColumnStyles, detailsHeaderStyles, detailsRowStyles,
  dialogContentStyles, dialogFooterStyles, documentCardStyles, dropdownStyles, modalStyles,
  navStyles, panelStyles, peoplePickerItemSuggestionStyles, peoplePickerStyles, pivotStyles, primaryButtonStyles, spinnerStyles, suggestionsItemStyles, suggestionsPeoplePickerStyles, textFieldStyles, tooltipStyles
} from './light.components';

export const themeLight: PartialTheme = {
  components: {
    CalloutContent: {},
    DatePicker: {},
    Check: {},
    Checkbox: {},
    ChoiceGroupOption: {},
    ComboBox: { styles: comboBoxStyles },
    CommandBar: { styles: commandBarStyles },
    CommandBarButton: { styles: commandBarButtonStyles },
    CompoundButton: { styles: compoundButtonStyles },
    DefaultButton: { styles: defaultButtonStyles },
    DetailsList: {},
    DocumentCard: { styles: documentCardStyles },
    PrimaryButton: { styles: primaryButtonStyles },
    Modal: { styles: modalStyles },
    MessageBar: {},
    Label: {},
    DialogContent: { styles: dialogContentStyles },
    DialogFooter: { styles: dialogFooterStyles },
    Panel: { styles: panelStyles },
    Persona: {},
    Toggle: {},
    ScrollablePane: {},
    SearchBox: {},
    Shimmer: {},
    SearchWellCallout: {},
    NormalPeoplePicker: { styles: peoplePickerStyles },
    Suggestions: { styles: suggestionsPeoplePickerStyles },
    SuggestionItem: { styles: suggestionsItemStyles },
    Pivot: { styles: pivotStyles },
    Spinner: { styles: spinnerStyles },
    Nav: { styles: navStyles },
    ContextualMenu: { styles: contextualMenuStyles },
    Breadcrumb: { styles: breadcrumbStyles },
    DetailsRow: { styles: detailsRowStyles },
    DetailsColumn: { styles: detailsColumnStyles },
    DetailsHeader: { styles: detailsHeaderStyles },
    TextField: { styles: textFieldStyles },
    Tooltip: {styles: tooltipStyles },
    Text: { styles: textFieldStyles },
    PeoplePickerItemSuggestion: { styles: peoplePickerItemSuggestionStyles },
    Dropdown: { styles: dropdownStyles }
  },
  palette: {
    themeDarker: "#33344a",
    themeDark: "#464775",
    themeDarkAlt: "#464775",
    themePrimary: "#6264a7",
    themeSecondary: "#8b8cc7",
    themeTertiary: "#bdbde6",
    themeLight: "#e2e2f6",
    themeLighter: "#f4f4fc",
    themeLighterAlt: "#f4f4fc",
    black: "#252423",
    "blackTranslucent40": "rgba(0,0,0,.4)",
    neutralDark: "#484644",
    neutralPrimary: "#605e5c",
    neutralPrimaryAlt: "#605e5c",
    neutralSecondary: "#979593",
    neutralSecondaryAlt: "#8a8886",
    neutralTertiary: "#c8c6c4",
    neutralTertiaryAlt: "#e1dfdd",
    neutralQuaternary: "#edebe9",
    neutralQuaternaryAlt: "#edebe9",
    neutralLight: "#f3f2f1",
    neutralLighter: "rgba(37, 36, 35, 0.05)",
    neutralLighterAlt: "#faf9f8",
    accent: "#6264a7",
    white: "#fff",
    "whiteTranslucent40": "rgba(255,255,255,.4)",
    yellowDark: "#d29200",
    yellow: "#ffb900",
    yellowLight: "#fff100",
    orange: "#d83b01",
    orangeLight: "#ea4300",
    orangeLighter: "#ff8c00",
    redDark: "#c4314b",
    red: "#e81123",
    magentaDark: "#5c005c",
    magenta: "#b4009e",
    magentaLight: "#e3008c",
    purpleDark: "#32145a",
    purple: "#5c2d91",
    purpleLight: "#b4a0ff",
    blueDark: "#002050",
    blueMid: "#00188f",
    blue: "#0078d4",
    blueLight: "#00bcf2",
    tealDark: "#004b50",
    teal: "#008272",
    tealLight: "#00b294",
    greenDark: "#004b1c",
    green: "#107c10",
    greenLight: "#bad80a"
  },
  semanticColors: {
    bodyBackground: "#faf9f8",
    menuDivider:"#aaa",
    variantBorder : '#e1dfdd',
    bodyText:'#605e5c'
    //errorText: "#a4262c",
    //messageText: "#323130",
    //messageLink: "#005A9E",
    //messageLinkHovered: "#004578",
    //infoIcon: "#605e5c",
    //errorIcon: "#A80000",
    //blockingIcon: "#FDE7E9",
    //warningIcon: "#797775",
    //severeWarningIcon: "#D83B01",
    //successIcon: "#107C10",
    //infoBackground: "#f3f2f1",
    //errorBackground: "#FDE7E9",
    //blockingBackground: "#FDE7E9",
    //warningBackground: "#FFF4CE",
    //severeWarningBackground: "#FED9CC",
    //successBackground: "#DFF6DD",
    //warningHighlight: "#ffb900",
    //warningText: "#323130", NON TROVATO SU GITHUB
    //successText: "#107C10",
    //listTextColor: "#605e5c" NON TROVATO SU GITHUB
  }
};