import { IBasePickerStyleProps, IBasePickerStyles, ILabelStyles, IShimmeredDetailsListStyleProps, IShimmeredDetailsListStyles, IStyleFunction } from "@fluentui/react"
import { ISingleDisjointmentPropsStyles, ISingleDisjointmentStyles } from "./singleDisjointment.types"

export const SingleStepGlobalClassNames = {
    root: 'edi-single-step-root',
    firstContainer: 'edi-single-step-firstContainer',
    stepSingleItem: 'edi-single-step-stepSingleItem',
    iconBarContainer: 'edi-single-step-iconBarContainer',
    subTitle: 'edi-single-step-subTitle',
    shimmer: 'edi-single-step-shimmer'
}

export const getStyles: IStyleFunction<ISingleDisjointmentPropsStyles, ISingleDisjointmentStyles> = (props: ISingleDisjointmentPropsStyles): ISingleDisjointmentStyles => {
    const { semanticColors, palette } = props.theme!; //eslint-disable-line @typescript-eslint/no-non-null-assertion

    return {
        root: [
            SingleStepGlobalClassNames.root,
            {
                display: 'flex',
                flexDirection: 'column',
                boxShadow: 'rgb(136 136 136) 0px 1px 8px 0px',
                padding: '20px 10px',
                margin: 10
            }
        ],
        firstContainer: [SingleStepGlobalClassNames.firstContainer, {
            display: 'flex',
            marginBottom: 10,
            gap: 20,
            justifyContent: 'space-between'
        }],
        singleDisjoinemntItem: [SingleStepGlobalClassNames.stepSingleItem, {
            flexGrow: 1,
        }],
        iconBarContainer: [SingleStepGlobalClassNames.iconBarContainer,{
            display:'flex',
            justifyContent: 'center',
            gap: 10
        }],
        peoplePickerStyles:[
            {             
            flex: '1 1 0',           
            input: {
                backgroundColor: 'transparent',
            },
            text: {
                border: '1px solid rgb(240, 240, 240) !important',
                borderRadius: 2,
                backgroundColor: 'rgb(240, 240, 240)',
            },
            itemsWrapper: {
                ".ms-PickerPersona-container": {
                    background: 'rgb(245, 245, 245)',
                    border: "1px solid rgb(245, 245, 245)",
                    ':hover': {
                        background: 'rgba(98, 100, 167, 0.2)',
                        border: "1px solid rgba(98, 100, 167, 0.2)",
                    }
                },
            },
            root: 
            {
                float: 'right',
                marginRight: '0 !important',
            },
            screenReaderText: {}
            }
        ],
        peoplePickerSuggestions: {
            padding: '8px 0'
        },
        peoplePickerSuggestionItem: {
            '::after': {
                display: 'none'
            },
            ".is-suggested": {
                background: palette.neutralQuaternary
            }
        },
        disclaimerZone: {
            display: 'flex',
            flexDirection: 'column',
            marginBottom: 30
        },
        labelBox: {
            width: '200px',
            overflow: 'hidden',
            height: '25px',
            padding: '3px 0'
        },
        labelsPickerZone: {
            display: 'flex',
            width: '100%',
            boxShadow: 'border-box'
        },
        labelsPickerSuggestions: {
            padding: '8px 0'
        },
        labelsPickerSuggestionItem: {
            '::after': {
                display: 'none',
            },
            ".is-suggested": {
                background: props.theme?.palette.neutralQuaternary
            }
        },
        detailsListRow: {
            background: 'transparent !important',
        },
        iconTagLabel: {
            width: '150px',
            overflow: 'hidden',
            height: '25px',
            padding: '3px 0',
            cursor: 'inherit',
        },
        iconTag: {
            width: '150px'
        },
        checkboxAlign: {
            height: '100%',
            alignItems: 'center',
            ".ms-Checkbox-label": {
                alignItems: 'center',
                height: '32px'
            },
            ".ms-Checkbox-text": {
                wordBreak: 'break-word',
                whiteSpace: 'pre-wrap',
                fontSize: '13px',
                lineHeight: '12px'
            },
        },
        containerTag: {
            display: 'flex',
            height: '100%',
            alignItems: 'center',
            flexFlow: 'row wrap',
            gap: '5px 10px'
        },
        profileContainer: {
            display: 'flex'
        },
        remainingIconTagContainer: {
            padding: '0 5px',
            width: '100%',
            textAlign: 'left',
            justifyContent: 'start',
            color: palette.themePrimary
        },
        subTitle: [SingleStepGlobalClassNames.subTitle,{
            fontSize:'12px',
            color: palette.neutralDark,
            marginBottom: 1
        }],
        shimmer: [SingleStepGlobalClassNames.shimmer,{
            height: 33,
            '>.ms-Shimmer-shimmerWrapper':{
                height: '100%'
            }
        }],
        subComponentStyles: {
            templatesCountCircle: (): ILabelStyles => {
                return {
                    root: {
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '50%',
                        width: '30px',
                        height: '30px',
                        fontSize: '12px',
                        backgroundColor: semanticColors.variantBorder,
                        color: palette.themePrimary,
                    }
                }
            },
            shimmeredDetailsList: (props: IShimmeredDetailsListStyleProps): IShimmeredDetailsListStyles => {
                return {
                    root: {
                        '::after': {
                            display: 'none'
                        },
                        position: 'relative'
                    }
                }
            },
            labelsPicker: (props: IBasePickerStyleProps): IBasePickerStyles => {
                return {
                    input: {
                        backgroundColor: 'transparent',
                    },
                    text: {
                        display: 'flex',
                        flexGrow: 1,
                        boxSizing:'border-box',
                        border: '1px solid rgb(240, 240, 240) !important',
                        borderRadius: 2,
                        backgroundColor: 'rgb(240, 240, 240)',
                        borderLeft: "none",
                        borderRight: "none",
                        borderTop: "none",
                        borderBottom: "none",
                        selectors: {
                            "::after": {
                                borderLeft: "none",
                                borderRight: "none",
                                borderTop: "none",
                                borderRadius: 0,
                            },
                        }
                    },
                    itemsWrapper: {
                        ".ms-PickerPersona-container": {
                            background: 'rgb(245, 245, 245)',
                            border: "1px solid rgb(245, 245, 245)",
                            ':hover': {
                                background: 'rgba(98, 100, 167, 0.2)',
                                border: "1px solid rgba(98, 100, 167, 0.2)",
                            }
                        },
                    },
                    root: {width:'100%'},
                    screenReaderText: {}
                }
            }
        }
    };
}