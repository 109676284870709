import { IStyleFunction } from "@fluentui/react"
import { IJsonFormPropsStyles, IJsonFormStyles } from "./jsonForm.types"

/*eslint-disable sonarjs/no-duplicate-string */

export const JsonFormGlobalClassNames = {
    root: 'edi-json-form-root'
}

export const getStyles: IStyleFunction<IJsonFormPropsStyles, IJsonFormStyles> = (props: IJsonFormPropsStyles): IJsonFormStyles => {
    const { palette } = props.theme!; //eslint-disable-line @typescript-eslint/no-non-null-assertion

    return {
        root: [
            JsonFormGlobalClassNames.root,
            {
                // CSS PROPS AND SELECTORS
            }
        ],
        childrenContainer: {
            ".ms-Label": {
                display: 'none'
            },
            ".labelField": {
                color: palette.black,
                fontWeight: 400,
                paddingBottom: '5px'
            },
            ".ms-TextField-errorMessage": {
                display: "none !important"
            },
            ".ms-TextField-fieldGroup": {
                '::after': {
                    borderBottom: "1px solid " + palette.themePrimary
                }
            },
            ".ms-TextField-field, .ms-Dropdown-container": {
                backgroundColor: palette.neutralLight,
                selectors: {
                    ':disabled': {
                        color: palette.neutralDark + " !important",
                        pointerEvents: 'none',
                        cursor: 'no-drop'
                    }
                }
            },
            ".ms-Dropdown.is-disabled > .ms-Dropdown-title": {
                backgroundColor: palette.neutralTertiary + " !important",
                color: palette.black
            },
            "input": {
                color: palette.neutralPrimary
            }
        },
        childrenContainerDisabled: {
            ".ms-TextField-field": {
                background: palette.neutralTertiary,
                pointerEvents: 'none',
                cursor: 'no-drop'
            },
            "input": {
                color: palette.neutralPrimary
            }
        },
        childrenContainerWithError: {
            ".ms-Label": {
                // color: palette.redDark + " !important",
                // fontWeight: 400
                display: 'none'
            },
            ".labelField": {
                color: palette.redDark + " !important",
                fontWeight: 400,
                paddingBottom: '5px'
            },
            ".ms-TextField-errorMessage": {
                display: "none !important"
            },
            ".ms-TextField-fieldGroup": {
                '::after': {
                    borderBottom: "1px solid " + palette.redDark
                }
            }
        },
        errorForm: {
            color: palette.redDark,
            fontSize: 12
        },
        titleSection: {
            fontSize: 20,
            fontWeight: "bold",
            margin: "15px 0"
        },
        arrayFieldItem: {
            display: 'flex',
            gap: 30,
            alignItems: 'flex-start',
            paddingTop: 10,
            ".ms-TextField-fieldGroup": {
                '::after': {
                    borderBottom: "1px solid " + palette.themePrimary
                }
            }
        },
        arrayFieldItemWithError: {
            display: 'flex',
            gap: 30,
            alignItems: 'flex-start',
            paddingTop: 10,
            ".ms-TextField-fieldGroup": {
                '::after': {
                    borderBottom: "1px solid " + palette.redDark
                }
            }
        },
        help: {
            fontSize: 12,
            margin: '5px 0',
            color: palette.neutralPrimary,
            fontWeight: 400,
            wordBreak: 'break-word'
        },
        required: {
            color: palette.redDark,
            paddingLeft: '5px'
        },
        label: {
            padding: '5px 0'
        },
        errorLabel: {
            color: palette.redDark
        },
        subComponentStyles: {
            dropdownStyle: {
                dropdown: {
                    '::before': {
                        display: 'none'
                    }
                }
            },
            iconButton: {
                root: {
                    selectors: {
                        ':disabled': {
                            backgroundColor: palette.neutralQuaternary,
                            color: palette.neutralTertiary,
                            pointerEvents: 'none'
                        },
                    }
                },
                iconHovered: {},
                menuIcon: {},
                rootExpanded: {},
                rootPressed: {},
                rootHovered: {}
            }
        }
    };
}