/* eslint-disable sonarjs/cognitive-complexity */
import React, { useCallback, useState } from "react";
import { classNamesFunction, ConstrainMode, IColumn, Icon, SelectionMode, ShimmeredDetailsList, Stack, StackItem } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import TeamsImage from "../../../../../../common/components/teamsImage/teamsImage";
import { ImageName } from "../../../../../../common/components/teamsImage/teamsImage.types";
import { ArchiveRoleId, FileShort } from "../../../../../../models/fileShort";
import teamsAuthService from "../../../../../../modules/authentication/teamsAuthService";
import webAuthService from "../../../../../../modules/authentication/webAuthService";
import { useAsyncApi, useOnMount } from "../../../../../../utilities/hooks";
import { workflowApi } from "../../../../../services/workflow.api";
import { IArchiveEDINavigationProps, IArchiveEDINavigationPropsStyles, IArchiveEDINavigationStyles } from "./archiveEDINavigation.types";

const getClassNames = classNamesFunction<IArchiveEDINavigationPropsStyles, IArchiveEDINavigationStyles>();

export const ArchiveEDINavigationBase = (props: IArchiveEDINavigationProps) => {
    const { t } = useTranslation(['archiveEDINavigation', 'common']);
    const [teamsList, setTeamsList] = useState<FileShort[]>([]);

    //TODO impostare infinity scroll
    const { loading, error, execute: getTeams, value: items } = useAsyncApi<void, FileShort[]>({
        func: async (): Promise<FileShort[]> => { 
            const response = await workflowApi.getEdiFiles('', 30, 0); 
            setTeamsList(response);
             
            return response;
        }
    });

    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className, isEmptyList: !items || items?.length === 0 });

    useOnMount(() => {
        getTeams();
    });

    const emptyTeamsList = useCallback(() => {
        if (loading || (items?.length !== 0))
            return null;

        return (
            <div className={classNames.emptyFolder}>
                <TeamsImage
                    imageName={ImageName.EmptyFolderDrop}
                    fullContainer
                    caption={t("common:emptyFolder")}
                />
            </div>
        )
    }, [classNames.emptyFolder, items, t, loading]);

    const columns : IColumn[] = [   
    {
        key: 'file-type',
        name: 'File Type',
        iconName: 'Page',
        isIconOnly: true,
        fieldName: '',
        minWidth: 20,
        maxWidth: 20,
        onRender: function getItemCell(item: FileShort) {
            return <span> <Icon iconName="Archive" /></span>
        }
    }, 
    {
        key: 'Name',
        name: t("name"),
        fieldName: 'Name',
        minWidth: 100,
        maxWidth: 350,
        isRowHeader: true,
        isResizable: true,
        onRender: function getItemName(item: FileShort) {
            return (
                props.interaction ?
                (item.currentUserRoleId === ArchiveRoleId.Contributor || item.currentUserRoleId === ArchiveRoleId.Owner || item.currentUserRoleId === ArchiveRoleId.Architect || item.currentUserRoleId === ArchiveRoleId.Professional || item.currentUserRoleId === ArchiveRoleId.Uploader) ?
                <span
                    onClick={() => {props.onTeamSelected(item)}}
                    style={{ cursor: 'pointer' }}
                >
                    {item.name}
                </span> : <span
                    style={{color:'GrayText' }}
                >
                    {item.name}
                </span> : <span
                    onClick={() => {props.onTeamSelected(item)}}
                    style={{ cursor: 'pointer' }}
                >
                    {item.name}
                </span> 
            )
        }
    }];

    return (
        <div className={classNames.root} id={"modalListZone"}>
            {error &&
                <TeamsImage
                    imageName={ImageName.Forbidden}
                    scale={0.3}
                    fullContainer
                    caption={t("errorForbidden")}
                />
            }
            {!error &&
                <>
                    <Stack horizontal>
                        <StackItem grow={2}> 
                        <div className={classNames.breadcrumbContainer}>

                            <div className={classNames.iconContainer}>
                                <Icon styles={{ root: { fontSize: '18px' } }} iconName="home" />
                            </div>
                            <div className={classNames.nameContainer}> 
                            {window.self !== window.top ? teamsAuthService.currentUserName  : webAuthService.currentUserName}                            </div>                           
                        </div>                
                        </StackItem>
                    </Stack>


                    <div id={"SPOfolderContentDetailsListZone"} style={{ height: '290px', overflow: 'scroll' }}>
                        <ShimmeredDetailsList
                            enableShimmer={loading}
                            items={teamsList ?? []}
                            columns={columns}
                            getKey={(item: FileShort) => item && `${item.id}`}
                            selectionMode={SelectionMode.none}
                            constrainMode={ConstrainMode.unconstrained}
                            onRenderDetailsFooter={emptyTeamsList}
                            onShouldVirtualize={() => false}
                            compact={true}
                            isHeaderVisible={false}
                            styles={classNames.subComponentStyles.shimmeredDetailsList}
                        />
                    </div>
                </>
            }
        </div>
    );
}