import { apiClient } from '../apiClient/apiClient';
import { svcPaths } from '../apiClient/config';
import { CurrentUserInfo } from './user.contracts';

class UsersApiClass {
    async getCurrentUserInfo(id: string) {
        const response = await apiClient.get<CurrentUserInfo>(`${svcPaths.workflow}/users/${id}`);
        return response.data
    }
} 

export const usersApi = new UsersApiClass();