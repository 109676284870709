import { PersonaDetails } from "../../common/models/PersonaDetails";
import { FileShort } from "../../models/fileShort";
import { User } from "../../models/User";
import { UserShort } from "../../models/UserShort";
import { WorkFlow } from "../../models/WorkFlow";
import { WorkFlowApprover } from "../../models/workflowApprover";
import { WorkflowLabShort, WorkflowShort } from "../../models/WorkflowShort";
import { WorkFlowLabRoleId } from "../../utilities/constants";
import { showRenameWorkflowModal } from "../features/workflowList";
import { INavigator } from "../hooks/useNavigator";
import { WorkflowDispatch } from "../workflowStore";
import { WorkflowLabSettingsTab, WorkflowLabTemplateTab } from "./routes";

export type ActionWorkflowEntry = {
    workflowLabRoles: WorkFlowLabRoleId[];
    label: string;
    onClick: (workflowLab: WorkflowLabShort, navigator: INavigator, dispatch?: WorkflowDispatch) => void;
    icon?: string;
    subActionItems?: ActionWorkflowEntry[];
    condition?: (workflowLab: WorkflowLabShort) => boolean;
}

const actions: ActionWorkflowEntry[] = [
    {
        label:  'common:commands.manageUsers',
        icon: 'PlayerSettings',
        onClick: (workflowlab, navigator) => navigator.goToWorkflowLabSettingsTab(WorkflowLabSettingsTab.users, workflowlab.id), 
        workflowLabRoles: [WorkFlowLabRoleId.Owner],
    },
    {
        label:  'common:commands.template',
        icon: 'AutoFillTemplate',
        onClick: (workflowlab, navigator) => navigator.goToWorkflowLabTemplateTabPath(WorkflowLabTemplateTab.template, workflowlab.id), 
        workflowLabRoles: [WorkFlowLabRoleId.Owner, WorkFlowLabRoleId.Supervisor]
    },
    {
        label:  'common:commands.renameWF',
        icon: 'Rename',
        onClick: (archive, navigator, dispatch) => {if(dispatch !== undefined) dispatch(showRenameWorkflowModal(true))},
        workflowLabRoles: [WorkFlowLabRoleId.Owner]
    }
];

export function isPdf(file: FileShort){
    if (!file)
        return false;
    const split = file.name.split(".");
    if(split){
        const ext = split[split.length - 1];
        if(ext === "pdf" || file.mimeType === "application/pdf"){
            return true;
        }
    }
    
    return false;
}

export const getWorkflowLabActions = (workflowLab: WorkflowLabShort) => {
    return actions.filter(entry =>
        (entry.condition === undefined || entry.condition(workflowLab) === true) &&
        entry.workflowLabRoles.includes(workflowLab.roleId));
}

export const mapToPersonaDetails = (users: UserShort[] | null): PersonaDetails[] => {

    const filteredUser = users === null ? [] : users.filter(u => u !== null);
    return filteredUser.map(a => {
        return { firstName: a.firstName, lastName: a.lastName, email: '' } as PersonaDetails;
    });
}

export const mapApproverToUser = (user: User | undefined): UserShort | null => {
    if(user === undefined){
        return null
    }
        return { firstName: user.firstName, lastName: user.lastName, email: user.email } as UserShort;
}

export const mapWorkFlowApproverToUser = (approvers: WorkFlowApprover[] | undefined): UserShort[] => {
    const filteredUser = approvers === undefined || approvers === null ? [] : approvers.filter(u => u !== null);
    return filteredUser.map(a => {
        return { firstName: a.approver.firstName, lastName: a.approver.lastName, email: a.approver.email } as UserShort;
    });
}

export const mapWorkflowToShort = (workflows: WorkFlow[]): WorkflowShort[] => {
    return workflows.map( w => {
        return { creator : mapApproverToUser(w.creator), id: w.id, name:w.name, relativeStatus: w.relativeStatus, createdOn:w.createdOn, documentName: w.documentName, involvedPeople: mapWorkFlowApproverToUser(w.involvedPeople), isDocumentAttachable: w.isDocumentAttachable } as WorkflowShort;
    });
}