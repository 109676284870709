import { PartialTheme } from '@fluentui/react';
import {
  comboBoxStyles, commandBarButtonStyles, commandBarStyles, compoundButtonStyles,
  defaultButtonStyles, detailsColumnStyles, detailsHeaderStyles, detailsRowStyles,
  dialogContentStyles, dialogFooterStyles, documentCardStyles, modalStyles,
  panelStyles, primaryButtonStyles, navStyles, breadcrumbStyles, peoplePickerStyles, suggestionsPeoplePickerStyles, pivotStyles, dropdownStyles, contextualMenuStyles
} from './dark.components';

export const themeDark: PartialTheme = {
  components: {
    CalloutContent: {},
    DatePicker: {},
    Check: {},
    Checkbox: {},
    ChoiceGroupOption: {},
    ComboBox: { styles: comboBoxStyles },
    CommandBar: { styles: commandBarStyles },
    CommandBarButton: { styles: commandBarButtonStyles },
    CompoundButton: { styles: compoundButtonStyles },
    DefaultButton: { styles: defaultButtonStyles },
    DetailsRow: { styles: detailsRowStyles },
    DetailsList: {},
    DetailsColumn: { styles: detailsColumnStyles },
    DetailsHeader: { styles: detailsHeaderStyles },
    DocumentCard: { styles: documentCardStyles },
    PrimaryButton: { styles: primaryButtonStyles },
    Modal: { styles: modalStyles },
    MessageBar: {},
    Label: {},
    DialogContent: { styles: dialogContentStyles },
    DialogFooter: { styles: dialogFooterStyles },
    TextField: {},
    Tooltip: {},
    Panel: { styles: panelStyles },
    Persona: {},
    Toggle: {},
    ScrollablePane: {},
    SearchBox: {},
    Shimmer: {},
    SearchWellCallout: {},
    NormalPeoplePicker: {styles: peoplePickerStyles },
    Suggestions:{styles: suggestionsPeoplePickerStyles },
    Nav: { styles: navStyles },
    Breadcrumb: { styles: breadcrumbStyles},
    Pivot: { styles: pivotStyles },
    Dropdown: {styles: dropdownStyles },
    ContextualMenu: { styles: contextualMenuStyles },
  },
  palette: {
    themeDarker: "#c7c9ff",
    themeDark: "#b2b5ff",
    themeDarkAlt: "#b2b5ff",
    themePrimary: "#9ea2ff",
    themeSecondary: "#8a8fff",
    themeTertiary: "#6264a7",
    themeLight: "#494b83",
    themeLighter: "#323348",
    themeLighterAlt: "#323348",
    black: "#fff",
    "blackTranslucent40": "rgba(0,0,0,.4)",
    neutralDark: "#d6d6d6",
    neutralPrimary: "#adadad",
    neutralPrimaryAlt: "#adadad",
    neutralSecondary: "#8a8a8a",
    neutralSecondaryAlt: "#8a8886",
    neutralTertiary: "#666",
    neutralTertiaryAlt: "#5c5c5c",
    neutralQuaternary: "#3d3d3d",
    neutralQuaternaryAlt: "#3d3d3d",
    neutralLight: "#141414",
    neutralLighter: "#1f1f1f",
    neutralLighterAlt: "#242424",
    accent: "#9ea2ff",
    white: "#292929",
    "whiteTranslucent40": "rgba(255,255,255,.4)",
    yellowDark: "#d29200",
    yellow: "#ffb900",
    yellowLight: "#fff100",
    orange: "#d83b01",
    orangeLight: "#ea4300",
    orangeLighter: "#ff8c00",
    redDark: "#f9526b",
    red: "#e81123",
    magentaDark: "#5c005c",
    magenta: "#b4009e",
    magentaLight: "#e3008c",
    purpleDark: "#32145a",
    purple: "#5c2d91",
    purpleLight: "#b4a0ff",
    blueDark: "#002050",
    blueMid: "#00188f",
    blue: "#0078d4",
    blueLight: "#00bcf2",
    tealDark: "#004b50",
    teal: "#008272",
    tealLight: "#00b294",
    greenDark: "#004b1c",
    green: "#107c10",
    greenLight: "#bad80a"
  },
  semanticColors: {
    bodyBackground: "#1f1f1f",
    listHeaderBackgroundHovered: "rgba(255, 255, 255, 0.05)",
    listItemBackgroundHovered: "#2d2c2c",
    listItemBackgroundChecked: "#2d2c2c",
    menuDivider:"#000",
    variantBorder : '#000',
    bodyText: '#adadad'
  }
};