import { classNamesFunction, PrimaryButton } from "@fluentui/react";
import React, { useState } from "react";
import { FileFormatExtension } from "../../../utilities/constants";
import { useOnMount } from "../../../utilities/hooks";
import { IUploadButtonProps, IUploadButtonPropsStyles, IUploadButtonStyles } from "./uploadButton.types";

const getClassNames = classNamesFunction<IUploadButtonPropsStyles, IUploadButtonStyles>();

export const UploadButtonBase = (props: IUploadButtonProps) => {
  const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
  const [acceptedExtensions, setAcceptedExtensions] = useState<string>();

  useOnMount(() => {
    let extensionList: string[] = [];

    props.acceptedFile.forEach(val => {
      const valueToAdd = FileFormatExtension.find(a => a.key === val)?.extension;
      if(valueToAdd)
      extensionList= [...extensionList, valueToAdd];
    });

    setAcceptedExtensions(extensionList.toString())
  });

  return (
    <PrimaryButton
      className={classNames.uploadButton}
      disabled={props.isDisabled}>
      {props.label}
      <input
        id='upload'
        className={classNames.inputFile}
        type='file'
        title=''
        onChange={ev => { (ev.target.files && props.uploadFile(Array.from(ev.target.files))); (ev.target as HTMLInputElement).value = "" }}
        disabled={props.isDisabled}
        accept={acceptedExtensions}
        multiple={props.multiple}
      />
    </PrimaryButton>
  );
}