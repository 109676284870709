import { DefaultButton, IButtonStyles, ILabelStyleProps, ILabelStyles, IPalette, IStyleFunction, Label, Stack, TooltipDelay, TooltipHost } from "@fluentui/react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import IconTag from "../../../../../common/components/iconTag/iconTag";
import { User } from "../../../../../models/User";
import { WorkflowLabShort } from "../../../../../models/WorkflowShort";
import AssociateLabelModal from "../../associateLabelModal/associateLabelModal";

export interface IMemberLabelsModalProps {
    member: User;
    labelListStyle: string;
    templatesCountCircleStyle: IStyleFunction<ILabelStyleProps, ILabelStyles>;
    labelBoxStyle: string;
    remainingIconTagContainerStyle: string;
    iconButtonStyles: IButtonStyles | undefined;
    itemIndex: number | undefined;
    onActionComplete: () => void;
    currentWorkflowLab: WorkflowLabShort | undefined;
    onActivitiesSaved: () => void;
    palette: IPalette;
    containerTagStyle: string;
    calloutTarget: string;
}

export const MemberLabelsModal = (props: IMemberLabelsModalProps) => {
    const { calloutTarget, containerTagStyle, palette, onActivitiesSaved, currentWorkflowLab, itemIndex, member, labelListStyle, templatesCountCircleStyle, labelBoxStyle, remainingIconTagContainerStyle } = props;
    const { t } = useTranslation(["workflowLabUsersSettings", "common"]);
    const labelList = member.labels.slice(0, 3);
    const remainingMembersList = member.labels.slice(3, member.labels.length);
    const [viewModal, setViewModal] = useState(false);

    const remainingMembersTooltip = (remainingMembersList: string[]): JSX.Element => {
        return (
            <Stack horizontalAlign={'start'} tokens={{ childrenGap: 2 }}>
                {remainingMembersList.map((x, i) => <Label key={i} className={remainingIconTagContainerStyle}>{x}</Label>)}
            </Stack>
        );
    }

    const showModal = (isOpen: boolean) => {
        setViewModal(isOpen);
    }
    
    return (
        <div className={labelListStyle} style={{ display: 'flex' }} >
            <div className={containerTagStyle}>
                {labelList.map((u, i) => {
                    return (
                        <div key={i} className="icontag-container">
                            <IconTag key={u.id} label={u.name} labelClassName={labelBoxStyle} tooltip />
                        </div>
                    )
                })}
                {remainingMembersList.length > 0 &&
                    <TooltipHost
                        id={'remainingMemberTooltips'}
                        content={remainingMembersTooltip(remainingMembersList.map(i => i.name))}
                        delay={TooltipDelay.zero}
                    >
                        <Label styles={templatesCountCircleStyle}>+{remainingMembersList.length}</Label>
                    </TooltipHost>
                }
            </div>

            <React.Fragment>
                {!currentWorkflowLab?.workflowLabMaster &&
                    <div className={"icontag-container"}>
                        <DefaultButton
                            id={`${calloutTarget}${itemIndex}`}
                            text={t('setLabels')}
                            onClick={() => showModal(true)}
                            style={{ width: "fit-content", borderRadius: 5, borderColor: palette.themePrimary }}
                            className={labelBoxStyle}
                            allowDisabledFocus
                            disabled={viewModal}
                        />
                    </div>
                }
                {viewModal &&
                    <AssociateLabelModal
                        showModalBool={viewModal}
                        showModalFunction={(bool: boolean) => showModal(bool)}
                        member={member}
                        currentWorkflowLab={currentWorkflowLab}
                        onActivitiesSaved={onActivitiesSaved}
                    />
                }
            </React.Fragment>
        </div>
    );
}