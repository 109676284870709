import React, { useCallback, useEffect, useState } from "react";
import { classNamesFunction, DefaultButton, Icon, IStyleFunctionOrObject, ITextFieldStyleProps, ITextFieldStyles, keyframes, PrimaryButton, Spinner, SpinnerSize, TextField } from "@fluentui/react";
import { ICreateLabelModalStyles, ICreateLabelModalProps, ICreateLabelModalPropsStyles } from "./createLabelModal.types";
import { useTranslation } from "react-i18next";
import { labelApi } from "../../../services/label/label.api";
import EdiModal from "../../../../common/components/ediModal/ediModal";
import { Helpers } from "../../../../utilities/helpers";
import { Constants } from "../../../../models/constants";
import { useAsyncApi } from "../../../../utilities/hooks";
import { ErrorsType } from "../../../../common/types/ErrorsType";
const getClassNames = classNamesFunction<ICreateLabelModalPropsStyles, ICreateLabelModalStyles>();

export const CreateLabelModalBase = (props: ICreateLabelModalProps) => {
    const classNames = getClassNames(props.styles,  { theme: props.theme, className: props.className });
    const { palette } = props.theme!; // eslint-disable-line @typescript-eslint/no-non-null-assertion
    const { t } = useTranslation(['createLabelModal', 'common']);
    const [currentName, setCurrentName] = useState<string | undefined>(undefined);
    const [validationIcon, setValidationIcon] = useState('Error');
    const [currentDescription, setCurrentDescription] = useState<string | undefined>(undefined);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(false);
    
    const onNameValidationResult = useCallback((error: string | JSX.Element, value: string | undefined) => {
        setValidationIcon(error ? 'Error' : 'Completed');
    }, []);

    useEffect(() => {
        setErrorMessage(undefined)
    }, [props.onClose])

    const validateWorkflowLabName = useCallback(async (value: string) => {
        if (!value)
            return `${t('common:fieldRequired')}`;

        setValidationIcon('ProgressRingDots');

        try {
            const [isValid] = await Promise.all([labelApi.checkLabelName(props.workFlowLabId, value), Helpers.delay(500)]);
            return isValid ? "" : t('common:nameNotAvailable');
        }
        catch {
            return t('common:genericErrorCheckName');
        }
    }, [t, props.workFlowLabId]);

    const setupParameter = () => {
        return <div>
            <TextField
                    label={t('common:name')}
                    placeholder={t('addName')}
                    deferredValidationTime={250}
                    onGetErrorMessage={validateWorkflowLabName}
                    required
                    autoFocus
                    autoComplete={"off"}
                    styles={styles}
                    onNotifyValidationResult={onNameValidationResult}
                    onChange={(_, newValue) => setCurrentName(newValue)}
                    maxLength={Constants.MAX_LENGTH_LABEL_NAME}
                    onRenderSuffix={() => <Icon iconName={validationIcon} />}
                />
            <TextField 
                maxLength={Constants.MAX_LENGHT_LABEL_DESCRIPTION}
                label={t('common:description')}
                multiline 
                rows={5} 
                onChange={(_, newValue) => setCurrentDescription(newValue)}/>
        </div>
    }

    const styles: IStyleFunctionOrObject<ITextFieldStyleProps, ITextFieldStyles> = () => {
        return {
            root: {
                marginBottom: '30px'
            },
            errorMessage: {
                position: 'absolute'
            },
            suffix: [
                { background: 'transparent', },
                validationIcon === 'Error' && { color: palette.redDark },
                validationIcon === 'Completed' && { color: palette.greenDark },
                validationIcon === 'ProgressRingDots' && {
                    '> i': {
                        animation: `${keyframes({ to: { transform: 'rotate(360deg)' } })} 1s linear infinite`
                    }
                }
            ]
        }
    }

    const createLabel = useAsyncApi({
        func: async () => {
            try {
                setLoading(true);
                if(currentName) {
                    await labelApi.createLabel(props.workFlowLabId, currentName, currentDescription);
                    if (props.onComplete)
                        props.onComplete();
                    if (props.onClose)
                        props.onClose();
                }
            } catch (er) {
                const error: ErrorsType = er as ErrorsType;
                if (error.code === 400) {
                    setErrorMessage(t('errorMessage'));
                }
            } finally{
                setLoading(false);
            }
        }

    });

    const modalFooter = () => {
        return (
            <div className={classNames.footerContainer}>
                {errorMessage && <span className={classNames.errorMessage}>{errorMessage}</span>}
                {loading && <Spinner size={SpinnerSize.medium}></Spinner>}
                <DefaultButton
                    onClick={props.onClose}
                    style={{marginRight: '10px'}}
                >
                    {t('common:cancel')}
                </DefaultButton>
                
                <PrimaryButton 
                    disabled={validationIcon !== "Completed"}
                    onClick={createLabel.execute}
                >
                    {t('common:create')}
                </PrimaryButton>
            </div>
        )
    }
    return (
        <>
            <EdiModal
                className={classNames.root}
                title={`${t('createLabel')}`}
                isOpen={props.isOpen}
                width={600}
                height={600}
                showCloseIcon={true}
                onCloseClick={props.onClose}
                body={setupParameter()}
                footer={modalFooter()}
                styles={
                    {
                        body: {
                            overflow: 'unset'
                        }
                    }
                }
            />
        </>
    );
}