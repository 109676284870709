import { classNamesFunction, DefaultButton, Icon, PrimaryButton, TextField } from "@fluentui/react";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IRenameWorkflowLabData, IRenameWorkflowLabModalProps, IRenameWorkflowLabModalPropsStyles, IRenameWorkflowLabModalStyles } from "./renameWorkflowLabModal.types";
import { getWorkflowsLab, useWorkflowList } from "../../features/workflowList";
import { useCurrentWorkflowLab } from "../../hooks/useCurrentWorkflowLab";
import { useWorkflowDispatch } from "../../workflowStore";
import { Constants } from "../../../models/constants";
import { Helpers } from "../../../utilities/helpers";
import { useAsyncApi } from "../../../utilities/hooks";
import { ErrorsType } from "../../../common/types/ErrorsType";
import { workflowApi } from "../../services/workflow.api";
import { workflowLabApi } from "../../services/workFlowLab/workFlowLab.api";
import EdiModal from "../../../common/components/ediModal/ediModal";

const getClassNames = classNamesFunction<IRenameWorkflowLabModalPropsStyles, IRenameWorkflowLabModalStyles>();

export const RenameWorkflowLabModalBase = (props: IRenameWorkflowLabModalProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const { isRenameWorkflowModalOpen } = useWorkflowList();
    const workflowLab = useCurrentWorkflowLab();
    const { t } = useTranslation(['renameWF', 'common']); 
    const dispatch = useWorkflowDispatch();
    const [validationIcon, setValidationIcon] = useState('Error');
    const [errorMessage, setErrorMessage] = useState(""); 
    const [data, setData] = useState<IRenameWorkflowLabData>({name: workflowLab?.name, isValid: false });   

    const validateArchiveName = useCallback(async (value: string) => {
        if (!value)
            return `${t('common:fieldRequired')}`;
    
        setValidationIcon('ProgressRingDots');
    
        try {
            const [isValid] = await Promise.all([workflowApi.isWorkflowLabNameAvailable(value), Helpers.delay(500)]);
            if(isValid)
            {
                setErrorMessage(""); 
                return "";
            }
            else 
            {
                setErrorMessage(t('common:nameNotAvailable'));
                return t('common:nameNotAvailable');
            }
        }
        catch {
            setErrorMessage (t('common:genericErrorCheckName'));
            return t('common:genericErrorCheckName');
        }
      }, [t]);
    
      const onNameValidationResult = useCallback((error: string | JSX.Element, value: string | undefined) => {
        setData({ ...data, isValid: !error, name: value ?? "" });
        setValidationIcon(error ? 'Error' : 'Completed');
      }, [data]);
    
      const {execute: renameArchive, loading, error: err} = useAsyncApi<void, void>({
        func: async () => {
          if (!data.isValid || !workflowLab?.id || !data.name)
            return;
          setErrorMessage(""); 
          await workflowLabApi.renameWorkflowLab(workflowLab.id, data.name)
          dispatch(getWorkflowsLab())
          props.onClose && props.onClose();
        }
      });
    
      useEffect(() => {
        if(data.name !== workflowLab?.name){
          setData({...data, name: workflowLab?.name});
          dispatch(getWorkflowsLab())
          props.onClose && props.onClose();
        }
      }, [workflowLab?.name]) //eslint-disable-line react-hooks/exhaustive-deps
    
      useEffect(() => {
        // Se il nome del WF Lab è lo stesso, non mostro il messaggio di errore perché il bottone di salvataggio è già disabilitato
        if(data.name === workflowLab?.name) {
            setErrorMessage(""); 
        }
      }, [data.name, errorMessage]) //eslint-disable-line react-hooks/exhaustive-deps

      useEffect( () => {
        if (!err) {
          setErrorMessage(""); 
          return;
        }
        const error: ErrorsType = (err as unknown) as ErrorsType;
        switch (error.code) {
          case 403: setErrorMessage(t("common:errorCode403")); break; 
          case 404: setErrorMessage(t("common:errorCode404")); break; 
          case 409: setErrorMessage(t("common:errorCode409")); break;  
          default: setErrorMessage(t("common:genericErrorApi")); 
        }
      }, [err]);  //eslint-disable-line react-hooks/exhaustive-deps
    
    
    const body = (
        <div className={classNames.innerContent}>
          <TextField
            label={t('common:name')}
            maxLength={Constants.MAX_LENGTH_WORKFLOWLAB_NAME}
            placeholder={t('createTemplateModal:setProperties.namePlaceholder')}
            deferredValidationTime={250}
            onGetErrorMessage={validateArchiveName}
            required
            defaultValue={data.name}
            autoFocus
            autoComplete={"off"}
            styles={classNames.subComponentStyles.textField}
            onNotifyValidationResult={onNameValidationResult}
            onChange={() => setData({ ...data, isValid: false })}
            onRenderSuffix={() => <Icon iconName={validationIcon} />}
          />     
          {errorMessage !== "" && <span className={classNames.errorMessage}>{errorMessage}</span>}
        </div>
      );
    
      const footer = (
        <>
        <div className={classNames.firstFooterContainer}>
          <DefaultButton
            style={{ margin: '0px 4px' }}
            text={t("common:abort")}
            onClick={props.onClose}
            disabled={loading}
          />
          <PrimaryButton
            style={{ margin: '0px 4px' }}
            text={t("common:save")}
            onClick={() => renameArchive()}
            disabled={!data.isValid || loading}
          />      
        </div>
        </>
      );
    
      return (
        <EdiModal
          isOpen={isRenameWorkflowModalOpen}
          showCloseIcon={true}
          onCloseClick={props.onClose}
          title={t("common:commands.renameWF")}
          body={body}
          footer={footer}
          width={400}
          height={255}
        />
      );
}