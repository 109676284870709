/*eslint-disable sonarjs/cognitive-complexity */
import { classNamesFunction, IconButton } from "@fluentui/react";
import React from "react";
import { IPickerWrapperCustomProps, IPickerWrapperCustomPropsStyles, IPickerWrapperCustomStyles } from "./pickerWrapper.types";

const getClassname = classNamesFunction<IPickerWrapperCustomPropsStyles, IPickerWrapperCustomStyles>();

export const PickerWrapperBase = (props: IPickerWrapperCustomProps) => {
    const classNames = getClassname(props.styles, { theme: props.theme, className: props.className });

    return (
        <div className={classNames.root}>
            {props.children}  
            <IconButton 
                styles={{root: {borderRadius: '15px'}}} 
                iconProps={{ iconName: "Cancel" }}
                onClick={() => props.onCancelClick ? props.onCancelClick() : undefined }
            />
        </div>
    );
}