import { DigitalSignatureCertificate } from "../../../models/digitalSignature"
import { FileSource } from "../../root/workflowOverview/createWorkflow/createWorkflow.types";

export type ApproveWorkFlowStep = {
    comments: string;
    approve: ApproveWorkFlowStepType;
    certificate?: DigitalSignatureCertificate;
    pin?: string;
    otp?: string;
    isUploadFile: boolean;
    fileSource?: FileSource;
    channelId?: string;
    teamId?: string;
    teamFileId?: string;
    ediFileId?: number;
}

export type CheckStepDocumentNameRequest = {
    workflowStepId: number;
    documentName: string;
}

export enum UploadFileEnum {
    setFileSourceStep,
    localUploadStep,
    teamsUploadStep,
    ediUploadStep,
}

export enum ApproveWorkFlowStepType {
    Approved = 0,
    Rejected = 1,
    StepBack = 2
}