import React, { useState } from "react";
import { classNamesFunction, DefaultButton, Dialog, DialogFooter, IconButton, PrimaryButton, Spinner, SpinnerSize } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { IDeleteTemplateModalProps, IDeleteTemplateModalPropsStyles, IDeleteTemplateModalStyles } from "./deleteTemplateModal.types";
import { templateApi } from "../../../services/template.api";

const getClassNames = classNamesFunction<IDeleteTemplateModalPropsStyles, IDeleteTemplateModalStyles>();

export const DeleteTemplateModalBase = (props: IDeleteTemplateModalProps) => {
    const classNames = getClassNames(props.styles,
        {
            theme: props.theme,
            className: props.className
        });

    const { t } = useTranslation(['deleteTemplateModal', 'common']);
    const [deleteTemplateModal, setDeleteTemplateModal] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>('');


    const deletetemplate = () => {
        setDeleting(true)
        templateApi.deleteTemplate(props.Id)
            .then(() => {
                if (props.onComplete) 
                    props.onComplete();
                    setDeleteTemplateModal(false)
                setDeleting(false);
            })
            .catch(() => setErrorMessage('something went wrong'))
            .finally(() => setDeleting(false));
    }
    
    return (
        <>
            <IconButton
                iconProps={{ iconName: 'Delete' }}
                title={`${t('common:delete')}`}
                onClick={(e) => {
                    e.stopPropagation();
                    setDeleteTemplateModal(true);
                }
                } />
            {deleteTemplateModal && <Dialog
                isOpen={deleteTemplateModal}
                dialogContentProps={{
                    title: t('common:deletetitle'),
                    showCloseButton: !deleting,
                    onDismiss: () => { setDeleteTemplateModal(false); },
                    subText: t('deleteTemplateModal:confirmMessage'),
                }}
            >
                <div className={classNames.deleteFooterContainer}>
                    <div className={classNames.deleteButtonContainer}>
                        {!errorMessage && deleting && <Spinner size={SpinnerSize.large}></Spinner>}
                        <div>
                            <DialogFooter
                            >
                                <DefaultButton disabled={deleting} onClick={() => setDeleteTemplateModal(false)} text={t('common:undoEliminate')} />
                                <PrimaryButton onClick={deletetemplate} styles={classNames.subComponentStyles.deletePrimaryButtonDisabled()} text={t('common:confirmEliminate')} disabled={deleting} />
                            </DialogFooter>
                        </div>
                    </div>
                </div>
            </Dialog>}
        </>
    );
}