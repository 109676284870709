import React, { useState } from "react";
import { classNamesFunction, DefaultButton, IconButton, PrimaryButton } from "@fluentui/react";
import { IAssociateCategoryModalStyles, IAssociateCategoryModalProps, IAssociateCategoryModalPropsStyles } from "./associateCategoryModal.types";
import { useTranslation } from "react-i18next";
import EdiModal from "../../../../common/components/ediModal/ediModal";
import { useOnMount } from "../../../../utilities/hooks";
import { categoryApi } from "../../../services/category/category.api";
import { AddLabelsToCategoryRequest } from "../../../services/category/category.contracts";
import LabelsSelector from "../../common/labelsSelector/labelsSelector";
import { CategoryLabelItem } from "../../../../models/categoryView";
const getClassNames = classNamesFunction<IAssociateCategoryModalPropsStyles, IAssociateCategoryModalStyles>();

export const AssociateCategoryModalBase = (props: IAssociateCategoryModalProps) => {
    const classNames = getClassNames(props.styles, //eslint-disable-line @typescript-eslint/no-unused-vars
        {
            theme: props.theme,
            className: props.className
        });
    const { t } = useTranslation(['workflowLabManageUsers', 'common']);
    const labels: CategoryLabelItem[] = [];
    const [associateCategoryModal, setAssociateCategoryModal] = useState(false);
    const [selectedLabels, setSelectedLabels] = useState(labels);
    const [isLoading, setIsLoading] = useState(false);

    useOnMount(() => {
        if (props.defaultLabels)
            setSelectedLabels(props.defaultLabels);
    });

    const associateCategorysModalBody = () => {
        return <div>
            <LabelsSelector
                workflowLabId={props.workFlowLabId}
                categoryId = {props.categoryId}
                readonly={false}
                visualizeMode={false}
                onChangeSelectedLabels={(labels) => setSelectedLabels(labels)}
                defaultLabels={selectedLabels}
            />
        </div>
    }


    const addLabels = () => {
        setIsLoading(true);
        const addLabelsRequest: AddLabelsToCategoryRequest = {
            workflowLabId: props.workFlowLabId,
            categoryId: props.categoryId,
            labels: selectedLabels,
        }

        categoryApi.addLabelsToCategory(addLabelsRequest)
            .then(() => {
                if (props.onComplete)
                    props.onComplete();
                setAssociateCategoryModal(false);
            })
            .finally(() => setIsLoading(false));
        setAssociateCategoryModal(false);
    }

    const associateCategorysModalFooter = () => {
        return <>
            <DefaultButton
                onClick={() => {setSelectedLabels(props.defaultLabels); setAssociateCategoryModal(false)}}
                style={{ marginRight: '10px' }}
            >
                {t('common:leave')}
            </DefaultButton>
            <PrimaryButton
                onClick={addLabels}
                disabled = {isLoading}
            >
                {t('common:confirm')}
            </PrimaryButton>
        </>
    }

    return (
        <>
            <IconButton
                iconProps={{ iconName: 'Add' }}
                title={t('editCategoryLabels')}
                onClick={(e) => {
                    e.stopPropagation();
                    setAssociateCategoryModal(true);
                }
                } />
            <EdiModal
                title={t("editCategoryLabels")}
                width={600}
                height={600}
                isOpen={associateCategoryModal}
                showCloseIcon={true}
                onCloseClick={() => {setSelectedLabels(props.defaultLabels); setAssociateCategoryModal(false)}}
                body={associateCategorysModalBody()}
                footer={associateCategorysModalFooter()}
            />
        </>
    );
}