import { styled } from "@fluentui/react";
import { getStyles } from "./CopyFileOnEdiModal.styles";
import { IEdiFilePropsStyles, IEdiFileStyles, IEdiFileProps } from "./CopyFileOnEdiModal.types";
import { CopyFileOnEdiModalBase } from "./CopyFileOnEdiModal.base";

const CopyFileOnEdiModal = styled<IEdiFileProps, IEdiFilePropsStyles, IEdiFileStyles>(
    CopyFileOnEdiModalBase,
    getStyles,
    undefined,
    { scope: 'CopyFileOnEdiModal' }
);

export default CopyFileOnEdiModal;