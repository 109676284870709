import React, { useState } from "react";
import { classNamesFunction, DefaultButton, Dialog, DialogFooter, PrimaryButton, Spinner, SpinnerSize } from "@fluentui/react";
import { IRemoveAssociationModalStyles, IRemoveAssociationModalProps, IRemoveAssociationModalPropsStyles } from "./removeAssociationModal.types";
import { useTranslation } from "react-i18next";
import { labelApi } from "../../../../services/label/label.api";

const getClassNames = classNamesFunction<IRemoveAssociationModalPropsStyles, IRemoveAssociationModalStyles>();

export const RemoveAssociationModalBase = (props: IRemoveAssociationModalProps) => {
    const classNames = getClassNames(props.styles,
        {
            theme: props.theme,
            className: props.className
        });

    const { t } = useTranslation(['removeAssociationModal', 'common']);
    const [deleting, setDeleting] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>('');


    const removeAssociation = () => {
        setDeleting(true)
        labelApi.disassociateLabel(props.IdLabel, props.category.id)
            .then(() => {
                props.onComplete();
                props.onClose();
            })
            .catch(() => setErrorMessage('Something went wrong'))
            .finally(() => setDeleting(false));
    }
    
    return (
        <>
            {props.isOpen && 
                <Dialog
                    isOpen={props.isOpen}
                    dialogContentProps={{
                        title: t('removeAssociationModal:removeAssociationLabel'),
                        showCloseButton: !deleting,
                        onDismiss:  props.onClose,
                        subText: t('removeAssociationModal:confirmMessage'),
                    }}
                >
                    <div className={classNames.deleteFooterContainer}>
                        <div className={classNames.deleteButtonContainer}>
                            {!errorMessage && deleting && <Spinner size={SpinnerSize.large}/>}
                            <div>
                                <DialogFooter>
                                    <DefaultButton disabled={deleting} onClick={props.onClose} text={t('common:undoEliminate')} />
                                    <PrimaryButton disabled={deleting} onClick={removeAssociation} text={t('common:confirmEliminate')} />
                                </DialogFooter>
                            </div>
                        </div>
                    </div>
                </Dialog>
            }
        </>
    );
}