/*eslint-disable sonarjs/cognitive-complexity */
/*eslint-disable sonarjs/no-duplicate-string */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { classNamesFunction, DefaultButton, Dialog, Label, PrimaryButton } from "@fluentui/react";
import { ICreateWorkflowPropsStyles, ICreateWorkflowStyles, ICreateWorkflowProps, CreateWorkflowEnum, FirstPageData, FileSource, SecondPageData, AltSecondPageData } from "./createWorkflow.types";
import MultiStepModal from "../../../../common/components/multiStepModal/multiStepModal";
import { IModalStepData } from "../../../../common/components/multiStepModal/multiStepModal.types";
import SetProperties from "./setProperties/setProperties";
import { useWorkflowState } from "../../../workflowStore";
import ChoiceInputSource from "./choiceInputSource/choiceInputSource";
import AddSteps from "./addSteps/addSteps";
import { ThirdPageData } from "./addSteps/addSteps.base";
import { WorkFlowStepTypes, WorkFlowTypes } from "../../../../models/WorkFlow";
import LocalFile from "./localFile/localFile";
import TeamsFile from "./teamsFile/teamsFile";
import { CreatingWorkFlowPage } from "./creatingWorkFlowPage";
import { useConst } from '@fluentui/react-hooks';
import { workflowStepToShort } from "../../../services/workflow.contracts";
import { useTranslation } from "react-i18next";
import EdiFile from "./ediFile/ediFile";
import { DateTime } from "luxon";
import { useAsyncApi, useOnMount } from "../../../../utilities/hooks";
import { templateApi } from "../../../services/template.api";
import { LoadingTemplatePage } from "../../../../common/components/loadingPage/loadingPage";
import { WorkFlowTemplate } from "../../../../models/workflowTemplate";
import { mapper } from "../../../../utilities/mapper";
import { FilePreset } from "./choiceInputSource/choiceInputSource.types";
import AddDisjointment from "./addDisjointment/addDisjointment";
import WorkflowSummary from "../../common/workflowSummary/workflowSummary";
import { PreviewSource } from "../../../../common/components/filePreview/filePreviewSource";


const getClassNames = classNamesFunction<ICreateWorkflowPropsStyles, ICreateWorkflowStyles>();

export const CreateWorkflowBase = (props: ICreateWorkflowProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const today = useConst(new Date(Date.now()));
    const selectedWorkflowLab = useWorkflowState().workflowList.selectedWorkflowLab;
    const { t } = useTranslation(['createWorkflow', 'common']);

    const tommorrow = () => {
        const result = new Date(today);
        result.setDate(today.getDate() + 1);
        return result;
    };

    const [teamsPageData, setTeamsPageData] = useState<SecondPageData>({ isPdf: false, file: undefined });
    const [ediPageData, setEdiPageData] = useState<SecondPageData>({ isPdf: false, file: undefined });
    const [disjointmentPageData, setDisjoinmentPageData] = useState<AltSecondPageData>({disjointments:[]})
    const [file, setFile] = useState<File[]>([]);
    const [fileValid, setFileValid] = useState(false);
    const [fileEditable, setFileEditable] = useState(false);
    const [firstStepData, setFirstStepData] = useState<FirstPageData>({
        isValid: false,
        name: '',
        description: '',
        disjointed: false,
        isFileStepAttachable: false,
    });
    const [presetFile, setPresetFile] = useState<FilePreset>();
    const [stepsData, setStepsData] = useState<ThirdPageData>({
        digitalSignEnabled: false,
        steps: []
    });
    const [activeStep, setActiveStep] = useState<CreateWorkflowEnum>(CreateWorkflowEnum.setProprietiesStep);
    const [fileSource, setFileSource] = useState<FileSource>(FileSource.local);
    const [dialogCloseModal, setDialogCloseModal] = useState(false);
    const [workflowTemplate, setWorkflowTemplate] = useState<WorkFlowTemplate>();
    const [isSourceFile, setIsSourceFile] = useState<boolean>(false);

    const { loading: loadingTemplates, execute: getAvaiableTemplates, value: availableTemplates } = useAsyncApi<void, WorkFlowTemplate[]>({
        func: async (): Promise<WorkFlowTemplate[]> => { 
            return await templateApi.getTemplates({ workflowLabId: selectedWorkflowLab });   
        }
    });
    
    useOnMount(() => {
        getAvaiableTemplates();
    });

    const resetLoadTemplate = () => {
        setPresetFile(undefined);
        setFileSource(FileSource.local);
        setIsSourceFile(false);
        setFileEditable(false);
        setStepsData({
            steps: [],
            digitalSignEnabled: false,
        });
        setFirstStepData({...firstStepData, templateSelected: undefined, disjointed: false});
        setWorkflowTemplate(undefined);
    }

    const resetDocumentFile = () => {
        setPresetFile(undefined);
        setFileSource(FileSource.local);
        setIsSourceFile(false);
        setFile([]);
        setFileEditable(false);
        setTeamsPageData({ isPdf: false, file: undefined });
        setEdiPageData({ isPdf: false, file: undefined });
    }

    // Method for manage next action for modal
    const next = useCallback((fileSource?: FileSource) => {
        switch (activeStep) {
            case CreateWorkflowEnum.setProprietiesStep:
                if(firstStepData.templateSelected === undefined){
                    if (firstStepData.disjointed)
                        return setActiveStep(CreateWorkflowEnum.addDisjointmentLabelsStep)
                    return setActiveStep(CreateWorkflowEnum.setFileSourceStep);}

                else return setActiveStep(CreateWorkflowEnum.loadTemplate);

            case CreateWorkflowEnum.loadTemplate:
                if(workflowTemplate?.disjointed)
                    return setActiveStep(CreateWorkflowEnum.addDisjointmentLabelsStep);
                else
                    return setActiveStep(CreateWorkflowEnum.setFileSourceStep);

            case CreateWorkflowEnum.addDisjointmentLabelsStep:
                return setActiveStep(CreateWorkflowEnum.setFileSourceStep);
            
            case CreateWorkflowEnum.setFileSourceStep:
                if (fileSource === undefined && presetFile) {
                    return setActiveStep(CreateWorkflowEnum.addStepsStep);
                }
                if (fileSource !== undefined) {
                    if (fileSource === FileSource.local) {
                        return setActiveStep(CreateWorkflowEnum.localUploadStep);
                    }
                    if (fileSource === FileSource.edi) {
                        return setActiveStep(CreateWorkflowEnum.ediUploadStep);
                    }
                    if (fileSource === FileSource.teams) {
                        return setActiveStep(CreateWorkflowEnum.teamsUploadStep);
                    }
                    if (fileSource === FileSource.sharepoint) {
                        return setActiveStep(CreateWorkflowEnum.addStepsStep);
                    }
                }
                return;

            case CreateWorkflowEnum.localUploadStep:{
                if(file[0]?.name !== undefined){
                    setPresetFile({fileName: file[0]?.name});
                    setIsSourceFile(false);
                }
                return setActiveStep(CreateWorkflowEnum.addStepsStep);
            }
            case CreateWorkflowEnum.ediUploadStep:{
                if(ediPageData.file?.name !== undefined){
                    setPresetFile({fileName: ediPageData.file?.name});
                    setIsSourceFile(false);
                }
                return setActiveStep(CreateWorkflowEnum.addStepsStep);
            }
            case CreateWorkflowEnum.teamsUploadStep:{
                if(teamsPageData.file?.name !== undefined && teamsPageData.file?.url !== undefined){
                    setPresetFile({fileName: teamsPageData.file?.name});
                    setIsSourceFile(false);
                }
                return setActiveStep(CreateWorkflowEnum.addStepsStep);
            }

            case CreateWorkflowEnum.addStepsStep:
                return setActiveStep(CreateWorkflowEnum.overviewStep);

            case CreateWorkflowEnum.overviewStep:
                return setActiveStep(CreateWorkflowEnum.creatingStep)

            default: return () => undefined;
        }
    }, [presetFile, activeStep, firstStepData.templateSelected, firstStepData.disjointed, file, ediPageData, teamsPageData, workflowTemplate?.disjointed])

    useEffect(() => {
        if(!workflowTemplate)
            return;

        if(activeStep === CreateWorkflowEnum.loadTemplate){
            if(workflowTemplate.documentName) {
                setPresetFile({
                    fileName: workflowTemplate.documentName ?? ''
                });
                setFileSource(FileSource.sharepoint);
                setIsSourceFile(true);
                setFileEditable(workflowTemplate.isDocumentEditable ?? false);
            }
            else {
                setPresetFile(undefined);
                setIsSourceFile(false);
            }


            setStepsData({
                steps: workflowTemplate.steps.map(s => mapper.mapWorkflowTemplateStepToWorkflowStep(s)),
                digitalSignEnabled: false,
            });
            setFirstStepData({
                ...firstStepData,
                disjointed: workflowTemplate.disjointed
            });
            setDisjoinmentPageData({ disjointments: mapper.mapWorkflowTemplateLabelToWorkFlowDisjointment(workflowTemplate.labelsCategory) })
            next(FileSource.sharepoint);
        }
    }, [next, workflowTemplate, activeStep, firstStepData])

    // Method for manage previous action for modal
    const previous = useCallback(() => {
        switch (activeStep) {
            case CreateWorkflowEnum.setProprietiesStep:
                if (props.onCloseClick) {
                    props.onCloseClick();
                }
                //TODO Clean redux state
                return;

            case CreateWorkflowEnum.loadTemplate:
                return setActiveStep(CreateWorkflowEnum.setProprietiesStep);

            case CreateWorkflowEnum.addDisjointmentLabelsStep:
                if(firstStepData.templateSelected !== undefined)
                    resetLoadTemplate();
                return setActiveStep(CreateWorkflowEnum.setProprietiesStep);

            case CreateWorkflowEnum.setFileSourceStep:{
                if(firstStepData.templateSelected !== undefined)
                    resetLoadTemplate();
                if(firstStepData.disjointed)
                    return setActiveStep(CreateWorkflowEnum.addDisjointmentLabelsStep)
                return setActiveStep(CreateWorkflowEnum.setProprietiesStep)
            }

            case CreateWorkflowEnum.localUploadStep:{
                if(file[0]?.name !== undefined && fileValid){
                    setPresetFile({fileName: file[0]?.name});
                    setIsSourceFile(false);
                }
                else
                    resetDocumentFile();
                return setActiveStep(CreateWorkflowEnum.setFileSourceStep);
            }
            case CreateWorkflowEnum.ediUploadStep:{
                if(ediPageData.file?.name !== undefined){
                    setPresetFile({fileName: ediPageData.file?.name});
                    setIsSourceFile(false);
                }
                else
                    resetDocumentFile();
                return setActiveStep(CreateWorkflowEnum.setFileSourceStep);
            }
            case CreateWorkflowEnum.teamsUploadStep:{
                if(teamsPageData.file?.name !== undefined && teamsPageData.file?.url !== undefined){
                    setPresetFile({fileName: teamsPageData.file?.name});
                    setIsSourceFile(false);
                }
                else
                    resetDocumentFile();
                return setActiveStep(CreateWorkflowEnum.setFileSourceStep);
            }

            case CreateWorkflowEnum.addStepsStep:
                return setActiveStep(CreateWorkflowEnum.setFileSourceStep);

            case CreateWorkflowEnum.overviewStep:
                return setActiveStep(CreateWorkflowEnum.addStepsStep);
            default: return () => undefined;
        }
    }, [activeStep, props, file, ediPageData, teamsPageData, firstStepData]) //eslint-disable-line react-hooks/exhaustive-deps

    const checkStepValidation = useCallback((): boolean => {
        let result = false;

        stepsData.steps.forEach(step => 
            result = result ||
            step.name === "" ||
            (step.approvers.length === 0 && firstStepData.disjointed === false) ||
            ((step.usersAndLabels?.length === 0 || step.usersAndLabels === undefined) && firstStepData.disjointed) ||
            (step.type === WorkFlowStepTypes.OnlyOneSignedApprover && firstStepData.disjointed === false && step.approvers.length > 1) ||
            (step.type === WorkFlowStepTypes.OnlyOneSignedApprover && firstStepData.disjointed === true && (step.usersAndLabels  === undefined || step.usersAndLabels.length > 1)));

        if (!stepsData.digitalSignEnabled) {
            stepsData.steps.forEach(step => {
                result = result || step.type === WorkFlowStepTypes.OnlyOneSignedApprover;
            })
        }
        
        return result;
    }, [stepsData, firstStepData.disjointed]);

    const footer = useMemo(() => {
        return (
            <div className={classNames.requiredContainer} >
                {
                activeStep !== CreateWorkflowEnum.setFileSourceStep && 
                activeStep !== CreateWorkflowEnum.overviewStep &&
                activeStep !== CreateWorkflowEnum.localUploadStep &&
                activeStep !== CreateWorkflowEnum.ediUploadStep &&
                activeStep !== CreateWorkflowEnum.teamsUploadStep &&
                activeStep !== CreateWorkflowEnum.addDisjointmentLabelsStep ?
                <Label className={classNames.requiredSign}>*{t("common:fieldRequired")}</Label>:
                <div></div>
            }
            <div className={classNames.buttonsContainer} >
                <DefaultButton
                    disabled={false}
                    text={t("common:previousButton")}
                    onClick={() => previous()}
                />
                <PrimaryButton
                    text={t("common:nextButton")}
                    disabled={(activeStep === CreateWorkflowEnum.setProprietiesStep && !firstStepData.isValid) ||
                        (activeStep === CreateWorkflowEnum.addDisjointmentLabelsStep && (disjointmentPageData.disjointments.find(e=>e.labels.length===0)!==undefined || disjointmentPageData.disjointments.length===0)) ||
                        (activeStep === CreateWorkflowEnum.setFileSourceStep && presetFile === undefined) ||
                        (activeStep === CreateWorkflowEnum.teamsUploadStep && teamsPageData.file?.name === undefined) ||
                        (activeStep === CreateWorkflowEnum.addStepsStep && checkStepValidation()) ||
                        (activeStep === CreateWorkflowEnum.localUploadStep && !fileValid) ||
                        (activeStep === CreateWorkflowEnum.ediUploadStep && ediPageData.file === undefined)}
                    onClick={(e) => {
                        e.stopPropagation()
                        next();
                    }}
                />
            </div>
            </div>
        );
    }, [classNames.buttonsContainer, activeStep, next, previous, checkStepValidation, firstStepData.isValid, disjointmentPageData.disjointments, file, teamsPageData.file, ediPageData]); //eslint-disable-line react-hooks/exhaustive-deps

    const fileSelectedIsPdf = useMemo(() => {
        switch (fileSource) {
            case FileSource.local:
                if (file && file.length > 0) {
                    return file[0].type === "application/pdf"
                }
                return false;

            case FileSource.edi:
                return ediPageData.isPdf;

            case FileSource.teams:
                return teamsPageData.isPdf;

            case FileSource.sharepoint:
                return workflowTemplate?.documentName?.search(".pdf") !== -1;

            default:
                return false
        }
    }, [fileSource, file, ediPageData.isPdf, teamsPageData.isPdf, workflowTemplate]);

    useEffect(() => {
        setStepsData({
            ...stepsData,
            digitalSignEnabled: fileSelectedIsPdf
        });
    }, [fileSelectedIsPdf]);  //eslint-disable-line react-hooks/exhaustive-deps

    const getMultiStepModal: IModalStepData[] = [
        {
            body: <SetProperties 
                    translationName="createWorkflow" 
                    initialState={firstStepData} 
                    onWorkflowDataChange={setFirstStepData}
                    template={availableTemplates}
                    loadingTemplates={loadingTemplates}
                  />,
            footer: footer
        },        
        {
            body: <LoadingTemplatePage
                    action={templateApi.getTemplate}
                    label={t('common:loadingTemplate')}
                    request={firstStepData.templateSelected}
                    onSuccess={(data: WorkFlowTemplate) => {
                        setWorkflowTemplate(data);                        
                    }}
                    onError={() => previous()}
                  />,
        },
        {
            body: <AddDisjointment
                    initialState={disjointmentPageData}
                    onWorkflowDataChange={setDisjoinmentPageData}
                  />,
            footer: footer
        },
        {
            body: <ChoiceInputSource onElementChoice={(value) => { next(value); setFileSource(value); }} filePresetData={presetFile} templateId={workflowTemplate?.id} setFilePresetData={setPresetFile} isSourceFile={isSourceFile} />,
            footer: footer
        },
        {
            body: <LocalFile onFileUploadMetadata={setFile} onValidateFile={setFileValid} onFileEdit={setFileEditable} />,
            footer: footer
        },
        {
            body: <TeamsFile initialState={teamsPageData} onWorkflowDataChange={setTeamsPageData} onFileEdit={setFileEditable} />,
            footer: footer
        },
        {
            body: <EdiFile 
                    initialState={ediPageData} 
                    onWorkflowDataChange={setEdiPageData} 
                    onFileEdit={setFileEditable} 
                  />,
            footer: footer
        },
        {
            body: <AddSteps 
                    fileType={fileSelectedIsPdf} 
                    onWorkflowDataChange={setStepsData} 
                    initialState={stepsData}
                    disjointed={firstStepData.disjointed}
                    maxSteps={15}
                  />,
            footer: footer
        },
        {
            body: <WorkflowSummary
                translationName="workflowDetails"                
                isTemplateSummary={false}
                isDocumentClickable={false}
                previewSource={PreviewSource.Workflow}
                onReload={() => console.log("")}
                workFlow={
                    {
                        id: 0,
                        name: firstStepData.name,
                        description: firstStepData.description,
                        type: WorkFlowTypes.Approval,
                        documentName: presetFile?.fileName,
                        steps: stepsData.steps.slice(),
                        involvedPeople: [],
                        isDocumentEditable: fileEditable,
                        isDocumentAttachable: firstStepData.isFileStepAttachable
                    }
                }
                workFlowDisjointment={disjointmentPageData.disjointments}                
            />,
            footer: footer
        },
        {
            body: <CreatingWorkFlowPage
                    translationName="createWorkflow"
                    file={file}
                    onClose={props.onCloseClick}
                    onSuccess={props.onSuccess}
                    disjointed={firstStepData.disjointed}                                    
                    request={{
                        description: firstStepData.description ?? '',
                        expirationDate: DateTime.fromJSDate(firstStepData.expireDate ?? tommorrow()).toISO(),
                        fileSource: fileSource,
                        name: firstStepData.name,
                        workFlowLabId: selectedWorkflowLab,
                        steps: stepsData.steps.map(s => workflowStepToShort(s)),
                        teamId: teamsPageData.file?.teamId,
                        teamFileId: teamsPageData.file?.uniqueId,
                        ediFileId: ediPageData.file?.id,
                        isDocumentEditable: fileEditable,
                        isDocumentAttachable: firstStepData.isFileStepAttachable,
                        templateId: workflowTemplate?.id,
                        LabelCategoryIds: disjointmentPageData.disjointments.map(d => d.labels.map(l => l.labelCategoryId ? l.labelCategoryId : 0)).flat(),
                }} />
        }        
    ];

    return (
        <>
            <MultiStepModal
                activeStep={activeStep}
                steps={getMultiStepModal}
                isOpen={true}
                width={650}
                height={750}
                showCloseIcon={activeStep !== CreateWorkflowEnum.creatingStep }
                onCloseClick={() => setDialogCloseModal(true)}
                title={t('title')}
                subtitle={t('subtitle')}
            />
            <Dialog
                hidden={!dialogCloseModal}
                dialogContentProps={{
                    title: t('checkDeleteWorkflow.title'),
                    subText: t('checkDeleteWorkflow.message'),
                }}
            >
                <div className={classNames.buttonsContainer} >
                    <DefaultButton
                        text={t("common:dialogButtonCancel")}
                        onClick={() => setDialogCloseModal(false)}
                    />
                    <PrimaryButton
                        text={t("common:dialogButtonConfirmClose")}
                        onClick={() => { if(props.onCloseClick) props.onCloseClick()}}
                    />
                </div>
            </Dialog>                       
        </>
    );
}