import { IStyleFunction } from "@fluentui/react"
import { ICreateTemplateModalPropsStyles, ICreateTemplateModalStyles } from "./createTemplateModal.types";

export const CreateTemplateModalGlobalClassNames = {
    root: 'ewe-createtemplatemodal-root',
    buttonsContainer: 'ewe-createtemplatemodal-buttonsContainer',
    spinnerContainer: 'ewe-createtemplatemodal-spinnerContainer',
    requiredContainer: 'ewe-createtemplatemodal-requiredContainer',
    requiredSign: 'ewe-createtemplatemodal-requiredSign'
}

export const getStyles: IStyleFunction<ICreateTemplateModalPropsStyles, ICreateTemplateModalStyles> = (props: ICreateTemplateModalPropsStyles): ICreateTemplateModalStyles => {
    const { palette } = props.theme!; // eslint-disable-line @typescript-eslint/no-non-null-assertion
    return {
        root: [CreateTemplateModalGlobalClassNames.root, {
                
        }],
        requiredSign:[CreateTemplateModalGlobalClassNames.requiredSign,{
            color:palette.redDark,
        }],
        requiredContainer:[CreateTemplateModalGlobalClassNames.requiredContainer,{
            display:'flex',
            flexDirection:'row',
            gap: 5,
            justifyContent: 'space-between',
            alignItems:'center',
            color:palette.redDark,
        }],
        buttonsContainer:[CreateTemplateModalGlobalClassNames.buttonsContainer,{
            display:'flex',
            flexDirection:'row',
            gap: 5,
            justifyContent: 'flex-end',
            alignItems:'center'
        }],
        spinnerContainer: [CreateTemplateModalGlobalClassNames.spinnerContainer,{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        }],
    }
};