/*eslint-disable sonarjs/cognitive-complexity */
import React, { useState } from "react";
import { classNamesFunction, Icon, keyframes } from "@fluentui/react";
import { IInformationPropsStyles, IInformationStyles, IInformationProps } from "./information.types";
import { useEffect } from "react";
import { showPanelAction, useCallNotification } from "../../../workflow/features/callNotification";
import { useWorkflowDispatch } from "../../../workflow/workflowStore";

const getClassNames = classNamesFunction<IInformationPropsStyles, IInformationStyles>();

export const InformationBase = (props: IInformationProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const dispatch = useWorkflowDispatch();
    const { notificationsQueue, status } = useCallNotification();
    const [globalLoading, setGlobalLoading] = useState(false);
    const [globalSuccessResponseOperation, setGlobalSuccessResponseOperation] = useState<boolean | undefined>(undefined);

    useEffect(() => {
        setGlobalSuccessResponseOperation(undefined);
        let singleLoading = false;
        notificationsQueue.forEach(item => {
            singleLoading = singleLoading || status[item].progress;
        });
        setGlobalLoading(singleLoading);
    }, [notificationsQueue, status]);

    const responseIcon = () => (
        <span className={classNames.responseContainer}>
            {globalSuccessResponseOperation === true && <Icon className={classNames.successIcon} onClick={() => dispatch(showPanelAction(true))} iconName="CompletedSolid"></Icon>}
            {globalSuccessResponseOperation === false && <Icon className={classNames.failureIcon} onClick={() => dispatch(showPanelAction(true))} iconName="StatusErrorFull"></Icon>}
            {globalSuccessResponseOperation === undefined && <></>}
        </span>
    )

    useEffect(() => {
        if (!globalLoading && notificationsQueue.length > 0) {
            const lastKey = notificationsQueue[notificationsQueue.length - 1];
            const lastElement = status[lastKey];
            if (!lastElement.success) {
                setGlobalSuccessResponseOperation(false);
            }
            else
            {
                setGlobalSuccessResponseOperation(true);
            }
        }
        else
        {
            setGlobalSuccessResponseOperation(undefined);
        }
    }, [globalLoading, notificationsQueue, status]);


    return (
        <div id="panelIcon" className={classNames.iconWrapper}>
            <div style={{ position: 'relative' }}>
                {globalLoading ? <div onClick={() => dispatch(showPanelAction(true))}>
                    <Icon iconName="Sync" style={{ animation: `${keyframes({ to: { transform: 'rotate(360deg)' } })} 1s linear infinite` }} /></div>
                    : <Icon onClick={() => dispatch(showPanelAction(true))} iconName="Info" />}
                {!globalLoading && responseIcon()}
            </div>
        </div>
    );
}