import React, { useState } from "react";
import { classNamesFunction, Dropdown, DropdownMenuItemType, Icon, IDropdownOption, SearchBox, TooltipHost } from "@fluentui/react";
import { ICustomWidgetSelectPropsStyles, ICustomWidgetSelectStyles, ICustomWidgetSelectProps } from "./customWidgetSelect.types";
import { useTranslation } from "react-i18next";

const getClassNames = classNamesFunction<ICustomWidgetSelectPropsStyles, ICustomWidgetSelectStyles>();
/*eslint-disable sonarjs/cognitive-complexity */
/*eslint-disable @typescript-eslint/no-explicit-any*/
/*eslint-disable no-mixed-operators*/
export const CustomWidgetSelectBase = (customProps: ICustomWidgetSelectProps) => {
    const { palette } = customProps.theme!; //eslint-disable-line @typescript-eslint/no-non-null-assertion
    const classNames = getClassNames(customProps.styles, { theme: customProps.theme, className: customProps.className });
    const [searchText, setSearchText] = useState("")
    const [key, setKey] = useState(0)
    const { t } = useTranslation(['tagPanel', 'common']);

    const renderOption = (option: IDropdownOption): JSX.Element => {
        return (option.itemType === DropdownMenuItemType.Header && option.key === "FilterHeader") ?
            <SearchBox
                defaultValue={searchText}
                onChange={(ev, newValue) => setSearchText(newValue || "")}
                underlined={true}
                styles={{
                    iconContainer: {
                        display: 'none'
                    }
                }}
                placeholder={t('metadata.placeHolderSearch')} /> :
            (option.itemType === DropdownMenuItemType.Header && option.key === "SeeMore") ?
                <div>{option.text}</div> :
                (option.itemType === DropdownMenuItemType.Header && option.key === "MoreChar" && searchText.length < 3) ?
                    <div>{option.text}</div> :
                    <>{option.text}</>;
    }

    const sortCompare = (a: IDropdownOption, b: IDropdownOption) => {
        const textA = a.text.toLowerCase(),
            textB = b.text.toLowerCase();
        if (textA < textB) {
            return -1;
        }
        if (textA > textB) {
            return 1;
        }
        return 0;
    }

    const customSearchableSelect = (options: IDropdownOption[], selectProps: any) => {
        const selectedItems: IDropdownOption[] = [];
        let filteredOptions: IDropdownOption[] = [];
        let filtered: IDropdownOption[] =
            searchText.length < 2 ?
                [{ key: 'MoreChar', text: t('metadata.moreChar'), itemType: DropdownMenuItemType.Header }] : [];

        if (selectProps.schema.uniqueItems) {
            selectProps.value && selectProps.value.sort().forEach((v: any) => {
                selectedItems.push({
                    key: v,
                    text: selectProps.options.enumOptions.find((option: any) => option.value.toLowerCase() === v.toLowerCase())?.label || v
                });
                selectedItems.sort((a, b) => sortCompare(a, b))
            });
            if (searchText.length >= 2)
            {
                options.forEach(op => searchText !== "" &&
                    op.text.toString().toLowerCase().includes(searchText.toLowerCase()) &&
                    (selectProps.value ? !selectProps.value.includes(op.key) : true) &&
                    filtered.push({
                        key: op.key,
                        text: op.text,
                        disabled: op.disabled
                    }));
                
                    filtered = filtered.sort((a, b) => sortCompare(a, b));
            }

            if (searchText === "" && (!selectProps.value || selectProps.value.length === 0)) {
                filteredOptions = options.slice(0, 20);
                //filteredOptions.sort((a, b) => sortCompare(a, b));
            }
            else if (searchText === "") {
                options.forEach(op => !selectProps.value.includes(op.key) &&
                    filtered.push({
                        key: op.key,
                        text: op.text,
                        disabled: op.disabled
                    }));
                filteredOptions = selectedItems.concat(filtered.slice(0, 20));
            }
            else
                filteredOptions = searchText.length < 2 ? filtered.concat(selectedItems) : selectedItems.concat(filtered);
        }
        else {
            (selectProps.value && selectProps.value !== "") && selectedItems.push({
                key: selectProps.value,
                text: selectProps.options.enumOptions.find((option: any) => option.value.toLowerCase() === selectProps.value.toLowerCase())?.label || selectProps.value
            })

            if (searchText.length >= 2) {
                options.forEach(op => searchText !== "" &&
                    op.text.toString().toLowerCase().includes(searchText.toLowerCase()) &&
                    selectProps.value !== op.key &&
                    filtered.push({
                        key: op.key,
                        text: op.text,
                        disabled: op.disabled
                    }));

                    filtered = filtered.sort((a, b) => sortCompare(a, b))
            }

            if (searchText === "" && (!selectProps.value || selectProps.value.length === 0)) {
                filteredOptions = options.slice(0, 20);
                //filteredOptions.sort((a, b) => sortCompare(a, b));
            }
            else if (searchText === "") {
                options.forEach(op => selectProps.value !== op.key &&
                    filtered.push({
                        key: op.key,
                        text: op.text,
                        disabled: op.disabled
                    }));
                filteredOptions = selectedItems.concat(filtered.slice(0, 20));
            }
            else
                filteredOptions = searchText.length < 2 ? filtered.concat(selectedItems) : selectedItems.concat(filtered);
        }
        return <Dropdown
            options={searchText === "" ? [
                { key: 'FilterHeader', text: '-', itemType: DropdownMenuItemType.Header },
                ...filteredOptions,
                { key: 'SeeMore', text: options.length - filteredOptions.length > 0 ? t('metadata.other') + (options.length - filteredOptions.length) + t('metadata.items') : t('metadata.noItems'), itemType: DropdownMenuItemType.Header },
            ] : [
                { key: 'FilterHeader', text: '-', itemType: DropdownMenuItemType.Header },
                ...filteredOptions
            ]}
            styles={{
                dropdown: {
                    pointerEvents: selectProps.disabled ? 'none' : 'auto'
                },
                callout:{
                    ".ms-Callout-main":{
                        maxHeight: "310px !important"
                    }
                }, 
                root: {
                    paddingTop: 5
                },
                title: customProps.disabled ? {
                    backgroundColor: palette.neutralLight,
                    color: palette.neutralDark + " !important",
                    pointerEvents: 'none'
                } : {}
            }}
            disabled={selectProps.disabled}
            // directionalHintFixed: true
            calloutProps={{ shouldRestoreFocus: false, setInitialFocus: false }}
            onRenderOption={props => props ? renderOption(props) : null}
            multiSelect={selectProps.schema.uniqueItems}
            onChange={(ev, op) => {

                if (selectProps.schema.uniqueItems) {
                    const valuesList = selectProps.value ? [...selectProps.value] : [];
                    const exists = valuesList.findIndex((el: any) => el === op?.key);

                    if (exists > -1)
                        valuesList.splice(exists, 1)
                    else
                        valuesList.push(op?.key);
                    selectProps.onChange(valuesList)
                }
                else
                    selectProps.onChange(op?.key)
               setKey(key + 1)
            }}
            defaultSelectedKeys={selectProps.value || selectProps.schema.default}
            defaultSelectedKey={selectProps.value || selectProps.schema.default}
            onDismiss={() => setSearchText('')}
        />
    }


    const customSelect = () => {
        try{
            const list: IDropdownOption[] = [];
            customProps.selectProps.options.enumOptions.map((element: any) => list.push({
                key: element.value,
                text: element.label,
                disabled: customProps.selectProps.options?.enumDisabled && customProps.selectProps.options.enumDisabled.includes(element.label)
            }));
            if (customProps.selectProps.schema.items && customProps.selectProps.schema.items.enum && !customProps.selectProps.schema.items.enum.includes(customProps.selectProps.value[0])) {
                customProps.selectProps.onChange([])
            }
            //list.sort((a, b) => sortCompare(a, b));
            return (<div>
                <label className={((customProps.selectProps.rawErrors && customProps.selectProps.rawErrors.length > 0)
                    && (!customProps.selectProps.value || customProps.selectProps.value.length === 0) && !customProps.disabled) ||
                    customProps.selectProps.schema.enum && customProps.selectProps.value && !customProps.selectProps.schema.enum.includes(customProps.selectProps.value) ||
                    (customProps.selectProps.schema.items && customProps.selectProps.schema.items.enum && customProps.selectProps.value.length > 0 && !customProps.selectProps.schema.items.enum.includes(customProps.selectProps.value[0]))
                    ?
                    classNames.errorLabel :
                    classNames.label}>{customProps.selectProps.label}
                    {customProps.selectProps.required && <span className={classNames.required}>*</span>}
                    {customProps.selectProps.options.description && <TooltipHost styles={{root:{marginLeft:5}}} content={customProps.selectProps.options.description}><Icon
                        styles={{ root: { fontSize: 13, cursor: 'pointer' } }}
                        iconName="Info" />
                    </TooltipHost>}
                </label>
                {
                    customProps.selectProps.options.enumOptions.length < 20 ?
                        <Dropdown multiSelect={customProps.selectProps.schema.uniqueItems}
                            defaultSelectedKeys={customProps.selectProps.value || customProps.selectProps.schema.default}
                            defaultSelectedKey={customProps.selectProps.value || customProps.selectProps.schema.default}
                            disabled={customProps.selectProps.disabled}
                            styles={{
                                dropdown: {
                                    pointerEvents: customProps.selectProps.disabled ? 'none' : 'auto'
                                },
                                callout:{
                                    ".ms-Callout-main":{
                                        maxHeight: "310px !important"
                                    }
                                }, 
                                root: {
                                    paddingTop: 5,
                                },
                                title: customProps.disabled ? {
                                    backgroundColor: palette.neutralLight,
                                    color: palette.neutralDark + " !important",
                                    pointerEvents: 'none'
                                } : {}
                            }}
                            onChange={(ev, op) => {
                                if (customProps.selectProps.schema.uniqueItems) {
                                    const valuesList = [...customProps.selectProps.value] || [];
                                    const exists = valuesList.findIndex((el: any) => el === op?.key);
                                    if (exists > -1)
                                        valuesList.splice(exists, 1)
                                    else
                                        valuesList.push(op?.key);
                                        customProps.selectProps.onChange(valuesList)
                                }
                                else
                                customProps.selectProps.onChange(op?.key)
                            }} options={list} /> :
                        customSearchableSelect(list, customProps.selectProps)}
            </div>)
        }
        catch(ex:any){
            console.log("ERROR: ",ex.message)
            customProps.hasError();
            return <div></div>
        }
      

    }
    return (
        customSelect()
    );
}