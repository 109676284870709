import { IStyleFunction } from "@fluentui/react"
import { IRemoveMemberButtonPropsStyles, IRemoveMemberButtonStyles } from "./removeMemberButton.types";

export const RemoveMemberButtonClassNames = {
    root: 'edi-remove-member-button-root'
}

export const getStyles: IStyleFunction<IRemoveMemberButtonPropsStyles, IRemoveMemberButtonStyles> = (props: IRemoveMemberButtonPropsStyles): IRemoveMemberButtonStyles => {
    const { palette } = props.theme!; // eslint-disable-line @typescript-eslint/no-non-null-assertion
    
    return {
        root: [
            RemoveMemberButtonClassNames.root,
            {
                // CSS PROPS AND SELECTORS
            }
        ],
        deleteButtonContainer: {
            display: 'flex',
            alignItems: 'flex-end'
        },
        deleteFooterContainer: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center'
        },
        deleteErrorMessageStyle: {
            color: palette.redDark,
            fontSize: '12px'
        },
        subComponentStyles: {            
            deleteDialogFooterContainer: {
                actions: {
                    margin: '8px 0 0'
                }
            },
            deletePrimaryButtonDisabled: {
                root: {
                    selectors: {
                        ':disabled': {
                            backgroundColor: palette.neutralQuaternary,
                            color: palette.neutralTertiary,
                            pointerEvents: 'none'
                        },
                    }
                },
            }
        }
    };
}