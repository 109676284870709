/*eslint-disable sonarjs/cognitive-complexity*/
import React, { useCallback, useState } from "react";
import { classNamesFunction, DefaultButton, Icon, IconButton, IStyleFunctionOrObject, ITextFieldStyleProps, ITextFieldStyles, keyframes, PrimaryButton, Spinner, SpinnerSize, TextField } from "@fluentui/react";
import { IEditLabelModalStyles, IEditLabelModalProps, IEditLabelModalPropsStyles } from "./editLabelModal.types";
import { useTranslation } from "react-i18next";
import { labelApi } from "../../../services/label/label.api";
import EdiModal from "../../../../common/components/ediModal/ediModal";
import { Helpers } from "../../../../utilities/helpers";
import { Constants } from "../../../../models/constants";
import { useAsyncApi } from "../../../../utilities/hooks";
import { ErrorsType } from "../../../../common/types/ErrorsType";
const getClassNames = classNamesFunction<IEditLabelModalPropsStyles, IEditLabelModalStyles>();

export const EditLabelModalBase = (props: IEditLabelModalProps) => {
    const classNames = getClassNames(props.styles, { 
        theme: props.theme, 
        className: props.className 
    });
    const { t } = useTranslation(['workflowLabManageUsers', 'common']);
    const [currentName, setCurrentName] = useState<string | undefined>(props.name);
    const [currentDescription, setCurrentDescription] = useState<string | undefined>(props.description);
    const [editLabelModal, setEditLabelModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [validationIcon, setValidationIcon] = useState('Error');
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const { palette } = props.theme!; // eslint-disable-line @typescript-eslint/no-non-null-assertion

    const onNameValidationResult = useCallback((error: string | JSX.Element, value: string | undefined) => {
        setValidationIcon(error ? 'Error' : 'Completed');
    }, []);

    const validateWorkflowLabName = useCallback(async (value: string) => {
        if (!value)
            return `${t('common:fieldRequired')}`;

        setValidationIcon('ProgressRingDots');        
        
        try {

            if (props.name === value) {
                setValidationIcon('Completed');
                return "";
            }
            const [isValid] = await Promise.all([labelApi.checkLabelName(props.workFlowLabId, value), Helpers.delay(500)]);
            return isValid ? "" : t('common:nameNotAvailable');
        }
        catch {
            return t('common:genericErrorCheckName');
        }
    }, [t, props.workFlowLabId, props.name]);
    
    const setupParameter = () => {
        return (isLoading ? 
                    <div style={{display: 'flex', justifyContent: 'center', height: 'inherit'}}>
                        <Spinner style={{alignSelf: 'center'}} size={SpinnerSize.medium} />
                    </div> : 
                    <div>
                        <TextField
                                label={t('common:name')}
                                placeholder={t('addName')}
                                deferredValidationTime={250}
                                onGetErrorMessage={validateWorkflowLabName}
                                required
                                autoFocus
                                autoComplete={"off"}
                                styles={styles}
                                onNotifyValidationResult={onNameValidationResult}
                                onChange={(_, newValue) => setCurrentName(newValue)}
                                defaultValue={currentName}
                                maxLength={Constants.MAX_LENGTH_LABEL_NAME}
                                onRenderSuffix={() => <Icon iconName={validationIcon} />}
                            />                        
                        <TextField 
                            maxLength={Constants.MAX_LENGHT_LABEL_DESCRIPTION}
                            label={t('common:description')} 
                            multiline 
                            rows={5} 
                            onChange={(_, newValue) => setCurrentDescription(newValue)} 
                            defaultValue={props.description}/>
                    </div>)
    }

    const styles: IStyleFunctionOrObject<ITextFieldStyleProps, ITextFieldStyles> = () => {
        return {
            root: {
                marginBottom: '30px'
            },
            errorMessage: {
                position: 'absolute'
            },
            suffix: [
                { background: 'transparent', },
                validationIcon === 'Error' && { color: palette.redDark },
                validationIcon === 'Completed' && { color: palette.greenDark },
                validationIcon === 'ProgressRingDots' && {
                    '> i': {
                        animation: `${keyframes({ to: { transform: 'rotate(360deg)' } })} 1s linear infinite`
                    }
                }
            ]
        }
    }

    const editLabel = useAsyncApi({
        func: async () => {
            try {
                setIsLoading(true);
                if(!currentName) return;
                await labelApi.updateLabel(props.Id, currentName, currentDescription);
                    if (props.onComplete)
                        props.onComplete();
            } catch (er) {
                const error: ErrorsType = er as ErrorsType;
                if (error.code === 400) {
                    setErrorMessage(t('errorMessageEditLabel'));
                }
            } finally{
                setIsLoading(false);
            }
        }

    });

    const modalFooter = () => {
        return (
            <div className={classNames.footerContainer}>
                    {errorMessage && <span className={classNames.errorMessage}>{errorMessage}</span>}
                    {/* {isLoading && <Spinner size={SpinnerSize.medium}></Spinner>} */}
                    <DefaultButton
                        onClick={() => {setCurrentName(props.name); setEditLabelModal(false); setErrorMessage(undefined)}}
                        style={{marginRight:'10px'}}
                    >
                        {t('common:cancel')}
                    </DefaultButton>
                    <PrimaryButton 
                        disabled={validationIcon !== "Completed"}
                        onClick={editLabel.execute}
                    >
                        {t('common:commands.edit')}
                    </PrimaryButton>  
            </div>
        )
    }

    return (
        <>
        <IconButton 
                    iconProps={{ iconName: 'Edit' }} 
                    title={`${t('editLabel')}`}
                    onClick={(e) => {
                        e.stopPropagation();
                        setEditLabelModal(true);
                    }
                }/>
            <EdiModal
                className={classNames.root}
                title={`${t('editLabel')}`}
                isOpen={editLabelModal}
                width={600}
                height={600}
                showCloseIcon={true}
                onCloseClick={() => {
                    setEditLabelModal(false);
                    setCurrentName(props.name);
                    setErrorMessage(undefined);
                    }}
                body={setupParameter()}
                footer={modalFooter()}
                styles={
                    {
                        body: {
                            overflow: 'unset'
                        },
                        // bodyContent:{
                        //     display: 'flex',
                        //     justifyContent: 'center',
                        // }
                    }
                }
            />
        </>
    );
}