import { styled } from "@fluentui/react";
import { EDIFolderContentBase } from "./EDIfolderContent.base";
import { getStyles } from "./EDIfolderContent.styles";
import { IEDIFolderContentProps, IEDIFolderContentPropsStyles, IEDIFolderContentStyles } from "./EDIfolderContent.types";


const EDIFolderContent = styled<IEDIFolderContentProps, IEDIFolderContentPropsStyles, IEDIFolderContentStyles>(
    EDIFolderContentBase,
    getStyles,
    undefined,
    { scope: 'EDIFolderContent' }
);

export default EDIFolderContent;