/*eslint-disable sonarjs/cognitive-complexity */
/*eslint-disable sonarjs/no-identical-functions */
import { classNamesFunction, DetailsList, DetailsRow, IDetailsRowProps, Persona, PersonaSize, PrimaryButton, ScrollablePane, SearchBox, SelectionMode, Spinner, SpinnerSize, Stack } from "@fluentui/react";
import { useBoolean } from '@fluentui/react-hooks';
import _ from "lodash";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Accordion from "../../../../common/components/accordion/accordion";
import TeamsImage from "../../../../common/components/teamsImage/teamsImage";
import { ImageName } from "../../../../common/components/teamsImage/teamsImage.types";
import { User } from "../../../../models/User";
import { WorkFlowLabRoleId } from "../../../../utilities/constants";
import { useAsyncApi, useOnMount } from "../../../../utilities/hooks";
import { mapper } from "../../../../utilities/mapper";
import { useCurrentWorkflowLab } from "../../../hooks/useCurrentWorkflowLab";
import { workflowLabApi } from "../../../services/workFlowLab/workFlowLab.api";
import WorkflowLabMembersModal from "../workflowLabMembersModal/workflowLabMembersModal";
import { MemberLabelsModal } from "./subComponents/memberLabelsModal";
import { MemberRoleDropdown } from "./subComponents/memberRoleDropdown";
import RemoveMemberButton from "./subComponents/removeMemeberButton/removeMemberButton";
import { IWorkflowLabMembersSettingsProps, IWorkflowLabMembersSettingsPropsStyles, IWorkflowLabMembersSettingsStyles } from "./workflowLabMembersSettings.types";

const getClassNames = classNamesFunction<IWorkflowLabMembersSettingsPropsStyles, IWorkflowLabMembersSettingsStyles>();

const getTotalMembersCount = (members?: Record<WorkFlowLabRoleId, User[]>) => {
  if (!members || Object.keys(members).length === 0)
    return 0;
  return _.values(members)
    .map(list => list?.length ?? 0)
    .reduce((prev, curr) => prev + curr);
}

export const WorkflowLabMembersSettingsBase = (props: IWorkflowLabMembersSettingsProps) => {
  const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
  const { t } = useTranslation(["workflowLabUsersSettings", "common"]);
  const currentworkflowLabId = useCurrentWorkflowLab()?.id //eslint-disable-line @typescript-eslint/no-non-null-assertion
  const [memberSearchKeyword, setMemberSearchKeyword] = useState('');
  const currentworkflowLab = useCurrentWorkflowLab(); //eslint-disable-line @typescript-eslint/no-non-null-assertion
  const [isUsersModalOpen, { setTrue: showUsersModal, setFalse: hideUsersModal }] = useBoolean(false);
  const [viewerPanelOpened, setViewerPanelOpened] = useState(false);

  const { palette } = props.theme!; //eslint-disable-line @typescript-eslint/no-non-null-assertion

  const { execute: loadMembers, value: members, loading } =
    useAsyncApi<string, Record<WorkFlowLabRoleId, User[]>>({
      keepResultsWhileReloading: true,
      func: async (keyword: string) => {
        if (!currentworkflowLab) return {} as Record<WorkFlowLabRoleId, User[]>;

        const workflowLabMembers = await workflowLabApi.getWorkflowLabMembers({
          id: currentworkflowLab.id,
          keyword: keyword
        });
        return _.groupBy(workflowLabMembers, (member) => member.workFlowLabRoleId) as Record<WorkFlowLabRoleId, User[]>;
      },
    });

  const totalMembersCount = useMemo(() => getTotalMembersCount(members), [members]);

  useOnMount(() => {
    loadMembers(memberSearchKeyword);
  });


  const viewerColumns = [
    {
      key: "persona",
      name: t('common:name'),
      minWidth: 100,
      maxWidth: 350,
      headerClassName: classNames.headerRow,
      onRender: function renderMemberPersona(member: User) {
        return (
          <Persona {...mapper.mapUserToPersona(member)} size={PersonaSize.size40} coinSize={32} />
        );
      }
    },
    {
      key: "role",
      name: !currentworkflowLab?.workflowLabMaster?t('common:role'):'',
      minWidth: 100,
      maxWidth: 120,
      headerClassName: classNames.headerRow,
      onRender: function renderMemberRoles(member: User) {
        if(!currentworkflowLab?.workflowLabMaster)
        return (
          <MemberRoleDropdown
            workflowLabId={currentworkflowLabId ? currentworkflowLabId : 0}
            dropdownStyles={classNames.subComponentStyles.rolesDropdown}
            member={member}
            totalCount={totalMembersCount}
            onRoleChangeCompleted={async () => await loadMembers(memberSearchKeyword)}
          />
        );
      },
    },
    {
      key: "labels",
      name: t('common:labels'),
      minWidth: 200,
      headerClassName: classNames.headerRow,
      onRender: function renderLabelPersona(member: User, index?: number) {
        return (
          <>
          {member.workFlowLabRoleId !== WorkFlowLabRoleId.Auditor &&
            <MemberLabelsModal
              member={member}
              labelListStyle={classNames.labelList}
              labelBoxStyle={classNames.labelBox}
              templatesCountCircleStyle={classNames.subComponentStyles.templatesCountCircle}
              remainingIconTagContainerStyle={classNames.remainingIconTagContainer}
              iconButtonStyles={classNames.subComponentStyles.iconButton()}
              itemIndex={index}
              calloutTarget = {member.workFlowLabRoleId===0 ? 'Owner' : member.workFlowLabRoleId===1 ? 'Supervisor' : 'User'}
              onActionComplete={() => console.log("")}
              currentWorkflowLab={currentworkflowLab}
              onActivitiesSaved={async () => await loadMembers(memberSearchKeyword)}
              palette={palette}
              containerTagStyle={classNames.containerTag}
            />
          }
        </>
        )
      }
    },
    {
      key: "remove",
      name: "",
      minWidth: 40,
      headerClassName: classNames.headerRow,
      onRender: function renderMemberRemoveButton(member: User) {
        if(!currentworkflowLab?.workflowLabMaster)
        return (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
            <RemoveMemberButton
              workflowLabId={currentworkflowLabId ? currentworkflowLabId : 0}
              buttonStyles={classNames.subComponentStyles.iconButton()}
              member={member}
              onRemoveMemberCompleted={async () => await loadMembers(memberSearchKeyword)}
            />
          </div>
        );
      },
    }
  ];

  const membersForRole = (role: WorkFlowLabRoleId) => {
    const singleRoleMembers = members && members[role];
    if (!singleRoleMembers) return null;
    return (
      <Accordion
        title={`${WorkFlowLabRoleId[role]} (${singleRoleMembers.length})`}
        opened={role === WorkFlowLabRoleId.Owner || (role === WorkFlowLabRoleId.User && viewerPanelOpened)}
        additionalOnAccordionClick={() => { if (!viewerPanelOpened) setViewerPanelOpened(true); }} stickyHeader
      >
        <DetailsList styles={classNames.subComponentStyles.list} items={singleRoleMembers}
          getKey={(item: User) => item.id}
          onRenderRow={(props?: IDetailsRowProps) =>
            props
              ? (
                <DetailsRow {...props} styles={classNames.subComponentStyles.row} />
              ) : null
          }
          columns={viewerColumns}
          selectionMode={SelectionMode.none} />
      </Accordion>
    );
  };

  const onSearchBoxClear = () => { setMemberSearchKeyword(''); loadMembers(''); }

  return (
    <React.Fragment>
      <Stack className={classNames.root}>
        <Stack horizontal>
          <Stack.Item style={{ marginRight: '30px' }}>
            <SearchBox
              key={currentworkflowLab?.id}
              placeholder={t("searchBox")}
              className={classNames.searchBox}
              onChange={(_, value) => setMemberSearchKeyword(value || '')}
              onSearch={() => loadMembers(memberSearchKeyword)}
              onClear={onSearchBoxClear}
              value={memberSearchKeyword}
            />
          </Stack.Item>
          <Stack.Item>
          {
                        !currentworkflowLab?.workflowLabMaster
                        && <PrimaryButton
                        iconProps={{ iconName: "AddFriend" }}
                        styles={classNames.subComponentStyles.addMembersButtonStyle()}
                        text={t("addMembers")}
                        onClick={showUsersModal}
                      />
                    }
          </Stack.Item>
        </Stack>
        <Stack.Item style={{marginTop: '15px'}} verticalFill className={classNames.lowerSection}>
          {loading 
            ? (
              <div className={classNames.loaderContainer}>
                <Spinner size={SpinnerSize.large} />
              </div>
            ) : (!loading && totalMembersCount === 0)
              ? (
                <div className={classNames.loaderContainer}>
                  <TeamsImage imageName={ImageName.EmptySearch} caption={t('common:emptySearch')} />
                </div>
              ) : (
                <ScrollablePane styles={classNames.subComponentStyles.scrollablePane}>
                  {membersForRole(WorkFlowLabRoleId.Owner)}
                  {membersForRole(WorkFlowLabRoleId.Supervisor)}
                  {membersForRole(WorkFlowLabRoleId.User)}
                  {membersForRole(WorkFlowLabRoleId.Auditor)}
                </ScrollablePane>
              )
          }
        </Stack.Item>
      </Stack>
      {isUsersModalOpen && 
        <WorkflowLabMembersModal
          isOpen={isUsersModalOpen}
          operationEnded={(success: boolean) => {
            hideUsersModal();
            if (success)
              loadMembers(memberSearchKeyword);
          }}
        />
      }
    </React.Fragment>
  );
};
