import { IStyleFunction } from "@fluentui/react"
import { IWorkflowCustomFilterModalPropsStyles, IWorkflowCustomFilterModalStyles } from "./WorkflowCustomFilterModal.types"

export const WorkflowCustomFilterModalGlobalClassNames = {
    root: 'edi-graph-workflow-status-custom-filter-modal-root'
}

export const getStyles: IStyleFunction<IWorkflowCustomFilterModalPropsStyles, IWorkflowCustomFilterModalStyles> = (props: IWorkflowCustomFilterModalPropsStyles): IWorkflowCustomFilterModalStyles => {
    return {
        root: [
            WorkflowCustomFilterModalGlobalClassNames.root,
            {
                // CSS PROPS AND SELECTORS
            }
        ]
    };
}