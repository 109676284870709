/*eslint-disable sonarjs/cognitive-complexity */
import { classNamesFunction, DetailsHeader, DetailsList, DetailsRow, IColumn, Label, MessageBar, MessageBarType, ScrollablePane, SelectionMode, Spinner, SpinnerSize, Stack, StackItem, TooltipHost } from "@fluentui/react";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Accordion from "../../../../common/components/accordion/accordion";
import { WorkFlow, WorkFlowStep, WorkFlowStepStatus } from "../../../../models/WorkFlow";
import { WorkFlowDetails, WorkFlowStepDetails } from "../../../../models/WorkFlowDetails";
import { WorkFlowRelativeStatus, WorkFlowStatus } from "../../../../models/WorkflowEnum";
import { Helpers } from "../../../../utilities/helpers";
import { useAsyncApi, useOnMount } from "../../../../utilities/hooks";
import { useCurrentWorkflowLab } from "../../../hooks/useCurrentWorkflowLab";
import { workflowStepToShort } from "../../../services/workflow.contracts";
import { workflowDisjointedApi } from "../../../services/workflowDisjointed/workflowDisjointed.api";
import StepStatusSummary from "./stepStatusSummary/stepStatusSummary";
import { IWorkflowSummaryProps, IWorkflowSummaryPropsStyles, IWorkflowSummaryStyles } from "./workflowSummary.types";

const getClassNames = classNamesFunction<IWorkflowSummaryPropsStyles, IWorkflowSummaryStyles>();

export type modalType = {
    open: boolean,
    step?: WorkFlowStepDetails<WorkFlowStep>,
    loading: boolean
}

export const WorkflowSummaryBase = (props: IWorkflowSummaryProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const { t } = useTranslation([props.translationName, 'StepStatus', 'common', 'workflowDetails']);
    const [workFlow, setWorkFlow] = useState<WorkFlowDetails<WorkFlow>>();
    const [isWFAssignedToMe, setIsWFAssignedToMe] = useState(false);
    const selectedWorkflowLab = useCurrentWorkflowLab();
    const [loadingExport, setLoadingExport] = useState(false);
    const displayDocument = props.workFlow?.documentName !== undefined && props.workFlow?.documentName !== null ? true : false;
    const cursorName: string = props.isDocumentClickable ? 'pointer' : '';
    const [warning, setWarning] = useState("");


    const { execute: generateExport } = useAsyncApi<void, void>({
        func: async () => {
            setLoadingExport(true);
            try {
                const request =
                {
                    description: props.workFlow ? props.workFlow?.description : '',
                    name: props.workFlow ? props.workFlow?.name : '',
                    steps: props.workFlow ? props.workFlow?.steps.map(s => workflowStepToShort(s)) : [],
                    labelCategoryIds: props.workFlowDisjointment ? props.workFlowDisjointment.map(d => d.labels.map(l => l.labelCategoryId ? l.labelCategoryId : 0)).flat() : [],
                    commandType: 0
                }
                if (selectedWorkflowLab?.id)
                    await workflowDisjointedApi.exportDisjointWorkflow(selectedWorkflowLab.id
                        , 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                        , workFlow?.workFlow.name + '.xlsx', request);
            } catch (er) {
                setLoadingExport(false);
            }
            setLoadingExport(false);
        }
    });

    const { execute: getWarning } = useAsyncApi<void, void>({
        func: async () => {
            const request =
            {
                description: props.workFlow ? props.workFlow?.description : '',
                name: props.workFlow ? props.workFlow?.name : '',
                steps: props.workFlow ? props.workFlow?.steps.map(s => workflowStepToShort(s)) : [],
                labelCategoryIds: props.workFlowDisjointment ? props.workFlowDisjointment.map(d => d.labels.map(l => l.labelCategoryId ?? 0)).flat() : [],
                commandType: 0
            }
            if (selectedWorkflowLab?.id) {
                const result = await workflowDisjointedApi.getWarning(selectedWorkflowLab.id, request);
                if (result === true) {
                    setWarning(t('workFlowSummary:noApproverFound'));
                }
            }
        }
    });

    useOnMount(() => {
        if (!props.isTemplateSummary && props.workFlowDisjointment?.length !== 0)
            getWarning()
    })

    useEffect(() => {
        if (props.workFlow) {
            setWorkFlow({
                workFlow: props.workFlow,
                stepsDetails: props.workFlow.steps.sort((a, b) => a.order > b.order ? 1 : -1).map((x, i) => {
                    return {
                        step: { ...x },
                        prevStep: (x.order !== 0) ? props.workFlow?.steps.filter(s => s.order === (x.order - 1))[0] : undefined,
                        isLastStep: x.order === ((props.workFlow?.steps.length || 1) - 1),
                        isUnusedStep: props.workFlow?.status === WorkFlowStatus.Refused && x.status === WorkFlowStepStatus.Idle
                    }
                })
            });
            // setWorkFlowStep(props.workFlow.steps.filter(x => x.status === WorkFlowStepStatus.ToBeApproved)[0])
            setIsWFAssignedToMe(props.workFlow.relativeStatus === WorkFlowRelativeStatus.ToMe);
        }
    }, [props.workFlow]);

    const emptyList = useCallback(() => {
        if (workFlow?.stepsDetails?.length !== 0)
            return null;

        return (
            <div className={classNames.emptyList}>
                <span>{t("common:emptyList")}</span>
            </div>
        )
    }, [workFlow?.stepsDetails?.length, t, classNames.emptyList]);

    const rulesColumn: IColumn[] = [
        {
            key: 'rules',
            name: t('iter.column.rules'),
            ariaLabel: 'rules',
            fieldName: '',
            minWidth: 150,
            maxWidth: 180,
            isResizable: true,
            onRender: function getValue(item: WorkFlowStepDetails<WorkFlowStep>) {
                return <span style={{ whiteSpace: 'normal' }}>{t(`stepStatus:type.${item.step.type}`)}</span>
            }
        }
    ];

    const stepColumn: IColumn[] = [
        {
            key: 'step',
            name: t("iter.column.step"),
            ariaLabel: 'step',
            fieldName: '',
            minWidth: 200,
            maxWidth: 420,
            isResizable: true,
            className: classNames.step,
            onRender: function getValue(item: WorkFlowStepDetails<WorkFlowStep>) {
                return <>
                    <StepStatusSummary item={item}></StepStatusSummary>
                </>
            }
        }
    ];

    // const getDocumentSharepointPath = () => {
    //     return workFlow?.workFlow.documentUrl?.concat(
    //         Helpers.getFileExtension(workFlow.workFlow.documentUrl) === FileExtensions.Pdf ?
    //             '' :
    //             SharePointDocumentPathSuffixes.ViewOnly);
    // }

    const columnMerger = () => {
        return stepColumn.concat(rulesColumn)
    }

    return (
        <>
            <Stack className={classNames.root}>
                <StackItem className={`${classNames.mainContent}`}>
                    <ScrollablePane>
                        <Accordion styles={classNames.subComponentStyles.accordion} title={t('workflowDetails:info.title')} opened={true} stickyHeader>
                            <div className={classNames.accordionContent}>
                                <Stack tokens={{ childrenGap: 10 }}>
                                    <Stack tokens={{ childrenGap: 30 }}>
                                        <Stack tokens={{ childrenGap: 10 }}>
                                            <StackItem><span style={{ fontWeight: 'bold' }}>{t('workflowDetails:info.workflowname')}</span></StackItem>
                                            <StackItem style={{ marginTop: 5 }}><span className={classNames.summaryWrapper}>{workFlow?.workFlow.name}</span></StackItem>
                                        </Stack>
                                    </Stack>
                                    <Stack horizontal tokens={{ childrenGap: 30 }}>
                                        {displayDocument &&
                                            <Stack tokens={{ childrenGap: 10 }}>
                                                <StackItem>
                                                    <span style={{ fontWeight: 'bold' }}>{t('workflowDetails:info.document')}</span>
                                                </StackItem>
                                                <StackItem style={{ width: 'inherit', marginTop: 5 }}>
                                                    <TooltipHost content={workFlow?.workFlow.documentName}>
                                                            <span
                                                                className={classNames.documentBold}
                                                                style={{ cursor: cursorName }}
                                                                onClick={() => { if (props.isDocumentClickable) return Helpers.getPreviewFile(workFlow?.workFlow.id ?? 0, props.previewSource) }}>
                                                                {workFlow?.workFlow.documentName}
                                                            </span>
                                                    </TooltipHost>
                                                </StackItem>
                                                <StackItem style={{ marginTop: 0 }}>
                                                    <Label style={{ fontSize: '12px' }}>{workFlow?.workFlow.isDocumentEditable ? t('workflowDetails:documentEditable') : t('documentNotEditable')}</Label>
                                                </StackItem>
                                            </Stack>
                                        }
                                    </Stack>
                                </Stack>
                            </div>
                        </Accordion>

                        <Accordion styles={classNames.subComponentStyles.accordion} title={t('workflowDetails:iter.title')} opened={!isWFAssignedToMe} stickyHeader>
                            <div className={classNames.accordionContent}>
                                {props.workFlowDisjointment && props.workFlowDisjointment.length > 0 && 
                                    <div>
                                        <span style={{ fontWeight: 'bold' }}>{t('workFlowSummary:categoriesDisjoint')}</span>
                                        {props.workFlowDisjointment.map(x => {
                                            return <span
                                                    className={classNames.summaryWrapper}
                                                    style={{flexDirection: 'column-reverse'}}
                                                    key={x.category.id} >{`${x.category.name}`} :
                                                    {x.labels.map((l, i, arr) => {
                                                        if (arr.length - 1 === i) {
                                                            return ` ${l.name}`
                                                        }
                                                        else {
                                                            return ` ${l.name},`
                                                        }
                                                    })}                                                
                                                </span>
                                        })}
                                </div>}
                                <DetailsList
                                    items={workFlow?.stepsDetails || []}
                                    columns={columnMerger()}
                                    selectionMode={SelectionMode.none}
                                    isHeaderVisible={true}
                                    onRenderDetailsHeader={props => props ? <DetailsHeader {...props} styles={classNames.subComponentStyles.detailsHeader} /> : null}
                                    getKey={((item: WorkFlowStepDetails<WorkFlowStep>) => item.step.id.toString())}
                                    setKey="none"
                                    styles={classNames.subComponentStyles.detailsList}
                                    onRenderRow={props => props ? <DetailsRow id={props.id} {...props} styles={classNames.subComponentStyles.detailsRow} /> : null}
                                    onRenderDetailsFooter={emptyList}
                                />
                            </div>
                        </Accordion>
                        {!props.isTemplateSummary && props.workFlowDisjointment?.length !== 0 && <div>
                            <div className={`${classNames.exportWorkflow}`}>
                                {loadingExport &&
                                    <Spinner className={`${classNames.spinner}`} size={SpinnerSize.medium} />}
                                <span
                                    onClick={() => generateExport()}
                                    className={`${classNames.exportWorkflowLabel}`}
                                >
                                    {t('workFlowSummary:exportDisjoint')}
                                </span>
                            </div>

                            {warning && <MessageBar
                                messageBarType={MessageBarType.warning}
                                isMultiline={true}
                                dismissButtonAriaLabel="Close"
                                overflowButtonAriaLabel="See more">
                                Warning : {warning}
                            </MessageBar>}
                        </div>
                        }
                    </ScrollablePane>
                </StackItem>
            </Stack>
        </>
    );
}

