import { IStyleFunction } from "@fluentui/react"
import { ISelectLabelUserPropsStyles, ISelectLabelUserStyles } from "./selectLabelUser.types"

export const SelectLabelUserGlobalClassNames = {
    root: 'edi-single-step-root',
    firstContainer: 'edi-single-step-firstContainer',
    stepSingleItem: 'edi-single-step-stepSingleItem',
    iconBarContainer: 'edi-single-step-iconBarContainer'
}

export const getStyles: IStyleFunction<ISelectLabelUserPropsStyles, ISelectLabelUserStyles> = (props: ISelectLabelUserPropsStyles): ISelectLabelUserStyles => {
    return {
        root: [
        ]
    };
}