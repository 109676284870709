import { FontSizes, FontWeights, IDetailsColumnStyleProps, IDetailsColumnStyles, IDetailsListStyleProps, IDetailsListStyles, IDetailsRowStyleProps, IDetailsRowStyles, ISearchBoxStyles, IStackStyles, IStyleFunction, ITooltipHostStyles } from "@fluentui/react";
import { IWorkflowLabTemplatesSettingsPropsStyles, IWorkflowLabTemplatesSettingsStyles } from "./workflowLabTemplatesSettings.types";

export const WorkflowLabTemplatesSettingsGlobalClassNames = {
    root: 'edi-workflowLab-templates-settings-root',
    title: 'edi-workflowLab-templates-title',
    titleIcon: 'edi-workflowLab-templates-title-icon',
    titleContent: 'edi-workflowLab-templates-title-content',
    upperSection: 'edi-workflowLab-templates-upper-section',
    searchBox: 'edi-workflowLab-templates-search-box',
    lowerSection: 'edi-workflowLab-templates-lower-section',
    headerRow: 'edi-workflowLab-templates-header-row',
    loaderContainer: 'edi-workflowLab-templates-loader-container',
    labelList: 'edi-workflowLab-templates-labelList',
    tagCounter: 'edi-workflowLab-templates-settings-tagCounter',
    labelBox: 'edi-workflowLab-templates-settings-labelBox',
    teamsImage: 'edi-workflowLab-templates-settings-teamsImage',
    containerTag: 'edi-workflowLab-templates-settings-containerTag',
    centeredContainer: 'edi-work-flow-template-list-centered-container',
    detailListContainer: 'edi-work-flow-template-list-container',
    emptyList: 'edi-work-flow-template-list-emptyList',
    templateNameWrapper: 'edi-work-flow-template-wrapper-name'
}

export const getStyles: IStyleFunction<IWorkflowLabTemplatesSettingsPropsStyles, IWorkflowLabTemplatesSettingsStyles> = (props: IWorkflowLabTemplatesSettingsPropsStyles): IWorkflowLabTemplatesSettingsStyles => {
    const { semanticColors, palette } = props.theme!; //eslint-disable-line @typescript-eslint/no-non-null-assertion
    const actionButtonWidth = '35px !important';
    return {
        root: [
            WorkflowLabTemplatesSettingsGlobalClassNames.root,
            {
                height: '100%',
                padding: '15px 25px'
            }
        ],        
        load: {
            display: 'none'
        },
        title: [
            WorkflowLabTemplatesSettingsGlobalClassNames.title,
            {
                fontSize: FontSizes.size18,
                fontWeight: FontWeights.semibold,
                paddingBottom: 10
            }
        ],
        titleIcon: [
            WorkflowLabTemplatesSettingsGlobalClassNames.titleIcon,
            {
                padding: 7,
                border: '1.5px solid',
                borderRadius: '50%',
                fontSize: 26
            }
        ],
        titleContent: [
            WorkflowLabTemplatesSettingsGlobalClassNames.titleContent,
            {
                paddingLeft: 10
            }
        ],
        upperSection: [
            WorkflowLabTemplatesSettingsGlobalClassNames.upperSection,
            {
                paddingTop: 10,
                paddingBottom: 10
            }
        ],
        searchBox: [
            WorkflowLabTemplatesSettingsGlobalClassNames.searchBox,
            {
                minWidth: 450,
                paddingLeft: 8,
                iconContainer: {
                    display: 'none'
                }
            }
        ],
        lowerSection: [
            WorkflowLabTemplatesSettingsGlobalClassNames.lowerSection,
            {
                position: 'relative'
            }
        ],     
        emptyList: [
            WorkflowLabTemplatesSettingsGlobalClassNames.emptyList,
            {
                position: 'absolute',
                top: '60px',
                width: '300px',
                left: 'calc(50% - 150px)'
            }],   
        detailListContainer: [
            WorkflowLabTemplatesSettingsGlobalClassNames.detailListContainer,
            {
                marginRight: '30px',
                marginTop: '15px',
                //marginBottom: '15px',
                height: '100%',
                '> .ms-Viewport': {
                    height: '100%'
                }
            }],        
        centeredContainer: [
            WorkflowLabTemplatesSettingsGlobalClassNames.centeredContainer,
            {
                height: '85vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }
        ],
        headerRow: [
            WorkflowLabTemplatesSettingsGlobalClassNames.headerRow,
            {
                ':hover': {
                    backgroundColor: 'transparent'
                },
                ':active': {
                    backgroundColor: 'transparent'
                }
            }
        ],
        labelList: [
            WorkflowLabTemplatesSettingsGlobalClassNames.labelList,
            {
                display: 'flex',
                alignItems: "center",
                flexDirection: 'horizontal',
                selectors: {
                    ' .icontag-container': {
                        marginLeft: '10px'
                    },
                    ' .edi-icon-tag-root': {
                        padding: '0 13px'
                    }
                }
            }
        ],        
        templateNameWrapper: [
            WorkflowLabTemplatesSettingsGlobalClassNames.templateNameWrapper,
            {
                position: 'relative',
                paddingRight: 30,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            }
        ], 
        containerTag: [
            WorkflowLabTemplatesSettingsGlobalClassNames.containerTag,
            {
            display: 'flex', 
            height: '100%', 
            alignItems: 'center',
            flexFlow: 'row wrap',
            gap: '5px 10px' 
            }
        ],
        loaderContainer: [
            WorkflowLabTemplatesSettingsGlobalClassNames.loaderContainer,
            {
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
                /*
                height: '85vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
                */
            }
        ],
        teamsImage: [
            WorkflowLabTemplatesSettingsGlobalClassNames.teamsImage,
            {
                width: '50%',
                height: '50%'
            }
        ],
        tagCounter: [
            WorkflowLabTemplatesSettingsGlobalClassNames.tagCounter,
            {
                marginLeft: '10px !important'
            }
        ],
        labelBox: [
            WorkflowLabTemplatesSettingsGlobalClassNames.labelBox,
            {
                width: '100px',
                overflow: 'hidden',
                height: '25px',
                padding: '3px 0'
            }
        ],
        remainingIconTagContainer: {
            padding: '0 5px',
            width: '100%',
            textAlign: 'left',
            justifyContent: 'start',
            color: palette.themePrimary
        },
        subComponentStyles: {
            addTemplatesButtonStyle: {
                icon: {
                    height: 'auto'
                }
            },
            iconButton: {
                root: {
                    paddingRight: 10,
                },
                icon: {
                    color: palette.black
                },
                iconDisabled: {
                    color: semanticColors.bodyText
                },
                rootHovered: {
                    background: 'transparent'
                },
                rootPressed: {
                    background: 'transparent'
                },
                rootDisabled: {
                    background: 'transparent',
                    cursor: 'not-allowed'
                }
            },
            detailsList: (props: IDetailsListStyleProps): IDetailsListStyles => {
                return {
                    root: {
                        '& [role="grid"]': {
                            display: 'flex',
                            flexDirection: 'column',
                        }
                    },
                    headerWrapper: {
                        flex: '0 0 auto'
                    },
                    contentWrapper: {
                        flexGrow: '1',
                        flexShrink: '1',
                        flexBasis: 'calc(100vh - 280px)',
                        overflowY: 'auto',
                        overflowX: 'hidden',
                        '@media(max-width: 730px)': { // 798 - 68
                            overflowX: 'auto' // show horizontal scrollbar when list is not shrinkable anymore
                        }
                    },
                    focusZone: {},
                }
            },
            column: (props: IDetailsColumnStyleProps): IDetailsColumnStyles => {
                return {
                    root: {},
                    gripperBarVerticalStyle: {},
                    cellTooltip: {},
                    cellTitle: {
                        cursor: 'pointer'
                    },
                    cellName: {},
                    iconClassName: {},
                    nearIcon: {},
                    accessibleLabel: {},
                    sortIcon: {},
                    filterChevron: {},
                    borderAfterDropping: {},
                    noBorderAfterDropping: {},
                    borderWhileDragging: {},
                    noBorderWhileDragging: {},
                }
            },
            detailsRow: (props: IDetailsRowStyleProps): IDetailsRowStyles => {
                return {
                    root: {
                        '& [data-automation-key="info"]':{
                            paddingLeft: 0,
                            paddingRight: 0,
                            width: actionButtonWidth
                        },
                        '& [data-automation-key="edit"]':{
                            paddingLeft: 0,
                            paddingRight: 0,
                            width: actionButtonWidth
                        },
                        '& [data-automation-key="delete"]':{
                            paddingLeft: 0,
                            paddingRight: 0,
                        }
                    },
                    cell: { alignSelf: 'center', textOverflow: 'unset'},
                    cellAnimation: {},
                    cellPadded: {},
                    cellMeasurer: {},
                    cellUnpadded: {},
                    check: {},
                    checkCell: {},
                    checkCover: {},
                    fields: {},
                    isMultiline: {},
                    isRowHeader: {},
                }
            },
            tooltipStyle: (): ITooltipHostStyles => {
                return {
                    root: {
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        color: palette.themePrimary,
                        cursor: 'context-menu'
                    }
                }
            },
            searchBoxStyles: (): ISearchBoxStyles => {
                return {
                    root: {
                        width: '300px'
                    }
                }
            },
            searchAndSendStyles: (): IStackStyles => {
                return{
                    root: {
                        flexWrap: 'wrap',
                        alignItems: 'end'
                    }
                }
            },
            deletePrimaryButtonDisabled: {
                root: {
                    selectors: {
                        ':disabled': {
                            backgroundColor: palette.neutralQuaternary,
                            color: palette.neutralTertiary,
                            pointerEvents: 'none'
                        },
                    }
                },
            }
        }
    };
}