import { styled } from "@fluentui/react";
import { getStyles } from "./associateCategoryModal.styles";
import { IAssociateCategoryModalPropsStyles, IAssociateCategoryModalStyles, IAssociateCategoryModalProps } from "./associateCategoryModal.types";
import { AssociateCategoryModalBase} from "./associateCategoryModal.base";

const AssociateCategoryModal = styled<IAssociateCategoryModalProps, IAssociateCategoryModalPropsStyles, IAssociateCategoryModalStyles>(
    AssociateCategoryModalBase,
    getStyles,
    undefined,
    { scope: 'AssociateCategoryModal' }
);

export default AssociateCategoryModal;