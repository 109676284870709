import React, { useState } from "react";
import { classNamesFunction, IPersonaSharedProps, Persona, Stack, StackItem, TooltipHost } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { StepCircleStatus } from "./stepStatusSummary.styles";
import { WorkFlowStepDetails } from "../../../../../models/WorkFlowDetails";
import { WorkFlowStep, WorkFlowStepStatus } from "../../../../../models/WorkFlow";
import { IStepStatusSummaryProps, IStepStatusSummaryPropsStyles, IStepStatusSummaryStyles } from "./stepStatusSummary.types";
import IconTag from "../../../../../common/components/iconTag/iconTag";
import { UserItem } from "../../../../services/workflow.contracts";

const getClassNames = classNamesFunction<IStepStatusSummaryPropsStyles, IStepStatusSummaryStyles>();

const getCircleStatus = (item: WorkFlowStepDetails<WorkFlowStep>) => {
    if (item.isUnusedStep)
        return StepCircleStatus.IdleAndPrevRefused;
    if ((item.step.status === WorkFlowStepStatus.Idle || item.step.status === WorkFlowStepStatus.ToBeApproved) && item.prevStep?.status !== WorkFlowStepStatus.Refused)
        return StepCircleStatus.IdleOrPendingAndPrevNotRefused;
    if ((item.step.status === WorkFlowStepStatus.Approved))
        return StepCircleStatus.Approved;
    if ((item.step.status === WorkFlowStepStatus.Refused))
        return StepCircleStatus.Refused;

    return StepCircleStatus.IdleOrPendingAndPrevNotRefused;
}

const mapUserToPersona = (user: UserItem): IPersonaSharedProps | undefined => {
    return {
        text: `${user.firstName} ${user.lastName}`,
        secondaryText: user.email,
        imageInitials: `${user.firstName[0].toUpperCase() || ""}${user.lastName[0].toUpperCase() || ""}`
    }
}

export const StepStatusSummaryBase = (props: IStepStatusSummaryProps) => {
    const [collapsed, setCollapsed] = useState(false);
    const { t } = useTranslation(['stepStatus', 'workflowDetails','workFlowSummary']);
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className, collapsed: collapsed, lastStep: props.item.isLastStep, status: getCircleStatus(props.item) });

    return (
        <>
            <Stack className={classNames.rowMargin} horizontal tokens={{ childrenGap: 10 }}>
                <StackItem>
                    <div onClickCapture={() => { setCollapsed(!collapsed) }} className={classNames.stepStatusSummary}></div>
                </StackItem>
                <StackItem style={{ width: '95%' }}>
                    <Stack tokens={{ childrenGap: 5 }}>
                        <StackItem>
                            <TooltipHost className={classNames.tooltip} content={props.item.step.name}>
                                <div className={classNames.stepName} >
                                    {t('title', { 'order': props.item.step.order + 1 })} {props.item.step.name}
                                </div>
                            </TooltipHost>
                        </StackItem>
                        {!collapsed ?
                            <StackItem >
                                <Stack className={classNames.approverSection} tokens={{ childrenGap: 15 }}>                                    
                                    {props.item.step.usersAndLabels !== undefined && props.item.step.usersAndLabels.map(x => {
                                        return x.label ? <Stack horizontal verticalAlign="center" key={x.label.id} >
                                            <StackItem>{t('workFlowSummary:label')}</StackItem>
                                            <StackItem>                                                
                                                <div className={classNames.approverInitialLettersSection}>
                                                    <IconTag className={classNames.wrapper} styles={{root: {minWidth: '100px',  maxHeight: '24px', padding: '4px 12px'}}} label= {x.label.name}/> 
                                                </div>
                                            </StackItem>                                        
                                        </Stack> :
                                        <Stack horizontal verticalAlign="center" key={x.user.id} >
                                        <StackItem>{t('workflowDetails:info.stepApprover')}</StackItem>
                                        <StackItem>                                                
                                            <div className={classNames.approverInitialLettersSection}>
                                                <Persona
                                                    {...mapUserToPersona(x.user)}
                                                    hidePersonaDetails
                                                    onRenderInitials={(props) => <TooltipHost content={props?.text}><div style={{ cursor: 'pointer' }}>{props?.imageInitials}</div></TooltipHost>}                        
                                                    coinSize={30}
                                                />
                                            </div>
                                        </StackItem>
                                        <StackItem>{`${x.user.firstName} ${x.user.lastName}`} </StackItem>
                                    </Stack>
                                    })}
                                    {props.item.step.usersAndLabels === undefined && props.item.step.approvers && props.item.step.approvers.map(x => {
                                        return <Stack horizontal verticalAlign="center" key={x.approver.id} >
                                            <StackItem>{t('workflowDetails:info.stepApprover')}</StackItem>
                                            <StackItem>                                                
                                                <div className={classNames.approverInitialLettersSection}>
                                                    <Persona
                                                        {...mapUserToPersona(x.approver)}
                                                        hidePersonaDetails
                                                        onRenderInitials={(props) => <TooltipHost content={props?.text}><div style={{ cursor: 'pointer' }}>{props?.imageInitials}</div></TooltipHost>}                        
                                                        coinSize={30}
                                                    />
                                                </div>
                                            </StackItem>
                                            <StackItem>{`${x.approver.firstName} ${x.approver.lastName}`} </StackItem>
                                        </Stack>
                                    })}
                                </Stack>

                            </StackItem> : ''}
                    </Stack>
                </StackItem>
            </Stack>
        </>
    );
}
