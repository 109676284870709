import { IStyleFunction } from "@fluentui/react"
import { IMultiStepModalPropsStyles, IMultiStepModalStyles } from "../../../../common/components/multiStepModal/multiStepModal.types"
import { IUploadUserLabelModalPropsStyles, IUploadUserLabelModalStyles } from "./uploadUserLabelModal.types"

export const UploadUserLabelGlobalClassNames = {
    root: 'edi-upload-userlabel-start-modal-root',
    selected: 'edi-upload-userlabel-start-modal-selected',
    selectedContainer: 'edi-upload-userlabel-start-modal-selectedContainer',
    grid: 'edi-upload-userlabel-start-modal-grid',
    loadContainer: 'edi-upload-userlabel-start-modal-loadContainer',
    errorMessage: 'edi-upload-userlabel-start-modal-errorMessage',
}
export const getStyles: IStyleFunction<IUploadUserLabelModalPropsStyles, IUploadUserLabelModalStyles> = (props: IUploadUserLabelModalPropsStyles): IUploadUserLabelModalStyles => {
    const { palette } = props.theme!; //eslint-disable-line @typescript-eslint/no-non-null-assertion

    return {
        subComponentStyles: {
            ediModal: (props: IMultiStepModalPropsStyles): IMultiStepModalStyles => {
                return {
                    // body: { marginTop: '0px !important' },
                    body:{},
                    bodyContent: {},
                    title: {},
                    closeIcon: {},
                    container: { width: '800px' },
                    content: {},
                    footer: {
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'flex-end',
                        alignItems: 'center'
                    },
                    header: {},
                    subComponentStyles: {
                        iconButton: {}
                    },
                    subTitle: { color: palette.black }
                }
            }
        },
        thirdFooter: {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            textAlign: 'center'
        },
        errorMessage: [UploadUserLabelGlobalClassNames.errorMessage,
            {
                color: palette.redDark,
                display: 'flex',
                alignItems: 'center'
            }],
       
    }
}