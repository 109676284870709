import { IDetailsHeaderStyleProps, IDetailsHeaderStyles, IDetailsListStyleProps, IDetailsListStyles, IDetailsRowStyleProps, IDetailsRowStyles, IStyleFunction } from "@fluentui/react";
import { IWorkflowStepHistoryPropsStyles, IWorkflowStepHistoryStyles } from "./workflowStepHistory.types";

export const WorkflowStepHistoryGlobalClassNames = {
    root: 'edi-workflow-step-history-root',
    workflowStepHistoryNameWrapper: 'edi-workflow-step-history-name-wrapper',
    spinnerStyle: 'edi-workflow-step-history-spinner',
}

export const getStyles: IStyleFunction<IWorkflowStepHistoryPropsStyles, IWorkflowStepHistoryStyles> = (props: IWorkflowStepHistoryPropsStyles): IWorkflowStepHistoryStyles => {

    return {
        root: [
            WorkflowStepHistoryGlobalClassNames.root, 
            {
            }
        ],
        workflowStepHistoryNameWrapper: [WorkflowStepHistoryGlobalClassNames.workflowStepHistoryNameWrapper,
            {
                position: 'relative',
                paddingRight: 30,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            }
        ],
        spinnerStyle: [WorkflowStepHistoryGlobalClassNames.spinnerStyle,
            {
                display: 'flex',
                alignContent: 'center',
                justifyContent: 'center',
                height: '100%'
            }
        ],
        subComponentStyles: {
            detailsList: (props: IDetailsListStyleProps): IDetailsListStyles => {
                return {
                    root: {
                    },
                    headerWrapper: {
                    },
                    contentWrapper: {
                    },
                    focusZone: {}
                }
            },
            detailsHeader: (props: IDetailsHeaderStyleProps): IDetailsHeaderStyles => {
                return {
                    root: {
                        borderBottom: '0px',
                        paddingTop: 0,
                    },
                    cellIsCheck: {},
                    check: {},
                    cellWrapperPadded: {},
                    cellIsActionable: {},
                    cellIsEmpty: {},
                    cellSizer: {},
                    cellSizerStart: {},
                    cellSizerEnd: {},
                    cellIsResizing: {},
                    cellIsGroupExpander: {},
                    collapseButton: {},
                    checkTooltip: {},
                    sizingOverlay: {},
                    dropHintCircleStyle: {},
                    dropHintCaretStyle: {},
                    dropHintLineStyle: {},
                    dropHintStyle: {},
                    accessibleLabel: {},
                }
            },
            detailsRow: (props: IDetailsRowStyleProps): IDetailsRowStyles => {
                return {
                    root: {
                        borderBottom: '0px',
                        selectors: {
                            ':hover': {
                                backgroundColor: 'unset'
                            }
                        },
                    },
                    cell: {},
                    cellAnimation: {},
                    cellPadded: {},
                    cellMeasurer: {},
                    cellUnpadded: {},
                    check: {},
                    checkCell: {},
                    checkCover: {},
                    fields: {},
                    isMultiline: {},
                    isRowHeader: {},
                }
            },
        }
    };
}