import { apiClient } from "../../../modules/apiClient/apiClient"; 
import { svcPaths } from "../../../modules/apiClient/config";
import { ApproveWorkFlowStep, CheckStepDocumentNameRequest } from "./workflowstep.contracts";

class WorkFlowStepApiClass {
    async ApproveWorkFlowStep(request: ApproveWorkFlowStep, id: number, file: File) {
        const formdata: FormData = new FormData();
        formdata.append('File', file);
        formdata.append('jsonCommand', JSON.stringify(request));
        
        const response = await apiClient.put<void>(`${svcPaths.workflow}/workflowstep/${id}`, formdata);
        return response.data;
    }

    async UpdateStepApprover(stepId: number, approversId: string[]){
        const response = await apiClient.put(`${svcPaths.workflow}/workflowstep/${stepId}/update`, 
            {
                UserIds: approversId
            }
        );
        return response.data;
    }

    async IsWorkFlowStepDocumentNameAvailable(request: CheckStepDocumentNameRequest){
        const response = await apiClient.post<boolean>(`${svcPaths.workflow}/workflowstep/check-name`, request);
        return response.data;
    }

    async getWorkflowStepFilePreviewUrl(id: number) {
        const response = await apiClient.get(`${svcPaths.workflow}/workflowstep/${id}/previewWorkflowStep`);
        return response.data;
    }

    async getWorkflowStepHistory(id: number) {
        const response = await apiClient.get(`${svcPaths.workflow}/workflowstep/${id}/workflowStepHistory`);
        return response.data;
    }
}

export const workFlowStepApi = new WorkFlowStepApiClass();