import React from "react";
import { classNamesFunction, DefaultButton, Dialog, DialogFooter, IconButton, PrimaryButton, Spinner, SpinnerSize } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { IConfirmModalProps, IConfirmModalPropsStyles, IConfirmModalStyles } from "./confirmModal.types";

const getClassNames = classNamesFunction<IConfirmModalPropsStyles, IConfirmModalStyles>();

export const ConfirmModalBase = (props: IConfirmModalProps) => {
    const classNames = getClassNames(props.styles,
        {
            theme: props.theme,
            className: props.className
        });

    const { t } = useTranslation(['common']);
    
    return (
        <>
            <IconButton
                iconProps={{ iconName: 'Delete' }}
                title={`${t('delete')}`}
                onClick={(e) => {
                    e.stopPropagation();
                }}
            />
            {props.isOpen && <Dialog
                isOpen={props.isOpen}
                dialogContentProps={{
                    title: props.title,
                    showCloseButton: !props.disable,
                    onDismiss: props.onClose,
                    subText: props.subTitle,
                }}
            >
                <div className={classNames.confirmFooterContainer}>
                    <div className={classNames.confirmButtonContainer}>
                        { props.disable && <Spinner size={SpinnerSize.large}></Spinner>}
                        <div>
                            <DialogFooter>
                                <DefaultButton disabled={props.disable} onClick={props.onClose} text={props.closeLabel} />
                                <PrimaryButton onClick={props.confirmAction} styles={classNames.subComponentStyles.confirmPrimaryButtonDisabled()} text={props.confirmLabel} disabled={props.disable} />
                            </DialogFooter>
                        </div>
                    </div>
                </div>
            </Dialog>}
        </>
    );
}