import { styled } from "@fluentui/react";
import { getStyles } from "./deleteTemplateModal.styles";
import { DeleteTemplateModalBase } from "./deleteTemplateModal.base";
import { IDeleteTemplateModalProps, IDeleteTemplateModalPropsStyles, IDeleteTemplateModalStyles } from "./deleteTemplateModal.types";

const DeleteTemplateModal = styled<IDeleteTemplateModalProps, IDeleteTemplateModalPropsStyles, IDeleteTemplateModalStyles>(
    DeleteTemplateModalBase,
    getStyles,
    undefined,
    { scope: 'DeleteTemplateModal' }
);

export default DeleteTemplateModal;