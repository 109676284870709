import React from 'react'
import { classNamesFunction } from "@fluentui/react";
import { ITeamsSpinnerPropsStyles, ITeamsSpinnerStyles, ITeamsSpinnerProps } from "./teamsSpinner.types";

const getClassNames = classNamesFunction<ITeamsSpinnerPropsStyles, ITeamsSpinnerStyles>();

export const TeamsSpinnerBase = (props: ITeamsSpinnerProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });

    return (
        <div className={classNames.root}>
            <div className={classNames.wrapper}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 60000">
                    <path fill="#5558af" d="M825.32 344.4c31.13-4.11 63.7 8.36 84.34 31.97 26.36 29.4 32.41 75.29 14.09 110.35-14.07 28.05-42.53 48.22-73.69 52.02-30.85 4.35-63.27-7.61-84.13-30.69-27.17-29.31-33.7-75.84-15.23-111.35 14.16-28.4 43.07-48.73 74.62-52.3zm4.06 1075.03c20.93-2.75 42.73 1.53 60.98 12.17 23.23 13.35 40.42 36.75 46.03 62.96 4.99 23.42 1.66 48.74-10.17 69.65-12.48 22.39-34.22 39.4-59 46.03-24.11 6.61-50.76 3.38-72.55-8.92-23.61-13.1-41.2-36.52-47.08-62.88-5.27-23.74-1.92-49.53 10.18-70.72 14.59-26.11 41.9-44.62 71.61-48.29z" />
                    <path fill="#5659b0" d="M822.34 2493.43c21.49-1.85 43.52 3.82 61.45 15.81 25.76 16.95 42.27 46.93 42.78 77.77.6 16.27-4.27 32.02-9.36 47.27-10.61 29.44-36.65 52.55-67.05 59.86-31.08 7.96-65.78-1.19-88.96-23.34-20.81-19.45-32.21-48.48-29.97-76.89.79-12.75 5.01-24.91 8.71-37.03 11.96-34.87 45.67-60.72 82.4-63.45z" />
                    <path fill="#575ab1" d="M794.36 3565.44c33.36-3.16 67.62 13.05 86.42 40.77 19.22 27.38 22.35 65.08 7.6 95.16-6.87 13.45-14.44 26.57-22.92 39.07-19.26 27.25-53.65 42.61-86.8 39.01-31-2.92-59.86-22.21-74.37-49.76-13.15-24.3-14.97-54.39-4.66-80.04 4.66-12.26 13-22.58 18.81-34.27 14.7-28.09 44.33-47.48 75.92-49.94z" />
                    <path fill="#585bb2" d="M744.37 4631.45c36.04-5.89 74.31 11.71 93.51 42.7 21.06 32.34 19.08 77.58-4.92 107.84-15.04 18.19-31.88 34.89-49.83 50.2-26.04 21.66-64.1 27.14-95.28 13.99-29.22-11.79-51.42-39.22-56.76-70.29-5.72-30.4 4.81-63.25 27.28-84.53 10.06-8.92 19.94-18.09 28.68-28.32 14.52-16.94 35.25-28.38 57.32-31.59z" />
                    <path fill="#5a5db4" d="M690.42 5685.47c21.26-2.22 43.23 3.18 61.01 15.08 24.67 16.25 40.66 44.85 41.32 74.42 1.12 29.85-13.26 59.76-37.41 77.37-26.58 19.31-55.34 35.65-85.63 48.38-31.47 12.77-69.57 6.04-94.94-16.48-25.93-22.21-37.69-59.42-29.01-92.48 6.69-27.43 26.64-51.22 52.58-62.42 18.04-7.67 34.94-17.76 50.82-29.22 12.31-8.1 26.59-13.17 41.26-14.65z" />
                    <path fill="#5b5fb6" d="M621.49 6725.71c30.08-3.02 61.14 10.19 79.85 33.93 15.01 18.72 22.23 43.51 19.4 67.36-2.87 26.12-17.64 50.66-39.48 65.29-9.32 6.5-20.08 10.39-30.62 14.39-37.14 13.75-76.18 22.56-115.68 25.56-36.27 2.19-72.29-19.87-87.13-52.98-15.08-31.82-9.36-72.05 14.12-98.31 15.09-17.45 37.01-28.77 59.99-30.88 24.86-2.2 49.39-8.1 72.49-17.56 8.61-3.6 17.76-5.95 27.06-6.8z" />
                    <path fill="#5d60b8" d="M408.36 7743.43c10.7-1.25 21.61-.5 32.06 2.09 26.89 6.57 54.88 8.66 82.45 6.09 11.36-.92 22.61-3.73 34.08-2.83 28.47 1.36 55.67 17.39 70.8 41.52 13.2 20.64 17.32 46.82 11.09 70.52-8.14 32.74-36.25 59.41-69.47 65.52-51.77 8.66-105.15 7.43-156.54-3.17-11.27-2.42-22.76-4.66-33.16-9.84-23.68-11.27-41.7-33.52-47.98-58.96-6.3-24.59-1.37-51.74 13.07-72.61 14.38-21.13 38.2-35.48 63.6-38.33z" />
                    <path fill="#5f63ba" d="M298.39 8699.5c17.69-2.87 36.25-.19 52.35 7.7 6.84 3.24 12.92 7.82 19.45 11.6 33.04 19.72 70.63 31.7 108.98 34.8 30.85 2.76 59.14 23.37 71.49 51.73 10.2 22.6 10.05 49.52-.27 72.05-14.18 32.23-49.2 53.73-84.4 51.45-72.62-5.48-143.68-30.15-204.19-70.64-27.34-18.71-41.95-53.82-36.16-86.42 5.58-36.38 36.31-66.98 72.75-72.27z" />
                    <path fill="#6165bc" d="M213.38 9619.45c30.37-4.61 62.61 8.62 80.84 33.37 30.72 41.47 74.19 73.4 123.13 89.86 21.88 7.29 40.4 23.85 50.12 44.74 14.52 29.79 9.37 67.66-12.42 92.6-21.29 25.37-57.96 36.36-89.65 26.54-83.96-27.39-158.7-81.95-210.69-153.3-17.76-25.22-19.99-60.15-5.86-87.53 12.3-24.7 37.19-42.56 64.53-46.28z" />
                    <path fill="#6467bf" d="M163.48 10511.66c39.56-4.46 79.33 23.37 88.88 61.98 17.43 59.02 56.64 111.34 108.69 144.25 28.65 18.23 43.59 54.98 35.94 88.05-6.69 32.49-34.38 59.27-67.13 64.72-19.27 3.54-39.74.03-56.68-9.81-89.14-55.35-155.95-145.27-183.69-246.42-6.95-28.21 2.39-59.55 23.39-79.56 13.57-13.21 31.76-21.47 50.6-23.21z" />
                    <path fill="#666ac1" d="M151.49 11389.68c20.02-1.84 40.64 3.98 56.64 16.18 22.12 16.44 34.81 44.59 32.26 72.07-5.82 66.89 15.47 135.69 58.25 187.46 7.34 9.31 16.56 17.14 22.45 27.55 14.88 24.6 15.19 57.11.84 82.01-10.92 19.41-30.06 33.99-51.73 39.18-27.08 6.85-57.35-1.44-77.02-21.3-77.92-80.81-120.9-193.71-117.12-305.85.7-14.21.58-28.82 5.41-42.4 9.92-29.94 38.57-52.44 70.02-54.9z" />
                    <path fill="#696cc4" d="M183.44 12269.69c31.43-4.17 64.24 12.72 79.15 40.68 12.76 22.81 13.1 51.9.93 75.03-37.17 75.52-34.57 168.97 6.79 242.28 16.63 28.42 12.7 66.86-9.18 91.42-20.91 24.65-57.51 34.08-87.7 22.42-17.23-6.31-32.09-18.8-41.22-34.72-33.92-60.14-52.58-128.76-53.99-197.78-1.4-63.14 11.61-126.54 37.81-184 3.83-8.23 7.5-16.66 13.05-23.92 12.8-17.34 32.97-28.93 54.36-31.41z" />
                    <path fill="#6b6fc7" d="M258.35 13169.41c32.24-5.79 66.71 11.66 81.26 40.96 15.87 29.78 8.92 69.46-16.25 91.98-47.76 42.23-78.9 102.42-86.43 165.67-4.25 34.24-1.62 69.27 7.48 102.55 7.42 29.05-4.31 61.64-28.31 79.54-19.41 14.94-46.14 19.75-69.52 12.37-24.28-7.3-44.26-27.3-51.31-51.69-20.82-75.43-19.65-156.69 2.92-231.59 22.04-73.83 64.95-141.3 122.63-192.4 10.61-9.08 23.75-15.16 37.53-17.39z" />
                    <path fill="#6e72ca" d="M375.49 14103.71c32.88-4.03 66.67 16.73 77.89 47.9 12.21 30.5.98 68.05-25.78 87.03-6 4.41-12.7 7.83-19.71 10.33-33.88 12.38-65.24 31.65-91.45 56.45-53.79 50.42-84.95 123.86-83.92 197.57.8 25.97-13.19 51.63-35.33 65.17-24.61 15.71-58.44 14.87-82.22-2.08-15.86-10.94-27.22-28.21-30.82-47.15-1.81-8.85-1.4-17.94-1.33-26.92 1.31-72.77 22.27-145.03 59.86-207.34 44.93-75.1 114.3-135.16 195.02-168.97 12.26-4.88 24.48-10.69 37.79-11.99z" />
                    <path fill="#7175cd" d="M481.45 15085.68c13.84-.55 27.74-.78 41.56.33 26.5 2.67 50.43 21.41 59.61 46.37 7.85 20.53 5.63 44.6-5.94 63.3-11.2 18.54-31.1 31.52-52.63 34.13-7.65 1.09-15.37.22-23.05.27-39.46-.05-78.94 8.48-114.74 25.09-73.73 33.73-129.91 102.63-148.66 181.45-6.87 31.26-36.62 55.37-68.62 55.66-21.49.64-43.02-8.88-56.89-25.34-14.62-16.9-20.68-40.79-15.69-62.6 15.02-63.83 45.67-123.87 88.27-173.71 38.57-45.25 86.99-82.07 140.98-106.99 48.82-22.6 102.07-35.52 155.8-37.96z" />
                    <path fill="#7478d0" d="M472.48 16088.71c64.3-4.29 129.6 6.59 188.89 31.9 10.34 4.51 21.08 8.76 29.64 16.35 23.83 19.72 31.5 55.98 17.52 83.61-12.98 28.06-46.08 44.61-76.33 38.18-10.84-1.85-20.33-7.63-30.48-11.51-64.69-26.2-139.54-26.2-204.15.28-58.79 23.74-108.58 68.79-138.25 124.81-11.2 21.88-34.7 36.67-59.26 37.4-29.71 1.6-58.92-18.15-68.49-46.32-6.5-17.9-5.2-38.45 3.57-55.35 30.02-57.21 73.83-107.06 126.54-144.39 61.55-43.82 135.39-70.13 210.8-74.96z" />
                    <path fill="#777bd3" d="M481.48 17090.72c70.72-3.22 142.18 12.35 205.2 44.59 43.66 22.29 83.22 52.54 116.17 88.82 18.47 18.5 24.51 48.02 14.54 72.23-10.5 27.55-40.64 45.73-69.92 42-17.08-1.77-33.17-10.49-44.24-23.58-45.5-50.54-110.53-82.55-178.19-88.64-83.98-8.39-170.46 25.37-227.33 87.56-13.64 15.88-34.91 24.78-55.8 23.12-22.61-1.39-43.86-15.22-54.47-35.2-13.34-24.04-9.75-56.06 8.74-76.44 72.2-80.37 177.33-130 285.3-134.46z" />
                    <path fill="#7a7ed6" d="M475.48 18093.69c74.46-4.7 150.03 11.91 215.81 47.06 85.44 45.01 153.45 121.74 188.09 211.87 3.86 10.55 8.54 21.07 9.33 32.43 1.51 17.93-4.97 36.3-17.3 49.38-12.52 13.51-31.06 21.13-49.46 20.42-21.09-.59-41.46-12.29-52.56-30.24-5.64-8.63-7.86-18.82-11.88-28.19-21.22-53.19-59.36-99.47-107.74-130.15-33.73-21.54-72.09-35.86-111.74-41.32-65.6-9.29-134.18 6.29-189.61 42.47-15.02 10.22-34.4 13.66-52 9.14-18.6-4.52-34.73-17.84-42.8-35.19-13.43-27.13-4.21-62.83 20.68-80.04 59.46-39.88 129.74-63.31 201.18-67.64z" />
                    <path fill="#7d81da" d="M478.3 19096.25c69.34-3.83 139.62 10.59 201.76 41.66 59.86 29.82 112.2 74.63 150.67 129.35 54.82 77.52 80.92 174.86 71.87 269.39-3.49 28.39-28.96 51.9-57.52 53.15-18.76 1.29-37.84-6.54-50.24-20.67-11.21-12.48-16.96-29.65-15.49-46.37 3.43-42.77-3.02-86.31-18.84-126.2-22.42-57.25-64.63-106.27-117.47-137.59-68.82-41.53-155.83-50.5-231.89-24.78-18.26 6.79-39.61 4.35-55.78-6.55-17.13-11.14-27.87-31.22-27.76-51.64-.41-26.37 17.98-51.56 43.23-59.19 34.67-11.68 70.93-18.61 107.46-20.56z" />
                    <path fill="#8084dd" d="M482.49 20098.74c73.92-3.29 148.64 14.35 213.23 50.46 53.92 29.98 100.58 72.75 135.48 123.6 36.97 53.63 60.47 116.43 67.81 181.15 9.49 82.01-6.83 166.97-46.89 239.21-14.69 25.05-49 35.5-75.15 22.86-23.74-10.36-38.38-37.53-34.01-63.06 1.54-12.74 9.26-23.31 14.22-34.81 28.51-61.85 33.63-134.07 13.99-199.29-19.19-64.87-62.77-122.03-119.94-158.11-50.82-32.31-112.18-47.42-172.2-42.94-19.46 1.71-39.38-7.05-51.34-22.48-12.56-15.67-16.13-37.91-9.21-56.75 6.49-18.4 22.61-33.01 41.6-37.53 7.32-1.85 14.92-1.86 22.41-2.31z" />
                    <path fill="#8387e0" d="M550.37 21107.48c6.41-1.19 13.02-1.26 19.46-.2 66.1 11.66 129.08 40.41 181.23 82.66 47.79 38.56 86.28 88.45 111.75 144.31 30.18 65.62 41.91 139.55 33.49 211.29-11.81 105.69-67.88 205.72-152.55 270.21-15.75 11.54-37.6 13.81-55.44 5.93-16.56-7.03-29.2-22.46-32.85-40.06-3.68-16.77.82-35.09 11.92-48.19 5.27-6.49 12.43-10.96 18.61-16.48 46.27-39.41 79.1-94.12 92.54-153.36 9.13-39.78 9.65-81.53 1.33-121.49-12.77-61.87-46.69-119.18-94.93-159.99-38.04-32.34-84.75-54.26-133.84-63.2-17.12-2.85-32.53-14.11-40.48-29.53-8.29-15.51-8.57-34.89-.92-50.7 7.58-16.09 23.18-28.03 40.68-31.2z" />
                    <path fill="#868be4" d="M637.47 22135.65c9.35-1.04 18.97.44 27.58 4.27 58.19 26.58 109.74 67.54 148.77 118.23 40.14 51.91 66.8 114.03 77.27 178.78 11.09 67.55 4.03 137.93-20.11 201.97-23.41 62.55-62.87 119.02-113.68 162.38-46.49 39.85-102.45 68.48-161.88 83.15-16.86 4.03-35.45-.93-48.19-12.65-12.66-11.35-19.27-28.96-17.27-45.83 1.82-17.17 12.5-33.09 27.72-41.26 8.34-4.73 18.01-5.92 26.98-8.95 58.22-17.76 110.39-54.58 146.57-103.53 40.04-53.6 60.66-121.39 56.75-188.22-3.54-66.36-30.8-131.22-76.12-179.86-23.41-25.29-51.42-46.29-82.2-61.79-6.54-3.37-13.57-5.97-19.34-10.65-14.96-11.42-23.01-31.09-20.33-49.72 2.82-23.92 23.49-44.09 47.48-46.32z" />
                    <path fill="#8a8ee7" d="M712.3 23184.24c13.7-1.28 27.74 3.36 38.08 12.41 50.79 41.84 90.83 96.61 115.11 157.78 28.73 71.94 35.43 152.44 19.16 228.16-12.93 60.68-40.66 118.09-79.93 166.1-39.61 48.61-90.99 87.58-148.58 112.42-70.15 30.43-149.29 39.59-224.57 26.29-16.34-2.93-30.72-14.48-37.25-29.71-6.8-15.21-5.35-33.7 3.63-47.72 8.54-13.65 23.86-22.77 39.95-23.66 8.45-.65 16.69 1.77 25.04 2.63 65.75 8.5 134.26-6.32 190.48-41.49 56.84-35.17 100.87-90.47 122.29-153.79 22.21-64.65 20.46-137.12-4.73-200.66-18.16-46.24-48.59-87.48-87.08-118.86-15.1-12.36-21.73-33.84-16.36-52.59 5.26-20.15 23.97-35.79 44.76-37.31z" />
                    <path fill="#8d91ea" d="M767.37 24250.47c17.44-3.43 36.43 3.77 47.11 18 38.59 52.2 63.79 114.2 72.52 178.53 10.58 77.02-2.5 157.13-37.14 226.74-26.73 53.97-66.27 101.49-114.33 137.76-53.33 40.41-117.17 66.85-183.52 75.64-94.86 12.84-194.33-10.48-273.01-65.16-13.05-9.83-20.29-26.67-18.6-42.91 1.51-17.2 13.08-33.02 29.01-39.67 14.96-6.55 33.16-4.72 46.52 4.69 59.7 40.49 134.71 57.41 206.04 46.9 66.33-9.42 128.87-42.55 174.19-91.85 44.71-48.13 72.28-111.69 77.07-177.19 5.53-68.8-14.63-139.14-55.37-194.8-10.27-13.29-12.75-32.03-6.26-47.52 5.97-14.95 19.93-26.31 35.77-29.16z" />
                    <path fill="#9095ee" d="M811.38 25328.45c15.79-2.48 32.56 4.21 42.35 16.83 5.47 6.7 7.95 15.12 10.98 23.08 24.71 67.99 29.79 142.96 14.29 213.64-13.65 62.84-43.01 122.27-85.04 170.98-44.3 51.57-102.47 90.99-166.68 113.27-69.47 24.33-145.95 28.23-217.49 10.87-99.69-23.52-189.14-88.25-241.4-176.47-10.9-20.5-2.63-48.33 17.66-59.58 20.07-12.44 48.97-5.51 61.22 14.68 31.9 52.05 80.28 93.78 136.59 117.43 40.01 16.99 83.77 25.09 127.22 23.39 66.68-2.4 132.17-28.22 182.67-71.82 46.63-39.9 80.13-94.73 94.64-154.34 13.5-54.37 10.88-112.55-7.21-165.56-3.53-11.07-9.68-21.78-8.55-33.8.72-21.04 17.87-39.9 38.75-42.6z" />
                    <path fill="#9398f1" d="M832.38 26414.49c17.72-3.31 36.75 6.16 44.92 22.19 4.65 8.37 4.98 18.12 5.99 27.41 6.55 68.26-5.69 138.22-35.11 200.17-26.88 56.87-67.75 107.13-118.3 144.63-55.66 41.5-122.78 67.36-191.86 74.18-68.42 6.96-138.64-4.95-200.87-34.23-55.51-25.96-104.81-65.22-142.15-113.84-39-50.54-65.03-110.96-75.09-174-1.57-9.28-3.17-19.01-.15-28.19 5.45-19.76 26.54-33.38 46.8-30.13 18.43 2.29 34.11 17.91 36.35 36.36 8.42 64.41 38.26 125.87 84.18 171.88 48.23 48.76 113.62 80.18 181.9 87.01 67.54 7.11 137.28-9.69 194.2-46.74 52.23-33.69 93.3-84.19 115.92-142.06 18.48-46.82 24.56-98.35 17.99-148.22-2.79-21.62 13.75-43.29 35.28-46.42z" />
                    <path fill="#969bf5" d="M164.37 27362.49c14.76-3.07 30.92 3.17 39.76 15.39 7.66 10.17 9.88 24.05 5.92 36.14-20.91 70.12-15.22 147.77 15.91 214.01 28.34 61.04 77.88 111.84 138.03 141.98 62.53 31.66 136.2 40.34 204.42 24.39 61.91-14.12 118.88-48.58 160.34-96.65 36.55-42 61-94.34 69.75-149.33 1.46-7.97 1.43-16.39 5.12-23.79 6.72-14.81 23.17-24.43 39.38-22.86 16.58 1.08 31.5 13.69 35.43 29.81 2.52 8.83.56 17.98-.69 26.83-9.23 60.43-33.3 118.53-69.58 167.74-39.04 53.11-91.9 96.05-152.15 122.86-67.25 30.14-143.31 40.07-216.07 28.46-60.79-9.54-119.14-34.12-168.38-71.02-53.91-40.21-97.08-94.77-123.3-156.74-35.02-81.9-40.11-176.19-13.82-261.31 4.44-13.15 16.32-23.34 29.93-25.91z" />
                    <path fill="#999ef8" d="M233.37 28239.51c14.94-3.42 31.44 3.62 39.38 16.71 8.58 13.39 7.15 32.19-3.47 44.06-40.67 46.78-66.3 106.29-72.69 167.93-4.75 44.65.5 90.34 15.42 132.7 20.54 58.7 59.56 110.75 110.17 146.9 55.47 39.92 124.64 60.21 192.89 56.77 62.51-2.85 123.84-25.8 173.06-64.41 38.02-29.59 68.71-68.43 88.87-112.18 7.86-17.19 29.91-25.66 47.29-18.22 18.58 6.9 28.55 30.01 20.73 48.25-26.55 58.74-68.77 110.21-120.82 148.19-50.46 36.99-110.14 61.35-172.15 69.79-72.58 10.09-147.96-1.5-214.19-32.82-56.54-26.55-106.21-67.46-143.19-117.79-41.11-55.56-66.71-122.52-72.71-191.41-9.46-102.1 24.57-207.57 92.76-284.26 5.02-5.15 11.62-8.71 18.65-10.21z" />
                    <path fill="#9ca1fb" d="M350.42 29155.55c16.06-2.5 32.84 7.97 37.6 23.52 5.62 15.94-2.55 35.1-17.95 42.05-55.71 25.74-103.23 68.7-134.44 121.53-35.45 59.36-50.15 130.87-40.47 199.37 9.28 67.78 41.97 132.1 91.39 179.42 48.72 47.05 113.36 77.19 180.71 84.36 85.9 9.71 175.18-19.29 239.06-77.5 11.76-11.68 32-12.9 45.02-2.62 12.75 9.34 17.36 27.84 10.52 42.09-3.34 7.85-10.32 13.07-16.54 18.53-50.3 43.17-112.22 72.53-177.39 84.53-67.12 12.47-137.57 5.95-201.36-18.31-58.39-22.06-111.18-58.81-151.93-106.11-46.33-53.55-76.93-120.45-87.46-190.45-11.02-72.08-.19-147.22 30.34-213.4 35.02-76.79 96.7-141.04 172.18-178.85 6.7-3.17 13.23-7.22 20.72-8.16z" />
                    <path fill="#9fa4fe" d="M474.27 30126.27c9.51-.24 19.36-2.16 28.64.74 14.15 4.34 23.95 19.32 22.14 34.03-1.26 15.6-15.34 28.79-31 28.96-60.92 1.23-121.38 20.75-171.28 55.77-55.63 38.71-97.86 96.18-118.25 160.81-20.92 65.33-19.07 137.61 5.1 201.81 23.13 62.47 67.25 116.8 123.41 152.58 74.27 48.16 169.57 61.8 254.39 36.46 8.13-2.07 15.95-6.26 24.57-5.65 13.02.47 25.16 9.65 29.08 22.1 4.37 12.52-.13 27.42-10.62 35.51-5.19 4.28-11.81 5.97-18.08 7.98-67.21 21.12-140.41 23.02-208.5 4.76-66.7-17.67-128.21-54.26-175.67-104.32-43.83-45.99-75.54-103.39-91.11-164.98-17.03-66.39-15.54-137.46 4.8-202.95 20.08-65.37 58.49-124.95 109.67-170.29 61.19-54.66 140.9-87.81 222.71-93.32z" />
                    <path fill="#9ca1fb" d="M478.46 31123.67c56.44-3.18 113.65 6.26 165.83 28.06 16.18 6.92 24.57 27.26 18.02 43.59-6.05 17.62-27.66 27.38-44.87 20.21-66.53-27.58-142.81-30.63-211.33-8.44-64.75 20.65-122.02 63.58-160.24 119.75-38.75 56.37-57.58 125.97-52.82 194.19 4.26 66.62 31.32 131.41 75.39 181.51 51.24 59.01 125.72 97.22 203.65 103.83 18.43.74 33.97 18.17 32.41 36.59-.57 18.09-17.38 33.59-35.47 32.62-66.39-5.12-131.19-28.68-185.6-67.03-53.05-37.23-96.25-88.36-124.03-146.93-30.5-63.82-42.13-136.36-33.66-206.55 7.35-62.66 30.91-123.31 67.76-174.52 35.6-49.72 83.46-90.57 138.17-117.88 45.59-22.84 95.88-36.19 146.79-39z" />
                    <path fill="#999ef8" d="M468.49 32121.71c110.98-9.63 225.6 32.49 302.76 113.06 14.56 16.78 9.08 45.56-10.59 55.86-10.81 6.2-24.75 6.38-35.73.49-7.09-3.61-11.97-10.08-17.85-15.22-48.45-45.12-112.08-73.39-177.97-79.54-67.19-6.66-136.31 10.19-193.12 46.62-51.95 32.99-93.51 82.07-117.22 138.88-17.1 40.66-25.34 85.05-23.74 129.15 2.08 61.32 23.46 121.71 60.07 170.9 25.63 34.6 58.67 63.69 96.31 84.62 15.13 8 22.89 27.2 17.59 43.48-4.39 15.13-19.26 26.29-35.02 26.31-7.75.24-15.29-2.4-21.82-6.44-55.14-31.32-102.1-76.8-135.28-130.83-35.31-57.18-55.1-123.86-56.37-191.07-1.53-72.54 18.23-145.41 56.34-207.16 31.79-51.72 76.33-95.48 128.46-126.57 49.45-29.61 105.71-47.81 163.18-52.54z" />
                    <path fill="#969bf5" d="M473.47 33118.67c72.22-5.26 145.7 10.94 209.22 45.62 68.05 36.77 124.39 94.85 158.79 164.14 5.71 12.33 4.31 27.53-3.37 38.71-7.61 11.43-21.46 18.33-35.16 17.46-14.06-.62-27.37-9.32-33.55-21.96-26.27-51.87-67.92-95.79-118.41-124.63-35.2-20.26-74.52-33.36-114.86-38.17-68.64-8.39-139.89 7.91-198.2 45.03-48.65 30.67-88.03 75.67-112.21 127.83-17.95 38.5-27.66 80.82-28.15 123.31-.96 66.12 20.65 132.23 60.24 185.17 12.12 15.94 9.26 40.73-6.14 53.52-15.94 14.6-43.46 12.41-56.83-4.58-43.32-57.09-69.74-126.74-75.74-198.12-5.76-66.51 6.55-134.41 35.08-194.74 26.34-56.15 66.54-105.75 116.22-142.89 58.56-44.04 129.99-70.64 203.07-75.7z" />
                    <path fill="#9398f1" d="M477.48 34115.72c68.94-4.17 138.93 10.66 200.13 42.69 54.55 28.44 102.24 70.02 137.56 120.41 28.96 41.21 49.67 88.19 60.54 137.37 3.2 14.75-2.35 30.9-13.79 40.71-11.03 9.75-27.29 13.06-41.25 8.37-13.88-4.43-25.02-16.43-28.24-30.66-7.62-34-21.12-66.71-39.91-96.06-33.27-52.18-83.22-93.45-140.65-116.46-67.03-27.18-143.93-28.63-212.03-4.35-86.03 29.81-156.05 101.33-184.3 187.85-17.1 51.24-19.71 107.1-7.97 159.79 3.99 18.04-5.5 37.85-21.93 46.21-18.51 10.25-43.87 3.82-55.3-13.96-5.67-7.97-6.95-17.87-8.8-27.21-12.78-68.3-6.64-140.04 17.76-205.12 23.63-63.43 64.45-120.29 116.78-163.2 62.2-51.25 140.96-81.81 221.4-86.38z" />
                    <path fill="#9095ee" d="M481.47 35112.7c69.61-3.36 140.06 12.41 201.5 45.35 58.1 30.96 107.84 77.21 143.41 132.57 42.49 65.82 64.16 144.85 61.06 223.14-1.04 15.27-10.51 29.56-24.08 36.61-13.93 7.51-31.79 6.9-45.17-1.56-13.52-8.25-22.06-23.94-21.48-39.79 1.27-41.52-6.18-83.31-22-121.73-23.41-57.2-65.25-106.62-117.77-139.2-58.4-36.53-129.76-51.57-197.96-41.96-63.69 8.62-124.11 38.87-169.38 84.46-40.31 40.18-68.42 92.31-80.03 148.01-2.8 15.41-14.18 28.84-28.91 34.15-15.1 5.74-33.12 2.51-45.29-8.09-12.11-10.21-18.18-27.04-15.18-42.62 13.49-66.71 45.1-129.64 90.55-180.31 68.22-76.87 167.95-124.75 270.73-129.03z" />
                    <path fill="#8d91ea" d="M474.27 36110.23c70.46-4.69 142.05 10.3 204.79 42.68 53.49 27.46 100.45 67.53 135.89 116.11 39.23 53.57 64.7 117.19 72.72 183.13 6.51 52.7 2.11 106.73-12.91 157.66-4.76 15.9-18.41 28.63-34.53 32.47-16.55 4.23-35.1-1.26-46.67-13.81-11.45-12.01-15.96-30.14-11.28-46.09 11.53-39.62 14.77-81.65 9.1-122.54-8.61-63.25-38.66-123.3-83.99-168.22-42.14-42.06-97.4-70.62-156-80.98-44.56-8-90.97-5.52-134.4 7.31-57.86 16.94-110.28 52.09-147.59 99.48-5.25 6.38-9.44 13.76-16.04 18.91-13.16 10.88-32.26 13.84-48.09 7.45-15.53-5.98-27.34-20.5-29.92-36.95-2.59-14.27 2.12-29.14 11.36-40.14 66.92-90.79 174.98-149.59 287.56-156.47z" />
                    <path fill="#8a8ee7" d="M470.49 37107.71c69.66-5.42 140.63 8.47 203.25 39.42 57.49 28.27 107.94 70.75 145.37 122.76 41.17 56.95 66.43 125.17 72.72 195.14 7.12 77.35-9.66 156.63-47.23 224.6-4.29 7.49-8.14 15.46-14.46 21.51-11.59 11.59-29 16.94-45.11 13.86-15.85-2.83-30.02-13.66-36.83-28.26-7.2-14.9-6.5-33.31 1.95-47.56 19.49-32.79 32.62-69.37 38.13-107.13 10.08-67.51-4.93-138.29-41.16-196.1-33.36-53.73-84.84-95.83-143.98-118.16-79.63-30.83-172.61-23.63-246.7 18.73-14.67 9.21-33.99 10.32-49.51 2.58-16.71-7.92-28.18-25.58-28.65-44.06-.81-18.3 9.14-36.59 25.03-45.74 50.94-29.41 108.55-47.11 167.18-51.59z" />
                    <path fill="#868be4" d="M474.48 38104.71c69.41-4.62 139.96 9.49 202.15 40.68 59.86 29.82 111.94 75.06 149.83 130.17 36.11 52.28 59.21 113.43 66.74 176.52 8.7 70.51-1.99 143.31-30.91 208.22-20.11 45.4-48.81 86.98-84.22 121.79-12.45 12.06-30.97 17.3-47.92 13.7-18.49-3.61-34.4-17.76-40.21-35.67-6.6-19.14-1.14-41.68 13.52-55.65 45.2-44.84 75.04-104.95 82.77-168.21 5.62-44.64.62-90.58-14.5-132.96-20.73-58.59-61.15-109.89-113.06-144.02-50.86-33.79-112.68-50.63-173.65-47.34-9.04.21-18.06 2.25-27.09.96-15.6-1.93-30.05-11.18-38.44-24.45-9.05-14.01-10.96-32.31-5-47.88 5.36-14.4 17.17-26.22 31.61-31.51 9.03-3.49 18.85-3.54 28.38-4.35z" />
                    <path fill="#8387e0" d="M597.38 39118.45c8.41-1.27 17.11-.62 25.23 1.93 57.89 18.54 111.33 50.72 154.92 93.07 49.41 47.84 86.16 108.62 105.41 174.65 21.07 71.54 21.17 148.98.86 220.7-27.52 98.92-95.3 185.71-184.41 236.65-17.1 9.58-39.17 9.12-55.94-.97-15.41-8.99-25.97-25.68-27.51-43.45-1.71-17.34 5.17-35.27 18.09-46.97 5.99-5.66 13.57-9.13 20.35-13.68 50.07-32.08 89.81-80.09 111.45-135.52 16.39-41.73 22.87-87.32 18.67-131.95-5.46-59.19-30.26-116.29-69.43-160.96-33.6-38.57-77.74-67.88-126.44-83.6-16.11-5.02-29.55-17.69-35.56-33.46-6.26-15.78-4.75-34.32 3.84-48.94 8.41-14.67 23.75-25.04 40.47-27.5z" />
                    <path fill="#8084dd" d="M721.46 40185.6c16.08-1.85 32.82 3.25 45.07 13.86 49.4 43.66 87.57 99.82 110.33 161.67 23.71 64.06 30.71 134.24 19.87 201.7-11.06 69.56-40.61 136.13-85.15 190.72-52.32 64.48-125.19 112.01-205.35 133.74-18.96 4.67-39.97-1-54.13-14.4-14.74-13.55-21.68-34.89-17.76-54.52 3.94-21.6 20.86-40.16 42.08-45.91 59.73-16.74 113.7-53.54 150.62-103.45 42.26-56.69 62.35-129.55 54.3-199.87-7.43-68.87-41.25-134.44-93.02-180.45-17-14.9-23.9-39.96-17.07-61.49 6.53-22.21 27.14-39.35 50.21-41.6z" />
                    <path fill="#7d81da" d="M804.35 41295.41c25.15-3.05 51.24 11.12 62.22 33.97 31.42 67.12 43.64 143.03 35.2 216.65-7.41 65.94-31.64 129.84-69.47 184.33-34.48 49.93-80.4 91.89-133.28 121.66-56.9 32.19-121.7 50.26-187.05 52.18-21.53 1.69-43.5-8.6-55.71-26.45-12.15-17.1-14.56-40.5-6.23-59.74 9.13-22.12 31.97-37.65 55.93-37.79 65.99-1.37 131.26-26.75 180.45-70.84 47.88-42.61 80.55-102.03 90.45-165.38 8.06-50.46 1.8-103.05-17.55-150.31-2.91-7.43-6.89-14.47-8.63-22.32-4.18-17.27-.36-36.23 10.09-50.59 10.07-14.12 26.35-23.53 43.58-25.37z" />
                    <path fill="#7a7ed6" d="M832.34 42428.37c30.4-5.35 62.45 14.49 71.42 43.97 3.86 11.14 3.24 23.07 3.18 34.67-1.02 64.55-17.71 128.78-48.32 185.62-31.56 58.8-77.9 109.55-133.46 146.5-93.55 62.71-213.9 83.67-323.07 55.83-31.89-9.14-52.35-45.37-43.5-77.4 7.3-32.7 42.89-55.3 75.6-47.79 69.49 16.92 145.39 6.05 206.89-30.62 53.15-31.35 95.41-80.85 117.68-138.44 13.6-34.81 19.75-72.38 18.76-109.71-.04-30.55 24.57-58.57 54.82-62.63z" />
                    <path fill="#777bd3" d="M810.47 43560.67c20.12-2.27 41.03 5.22 55.16 19.71 15.02 15.11 22.14 37.61 18.39 58.61-1.4 8.88-5.25 17.09-8.74 25.3-24.01 55.11-60.42 104.75-105.76 144.22-52.43 45.76-116.59 78.03-184.72 92.32-93.71 19.98-194.38 5.74-278.62-39.99-28.09-16.05-40.63-53.75-27.66-83.41 7.14-17.6 22.09-31.78 40.06-37.92 17.72-6.31 37.99-4.61 54.46 4.43 62.34 32.55 137.33 40.03 204.76 19.87 73.7-21.54 137.34-75.35 170.14-144.86 5.13-9.99 8.05-21.11 14.75-30.26 10.94-15.62 28.83-26.06 47.78-28.02z" />
                    <path fill="#7478d0" d="M731.35 44672.42c31.67-5.89 65.45 13.44 76.61 43.59 9.75 24.37 4.17 53.99-13.98 72.99-46.89 48-105.92 83.88-169.88 104.08-65.99 21-137.33 24.86-205.2 11.09-73.56-14.78-143.09-49.88-198.11-100.96-24.52-23.65-27.07-65.61-5.62-92.08 19.67-26.43 58.93-34.92 87.8-19.04 10.12 5.03 17.7 13.6 26.55 20.4 49.71 39.92 113.7 61.68 177.47 59.89 69.02-1.61 136.99-30.74 185.66-79.72 10.33-10.63 24.07-17.88 38.7-20.24z" />
                    <path fill="#7175cd" d="M204.36 45617.43c22.49-3.33 46.31 4.72 62.09 21.11 6.62 6.61 11.07 14.93 16.77 22.28 37.58 50.74 93.44 87.59 154.95 101.95 55.46 13.27 114.93 7.8 167.42-14.27 30.6-12.13 68.31.3 85.88 28.07 11.67 17.61 15.05 40.43 8.81 60.64-5.96 20.17-21.18 37.36-40.56 45.57-72.59 30.31-153.88 39.45-231.37 25.78-109.69-18.82-210.33-84.14-272.24-176.61-13.85-18.88-17.79-44.53-9.89-66.62 8.44-25.17 31.84-44.39 58.14-47.9z" />
                    <path fill="#6e72ca" d="M167.42 46538.5c33.31-4.76 68 16.1 79.33 47.78 19.1 55.9 57.18 105.05 106.61 137.39 43.47 28.76 95.57 44.06 147.67 43.75 29.1-.2 57.05 18.48 68.25 45.28 8.86 20.34 7.97 44.62-2.4 64.23-9.93 19.2-28.54 33.64-49.64 38.36-11.22 2.7-22.85 1.82-34.27 1.53-71.9-2.83-142.95-24.65-203.91-62.9-74.78-46.58-134.13-117.44-166.63-199.34-4.15-10.93-8.97-21.84-10.14-33.59-2.26-19.67 3.81-40.08 16.32-55.4 11.97-14.89 29.87-24.75 48.81-27.09z" />
                    <path fill="#6b6fc7" d="M147.37 47453.44c20.87-3.27 42.93 2.58 59.44 15.76 16.81 13.13 27.65 33.5 29.17 54.78 5.47 62.16 34.02 121.84 78.6 165.44 24.87 24.5 54.71 43.88 87.16 56.78 25.84 10.55 44.51 36.19 46.87 63.98 2.4 24.06-7.4 48.93-25.55 64.9-20.68 18.71-51.84 24.75-77.95 14.84-62.24-24.51-118.3-64.39-162.06-114.96-58.54-67.4-94.99-153.88-101.36-242.99-1.9-37.83 28.14-73.69 65.68-78.53z" />
                    <path fill="#696cc4" d="M152.37 48366.41c20.5-2.53 41.9 3.28 58.21 15.97 18.66 14.27 30.42 37.13 30.97 60.64.47 10.57-2.3 20.88-3.11 31.36-7.38 72.84 17.6 148.16 66.94 202.22 7.88 9.11 17.71 16.53 24.21 26.82 15.89 23.63 17.43 55.92 3.9 80.98-9.87 18.77-27.52 33.29-47.92 39.16-21.87 6.52-46.5 3.02-65.62-9.48-8.05-5.08-14.64-12.03-21.3-18.73-41.96-42.79-74.59-94.65-95.05-150.97-22.47-61.52-30.34-128.31-22.7-193.37 1.66-13.03 2.91-26.43 8.61-38.46 11.06-24.92 35.78-43.05 62.86-46.14z" />
                    <path fill="#666ac1" d="M176.38 49283.44c32.27-4.03 65.81 13.46 80.96 42.22 12.29 22.23 13.21 50.17 2.54 73.2-28.56 66.81-26.84 145.55 3.98 211.28 14.51 30.86 6.61 70.19-18.49 93.22-16.24 15.36-38.99 23.62-61.31 22.06-27.63-1.58-53.8-18.25-66.73-42.74-53.34-110.06-55.32-243.66-4.75-355.07 12.05-24.22 36.92-41.34 63.8-44.17z" />
                    <path fill="#6467bf" d="M216.34 50209.42c32.73-4.89 67.18 11.95 83.62 40.61 17.07 28.3 15.05 66.61-5.13 92.83-34.4 44.63-53.27 100.82-53.18 157.14-.23 12.04 1.89 24.15-.27 36.12-3.93 25.85-20.82 49.25-44.04 61.23-21.41 11.31-47.79 12.63-70.21 3.48-27.91-10.97-48.73-37.91-51.99-67.76-8.61-104.39 23.32-211.56 87.87-294.07 13.22-16.11 32.68-26.86 53.33-29.58z" />
                    <path fill="#6165bc" d="M276.47 51147.66c19.83-2.12 40.34 2.9 56.88 14.04 22.9 15.16 37.68 41.82 38.12 69.32.81 26.19-11.18 52.39-31.64 68.79-35.94 28.6-63.79 67.16-79.82 110.19-4.64 12.01-7.14 24.94-14.15 35.93-16.4 27.91-49.58 44.73-81.8 41.3-29.87-2.57-57.49-22.05-69.87-49.36-8.52-18.16-10.36-39.35-5.03-58.69 24.49-84.11 75.61-160.28 144.43-214.53 12.38-9.45 27.39-15.39 42.88-16.99z" />
                    <path fill="#5f63ba" d="M344.36 52102.45c30.8-4.19 63.2 9.54 81.57 34.62 13.82 18.45 20 42.45 16.54 65.27-3.98 28.65-23.11 54.65-49.4 66.78-38.3 17.62-71.97 45.04-97.08 78.89-13.99 18.81-35.84 31.49-59.12 34.34-33.5 4.73-68.63-12.2-86.03-41.16-18.08-28.77-17.17-68.05 2.47-95.82 41.28-56.64 96.85-102.72 160.04-133.02 9.72-4.93 20.15-8.59 31.01-9.9z" />
                    <path fill="#5d60b8" d="M420.39 53075.48c32.67-5.1 67.26 9.95 86.06 37.09 19.33 26.89 21.78 64.68 5.89 93.78-12.26 23.38-35.34 40.71-61.27 45.8-33 6.43-64.62 19.63-92.48 38.45-26.87 17.84-63.47 19.12-91.64 3.48-28.8-15.34-47.61-47.44-46.77-80.09.24-28.42 14.96-56.26 38.46-72.3 48.61-32.83 104.02-55.7 161.75-66.21z" />
                    <path fill="#5b5fb6" d="M481.47 54066.7c13.86-.49 27.76-.67 41.59.39 30.47 2.78 58.62 22.25 72.25 49.6 14.29 27.64 12.88 62.6-3.59 89-13.85 22.79-38.09 38.91-64.51 42.65-10.67 1.79-21.48.4-32.22.73-20.38.35-40.7 3.29-60.39 8.56-26.69 6.94-56.3.71-78.03-16.22-23.93-18.1-37.62-48.61-35.41-78.51 1.84-30.68 20.42-59.68 47.54-74.15 11.39-6.41 24.28-9.19 36.89-12.08 24.93-5.57 50.36-8.9 75.88-9.97z" />
                    <path fill="#5a5db4" d="M506.33 55066.39c6.82-1.03 13.74-1.24 20.62-.78 31.79 2.02 63.39 7.45 93.96 16.43 29.24 8.94 52.94 33.44 61.19 62.85 8.98 30.21.96 64.67-20.32 87.88-19.05 21.29-48.31 32.92-76.79 30.29-11.31-.73-21.96-4.86-32.99-7.06-13.69-3.03-27.66-4.55-41.63-5.46-33.51-3.14-64-26.28-76.33-57.55-12.83-30.96-6.97-68.64 14.75-94.17 14.38-17.35 35.24-29.17 57.54-32.43z" />
                    <path fill="#585bb2" d="M610.41 56086.48c15.27-1.87 30.98.15 45.31 5.73 20.35 7.84 40.19 17.07 59.1 27.94 27.32 16.21 44.97 47.12 45.28 78.87.88 33.02-17.27 65.66-45.75 82.38-27.78 16.92-64.44 17.88-92.97 2.16-12.05-6.82-24.75-12.36-37.64-17.38-29.31-12.53-50.89-41.01-55.29-72.56-3.76-24.8 3.08-50.92 18.34-70.8 15.16-20.03 38.68-33.41 63.62-36.34z" />
                    <path fill="#575ab1" d="M695.37 57133.44c24.71-3.35 50.6 3.59 70.31 18.87 11.37 8.87 22.67 17.93 32.88 28.14 23.67 24.52 32.14 62.23 21.46 94.56-9.59 30.63-35.73 55.36-66.89 63.11-29.72 7.87-63 .16-86.09-20.17-6.97-6.43-14.49-12.18-21.83-18.16-25.09-21.37-37.35-56.4-31.27-88.77 6.58-39.91 41.22-72.98 81.43-77.58z" />
                    <path fill="#5659b0" d="M764.46 58197.63c31.17-2.95 63.31 10.52 82.9 34.98 8.1 10.54 16.41 21.22 21.43 33.66 11.89 28.06 9.12 61.68-7.05 87.49-15.81 25.84-44.47 43.27-74.72 45.24-27.03 2.08-54.72-7.94-74.04-26.97-7.61-7.21-13.27-16.1-19.47-24.48-18.92-27.12-21.98-64.18-8.04-94.13 13.82-30.97 45.17-53.15 78.99-55.79z" />
                    <path fill="#5558af" d="M801.4 59271.5c31.25-4.83 64.39 7.05 85.53 30.53 14.66 16.16 23.74 37.3 25.56 59.02 2.34 27.92-8.29 56.54-28.23 76.21-16.71 16.69-39.73 26.85-63.31 27.99-26.89 1.46-54.11-9.02-73.12-28.08-15.67-15.59-25.63-36.67-28.39-58.55-3.51-28.21 6.34-57.67 26.02-78.16 14.72-15.53 34.77-25.85 55.94-28.96z" />
                </svg>
            </div>
        </div>
    );
}