/*eslint-disable @typescript-eslint/no-non-null-assertion*/
import React, { useEffect, useState } from "react";
import { ActionButton, Checkbox, classNamesFunction, DefaultButton, DetailsList, IColumn, PrimaryButton, SearchBox, SelectionMode, Spinner, SpinnerSize } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import EdiModal from "../../../../common/components/ediModal/ediModal";
import { IAssociateLabelModalProps, IAssociateLabelModalPropsStyles, IAssociateLabelModalStyles } from "./associateLabelModal.types";
import { useAsyncApi, useOnMount } from "../../../../utilities/hooks";
import TeamsImage from "../../../../common/components/teamsImage/teamsImage";
import { ImageName } from "../../../../common/components/teamsImage/teamsImage.types";
import { LabelAssociate } from "../../../../models/labelView";
import IconTag from "../../../../common/components/iconTag/iconTag";
import { workflowLabApi } from "../../../services/workFlowLab/workFlowLab.api";
import { ErrorsType } from "../../../../common/types/ErrorsType";

const getClassNames = classNamesFunction<IAssociateLabelModalPropsStyles, IAssociateLabelModalStyles>();

export const AssociateLabelModalBase = (props: IAssociateLabelModalProps) => {
    const classNames = getClassNames(props.styles,
        {
            theme: props.theme,
            className: props.className
        });
    const { t } = useTranslation(['workflowLabManageUsers', 'common']);
    const [keyphrase, setKeyphrase] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [firstRender, setFirstRender] = useState<boolean>(true);
    const [selectedIdLabels, setSelectedIdLabels] = useState<number[]>([]);
    const [allWorkflowLabLabels, setAllWorkflowLabLabels] = useState<LabelAssociate[]>([]);

    const { execute: getUserLabelsWithScope, value: currentLabels, loading: loadingUserLabels, error: errorUserLabels } = useAsyncApi<string, LabelAssociate[]>({
        func: async (keyword: string) => {
            return await workflowLabApi.UserLabelsWithScope(props.currentWorkflowLab?.id, props.member.id, keyword);
        }
    });

    useOnMount(() => {
        getUserLabelsWithScope('');
    });

    useEffect(() => {
        if (currentLabels !== undefined && firstRender) {
            setAllWorkflowLabLabels(currentLabels);
            setFirstRender(false);

            const labelsIdToSet = currentLabels.filter(l => l.associationFlag).map(l => l.id);
            setSelectedIdLabels(labelsIdToSet);
        }
        else if (currentLabels !== undefined) { //Update the currentLabels (retrive from BE) with the right boolean flag of allWorkflowLabLabels, after every search
            currentLabels?.forEach(cl => allWorkflowLabLabels.forEach(al => { if (al.id === cl.id) cl.associationFlag = al.associationFlag }))
        }
    }, [currentLabels]); //eslint-disable-line react-hooks/exhaustive-deps

    const { execute: bulkNewLabelsUser, loading: loadingBulk, error: errorBulk } = useAsyncApi({
        func: async () => {
            try {
                await workflowLabApi.BulkNewLabelsUser(selectedIdLabels, props.member.id, props.currentWorkflowLab?.id);
                props.showModalFunction(false);
                props.onActivitiesSaved();
            } catch (er) {
                const error: ErrorsType = er as ErrorsType;
                switch (error.code) {
                    case 400:
                        setErrorMessage(t('errorMessageUserLabels'));
                        break;
                    case 403:
                        setErrorMessage(t('common:unAuthorized'));
                        break;
                    case 404:
                        setErrorMessage(t('common:notFound'));
                        break;
                    default:
                        setErrorMessage(t('common:genericErrorApi'));
                        break;
                }
            }
        }
    });

    const selectAllLabels = () => {
        allWorkflowLabLabels.forEach(al => al.associationFlag = true);
        const labelsIdToSet = allWorkflowLabLabels.filter(l => l.associationFlag).map(l => l.id);
        setSelectedIdLabels(labelsIdToSet);
        currentLabels?.forEach(cl => cl.associationFlag = true);
    }

    const columns: IColumn[] = [
        {
            key: 'column0',
            name: '',
            minWidth: 20,
            maxWidth: 20,
            className: classNames.detailsList,
            onRender: function onRenderCheckbox(label: LabelAssociate) {
                return (
                    <Checkbox
                        disabled={false}
                        checked={label.associationFlag}
                        id={label.id.toString()}
                        className={classNames.customCheckbox}
                        onChange={(ev, checked) => {
                            label.associationFlag = checked!;
                            allWorkflowLabLabels.forEach(al => { if (label.id === al.id) al.associationFlag = checked! })
                            setSelectedIdLabels(allWorkflowLabLabels.filter(l => l.associationFlag).map(l => l.id));
                        }}
                    />
                )
            }
        },
        {
            key: 'column1',
            name: '',
            minWidth: 250,
            maxWidth: 250,
            className: classNames.detailsList,
            onRender: function onRenderLabel(label: LabelAssociate) {
                const categories = label.categoriesName.join(', ');
                return (
                    <div className={classNames.labelCategoriesContainer}>
                        <IconTag
                            key={label.id}
                            label={label.name}
                            labelClassName={classNames.labelBox}
                            className={classNames.labelBox}
                        />
                        <span className={classNames.spanCategories}>
                            {categories}
                        </span>
                    </div>

                )
            }
        },
        /*{
            key: 'column2',
            name: '',
            minWidth: 100,
            className: classNames.detailsList
        }*/
    ]

    const body = () => {
        return (
            <div className={classNames.divBodyContainer}>
                {loadingUserLabels &&
                    <div className={classNames.loaderContainer}>
                        <Spinner
                            size={SpinnerSize.large}
                        />
                    </div>
                }
                {!loadingUserLabels && errorUserLabels &&
                    <div className={classNames.loaderContainer}>
                        <TeamsImage
                            imageName={ImageName.Error1}
                            caption={t('common:genericErrorApi')}
                            scale={0.6}
                        />
                    </div>
                }
                {!loadingUserLabels && !errorUserLabels &&
                    <>
                        <div>
                            <SearchBox
                                defaultValue={keyphrase}
                                placeholder={t('searchLabel')}
                                onChange={(_, newValue) => {
                                    setKeyphrase(newValue || '');
                                }}
                                onSearch={() => {
                                    getUserLabelsWithScope(keyphrase);
                                }}
                                onClear={() => {
                                    setKeyphrase('');
                                    getUserLabelsWithScope('');
                                }}
                            />
                            <div className={classNames.selectAllContainer}>
                                <ActionButton
                                    text={t('common:selectAll')}
                                    className={classNames.selectAllButton}
                                    onClick={selectAllLabels}
                                />
                            </div>
                        </div>
                        <div style={{ overflow: 'overlay', height: 'calc(100% - 100px)' }}>
                            <DetailsList
                                items={currentLabels ?? []}
                                columns={columns}
                                selectionMode={SelectionMode.none}
                                isHeaderVisible={false}
                            />
                        </div>
                    </>
                }
            </div>
        )
    }

    const footer = () => {
        return (
            <div className={classNames.footerContainer}>
                {errorBulk &&
                    <span className={classNames.spanErrorContainer}>
                        {errorMessage}
                    </span>
                }
                <DefaultButton
                    text={t("common:cancel")}
                    style={{ marginRight: "10px" }}
                    onClick={() => props.showModalFunction(false)}
                />
                <PrimaryButton
                    text={t("common:save")}
                    onClick={bulkNewLabelsUser}
                    disabled={loadingUserLabels || loadingBulk || errorUserLabels}
                />
            </div>
        )
    }

    return (
        <EdiModal
            title={t('assignUserLabels')}
            width={600}
            height={600}
            isOpen={props.showModalBool}
            showCloseIcon={true}
            onCloseClick={() => props.showModalFunction(false)}
            body={body()}
            footer={footer()}
        />
    );
}