import { apiClient } from "../../../modules/apiClient/apiClient";
import { svcPaths } from "../../../modules/apiClient/config";
import { LabelViewItem } from "../../../models/labelView";
import { IUser } from "../../../common/interfaces/IUser";
import { CancelToken } from "axios";
import { CategoryLabelItem} from "../../../models/categoryView";
import QueryString from "qs";
import { GetLabelViewWithNoAssociationRequest } from "./label.contracts";

class LabelApiClass {
    async getLabelView(workFlowLabId: number, pageSize: number, pageNumber: number, searchBy: string | undefined){
        const response = await apiClient.get<LabelViewItem[]>(`${svcPaths.workflow}/label/getLabelView`, {
            params: {
                id: workFlowLabId,
                pageSize: pageSize,
                pageNumber: pageNumber,
                searchBy: searchBy
            }
        })
        return response.data;
    }

    async getLabelViewWithNoAssociation(request: GetLabelViewWithNoAssociationRequest, cancelToken?: CancelToken){
        const response = await apiClient.get<CategoryLabelItem[]>(`${svcPaths.workflow}/label/getLabelViewWithNoAssociation`, {            params: {
                id: request.workflowLabId,
                pageSize: request.pageSize,
                categoryId: request.categoryId,
                searchBy: request.keyword,
                labelIdsToExclude: 0
            },
            paramsSerializer: (params) => QueryString.stringify(params, { arrayFormat: 'repeat' }),
            cancelToken: cancelToken
        });
        return response.data;
    }
    

    async createLabel(workFlowLabId: number, name: string, description: string | undefined){
        const response = await apiClient.post(`${svcPaths.workflow}/label`, {
            workFlowLabId: workFlowLabId,
            name: name,
            description: description,
        })
        return response.data;
    }

    async updateLabel(labelId: number, name: string, description: string | undefined){

        const response = await apiClient.put(`${svcPaths.workflow}/label/editLabel`, {
            Id: labelId,
            name: name,
            description: description,
        })
        return response.data;
    }

    async deleteLabel(id: number){
        const response = await apiClient.delete(`${svcPaths.workflow}/label`, {
            params:{
                id: id
            }
        })
        return response.data;
    }

    async searchWorkFlowLabUsers(workFlowLabId: number, searchText: string, hasAuditor: boolean){
        const response = await apiClient.get<IUser[]>(`${svcPaths.workflow}/WorkFlowLab/${workFlowLabId}/users`, {
            params:{
                searchBy: searchText,
                hasAuditor: hasAuditor
            }
        })
        return response.data;
    }

    async updateLabelUsers(labelId: number, users: string[]){
        const response = await apiClient.put(`${svcPaths.workflow}/label/editlabeluser`, {
            id: labelId,
            users: users
        })
        return response.data;
    }

    async checkLabelName(workFlowLabId: number, name: string){
        const response = await apiClient.post(`${svcPaths.workflow}/label/check-name`, {
            workFlowLabId: workFlowLabId,
            name: name
        })
        return response.data;
    }

    async disassociateLabel(idLabel:number, idCategory:number){
        const response = await apiClient.put(`${svcPaths.workflow}/label/removeassociatedcategory`,{
            idLabel: idLabel,
            idCategory: idCategory
        })
        return response.data
    }

}

export const labelApi = new LabelApiClass();