/*eslint-disable sonarjs/cognitive-complexity */
import React, { useState } from "react";
import { classNamesFunction, DetailsList, DetailsRow, IColumn, IconButton, IDetailsRowProps, Label, Persona, PersonaSize, PrimaryButton, SearchBox, SelectionMode, Spinner, SpinnerSize, Stack, TooltipDelay, TooltipHost } from "@fluentui/react";
import { IWorkflowLabManageUsersLabelViewStyles, IWorkflowLabManageUsersLabelViewProps, IWorkflowLabManageUsersLabelViewPropsStyles } from "./workflowLabManageUsersLabelView.types";
import { useOnMount } from "../../../../utilities/hooks";
import { useTranslation } from "react-i18next";
import { labelApi } from "../../../services/label/label.api";
import { useCurrentWorkflowLab } from "../../../hooks/useCurrentWorkflowLab";
import { LabelUserItem, LabelViewItem } from "../../../../models/labelView";
import Accordion from "../../common/accordion/accordion";
import { mapper } from "../../../../utilities/mapper";
import IconTag from "../../../../common/components/iconTag/iconTag";
import  CreateLabelModal  from "../createLabelModal/createLabelModal";
import  EditLabelModal  from "../editLabelModal/editLabelModal";
import  EditLabelUserModal  from "../editLabelUserModal/editLabelUserModal";
import  DeleteLabelModal  from "../deleteLabelModal/deleteLabelModal";
import TeamsImage from "../../../../common/components/teamsImage/teamsImage";
import { ImageName } from "../../../../common/components/teamsImage/teamsImage.types";

const getClassNames = classNamesFunction<IWorkflowLabManageUsersLabelViewPropsStyles, IWorkflowLabManageUsersLabelViewStyles>();
export const WorkflowLabManageUsers = (props: IWorkflowLabManageUsersLabelViewProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const { t } = useTranslation(['workflowLabManageUsers', 'common']);
    const selectedWorkflowLab = useCurrentWorkflowLab();
    const items: LabelViewItem[] = [];

    const [currentItems, setCurrentItems] = useState(items);
    const [createLabelIsOpen, setCreateLabelIsOpen] = useState(false);
    const [loadingLabels, setLoadingLabels] = useState(false);
    const [canLoadMore, setCanLoadMore] = useState(false);
    const pageSize = 20;
    const [pageNumber, setPageNumber] = useState(0);
    const [searchBy, setSearchBy] = useState('');

    useOnMount(() => {
        getLabels(searchBy);
    });

    const getLabels = async (keyword: string, reset = false) => {
        setLoadingLabels(true);
        const pNumber = reset ? 0 : pageNumber;
        if (!selectedWorkflowLab) return;
        
        await labelApi.getLabelView(selectedWorkflowLab.id, pageSize, pNumber, keyword)
            .then((elements) => {

                if (reset)
                    setCurrentItems(elements);
                else
                    setCurrentItems(currentItems.concat(elements));
                if (elements.length >= pageSize) {

                    setCanLoadMore(true);
                    setPageNumber(pNumber + 1);
                }
                else {
                    setCanLoadMore(false);
                }
            })
            .finally(() => {
                setLoadingLabels(false);
            })
    }

    const title = (label: LabelViewItem) => {
        return (
            <div style={{display: 'flex', flexDirection: 'column'}}>
                <div style={{display: 'flex', flexDirection: 'row', alignContent: 'center'}}>
                    <span style={{overflow: 'hidden', textOverflow: 'ellipsis'}}>
                        {label.name} ({label.users.length})
                    </span>
                    <Stack style={{ position: "relative", bottom: "4px", width: '25px', height: '25px' }}>
                        <TooltipHost content={label.description}>
                            <IconButton
                                iconProps={{ iconName: 'Info' }}
                                onClick={(e) => { e.stopPropagation(); }}
                            />
                        </TooltipHost>
                    </Stack>
                </div>
                {label.categories.length !== 0 &&
                    <div className={classNames.categoryListContainer}>
                        <TooltipHost content={label.categories.join(', ')}>
                            <span className={classNames.categoryList}>
                                {label.categories.join(', ')}
                            </span>
                        </TooltipHost>
                    </div>
                }
            </div>
        )
    }

    const printCustomHeader = (label: LabelViewItem) => {
        return (
            <>
                {!selectedWorkflowLab?.workflowLabMaster &&
                <Stack horizontal horizontalAlign="end" className={classNames.headerButtons}>                
                    <EditLabelModal
                        workFlowLabId={selectedWorkflowLab ? selectedWorkflowLab.id : 0}
                        Id={label.labelId}
                        name={label.name}
                        description={label.description}
                        onComplete={() => getLabels(searchBy, true)}
                    />
                    <EditLabelUserModal
                        defaultPeople={label.users}
                        labelId={label.labelId}
                        workFlowLabId={selectedWorkflowLab ? selectedWorkflowLab.id : 0}
                        onComplete={() => getLabels(searchBy, true)}
                    />
                    <DeleteLabelModal
                        Id={label.labelId}
                        onComplete={() => getLabels(searchBy, true)}
                    />                
                </Stack>}
            </>
        )
    }

    const remainingMembersTooltip = (remainingMembersList: string[]): JSX.Element => {
        return (
            <Stack horizontalAlign={'start'} tokens={{ childrenGap: 2 }}>
                {remainingMembersList.map((x, i) => <Label key={i} className={classNames.remainingIconTagContainer}>{x}</Label>)}
            </Stack>);
    }

    const columns: IColumn[] = [
        {
            key: 'persona',
            name: t('common:name'),
            minWidth: 100,
            maxWidth: 350,
            headerClassName: "",
            //onColumnClick: sortByColumn,
            isSorted: false,
            //isSortedDescending: !data.isAscending,
            onRender: function renderMemberPersona(member: LabelUserItem) {
                return (
                    <Persona {...mapper.mapUserToPersona(member)} size={PersonaSize.size40} coinSize={32} />
                );
            }
        },
        {
            key: 'users',
            name: t('common:labelsshort'),
            minWidth: 150,
            //headerClassName: classNames.headerRow,
            onRender: function renderMemberPersona(label: LabelUserItem) {
                const labelList = label.labels.slice(0, 3);
                const remainingMembersList = label.labels.slice(3, label.labels.length);
                return (
                    <div className={classNames.labelList}>
                        {labelList.map((u, i) => {
                            return (
                                <div key={i} className={"icontag-container"}>
                                    <IconTag key={u.id} label={u.name} labelClassName={classNames.labelBox} className={classNames.iconTagLabel}  tooltip />
                                </div>
                            )
                        })}
                        {remainingMembersList.length > 0 &&
                            <div className={classNames.tagCounter}>
                                <TooltipHost
                                    id={'remainingMemberTooltips'}
                                    content={remainingMembersTooltip(remainingMembersList.map(i => i.name))}
                                    delay={TooltipDelay.zero}>
                                    <Label
                                        styles={classNames.subComponentStyles.templatesCountCircle}>+{remainingMembersList.length}
                                    </Label>
                                </TooltipHost>
                            </div>
                        }
                    </div>
                )
            }
        },
    ]
    // eslint-disable-next-line
    const onScroll = (event: any) => {
        // visible height + pixel scrolled >= total height 
        if ((canLoadMore && !loadingLabels) && event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight - 10) {
            getLabels(searchBy);
        }
    }

    return (
        <React.Fragment>
            <Stack className={classNames.root}>
                <Stack horizontal>
                    <Stack.Item style={{ marginRight: '30px' }}>
                        <SearchBox
                            defaultValue={searchBy}
                            className={classNames.searchBar}
                            placeholder={t('searchLabel')}
                            onChange={(_, newValue) => {
                                setSearchBy(newValue || '');
                            }}
                            onSearch={() => {
                                getLabels(searchBy, true);
                            }}
                            onClear={() => {
                                getLabels('', true);
                            }}
                        />
                    </Stack.Item>
                    <Stack.Item>
                    {
                        !selectedWorkflowLab?.workflowLabMaster && <PrimaryButton iconProps={{ iconName: 'Add', style: { height: 'auto' } }} onClick={() => setCreateLabelIsOpen(true)}>{t('workflowLabManageUsers:newLabel')}</PrimaryButton>
                    }
                    </Stack.Item>
                </Stack>
                <Stack className={classNames.accordionStack}>
                    {
                        <div className={classNames.detailListContainer} id={"labelsListZone"}>
                            <>
                                {!loadingLabels && currentItems.length === 0 &&
                                    <div className={classNames.containerEmptyList}>
                                        <TeamsImage imageName={ImageName.EmptySearch} caption={t('common:emptySearch')} />
                                    </div>
                                }
                                <div onScroll={onScroll} id="accordion-scrollable" style={{ height: 'calc(100% - 80px)', overflow: 'overlay', paddingRight: '15px' }}>
                                    {
                                        (loadingLabels && currentItems.length < pageSize) ?
                                            <Spinner style={{ marginTop: '200px' }} size={SpinnerSize.large} /> : currentItems.map((label, i) => {
                                                return (
                                                    <Accordion
                                                        customHeader={printCustomHeader(label)}
                                                        //className={"accordioName"}
                                                        styles={{root:{'.edi-accordion-title':{width: '100%', overflow: 'hidden', textOverflow: 'ellipsis'}}}}
                                                        title={title(label)}
                                                        key={i}
                                                        opened={i === 0 ? true : false}
                                                    >
                                                        <DetailsList
                                                            styles={classNames.subComponentStyles.list}
                                                            items={label.users ?? []}
                                                            getKey={(item: LabelUserItem) => item && item.id}
                                                            onRenderRow={(props?: IDetailsRowProps) =>
                                                                props
                                                                    ? (
                                                                        <DetailsRow {...props} styles={classNames.subComponentStyles.row} />
                                                                    ) : null
                                                            }
                                                            columns={columns}
                                                            selectionMode={SelectionMode.none}
                                                        />
                                                    </Accordion>
                                                );
                                            }
                                            )
                                    }
                                </div>
                                <div className={classNames.load}>
                                    <PrimaryButton id={"addnewlabels"} />
                                </div>
                            </>
                        </div>

                    }
                </Stack>
            </Stack>
            <CreateLabelModal
                workFlowLabId={selectedWorkflowLab ? selectedWorkflowLab.id : 0}
                isOpen={createLabelIsOpen}
                onClose={() => setCreateLabelIsOpen(false)}
                onComplete={() => getLabels(searchBy, true)}
                theme={props.theme}
            />
        </React.Fragment>
    );
}