
import { classNamesFunction, CommandBar, ICommandBarItemProps, Pivot, PivotItem } from "@fluentui/react";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import Information from "../../../common/components/information/information";
import { WorkFlowLabRoleId } from "../../../utilities/constants";
import { useCurrentWorkflowLab } from "../../hooks/useCurrentWorkflowLab";
import { useNavigator } from "../../hooks/useNavigator";
import { WorkflowTab } from "../../utilities/routes";
import workflowStore from "../../workflowStore";
import { IToolbarProps, IToolbarPropsStyles, IToolbarStyles } from "./toolbar.types";

const getClassNames = classNamesFunction<IToolbarPropsStyles, IToolbarStyles>();

export const ToolbarBase = (props: IToolbarProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const { t } = useTranslation('toolbar');
    const selectedWorkflowLab = useCurrentWorkflowLab(); 

    const navigator = useNavigator();
    const rightTabs = useCallback(() => {
        return (
            <>
                <Pivot
                    headersOnly={true}
                    styles={classNames.subComponentStyles.pivot}
                    selectedKey={navigator.currentWorkflowTab ?? null}
                    onLinkClick={item => {
                        if(item && item.props.itemKey && navigator.currentWorkflowTab !== item.props.itemKey && !workflowStore.getState().workflowList.workflowsLab.authError){
                            navigator.changeWorkflowTab(item.props.itemKey as WorkflowTab)
                        }                        
                    }}
                >
                    <PivotItem key={WorkflowTab.workflow} itemKey={WorkflowTab.workflow} headerText={t('overview')} />
                    {selectedWorkflowLab?.roleId !== WorkFlowLabRoleId.Auditor &&
                        <PivotItem key={WorkflowTab.dashboard} itemKey={WorkflowTab.dashboard} headerText={t('dashboard')} />
                    }
                </Pivot>
            </>
        );
    }, [classNames.subComponentStyles.pivot, t, navigator, selectedWorkflowLab?.roleId]);

    const menuAction: ICommandBarItemProps = {
        key: "menu",
        iconProps: { iconName: "CollapseMenu" },
        onClick: props.onMenuButtonClick
    }

    const infoTab = () => (
        <>
            <Information />
        </>
    )

    return (
        <div className={classNames.root}>
            <CommandBar
                className={classNames.wrapper}
                items={[menuAction]}
                farItems={[{ key: "info", commandBarButtonAs: infoTab }, { key: "right-tabs", commandBarButtonAs: rightTabs }]}
            />
        </div>
    );
}