import { IStyleFunction } from "@fluentui/react"
import { IRemoveAssociationModalPropsStyles, IRemoveAssociationModalStyles } from "./removeAssociationModal.types"

export const DeleteCategoryModalClassNames = {
    root: 'ewe-workflowlab-delete-category',
    footerButtons: 'ewe-workflowlab-delete-category-footerButtons',
    deleteButtonContainer: 'ewe-workflowlab-delete-buttonContainer',
    deleteFooterContainer: 'ewe-workflowlab-delete-footerContainer',
    deleteDialogFooterContainer: 'ewe-workflowlab-delete-dialogFooterContainer',
}

export const getStyles: IStyleFunction<IRemoveAssociationModalPropsStyles, IRemoveAssociationModalStyles> = (props: IRemoveAssociationModalPropsStyles): IRemoveAssociationModalStyles => {
    return {
        root: [
            DeleteCategoryModalClassNames.root,
            {
                position: 'relative',
            }
        ],
        footerButtons: [
            DeleteCategoryModalClassNames.footerButtons,
            {
                marginLeft: '100px'
            }
        ],
        deleteButtonContainer: [
            DeleteCategoryModalClassNames.deleteButtonContainer,
            {
                display: 'flex',
                alignItems: 'flex-end'
            }
        ],
        deleteFooterContainer: [
            DeleteCategoryModalClassNames.deleteFooterContainer,
            {
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center'
            }
        ],
        deleteDialogFooterContainer: [
            DeleteCategoryModalClassNames.deleteDialogFooterContainer,
            {
                margin: '8px 0 0'
            }
        ]
    }
};