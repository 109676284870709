/* eslint-disable sonarjs/cognitive-complexity */
import { Check, classNamesFunction, ConstrainMode, IColumn, Icon, PrimaryButton, SelectionMode, ShimmeredDetailsList } from "@fluentui/react";
import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import FileIconCell from "../../../../../../common/components/fileIconCell/fileIconCell";
import TeamsImage from "../../../../../../common/components/teamsImage/teamsImage";
import { ImageName } from "../../../../../../common/components/teamsImage/teamsImage.types";
import { ErrorsType } from "../../../../../../common/types/ErrorsType";
import { FileShort } from "../../../../../../models/fileShort";
import { Helpers } from "../../../../../../utilities/helpers";
import { useOnMount } from "../../../../../../utilities/hooks";
import { workflowApi } from "../../../../../services/workflow.api";
import { SPOFolderBreadcrumb } from "./EDIfolderContent.breadcrumb";
import { IEDIFolderContentProps, IEDIFolderContentPropsStyles, IEDIFolderContentStyles } from "./EDIfolderContent.types";

const getClassNames = classNamesFunction<IEDIFolderContentPropsStyles, IEDIFolderContentStyles>();

type folderBreadcrumb = {
    uniqueId: string | undefined,
    name: string
}

export const EDIFolderContentBase = (props: IEDIFolderContentProps) => {
    const [selectedFolder, setSelectedFolder] = useState<folderBreadcrumb>({ uniqueId: props.selectedTeam?.rootFolderId?.toString(), name: props.selectedTeam?.name || 'Root' });
    const { t } = useTranslation(['createWorkflow', 'common']);
    const [loadingPage, setLoadingPage] = useState(false);
    const [loading, setLoading] = useState(false);
    const [SPOFiles, setSPOFiles] = useState<FileShort[]>([]);
    const [pageNumber, setPageNumber] = useState(0);
    const [error, setError] = useState(false);
    const [selectedAllEnabled] = useState(false);
    const [selectedItems, setSelectedItems] = useState<FileShort[]>(props.selection || []);
    const [isContentChanging, setIsContentChanging] = useState<boolean>(true);

    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className, isEmptyList: !SPOFiles || SPOFiles.length === 0 });

    const pageSize = 30;

    useOnMount(() => {
        fetchFiles();
        registerEvent()
    });

    const loadMoreItems = async (newPageNumber: number) => {
        if (!newPageNumber || loadingPage)
            return
        setLoadingPage(true)
        try {
            const result = await workflowApi.getEdiFiles(
                selectedFolder?.uniqueId?.toString(),
                pageSize,
                newPageNumber);

            if(result.length !== 0)
                setIsContentChanging(true);
            else 
                setIsContentChanging(false);

            setSPOFiles(SPOFiles.concat(result));
        }
        catch (ex) {
            const error: ErrorsType = ex as ErrorsType;
            console.log(error.message);
            setError(true)
        }
        finally {
            setLoadingPage(false)
        }
    }

    const fetchFiles = async (folderId?: string, folderName?: string) => {
        setLoading(true)
        setError(false)
        try {
            let folderUniqueId = props.selectedTeam?.rootFolderId?.toString();

            if (folderId !== undefined) {
                folderUniqueId = folderId.toString();
                setSelectedFolder({name: folderName ?? "root", uniqueId: folderId})
            }

            const result = await workflowApi.getEdiFiles(
                folderUniqueId,
                pageSize, 0);

            setSPOFiles(result)
        }
        catch (ex) {
            const error: ErrorsType = ex as ErrorsType;
            console.log("Error while retrieving files from SPO: ", error.message);            
            setError(true)
        }
        finally {
            setLoading(false)
            const listElm = document.querySelector('#EDIfolderContentDetailsListZone');
            listElm && listElm.scrollTo(0, 0);
        }
    }

    useEffect(() => {
            setPageNumber(0);
            setIsContentChanging(true);
    }, [selectedFolder.uniqueId]);

    const registerEvent = useCallback(() => {
        const listElm = document.querySelector('#EDIfolderContentDetailsListZone'); //#modalListZone .ms-DetailsList-contentWrapper
        listElm && listElm.addEventListener('scroll', () => {
            if (listElm.scrollTop + listElm.clientHeight >= listElm.scrollHeight - 50) {
                const button = document.getElementById("click_EDIFolderContent");
                button && button.click();
            }
        });
    }, []);

    const loadMore = async () => {
        if (loading || (SPOFiles.length < pageSize) || loadingPage) return;
        setPageNumber(pageNumber + 1);
        await loadMoreItems(pageNumber + 1);
    }


    const emptyFolder = useCallback(() => {
        if (loading || (SPOFiles?.length !== 0))
            return null;

        return (
            <div className={classNames.emptyFolder}>
                <TeamsImage
                    imageName={ImageName.EmptyFolderDrop}
                    fullContainer
                    caption={t("common:emptyFolder")}
                />
            </div>
        )
    }, [classNames.emptyFolder, SPOFiles, t, loading]);
 
    const onRenderHeader = () => {
        return props.selectAllEnabled && SPOFiles && SPOFiles.length > 0 ? <div data-checked={true} className={classNames.checkboxHeader}>
            <Check checked={selectedAllEnabled} />
        </div> : <></>
    }

    const columns: IColumn[] = [{
        key: 'selection',
        name: 'selection',
        isIconOnly: true,
        onRenderHeader: onRenderHeader,
        minWidth: 20,
        maxWidth: 20,
        onRender: function getItemCell(item: FileShort) {
            if (item.isFolder && props.disableFolderSelection)
                return null;

            if (!item.isFolder && (!props.enabledFileTypes 
                || !props.enabledFileTypes?.some(type => type.toLowerCase() === Helpers.getFileExtension(item.name).toLowerCase()))) {
                return null;
            }

            if (!item.isFolder && (item.size && Helpers.convertToMB(item.size) > 100))
                return null;
                
            if (!item.isFolder && props.disableFileSelection)
                return null;    

            const checked = selectedItems.some(i => i.id === item.id);
            
            const toggleSelected = () => {
                let newItems;

                if (props.maxNumFiles === 1) {
                    newItems = checked ? [] : [item];
                }
                else {
                    if ((selectedItems.length > (props.maxNumFiles - 1) && !checked))
                        return;
                    if (checked)
                        newItems = _.remove([...selectedItems], i => i.uniqueId !== item.uniqueId);
                    else
                        newItems = [...selectedItems, item];
                }
                setSelectedItems(newItems);
                props.onSelectionChange([...newItems]);
            }

            return (
                <div data-checked={checked} onClick={() => toggleSelected()} className={classNames.checkbox}>
                    <Check checked={checked} />
                </div>
            )
        }
    }, {
        key: 'file-type',
        name: 'File Type',
        iconName: 'Page',
        isIconOnly: true,
        fieldName: '',
        minWidth: 20,
        maxWidth: 20,
        onRender: function getItemCell(item: FileShort) {
            const extIndex = item.name.lastIndexOf('.');
            const ext = extIndex !== -1 ? item.name.substring(extIndex) : "";
            return <div className={(!item.isFolder && props.disableFileSelection)? classNames.nonInteractableContainer : ''}>
                <FileIconCell fileExtension={ext} isFolder={item.isFolder} />
                </div>
        }
    }, {
        key: 'Name',
        name: t("columns.name"),
        fieldName: 'Name',
        minWidth: 100,
        maxWidth: 350,
        isRowHeader: true,
        isResizable: true,
        onRender: function getItemName(item: FileShort) {
            return (
                <span
                    onClick={async () => { item.isFolder && await fetchFiles(item.id.toString(), item.name);
                    if(props.onFolderSelection && (item.isFolder))props.onFolderSelection(item.id)}}
                    style={{ cursor: item.isFolder || props.canShowPreview ? 'pointer' : 'default'}}
                    className={(!item.isFolder && props.disableFileSelection)? classNames.nonInteractableContainer : ''}
                >
                    {item.name}
                </span>
            )
        }
    }, {
        key: 'CreatedOn',
        name: t("columns.createdOn"),
        fieldName: 'CreatedOn',
        minWidth: 130,
        maxWidth: 130,
        isResizable: true,
        // isSorted: data.orderBy === 'CreatedOn',
        // isSortedDescending: !data.isAsc,
        //onColumnClick: sortByColumn,
        onRender: function render(item: FileShort) {
            return <div className={(!item.isFolder && props.disableFileSelection)? classNames.nonInteractableContainer : ''}>
                {Helpers.getShortDate(item.createdOn)}
                </div>
        }
    }, {
        key: 'CreatedBy',
        name: t("columns.createdBy"),
        fieldName: 'CreatedBy',
        minWidth: 130,
        maxWidth: 250,
        isResizable: true,
        isCollapsible: true,
        // isSorted: data.orderBy === 'CreatedBy',
        // isSortedDescending: !data.isAsc,
        // onColumnClick: sortByColumn,
        onRender: function getCreatedBy(item: FileShort) {
            return <span className={(!item.isFolder && props.disableFileSelection)? classNames.nonInteractableContainer : ''}>{item.createdBy}</span>;
        },
    }];

    // const setContentScope = (name: string) => {
    //     return name ? ContentItemScope.File : ContentItemScope.All
    // }

    return (
        <>
            <div className={classNames.root}>

                <div>
                   {!error && 
                    <div className={classNames.breadcrumbContainer}>
                        <div className={classNames.iconContainer}>
                            <Icon onClick={() => props.onLevelUp && props.onLevelUp(true)} styles={{ root: { fontSize: '16px', cursor: 'pointer' } }} iconName="up" />
                        </div>               
                        <div style={{width: '100%'}}>                                    
                            <SPOFolderBreadcrumb
                                folderId={selectedFolder?.uniqueId?.toString() || ""}
                                folderName={selectedFolder?.name ?? ''}
                                onClick={() => {fetchFiles(selectedFolder.uniqueId, selectedFolder.name);
                                    if(props.onFolderSelection && selectedFolder.uniqueId)props.onFolderSelection(parseInt(selectedFolder.uniqueId));
                                }}
                            />
                        </div>
                    </div>
                    }
                </div>
                

                <div id={"EDIfolderContentDetailsListZone"} style={{ height: '260px', overflow: 'overlay' }}>
                    {error ? <div className={classNames.errorContainer}>{t('common:genericErrorApi')}</div> :
                    <ShimmeredDetailsList
                        onShouldVirtualize={() => false}
                        items={SPOFiles || []}
                        columns={columns}
                        enableShimmer={loading}
                        getKey={(item: FileShort) => item && `${item.isFolder ? 'f' : ''}${item.id}`}
                        selectionMode={SelectionMode.none}
                        constrainMode={ConstrainMode.unconstrained}
                        onRenderDetailsFooter={emptyFolder}
                    />}
                </div>


                <div className={classNames.load}>
                    <PrimaryButton id={"click_EDIFolderContent"} onClick={async () => isContentChanging && await loadMore()} />
                </div>
            </div>
        </>
    );
}