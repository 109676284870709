import { useCallback, useMemo } from "react";
import { matchPath, useHistory, useLocation } from "react-router-dom";
import { setAuthError, updateIdSelectedWorkflowLab, useWorkflowList } from "../features/workflowList";
import { WorkflowLabRoutes, WorkflowLabSettingsTab, WorkflowLabTemplateTab, WorkflowTab } from "../utilities/routes";
import { useWorkflowDispatch } from "../workflowStore";

export interface INavigator {
    goToPath: (path: string) => void;
    goBack: () => void;
    changeWorkflowLab: (workflowLabId: number) => void;
    goToWorkflowLabSettingsTab: (tab: WorkflowLabSettingsTab, workflowLabId: number) => void;
    goToWorkflowLabTemplateTabPath: (tab: WorkflowLabTemplateTab, workflowLabId: number) => void;
    goToFirstWorkflowLab: () => void;
    changeWorkflowTab: (workflowTab: WorkflowTab) => void;
    currentWorkflowTab?: WorkflowTab;
    goToWorkflowDetails: (workflowId: number) => void;
    selectedWorkFlowLabId?: number;
    selectedworkflowId: number;
}

export const useNavigator = (): INavigator => {
    const location = useLocation();
    const history = useHistory();
    const dispatch = useWorkflowDispatch();
    const workflowState = useWorkflowList();

    const goToPath = useCallback((path: string) => history.push(path), [history]);
    const goBack = useCallback(() => history.goBack(), [history]);

    const workflowRoutePath = useMemo(() =>        
        matchPath<{ workflowLabId: string, tab: WorkflowTab }>(location.pathname, { path: WorkflowLabRoutes.workflowLabTabOptional }),
        [location.pathname]);

    const workflowIdsRoutePath = useMemo(() =>        
        matchPath<{ workflowLabId: string, workflowId: string }>(location.pathname, { path: WorkflowLabRoutes.workflowDetails }),
        [location.pathname]);

    const selectedworkflowId = useMemo(() => {
        return parseInt(workflowIdsRoutePath?.params.workflowId ?? "0")
    }, [workflowIdsRoutePath])

    const selectedWorkFlowLabId = useMemo(() => 
        workflowRoutePath?.params.workflowLabId 
            ? +workflowRoutePath?.params.workflowLabId 
            : undefined
        , [workflowRoutePath]);
    
    const changeWorkflowLab = useCallback((workflowLabId: number) => {
        dispatch(updateIdSelectedWorkflowLab(workflowLabId));
        dispatch(setAuthError(false));
        history.push(WorkflowLabRoutes.generateWorkflowLabTabPath(workflowLabId, WorkflowTab.workflow));
    }, [history, dispatch]);

    const changeWorkflowTab = useCallback((workflowTab: WorkflowTab) => {
        if (!selectedWorkFlowLabId)
            return; 
        history.push(WorkflowLabRoutes.generateWorkflowLabTabPath(selectedWorkFlowLabId, workflowTab));
    }, [history, selectedWorkFlowLabId]);

    const currentWorkflowTab = useMemo(() => workflowRoutePath?.params.tab, [workflowRoutePath]);

    const goToFirstWorkflowLab = useCallback(() => {
        if(workflowState.workflowsLab.workflowLabIds[0]){
            dispatch(updateIdSelectedWorkflowLab(workflowState.workflowsLab.workflowLabIds[0]));
            history.push(WorkflowLabRoutes.generateWorkflowLabTabPath(workflowState.workflowsLab.workflowLabIds[0], WorkflowTab.workflow));
        }
    }, [history, dispatch, workflowState.workflowsLab.workflowLabIds])

    const goToWorkflowDetails = useCallback((workflowId: number) => {
        if (!selectedWorkFlowLabId)
            return; 
        history.push(WorkflowLabRoutes.generateWorkflowDetailsPath(selectedWorkFlowLabId, workflowId));
    }, [history, selectedWorkFlowLabId]);

    const goToWorkflowLabSettingsTab = useCallback((tab: WorkflowLabSettingsTab, workFlowLabId: number) => {
        if (!selectedWorkFlowLabId)
            return; 

        history.push(WorkflowLabRoutes.generateArchiveSettingsTabPath(selectedWorkFlowLabId, tab));
    }, [history, selectedWorkFlowLabId]);

    const goToWorkflowLabTemplateTabPath = useCallback((tab: WorkflowLabTemplateTab, workFlowLabId: number) => {
        if (!selectedWorkFlowLabId)
            return; 

        history.push(WorkflowLabRoutes.generateWorkflowLabTemplateTabPath(selectedWorkFlowLabId, tab));
    }, [history, selectedWorkFlowLabId]);

    return {
        goToPath: goToPath,
        goBack: goBack,
        changeWorkflowLab: changeWorkflowLab,
        goToFirstWorkflowLab: goToFirstWorkflowLab,
        changeWorkflowTab: changeWorkflowTab,
        currentWorkflowTab: currentWorkflowTab,
        goToWorkflowDetails: goToWorkflowDetails, 
        selectedWorkFlowLabId: selectedWorkFlowLabId,
        goToWorkflowLabSettingsTab: goToWorkflowLabSettingsTab,
        goToWorkflowLabTemplateTabPath: goToWorkflowLabTemplateTabPath,
        selectedworkflowId: selectedworkflowId
    }
}