import { styled } from "@fluentui/react";
import { ConfirmModalBase } from "./confirmModal.base";
import { getStyles } from "./confirmModal.styles";
import { IConfirmModalProps, IConfirmModalPropsStyles, IConfirmModalStyles } from "./confirmModal.types";

const ConfirmModal = styled<IConfirmModalProps, IConfirmModalPropsStyles, IConfirmModalStyles>(
    ConfirmModalBase,
    getStyles,
    undefined,
    { scope: 'ConfirmModal' }
);

export default ConfirmModal;