import { IStyleFunction } from "@fluentui/react"
import { IFirstPagePropsStyles, IFirstPageStyles } from "./firstPage.types"

export const FirstPageGlobalClassNames = {
    root: 'edi-fist-page-content-root',
    iconResized: 'icon-resized',
    contentContainer: 'content-container'
}

export const getStyles: IStyleFunction<IFirstPagePropsStyles, IFirstPageStyles> = (props: IFirstPagePropsStyles): IFirstPageStyles => {
    const { palette } = props.theme!; //eslint-disable-line @typescript-eslint/no-non-null-assertion
    
    return {
        subComponentStyles: {           
            iconButton: {
                root: {
                    color: palette.black,
                    marginLeft: 'auto',
                    marginTop: '4px',
                    marginRight: '2px',
                },
                rootHovered: {
                    color: palette.neutralPrimary,
                }
            }
        },
        root: [
            FirstPageGlobalClassNames.root,
            {
                color: palette.black
            }
        ],
        iconResized: [
            FirstPageGlobalClassNames.iconResized,
            {
                marginTop: 15,
                height: 30,
                width: 30,
                fontSize: 24,
            }
        ],
        contentContainer: [
            FirstPageGlobalClassNames.contentContainer,
            {
                marginTop: 15
            }
        ]
    };
}