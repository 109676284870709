import { User } from "./User";

export type WorkFlowApprover = {
    workFlowStepId: number;
    approver: User;
    status: WorkFlowApproverStatus;
    comment?: string;
    updatedOn?: Date;
}

export enum WorkFlowApproverStatus {
    ToBeApproved = 0,
    Approved = 1,
    Refused = 2
}
