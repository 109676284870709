import React, { useMemo } from "react";
import { classNamesFunction, StackItem } from "@fluentui/react";
import { Doughnut } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { WorkFlowRelativeStatus } from "../../../../../models/WorkflowEnum";
import { IWorkflowDisjointedGraphProps, IWorkflowDisjointedGraphPropsStyles, IWorkflowDisjointedGraphStyles } from "./workflowDisjointedGraph.types";

const getClassNames = classNamesFunction<IWorkflowDisjointedGraphPropsStyles, IWorkflowDisjointedGraphStyles>();

export const WorkflowDisjointedGraphBase = (props: IWorkflowDisjointedGraphProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className }); //eslint-disable-line @typescript-eslint/no-unused-vars
    const { palette, semanticColors } = props.theme!; // eslint-disable-line @typescript-eslint/no-non-null-assertion
    const { t } = useTranslation(['workflow', 'common']);

    const workflowsData = useMemo(() => {
        return {
            labels: [t(`status.${WorkFlowRelativeStatus.ToMe}`), t(`status.${WorkFlowRelativeStatus.Approved}`), t(`status.${WorkFlowRelativeStatus.Refused}`), t(`status.${WorkFlowRelativeStatus.Ongoing}`)],
            datasets: [
                {
                    data: [
                        _.sum(props.workflows?.filter(a => a.status === WorkFlowRelativeStatus.ToMe).map(r => r.count)),
                        _.sum(props.workflows?.filter(a => a.status === WorkFlowRelativeStatus.Approved).map(r => r.count)),
                        _.sum(props.workflows?.filter(a => a.status === WorkFlowRelativeStatus.Refused).map(r => r.count)),
                        _.sum(props.workflows?.filter(a => a.status === WorkFlowRelativeStatus.Ongoing).map(r => r.count))
                    ],
                    backgroundColor: [palette.themePrimary, palette.green, palette.redDark, palette.yellow],
                    borderColor: 'transparent',
                    cutout: '70%'
                },
            ] ,
        }
    }, [palette, props.workflows, t]) 

    const sentWorkflowsDoughnutOptions = {
        maintainAspectRatio: false,
        responsive: true,
        layout: {
            padding: 0
        },
        plugins: {
            legend: {
                display: false,
                labels: {
                    color: semanticColors.bodyText
                },
            },
            title: {
                display: true,
                text: props.disjointName
            },
        },

        tooltip: {
            callbacks: {
                afterLabel: (context: any) => { //eslint-disable-line @typescript-eslint/no-explicit-any
                    return "afterLabel"
                }
            }
        },
    };

    const sumWorkflow = useMemo(() => {
        return _.sum(workflowsData.datasets[0].data);
    }, [workflowsData]);

    return (
        <StackItem style={{
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative'
            }}>
                
            <React.Fragment>
                <span className={classNames.doughnutTotal}>{sumWorkflow}</span>
                <Doughnut data={workflowsData} options={sentWorkflowsDoughnutOptions}/>
            </React.Fragment>
        </StackItem>
    )
}