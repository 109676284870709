import React, { useState } from "react";
import { IExtTagItemProps, ILabelsSelectorBaseProps, ILabelsSelectorBasePropsStyles, ILabelsSelectorBaseStyles, LabelsPageData } from "./labelsSelector.types";
import { classNamesFunction, PrimaryButton, TagPicker, IBasePickerSuggestionsProps, DetailsList, DetailsRow, SelectionMode, IColumn, IconButton, ITag } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { mapper } from "../../../../utilities/mapper";
import { labelApi } from "../../../services/label/label.api";
import IconTag from "../../../../common/components/iconTag/iconTag";
import { CategoryLabelItem } from "../../../../models/categoryView";
import { GetLabelViewWithNoAssociationRequest } from "../../../services/label/label.contracts";
const getClassNames = classNamesFunction<ILabelsSelectorBasePropsStyles, ILabelsSelectorBaseStyles>();

export const LabelsSelectorBase = (props: ILabelsSelectorBaseProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const { t } = useTranslation(['common']);
    const [pickerBuffer, setPickerbuffer] = useState<IExtTagItemProps[]>([]);
    const [data, setData] = useState<LabelsPageData>({ isValid: false, selectedLabels: props.defaultLabels || [] });

    const availableColumns = (): IColumn[] => {
        return [
            { key: 'label', name: 'label', minWidth: 120, onRender: renderLabelIconTag },
            { key: 'delete', name: 'delete', minWidth: 20, maxWidth: 100, onRender: renderDeleteLabel }
        ];
    };

    const renderLabelIconTag = (item: CategoryLabelItem) => {
        const mapped = mapLabelToTag(item);
        return <IconTag {...mapped}
            label={item.name}
            labelClassName={classNames.labelBox}
            className={classNames.iconTagLabel}
        />
    };

    const deleteLabel = (item: CategoryLabelItem) => {
        const newState = { ...data };
        const remained = newState.selectedLabels.filter(i => i.id !== item.id);
        newState.selectedLabels = remained;
        setData(newState);
        if (props.onChangeSelectedLabels)
            props.onChangeSelectedLabels(newState.selectedLabels);
    }

    const renderDeleteLabel = (label: CategoryLabelItem) => {
        return <IconButton iconProps={{ iconName: 'Cancel' }} onClick={() => deleteLabel(label)}></IconButton>
    };

    const suggestionOptions: IBasePickerSuggestionsProps = {
        showRemoveButtons: false,
        suggestionsClassName: classNames.labelsPickerSuggestions,
        suggestionsItemClassName: classNames.labelsPickerSuggestionItem,
        loadingText: t('common:loading'),
        noResultsFoundText: t('noResults'),
    };

    const searchForAvailableLabels = async (filter: string, selectedItems?: ITag[]) => {
        const selectedIds = data.selectedLabels.map(i => i.id)
            .concat(pickerBuffer.map(l => l.labelData.id));

        const request: GetLabelViewWithNoAssociationRequest =
        {
            workflowLabId: props.workflowLabId,
            categoryId: props.categoryId ?? 0,
            pageSize: 10,
            keyword: filter,
            labelIdsToExclude: selectedIds
        }
        
        return labelApi.getLabelViewWithNoAssociation(request)
            .then((result) => {
                return result.map(l => mapLabelToTag(l));
            })
    }

    const mapLabelToTag = (label: CategoryLabelItem): IExtTagItemProps => {
        return {
            ...mapper.mapLabelToTag(label),
            labelData: label
        }
    }


    const addLabelsToPickerBuffer = (labels?: ITag[]) => {
        const converted: IExtTagItemProps[] | undefined = labels?.map(p => p as IExtTagItemProps);
        setPickerbuffer(converted ?? []);
    }

    const movePickerBufferToLabels = () => {
        const newState = { ...data };

        newState.selectedLabels = newState.selectedLabels.concat(pickerBuffer.map(label => label.labelData as CategoryLabelItem));
        newState.isValid = newState.selectedLabels.length > 0;

        if (props.onChangeSelectedLabels)
            props.onChangeSelectedLabels(newState.selectedLabels);
        setData(newState);
        setPickerbuffer([]);
    }

    return (
        <>
            {!props.visualizeMode &&
                <div className={classNames.labelsPickerZone}>
                    <TagPicker
                        styles={classNames.subComponentStyles.labelsPicker}
                        onResolveSuggestions={searchForAvailableLabels}
                        resolveDelay={500}
                        pickerSuggestionsProps={suggestionOptions}
                        selectedItems={pickerBuffer}
                        onChange={addLabelsToPickerBuffer}
                        
                    />
                    <PrimaryButton text={t("common:add")} onClick={movePickerBufferToLabels} disabled={pickerBuffer.length === 0} />
                </div>}
            <div>
                <DetailsList
                    key={data.selectedLabels.length}
                    items={data.selectedLabels}
                    columns={availableColumns()}
                    selectionMode={SelectionMode.none}
                    isHeaderVisible={false}
                    onRenderRow={props => props ? <DetailsRow {...props} className={classNames.detailsListRow} /> : null}
                />
            </div>
        </>
    );
}