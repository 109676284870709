import { classNamesFunction, DefaultButton, FontIcon, PrimaryButton, Spinner, SpinnerSize, Stack, StackItem, TooltipHost } from "@fluentui/react";
import React, {useState } from "react";
import { useTranslation } from "react-i18next";
import MultiStepModal from "../../../../common/components/multiStepModal/multiStepModal";
import TeamsImage from "../../../../common/components/teamsImage/teamsImage";
import { ImageName } from "../../../../common/components/teamsImage/teamsImage.types";
import { ErrorDetails } from "../../../../modules/apiClient/apiClient";
import { useCurrentWorkflowLab } from "../../../hooks/useCurrentWorkflowLab";
import { workflowLabApi } from "../../../services/workFlowLab/workFlowLab.api";
import FirstPage from "./pages/firstPage/firstPage";
import { IUploadUserLabelModalProps, IUploadUserLabelModalPropsStyles, IUploadUserLabelModalStyles } from "./uploadUserLabelModal.types";


const getClassNames = classNamesFunction<IUploadUserLabelModalPropsStyles, IUploadUserLabelModalStyles>();

export const UploadUserLabelModalBase = (props: IUploadUserLabelModalProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });

    const [currentStep, setCurrentStep] = useState(0);
    const [uploadFile, setUploadFile] = useState(false);
    const [error, setError] = useState('');
    const [reset, setReset] = useState(false);
    const [fileAccepted, setFileAccepted] = useState(false);
    const [loading, setLoading] = useState(false);
    const [fileUploaded, setFileUploaded] = useState<any>();//eslint-disable-line @typescript-eslint/no-explicit-any      
    const [canRemovefile, setCanRemovefile] = useState<boolean>();
    const currentworkflowLab = useCurrentWorkflowLab(); //eslint-disable-line @typescript-eslint/no-non-null-assertion

    const { t } = useTranslation(['uploadUserLabelModal', 'common']);
    const nextButtonText = t('common:nextButton');

    const getSteps = () => {
        return [
            {
                title: t('firstPageTitle'),
                body: bodyPage(),
                footer: standardFooter(),
                subtitle: t('firstPageSubHeader'),
            },
            getMexAnalyzingStep(),
        ];
    }

    const bodyPage = () => {
        return (
            <FirstPage            
                fileUploadedCallback={(files: File[]) => {
                    setFileUploaded(files[0]);
                    if(files.length === 0){
                        setReset(false);
                        setCanRemovefile(false);
                    }else{
                        setCanRemovefile(true);                            
                        setError('');                                   
                    }
                } }
                reset={reset}
                canRemovefile={canRemovefile}
                removeFile={() => setReset(true)}
                fileAccepted={(value: boolean)=> setFileAccepted(value)}>
            </FirstPage>
        );
    }

    const standardFooter = () => {
        return <>
            <Stack styles={{ root: { width: '100%' } }} horizontal>
                <StackItem style={{ marginBottom: 10, minWidth: '60%' }}>
                    {error && <span className={classNames.errorMessage}>
                        <span>
                            <TooltipHost content={error} styles={{ root: { display: 'inline-block', marginRight: 5 } }} >
                                <div style={{ display: 'flex' }}><FontIcon iconName="StatusErrorFull" style={{ fontSize: 14, cursor: 'help' }} /></div>
                            </TooltipHost>
                            {error}
                        </span>
                    </span>}
                </StackItem>
                <StackItem styles={{ root: { width: '100%', display: 'flex' } }}>
                    <div style={{ marginLeft: 'auto', display: 'flex' }}>
                        <div style={{ marginRight: '5px', overflow: 'hidden'}}>{loading && <Spinner size={SpinnerSize.large}></Spinner>}</div>
                        <div>
                        <DefaultButton
                            style={{ marginRight: 8 }}
                            text={t("common:cancel")}
                            onClick={() =>
                                props.onClose(false)
                            }
                        />
                        <PrimaryButton
                            text={nextButtonText}
                            disabled={fileUploaded === undefined || fileAccepted || loading}
                            onClick={async () => { onClickNextFooter() }}
                        />
                        </div>
                    </div>
                </StackItem>                
            </Stack>
        </>
    }

    const onClickNextFooter = async () => {
        setError('');
        setUploadFile(true);
        try {
            setLoading(true);
            await workflowLabApi.validateUploadFile(currentworkflowLab ? currentworkflowLab?.id : 0, fileUploaded);
            setLoading(true);
            setError('');
            setCurrentStep(currentStep + 1);
            onValidation();
        }
        catch (er: any) {//eslint-disable-line @typescript-eslint/no-explicit-any      
            setLoading(false);
            setReset(true);
            setFileUploaded(undefined);
            const error: ErrorDetails = er as ErrorDetails;
            switch (error.code) {
                case 403: setError(t('notPermissions')); break;
                case 404: setError(t('notFound')); break;
                case 422: 
                        switch (error.subCode) {
                            case  0:  setError(t("ColumnsError")); break;
                            case  1:  setError(t("UserNotFound")); break;
                            case  2:  setError(t("DuplicateUsers")); break;                            
                            case  3:  setError(t("RoleNotFound")); break;
                            case  4:  setError(t("CanNotChangeYourRole")); break;
                            case  5:  setError(t("LabelNameNotValid")); break;
                            case  6:  setError(t("AuditorHasNoLabels")); break;
                            case  7:  setError(t("AuditorCannotChangeRole")); break;
                            default: break;
                        }
                        break;
                default: setError(t('common:genericErrorApi')); break;
            }
        }
        finally {
            setUploadFile(false);
        }
    }

    const onValidation = async () => {
        await workflowLabApi.uploadFile(currentworkflowLab ? currentworkflowLab?.id : 0, fileUploaded);        
    }

    const getMexAnalyzingStep = () => {
        return {
            bodyOnly: true,
            body: finalMessagePageBody()
        }
    }

    const finalMessagePageBody = () => {
        return (
            <>
                <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                    <TeamsImage imageName={ImageName.StillThere} style={{ flexBasis: '50%', flexShrink: 0 }}
                        fullContainer scale={0.8} />
                    <div className={classNames.thirdFooter}>
                        {t('secondPage')}
                        <div>
                            <PrimaryButton
                                text={t('end')}
                                allowDisabledFocus
                                disabled={uploadFile}
                                onClick={() => props.onClose(true)}
                                styles={{ root: { padding: '0px 80px', marginTop: '20px' } }}
                            />
                        </div>
                    </div>
                </div>
            </>
        )
    }

    return (
        <MultiStepModal
            styles={classNames.subComponentStyles.ediModal}
            isOpen={true}
            width={600}
            height={600}
            showCloseIcon={currentStep === 0}
            onCloseClick={() => props.onClose(false)}
            activeStep={currentStep}
            steps={getSteps()}
            animateInitialStep
            hideScrollbarBody={true}
        />
    );
}
