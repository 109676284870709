import React, { useState } from "react";
import { classNamesFunction, DefaultButton, Dialog, DialogFooter, IconButton, PrimaryButton, Spinner, SpinnerSize } from "@fluentui/react";
import { IDeleteLabelModalStyles, IDeleteLabelModalProps, IDeleteLabelModalPropsStyles } from "./deleteLabelModal.types";
import { useTranslation } from "react-i18next";
import { labelApi } from "../../../services/label/label.api";

const getClassNames = classNamesFunction<IDeleteLabelModalPropsStyles, IDeleteLabelModalStyles>();

export const DeleteLabelModalBase = (props: IDeleteLabelModalProps) => {
    const classNames = getClassNames(props.styles,
        {
            theme: props.theme,
            className: props.className
        });

    const { t } = useTranslation(['deleteLabelModal', 'common']);
    const [deleteLabelModal, setDeleteLabelModal] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>('');


    const deleteLabel = () => {
        setDeleting(true)
        labelApi.deleteLabel(props.Id)
            .then(() => {
                if (props.onComplete) 
                    props.onComplete();
                setDeleting(false);
            })
            .catch(() => setErrorMessage('something went wrong'))
            .finally(() => setDeleting(false));
    }
    
    return (
        <>
            <IconButton
                iconProps={{ iconName: 'Delete' }}
                title={`${t('common:delete')}`}
                onClick={(e) => {
                    e.stopPropagation();
                    setDeleteLabelModal(true);
                }
                } />
            {deleteLabelModal && <Dialog
                isOpen={deleteLabelModal}
                dialogContentProps={{
                    title: t('common:deletetitle'),
                    showCloseButton: !deleting,
                    onDismiss: () => { setDeleteLabelModal(false); },
                    subText: t('deleteLabelModal:confirmMessage'),
                }}
            >
                <div className={classNames.deleteFooterContainer}>
                    <div className={classNames.deleteButtonContainer}>
                        {!errorMessage && deleting && <Spinner size={SpinnerSize.large}></Spinner>}
                        <div>
                            <DialogFooter
                            //styles={classNames.subComponentStyles.deleteDialogFooterContainer}
                            >
                                <DefaultButton disabled={deleting} onClick={() => setDeleteLabelModal(false)} text={t('common:undoEliminate')} />
                                <PrimaryButton onClick={deleteLabel} styles={classNames.subComponentStyles.deletePrimaryButtonDisabled()} text={t('common:confirmEliminate')} disabled={deleting} />
                            </DialogFooter>
                        </div>
                    </div>
                </div>
            </Dialog>}
        </>
    );
}