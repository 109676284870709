import { IStyle, IStyleFunctionOrObject, ITheme } from "@fluentui/react";
import { WorkFlowStepTypes } from "../../../../../../../models/WorkFlow";
import { AvailableApproversAndLabels } from "../../../../../../services/workflow.contracts";

export interface ISelectLabelUserProps {
    theme?: ITheme;
    styles?: IStyleFunctionOrObject<ISelectLabelUserPropsStyles, ISelectLabelUserStyles>;
    className?: string;
    updateApprovers?: (approvers: AvailableApproversAndLabels[])=> void;
    selectedApprovers: AvailableApproversAndLabels[];
    selectType?: (type: SearchType) => void;
    stepType: WorkFlowStepTypes;
    customError?: string;
    isRequired?: boolean;
    errorMessage?: string;
    placeholder: string;
}

export interface ISelectLabelUserPropsStyles {
    theme?: ITheme;
    styles?: IStyleFunctionOrObject<ISelectLabelUserPropsStyles, ISelectLabelUserStyles>;
    className?: string;
    // ADD STYLE PROPS HERE FOR CONDITIONAL CSS GENERATION
}

export interface ISelectLabelUserStyles {
    root: IStyle; 
}

export enum SearchType {
    Mixed = 0,
    OnlyUser = 1,
    OnlyLabels = 2
}