import { IStyleFunction } from "@fluentui/react"
import { IEdiLinkModalPropsStyles, IEdiLinkModalStyles } from "./ediLinkModal.types";

export const EdiLinkModalGlobalClassNames = {
    root: 'edi-workflow-edi-link-modal-root',
    shimmer: 'edi-workflow-edi-link-modal-shimmer',
    divButtonFooter: 'edi-workflow-edi-link-modal-divButtonFooter',
    divErrorMessage: 'edi-workflow-edi-link-modal-divErrorMessage',
    errorMessage: 'edi-workflow-edi-link-modal-errorMessage',
}

export const getStyles: IStyleFunction<IEdiLinkModalPropsStyles, IEdiLinkModalStyles> = (props: IEdiLinkModalPropsStyles): IEdiLinkModalStyles => {
    const { palette } = props.theme!; //eslint-disable-line @typescript-eslint/no-non-null-assertion
    return {
        root: [
            EdiLinkModalGlobalClassNames.root,
            {
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center'
            }
        ],
        shimmer: [
            EdiLinkModalGlobalClassNames.shimmer,
            {
                height: 33,
                '>.ms-Shimmer-shimmerWrapper': {
                    height: '100%'
                }
            }
        ],
        divButtonFooter: [
            EdiLinkModalGlobalClassNames.divButtonFooter,
            {
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-end',
                width: '65%',
            }
        ],
        divErrorMessage: [
            EdiLinkModalGlobalClassNames.divErrorMessage,
            {
                width: '35%',
            }
        ],
        errorMessage: [
            EdiLinkModalGlobalClassNames.errorMessage,
            {
                width: 'auto',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                color: palette.redDark,
                display: 'flex',
                alignItems: 'center'
            }
        ],
    };
}