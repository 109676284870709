import { styled } from "@fluentui/react";
import { FirstPageBase } from "./firstPage.base";
import { IFirstPageProps, IFirstPagePropsStyles, IFirstPageStyles } from "./firstPage.types";
import { getStyles } from "./firstPage.styles";

const FirstPage = styled<IFirstPageProps, IFirstPagePropsStyles, IFirstPageStyles>(
    FirstPageBase,
    getStyles,
    undefined,
    { scope: 'FirstPage' }
);

export default FirstPage;