import { styled } from "@fluentui/react";
import { getStyles } from "./localFile.styles";
import { ILocalFilePropsStyles, ILocalFileStyles, ILocalFileProps } from "./localFile.types";
import { LocalFileBase } from "./localFile.base";

const LocalFile = styled<ILocalFileProps, ILocalFilePropsStyles, ILocalFileStyles>(
    LocalFileBase,
    getStyles,
    undefined,
    { scope: 'LocalFile' }
);

export default LocalFile;