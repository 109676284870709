/*eslint-disable sonarjs/cognitive-complexity */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { classNamesFunction, DefaultButton, Dialog, Label, PrimaryButton } from "@fluentui/react";
import { CreateTemplateModalEnum, FirstPageData, ICreateTemplateModalProps, ICreateTemplateModalPropsStyles, ICreateTemplateModalStyles, SecondPageData } from "./createTemplateModal.types";
import { useWorkflowState } from "../../../workflowStore";
import { IModalStepData } from "../../../../common/components/multiStepModal/multiStepModal.types";
import MultiStepModal from "../../../../common/components/multiStepModal/multiStepModal";
import { ThirdPageData } from "../../workflowOverview/createWorkflow/addSteps/addSteps.base";
import { WorkFlowStepTypes, WorkFlowTypes } from "../../../../models/WorkFlow";
import SetProperties from "../../workflowOverview/createWorkflow/setProperties/setProperties";
import AddSteps from "../../workflowOverview/createWorkflow/addSteps/addSteps";
import { CreatingWorkFlowPage } from "../../workflowOverview/createWorkflow/creatingWorkFlowPage";
import { workflowTemplateStepToWorkflowStep } from "../../../services/template.contracts";
import { AltSecondPageData, FileSource } from "../../workflowOverview/createWorkflow/createWorkflow.types";
import ChoiceInputSource from "../../workflowOverview/createWorkflow/choiceInputSource/choiceInputSource";
import LocalFile from "../../workflowOverview/createWorkflow/localFile/localFile";
import TeamsFile from "../../workflowOverview/createWorkflow/teamsFile/teamsFile";
import EdiFile from "../../workflowOverview/createWorkflow/ediFile/ediFile";
import { workflowStepToShort } from "../../../services/workflow.contracts";
import TeamsSpinner from "../../../../common/components/teamsSpinner/teamsSpinner";
import { FilePreset } from "../../workflowOverview/createWorkflow/choiceInputSource/choiceInputSource.types";
import AddDisjointment from "../../workflowOverview/createWorkflow/addDisjointment/addDisjointment";
import { mapper } from "../../../../utilities/mapper";
import WorkflowSummary from "../../common/workflowSummary/workflowSummary";
import { PreviewSource } from "../../../../common/components/filePreview/filePreviewSource";

const getClassNames = classNamesFunction<ICreateTemplateModalPropsStyles, ICreateTemplateModalStyles>();

export const CreateTemplateModalBase = (props: ICreateTemplateModalProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const selectedWorkflowLab = useWorkflowState().workflowList.selectedWorkflowLab;
    const { t } = useTranslation(['createTemplateModal', 'common']);

    useEffect(() =>{
        setFirstStepData({isValid: false, name: props.template.name, description: props.template.description, disjointed: props.template.disjointed})
        setStepsData({
            ...stepsData,
            steps: props.template.steps.map(s => mapper.mapWorkflowTemplateStepToWorkflowStep(s))})
        if(props.template.documentName !== undefined && props.template.documentName !== null)
            setFilePresetData({fileName: props.template.documentName});
        else
            setFilePresetData(undefined);
        setFileEditable(props.template.isDocumentEditable ?? false);
        setTemplateIdUpdate(props.template.id);
        setDisjoinmentPageData({ disjointments: mapper.mapWorkflowTemplateLabelToWorkFlowDisjointment(props.template.labelsCategory) })
    
        props.template.documentName !== undefined && props.template.documentName !== null ? setIsSourceFile(true) : setIsSourceFile(false);
    }, [props])  //eslint-disable-line react-hooks/exhaustive-deps

    const [firstStepData, setFirstStepData] = useState<FirstPageData>({
        isValid: false,
        name: props.template.name,
        description: props.template.description,
        disjointed: props.template.disjointed
    });

    const [teamsPageData, setTeamsPageData] = useState<SecondPageData>({ isPdf: false, file: undefined });
    const [ediPageData, setEdiPageData] = useState<SecondPageData>({ isPdf: false, file: undefined });
    const [file, setFile] = useState<File[]>([]);
    const [fileValid, setFileValid] = useState(false);
    const [fileEditable, setFileEditable] = useState(props.template.isDocumentEditable ?? false);

    const [stepsData, setStepsData] = useState<ThirdPageData>({
        digitalSignEnabled: false,
        steps: props.template.steps.map(s => workflowTemplateStepToWorkflowStep(s))
    });

    const [filePresetData, setFilePresetData] = useState<FilePreset>();
    const [activeStep, setActiveStep] = useState<CreateTemplateModalEnum>(props.modalStep ?? CreateTemplateModalEnum.setProprietiesStep);
    const [fileSource, setFileSource] = useState<FileSource | undefined>(undefined);
    const [dialogCloseModal, setDialogCloseModal] = useState(false);
    const [templateIdUpdate, setTemplateIdUpdate] = useState<number | undefined>(undefined);
    const [removeFile, setRemoveFile] = useState<boolean>(false);
    const [isSourceFile, setIsSourceFile] = useState<boolean>(false);
    const [disjointmentPageData, setDisjoinmentPageData] = useState<AltSecondPageData>({disjointments:[]})

    const resetDocumentFile = () => {
        setFilePresetData(undefined);
        setRemoveFile(true);
        setFileSource(undefined);
        setFile([]);
        setFileEditable(false);
        setIsSourceFile(false);
        setTeamsPageData({ isPdf: false, file: undefined });
        setEdiPageData({ isPdf: false, file: undefined });
        setStepsData({ ...stepsData, digitalSignEnabled: false });
    }

    // Method for manage next action for modal
    const next = useCallback((fileSource?: FileSource) => {
        switch (activeStep) {
            case CreateTemplateModalEnum.setProprietiesStep:
                if(firstStepData.disjointed)
                    return setActiveStep(CreateTemplateModalEnum.addDisjointmentLabelsStep);
                return setActiveStep(CreateTemplateModalEnum.setFileSourceStep);

            case CreateTemplateModalEnum.addDisjointmentLabelsStep:
                return setActiveStep(CreateTemplateModalEnum.setFileSourceStep);

            case CreateTemplateModalEnum.setFileSourceStep:{
                if (fileSource !== undefined) {
                    if (fileSource === FileSource.local) {                        
                        return setActiveStep(CreateTemplateModalEnum.localUploadStep);
                    }
                    if (fileSource === FileSource.edi) {
                        return setActiveStep(CreateTemplateModalEnum.ediUploadStep);
                    }
                    if (fileSource === FileSource.teams) {
                        return setActiveStep(CreateTemplateModalEnum.teamsUploadStep);
                    }
                }
                return setActiveStep(CreateTemplateModalEnum.addStepsStep);
            }

            case CreateTemplateModalEnum.localUploadStep:{
                if(file[0]?.name !== undefined){
                    setFilePresetData({fileName: file[0]?.name});
                    setIsSourceFile(false);
                }
                return setActiveStep(CreateTemplateModalEnum.addStepsStep);
            }

            case CreateTemplateModalEnum.ediUploadStep:{
                if(ediPageData.file?.name !== undefined){
                    setFilePresetData({fileName: ediPageData.file?.name});
                    setIsSourceFile(false);
                }
                return setActiveStep(CreateTemplateModalEnum.addStepsStep);
            }

            case CreateTemplateModalEnum.teamsUploadStep:{
                if(teamsPageData.file?.name !== undefined && teamsPageData.file?.url !== undefined){
                    setFilePresetData({fileName: teamsPageData.file?.name});
                    setIsSourceFile(false);
                }
                return setActiveStep(CreateTemplateModalEnum.addStepsStep);
            }

            case CreateTemplateModalEnum.addStepsStep:
                return setActiveStep(CreateTemplateModalEnum.overviewStep);

            case CreateTemplateModalEnum.overviewStep:
                return setActiveStep(CreateTemplateModalEnum.creatingStep);

            default: return () => undefined;
        }
    }, [activeStep, file, ediPageData, teamsPageData, firstStepData.disjointed])

    // Method for manage previous action for modal
    const previous = useCallback(() => {
        switch (activeStep) {
            case CreateTemplateModalEnum.setProprietiesStep:
                if (props.onCloseClick) {
                    props.onCloseClick();
                }
                return;

            case CreateTemplateModalEnum.setFileSourceStep:{
                if (firstStepData.disjointed) return setActiveStep(CreateTemplateModalEnum.addDisjointmentLabelsStep)
                else return setActiveStep(CreateTemplateModalEnum.setProprietiesStep);}

            case CreateTemplateModalEnum.localUploadStep:{
                if(file[0]?.name !== undefined && fileValid){
                    setFilePresetData({fileName: file[0]?.name});
                    setIsSourceFile(false);
                }
                else
                    resetDocumentFile();
                return setActiveStep(CreateTemplateModalEnum.setFileSourceStep);
            }

            case CreateTemplateModalEnum.ediUploadStep:{
                if(ediPageData.file?.name !== undefined){
                    setFilePresetData({fileName: ediPageData.file?.name});
                    setIsSourceFile(false);
                }
                else
                    resetDocumentFile();
                return setActiveStep(CreateTemplateModalEnum.setFileSourceStep);
            }

            case CreateTemplateModalEnum.teamsUploadStep:{
                if(teamsPageData.file?.name !== undefined && teamsPageData.file?.url !== undefined){
                    setFilePresetData({fileName: teamsPageData.file?.name});
                    setIsSourceFile(false);
                }
                else
                    resetDocumentFile();
                return setActiveStep(CreateTemplateModalEnum.setFileSourceStep);
            }

            case CreateTemplateModalEnum.addStepsStep:
                return setActiveStep(CreateTemplateModalEnum.setFileSourceStep);

            case CreateTemplateModalEnum.overviewStep:
                return setActiveStep(CreateTemplateModalEnum.addStepsStep);

            case CreateTemplateModalEnum.addDisjointmentLabelsStep:
                return setActiveStep(CreateTemplateModalEnum.setProprietiesStep)

            default: return () => undefined;
        }
    }, [activeStep, props, file, ediPageData, teamsPageData]) //eslint-disable-line react-hooks/exhaustive-deps

    const checkStepValidation = useCallback((): boolean => {
        let result = false;

        stepsData.steps.forEach(step => 
            result = result ||
            step.name === "" ||
            (step.approvers.length === 0 && firstStepData.disjointed === false) ||
            ((step.usersAndLabels?.length === 0 || step.usersAndLabels === undefined) && firstStepData.disjointed) ||
            (step.type === WorkFlowStepTypes.OnlyOneSignedApprover && step.approvers.length > 1));

        if (!stepsData.digitalSignEnabled) {
            stepsData.steps.forEach(step => {
                result = result || step.type === WorkFlowStepTypes.OnlyOneSignedApprover
            })
        }
        return result;
    }, [stepsData, firstStepData.disjointed]);

    const footer = useMemo(() => {
        return (
            <div className={classNames.requiredContainer} >
                {
                activeStep !== CreateTemplateModalEnum.setFileSourceStep && 
                activeStep !== CreateTemplateModalEnum.overviewStep &&
                activeStep !== CreateTemplateModalEnum.localUploadStep &&
                activeStep !== CreateTemplateModalEnum.ediUploadStep &&
                activeStep !== CreateTemplateModalEnum.teamsUploadStep &&
                activeStep !== CreateTemplateModalEnum.addDisjointmentLabelsStep ?
                <Label className={classNames.requiredSign}>*{t("common:fieldRequired")} </Label> : 
                <div></div>
                }
                <div className={classNames.buttonsContainer} >
                    <DefaultButton
                        disabled={false}
                        text={t("common:previousButton")}
                        onClick={() => previous()}
                    />
                    <PrimaryButton
                        text={t("common:nextButton")}
                        disabled={
                            (activeStep === CreateTemplateModalEnum.setProprietiesStep && !firstStepData.isValid) ||
                            (activeStep === CreateTemplateModalEnum.addDisjointmentLabelsStep && (disjointmentPageData.disjointments.find(e=>e.labels.length===0)!==undefined || disjointmentPageData.disjointments.length===0)) ||
                            (activeStep === CreateTemplateModalEnum.addStepsStep && checkStepValidation()) ||
                            (activeStep === CreateTemplateModalEnum.teamsUploadStep && teamsPageData.file?.name === undefined) ||
                            (activeStep === CreateTemplateModalEnum.localUploadStep && !fileValid) ||
                            (activeStep === CreateTemplateModalEnum.ediUploadStep && ediPageData.file === undefined)
                        }
                        onClick={() => next()}
                    />
                </div>
            </div>
        );
    }, [classNames.buttonsContainer, activeStep, disjointmentPageData, next, previous, checkStepValidation, firstStepData.isValid, file, teamsPageData.file, ediPageData]); //eslint-disable-line react-hooks/exhaustive-deps

    const footerTemplateDetails = useMemo(() => {
        return (
            <div className={classNames.buttonsContainer} >
                <PrimaryButton
                    text={t("common:close")}
                    onClick={() => { if(props.onCloseClick) props.onCloseClick()}}
                />
            </div>
        );
    }, [classNames.buttonsContainer, props, t]);

    const fileSelectedIsPdf = useMemo(() => {
        switch (fileSource) {
            case FileSource.local:
                if (file && file.length > 0) {
                    return file[0].type === "application/pdf"
                }
                return false;

            case FileSource.edi:
                return ediPageData.isPdf;

            case FileSource.teams:
                return teamsPageData.isPdf;
            
            default:
                if(filePresetData?.fileName !== undefined)
                    return filePresetData.fileName.search(".pdf") !== -1;
                else
                    return false;
        }
    }, [fileSource, file, ediPageData.isPdf, teamsPageData.isPdf, filePresetData]);

    useEffect(() => {
        setStepsData({
            ...stepsData,
            digitalSignEnabled: fileSelectedIsPdf
        });
    }, [fileSelectedIsPdf]); //eslint-disable-line react-hooks/exhaustive-deps

    const getMultiStepModal: IModalStepData[] = [
        {
            body: !props.loadingInfo ? <SetProperties isTemplate={true} translationName="createTemplateModal" initialState={firstStepData} onWorkflowDataChange={setFirstStepData} /> : <div className={classNames.spinnerContainer}><TeamsSpinner /></div>,
            footer: footer
        },        
        {
            //the modal for the selection of the disjointment labels goes here!
            body: <AddDisjointment
                    initialState={disjointmentPageData}
                    onWorkflowDataChange={setDisjoinmentPageData}
                  />,
            footer: footer
        },
        {
            body: <ChoiceInputSource onElementChoice={(value) => { next(value); setFileSource(value); }} filePresetData={filePresetData} templateId={props.template.id} setFilePresetData={(document) => {setFilePresetData(document); resetDocumentFile()}} isSourceFile={isSourceFile} />,
            footer: footer
        },
        {
            body: <LocalFile onFileUploadMetadata={setFile} onValidateFile={setFileValid} onFileEdit={setFileEditable} />,
            footer: footer
        },
        {
            body: <TeamsFile initialState={teamsPageData} onWorkflowDataChange={setTeamsPageData} onFileEdit={setFileEditable} />,
            footer: footer
        },
        {
            body: <EdiFile 
                    initialState={ediPageData} 
                    onWorkflowDataChange={setEdiPageData} 
                    onFileEdit={setFileEditable} 
                  />,
            footer: footer
        },
        {
            body: <AddSteps 
                    fileType={fileSelectedIsPdf} 
                    onWorkflowDataChange={setStepsData} 
                    initialState={stepsData} 
                    disjointed={firstStepData.disjointed}
                    maxSteps={15}
                  />,
            footer: footer
        },
        {
            body: !props.loadingInfo ? <WorkflowSummary
                translationName="workflowTemplateDetails"
                isDocumentClickable={props.overview ? true : false}
                isTemplateSummary={true}
                previewSource={PreviewSource.Template}
                onReload={() => console.log("")}
                workFlow={
                    {
                        id: props.template.id ?? 0,
                        name: firstStepData.name,
                        description: firstStepData.description,
                        type: WorkFlowTypes.Approval,
                        documentName: filePresetData?.fileName,
                        steps: stepsData.steps.slice(),
                        involvedPeople: [],
                        isDocumentEditable: fileEditable
                    }
                }
                workFlowDisjointment={disjointmentPageData.disjointments}                
            /> : <div className={classNames.spinnerContainer}><TeamsSpinner /></div>,
            footer: props.overview ? footerTemplateDetails : footer
        },
        {
            body: <CreatingWorkFlowPage
                isTemplate = {true}
                templateIdUpdate = {templateIdUpdate}
                removeFile = {removeFile}
                translationName="createTemplateModal"
                file = {file}
                onSuccess = {props.onSuccess}
                onClose = {props.onCloseClick}
                request = {
                    {
                        name: firstStepData.name,
                        description: firstStepData.description ?? '',
                        workFlowLabId: selectedWorkflowLab,
                        steps: stepsData.steps.map(s => workflowStepToShort(s)),
                        fileSource: fileSource,
                        teamId: teamsPageData.file?.teamId,
                        teamFileId: teamsPageData.file?.uniqueId,
                        ediFileId: ediPageData.file?.id,
                        isDocumentEditable: fileEditable,
                        LabelCategoryIds: disjointmentPageData.disjointments.map(d => d.labels.map(l => l.labelCategoryId ? l.labelCategoryId : 0)).flat(),
                        disjointed: firstStepData.disjointed,
                    }
                } 
            />
        }
    ]

    return (
        <>
        <MultiStepModal
            activeStep={activeStep}
            steps={getMultiStepModal}
            isOpen={true}
            width={650}
            height={750}
            showCloseIcon={activeStep !== CreateTemplateModalEnum.creatingStep }
            onCloseClick={props.overview ? () => {if(props.onCloseClick) props.onCloseClick()} : () => setDialogCloseModal(true)}
            title={t('title')}
            subtitle={props.overview ? '' : templateIdUpdate !== undefined && templateIdUpdate !== 0 ? t('subtitle2') : t('subtitle')}
        />
        <Dialog
            hidden={!dialogCloseModal}
            dialogContentProps={templateIdUpdate !== undefined && templateIdUpdate !== 0 ?
                {
                    title: t('checkDeleteEditTemplate.title'),
                    subText: t('checkDeleteEditTemplate.message'),   
                }
                :
                {
                    title: t('checkDeleteWorkflowTemplate.title'),
                    subText: t('checkDeleteWorkflowTemplate.message'),
                }
            }
        >
            <div className={classNames.buttonsContainer} >
                <DefaultButton
                    text={t("common:dialogButtonCancel")}
                    onClick={() => setDialogCloseModal(false)}
                />
                <PrimaryButton
                    text={t("common:dialogButtonConfirmClose")}
                    onClick={() => { if(props.onCloseClick) props.onCloseClick()}}
                />
            </div>
        </Dialog>
        </>
    );
}