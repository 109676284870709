import React from "react";
import { classNamesFunction, Icon, Stack } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { IWorkflowLabManageTemplatesProps, IWorkflowLabManageTemplatesPropsStyles, IWorkflowLabManageTemplatesStyles } from "./workflowLabManageTemplates.types";
import WorkflowLabTemplatesSettings from "./workflowLabTemplatesSettings/workflowLabTemplatesSettings";

const getClassNames = classNamesFunction<IWorkflowLabManageTemplatesPropsStyles, IWorkflowLabManageTemplatesStyles>();
export const WorkflowLabManageTemplates = (props: IWorkflowLabManageTemplatesProps) => {
    const classNames = getClassNames(props.styles,
        {
            theme: props.theme,
            className: props.className
        });
    const { t } = useTranslation(['workflowLabManageTemplates']);

    return (
        <>
            <div className={classNames.root}>
                <Stack className={classNames.pageTitle} horizontal verticalAlign={"center"}>
                    <Icon iconName={"AutoFillTemplate"} className={classNames.titleIcon} />
                    <span className={classNames.titleContent}>{t("common:manageTempalte")}</span>
                </Stack>
                <WorkflowLabTemplatesSettings></WorkflowLabTemplatesSettings>
            </div>
        </>
    );
}