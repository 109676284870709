import React from "react";
import { classNamesFunction } from "@fluentui/react";
import { useMemo } from "react";
import { Provider } from "react-redux";
import { Route, useHistory } from "react-router-dom";
import { useOnMount } from "../../../utilities/hooks";
import workflowStore from "../../../workflow/workflowStore";
import WorkflowRoot from "../../../workflow/root/root";
import { AppRoutes, AppTab } from "../../utilities/routes";
import Header from "../header/header";
import { ITeamsProps, ITeamsPropsStyles, ITeamsStyles } from "./teams.types";



const getClassNames = classNamesFunction<ITeamsPropsStyles, ITeamsStyles>();

export const TeamsBase = (props: ITeamsProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const workflowRoute = useMemo(() => AppRoutes.generateRouteForTab(AppTab.workflow), []);
    const history = useHistory();
    useOnMount(() => {
        if (props.teamsContext?.subEntityId) {
            history.replace(atob(props.teamsContext?.subEntityId))
        }
    })

    return (
        <div className={classNames.root}>
            <Header />
            <Route path={workflowRoute}>
                <Provider store={workflowStore}>
                    <WorkflowRoot ctx={props.teamsContext} />
                </Provider>
            </Route>
        </div>
    );
}