import { BasePeoplePicker, FontWeights, IBasePickerProps, IBasePickerStyles, IBreadcrumbStyleProps, IBreadcrumbStyles, IButtonProps, IButtonStyles, ICheckboxStyleProps, ICheckboxStyles, IComboBoxProps, IComboBoxStyles, ICommandBarStyleProps, ICommandBarStyles, IContextualMenuItemProps, IContextualMenuItemStyles, IContextualMenuProps, IContextualMenuStyles, IDetailsColumnProps, IDetailsColumnStyles, IDetailsHeaderProps, IDetailsHeaderStyles, IDetailsRowProps, IDetailsRowStyles, IDialogContentProps, IDialogContentStyles, IDialogFooterProps, IDialogFooterStyles, IDocumentCardProps, IDocumentCardStyles, IDropdownStyleProps, IDropdownStyles, IModalProps, IModalStyles, INavStyleProps, INavStyles, IPanelProps, IPanelStyles, IPivotStyleProps, IPivotStyles, ISearchBoxProps, ISearchBoxStyles, ISuggestionsItemStyleProps, ISuggestionsItemStyles, ISuggestionsStyleProps, ISuggestionsStyles, ITextFieldProps, ITextFieldStyles, ITooltipStyleProps, ITooltipStyles } from '@fluentui/react';
const standardBorder = "1px 1px 2px 1px";
const borderWhite = "1px solid #fff";
const standardShadow = "0 2px 4px -0.75px rgba(0,0,0,.1)";
const otherBoxShadow = "0 2px 4px -0.75px rgba(0,0,0,.25)";

export const comboBoxStyles = (props: IComboBoxProps): IComboBoxStyles => {
  return {
    container: {},
    label: {},
    labelDisabled: {},
    rootDisallowFreeForm: {},
    errorMessage: {},
    optionsContainerWrapper: {},
    screenReaderText: {},
    divider: {
      margin: "4px 0",
      height: "2px",
      backgroundColor: "#fff",
      border: "none"
    },
    root: {
      borderRadius: "3px",
      paddingLeft: "12px",
      borderWidth: "1px 1px 1px 1px",
      borderStyle: "solid",
      borderColor: "#fff",
      backgroundColor: "#000",
      color: "#fff",
      minWidth: "auto",
      paddingTop: "1px",
      selectors: {
        "::after": {
          display: "none"
        },
        "&.is-open": {
          borderWidth: standardBorder,
          borderColor: "#fff",
          borderBottomColor: "#ffff00",
          borderBottomRightRadius: "2px",
          borderBottomLeftRadius: "2px"
        },
        ".ms-Button--icon": {
          width: "34px",
          backgroundColor: "transparent",
          position: "absolute",
          right: "-1px",
          selectors: {
            ":hover": {
              color: "inherit",
              backgroundColor: "transparent"
            }
          }
        },
        ".ms-Button-icon": {
          color: "#fff",
          fontSize: "12px",
          fontWeight: "bold",
          position: "relative",
          top: "2px",
          selectors: {
            ".icons-default-fill": {
              fill: "#fff"
            }
          }
        }
      }
    },
    rootError: {
      borderBottomRightRadius: "2px",
      borderBottomLeftRadius: "2px",
      borderWidth: standardBorder
    },
    input: {
      backgroundColor: "transparent",
      height: "30px",
      lineHeight: "30px",
      color: "#fff",
      selectors: {
        "::placeholder": {
          color: "#fff"
        },
        ":-ms-input-placeholder": {
          color: "#fff"
        }
      }
    },
    inputDisabled: {
      color: "#fff"
    },
    rootPressed: {
      borderWidth: standardBorder,
      borderColor: "#fff",
      borderBottomColor: "#ffff00",
      borderBottomRightRadius: "2px",
      borderBottomLeftRadius: "2px"
    },
    rootFocused: {
      borderWidth: standardBorder,
      borderColor: "#fff",
      borderBottomColor: "#ffff00",
      borderBottomRightRadius: "2px",
      borderBottomLeftRadius: "2px",
      selectors: {
        input: {
          selectors: {
            "::placeholder": {
              color: "#fff"
            },
            ":-ms-input-placeholder": {
              color: "#fff"
            }
          }
        }
      }
    },
    rootHovered: {
      borderColor: "#fff",
      selectors: {
        ".ms-Button": {
          backgroundColor: "transparent"
        },
        ".ms-ComboBox-Input": {
          color: "inherit"
        }
      }
    },
    rootDisabled: {
      opacity: 1,
      borderColor: "#30f42c",
      selectors: {
        input: {
          color: "#30f42c"
        },
        ".ms-Button-icon": {
          color: "#30f42c"
        }
      }
    },
    callout: {
      selectors: {
        ".ms-Callout-main": {
          paddingTop: "8px",
          paddingBottom: "8px"
        },
        ".is-checked": {
          backgroundColor: "#00ebff",
          color: "#000"
        },
        ".ms-Fabric--isFocusVisible &": {
          selectors: {
            ".is-checked": {
              backgroundColor: "#ffff00",
              selectors: {
                ":after": {
                  borderColor: "transparent",
                  outline: "none"
                }
              }
            }
          }
        }
      }
    },
    header: {
      padding: "0 8px",
      color: "#fff"
    },
    optionsContainer: {
      selectors: {
        ".ms-Button": {
          padding: "4px 16px",
          minHeight: "32px",
          height: "32px",
          whiteSpace: "noWrap",
          backgroundColor: "transparent",
          color: "#fff",
          selectors: {
            ":hover": {
              backgroundColor: "#ffff00",
              color: "#000"
            },
            ":focus": {
              backgroundColor: "#ffff00",
              color: "#000"
            },
            ":active": {
              backgroundColor: "#ffff00",
              color: "#000"
            },
            ":hover:active": {
              backgroundColor: "#ffff00 !important"
            }
          }
        },
        ".ms-ComboBox-optionText": {
          overflow: "visible"
        },
        ".ms-Checkbox-text": {
          color: "#fff"
        },
        ".ms-Checkbox:hover": {
          selectors: {
            ".ms-Checkbox-text": {
              color: "#fff",
              selectors: {
                ":hover": {
                  color: "#fff"
                }
              }
            }
          }
        },
        ".is-disabled": {
          color: "#30f42c"
        }
      }
    }
  }
}

export const commandBarStyles = (props: ICommandBarStyleProps): ICommandBarStyles => {
  return {
    root: {
      height: 40,
      backgroundColor: "#000",
      borderTop: borderWhite,
      borderBottom: borderWhite,
      selectors: {
        ".icons-filled": {
          display: "none"
        },
        ".icons-unfilled": {
          display: "block"
        },
        svg: {
          height: 40,
          margin: "0 -9px",
          width: "32px"
        },
        "svg, .icons-default-fill": {
          fill: "#ffff00"
        },
        ".ms-Button-menuIcon, .ms-Button-icon": {
          color: "#ffff00",
          fontSize: "14px",
          minWidth: "14px",
          lineHeight: "40px",
          height: "40px"
        },
        ".ms-Button-menuIcon": {
          fontSize: "12px",
          minWidth: "12px",
          lineHeight: "40px",
          height: "40px",
          marginLeft: "6px",
          marginTop: "1px",
          selectors: {
            svg: {
              width: "20px",
              height: "38px",
              margin: "0 -4px"
            }
          }
        },
        ".ms-Button--commandBar": {
          border: "1px solid transparent"
        },
        ".ms-CommandBarItem-link": {
          textDecoration: "none",
          border: "1px solid transparent",
          color: "#fff"
        },
        "button, a": {
          marginRight: "16px",
          minWidth: "auto"
        },
        button: {
          height: "100%"
        },
        ".ms-OverflowSet-item:last-child button,  .ms-OverflowSet-item:last-child a": {
          marginRight: "0"
        },
        "a, a:link, a:visited, a:hover, button, button:active, button:hover": {
          backgroundColor: "transparent",
          color: "#ffff00"
        },
        "button:hover": {
          color: "#00ebff",
          selectors: {
            "svg, .icons-default-fill": {
              fill: "#00ebff"
            },
            ".ms-Button-menuIcon, .ms-Button-icon": {
              fontWeight: "bold",
              color: "#00ebff",
              backgroundColor: "transparent"
            },
            ".icons-filled": {
              display: "block"
            },
            ".icons-unfilled": {
              display: "none"
            },
            "[data-icon-name='StatusErrorFull']": {
              fontWeight: 400,
              color: "#fff"
            }
          }
        },
        ".ms-Fabric--isFocusVisible &": {
          selectors: {
            ".ms-Button:focus": {
              selectors: {
                ":after": {
                  borderColor: "#000",
                  outline: borderWhite
                }
              }
            }
          }
        },
        ".is-expanded": {
          color: "#00ebff",
          selectors: {
            "svg, .icons-default-fill": {
              fill: "#00ebff"
            },
            ".ms-Button-menuIcon, .ms-Button-icon": {
              fontWeight: "bold",
              color: "#00ebff",
              backgroundColor: "transparent"
            },
            ".icons-filled": {
              display: "block"
            },
            ".icons-unfilled": {
              display: "none"
            }
          }
        },
        "a:hover": {
          color: "#00ebff",
          selectors: {
            "svg, .icons-default-fill": {
              fill: "#00ebff"
            },
            ".ms-Button-menuIcon, .ms-Button-icon": {
              fontWeight: "bold",
              color: "#00ebff",
              backgroundColor: "transparent"
            },
            ".icons-filled": {
              display: "block"
            },
            ".icons-unfilled": {
              display: "none"
            }
          }
        },
        ".ms-CommandBar-overflowButton": {
          minWidth: "auto",
          selectors: {
            ".ms-Button-menuIcon": {
              margin: 0,
              fontSize: "16px"
            }
          }
        },
        "[data-icon-name='StatusErrorFull']": {
          fontWeight: 400,
          color: "#fff"
        }
      }
    },
    secondarySet: {
      selectors: {
        ".ms-Button-icon": {
          width: "auto"
        }
      }
    }
  }
}

export const commandBarButtonStyles = (props: IContextualMenuItemProps): IContextualMenuItemStyles => {
  return {
    root: {
      outline: "transparent",
      position: "relative",
      selectors: {
        "::-moz-focus-inner": {
          border: "0"
        },
        ".ms-Fabric--isFocusVisible &:focus:after": {
          content: "\"\"",
          position: "absolute",
          left: 3,
          top: 3,
          bottom: 3,
          right: 3,
          border: borderWhite,
          outline: "1px solid #979593",
          zIndex: 1,
          selectors: {}
        }
      }
    }
  }
}

export const compoundButtonStyles = (props: IButtonProps): IButtonStyles => {
  return {
    root: {
      borderRadius: "2px",
      boxShadow: standardShadow,
      backgroundColor: "#000",
      borderColor: "#fff",
      borderWidth: "2px",
      color: "#fff",
      minHeight: "auto",
      padding: "12px",
      selectors: {
        ".ms-Button-icon": {
          color: "inherit"
        },
        ".icons-default-fill": {
          fill: "inherit"
        },
        svg: {
          width: "32px",
          height: "32px",
          padding: "6px",
          display: "block",
          boxSizing: "content-box",
          borderRadius: "3px",
          fill: "#000",
          background: "#fff"
        },
        ".icons-unfilled": {
          display: "none"
        },
        ".icons-filled": {
          display: "block"
        },
        ".ms-Fabric--isFocusVisible &": {
          selectors: {
            ":focus::after": {
              outline: "none",
              borderRadius: "2px",
              borderColor: "#000",
              borderWidth: "1px",
              borderStyle: "solid",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              display: "none"
            },
            ":focus": {
              borderColor: "#ffff00",
              selectors: {
                svg: {
                  background: "#000",
                  fill: "#fff"
                }
              },
              backgroundColor: "#ffff00",
              color: "#000"
            }
          }
        },
        "&.ms-Button--compoundPrimary": {
          backgroundColor: "#fff",
          borderColor: "#fff",
          boxShadow: otherBoxShadow,
          color: "#000",
          selectors: {
            svg: {
              fill: "#fff",
              background: "#000"
            }
          }
        },
        ".ms-Button-description": {
          color: "inherit !important"
        }
      }
    },
    rootHovered: {
      selectors: {
        "&.ms-Button--compound:hover": {
          backgroundColor: "#ffff00",
          borderColor: "#ffff00",
          color: "#000",
          selectors: {
            svg: {
              fill: "#fff",
              background: "#000"
            }
          }
        },
        "&.ms-Button--compoundPrimary:hover": {
          backgroundColor: "#ffff00",
          borderColor: "#ffff00",
          color: "#000",
          selectors: {
            svg: {
              fill: "#fff"
            }
          }
        }
      }
    },
    rootPressed: {
      boxShadow: "none",
      selectors: {
        "&.ms-Button--compound:active": {
          backgroundColor: "#ffff00",
          borderColor: "#ffff00",
          color: "#000",
          selectors: {
            svg: {
              fill: "#fff",
              background: "#000"
            }
          }
        },
        "&.ms-Button--compoundPrimary:active": {
          backgroundColor: "#ffff00",
          borderColor: "#ffff00",
          color: "#000",
          selectors: {
            svg: {
              fill: "#fff"
            }
          }
        }
      }
    },
    rootDisabled: {
      backgroundColor: "#000",
      color: "#30f42c",
      borderColor: "#30f42c",
      boxShadow: "none",
      selectors: {
        svg: {
          fill: "#000",
          background: "#30f42c"
        },
        "&.ms-Button--compoundPrimary.is-disabled": {
          backgroundColor: "#30f42c",
          borderColor: "#30f42c",
          color: "#000",
          boxShadow: "none",
          selectors: {
            svg: {
              fill: "#30f42c",
              background: "#000"
            }
          }
        }
      }
    },
    rootChecked: {
      backgroundColor: "#fff",
      borderColor: "#fff",
      color: "#000",
      selectors: {
        "&.ms-Button--compoundPrimary.is-checked": {
          backgroundColor: "#ffff00",
          borderColor: "#ffff00",
          color: "#000"
        }
      }
    },
    rootCheckedHovered: {
      selectors: {
        "&.ms-Button--compoundPrimary.is-checked:hover": {
          backgroundColor: "#ffff00",
          borderColor: "#ffff00",
          color: "#000"
        }
      }
    },
    rootCheckedDisabled: {
      backgroundColor: "#000",
      color: "#30f42c",
      borderColor: "#30f42c",
      boxShadow: "none",
      selectors: {
        "&.ms-Button--compoundPrimary.is-checked.is-disabled": {
          backgroundColor: "#30f42c",
          borderColor: "#30f42c",
          color: "#000",
          boxShadow: "none"
        }
      }
    },
    flexContainer: {
      alignItems: "center"
    },
    textContainer: {
      flexShrink: "1"
    },
    icon: {
      width: "auto",
      height: "auto",
      marginRight: "12px"
    },
    description: {
      color: "inherit",
      fontSize: "14px",
      opacity: 0.84
    }
  }
}

export const defaultButtonStyles = (props: IButtonProps): IButtonStyles => {
  const { palette } = props.theme!; // eslint-disable-line @typescript-eslint/no-non-null-assertion

  return {
    root: {
      borderRadius: "2px",
      backgroundColor: palette.white,
      borderColor: palette.black,
      color: palette.black,
      boxShadow: standardShadow,
      cursor: "pointer",
      minWidth: "96px",
      padding: "0 16px",
      selectors: {
        ".icons-default-fill": {
          fill: palette.black
        },
        ".icons-filled": {
          display: "none"
        }
      },
      borderWidth: "2px"
    },
    rootHovered: {
      backgroundColor: palette.themeDark,
      borderColor: palette.themeDark,
      color: palette.white,
      selectors: {
        "svg, .icons-default-fill": {
          fill: palette.white
        },
        ".ms-Fabric--isFocusVisible &": {
          selectors: {
            ":focus::after": {
              outline: "none",
              borderRadius: "2px",
              borderColor: palette.themeDark,
              borderWidth: "1px",
              borderStyle: "solid",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0
            },
            ":focus": {
              borderColor: palette.themeDark
            }
          }
        }
      }
    },
    rootPressed: {
      backgroundColor: palette.themeDark,
      borderColor: palette.themeDark,
      boxShadow: "none",
      color: palette.white
    },
    rootChecked: {
      backgroundColor: palette.black,
      borderColor: palette.black,
      color: palette.white,
      selectors: {
        "svg, .icons-default-fill": {
          fill: palette.white
        },
        ".ms-Fabric--isFocusVisible &": {
          selectors: {
            ":focus::after": {
              outline: "none",
              borderRadius: "2px",
              borderColor: palette.themeDark,
              borderWidth: "1px",
              borderStyle: "solid",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0
            },
            ":focus": {
              borderColor: palette.themeDark
            }
          }
        }
      }
    },
    rootCheckedHovered: {
      selectors: {
        "svg, .icons-default-fill": {
          fill: palette.black
        },
        ".ms-Fabric--isFocusVisible &": {
          selectors: {
            ":focus::after": {
              outline: "none",
              borderRadius: "2px",
              borderColor: palette.themeDark,
              borderWidth: "1px",
              borderStyle: "solid",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0
            },
            ":focus": {
              borderColor: palette.themeDark
            }
          }
        }
      }
    },
    rootDisabled: {
      backgroundColor: palette.white,
      color: "#30f42c",
      borderColor: "#30f42c",
      boxShadow: "none",
      selectors: {
        "svg, .icons-default-fill": {
          fill: "#30f42c"
        },
        ".ms-Fabric--isFocusVisible &": {
          selectors: {
            ":focus::after": {
              outline: "none",
              borderRadius: "2px",
              borderColor: palette.themeDark,
              borderWidth: "1px",
              borderStyle: "solid",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0
            },
            ":focus": {
              borderColor: palette.themeDark
            }
          }
        }
      },
      borderWidth: "2px"
    },
    rootFocused: {
      boxShadow: standardShadow,
      selectors: {
        "svg, .icons-default-fill": {
          fill: palette.white
        },
        ".ms-Fabric--isFocusVisible &": {
          selectors: {
            ":focus::after": {
              outline: "none",
              borderRadius: "2px",
              borderColor: palette.themeDark,
              borderWidth: "1px",
              borderStyle: "solid",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0
            },
            ":focus": {
              borderColor: palette.themeDark
            }
          }
        }
      },
      color: palette.white,
      borderWidth: "2px",
      backgroundColor: palette.themeDark,
      borderColor: palette.themeDark
    },
    icon: {
      height: "32px",
      selectors: {
        svg: {
          height: "32px",
          width: "32px",
          marginRight: "-9px",
          marginLeft: "-9px"
        }
      }
    },
    menuIcon: {
      selectors: {
        svg: {
          height: "28px",
          width: "28px",
          marginRight: "-8px",
          marginLeft: "-8px",
          marginTop: "-9px",
          marginBottom: "-9px"
        }
      }
    },
    splitButtonMenuButton: {
      borderTopRightRadius: "2px",
      borderBottomRightRadius: "2px",
      borderWidth: "2px",
      borderStyle: "solid",
      background: palette.white,
      borderLeft: 0,
      color: palette.black,
      marginLeft: 0,
      selectors: {
        ".icons-filled": {
          display: "none"
        },
        ":hover": {
          backgroundColor: palette.themeDark,
          color: palette.white
        },
        ":active": {
          backgroundColor: palette.themeDark,
          color: palette.white
        },
        ".ms-Button-icon": {
          color: "inherit",
          fontWeight: 700
        }
      }
    },
    splitButtonContainer: {
      selectors: {
        ".ms-Button": {
          borderColor: palette.black,
          backgroundClip: "padding-box"
        },
        ".ms-Button--default": {
          borderRight: 0,
          borderTopRightRadius: "0px",
          borderBottomRightRadius: "0px"
        },
        ".ms-Button--default + .ms-Button": {
          boxShadow: standardShadow
        },
        ".ms-Button--primary": {
          borderRight: 0,
          borderTopRightRadius: "0px",
          borderBottomRightRadius: "0px",
          borderColor: "transparent",
          background: palette.black,
          color: palette.white,
          boxShadow: otherBoxShadow,
          selectors: {
            ":hover": {
              borderColor: "transparent",
              background: palette.themeDark,
              color: palette.white
            },
            ":active": {
              borderColor: "transparent",
              background: palette.themeDark,
              color: palette.white
            },
            "svg, .icons-default-fill": {
              fill: palette.white
            }
          }
        },
        ".ms-Button--primary + .ms-Button": {
          borderColor: "transparent",
          background: palette.black,
          color: palette.white,
          boxShadow: otherBoxShadow,
          selectors: {
            ":hover": {
              borderColor: "transparent",
              background: palette.themeDark,
              color: palette.white
            },
            ":active": {
              borderColor: "transparent",
              background: palette.themeDark,
              color: palette.white
            },
            "svg, .icons-default-fill": {
              fill: palette.white
            }
          }
        },
        ".ms-Button--primary ~ span[class*='css-']": {
          top: 0,
          bottom: 0,
          backgroundColor: palette.white
        },
        ".ms-Button--primary + .ms-Button[aria-expanded='true']": {
          background: palette.themeDark,
          color: palette.white
        },
        ":hover": {
          selectors: {
            ".ms-Button": {
              borderColor: palette.black
            },
            ".ms-Button--primary": {
              borderColor: "transparent"
            },
            ".ms-Button--primary + .ms-Button": {
              borderColor: "transparent"
            }
          }
        },
        ":active": {
          selectors: {
            ".ms-Button": {
              borderColor: palette.black,
              boxShadow: "none"
            },
            ".ms-Button--primary": {
              borderColor: "transparent"
            },
            ".ms-Button--primary + .ms-Button": {
              borderColor: "transparent"
            }
          }
        }
      }
    },
    splitButtonContainerFocused: {
      selectors: {
        ".ms-Fabric--isFocusVisible &": {
          selectors: {
            ":focus::after": {
              outline: "none",
              borderRadius: "3px",
              borderColor: palette.black,
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              boxShadow: "transparent 0 0 0 1px inset"
            },
            ":focus": {
              selectors: {
                ".ms-Button": {
                  backgroundColor: palette.themeDark,
                  color: palette.white
                },
                ".ms-Button ~ span[class*='css-']": {
                  backgroundColor: palette.white
                },
                ".ms-Button--primary": {
                  backgroundColor: palette.themeDark,
                  color: palette.white
                },
                ".ms-Button--primary + .ms-Button": {
                  backgroundColor: palette.themeDark,
                  color: palette.white
                }
              }
            }
          }
        }
      }
    },
    splitButtonMenuButtonExpanded: {
      background: palette.themeDark,
      color: palette.white,
      borderColor: palette.white
    },
    splitButtonDivider: {
      top: 1,
      bottom: 1,
      width: "2px",
      backgroundColor: palette.black
    },
    description: {
      color: "inherit"
    }
  }
}


export const dialogContentStyles = (props: IDialogContentProps): IDialogContentStyles => {
  return {
    title: {
      fontSize: "18px",
      fontWeight: 700,
      padding: "27px 54px 8px 32px",
      lineHeight: "24px",
      color: "#fff"
    },
    topButton: {
      padding: "23px 22px 4px 0",
      fontSize: "14px",
      selectors: {
        ".ms-Dialog-button": {
          color: "#fff"
        },
        ".ms-Dialog-button:hover": {
          color: "#ffff00",
          backgroundColor: "transparent",
          selectors: {
            i: {
              fontWeight: 700
            }
          }
        },
        ".ms-Dialog-button:active": {
          color: "#ffff00"
        }
      }
    },
    inner: {
      padding: "1px 32px 32px 32px",
      color: "#fff",
      selectors: {
        ".ms-Label": {
          color: "#fff"
        },
        ".ms-Button--primary": {
          color: "#000",
          backgroundColor: "#fff"
        },
        ".ms-Button--primary:hover": {
          color: "#000",
          backgroundColor: "#ffff00"
        },
        ".ms-Button--primary:active": {
          color: "#fff",
          backgroundColor: "#ffff00"
        },
        ".ms-Button--primary:focus": {
          color: "#000",
          backgroundColor: "#ffff00"
        }
      }
    },
    innerContent: {
      paddingTop: "4px",
      marginBottom: "20px",
      selectors: {
        ":last-child": {
          marginBottom: 0
        }
      }
    },
    subText: {
      color: "#fff",
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "20px",
      paddingTop: 0
    },
    content: {},
    header: {},
    button: {},
  }
}

export const dialogFooterStyles = (props: IDialogFooterProps): IDialogFooterStyles => {
  return {
    actions: {
      margin: "16px 0 0"
    },
    actionsRight: {},
    action: {}
  }
}

export const documentCardStyles = (props: IDocumentCardProps): IDocumentCardStyles => {
  const { palette } = props.theme!; // eslint-disable-line @typescript-eslint/no-non-null-assertion

  return {
    root: {
      borderRadius: "3px",
      backgroundColor: palette.white,
      borderColor: palette.black,
      borderStyle: "solid",
      borderWidth: "1px",
      boxShadow: standardShadow,
      selectors: {
        ":hover": {
          backgroundColor: palette.white
        },
        ":hover::after": {
          display: "none"
        },
        ":focus": {
          borderColor: palette.black
        },
        ".ms-DocumentCardTitle": {
          color: palette.black,
          fontSize: "14px",
          height: "20px",
          lineHeight: "20px"
        }
      }
    }
  }
}

export const modalStyles = (props: IModalProps): IModalStyles => {
  return {
    root: {
      backgroundColor: "none",
      selectors: {
        ".ms-Overlay": {
          backgroundColor: "rgba(0,0,0,.75)"
        }
      }
    },
    main: {
      backgroundColor: "#000",
      borderRadius: "3px",
      color: "#fff",
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: "#fff",
      boxShadow: "0 16px 18px -4px rgba(0,0,0,.1)",
      minWidth: "400px !important",
      minHeight: "116px !important"
    },
    scrollableContent: {},
    layer: {},
    keyboardMoveIconContainer: {},
    keyboardMoveIcon: {},
  }
}

export const panelStyles = (props: IPanelProps): IPanelStyles => {
  return {
    main: {
      selectors: {
        "&.ms-Panel-main": {
          borderWidth: "2px",
          borderStyle: "solid",
          borderColor: "#fff"
        },
        ".ms-Checkbox": {
          paddingTop: 12,
          paddingBottom: 0,
          selectors: {
            ".ms-Persona": {
              paddingLeft: 4,
              paddingRight: 4,
              paddingTop: 0,
              paddingBottom: 0
            }
          }
        },
        ".ms-ProgressIndicator-itemName": {
          color: "#fff"
        },
        ".ms-ProgressIndicator-itemDescription > span": {
          color: "#fff"
        },
        ".ms-Link": {
          color: "#ffff00",
          selectors: {
            ":hover": {
              color: "#ffff00"
            }
          }
        },
        ".ms-Checkbox-label img": {
          display: "block"
        },
        ".ms-TilesList-grid .ms-ProgressIndicator-itemDescription [class*='description']": {
          color: "#fff"
        },
        ".ms-TilesList-grid [class*='error'] .ms-ProgressIndicator-itemDescription [class*='description']": {
          color: "#fff"
        },
        ".ms-BasePicker-input": {
          backgroundColor: "#000"
        },
        "div .ms-BasePicker-text": {
          border: borderWhite
        }
      }
    },
    overlay: {
      backgroundColor: "rgba(0,0,0,.75)"
    },
    closeButton: {
      color: "#fff",
      selectors: {
        "&:hover": {
          color: "#ffff00",
          backgroundColor: "transparent",
          selectors: {
            i: {
              fontWeight: 700
            }
          }
        },
        "&:active": {
          color: "#ffff00",
          backgroundColor: "transparent",
          selectors: {
            i: {
              fontWeight: 700
            }
          }
        }
      }
    },
    commands: {
      paddingTop: "10px",
      paddingBottom: "20px"
    },
    content: {
      color: "#fff",
      selectors: {
        ".ms-Button--icon": {
          selectors: {
            i: {
              color: "#fff"
            },
            ":hover": {
              backgroundColor: "transparent",
              selectors: {
                i: {
                  fontWeight: "bold",
                  color: "#ffff00"
                }
              }
            },
            ":active": {
              backgroundColor: "transparent"
            }
          }
        }
      }
    },
    headerText: {
      color: "#fff"
    },
    root: {},
    hiddenPanel: {},
    contentInner: {},
    scrollableContent: {},
    navigation: {},
    header: {},
    footer: {},
    footerInner: {},
    subComponentStyles: {
      closeButton: {}
    },
  }
}

export const primaryButtonStyles = (props: IButtonProps): IButtonStyles => {
  return {
    root: {
      backgroundColor: "#fff",
      color: "#000",
      boxShadow: otherBoxShadow,
      borderColor: "#fff",
      selectors: {
        "svg, .icons-default-fill": {
          fill: "#000"
        }
      }
    },
    rootHovered: {
      backgroundColor: "#ffff00",
      borderColor: "#ffff00",
      color: "#000",
      selectors: {
        "svg, .icons-default-fill": {
          fill: "#000"
        }
      }
    },
    rootPressed: {
      backgroundColor: "#ffff00",
      borderColor: "#ffff00",
      color: "#000",
      selectors: {
        "svg, .icons-default-fill": {
          fill: "#000"
        }
      }
    },
    rootChecked: {
      backgroundColor: "#ffff00",
      borderColor: "#ffff00",
      color: "#000",
      selectors: {
        "svg, .icons-default-fill": {
          fill: "#000"
        }
      }
    },
    rootCheckedHovered: {
      backgroundColor: "#ffff00",
      borderColor: "#ffff00",
      color: "#000",
      selectors: {
        "svg, .icons-default-fill": {
          fill: "#000"
        }
      }
    },
    rootDisabled: {
      backgroundColor: "#30f42c",
      borderColor: "#30f42c",
      color: "#000",
      selectors: {
        "svg, .icons-default-fill": {
          fill: "#000"
        }
      }
    },
    rootFocused: {
      backgroundColor: "#ffff00",
      borderColor: "#ffff00",
      borderRadius: "2px",
      color: "#000",
      selectors: {
        "svg, .icons-default-fill": {
          fill: "#000"
        }
      }
    }
  }
}

// export const fileNameCellStyles = (props: IFileNameCellPropsStyles): IFileNameCellStyles => {
//   const { palette } = props.theme!; // eslint-disable-line @typescript-eslint/no-non-null-assertion

//   return {
//     menuIconWrapper: {},
//     text: {},
//     subComponentStyles: {
//         iconButton: {},
//     }
//   }

// }

export const contextualMenuStyles = (props: IContextualMenuProps): IContextualMenuStyles => {
  const { semanticColors, palette } = props.theme!; // eslint-disable-line @typescript-eslint/no-non-null-assertion

  return {
    title: {},
    container: {},
    root: {
      borderstyle: 'solid',
      bordercolor: 'coral'
    },
    header: {},
    list: {},
    subComponentStyles: {
      callout: {},
      menuItem: {
        root: {
          color: 'white',
          selectors: {
            ':hover': {
              backgroundColor: semanticColors.listItemBackgroundHovered,
              color: palette.white
            },
            '&.is-selected': {
              backgroundColor: semanticColors.listItemBackgroundChecked,
              borderBottom: `1px solid ${palette.neutralLighter}`,
              color: palette.white
            },
            '&.is-selected:not(:hover)': {
              backgroundColor: semanticColors.listItemBackgroundChecked,
              color: palette.white
            },
            '&.is-selected:hover': {
              backgroundColor: semanticColors.listItemBackgroundHovered,
              color: palette.white
            }
          }
        }
      },
    }
  }

}

export const tooltipStyles = (props: ITooltipStyleProps): ITooltipStyles => {
  const { palette } = props.theme!; // eslint-disable-line @typescript-eslint/no-non-null-assertion
  return {
    root: {},
    content: {},
    subText: {
      color: palette.themePrimary
    }
  }
}

export const textFieldStyles = (props: ITextFieldProps): ITextFieldStyles => {
  const { palette } = props.theme!; // eslint-disable-line @typescript-eslint/no-non-null-assertion

  return {
    root: {
      marginBottom: 1
    },
    fieldGroup: {
      border: 'none',
      color: palette.black,
      backgroundColor: palette.neutralLighter,
      '::after': {
        borderTop: 'none',
        borderLeft: 'none',
        borderRight: 'none',
        borderRadius: 0,
        width: '100%',
        inset: '0px'
      }
    },
    prefix: {
      color: palette.white,
    },
    suffix: {},
    field: {
      '::placeholder': {
        color: palette.black
      },
      color: palette.neutralPrimary
    },
    icon: {},
    description: {},
    wrapper: {},
    errorMessage: {},
    revealButton: {},
    revealSpan: {},
    revealIcon: {},
    subComponentStyles: {
      label: {
        root: {
          fontWeight: FontWeights.regular,
          color: palette.black
        }
      }
    }
  }
}

export const dropdownStyles = (props: IDropdownStyleProps): IDropdownStyles => {
  const { palette } = props.theme!; // eslint-disable-line @typescript-eslint/no-non-null-assertion

  return {
    root: {},
    label: {},
    dropdown: {
      selectors: {
        ':focus': {
          '::after': {
            border: 'none',
            borderBottom: '2px solid ' + palette.themePrimary
          },
          '.ms-Dropdown-caretDown': {
            color: 'white'
          }
        },
        ':hover': {
          '.ms-Dropdown-caretDown': {
            color: 'white'
          }
        },
        ':active': {
          '.ms-Dropdown-caretDown': {
            color: 'white'
          }
        }
      }
    },
    title: {
      border: 'none',
      backgroundColor: palette.neutralLight, //'rgb(240,240,240)'
      color: palette.black
    },
    caretDownWrapper: {},
    caretDown: {},
    errorMessage: {},
    dropdownItemsWrapper: {},
    dropdownItems: {},
    dropdownItem: {
      color: palette.black,
      selectors: {
        ':hover': {
          backgroundColor: palette.yellowLight,
          ':focus': {
            backgroundColor: palette.yellowLight,
            color: palette.themePrimary
          }
        }
      }
    },
    dropdownItemSelected: {},
    dropdownItemDisabled: {},
    dropdownItemSelectedAndDisabled: {},
    dropdownItemHidden: {},
    dropdownOptionText: {},
    dropdownDivider: {},
    dropdownItemHeader: {},
    panel: {},
    callout: {},
    subComponentStyles: {
      label: {},
      multiSelectItem: {},
      panel: {}
    }
  }
}

export const peoplePickerStyles = (props: IBasePickerProps<BasePeoplePicker>): IBasePickerStyles => {
  const { palette } = props.theme!; // eslint-disable-line @typescript-eslint/no-non-null-assertion

  return {
    root: {
      width: '100%',
      marginRight: '20px'
    },
    text: {
      borderLeft: 'none',
      borderRight: 'none',
      borderTop: 'none',
      borderBottom: '1px solid ' + palette.themePrimary,
      selectors: {
        '::after':
        {
          borderLeft: 'none',
          borderRight: 'none',
          borderTop: 'none'
        }
      },
      backgroundColor: 'transparent'
    },
    input: {
      color: palette.white,
      backgroundColor: 'black'
    },
    itemsWrapper: {
      '.ms-PickerPersona-container': {
        border: '1px solid' + palette.neutralSecondary,
        marginBottom: '5px',
        background: 'transparent',
        '.ms-TooltipHost': {
          color: palette.themePrimary
        },
        '.ms-PickerItem-removeButton': {
          color: palette.themePrimary,
          selectors: {
            ':hover': {
              background: palette.yellowLight
            }
          }
        }
      }
    },
    screenReaderText: {}

  }
}

export const suggestionsPeoplePickerStyles = (props: ISuggestionsStyleProps): ISuggestionsStyles => {
  return {
    suggestionsContainer: {
      maxHeight: '40vh'
    },
    forceResolveButton: {},
    noSuggestions: {},
    root: {},
    title: {},
    searchForMoreButton: {},
    subComponentStyles: {
      spinner: {}
    },
    suggestionsAvailable: {}
  }
}

export const suggestionsItemStyles = (props: ISuggestionsItemStyleProps): ISuggestionsItemStyles => {
  const { palette } = props.theme!; // eslint-disable-line @typescript-eslint/no-non-null-assertion

  return {
    root: {},
    itemButton: {
      selectors: {
        ':hover': {
          background: palette.blue
        },
        '.ms-PeoplePicker-personaContent':
        {
          selectors: {
            ':hover': {
              backgroundColor: palette.blue
            }
          }
        }
      }
    },
    closeButton: {}
  }

}

// export const modalUploadFile = (props: IUploadFileModalProps): IUploadFileModalStyles => {
//   return {
//     uploadButton: {
//       border: '1px solid !important '
//     },
//     subComponentStyles: {
//       errorStyle: {},
//       textField: {}
//     }
//   }
// }

export const pivotStyles = (props: IPivotStyleProps): IPivotStyles => {
  return {
    root: {},
    link: {
      paddingLeft: 0,
      paddingRight: 0,
      marginLeft: 8,
      marginRight: 8,
      color: props.theme.palette.yellowLight,

      '&:hover': {
        backgroundColor: 'inherit',
        color: props.theme?.palette.yellowLight
      },
      '&:active': {
        backgroundColor: 'inherit',
      },

      '&::before': {
        transition: 'none',
        height: 3,
      },

      '&:not(.is-selected):hover::before': {
        backgroundColor: props.theme?.palette.yellowLight,
        right: 0,
        left: 0,
      }
    },
    linkIsSelected: {
      cursor: 'default',
      color: props.theme.palette.black,

      '&:hover': {
        cursor: 'default',
        color: props.theme.palette.black
      },

      '&::before': {
        right: 0,
        left: 0
      }
    },
    linkContent: {},
    text: {
      fontSize: 14
    },
    count: {},
    icon: {},
    linkInMenu: {},
    overflowMenuButton: {}
  }
}

export const navStyles = (props: INavStyleProps): INavStyles => {
  const { isExpanded } = props;
  const { palette, semanticColors, spacing, fonts } = props.theme;

  return {
    root: {
      padding: '5px 0',
      background: semanticColors.menuItemBackgroundPressed,
      fontSize: spacing.s1,
      '.ms-Nav-link': {
        height: '28px',
        // '.ms-Icon':{
        //   color:'red'
        // }
      },
      height: '100%',
      selectors: {
        '.is-selected button': {
          backgroundColor: palette.yellowLight,
          color: palette.themeDarker,
          'i': {
            color: palette.themeDarker
          }
        }
      },
    },
    linkText: {},
    link: {
      color: '#fff',
      selectors: {
        '.ms-Nav-compositeLink:hover &': {
          backgroundColor: palette.yellowLight,
          color: '#000'
        },
        // '.ms-Button-flexContainer':{
        //   'i':{
        //     color: palette.themePrimary
        //   }
        // },
        ':active': {
          '.ms-Button-flexContainer': {
            position: 'unset'
          }
        }
      },
    },
    compositeLink: {},
    chevronButton: {
      fontSize: fonts.medium.fontSize,
      color: '#fff',
      paddingTop: 0,
      paddingBottom: 0,
      margin: 0,
      selectors: {
        ':hover': {
          backgroundColor: palette.yellowLight,
          color: '#000'
        },
      },
    },
    chevronIcon: [
      {
        fontSize: fonts.xSmall.fontSize,
        transform: 'rotate(-90deg)'
      },
      isExpanded && {
        transform: 'rotate(0deg)',
        top: '5px'
      }
    ],
    navItems: {},
    navItem: {
      borderBottom: '1px solid white',
      borderLeft: '1px solid white'
    },
    group: {},
    groupContent: {
      marginBottom: '0px'
    }
  };
}

export const breadcrumbStyles = (props: IBreadcrumbStyleProps): IBreadcrumbStyles => {
  return {
    root: {
      margin: "8px 0px 0px",
      paddingLeft: "24px",
      paddingRight: "24px",
      lineHeight: "20px"
    },
    list: {},
    listItem: {
    },
    chevron: {},
    overflow: {},
    overflowButton: {},
    itemLink: {
      fontSize: "unset",
      color: "#fff",
      lineHeight: "40px",
      height: "40px",
      fontWeight: "400",
      callout: {

      },
      selectors: {
        ':hover': {
          color: "#ffff00",
          textDecoration: "none",
          cursor: "pointer",
          background: "none"
        },
        ':active': {
          color: "#ffff00 !important",
          textDecoration: "none !important",
          cursor: "pointer !important",
          background: "none !important"
        },
        ':last-child': {
          fontWeight: "700 !important",
          color: "#ffffff !important"
        },
      }
    },
    item: {
      fontSize: "unset",
      color: "#fff",
      lineHeight: "40px",
      height: "40px",
      fontWeight: "400",
      selectors: {
        ':last-child': {
          fontWeight: "700 !important",
          color: "#ffffff !important"
        },
      }
    },
  }
}

export const detailsRowStyles = (props: IDetailsRowProps): IDetailsRowStyles => {
  const { fonts, semanticColors, palette } = props.theme!; // eslint-disable-line @typescript-eslint/no-non-null-assertion

  return {
    root: {
      backgroundColor: semanticColors.bodyBackground,
      selectors: {
        ':hover': {
          backgroundColor: semanticColors.listItemBackgroundHovered,
          color: palette.white
        },
        '&.is-selected': {
          backgroundColor: semanticColors.listItemBackgroundChecked,
          borderBottom: `1px solid ${palette.neutralLighter}`,
          color: palette.white
        },
        '&.is-selected:not(:hover)': {
          backgroundColor: semanticColors.listItemBackgroundChecked,
          color: palette.white
        },
        '&.is-selected:hover': {
          backgroundColor: semanticColors.listItemBackgroundHovered,
          color: palette.white
        }
      }
    },
    cell: {
      fontSize: fonts.medium.fontSize,

    },
    cellAnimation: {},
    cellUnpadded: {},
    cellPadded: {},
    checkCell: {},
    isRowHeader: {
      color: 'inherit !important',
      fontWeight: fonts.medium.fontWeight,
    },
    isMultiline: {},
    fields: {},
    cellMeasurer: {},
    checkCover: {},
    check: {}
  }
}

export const detailsColumnStyles = (props: IDetailsColumnProps): IDetailsColumnStyles => {
  const { semanticColors, palette } = props.theme!; // eslint-disable-line @typescript-eslint/no-non-null-assertion

  return {
    root: {
      height: '32px',
    },
    cellName: {
      fontSize: '12px',
      fontWeight: '400',
    },
    cellTitle: {
      color: palette.black,
      selectors: {
        ':hover': {
          backgroundColor: semanticColors.listItemBackgroundHovered,
          color: palette.white
        }
      }
    },
    sortIcon: {
      color: palette.black
    },
    gripperBarVerticalStyle: {},
    cellTooltip: {},
    iconClassName: {},
    nearIcon: {},
    accessibleLabel: {},
    filterChevron: {},
    borderAfterDropping: {},
    noBorderAfterDropping: {},
    borderWhileDragging: {},
    noBorderWhileDragging: {}
  }
}

export const detailsHeaderStyles = (props: IDetailsHeaderProps): IDetailsHeaderStyles => {
  const { palette } = props.theme!; // eslint-disable-line @typescript-eslint/no-non-null-assertion

  return {
    root: {
      height: '32px',
      lineHeight: '32px',
      borderBottomColor: palette.neutralQuaternary,

    },
    cellIsCheck: {
      height: '32px'
    },
    check: {},
    cellWrapperPadded: {},
    cellIsActionable: {},
    cellIsEmpty: {},
    cellSizer: {},
    cellSizerStart: {},
    cellSizerEnd: {},
    cellIsResizing: {},
    cellIsGroupExpander: {},
    collapseButton: {},
    checkTooltip: {},
    sizingOverlay: {},
    dropHintCircleStyle: {},
    dropHintCaretStyle: {},
    dropHintLineStyle: {},
    dropHintStyle: {},
    accessibleLabel: {},
  }
}


export const searchBoxStyles = (props: ISearchBoxProps): ISearchBoxStyles => {
  const { palette } = props.theme!; // eslint-disable-line @typescript-eslint/no-non-null-assertion

  return {
    root: {
    },
    iconContainer: {},
    icon: {},
    field: {
      color: palette.black
    },
    clearButton: {},
  }
}

export const checkBoxStyles = (props: ICheckboxStyleProps): ICheckboxStyles => {
  const { palette } = props.theme!; // eslint-disable-line @typescript-eslint/no-non-null-assertion

  return {
    root: {},
    input: {},
    label: {},
    checkbox: {
      backgroundColor: palette.black,
      borderRadius: '2px'
    },
    checkmark: { color: palette.white },
    text: {},
  }
}