export const authority = "https://login.microsoftonline.com/c16e514b-893e-4a01-9a30-b8fef514a650";
export const tenantId = "c16e514b-893e-4a01-9a30-b8fef514a650";

export type ConfigEntry = {
    clientId: string;
    redirectUri: string;
    scopes: string[];
}

export const environment: Record<string, ConfigEntry> = {
    'localhost:3010': {
        clientId: "ee15f8cb-0b1a-4721-9b77-1fdf98076b88",
        redirectUri: "https://localhost:3010",
        scopes: ["api://e1d60e7d-e1bd-466e-903b-395debea9351/Access.All"]
    },
    'sd-edi-workflow.eni.com': {
        clientId: "c00572dd-0e56-4ca0-b9e5-edcce640b212",
        redirectUri: "https://sd-edi-workflow.eni.com",
        scopes: ["api://ac945214-577b-4159-b30a-9c541e4b7a56/Access.All"]
    },
    'st-edi-workflow.eni.com': {
        clientId: "927e09b6-8708-4d60-9709-74c963eba1d4",
        redirectUri: "https://st-edi-workflow.eni.com",
        scopes: ["api://4b5ce7bf-b7f5-499a-bf74-0e765ca8cd3b/Access.All"]
    },
    'pp-edi-workflow.eni.com': {
        clientId: "d832e8de-9579-4a9a-88d6-7d5ed838630e",
        redirectUri: "https://pp-edi-workflow.eni.com",
        scopes: ["api://f9c06329-dcc9-4808-ac8a-e6fedbf2bc5a/Access.All"]
    },
    'edi-workflow.eni.com': {
        clientId: "a8cda4a0-da2c-4ada-a8b1-f5f6689bfa4a",
        redirectUri: "https://edi-workflow.eni.com",
        scopes: ["api://e4a71d4e-f2d4-4edc-b07a-da5c58d7d543/Access.All"]
    }
}