import { styled } from "@fluentui/react";
import { getStyles } from "./editCategoryModal.styles";
import { IEditCategoryModalPropsStyles, IEditCategoryModalStyles, IEditCategoryModalProps } from "./editCategoryModal.types";
import { EditCategoryModalBase } from "./editCategoryModal.base";

const EditCategoryModal = styled<IEditCategoryModalProps, IEditCategoryModalPropsStyles, IEditCategoryModalStyles>(
    EditCategoryModalBase,
    getStyles,
    undefined,
    { scope: 'EditCategoryModal' }
);

export default EditCategoryModal;