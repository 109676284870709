import { DetailsRowGlobalClassNames, IDetailsListStyleProps, IDetailsListStyles, IDetailsRowStyleProps, IDetailsRowStyles, IStyleFunction } from "@fluentui/react";
import { IWorkflowDisjointedPropsStyles, IWorkflowDisjointedStyles } from "./workflowDisjointed.types";


export const WorkflowDisjointedGlobalClassNames = {
    root: 'edi-work-flow-list-root',
    emptyList: 'edi-work-flow-list-emptyList',
    detailListContainer: 'edi-work-flow-list-container',
    centeredContainer: 'edi-work-flow-list-centered-container',
    workflowOverviewNameWrapper: 'edi-work-flow-list-workflow-list-name-wrapper',
    workflowOverviewNameWrapperTag: 'edi-work-flow-list-workflow-list-name-wrapper-tag',
    workflowRow: 'edi-work-flow-list-workflow-row',
    groupHeader: 'edi-work-flow-groupHeader',
    groupAction:'edi-work-flow-groupAction',
}

export const getStyles: IStyleFunction<IWorkflowDisjointedPropsStyles, IWorkflowDisjointedStyles> = (props: IWorkflowDisjointedPropsStyles): IWorkflowDisjointedStyles => {
    const { palette } = props.theme!; // eslint-disable-line @typescript-eslint/no-non-null-assertion
    return {
        root: [
            WorkflowDisjointedGlobalClassNames.root,
            {
                visibility: 'hidden'
            }
        ],
        load: {
            display: 'none'
        },
        groupHeader:[
            WorkflowDisjointedGlobalClassNames.groupHeader,
            {
                minWidth: '200px !important',
                maxWidth: 200,
                ':hover':{
                    background: 'unset',
                    color: 'unset'
                }
            }
        ],
        groupAction:[
            WorkflowDisjointedGlobalClassNames.groupAction,
            {
                display: 'flex',
                width: '78%',
                justifyContent: 'flex-end',
                alignItems: 'center',
                marginLeft: 'auto',
                paddingRight: 15
            }
        ],
        detailListContainer: [
            WorkflowDisjointedGlobalClassNames.detailListContainer,
            {
                marginRight: '30px',
                marginTop: '15px',
                marginBottom: '15px',
                height: '100%',
                '> .ms-Viewport': {
                    height: '100%'
                }
            }],
        emptyList: [
            WorkflowDisjointedGlobalClassNames.emptyList,
            {
                position: 'absolute',
                top: '60px',
                width: '300px',
                left: 'calc(50% - 150px)'
            }],
        centeredContainer: [
            WorkflowDisjointedGlobalClassNames.centeredContainer,
            {
                height: '85vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }
        ],
        workflowOverviewNameWrapper: [
            WorkflowDisjointedGlobalClassNames.workflowOverviewNameWrapper,
            {
                position: 'relative',
                paddingRight: 30,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            }
        ],
        workflowOverviewNameWrapperTag: [
            WorkflowDisjointedGlobalClassNames.workflowOverviewNameWrapperTag,
            {
                display: 'block !important',
                '> .ms-Label': {
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    textAlign: 'center'
                }                
            }
        ],
        workflowRow: [
            WorkflowDisjointedGlobalClassNames.workflowRow,
            {
                cursor: 'pointer',
                ':hover': {
                    textDecoration: 'underline'
                }
            }
        ],
        menuIconWrapper: {
            position: 'absolute',
            top: 0,
            right: 0,
            height: '100%',
            color: 'inherit',
            visibility: 'hidden',
            [`.${DetailsRowGlobalClassNames.root}:hover &`]: {
                visibility: 'visible'
            }
        },
        deleteButtonContainer: {
            display: 'flex',
            alignItems: 'flex-end'
        },
        deleteFooterContainer: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center'
        },
        subComponentStyles: {
            detailsList: (props: IDetailsListStyleProps): IDetailsListStyles => {
                return {
                    root: {
                        '& [role="grid"]': {
                            display: 'flex',
                            flexDirection: 'column',
                        }
                    },
                    headerWrapper: {
                        flex: '0 0 auto'
                    },
                    contentWrapper: {
                        flexGrow: '1',
                        flexShrink: '1',
                        flexBasis: 'calc(100vh - 350px)',
                        overflowY: 'auto',
                        overflowX: 'hidden',
                        '@media(max-width: 730px)': { // 798 - 68
                            overflowX: 'auto' // show horizontal scrollbar when list is not shrinkable anymore
                        }
                    },
                    focusZone: {},
                }
            },
            detailsRow: (props: IDetailsRowStyleProps): IDetailsRowStyles => {
                return {
                    root: {
                    },
                    cell: { alignSelf: 'center', textOverflow: 'unset' },
                    cellAnimation: {},
                    cellPadded: {},
                    cellMeasurer: {},
                    cellUnpadded: {},
                    check: {},
                    checkCell: {},
                    checkCover: {},
                    fields: {},
                    isMultiline: {},
                    isRowHeader: {},
                }
            },
            iconButton: {
                root: {
                    height: '100%',
                    float: 'right'
                },
                iconHovered: {
                    textShadow: `0 0 1px ${palette.black}`
                },
                menuIcon: {
                    display: 'none !important'
                },
                rootExpanded: {
                    backgroundColor: 'inherit'
                },
                rootPressed: {
                    backgroundColor: 'inherit'
                },
                rootHovered: {
                    backgroundColor: 'inherit',
                }
            },
            deleteDialogFooterContainer: {
                actions: {
                    margin: '8px 0 0'
                }
            },
            deletePrimaryButtonDisabled: {
                root: {
                    selectors: {
                        ':disabled': {
                            backgroundColor: palette.neutralQuaternary,
                            color: palette.neutralTertiary,
                            pointerEvents: 'none'
                        },
                    }
                },
            }
        }
    };
}