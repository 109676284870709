import { IStyleFunction } from "@fluentui/react"
import { IWorkflowLabListPropsStyles, IWorkflowLabListStyles } from "./workflowLabList.types"

export const WorkflowLabListGlobalClassNames = {
    root: 'edi-workflowlabs-list-root',
    renderLinkStyle: 'edi-workflowlabs-list-renderLinkStyle',
    moreButton: 'edi-workflowlabs-list-moreButton',
    emptyFolderContainer:'edi-workflowlabs-list-emptyFolderContainer'
}

export const getStyles: IStyleFunction<IWorkflowLabListPropsStyles, IWorkflowLabListStyles> = (props: IWorkflowLabListPropsStyles): IWorkflowLabListStyles => {
    const { palette, semanticColors } = props.theme!; // eslint-disable-line @typescript-eslint/no-non-null-assertion
    return {
        root: [
            WorkflowLabListGlobalClassNames.root,
            {
                height: '100%',
                position: 'relative',
                selectors: {
                    '::before': {
                        background: `linear-gradient(to right,transparent,${semanticColors.menuDivider})`,
                        content: "''",
                        opacity: '0.2',
                        pointerEvents: 'none',
                        position: 'absolute',
                        right: 0,
                        width: '0.8rem',
                        height: '100%',
                        zIndex: '2'
                    }
                }
            }
        ],
        renderLinkStyle: [WorkflowLabListGlobalClassNames.renderLinkStyle, {
            marginLeft: '10px',
            marginRight: 'auto',
            height: 28,
            lineHeight: 28,
            width: 'calc(100% - 40px)',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            textAlign: 'left'
        }],
        moreButton: [WorkflowLabListGlobalClassNames.moreButton, {
            right: 12,
            height: 28,
            width: 32,
        }],
        emptyFolderContainer:[WorkflowLabListGlobalClassNames.emptyFolderContainer,{
            height:'100%',
            display:'flex',
            flexDirection: 'column',
            justifyContent: 'center'
        }],
        subComponentStyles: {
            nav: {
                navItem: {
                    ':hover *': {
                        visibility: 'visible'
                    },
                },
                link: {
                    padding: '0 10px'
                }
            },
            moreIcon: {
                root: {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    visibility: 'hidden',
                    ':hover': {
                        textShadow: `0 0 1px ${palette.black}`,
                    },
                }
            }
        }
    };
}