import { WorkFlowStep, WorkFlowStepRelativeStatus, WorkFlowStepStatus } from "../../models/WorkFlow";
import { WorkFlowApprover, WorkFlowApproverStatus } from "../../models/workflowApprover";
import { WorkFlowTemplateApprover, WorkFlowTemplateStep } from "../../models/workflowTemplate";
import { FileSource } from "../root/workflowOverview/createWorkflow/createWorkflow.types";
import { WorkflowStepShort } from "./workflow.contracts";

export type UpdateTemplateRequest = {
    templateId: number;
    name: string;
    description: string;
    steps: WorkflowStepShort[];
    fileSource?: FileSource;
    channelId?: string;
    teamId?: string;
    teamFileId?: string;
    ediFileId?: number; 
    isDocumentEditable: boolean;
    removeFile?: boolean;
    LabelCategoryIds?: number[];
    disjointed?: boolean;
}

export const workflowTemplateStepToShort = (workflowTemplateStep: WorkFlowTemplateStep) : WorkflowStepShort => {
    return {
        name: workflowTemplateStep.name,
        order: workflowTemplateStep.order,
        type: workflowTemplateStep.type,
        approvers: workflowTemplateStep.approvers.map(user => user.approver.id)
    }
}

export const workflowTemplateApproverToWorkflowApprover = (workflowTemplateApprover: WorkFlowTemplateApprover) : WorkFlowApprover => {
    return {
        workFlowStepId: workflowTemplateApprover.workFlowTemplateStepId,
        approver: workflowTemplateApprover.approver,
        status: WorkFlowApproverStatus.ToBeApproved,
    }
}

export const workflowApproverToWorkflowTemplateApprover = (workflowApprover: WorkFlowApprover) : WorkFlowTemplateApprover => {
    return {
        workFlowTemplateStepId: workflowApprover.workFlowStepId,
        approver: workflowApprover.approver
    }
}

export const workflowTemplateStepToWorkflowStep = (workflowTemplateStep: WorkFlowTemplateStep) : WorkFlowStep => {
    return {
        id: workflowTemplateStep.id,
        name: workflowTemplateStep.name,
        order: workflowTemplateStep.order,
        type: workflowTemplateStep.type,
        workFlowId: workflowTemplateStep.workFlowTemplateId,
        approvers: workflowTemplateStep.approvers.map(a => workflowTemplateApproverToWorkflowApprover(a)),
        approvedOn: workflowTemplateStep.approvedOn,
        createdOn: workflowTemplateStep.createdOn,
        lastUpdatedOn: workflowTemplateStep.lastUpdatedOn,
        status: WorkFlowStepStatus.ToBeApproved,
        relativeStatus: WorkFlowStepRelativeStatus.ToBeApproved,
        comment: "",
        involvedPeople: []
    }
}

export const workflowStepToWorkflowTemplateStep = (workflowStep: WorkFlowStep) : WorkFlowTemplateStep => {
    return {
        id: workflowStep.id,
        name: workflowStep.name,
        workFlowTemplateId: workflowStep.workFlowId,
        order: workflowStep.order,
        type: workflowStep.type,
        approvers: workflowStep.approvers.map(a => workflowApproverToWorkflowTemplateApprover(a)),
        approvedOn: workflowStep.approvedOn,
        createdOn: workflowStep.createdOn,
        lastUpdatedOn: workflowStep.lastUpdatedOn,
        labels: workflowStep.usersAndLabels?.map(u => u.label) ?? []
    }
}