import { styled } from "@fluentui/react";
import { getStyles } from "./teamsFile.styles";
import { ITeamsFilePropsStyles, ITeamsFileStyles, ITeamsFileProps } from "./teamsFile.types";
import { TeamsFileBase } from "./teamsFile.base";

const TeamsFile = styled<ITeamsFileProps, ITeamsFilePropsStyles, ITeamsFileStyles>(
    TeamsFileBase,
    getStyles,
    undefined,
    { scope: 'TeamsFile' }
);

export default TeamsFile;