import { styled } from "@fluentui/react";
import { getStyles } from "./stepStatusSummary.styles";
import { IStepStatusSummaryPropsStyles, IStepStatusSummaryStyles, IStepStatusSummaryProps } from "./stepStatusSummary.types";
import { StepStatusSummaryBase } from "./stepStatusSummary.base";

const StepStatusSummary = styled<IStepStatusSummaryProps, IStepStatusSummaryPropsStyles, IStepStatusSummaryStyles>(
    StepStatusSummaryBase,
    getStyles,
    undefined,
    { scope: 'StepStatusSummary' }
);

export default StepStatusSummary;