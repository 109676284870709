import React from "react";
import {  classNamesFunction, IconButton, Stack, } from "@fluentui/react";
import { IChoiceInputSourceProps, IChoiceInputSourcePropsStyles, IChoiceInputSourceStyles } from "./choiceInputSource.types";
import TeamsButton from "../../../../../common/components/teamsButton/teamsButton";
import { ImageName } from "../../../../../common/components/teamsImage/teamsImage.types";
import { FileSource } from "../createWorkflow.types";
import { useTranslation } from "react-i18next";
import { Helpers } from "../../../../../utilities/helpers";
import FileIconCell from "../../../../../common/components/fileIconCell/fileIconCell";
import { PreviewSource } from "../../../../../common/components/filePreview/filePreviewSource";
/*eslint-disable sonarjs/cognitive-complexity */

const getClassNames = classNamesFunction<IChoiceInputSourcePropsStyles, IChoiceInputSourceStyles>();

export const ChoiceInputSourceBase = (props: IChoiceInputSourceProps) => {
    const { t } = useTranslation(['createWorkflow', 'common']);
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const cursorName : string = props.isSourceFile ? 'pointer' : '';

    const getFilePreviewUrl = async () => {
        if(!props.isSourceFile)
            return

        if(props.templateId){
            return Helpers.getPreviewFile(props.templateId, PreviewSource.Template);
        }
    }

    return (
        <>
            { props.filePresetData &&
                <div className={classNames.presetFile}>
                    <div style={{fontWeight: 600, marginRight: '5px', marginLeft: '10px'}}>
                        Selected file:
                    </div>
                    <FileIconCell fileExtension={Helpers.getFileExtension(props.filePresetData?.fileName)}  styles={{root: {margin: '10px'}}} />
                    <div style={{cursor: cursorName, textDecoration: 'underline'}} onClick={getFilePreviewUrl}>
                        {props.filePresetData.fileName}
                    </div>
                    <IconButton
                        iconProps={{ iconName: 'Cancel' }}
                        title={`${t('common:delete')}`}
                        onClick={() => {
                            if(props.setFilePresetData)
                                props.setFilePresetData(undefined)
                        }}                             
                    />
                </div>
            }
            <Stack>
                <TeamsButton 
                    imageName={ImageName.TeamsImage} 
                    title={t("choiceInputSource.teams")} 
                    subTitle={t("choiceInputSource.teamsSubtitle")} 
                    onClick={() => props.onElementChoice(FileSource.teams)} />
                <TeamsButton 
                    imageName={ImageName.EmptyFolderDrop}
                    title={t("choiceInputSource.local")} 
                    subTitle={t("choiceInputSource.localSubtitle")} 
                    onClick={() => props.onElementChoice(FileSource.local)} />
                <TeamsButton 
                    imageName={ImageName.EdiAppLogo} 
                    title={t("choiceInputSource.edi")} 
                    subTitle={t("choiceInputSource.ediSubtitle")} 
                    onClick={() => props.onElementChoice(FileSource.edi)} />
            </Stack>
        </>
        
    )
}