/*eslint-disable sonarjs/cognitive-complexity */
import React, { useEffect, useMemo, useState } from "react";
import { ActionButton, classNamesFunction, IDropdownOption, Label } from "@fluentui/react";
import { IAddDisjointmentPropsStyles, IAddDisjointmentStyles, IAddDisjointmentProps } from "./addDisjointment.types";
import { useOnMount } from "../../../../../utilities/hooks";
import { WorkFlowDisjointment } from "../../../../../models/WorkFlow";
import { useTranslation } from "react-i18next";
import SingleDisjoinemnt from "./SingleDisjoinment/singleDisjointment";
import { CategoryLabelItem, CategoryViewItem } from "../../../../../models/categoryView";
import { categoryApi } from "../../../../services/category/category.api";
import { useWorkflowState } from "../../../../workflowStore";
import { AltSecondPageData } from "../createWorkflow.types";

const getClassNames = classNamesFunction<IAddDisjointmentPropsStyles, IAddDisjointmentStyles>();

export const AddDisjointmentBase = (props: IAddDisjointmentProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const [data, setData] = useState<AltSecondPageData>(props.initialState);
    const [categoryDropdownOptions, setCategoryDropdownOptions] = useState<IDropdownOption[]>([]);
    const [categories , setCategories] = useState<CategoryViewItem[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const { t } = useTranslation(['createWorkflow', 'common']);
    const currentWorkflowLab = useWorkflowState();

    useOnMount(() => {
        getCategories(currentWorkflowLab.workflowList.selectedWorkflowLab);
        if (data.disjointments.length === 0)
            addDisjointment();
    })

    useEffect(() => {
        const dropdownItems = categories.map(a => {
            return {
                key: a.id,
                text: a.name,
                disabled: data.disjointments.some(b => b.category.id === a.id) ? true : false
            } as IDropdownOption 
        }); 
        setCategoryDropdownOptions(dropdownItems);
    }, [data.disjointments, categories ])

    const getCategories = async (workflowLabId: number) => {
        try 
        {
            setLoading(true);
            const result = await categoryApi.getAllCategories({workflowLabId: workflowLabId});
            setCategories(result);
        
        } finally{
            setLoading(false)
        }
    }

    const addDisjointment = () => {
        const disjointment: WorkFlowDisjointment = {
            id: data.disjointments.length === 0 ? 1 : data.disjointments.length + 1,
            category: {id: 0, name: '', workflowLabId: 0, labels: []},
            labels: []
        }
        const newItems = data.disjointments.concat(disjointment);
        setData({ ...data, disjointments: newItems});
        props.onWorkflowDataChange({ ...data, disjointments: newItems});
    }

    const onUpdateCategory = (category: CategoryViewItem, categoryId: number) => {
        const newItems = data.disjointments.map((c) => {
            if (c.category.id === categoryId) {
                c.category = category; 
                c.labels = []; 
            }
            
            return c;
        });
        setData({ ...data, disjointments: newItems });
        props.onWorkflowDataChange({ ...data, disjointments: newItems });
    }

    const onUpdateLabels = (labels: CategoryLabelItem[], categoryId: number) => {
        const newItems = data.disjointments.map((c) => {
            if (c.category.id === categoryId) {
                c.category.labels = labels; 
                c.labels = labels; 
            }            
            return c;
        });
        setData({ ...data, disjointments: newItems });
        props.onWorkflowDataChange({ ...data, disjointments: newItems });
    }

    const productsresult = useMemo(() => {
        let products=1;
        const labels = data.disjointments.map(a => a.labels.length);
        if(Math.max(...labels) === 0)
            return 0;

        data.disjointments.forEach((p) => {
            if(p.labels.length !== 0) { 
                products = products * p.labels.length;
            }
        }) 
        return products;
    }, [data]);

    const deleteItem = (item: WorkFlowDisjointment) => {
        const newList = data.disjointments.filter(element => element.category.id !== item.category.id);
        setData({ ...data, disjointments: newList });
        props.onWorkflowDataChange({ ...data, disjointments: newList });
    }

    return (
        <div className={classNames.root}>
            
            {data.disjointments.map((disjointment) => {
                return (
                    <SingleDisjoinemnt
                        disjointment={disjointment}
                        key={disjointment.category.id}
                        loading={loading}
                        deleteItem={(item: WorkFlowDisjointment) => deleteItem(item)}
                        categories={categories}
                        onUpdateLabels={(labels) => onUpdateLabels(labels, disjointment.category.id)}
                        onUpdateCategory={(category) => onUpdateCategory(category, disjointment.category.id)}
                        readonly={false} 
                        visualizeMode={false}
                        numberDisjointments={data.disjointments.length}
                        categoryOptions={categoryDropdownOptions}
                    />
                )
            })}

            <div className={classNames.addContainer}>
                <div className={classNames.showProduct}>
                    <Label>{t('selectDisjointmentCategory.labelwarning')+productsresult.valueOf()}</Label>
                </div>
                <ActionButton
                    onClick={()=> addDisjointment()} 
                    className={`${classNames.addLabel}`}
                >
                    {t('addDisjointment.addDisjointment')}
                </ActionButton>
            </div>
        </div>
    );
}