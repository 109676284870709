import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { IWorkflowDisjointedGraphContainerProps, IWorkflowDisjointedGraphContainerPropsStyles, IWorkflowDisjointedGraphContainerStyles  } from "./workflowDisjointedGraphContainer.types";
import WorkflowDisjointedGraph from "./workflowDisjointedGraph/workflowDisjointedGraph"
import { classNamesFunction, DefaultButton, IContextualMenuItem, IContextualMenuProps, Label, Stack } from "@fluentui/react";
import Carousel from "../../../../common/components/carousel/carousel";
import { carouselItem } from "../../../../common/components/carousel/carousel.base";
import { WorkFlowRelativeStatus } from "../../../../models/WorkflowEnum";
import WorkflowCustomFilterModal from "../workflowCustomFilterModal/WorkflowCustomFilterModal";
import currentUser from "../../../../modules/authentication/currentUser";
import { useCurrentWorkflowLab } from "../../../hooks/useCurrentWorkflowLab";
import { Helpers } from "../../../../utilities/helpers";
import TeamsSpinner from "../../../../common/components/teamsSpinner/teamsSpinner";
import { useConst } from '@fluentui/react-hooks';
import { useOnMount } from "../../../../utilities/hooks";


const getClassNames = classNamesFunction<IWorkflowDisjointedGraphContainerPropsStyles, IWorkflowDisjointedGraphContainerStyles>();

export const WorkflowDisjointedGraphContainerBase = (props: IWorkflowDisjointedGraphContainerProps) => {
    const { t } = useTranslation(['workflow', 'common']);

    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className }); //eslint-disable-line @typescript-eslint/no-unused-vars
    const { palette } = props.theme!; // eslint-disable-line @typescript-eslint/no-non-null-assertion

    const [activePage, setActivePage] = useState(0);   

    const [showCustomFilterModal, setShowCustomFilterModal] = useState(false);
    const selectedWorkflowLab = useCurrentWorkflowLab(); 
    const [contextualButtonText, setContextualButtonText] = useState<string>(t('filters.nextMonth'));
    const [contextualButtonUser, setContextualButtonUser] = useState<IContextualMenuItem>(
    {
        key: currentUser.currentUserId ?? '',
        text: `${currentUser.getUserInfo?.firstName} ${currentUser.getUserInfo?.lastName}` 
    });

    const today = useConst(new Date(Date.now()));
    const nextMonthDate = new Date(new Date(Date.now()).setMonth(new Date(Date.now()).getMonth() + 1));
    const nextWeekDate = new Date(new Date(Date.now()).setDate(new Date(Date.now()).getDate() + 7));

    useOnMount(() => {
        props.onChange({
            workflowLabId: selectedWorkflowLab?.id,
            userId: contextualButtonUser.key,
            dateFrom: props.dataRequest?.dateFrom ?? today.toISOString(),
            dateTo: props.dataRequest?.dateTo ?? nextMonthDate.toISOString(),
        }); 
    });

    const onFilter = (from: Date | undefined, to: Date | undefined) => {
        props.onChange({
            workflowLabId: selectedWorkflowLab?.id,
            userId: contextualButtonUser.key,
            dateFrom: from?.toISOString(),
            dateTo: to?.toISOString()
        });
        setActivePage(0);               
    }

    const onContextualClick = (item: IContextualMenuItem | undefined): void => {
        if (!item) return;
        setContextualButtonText(item.text ?? '');
        if (item.key === 'custom') {
            setShowCustomFilterModal(true);
        } else
            onFilter(item.key === 'all' ? undefined : today, item.key === 'nextMonth' ? nextMonthDate : item.key === 'nextWeek' ? nextWeekDate : undefined);
    };

    const onContextualClickUser = useCallback((item: IContextualMenuItem | undefined): void => {
        if (!item)
            return;
        setContextualButtonUser({key: item.key, text: item.text});
        props.onChange({
            workflowLabId: selectedWorkflowLab?.id,
            userId: item.key,
            dateFrom: props.dataRequest?.dateFrom,
            dateTo: props.dataRequest?.dateTo
        }); 
        setActivePage(0);
    }, [props, selectedWorkflowLab?.id]);

    const avaiableUser = useMemo(() => {
        return {
            items: props.users?.map(u => {
                return {
                    key: u.id,
                    text: `${u.firstName} ${u.lastName}`,
                    onClick: (_, item) => onContextualClickUser(item)
                } as IContextualMenuItem
            }) ?? []
        }
    }, [props.users, onContextualClickUser]) 

    const getContextualMenuProps: IContextualMenuProps = {
        items: [
            {
                key: 'all',
                text: t('filters.all'),
                onClick: (_, item) => onContextualClick(item)
            },
            {
                key: 'nextMonth',
                text: t('filters.nextMonth'),
                onClick: (_, item) => onContextualClick(item)
            },
            {
                key: 'nextWeek',
                text: t('filters.nextWeek'),
                onClick: (_, item) => onContextualClick(item)
            },
            {
                key: 'custom',
                text: t('filters.custom'),
                onClick: (_, item) => onContextualClick(item)
            }
        ]
    };

    const carouselItems : carouselItem[][] = useMemo(() => {
        const workflows = props.dataDashboard.map(w => {
            return {
                description: "",
                title: w.nameWorkflowDisjointed,
                element: <WorkflowDisjointedGraph disjointName={w.nameWorkflowDisjointed} workflows={w.workflowsDisjointed} />
            } as carouselItem;
        });

        return Helpers.chunkArray(workflows, 2);
    }, [props.dataDashboard])

    
    const renderLegend = <div className={classNames.legendContainer}>
        <div className={classNames.legendItem}>
            <span className={classNames.legendCheck} style={{ backgroundColor: palette.themePrimary }}></span>
            <span>{t(`status.${WorkFlowRelativeStatus.ToMe}`)}</span>
        </div>
        <div className={classNames.legendItem}>
            <span className={classNames.legendCheck} style={{ backgroundColor: palette.green }}></span>
            <span>{t(`status.${WorkFlowRelativeStatus.Approved}`)}</span>
        </div>
        <div className={classNames.legendItem}>
            <span className={classNames.legendCheck} style={{ backgroundColor: palette.redDark }}></span>
            <span>{t(`status.${WorkFlowRelativeStatus.Refused}`)}</span>
        </div>
        <div className={classNames.legendItem}>
            <span className={classNames.legendCheck} style={{ backgroundColor: palette.yellow }}></span>
            <span>{t(`status.${WorkFlowRelativeStatus.Ongoing}`)}</span>
        </div>
    </div>;

    return (
        <Stack style={{ height: '100%', position: 'relative' }} horizontal tokens={{ childrenGap: 20, padding: 0 }}>
            <div className={classNames.centerItem}>
                {props.loadingDashboard && <TeamsSpinner />}
                {!props.loadingDashboard && (props.dataDashboard && props.dataDashboard?.length === 0 ) && <Label>No data</Label> }
                {!props.loadingDashboard && props.dataDashboard.length > 0 && 
                    <Carousel 
                        activeStep={activePage} 
                        items={carouselItems[activePage]} 
                        pageCount={carouselItems.length - 1} 
                        onClickItem={() => { return }}
                        next={() => activePage !== carouselItems.length - 1 && setActivePage(activePage + 1)}
                        back={() => activePage !== 0 && setActivePage(activePage - 1)}
                        styles={{root: {
                            margin: '20px 0',
                            height: '100%'
                        }}}
                />} 

            </div>            
            <div className={classNames.rightContainer}>
                <Stack.Item className={classNames.filterContainer}>
                    <div className={classNames.filterItem}>
                        <span style={{marginBottom: '5px'}}>{t('filters.filterCreatedBy')}</span>
                        <DefaultButton
                            id="filterButton"
                            text={contextualButtonUser.text}
                            style={{ boxShadow: 'unset' }}
                            menuProps={avaiableUser}
                            disabled={props.loadingUsers}
                        />
                    </div>
                    <div className={classNames.filterItem}>
                        <span style={{marginBottom: '5px'}}>{t('filters.filterExpirationDate')}</span>
                        <DefaultButton
                            id="filterButton"
                            text={contextualButtonText}
                            style={{ boxShadow: 'unset' }}
                            menuProps={getContextualMenuProps}
                        />
                    </div>                    
                </Stack.Item>                
                {renderLegend}
            </div>
            {showCustomFilterModal && <WorkflowCustomFilterModal
                isOpen={showCustomFilterModal}
                // target={"filterButton"}
                onDismiss={() => setShowCustomFilterModal(false)}
                onFilter={(from, to) =>{
                    onFilter(from, to)
                    setShowCustomFilterModal(false)
                }}
            />}
        </Stack>
    );
}
