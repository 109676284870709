import { styled } from "@fluentui/react";
import { CreateTemplateModalBase } from "./createTemplateModal.base";
import { getStyles } from "./createTemplateModal.styles";
import { ICreateTemplateModalProps, ICreateTemplateModalPropsStyles, ICreateTemplateModalStyles } from "./createTemplateModal.types";

const CreateTemplateModal = styled<ICreateTemplateModalProps, ICreateTemplateModalPropsStyles, ICreateTemplateModalStyles>(
    CreateTemplateModalBase,
    getStyles,
    undefined,
    { scope: 'CreateTemplateModal' }
);

export default CreateTemplateModal;