export enum WorkFlowStatus {
    Approved = 0,
    Refused = 1,
    Ongoing = 2,
    NotStarted = 3
}

export enum WorkFlowRelativeStatus {
    Approved = 0,
    Refused = 1,
    Ongoing = 2,
    ToMe = 3
}

export enum WorkflowViewType {
    received = 'received',
    created = 'created'
}