import { IStyleFunction } from "@fluentui/react"
import { IWorkFlowLabMembersModalPropsStyles, IWorkFlowLabMembersModalStyles } from "./workflowLabMembersModal.types"

export const WorkFlowLabMembersModalGlobalClassNames = {
    root: 'edi-workFlowLab-members-modal-root'
}

export const getStyles: IStyleFunction<IWorkFlowLabMembersModalPropsStyles, IWorkFlowLabMembersModalStyles> = (props: IWorkFlowLabMembersModalPropsStyles): IWorkFlowLabMembersModalStyles => {
    return {
        root: [
            WorkFlowLabMembersModalGlobalClassNames.root,
            {
                // CSS PROPS AND SELECTORS
            }
        ]
    };
}