import React, { useState } from "react";
import { classNamesFunction, DefaultButton, Dialog, DialogFooter, IconButton, PrimaryButton, Spinner, SpinnerSize } from "@fluentui/react";
import { IDeleteCategoryModalStyles, IDeleteCategoryModalProps, IDeleteCategoryModalPropsStyles } from "./deleteCategoryModal.types";
import { useTranslation } from "react-i18next";
import { categoryApi } from "../../../../services/category/category.api";

const getClassNames = classNamesFunction<IDeleteCategoryModalPropsStyles, IDeleteCategoryModalStyles>();

export const DeleteCategoryModalBase = (props: IDeleteCategoryModalProps) => {
    const classNames = getClassNames(props.styles,
        {
            theme: props.theme,
            className: props.className
        });

    const { t } = useTranslation(['deleteCategoryModal', 'common']);
    const [deleteCategoryModal, setDeleteCategoryModal] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>('');


    const deleteCategory = () => {
        setDeleting(true)
        categoryApi.deleteCategory(props.Id)
            .then(() => {
                if (props.onComplete) 
                    props.onComplete();
                setDeleting(false);
                setDeleteCategoryModal(false)
            })
            .catch(() => setErrorMessage('something went wrong'))
            .finally(() => setDeleting(false));
    }
    
    return (
        <>
            <IconButton
                iconProps={{ iconName: 'Delete' }}
                title={`${t('common:Delete')}`}
                onClick={() => {setDeleteCategoryModal(true);}} 
            />
            {deleteCategoryModal && <Dialog
                isOpen={deleteCategoryModal}
                dialogContentProps={{
                    title: t('deleteCategoryModal:delete'),
                    showCloseButton: !deleting,
                    onDismiss: () => { setDeleteCategoryModal(false); },
                    subText: t('deleteCategoryModal:confirmMessage'),
                }}
            >
                <div className={classNames.deleteFooterContainer}>
                    <div className={classNames.deleteButtonContainer}>
                        {!errorMessage && deleting && <Spinner size={SpinnerSize.large}></Spinner>}
                        <div>
                            <DialogFooter
                            //styles={classNames.subComponentStyles.deleteDialogFooterContainer}
                            >
                                <DefaultButton disabled={deleting} onClick={() => setDeleteCategoryModal(false)} text={t('common:undoEliminate')} />
                                <PrimaryButton 
                                    onClick={() => {
                                        deleteCategory();
                                    }}
                                    text={t('common:confirmEliminate')}
                                    styles={classNames.subComponentStyles.deletePrimaryButtonDisabled()}
                                    disabled={deleting}
                                />
                            </DialogFooter>
                        </div>
                    </div>
                </div>
            </Dialog>}
        </>
    );
}