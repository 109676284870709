/*eslint-disable sonarjs/cognitive-complexity*/
/*eslint-disable sonarjs/no-duplicate-string */
import { DefaultButton, mergeStyleSets, PrimaryButton } from "@fluentui/react"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import TeamsImage from "../../../../common/components/teamsImage/teamsImage";
import { ImageName } from "../../../../common/components/teamsImage/teamsImage.types";
import TeamsSpinner from "../../../../common/components/teamsSpinner/teamsSpinner";
import { Helpers } from "../../../../utilities/helpers";
import { useOnMount } from "../../../../utilities/hooks";
import { templateApi } from "../../../services/template.api";
import { workflowApi } from "../../../services/workflow.api";
import { CreateWorkflowRequest } from "../../../services/workflow.contracts";
import { UpdateTemplateRequest } from "../../../services/template.contracts";
import { workflowDisjointedApi } from "../../../services/workflowDisjointed/workflowDisjointed.api";

export interface ICreateWorkFlowFirstPageProps {
    onClose?: () => void;
    onSuccess?: () => void;
    request: CreateWorkflowRequest;
    file: File[];
    isTemplate?: boolean;
    templateIdUpdate?: number;
    translationName: string;
    removeFile?: boolean;
    disjointed?: boolean;
}

const classNames = mergeStyleSets({
    container: {
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        '> :not(:last-child)': {
            marginBottom: 20
        }
    },
    label: {
        fontSize: 24,
        fontWeight: 600,
        textAlign: 'center'
    },
    errorButtons: {
        display: 'flex',
        justifyContent: 'space-around',
        alignSelf: 'stretch'
    }
})

export const CreatingWorkFlowPage = (props: ICreateWorkFlowFirstPageProps) => {
    const [success, setSuccess] = useState<boolean | undefined>(undefined);
    const [creating, setCreating] = useState(false);
    const [errorCount, setErrorCount] = useState(0);
    const { t } = useTranslation([props.translationName, 'common']);

    useOnMount(() => void createWorkFlow());

    const createWorkFlow = async () => {
        if (success)
            return;

        setCreating(true);
        setSuccess(undefined);

        try {
            if(props.templateIdUpdate !== undefined && props.templateIdUpdate !== 0) {
                const updateRequest : UpdateTemplateRequest = {
                    templateId: props.templateIdUpdate,
                    name: props.request.name,
                    description: props.request.description,
                    steps: props.request.steps,
                    fileSource: props.request.fileSource,
                    isDocumentEditable: props.request.isDocumentEditable,
                    channelId: props.request.channelId,
                    ediFileId: props.request.ediFileId,                    
                    teamFileId: props.request.teamFileId,
                    teamId: props.request.teamId,
                    removeFile: props.removeFile,
                    LabelCategoryIds: props.request.LabelCategoryIds,
                    disjointed: props.request.disjointed,
                }
                await Promise.all([templateApi.updateTemplate(updateRequest, props.file[0]), Helpers.delay(500)]); setSuccess(true);
            }
            else if(props.isTemplate) {
                await Promise.all([templateApi.createWorkflowTemplate(props.request, props.file[0]), Helpers.delay(500)]); setSuccess(true);
            }
            else if(props.disjointed) {
                workflowDisjointedApi.createWorkflowDisjointed(props.request, props.file[0]);
                Helpers.delay(500); 
                setSuccess(true);
            }
            else {
                await Promise.all([workflowApi.createWorkflow(props.request, props.file[0]), Helpers.delay(500)]); setSuccess(true);
            }
        }
        catch { setErrorCount(errorCount + 1); setSuccess(false); }
        finally { setCreating(false); }
    }

    return (
        <div className={classNames.container}>
            {creating && !success &&
                <>
                    <TeamsSpinner />
                    <span className={classNames.label}>{props.templateIdUpdate !== undefined && props.templateIdUpdate !== 0 ? t('updatingTemplate') : t('creatingWorkflow')}</span>
                </>
            }
            {!creating && success && (props.disjointed === undefined || !props.disjointed )  &&
                <>
                    <TeamsImage imageName={ImageName.WellDone} style={{ width: '60%' }} />
                    <span className={classNames.label}>{t('common:niceWork')}</span>
                    <PrimaryButton text={t('common:leave')} onClick={props.onSuccess} style={{ flex: '0 0 auto' }} />
                </>
            }
            {!creating && success && props.disjointed &&
                <>
                    <TeamsImage imageName={ImageName.InProgress} style={{ width: '60%' }} />
                    <span className={classNames.label}>{t('common:requestSent')}</span>
                    <PrimaryButton text={t('common:leave')} onClick={props.onSuccess} style={{ flex: '0 0 auto' }} />
                </>
            }
            {!creating && success === false &&
                <>
                    <div className={classNames.label}>{t('common:genericErrorApi')}</div>
                    <div className={classNames.errorButtons}>
                        {errorCount < 3 && <DefaultButton text={t('common:tryAgain')} onClick={createWorkFlow} />}
                        <PrimaryButton text={t('common:leave')} onClick={props.onClose} />
                    </div>
                </>
            }
        </div>
    )
}