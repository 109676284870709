import { IStyleFunction } from "@fluentui/react" 
import { IWorkflowApprovalStepPropsStyles, IWorkflowApprovalStepStyles } from "./workflowApprovalStep.types"

export const WorkflowApprovalStepGlobalClassNames = {
    root: 'edi-workflow-approval-step-root',
    requiredActionTitle: 'edi-workflow-approval-step-requiredActionTitle',
    containerUpload: 'edi-workflow-approval-step-containerUpload',
    labelFileUpload: 'edi-workflow-approval-step-labelFileUpload',
    buttonsContainer: 'edi-workflow-approval-step-buttonsContainer',
    stepBackButton: 'edi-workflow-approval-step-backButton',
    actionContainer: 'edi-workflow-approval-action-container',
    uploadFile: 'edi-workflow-approval-upload-file',
    errorFileUpload: 'edi-workflow-approval-error-file-upload',
    titleWrapper: 'edi-workflow-approval-title-wrapper',
}

export const getStyles: IStyleFunction<IWorkflowApprovalStepPropsStyles, IWorkflowApprovalStepStyles> = (props: IWorkflowApprovalStepPropsStyles): IWorkflowApprovalStepStyles => {
    
    const { palette } = props.theme!; //eslint-disable-line @typescript-eslint/no-non-null-assertion

    return {
        root: [
            WorkflowApprovalStepGlobalClassNames.root, 
            {
                background: palette.white,
                width: 'auto', 
                height: '220px',
                borderRadius: '4px',  
                boxShadow: 'rgb(0 0 0 / 10%) 0px 2px 4px -0.75px', 
            }
        ],
        requiredActionTitle: [
            WorkflowApprovalStepGlobalClassNames.requiredActionTitle, 
            {
                width: '100%',
                height: '20px',
                fontSize: 18,
                marginTop: 5,
                marginBottom: 15,
                fontWeight: 600,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
            }
        ],
        containerUpload: [
            WorkflowApprovalStepGlobalClassNames.containerUpload, 
            {
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                width: '60%',
            }
        ],
        labelFileUpload: [
            WorkflowApprovalStepGlobalClassNames.labelFileUpload, 
            {
                width: 'auto',
                maxWidth: '40%',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                textDecoration: 'underline',
            }
        ],
        buttonsContainer: [
            WorkflowApprovalStepGlobalClassNames.buttonsContainer,
            {
                display:'flex',
                flexDirection:'row',
                gap: 5,
                justifyContent: 'flex-end',
            }
        ],
        stepBackButton: [
            WorkflowApprovalStepGlobalClassNames.stepBackButton,
            {
                cursor:'pointer',
                textDecoration:'underline',
                color: palette.themePrimary
            }
        ],
        actionContainer: [
            WorkflowApprovalStepGlobalClassNames.actionContainer,
            {
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between'
            }
        ],
        uploadFile:[
            WorkflowApprovalStepGlobalClassNames.uploadFile,
            {
                ':hover':{
                    backgroundColor: 'none',
                    color: palette.neutralDark
                },
                color: palette.themePrimary,
                padding: 5
            }
        ],
        errorFileUpload: [
            WorkflowApprovalStepGlobalClassNames.errorFileUpload, 
            {
                width: 'auto',
                maxWidth: '40%',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                color: palette.redDark,
            }
        ],
        titleWrapper: [
            WorkflowApprovalStepGlobalClassNames.titleWrapper, 
            {
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
            }
        ]
    };
}