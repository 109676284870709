import React, { useEffect, useState } from "react";
import { classNamesFunction, DefaultButton, IContextualMenuItem, IContextualMenuProps, Stack } from "@fluentui/react";
import { useConst } from '@fluentui/react-hooks';
import { useTranslation } from "react-i18next";
import { IRequestFiltersProps, IRequestFiltersPropsStyles, IRequestFiltersStatusStyles } from "./filtersDropdown.types";
import { useWorkflowDispatch, useWorkflowState } from "../../../workflowStore";
import WorkflowCustomFilterModal from "../../workflowDashboard/workflowCustomFilterModal/WorkflowCustomFilterModal";
import { updateFilters } from "../../../features/workflowList";

const getClassNames = classNamesFunction<IRequestFiltersPropsStyles, IRequestFiltersStatusStyles>();

export const RequestFiltersBase = (props: IRequestFiltersProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className }); 
    const { t } = useTranslation(['workflow', 'dashboard']);

    const [showCustomFilterModal, setShowCustomFilterModal] = useState<boolean>(false);
    const [oldContextualButtonKey, setOldContextualButtonKey] = useState<string>('');
    const [contextualButtonKey, setContextualButtonKey] = useState<string>('all');
    const [contextualButtonText, setContextualButtonText] = useState<string>(t('filters.all'));
    const lastMonthDate = new Date(new Date(Date.now()).setMonth(new Date(Date.now()).getMonth() - 1));
    const lastWeekDate = new Date(new Date(Date.now()).setDate(new Date(Date.now()).getDate() - 7));
    const filters = useWorkflowState().workflowList.filters;    
    const selectedWorkflowLab = useWorkflowState().workflowList.selectedWorkflowLab;
    const dispatch = useWorkflowDispatch();

    const today = useConst(new Date(Date.now()));

    const onContextualClick = (item: IContextualMenuItem | undefined): void => {
        if (!item) return;
        setOldContextualButtonKey(contextualButtonKey);
        setContextualButtonKey(item.key ?? '');
        setContextualButtonText(item.text ?? '');
        if (item.key === 'custom') {
            setShowCustomFilterModal(true);
        } else
            onFilter(item.key === 'lastMonth' ? lastMonthDate : item.key === 'lastWeek' ? lastWeekDate : undefined, undefined);
    };

    const onModalCustomFilterDismiss = (): void => {
        //rieseguo il filtro sul vecchio
        setContextualButtonKey(oldContextualButtonKey);
        setContextualButtonText(t(`filters.${oldContextualButtonKey}`));
        onFilter(oldContextualButtonKey === 'lastMonth' ? lastMonthDate : oldContextualButtonKey === 'lastWeek' ? lastWeekDate : undefined, undefined);
    };

    const onFilter = async (from: Date | undefined, to: Date | undefined): Promise<void> => {

        if (showCustomFilterModal)
            setShowCustomFilterModal(false);
    
        const toDate = (to ?? today).toISOString();
        const fromDate =(from ?? today).toISOString();
        if (!from && !to) {
            dispatch(updateFilters({ 
                ...filters,
                pageNumber: 0,
                creationDateFrom: from,
                creationDateTo: to
            }));
            return;
        }
        
        dispatch(updateFilters({ 
            ...filters,
            pageNumber: 0,
            creationDateFrom: fromDate,
            creationDateTo: toDate
        }));

        if (props.onReset)
            props.onReset();
    };
    
    useEffect(() => {
        setContextualButtonKey('all');
        setContextualButtonText(t('workflow:filters.all'));
    }, [selectedWorkflowLab]); //eslint-disable-line react-hooks/exhaustive-deps

    const getContextualMenuProps: IContextualMenuProps = {
        items: [
            {
                key: 'all',
                text: t('filters.all'),
                onClick: (_, item) => onContextualClick(item)
            },
            {
                key: 'lastMonth',
                text: t('filters.lastMonth'),
                onClick: (_, item) => onContextualClick(item)
            },
            {
                key: 'lastWeek',
                text: t('filters.lastWeek'),
                onClick: (_, item) => onContextualClick(item)
            },
            {
                key: 'custom',
                text: t('filters.custom'),
                onClick: (_, item) => onContextualClick(item)
            },
        ]
    };

    return (
        <React.Fragment>
        <Stack style={{ height: '100%'}} horizontal tokens={{ childrenGap: 20, padding: 0 }}>
            <Stack.Item className={classNames.filterContainer}>
                <DefaultButton
                    disabled={props.disabled??false}
                    className={classNames.filter}
                    id="filterButton"
                    text={contextualButtonText}
                    menuProps={getContextualMenuProps}
                />
            </Stack.Item>
        </Stack>
        {showCustomFilterModal && <WorkflowCustomFilterModal
            isOpen={showCustomFilterModal}
            onDismiss={() => onModalCustomFilterDismiss()}
            onFilter={(from, to) =>{
                onFilter(from, to)
            }}
        />}
    </React.Fragment>
    );
}