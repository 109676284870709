import { IStyleFunction } from "@fluentui/react"
import { IEdiFilePropsStyles, IEdiFileStyles } from "./CopyFileOnEdiModal.types"

export const EdiFileGlobalClassNames = {
    root: 'edi-Teams-file-root',
    saveFooterContainer:'edi-Teams-file-saveFooterContainer'
}

export const getStyles: IStyleFunction<IEdiFilePropsStyles, IEdiFileStyles> = (props: IEdiFilePropsStyles): IEdiFileStyles => {
    return {
        root: [
            EdiFileGlobalClassNames.root,
            {
               // marginBottom: 20
            }
        ],
        saveFooterContainer: {
            display: 'flex',
            flexDirection:'row',
            justifyContent: 'flex-end',
            alignItems: 'center',
            width:'100%'
        },
        saveButtonContainer: {
            display: 'flex',
            flexDirection:'row',
            alignItems:'center',
            justifyContent: 'space-between',
        },
        wellDoneImage: {
            display:'flex',
            //flexBasis: '50%',
            //flexShrink: 0,
            fontSize: 24,
            fontWeight: 600,
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height:'100%'
        },
        secondFooterContainer: {
            display: 'flex',
            alignItems: 'center',
            height: '100%'
        },
        subfirstFooterContainer: {
            display: 'flex',
            justifyContent: 'space-between'
        },
        firstFooterContainer: {
            paddingTop: '10px',
            textAlign: 'right'
        },
        footer: {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            textAlign: 'center'
        },

    };
}