import { styled } from "@fluentui/react";
import { PeopleSelectorBase } from "./peopleSelector.base";
import { getStyles } from "./peopleSelector.styles";
import { IPeopleSelectorBaseProps, IPeopleSelectorBasePropsStyles, IPeopleSelectorBaseStyles } from "./peopleSelector.types";

const PeopleSelector = styled<IPeopleSelectorBaseProps, IPeopleSelectorBasePropsStyles, IPeopleSelectorBaseStyles>(
    PeopleSelectorBase, 
    getStyles,
    undefined,
    { scope: 'PeopleSelector'}
)

export default PeopleSelector;