import { IDetailsListStyleProps, IDetailsListStyles, IShimmeredDetailsListStyleProps, IShimmeredDetailsListStyles, IStyleFunction } from "@fluentui/react";
import { ITeamNavigationPropsStyles, ITeamNavigationStyles } from "./teamNavigation.types";

export const ArchiveNavigationGlobalClassNames = {
    root: 'edi-teams-navigation-root',
}

export const getStyles: IStyleFunction<ITeamNavigationPropsStyles, ITeamNavigationStyles> = (props: ITeamNavigationPropsStyles): ITeamNavigationStyles => {
    const { palette } = props.theme!; //eslint-disable-line @typescript-eslint/no-non-null-assertion

    return {
        root: [
            ArchiveNavigationGlobalClassNames.root,
            {
                height: '100%',
                '> .ms-Viewport': {
                    height: '100%'
                }
            },
            props.isEmptyList && {
                '> .ms-Viewport .ms-DetailsList-contentWrapper': {
                    flexBasis: '0 !important'
                }
            }
        ],
        emptyFolder: {
            height: '170px',
        },
        load: {
            display: 'none'
        },
        disabled: {
            opacity: '0.3', 
            'div:first-child': {
                opacity: '0.3', 
            }, 
        },  
        breadcrumbContainer: {
            display: "flex", 
            height: "48px"
        }, 
        iconContainer: {
            display: "flex", 
            alignItems: "center", 
            margin: "8px 0 0", 
            paddingLeft: "8px"
        }, 
        nameContainer: {
            width: '100%', 
            paddingLeft: '12px', 
            display: "flex", 
            alignItems: "center", 
            margin: "8px 0 0" 
        },
        searchContainer: {
            display: 'flex',
             flexDirection: 'row', 
             alignItems: 'center',
             background: palette.neutralLight
        },
        subComponentStyles: {
            shimmeredDetailsList: (props: IShimmeredDetailsListStyleProps): IShimmeredDetailsListStyles => {
                const { palette } = props.theme;
                return {
                    root: {
                        '::after': {
                            display: 'none'
                        },
                        position: 'relative',
                        height: '100%',
                        '& [role="grid"]': {
                            display: 'flex',
                            flexDirection: 'column',
                            height: '100%'
                        },
                        ' .ms-DetailsHeader': {
                            background: palette.white
                        },
                        ' .ms-DetailsRow': {
                            background: palette.white,                         
                        }, 
                        ' .ms-DetailsRow:hover': {
                            background: 'rgba(37, 36, 35, 0.05)',
                            color: 'rgb(96, 94, 92)'
                        },          
                    }
                }
            },
            detailsList: (props: IDetailsListStyleProps): IDetailsListStyles => {
                const { palette } = props.theme;
                return {
                    root: {
                        height: '100%',
                        '& [role="grid"]': {
                            display: 'flex',
                            flexDirection: 'column',
                            height: '100%'
                        },
                        ' .ms-DetailsHeader': {
                            background: palette.white
                        },
                        ' .ms-DetailsRow': {
                            background: palette.white,                         
                        }, 
                        ' .ms-DetailsRow:hover': {
                            background: 'rgba(37, 36, 35, 0.05)',
                            color: 'rgb(96, 94, 92)'
                        },                        
                    },
                    headerWrapper: {
                        flex: '0 0 auto'
                    },
                    contentWrapper: {
                        flexGrow: '1',
                        flexShrink: '1',
                        flexBasis: '100%',
                        overflowY: 'auto',
                        overflowX: 'hidden',
                        '@media(max-width: 730px)': { // 798 - 68
                            overflowX: 'auto' // show horizontal scrollbar when list is not shrinkable anymore
                        }
                    },
                    focusZone: {},
                }
            }
        }
    };
}