import { IDropdownOption, IPersonaProps, ITag } from "@fluentui/react"
import { IUser } from "../common/interfaces/IUser"
import { CategoryLabelItem } from "../models/categoryView"
import { IExtPersonaProps } from "../models/User"
import { WorkFlowApproverStatus, WorkFlowDisjointment, WorkFlowStep, WorkFlowStepRelativeStatus, WorkFlowStepStatus } from "../models/WorkFlow"
import { WorkFlowApprover } from "../models/workflowApprover"
import { WorkFlowTemplateApprover, WorkflowTemplateLabel, WorkFlowTemplateStep } from "../models/workflowTemplate"
//import { ITagExtItem } from "../workflow/root/common/modalPages/addLabelsPage"
import { IExtTagItemProps } from "../workflow/root/common/labelsSelector/labelsSelector.types"
import { WorkflowUserRoleId } from "./constants"
import { AvailableApproversAndLabels, GetAvailableUsersType } from "../workflow/services/workflow.contracts";
import { groupBy } from "lodash"
import { LabelItem } from "../models/labelView"

export const mapper = {

    // IUser --> IPersonaProps
    mapUserToPersona: (user: IUser | undefined): IPersonaProps => {
        return {
            text: user ? `${user.firstName} ${user.lastName}` : '',
            secondaryText: user ? user.email : ''
        }
    },

    // IExtPersonaProps --> IUser
    mapPersonaToUser: (persona: IExtPersonaProps): IUser => {
        return {
            id: persona.userData.id ? persona.userData.id : '',
            firstName: persona.userData.firstName ? persona.userData.firstName : '',
            lastName: persona.userData.lastName ? persona.userData.lastName : '',
            email: persona.userData.email ? persona.userData.email : '',
            roleId: persona.userData.roleId || undefined, 
            workFlowLabRoleId : persona.userData.workFlowLabRoleId,
            labels : persona.userData.labels
        }
    },

    // IExtPersonaProps --> WorkFlowApprover
    mapPersonaToApprover: (persona: IExtPersonaProps) : WorkFlowApprover => {
        return {
            status: WorkFlowApproverStatus.ToBeApproved,
            workFlowStepId: 0,
            
            approver: {
                id: persona.userData.id ? persona.userData.id : '',
                firstName: persona.userData.firstName ? persona.userData.firstName : '',
                lastName: persona.userData.lastName ? persona.userData.lastName : '',
                email: persona.userData.email ? persona.userData.email : '',
                roleId: WorkflowUserRoleId.User,
                workFlowLabRoleId : persona.userData.workFlowLabRoleId,
                labels : persona.userData.labels
            }
        }
    },

    // IExtPersonaProps --> WorkFlowTemplateApprover
    mapPersonaToTemplateApprover: (persona: IExtPersonaProps) : WorkFlowTemplateApprover => {
        return {
            workFlowTemplateStepId: 0,
            
            approver: {
                id: persona.userData.id ? persona.userData.id : '',
                firstName: persona.userData.firstName ? persona.userData.firstName : '',
                lastName: persona.userData.lastName ? persona.userData.lastName : '',
                email: persona.userData.email ? persona.userData.email : '',
                roleId: WorkflowUserRoleId.User,
                workFlowLabRoleId : persona.userData.workFlowLabRoleId,
                labels : persona.userData.labels
            }
        }
    },

    // CategoryLabelItem --> ITag
    mapLabelToTag: (label: CategoryLabelItem | undefined): ITag =>{
        return{
            name: label? label.name : '',
            key: label? label.id: 0
        }
    },

    // CategoryLabelItem --> IDropdownOption
    mapLabelToDropdownOption: (label: CategoryLabelItem | undefined): IDropdownOption =>{
        return{
            text: label? label.name : '',
            key: label? label.id: 0
        }
    },

    // IExtTagItemProps --> CategoryLabelItem
    mapTagToLabel: (tag: IExtTagItemProps): CategoryLabelItem =>{
        return{
            name: tag.labelData.name? tag.labelData.name : '',
            id: tag.labelData.id,
            description: tag.labelData.name? tag.labelData.description: ''
        }
    },

    mapWorkflowApproverTemplateToBaseWorkflowApprover: (from: WorkFlowTemplateApprover) : WorkFlowApprover => {
        return {
            approver: from.approver,
            status: WorkFlowApproverStatus.ToBeApproved,
            workFlowStepId: 0
        }
    },

    mapWorkflowTemplateStepApproversToAvaiableApproversAndLabes: (from: WorkFlowTemplateStep):  AvailableApproversAndLabels[] => {
        const result : AvailableApproversAndLabels[] = [];
        from.approvers.forEach(a => result.push({
            user: {
                firstName: a.approver.firstName,
                email: a.approver.email,
                id: a.approver.id,
                lastName: a.approver.lastName
            },
            type: GetAvailableUsersType.user
        } as AvailableApproversAndLabels));

        from.labels.forEach(l => result.push({
            label: l,
            type: GetAvailableUsersType.label
        } as AvailableApproversAndLabels))
        
        return result;
    }, 

    mapWorkflowTemplateStepToWorkflowStep: (from: WorkFlowTemplateStep): WorkFlowStep => {
        return {
            id: 0,
            approvedOn: new Date(),
            approvers: from.approvers.map(a => mapper.mapWorkflowApproverTemplateToBaseWorkflowApprover(a)),
            comment: "",
            createdOn: new Date(),
            involvedPeople: [],
            lastUpdatedOn: new Date(),
            name: from.name,
            order: from.order,
            relativeStatus: WorkFlowStepRelativeStatus.ToBeApproved,
            status: WorkFlowStepStatus.ToBeApproved,
            type: from.type,
            workFlowId: 0,
            usersAndLabels: mapper.mapWorkflowTemplateStepApproversToAvaiableApproversAndLabes(from)
        }
    },

    mapWorkflowTemplateLabelToWorkFlowDisjointment: (from: WorkflowTemplateLabel[]): WorkFlowDisjointment[]  => {
        const result : WorkFlowDisjointment[] = [];
        
        let id = 1;
        for (const [_, value] of Object.entries(groupBy(from, (f) => f.labelCategory.category.id))) { //eslint-disable-line @typescript-eslint/no-unused-vars
            result.push({
                category: value[0]?.labelCategory.category,
                labels: value.map(v => mapper.mapCategoryLabelItemToLabelItem(v.labelCategory.label)),
                id: id,
            });
            id = (id + 1);
        }
        
        return result;
    },

    mapCategoryLabelItemToLabelItem: (from: LabelItem) : CategoryLabelItem => {
        return {
            description: from.description,
            id: from.id,
            name: from.name
        }
    },

}