/*eslint-disable sonarjs/cognitive-complexity */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FileFormatExtension, FileType } from "../../../utilities/constants";
import { templateApi } from "../../../workflow/services/template.api";
import { workflowApi } from "../../../workflow/services/workflow.api";
import { PreviewResponse } from "../../../workflow/services/workflow.contracts";
import { workFlowStepApi } from "../../../workflow/services/workflowstep/workflowstep.api";
import { ErrorsType } from "../../types/ErrorsType";
import TeamsImage from "../teamsImage/teamsImage";
import { ImageName } from "../teamsImage/teamsImage.types";
import TeamsSpinner from "../teamsSpinner/teamsSpinner";
import { IFilePreviewProps } from "./filePreview.types";
import { PreviewSource } from "./filePreviewSource";

export const PreviewWrapper = (props: IFilePreviewProps) => {
  const [loading, setLoading] = useState(false);
  const [documentUrl, setdocumentUrl] = useState<string>("");
  const [error, setError] = useState("");
  const { t } = useTranslation("common");
  const params = window.location.pathname.split("/");
  const source = params.pop();
  const id = params.pop();

  const loadUrl = async () => {
    setdocumentUrl("");
    setError("");
    setLoading(true);
    try {
      let response;
      if(id && source === PreviewSource.Workflow){
        response = await workflowApi.getWorkflowFilePreviewUrl(parseInt(id));
      }
      if(id && source === PreviewSource.Template){
        response = await templateApi.getTemplateFilePreviewUrl(parseInt(id));
      }
      if(id && source === PreviewSource.WorkflowStep){
        response = await workFlowStepApi.getWorkflowStepFilePreviewUrl(parseInt(id));
      }
      const tempResponse = calculateUrlPreview(response);
      setdocumentUrl(tempResponse);
    }
    catch (er) {
      const error: ErrorsType = er as ErrorsType;
      switch (error.code) {
        case 403:
          setError("archiveLinkForbiddenMessage");
          break;
        case 404:
          setError("fileNotFound");
          break;
        default:
          setError("genericErrorApi");
          break;
      }
    }
    finally {
      setLoading(false);
    }
  };

  const calculateUrlPreview = (preview : PreviewResponse) => {
    if(preview.mimeType !== FileFormatExtension[FileType.Pdf].key)
      return `${preview.url}&&wddownloadbutton=false`
    else 
      return preview.url;
  }

  useEffect(() => {
    loadUrl();
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  const getTeamsImage = (imageName: ImageName) => {
    return (
      <div>
        <TeamsImage
          styles={{
            img: {
              marginTop: "200px",
              height: "25%",
              width: "25%",
            },
          }}
          imageName={imageName}
          fullContainer
          caption={t(error)}
        />
      </div>
    );
  }

  !loading && documentUrl !== "" && window.location.assign(documentUrl);
  switch (error) {
    case "archiveLinkForbiddenMessage":
      return getTeamsImage(ImageName.Forbidden);
    case ("genericErrorApi"):
      return getTeamsImage(ImageName.EmptySearch);
    case ("fileNotFound"):
      return getTeamsImage(ImageName.EmptySearch);
    default:
      return (
        <TeamsSpinner
          styles={{
            root: {
              position: "absolute",
              top: "calc(50% - 19px)",
              left: "calc(50% - 19px)",
            },
          }}
        />
      );
  }
};

export const FilePreviewBase = (props: IFilePreviewProps) => {
  return <></>;
};
