import React, { useState } from "react";
import { classNamesFunction, Stack } from "@fluentui/react";
import { Route } from "react-router-dom";
import NotificationPanel from "../../common/components/notificationPanel/notificationPanel";
import Page from "../../common/components/page/Page";
import { IRootProps, IRootPropsStyles, IRootStyles } from "./root.types";
import Toolbar from "./toolbar/toolbar";
import WorkflowLabList from "./workflowLabList/workflowLabList";
import { WorkflowLabRoutes, WorkflowLabSettingsTab, WorkflowLabTemplateTab, WorkflowTab } from "../utilities/routes";
import WorkflowOverview from "./workflowOverview/workFlowOverview";
import WorkFlowDetailsPage from "./workFlowDetailsPage/workFlowDetailsPage";
import WorkflowLabManageUsersPage from "./workflowLabManageUsers/workflowLabManageUsers";
import WorkflowDashboardContainer from "./workflowDashboard/workflowDashboardContainer";
import WorkflowLabManageTemplatesPage from "./workflowLabManageTemplate/workflowLabManageTemplates";
import { showRenameWorkflowModal, useWorkflowList } from "../features/workflowList";
import RenameWorkflowLabModal from "./renameWorkflowLabModal/renameWorkflowLabModal";
import { useWorkflowDispatch } from "../workflowStore";

const getClassNames = classNamesFunction<IRootPropsStyles, IRootStyles>();

const collapseStyle = {
    flexBasis: 0,
    opacity: 0,
}

export const RootBase = (props: IRootProps) => {
    const dispatch = useWorkflowDispatch();
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
    const {isRenameWorkflowModalOpen} = useWorkflowList();
    //const { t } = useTranslation('common');

    const isWorkflowDeepLink = (): boolean => {
        if (props.ctx?.subEntityId) {
            return atob(props.ctx?.subEntityId).includes(WorkflowTab.workflow);
        }
        return false;
    }

    return (
        <>
            <Stack className={classNames.root} horizontal verticalAlign={"stretch"}>
                <Stack.Item className={classNames.leftNav} disableShrink style={sidebarCollapsed ? collapseStyle : {}}>
                    <WorkflowLabList isWorkflowDeepLink={isWorkflowDeepLink()}/>
                </Stack.Item>
                <Stack.Item className={classNames.rightContent} grow>
                    <Stack style={{ height: '100%' }} horizontal={false} verticalAlign={"stretch"}>
                        <Stack.Item disableShrink>
                            <Toolbar onMenuButtonClick={() => setSidebarCollapsed(!sidebarCollapsed)} />
                        </Stack.Item>
                        <Stack.Item grow>
                            <Route path={`${WorkflowLabRoutes.workflowDetails}`}>
                                <Page title="Workflow lab - Workflow Details" content={WorkFlowDetailsPage} />
                            </Route>
                            <Route  path={`${WorkflowLabRoutes.workflowLab}/${WorkflowTab.workflow}`}>
                                <Page title="Workflow Lab - Overview" content={WorkflowOverview}></Page>
                            </Route>
                            <Route path={`${WorkflowLabRoutes.workflowLab}/${WorkflowTab.dashboard}`}>
                                <Page title="Workflow Lab - Dashboard" content={WorkflowDashboardContainer}></Page>
                            </Route>  
                            <Route path={`${WorkflowLabRoutes.workflowLab}/settings/${WorkflowLabSettingsTab.users}`}>
                                <Page title="Workflow lab - Manage Users" content={WorkflowLabManageUsersPage} />
                            </Route>
                            <Route path={`${WorkflowLabRoutes.workflowLab}/settings/${WorkflowLabTemplateTab.template}`}>
                                <Page title="Workflow lab - Manage Template" content={WorkflowLabManageTemplatesPage} />
                            </Route>       
                        </Stack.Item>
                    </Stack>
                </Stack.Item>
            </Stack>

            <NotificationPanel />

            {isRenameWorkflowModalOpen && <RenameWorkflowLabModal onClose={() => { dispatch(showRenameWorkflowModal(false)) }} />}
        </>
    );
}