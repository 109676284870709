import { styled } from "@fluentui/react";
import { getStyles } from "./deleteCategoryModal.styles";
import { IDeleteCategoryModalPropsStyles, IDeleteCategoryModalStyles, IDeleteCategoryModalProps } from "./deleteCategoryModal.types";
import { DeleteCategoryModalBase as DeleteCategoryModalComponent } from "./deleteCategoryModal.base";

const DeleteCategoryModal = styled<IDeleteCategoryModalProps, IDeleteCategoryModalPropsStyles, IDeleteCategoryModalStyles>(
    DeleteCategoryModalComponent,
    getStyles,
    undefined,
    { scope: 'DeleteCategoryModal' }
);

export default DeleteCategoryModal;