import { styled } from "@fluentui/react";
import { getStyles } from "./addDisjointment.styles";
import { IAddDisjointmentPropsStyles, IAddDisjointmentStyles, IAddDisjointmentProps } from "./addDisjointment.types";
import { AddDisjointmentBase } from "./addDisjointment.base";

const AddDisjointment = styled<IAddDisjointmentProps, IAddDisjointmentPropsStyles, IAddDisjointmentStyles>(
    AddDisjointmentBase,
    getStyles,
    undefined,
    { scope: 'AddDisjointment' }
);

export default AddDisjointment;