export const localEnvironment: Record<string,string> = {
    'edi-be-edi-workflow': 'https://localhost:44316/'
}

export const environment: Record<string, string> = {
    'localhost:3010': 'https://apim-wwe-ictx-edi.azure-api.net/local',
    'sd-edi-workflow.eni.com': 'https://api-app.eni.com/sd-edi/',
    'st-edi-workflow.eni.com': 'https://api-app.eni.com/st-edi/',
    'pp-edi-workflow.eni.com': 'https://api-app.eni.com/pp-edi/',
    'edi-workflow.eni.com': 'https://api-app.eni.com/pr-edi/'
}

export const svcPaths: Record<string,string> = {
    workflow: 'edi-be-edi-workflow'
}

export const isInDebug = (baseURL:string|undefined, url:string|undefined) => {
    if(!url)
        return baseURL;

    if (window.location.host !== 'localhost:3010')
        return baseURL;

    const svcPath = url.split('/')[0];
    return localEnvironment[svcPath] ?? baseURL;
}