import React, { useCallback, useState } from "react";
import { classNamesFunction, DefaultButton, Icon, IStyleFunctionOrObject, ITextFieldStyleProps, ITextFieldStyles, keyframes, PrimaryButton, TextField } from "@fluentui/react";
import { ICreateCategoryModalStyles, ICreateCategoryModalProps, ICreateCategoryModalPropsStyles } from "./createCategoryModal.types";
import { useTranslation } from "react-i18next";
import EdiModal from "../../../../common/components/ediModal/ediModal";
import { Helpers } from "../../../../utilities/helpers";
import { Constants } from "../../../../models/constants";
import { categoryApi } from "../../../services/category/category.api";
import { CreateCategoryRequest } from "../../../services/category/category.contracts";
const getClassNames = classNamesFunction<ICreateCategoryModalPropsStyles, ICreateCategoryModalStyles>();

export const CreateCategoryModalBase = (props: ICreateCategoryModalProps) => {
    const classNames = getClassNames(props.styles,  { theme: props.theme, className: props.className });
    const { palette } = props.theme!; // eslint-disable-line @typescript-eslint/no-non-null-assertion
    const { t } = useTranslation(['createCategoryModal', 'common']);
    
    const [currentName, setCurrentName] = useState<string | undefined>(undefined);
    const [validationIcon, setValidationIcon] = useState('Error');
    
    const onNameValidationResult = useCallback((error: string | JSX.Element, value: string | undefined) => {
        setValidationIcon(error ? 'Error' : 'Completed');
    }, []);

    const validateWorkflowLabName = useCallback(async (value: string) => {
        if (!value)
            return `${t('common:fieldRequired')}`;

        setValidationIcon('ProgressRingDots');

        try {
            const [isValid] = await Promise.all([categoryApi.isCategoryNameAvailable(props.workFlowLabId, value), Helpers.delay(500)]);
            return isValid ? "" : t('common:nameNotAvailable');
        }
        catch {
            return t('common:genericErrorCheckName');
        }
    }, [t, props.workFlowLabId]);

    const setupParameter = () => {
        return <div>
            <TextField
                    label={t('common:name')}
                    placeholder={t('addName')}
                    deferredValidationTime={250}
                    onGetErrorMessage={validateWorkflowLabName}
                    required
                    autoFocus
                    autoComplete={"off"}
                    styles={styles}
                    onNotifyValidationResult={onNameValidationResult}
                    onChange={(_, newValue) => setCurrentName(newValue)}
                    maxLength={Constants.MAX_LENGTH_CATEGORY_NAME}
                    onRenderSuffix={() => <Icon iconName={validationIcon} />}
                />
        </div>
    }

    const styles: IStyleFunctionOrObject<ITextFieldStyleProps, ITextFieldStyles> = () => {
        return {
            root: {
                marginBottom: '30px'
            },
            errorMessage: {
                position: 'absolute'
            },
            suffix: [
                { background: 'transparent', },
                validationIcon === 'Error' && { color: palette.redDark },
                validationIcon === 'Completed' && { color: palette.greenDark },
                validationIcon === 'ProgressRingDots' && {
                    '> i': {
                        animation: `${keyframes({ to: { transform: 'rotate(360deg)' } })} 1s linear infinite`
                    }
                }
            ]
        }
    }

    const createCategory = () => {
        const creationRequest: CreateCategoryRequest = {
            workflowLabId: props.workFlowLabId,
            name: currentName ?? ""
        }

        if(currentName)
            categoryApi.createCategory(creationRequest)
                .then(() => {
                    if (props.onComplete)
                        props.onComplete();
                    if (props.onClose)
                        props.onClose();
                })
    }


    const modalFooter = () => {
        return (
            <div>
                <DefaultButton
                    onClick={props.onClose}
                    style={{marginRight: '10px'}}
                >
                    {t('common:cancel')}
                </DefaultButton>
                
                <PrimaryButton
                    disabled={validationIcon !== "Completed"}
                    onClick={createCategory}
                >
                    {t('common:create')}
                </PrimaryButton>
            </div>
        )
    }
    return (
        <>
            <EdiModal
                className={classNames.root}
                title={`${t('createCategory')}`}
                isOpen={props.isOpen}
                width={600}
                height= {600}
                showCloseIcon={true}
                onCloseClick={props.onClose}
                body={setupParameter()}
                footer={modalFooter()}
                styles={
                    {
                        body: {
                            overflow: 'unset'
                        }
                    }
                }
            />
        </>
    );
}