import { styled } from "@fluentui/react";
import { WorkflowOverviewBase } from "./workFlowOverview.base";
import { getStyles } from "./workFlowOverview.styles";
import { IWorkflowOverviewPropsStyles, IWorkflowOverviewStyles, IWorkflowOverviewProps } from "./workFlowOverview.types";

const WorkflowOverview = styled<IWorkflowOverviewProps, IWorkflowOverviewPropsStyles, IWorkflowOverviewStyles>(
    WorkflowOverviewBase,
    getStyles,
    undefined,
    { scope: 'WorkflowOverview' }
);

export default WorkflowOverview;