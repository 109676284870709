import { styled } from "@fluentui/react";
import { getStyles } from "./workflowLabMembersSettings.styles";
import { WorkflowLabMembersSettingsBase } from "./workflowLabMembersSettings.base";
import { IWorkflowLabMembersSettingsProps, IWorkflowLabMembersSettingsPropsStyles, IWorkflowLabMembersSettingsStyles } from "./workflowLabMembersSettings.types";

const WorkflowLabMembersSettings = styled<IWorkflowLabMembersSettingsProps, IWorkflowLabMembersSettingsPropsStyles, IWorkflowLabMembersSettingsStyles>(
    WorkflowLabMembersSettingsBase,
    getStyles,
    undefined,
    { scope: 'WorkflowLabMembersSettings' }
);

export default WorkflowLabMembersSettings;