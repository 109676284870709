import { styled } from "@fluentui/react";
import { getStyles } from "./workflowLabManageUsers.styles";
import { IWorkflowLabManageUsersPropsStyles, IWorkflowLabManageUsersStyles, IWorkflowLabManageUsersProps } from "./workflowLabManageUsers.types";
import { WorkflowLabManageUsers } from "./workflowLabManageUsers.base";

const WorkflowLabManageUsersPage = styled<IWorkflowLabManageUsersProps, IWorkflowLabManageUsersPropsStyles, IWorkflowLabManageUsersStyles>(
    WorkflowLabManageUsers,
    getStyles,
    undefined,
    { scope: 'WorkflowLabManageUsers' }
);

export default WorkflowLabManageUsersPage;