import { generatePath, matchPath } from "react-router-dom";

export enum AppChannel {
    teams = "teams"
}

export enum AppTab {
    workflow = "workflowlabs",
}

class RoutesClass {
    public home = `/:channel(${AppChannel.teams})`;
    public tab = `${this.home}/:tab(${AppTab.workflow})`;

    public generateRouteForTab(tab: AppTab) {
        return generatePath(this.tab, { channel: AppChannel.teams, tab: tab });
    }

    public getSelectedTab(path: string) {
        return matchPath<{ tab: AppTab }>(path, { path: this.tab })?.params.tab;
    }

    public isHome(path: string) {
        return matchPath(path, { path: this.home, exact: true }) !== null;
    }
}

export const AppRoutes = new RoutesClass();