import { IStyleFunction, keyframes } from "@fluentui/react"
import { ITeamsSpinnerPropsStyles, ITeamsSpinnerStyles } from "./teamsSpinner.types"

export const TeamsSpinnerGlobalClassNames = {
    root: 'edi-teams-spinner-root',
    wrapper: 'edi-teams-spinner-wrapper'
}



export const getStyles: IStyleFunction<ITeamsSpinnerPropsStyles, ITeamsSpinnerStyles> = (props: ITeamsSpinnerPropsStyles): ITeamsSpinnerStyles => {
    return {
        root: [
            TeamsSpinnerGlobalClassNames.root,
            {
                width: 38,
                height: 38,
                overflow: 'hidden',
            }
        ],
        wrapper: [
            TeamsSpinnerGlobalClassNames.wrapper,
            {
                animation: `${keyframes({to: {transform: 'translate3d(0, -2280px, 0)'}})} 2s steps(60) infinite both`,
                selectors: {
                    '& svg': {
                        width: 38,
                        height: 2280,
                        margin: 0,
                        padding: 0
                    }
                }
            }
        ]
    };
}