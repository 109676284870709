/*eslint-disable sonarjs/cognitive-complexity*/
import React, { useState } from "react";
import { classNamesFunction, DetailsList, IColumn, SelectionMode } from "@fluentui/react";
import { IDocumentTagsListProps, IDocumentTagsListPropsStyles, IDocumentTagsListStyles } from "./documentTagsList.types";
import { useTranslation } from "react-i18next";
import { useOnMount } from "../../../../../utilities/hooks";
import JsonForm from "../../../../../common/components/tagPanel/jsonForm/jsonForm";
import { WorkFlow } from "../../../../../models/WorkFlow";
import { WorkflowShort } from "../../../../../models/WorkflowShort";
import { WorkFlowStepDocumentDetails } from "../../../../../models/WorkFlowDetails";

const getClassNames = classNamesFunction<IDocumentTagsListPropsStyles, IDocumentTagsListStyles>();

export const DocumentTagsListBase = (props: IDocumentTagsListProps) => {
  const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
  const { t } = useTranslation(['uploadFileModal', 'common']);
  const [errorForm, setErrorForm] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>("");
  console.log(props.documents);
  console.log(props.workflows);

  useOnMount(()=>{
    if(props.shortJsonSchema === undefined || props.shortJsonSchema === null)
      setErrorForm(true);
  });
  
  const columns1: IColumn[] = [
    {
      key: 'column0',
      name: t('detailsList.documentName'),
      ariaLabel: 'name',
      isResizable: false,
      fieldName: '',
      minWidth: 300,
      maxWidth: 300,
      isCollapsible: true,
      headerClassName: classNames.root,
      onRender: function columnRender(item1: WorkflowShort) {
        return <span>{item1.documentName}</span>
      }
    },
    {
      key: 'column1',
      name: t('detailsList.documentTag'),
      ariaLabel: 'tags',
      isResizable: true,
      fieldName: '',
      minWidth: 300,
      maxWidth: 300,
      isCollapsible: true,
      headerClassName: classNames.root,
      onRender: function columnRender(item1: WorkflowShort) {
        return errorForm ? <span>{t('common:genericErrorApi')}</span> :
        <JsonForm
          searchText={searchText}
          formData={{}}
          schema={props.shortJsonSchema}
          uiSchema={{}}
          onChange={(changeEvent) => { item1.documentType = changeEvent.formData.documentType; }}
          onChangeSearchText={(str: string) => setSearchText(str)}
          hasErrorFE={() => setErrorForm(true)}
          disabled={false}
          onSubmit={() => console.log("Submit")}
          onError={(err) => console.log("Errors: ", err)}
        />
      },
    }
  ];

  const columns2: IColumn[] = [
    {
      key: 'column0',
      name: t('detailsList.documentName'),
      ariaLabel: 'name',
      isResizable: false,
      fieldName: '',
      minWidth: 300,
      maxWidth: 300,
      isCollapsible: true,
      headerClassName: classNames.root,
      onRender: function columnRender(item2: WorkFlowStepDocumentDetails) {
        return <span>{item2.document}</span>
      }
    },
    {
      key: 'column1',
      name: t('detailsList.documentTag'),
      ariaLabel: 'tags',
      isResizable: true,
      fieldName: '',
      minWidth: 300,
      maxWidth: 300,
      isCollapsible: true,
      headerClassName: classNames.root,
      onRender: function columnRender(item2: WorkFlowStepDocumentDetails) {
        return errorForm ? <span>{t('common:genericErrorApi')}</span> :
        <JsonForm
          searchText={searchText}
          formData={{}}
          schema={props.shortJsonSchema}
          uiSchema={{}}
          onChange={(changeEvent) => { item2.documentType = changeEvent.formData.documentType; }}
          onChangeSearchText={(str: string) => setSearchText(str)}
          hasErrorFE={() => setErrorForm(true)}
          disabled={false}
          onSubmit={() => console.log("Submit")}
          onError={(err) => console.log("Errors: ", err)}
        />
      },
    }
  ];

  return (
    props.documents?.length === 0 ?
    <DetailsList
      items={props.workflows ? props.workflows : []}
      columns={columns1}
      styles={classNames.subComponentStyles.detailsList}
      setKey={"documentType"}
      getKey={(item: WorkFlow) => item.id.toString()}
      selectionMode={SelectionMode.none}
    /> : <DetailsList
    items={props.documents ? props.documents : []}
    columns={columns2}
    styles={classNames.subComponentStyles.detailsList}
    setKey={"documentType"}
    getKey={(item: WorkFlowStepDocumentDetails) => item.stepDocumentId.toString()}
    selectionMode={SelectionMode.none}
  />
  );
}