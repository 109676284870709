import { IBasePickerStyleProps, IBasePickerStyles, ILabelStyles, IShimmeredDetailsListStyleProps, IShimmeredDetailsListStyles, IStyleFunction } from "@fluentui/react"
import { ILabelsSelectorBasePropsStyles, ILabelsSelectorBaseStyles } from "./labelsSelector.types";


export const LabelsSelectorGlobalClassNames = {
    root: 'edi-select-profile-viewer-root',
    disclaimerZone: 'edi-select-profile-viewer-disclaimerZone',
    labelsPickerSuggestions: 'edi-select-profile-viewer-labelsPickerSuggestions',
    labelsPickerSuggestionItem: 'edi-select-profile-viewer-labelsPickerSuggestionItem',
    iconTagLabel: 'edi-select-profile-view-icontaglabel',
    labelBox: 'edi-label-box',
    containerTag: 'edi-select-profile-containertag',
    profileContainer: 'edi-select-profile-profilecontainer',
    templatesCountCircle: 'edi-select-profile-templatescountcircle',
    remainingIconTagContainer: 'edi-select-profile-remainingicontagcontainer',
    labelsPicker: 'edi-select-profile-labels-picker'
}

export const getStyles: IStyleFunction<ILabelsSelectorBasePropsStyles, ILabelsSelectorBaseStyles> = (props: ILabelsSelectorBasePropsStyles): ILabelsSelectorBaseStyles => {
    const { semanticColors, palette } = props.theme!; // eslint-disable-line @typescript-eslint/no-non-null-assertion

    return {
        root: [
            LabelsSelectorGlobalClassNames.root,
            {
            }
        ],
        disclaimerZone: {
            display: 'flex',
            flexDirection: 'column',
            marginBottom: 30
        },
        labelBox: {
            width: '100px',
            overflow: 'hidden',
            height: '25px',
            padding: '3px 0'
        },
        labelsPickerZone: {
            display: 'flex',
            marginBottom: '20px',
        },
        labelsPickerSuggestions: {
            padding: '8px 0',
            maxWidth:'500px',
        },
        labelsPickerSuggestionItem: {
            '::after': {
                display: 'none',
            },
            ".is-suggested": {
                background: props.theme?.palette.neutralQuaternary
            }
        },
        detailsListRow: {
            background: 'transparent !important',
        },
        iconTagLabel: {
            width: '150px',
            overflow: 'hidden',
            height: '25px',
            padding: '3px 0',
            cursor: 'inherit',
        },
        iconTag: {
            width: '150px'
        },
        checkboxAlign: {
            height: '100%',
            alignItems: 'center',
            ".ms-Checkbox-label": {
                alignItems: 'center',
                height: '32px'
            },
            ".ms-Checkbox-text": {
                wordBreak: 'break-word',
                whiteSpace: 'pre-wrap',
                fontSize: '13px',
                lineHeight: '12px'
            },
        },
        containerTag: {
            display: 'flex',
            height: '100%',
            alignItems: 'center',
            flexFlow: 'row wrap',
            gap: '5px 10px'
        },
        profileContainer: {
            display: 'flex'
        },
        remainingIconTagContainer: {
            padding: '0 5px',
            width: '100%',
            textAlign: 'left',
            justifyContent: 'start',
            color: palette.themePrimary
        },
        subComponentStyles: {
            templatesCountCircle: (): ILabelStyles => {
                return {
                    root: {
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '50%',
                        width: '30px',
                        height: '30px',
                        fontSize: '12px',
                        backgroundColor: semanticColors.variantBorder,
                        color: palette.themePrimary,
                    }
                }
            },
            shimmeredDetailsList: (props: IShimmeredDetailsListStyleProps): IShimmeredDetailsListStyles => {
                return {
                    root: {
                        '::after': {
                            display: 'none'
                        },
                        position: 'relative'
                    }
                }
            },
            labelsPicker: (props: IBasePickerStyleProps): IBasePickerStyles => {
                return {
                    input: {
                        backgroundColor: 'transparent',
                    },
                    text: {
                        width: '415px',
                        marginRight: '20px',
                        border: '1px solid rgb(240, 240, 240) !important',
                        borderRadius: 2,
                        backgroundColor: 'rgb(240, 240, 240)',
                        borderLeft: "none",
                        borderRight: "none",
                        borderTop: "none",
                        borderBottom: "none",
                        selectors: {
                            "::after": {
                                borderLeft: "none",
                                borderRight: "none",
                                borderTop: "none",
                                borderRadius: 0,
                            },
                        }
                    },
                    itemsWrapper: {
                        ".ms-PickerPersona-container": {
                            background: 'rgb(245, 245, 245)',
                            border: "1px solid rgb(245, 245, 245)",
                            ':hover': {
                                background: 'rgba(98, 100, 167, 0.2)',
                                border: "1px solid rgba(98, 100, 167, 0.2)",
                            }
                        },
                    },
                    root: {},
                    screenReaderText: {}
                }
            }
        }
    }
}