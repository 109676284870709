import { styled } from "@fluentui/react";
import { RemoveMemberButtonBase } from "./removeMemberButton.base";
import { getStyles } from "./removeMemberButton.styles";
import { IRemoveMemberButtonProps, IRemoveMemberButtonPropsStyles, IRemoveMemberButtonStyles } from "./removeMemberButton.types";

const RemoveMemberButton = styled<IRemoveMemberButtonProps, IRemoveMemberButtonPropsStyles, IRemoveMemberButtonStyles>(
    RemoveMemberButtonBase,
    getStyles,
    undefined,
    { scope: 'EditLabelUserModal' }
);

export default RemoveMemberButton;