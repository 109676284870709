import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import callNotification from './features/callNotification';
import workflowList from './features/workflowList';

const workflowStore = configureStore({
    reducer: {
        workflowList: workflowList,
        callNotification: callNotification,
    }
});

export type WorkflowState = ReturnType<typeof workflowStore.getState>;
export type WorkflowDispatch = typeof workflowStore.dispatch;
export default workflowStore;

export const useWorkflowState = (): WorkflowState => workflowStore.getState();
export const useWorkflowDispatch = (): WorkflowDispatch => useDispatch<WorkflowDispatch>();
export const useWorkflowSelector: TypedUseSelectorHook<WorkflowState> = useSelector;
