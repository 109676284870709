import { CancelToken } from "axios";
import QueryString from "qs";
import { CategoryLabelItem, CategoryViewItem } from "../../../models/categoryView";
import { apiClient } from "../../../modules/apiClient/apiClient";
import { svcPaths } from "../../../modules/apiClient/config";
import { AddLabelsToCategoryRequest, CreateCategoryRequest, GetAllCategoryRequest, GetCategoryRequest, GetLabelAssociatedWithCategory } from "./category.contracts";

class CategoryApiClass {    

    async getCategories(request: GetCategoryRequest, cancelToken?: CancelToken) {
        const response = await apiClient.get<CategoryViewItem[]>(`${svcPaths.workflow}/category/getcategories`, {
            params: {
                workflowLabId: request.workflowLabId,
                pageNumber: request.pageNumber,
                pageSize: request.pageSize,
                keyword: request.keyword,
                categoryIdsToExclude: request.categoryIdsToExclude,
            },
            paramsSerializer: (params) => QueryString.stringify(params, { arrayFormat: 'repeat' }),
            cancelToken: cancelToken
        })
        return response.data;
    }

    async getAllCategories(request: GetAllCategoryRequest, cancelToken?: CancelToken) {
        const response = await apiClient.get<CategoryViewItem[]>(`${svcPaths.workflow}/category/getallcategories`, {
            params: {
                workflowLabId: request.workflowLabId
            },
            cancelToken: cancelToken
        })
        return response.data;
    }

    async createCategory(request: CreateCategoryRequest, cancelToken?: CancelToken) {
        const response = await apiClient.post(`${svcPaths.workflow}/category/createCategory`, {
            workFlowLabId: request.workflowLabId,
            name: request.name
        })
        return response.data;
    }

    async addLabelsToCategory(request: AddLabelsToCategoryRequest, cancelToken?: CancelToken) {
        const response = await apiClient.put(`${svcPaths.workflow}/category/addLabelsToCategory`, {
            categoryId: request.categoryId,
            labels: request.labels
        })
        return response.data;
    }    

    async deleteCategory(id: number){
        const response = await apiClient.delete(`${svcPaths.workflow}/category/${id}`)
        return response.data;
    }

    async updateCategory(categoryId: number, name: string) {

        const response = await apiClient.put(`${svcPaths.workflow}/category/updateCategory`, {
            Id: categoryId,
            name: name,
        })
        return response.data;
    }

    async isCategoryNameAvailable(workFlowLabId: number, name: string) {
        const response = await apiClient.post(`${svcPaths.workflow}/category/check-name`, {
            workFlowLabId: workFlowLabId,
            name: name
        })
        return response.data;
    }

    async GetLabelsAssociatedWithCategory(request: GetLabelAssociatedWithCategory, cancelToken?: CancelToken){
        const response = await apiClient.get<CategoryLabelItem[]>(`${svcPaths.workflow}/category/getLabelsassociatedwithcategory`, {params: {
                id: request.workflowLabId,
                categoryId: request.categoryId,
            },
            paramsSerializer: (params) => QueryString.stringify(params, { arrayFormat: 'repeat' }),
            cancelToken: cancelToken
        });
        return response.data;
    }

}

export const categoryApi = new CategoryApiClass();