import { DefaultEffects, IStyleFunction } from "@fluentui/react"
import { ILabelListPropsStyles, ILabelListStyles } from "./labelList.types";

export const LabelListGlobalClassNames = {
    root: 'edi-label-list-root',
    error: 'edi-label-list-error',
    empty: 'edi-label-list-empty',
    stackItem: 'edi-label-list-stack-item',
    labelBox: 'edi-label-list-label-box',
    flexCenter: 'edi-label-list-flex-center',
    iconTagLabel: 'edi-label',
    tagPanel: 'edi-panel',
    containerTag: 'edi-container'
}

export const getStyles: IStyleFunction<ILabelListPropsStyles, ILabelListStyles> = (props: ILabelListPropsStyles): ILabelListStyles => {
    const { palette } = props.theme!; // eslint-disable-line @typescript-eslint/no-non-null-assertion
    return {
        root: [
            LabelListGlobalClassNames.root,
            {
                overflowY: 'auto',
                overflowX: 'hidden',
                backgroundColor: palette.white
            }
        ],
        error: [
            LabelListGlobalClassNames.error,
            {
                color: palette.redDark,
                fontWeight: 'bold',
                height: '100%'
            }
        ],
        empty: [
            LabelListGlobalClassNames.empty,
            {
                fontWeight: 'bold',
                height: '100%'
            }
        ],
        stackItem: [
            LabelListGlobalClassNames.stackItem,
            {
                boxShadow: DefaultEffects.elevation8,
                padding: 15,
                background: palette.white
            }
        ],
        flexCenter: [
            LabelListGlobalClassNames.flexCenter,
            {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative'
            }
        ],
        tagPanel: [
            LabelListGlobalClassNames.tagPanel,
            {
                height: '100%',
                backgroundColor: palette.white
            }
        ],
        containerTag: [
            LabelListGlobalClassNames.containerTag,
            {
                display: 'flex',
                alignContent: 'flex-start',
                flexFlow: 'row wrap',
                gap: '15px',
                overflowX: 'hidden',
                overflowY: 'auto',
                width: 'calc(100% - 20px)',
                padding: '10px',
                backgroundColor: palette.white
            }
        ],
        labelBox: [
            LabelListGlobalClassNames.labelBox, {
                width: '122px',
                overflow: 'hidden',
                height: '30px',
            }
        ],
        iconTagLabel: [
            LabelListGlobalClassNames.iconTagLabel,
            {
                width: '172px',
                overflow: 'hidden',
                height: '30px',
                padding: '10px',
                cursor: 'inherit',
            },
        ]
    };
}