/*eslint-disable sonarjs/cognitive-complexity */
import { ActionButton, classNamesFunction, DefaultButton, DetailsList, DetailsRow, Dialog, DialogFooter, GroupHeader, IColumn, IconButton, IGroup, IGroupHeaderProps, IGroupRenderProps, PrimaryButton, SelectionMode, Spinner, SpinnerSize } from "@fluentui/react";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import IconTag from "../../../../common/components/iconTag/iconTag";
import MemberList from "../../../../common/components/memberList/memberList";
import TeamsImage from "../../../../common/components/teamsImage/teamsImage";
import { ImageName } from "../../../../common/components/teamsImage/teamsImage.types";
import { PersonaDetails } from "../../../../common/models/PersonaDetails";
import { UserShort } from "../../../../models/UserShort";
import { WorkflowShort } from "../../../../models/WorkflowShort";
import { Helpers } from "../../../../utilities/helpers";
import { useNavigator } from "../../../hooks/useNavigator";
import { useWorkflowState } from "../../../workflowStore";
import { IWorkflowDisjointedProps, IWorkflowDisjointedPropsStyles, IWorkflowDisjointedStyles } from "./workflowDisjointed.types";
import { workflowDisjointedApi } from "../../../services/workflowDisjointed/workflowDisjointed.api";
import { useCurrentWorkflowLab } from "../../../hooks/useCurrentWorkflowLab";
import { useAsyncApi } from "../../../../utilities/hooks";
import currentUser from "../../../../modules/authentication/currentUser";
import CopyFileOnEdiModal from "../copyOnEdiModal/CopyFileOnEdiModal";
import { WorkFlowRelativeStatus } from "../../../../models/WorkflowEnum";
import { WorkFlowLabRoleId } from "../../../../utilities/constants";

const getClassNames = classNamesFunction<IWorkflowDisjointedPropsStyles, IWorkflowDisjointedStyles>();

export const WorkflowDisjointedBase = (props: IWorkflowDisjointedProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const { t } = useTranslation(['workflowOverview', 'workflowDisjointed', 'common']);
    const selectedWorkflowLab = useCurrentWorkflowLab();
    const [selectedWorkFlow, setSelectedWorkflow] = useState(0);
    const [disjointed, setDisjointed] = useState(false);
    const filters = useWorkflowState().workflowList.filters;
    const navigator = useNavigator();
    const [keyProp, setKeyProp] = useState("");
    const [copyOnEdiModalShown, setCopyOnEdiModalShown] = useState(false);
    const currentUserId = currentUser.currentUserId;
    const [deleteDisjointedModalShown, setDeleteDisjointedModalShown] = useState(false);
    const [instance, setInstance] = useState(false);

    type customGroup = { group?: IGroup, isClosed?: boolean, hasUploadables?: boolean, createdBy?: string };

    const { execute: onClickSendReminder, loading: loadingReminder } =
        useAsyncApi<number, number>({
            func: async (input: number) => {
                return workflowDisjointedApi.sendWorkflowDisjointedeminder(input);
            }
        });

    const openCallout = async (input: number) => {
        await onClickSendReminder(input);
    };

    const { execute: groupDelete, loading: deleting } =
        useAsyncApi<number, void>({
            func: async (input: number) => {
                await workflowDisjointedApi.deleteWorkflowDisjointed(input);
            }
        });

    const { execute: onClickDownload, loading: downloading } =
        useAsyncApi<{ input: number, disjointedName: string }, void>({
            func: async (request: { input: number, disjointedName: string }) => {
                return workflowDisjointedApi.exportDisjointedWorkflowInfo(
                    request.input,
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    request.disjointedName + '.xlsx'
                )
            }
        });

    const openDownload = async (input: number, disjointedName: string) => {
        await onClickDownload({ disjointedName: disjointedName, input: input });
    };

    const groupProps: IGroupRenderProps = {
        onRenderHeader: function renderHeader(groupProps?: IGroupHeaderProps): JSX.Element {
            const myGroup = groupProps?.group as customGroup
            return (
                <div style={{ display: "flex", alignItems: "center" }}>
                    <GroupHeader className={classNames.groupHeader} {...groupProps} />
                    {selectedWorkflowLab ?
                        <div className={classNames.groupAction}>
                            {(loadingReminder || downloading || deleting) && keyProp === groupProps?.group?.key &&
                                <Spinner size={SpinnerSize.medium} />
                            }
                            {!myGroup.isClosed && selectedWorkflowLab.roleId !== WorkFlowLabRoleId.Auditor &&
                                <ActionButton
                                    disabled={(loadingReminder || downloading) && keyProp === groupProps?.group?.key}
                                    onClick={() => groupProps?.group?.key ? (setKeyProp(groupProps?.group?.key), openCallout(parseInt(groupProps?.group?.key))) : null}
                                >
                                    {t('workflowDisjointed:urges')}
                                </ActionButton>
                            }
                            <IconButton iconProps={{ iconName: 'Download' }}
                                disabled={(loadingReminder || downloading) && keyProp === groupProps?.group?.key}
                                onClick={() => groupProps?.group?.key ? (setKeyProp(groupProps?.group?.key), openDownload(parseInt(groupProps?.group?.key), groupProps?.group?.name)) : null}
                            />
                            {myGroup.hasUploadables && (myGroup.createdBy === currentUserId || selectedWorkflowLab?.roleId === WorkFlowLabRoleId.Owner) &&
                                selectedWorkflowLab.roleId !== WorkFlowLabRoleId.Auditor &&
                                <IconButton iconProps={{ iconName: 'Share' }}
                                    disabled={(loadingReminder || downloading) && keyProp === groupProps?.group?.key}
                                    onClick={() => groupProps?.group?.key ? (setKeyProp(groupProps?.group?.key), setCopyOnEdiModalShown(true), setDisjointed(true), setSelectedWorkflow(parseInt(groupProps?.group?.key))) : null}
                                />
                            }
                            {groupProps?.group?.count === 0 && myGroup.createdBy === currentUserId &&
                                selectedWorkflowLab.roleId !== WorkFlowLabRoleId.Auditor &&
                                <IconButton iconProps={{ iconName: 'Delete' }}
                                    disabled={(loadingReminder || downloading) && keyProp === groupProps?.group?.key}
                                    onClick={() => groupProps?.group?.key ? (setKeyProp(groupProps?.group?.key), setDeleteDisjointedModalShown(true), setSelectedWorkflow(parseInt(groupProps?.group?.key))) : null}
                                />
                            }
                        </div>
                        : null}
                </div>
            )
        },
        showEmptyGroups: true
    };

    const mapToPersonaDetails = (users: UserShort[] | null): PersonaDetails[] => {
        const filteredUser = users === null ? [] : users.filter(u => u !== null);
        return filteredUser.map(a => {
            return { firstName: a.firstName, lastName: a.lastName, email: '' } as PersonaDetails;
        });
    }

/*
    const sortByColumn = (_: React.MouseEvent, column: IColumn) => {
        props.sortByColumnDisjointed(column);
    };
*/
    const columns = useMemo(() => {
        let result: IColumn[] = [
            {
                key: 'name',
                name: t('column.name'),
                ariaLabel: 'name',
                isResizable: true,
                fieldName: 'Name',
                minWidth: 100,
                maxWidth: 140,
                isCollapsible: true,
                /*isSorted: filters.orderBy === 'Name',*/
                isSortedDescending: !filters.isAscending,
                /*onColumnClick: (_, column) => sortByColumn(_, column),*/
                onRender: function getItemName(item: WorkflowShort) {
                    return <div className={classNames.workflowOverviewNameWrapper}>
                        <span>{item.name}</span>
                    </div>
                }
            },
        ];
        result = [...result, ...[{
            key: 'template',
            name: t('column.template'),
            ariaLabel: 'template',
            isResizable: true,
            fieldName: 'Template',
            minWidth: 100,
            maxWidth: 100,
            isCollapsible: true,
            onRender: function getItemName(item: WorkflowShort) {
                return item.templateName ?
                    <IconTag
                        label={item.templateName}
                        tooltip={true}
                        className={classNames.workflowOverviewNameWrapperTag}
                        hiddenIcon={true}
                        styles={{ root: { backgroundColor: props.theme?.palette['magenta'] } }}
                    />
                    : <></>
            },
        }]];

        const leftColumns: IColumn[] = [
            {
                key: 'status',
                name: t('column.status'),
                ariaLabel: 'status',
                fieldName: 'status',
                minWidth: 130,
                maxWidth: 130,
                isCollapsible: true,
                isResizable: true,
                onRender: function getItemType(item: WorkflowShort) {
                    return <div className={classNames.workflowOverviewNameWrapper}>
                        <span>{t(`status.${item.relativeStatus}`)}</span>
                    </div>
                }
            },
            {
                key: 'createdOn',
                name: t("column.createdOn"),
                ariaLabel: 'createdOn',
                fieldName: 'CreatedOn',
                minWidth: 100,
                maxWidth: 100,
                isResizable: true,
                isCollapsible: true,
                // isSorted: filters.orderBy === 'CreatedOn',
                // isSortedDescending: !filters.isAscending,
                // onColumnClick: (_, column) => sortByColumn(_, column),
                onRender: function getItemType(item: WorkflowShort) {
                    return <span>{Helpers.getShortDate(item.createdOn)}</span>
                }
            },
            {
                key: 'requestedBy',
                name: t("column.requestedBy"),
                ariaLabel: 'requestedBy',
                fieldName: 'requestedBy',
                minWidth: 80,
                maxWidth: 80,
                isResizable: true,
                isCollapsible: true,
                onRender: function getItemDate(item: WorkflowShort) {
                    return <MemberList
                        memberRounded={true}
                        members={mapToPersonaDetails([item.creator])}
                        sliceLength={1}
                    />
                }
            },
            {
                key: 'assignedTo',
                name: t("column.assignedTo"),
                ariaLabel: 'assignedTo',
                fieldName: 'assignedTo',
                minWidth: 70,
                maxWidth: 70,
                isResizable: true,
                isCollapsible: true,
                onRender: function getItemFrom(item: WorkflowShort) {
                    return <MemberList
                        members={mapToPersonaDetails(item.assignedTo)}
                        sliceLength={1}
                        memberRounded={true}
                    />;
                }
            },
            {
                key: 'expirationDate',
                name: t("column.expirationDate"),
                ariaLabel: 'expirationDate',
                fieldName: 'ExpirationDate',
                minWidth: 100,
                maxWidth: 100,
                isResizable: true,
                isCollapsible: true,
                // isSorted: filters.orderBy === 'ExpirationDate',
                // isSortedDescending: !filters.isAscending,
                // onColumnClick: (_, column) => sortByColumn(_, column),
                onRender: function getItemType(item: WorkflowShort) {
                    return <span>{Helpers.getShortDate(item.expirationDate ?? null)}</span>
                }
            },
            {
                key: 'delete',
                name: '',
                ariaLabel: 'delete',
                fieldName: 'Delete',
                minWidth: 20,
                maxWidth: 20,
                isResizable: true,
                isCollapsible: true,
                onRender: function getItemType(item: WorkflowShort) {
                    if (item.creatorId === currentUser.currentUserId && selectedWorkflowLab?.roleId !== WorkFlowLabRoleId.Auditor)
                        return (
                            <IconButton
                                iconProps={{ iconName: "Delete" }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    props.deleteDisjointed(item);
                                }}
                            />
                        )
                }
            },
            {
                key: 'save',
                name: '',
                ariaLabel: 'save',
                fieldName: 'Save',
                minWidth: 20,
                maxWidth: 20,
                isResizable: true,
                isCollapsible: true,
                onRender: function getItemType(item: WorkflowShort) {
                    if (item.relativeStatus === WorkFlowRelativeStatus.Approved && (item.creatorId === currentUser.currentUserId || selectedWorkflowLab?.roleId === WorkFlowLabRoleId.Owner) && !item.ediFileId && selectedWorkflowLab?.roleId !== WorkFlowLabRoleId.Auditor)
                        return (
                            <IconButton
                                iconProps={{ iconName: "Share" }}
                                text="Save to EDI"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setCopyOnEdiModalShown(true);
                                    setInstance(true);
                                    setSelectedWorkflow(item.id)
                                }}
                            />)
                }
            }
        ];

        return [...result, ...leftColumns];
    }, [classNames, navigator, t, props.theme?.palette]); //eslint-disable-line react-hooks/exhaustive-deps

    const emptyList = useCallback(() => {
        if (props.workflows.length !== 0)
            return null;
        return (
            <div className={classNames.emptyList}>
                <TeamsImage styles={{ img: { width: '60%' } }} imageName={ImageName.EmptyFolderDrop} caption={t('common:emptyList')} />
            </div>
        )
    }, [props.workflows.length, t, classNames.emptyList]);

    return (
        <>
            <div style={{ height: 'calc(100% - 10px)', overflow: 'overlay' }}>
                <DetailsList
                    items={props.workflows}
                    columns={columns}
                    //compact={true}
                    groups={props.groups}
                    groupProps={groupProps}
                    selectionMode={SelectionMode.none}
                    isHeaderVisible={true}
                    getKey={((item: WorkflowShort) => item.id.toString())}
                    setKey="none"
                    styles={classNames.subComponentStyles.detailsList}
                    onRenderRow={props => props ? (
                        <div
                            onClick={() => navigator.goToWorkflowDetails(props.item.id)}
                            style={{ cursor: 'pointer' }}
                        >
                            <DetailsRow
                                {...props}
                                styles={classNames.subComponentStyles.detailsRow}
                            />
                        </div>
                    ) : null}
                    onRenderDetailsFooter={emptyList}
                />
            </div>
            {
                copyOnEdiModalShown && <CopyFileOnEdiModal
                    initialState={{ folder: 0 }}
                    disjointed={disjointed}
                    selectedDocuments={[]}
                    selectedWorkfows={instance ? [...props.workflows.filter(x => x.id === selectedWorkFlow)] : [...props.workflows.filter(x => x.workflowDisjointedId === selectedWorkFlow && x.ediFileId === null && x.relativeStatus === WorkFlowRelativeStatus.Approved)]}
                    selectedDisjointed={selectedWorkFlow}
                    reloadList={() => { if (props.reloadList) props.reloadList() }}
                    saveModalShown={copyOnEdiModalShown}
                    closeModal={() => setCopyOnEdiModalShown(false)} />
            }
            {deleteDisjointedModalShown && <Dialog
                isOpen={deleteDisjointedModalShown}
                dialogContentProps={{
                    title: t('deleteTitle'),
                    showCloseButton: !deleting,
                    onDismiss: () => { setDeleteDisjointedModalShown(false); },
                    subText: t('deleteMessageDisjointed'),
                }}

            >
                <div className={classNames.deleteFooterContainer}>
                    <div className={classNames.deleteButtonContainer}>
                        {deleting && <Spinner size={SpinnerSize.large}></Spinner>}
                        <DialogFooter styles={classNames.subComponentStyles.deleteDialogFooterContainer}>
                            <DefaultButton
                                text={t('common:undoEliminate')}
                                disabled={deleting}
                                onClick={() => setDeleteDisjointedModalShown(false)}
                            />
                            <PrimaryButton
                                onClick={async () => {
                                    await groupDelete(selectedWorkFlow);
                                    setDeleteDisjointedModalShown(false);
                                    props.reloadList();
                                }}
                                styles={classNames.subComponentStyles.deletePrimaryButtonDisabled()}
                                text={t('common:confirmEliminate')}
                                disabled={deleting}
                            />
                        </DialogFooter>
                    </div>
                </div>
            </Dialog>}
        </>
    );
}
