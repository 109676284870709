import { IStyleFunction } from "@fluentui/react"
import { IChoiceInputSourcePropsStyles, IChoiceInputSourceStyles } from "./choiceInputSource.types"

export const ChoiceInputSourceGlobalClassNames = {
    root: 'edi-input-choice-root',
    presetFile: 'edi-input-choice-preset-file',
}

export const getStyles: IStyleFunction<IChoiceInputSourcePropsStyles, IChoiceInputSourceStyles> = (props: IChoiceInputSourcePropsStyles): IChoiceInputSourceStyles => {
    // const { palette } = props.theme!; //eslint-disable-line @typescript-eslint/no-non-null-assertion

    return {
        root: [
            ChoiceInputSourceGlobalClassNames.root,
            {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                gap: 20,
                alignItems: 'center'
            }
        ],
        presetFile: [
            ChoiceInputSourceGlobalClassNames.presetFile,
            {
                display: 'flex',
                width: 'fit-content',
                alignItems: 'center', 
                margin: '10px', 
                minHeight: '42px', 
                padding: '5px', 
                boxShadow: "0 0.16rem 0.36rem 0 rgb(0 0 0 / 13%), 0 0.03rem 0.09rem 0 rgb(0 0 0 / 11%)", 
                borderRadius: "0.4rem", 
                marginBottom: '30px' 
            }
        ]
    };
}