import { IDetailsRowStyleProps, IDetailsRowStyles, IStyle, IStyleFunctionOrObject, ITheme } from "@fluentui/react";
import { CancelToken } from "axios";
/*eslint-disable @typescript-eslint/no-explicit-any */

export enum UploadStatus {
    Uploading = 0,
    Uploaded = 1,
    Error = 2, 
    ToUpload = 3,
}

export interface IFileToUpload {
    fileName: string;
    fileType: string;
    fileSize: number;
    fileUploadStatus: UploadStatus; 
    errorMessage?: JSX.Element | JSX.Element[];
    file?: File;
    isValid: boolean; 
    id: string; 
}
export interface IFileUploaderProps {
    theme?: ITheme;
    styles?: IStyleFunctionOrObject<IFileUploaderPropsStyles, IFileUploaderStyles>;
    className?: string;
    fileTypes: string[];
    isFileDraggable: boolean;
    maxNumFilesUploadable: number;
    showMessageError: boolean;
    showMessages: boolean;    
    isFileAccepted?: (result?: boolean) => void
    multiUpload: boolean; 
    showFileNameOnly: boolean;
    removeBackground?: boolean;
    sendFiles?: (formData: FormData, cancelToken?: CancelToken) => Promise<any>
    onFileUpdated?: (files: IFileToUpload[], result?: any) => void;
    labelToPrint?: string;
    fileOnInit?: any;
    onFileUploadMetadata?: (files: File[]) => void;
    isDisable?: boolean;
    multiple: boolean;
    isUploading?: (actionCompleted: boolean) => void;
    disableUpdatedIcon?: boolean;
    reset? :boolean;
    // ADD COMPONENT PROPS HERE
}

export interface IFileUploaderPropsStyles {
    theme?: ITheme;
    styles?: IStyleFunctionOrObject<IFileUploaderPropsStyles, IFileUploaderStyles>;
    className?: string;
    // ADD STYLE PROPS HERE FOR CONDITIONAL CSS GENERATION
}

export interface IFileUploaderStyles {
    disclaimers: IStyle;
    pickerErrors: IStyle;
    rightContainer: IStyle;
    leftContainer: IStyle;
    dropzoneBorders: IStyle;
    dropzoneContent: IStyle;
    fileList: IStyle;
    textParagraph: IStyle;
    wellDoneImage: IStyle;
    body?: IStyle;

    spinner?: IStyle;
    firstFooterContainer?: IStyle;
    subfirstFooterContainer?: IStyle;
    progressIndicatorbody?: IStyle;
    progressIndicatoritemName?: IStyle;
    progressIndicatoritemDescription?: IStyle;
    detailsListContainer?: IStyle;
    removeBackgroung?: IStyle;
    footer?: IStyle;
    secondFooterContainer?: IStyle;
    subComponentStyles: { 
        detailsRow: IStyleFunctionOrObject<IDetailsRowStyleProps, IDetailsRowStyles>; 
    }
}

export enum UploadErrors {
    FileNull = "E1001",
    LengthOutOfRange = "E1002",
    NameInvalid = "E1003",
    MimeTypeNotAllowed = "E0050",
    InvalidFileSignature = "E0051",
    TooLarge = "E0052"
}