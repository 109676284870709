import { DefaultEffects, IStyleFunction } from "@fluentui/react"
import { IGraphWorkflowStatusPropsStyles, IGraphWorkflowStatusStyles } from "./graphWorkflowStatus.types"

export const GraphWorkflowStatusGlobalClassNames = {
    root: 'edi-graph-workflow-status-root',
    emptyGraph: 'edi-graph-workflow-status-stack-empty-graph',
    stackLeft: 'edi-graph-workflow-status-stack-left',
    stackRight: 'edi-graph-workflow-status-stack-right',
    doughnutTotal: 'edi-graph-workflow-status-doughnut-total',
    legendContainer: 'edi-graph-workflow-status-legend-container',
    legendItem: 'edi-graph-workflow-status-legend-item',
    legendCheck: 'edi-graph-workflow-status-legend-check',
    filterContainer: 'edi-graph-workflow-status-filter-container',
    stackItem: 'edi-dashboard-container-stack-item',
    title: 'edi-dashboard-container-title',
    doughnutTitle: 'edi-graph-workflow-status-doughnut-title',
}
//

export const getStyles: IStyleFunction<IGraphWorkflowStatusPropsStyles, IGraphWorkflowStatusStyles> = (props: IGraphWorkflowStatusPropsStyles): IGraphWorkflowStatusStyles => {
    const { palette } = props.theme!;//eslint-disable-line @typescript-eslint/no-non-null-assertion
    return {
        root: [
            GraphWorkflowStatusGlobalClassNames.root,
            {
                // CSS PROPS AND SELECTORS
                height: '82vh',
                overflowY: 'auto',
                overflowX: 'hidden'
            }
        ],
        emptyGraph: [
            GraphWorkflowStatusGlobalClassNames.emptyGraph,
            {
                height: '100%',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontWeight: 'bold'
            }
        ],
        stackLeft: [
            GraphWorkflowStatusGlobalClassNames.stackLeft,
            {
                width: '25%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative'
            }
        ],
        stackRight: [
            GraphWorkflowStatusGlobalClassNames.stackRight,
            {
                width: '25%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative'
            }
        ],
        doughnutTotal: [
            GraphWorkflowStatusGlobalClassNames.doughnutTotal,
            {
                position: 'absolute',
                userSelect: 'none',
                fontSize: '18px',
                top: '51%',
            }
        ],
        doughnutTitle: [
            GraphWorkflowStatusGlobalClassNames.doughnutTitle,
            {
                display: 'flex',
                alignItems: 'center',
                position: 'absolute',
                userSelect: 'none',
                fontSize: '13px',
                fontStyle: 'italic',
                fontWeight: 'bold',
                                alignSelf: "flex-start",
                left: 0,
            }
        ],
        legendContainer: [
            GraphWorkflowStatusGlobalClassNames.legendContainer,
            {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                height: '100%',
                flexWrap: 'wrap',
                '@media(max-width: 1410px)': {
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    alignContent: 'center',
                    rowGap: 10
                }

            }
        ],
        legendItem: [
            GraphWorkflowStatusGlobalClassNames.legendItem,
            {
                display: 'flex',
                alignItems: 'center',
                columnGap: '7px',
                marginLeft: 10,             
                fontSize: 13,   
                '@media(max-width: 1410px)': { 
                    margin: 0
                }
            }
        ],
        legendCheck: [
            GraphWorkflowStatusGlobalClassNames.legendCheck,
            {
                height: 7,
                maxHeight: 7,
                width: 7,
                maxWidth: 7,
            }
        ],
        filterContainer: [
            GraphWorkflowStatusGlobalClassNames.filterContainer,
            {
                position: 'absolute',
                top: -37,
                right: '10%',
                display: 'flex',
                flexDirection: 'column',
                gap: 5,
                minWidth: 175
            }
        ],
        stackItem: [
            GraphWorkflowStatusGlobalClassNames.stackItem,
            {
                boxShadow: DefaultEffects.elevation8,
                padding: 15,
                background: palette.white
            }
        ],
        title: [
            GraphWorkflowStatusGlobalClassNames.title,
            {
                fontWeight: 'bold',
                fontSize: '15px',
                paddingBottom: '15px',
            }
        ],
    };
}