import { styled } from "@fluentui/react";
import { WorkflowDisjointedBase } from "./workflowDisjointed.base";
import { getStyles } from "./workflowDisjointed.styles";
import { IWorkflowDisjointedProps, IWorkflowDisjointedPropsStyles, IWorkflowDisjointedStyles } from "./workflowDisjointed.types";

const WorkflowDisjointed = styled<IWorkflowDisjointedProps, IWorkflowDisjointedPropsStyles, IWorkflowDisjointedStyles>(
    WorkflowDisjointedBase,
    getStyles,
    undefined,
    { scope: 'WorkflowDisjointed' }
);

export default WorkflowDisjointed;