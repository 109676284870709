import { styled } from "@fluentui/react";
import { getStyles } from "./ediFile.styles";
import { IEdiFilePropsStyles, IEdiFileStyles, IEdiFileProps } from "./ediFile.types";
import { EdiFileBase } from "./ediFile.base";

const EdiFile = styled<IEdiFileProps, IEdiFilePropsStyles, IEdiFileStyles>(
    EdiFileBase,
    getStyles,
    undefined,
    { scope: 'EdiFile' }
);

export default EdiFile;