import { styled } from "@fluentui/react";
import { getStyles } from "./createCategoryModal.styles";
import { ICreateCategoryModalPropsStyles, ICreateCategoryModalStyles, ICreateCategoryModalProps } from "./createCategoryModal.types";
import { CreateCategoryModalBase } from "./createCategoryModal.base";

const CreateCategoryModal = styled<ICreateCategoryModalProps, ICreateCategoryModalPropsStyles, ICreateCategoryModalStyles>(
    CreateCategoryModalBase,
    getStyles,
    undefined,
    { scope: 'CreateCategoryModal' }
);

export default CreateCategoryModal;