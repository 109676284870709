import { IStyle, IStyleFunctionOrObject, ITheme } from "@fluentui/react";
import { FileShort } from "../../../../models/fileShort";
import { WorkFlowTemplate, WorkFlowTemplateStep } from "../../../../models/workflowTemplate";

export interface ICreateTemplateModalProps {
    theme?: ITheme;
    styles?: IStyleFunctionOrObject<ICreateTemplateModalPropsStyles, ICreateTemplateModalStyles>;
    className?: string;
    onCloseClick?: () => void;
    onSuccess?: () => void;
    isOpen?: boolean;
    template: WorkFlowTemplate;
    modalStep?: CreateTemplateModalEnum;
    overview?: boolean;
    loadingInfo?: boolean;
    // ADD COMPONENT PROPS HERE
}

export interface ICreateTemplateModalPropsStyles {
    theme?: ITheme;
    styles?: IStyleFunctionOrObject<ICreateTemplateModalPropsStyles, ICreateTemplateModalStyles>;
    className?: string;
    // ADD STYLE PROPS HERE FOR CONDITIONAL CSS GENERATION
}

export interface ICreateTemplateModalStyles {
    root: IStyle;
    buttonsContainer: IStyle;
    spinnerContainer: IStyle;
    requiredContainer:IStyle;
    requiredSign: IStyle;
}

export enum CreateTemplateModalEnum {
    setProprietiesStep,
    addDisjointmentLabelsStep,
    setFileSourceStep,
    localUploadStep,
    teamsUploadStep,
    ediUploadStep,
    addStepsStep,
    overviewStep,
    creatingStep,
}

export type FirstPageData = {
    name: string;
    description?: string;
    isValid: boolean;
    disjointed?: boolean;
}

export type SecondPageData = {
    file: FileShort | undefined;
    isPdf: boolean;
}

export type ThirdPageData = {
    digitalSignEnabled: boolean,
    steps: WorkFlowTemplateStep[]
}