import React, { useState } from "react";
import { ActionButton, classNamesFunction, Icon, Pivot, PivotItem, Spinner, SpinnerSize, Stack } from "@fluentui/react";
import { IWorkflowLabManageUsersStyles, IWorkflowLabManageUsersProps, IWorkflowLabManageUsersPropsStyles } from "./workflowLabManageUsers.types";
import { useTranslation } from "react-i18next";
import WorkflowLabManageUsersLabelView from "./workflowLabManageUsersLabelView/workflowLabManageUsersLabelView";
import WorkflowLabMembersSettings from "./workflowLabMembersSettings/workflowLabMembersSettings";
import WorkflowLabCategoryView from "./workflowLabCategoryView/workflowLabCategoryView";
import { useAsyncApi } from "../../../utilities/hooks";
import { useCurrentWorkflowLab } from "../../hooks/useCurrentWorkflowLab";
import UploadUserLabelModal from "./createWorkflowLabModal/uploadUserLabelModal";
import { workflowLabApi } from "../../services/workFlowLab/workFlowLab.api";
import { Call, callsList } from "../../../common/types/Call";
import { nanoid } from "@reduxjs/toolkit";
import { insertCall, setCall } from "../../features/callNotification";
import { useWorkflowDispatch } from "../../workflowStore";
import {useNavigator} from "../../hooks/useNavigator"
import ConfirmModal from "./confirmModal/confirmModal";
import { WorkflowTab } from "../../utilities/routes";

const getClassNames = classNamesFunction<IWorkflowLabManageUsersPropsStyles, IWorkflowLabManageUsersStyles>();
export const WorkflowLabManageUsers = (props: IWorkflowLabManageUsersProps) => {
    const classNames = getClassNames(props.styles,
        {
            theme: props.theme,
            className: props.className
        });
    const { t } = useTranslation(['workflowLabManageUsers']);
    const currentworkflowLab = useCurrentWorkflowLab() //eslint-disable-line @typescript-eslint/no-non-null-assertion
    const [isUploadFileModalOpen, setIsUploadFileModalOpen] = useState<boolean>(false);
    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
    const dispatch = useWorkflowDispatch();
    const navigator = useNavigator();
    const { execute: generateExport, loading: generatingExport } = useAsyncApi<void, void>({
        func: async () => {
            if (currentworkflowLab?.id)
                await workflowLabApi.export(currentworkflowLab.id
                    , 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                    , currentworkflowLab.name + '.xlsx');
        }
    });

    const { execute: synchronize } = useAsyncApi<void, void>({
        func: async () => {
            const synchWorkflowLab: Call = {
                type: callsList.synchWorkflowLab,
                nameOperation: t('synchWorkflowLabOperation'),
                errors: [
                    { code: 403, message: t('notPermissions') },
                    { code: 500, message: t('common:deleteGenericErrorApi')}
                ]
            }
            const id = nanoid();
            const payload = { requestId: id, notification: synchWorkflowLab }
            dispatch(insertCall(payload));
            try {
                if (currentworkflowLab?.id)
                await workflowLabApi.synchSlaveMaster(currentworkflowLab.id);
                navigator.changeWorkflowTab(WorkflowTab.workflow);
                const successPayload = { requestId: id, success: true, message: t('synchCompleted') }
                dispatch(setCall(successPayload));
            }
            catch (error:any) { //eslint-disable-line @typescript-eslint/no-explicit-any
                let failurePayload;
                if(error.code === 403){ 
                    failurePayload = { requestId: id, success: false, message: t('common:unAuthorized') }
                }
                else failurePayload = { requestId: id, success: false, message: t('common:genericErrorApi') }
                dispatch(setCall(failurePayload));
            }            
        }
    });    

    return (
        <>
            <div className={classNames.root}>
                <Stack horizontal horizontalAlign="space-between" verticalAlign="center">

                    <Stack.Item className={classNames.pageTitle} >
                        <Icon iconName={"PlayerSettings"} className={classNames.titleIcon} />
                        <span className={classNames.titleContent}>{t("common:manageUsers")}</span>
                    </Stack.Item>

                    <Stack.Item style={{display: 'flex'}}>
                        {(generatingExport) && <Spinner size={SpinnerSize.small} />}
                        <ActionButton
                            disabled={generatingExport}
                            onClick={() => generateExport()}
                            >
                            {t('export')}
                        </ActionButton>
                        {!currentworkflowLab?.workflowLabMaster?
                        <ActionButton onClick={() => setIsUploadFileModalOpen(true)} style={{marginLeft: 5, marginRight: 20}}>
                            {t('import')}
                        </ActionButton> :
                         <ActionButton onClick={() => setShowConfirmModal(true)} style={{marginLeft: 5, marginRight: 20}}>
                         {t('synch')}
                     </ActionButton>
                        }
                    </Stack.Item>                                                     
                
                </Stack>

                <Pivot className={classNames.pivot}>
                    <PivotItem className={classNames.userview} headerText={t('userview')}>
                        <WorkflowLabMembersSettings></WorkflowLabMembersSettings>
                    </PivotItem>
                    <PivotItem className={classNames.labelview} headerText={t('labelview')}>
                        <WorkflowLabManageUsersLabelView></WorkflowLabManageUsersLabelView>
                    </PivotItem>
                    <PivotItem className={classNames.categoryview} headerText={t('categoryview')}>
                        <WorkflowLabCategoryView></WorkflowLabCategoryView>
                    </PivotItem>
                </Pivot>
                
            </div>
            <ConfirmModal
                title={t('syncConfirmTitle')}
                subTitle={t('syncConfirmSubTitle')}
                closeLabel={t('syncCancelLabel')}
                confirmLabel={t('syncConfirmLabel')}
                isOpen={showConfirmModal}                        
                disable={false}
                confirmAction={() => {synchronize(); setShowConfirmModal(false);}}
                onClose={() => setShowConfirmModal(false)}
            />
            {isUploadFileModalOpen && 
                <UploadUserLabelModal
                isOpen={isUploadFileModalOpen}
                onClose={(success: boolean) => {
                    setIsUploadFileModalOpen(false);
                }}
                
                />
            }
        </>
    );
}
