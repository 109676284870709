/*eslint-disable sonarjs/cognitive-complexity */
import React, { useEffect, useState } from "react";
import { BasePicker, classNamesFunction, IPickerItemProps, Persona, PersonaSize } from "@fluentui/react";
import { ISelectLabelUserProps, ISelectLabelUserPropsStyles, ISelectLabelUserStyles, SearchType } from "./selectLabelUser.types";
import { GetAvailableUsersType, AvailableApproversAndLabels } from "../../../../../../services/workflow.contracts";
import { workflowApi } from "../../../../../../services/workflow.api";
import PickerWrapper from "../../../../../../../common/components/pickerWrapper/pickerWrapper";
import IconTag from "../../../../../../../common/components/iconTag/iconTag";
import { useWorkflowState } from "../../../../../../workflowStore";
import { WorkFlowStepTypes } from "../../../../../../../models/WorkFlow";

const getClassNames = classNamesFunction<ISelectLabelUserPropsStyles, ISelectLabelUserStyles>();

export const SelectLabelUserBase = (props: ISelectLabelUserProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const currentWorkflowLab = useWorkflowState();
    const [required, setRequired] = useState(props.isRequired);

    useEffect(() => {
        if(props.isRequired){
            if(props.isRequired && props.selectedApprovers && props.selectedApprovers?.length < 1)
                setRequired(true);
            else
                setRequired(false);
        }
    }, [props.selectedApprovers, props.isRequired])


    const getAvaiableMember = async (searchBy: string, selectedItems?: AvailableApproversAndLabels[]) => {
        let searchType = SearchType.Mixed;
        if(selectedItems && selectedItems[0]) {
            searchType = selectedItems[0].type === GetAvailableUsersType.label ? SearchType.OnlyLabels : SearchType.OnlyUser;
        }

        if(props.selectType)
            props.selectType(searchType);
        
        return await workflowApi.getAvaiableApproversAndLabels({
            workflowLabId: currentWorkflowLab.workflowList.selectedWorkflowLab || -1,
            excludeCurrentUser: false,
            excludeLabels: selectedItems?.filter(s => s.type === GetAvailableUsersType.label).map(i => parseInt(i.label.id.toString())) ?? [],
            excludeUsers: selectedItems?.filter(s => s.type === GetAvailableUsersType.user).map(i => i.user.id.toString()) ?? [],
            searchBy: searchBy,
            searchType: props.stepType === WorkFlowStepTypes.OnlyOneSignedApprover ? SearchType.OnlyUser : searchType
        });
    }

    const itemToRender = (props: IPickerItemProps<AvailableApproversAndLabels>) => {
        return (
            <>
                {props.item.type === GetAvailableUsersType.label && <PickerWrapper onCancelClick={props.onRemoveItem}>
                    <IconTag styles={{root: {minWidth: '100px', maxWidth: '300px', maxHeight: '24px', marginLeft: 4}}} label={props.item.label.name}/>
                </PickerWrapper>}
                {props.item.type === GetAvailableUsersType.user && <PickerWrapper onCancelClick={props.onRemoveItem}>
                    <Persona styles={{root: {padding: '4px 12px'}}} text={`${props.item.user.firstName} ${props.item.user.lastName}`} size={PersonaSize.size24}/>
                </PickerWrapper>}
            </>
        );
    }

    return (
        <div className={classNames.root}>
            <BasePicker
                key="base-picker-disjointed"
                onResolveSuggestions={(filter, selectedItems?) => {
                    return getAvaiableMember(filter, selectedItems as AvailableApproversAndLabels[])
                }} 
                onRenderItem={(value) => {
                    return itemToRender(value as IPickerItemProps<AvailableApproversAndLabels>)
                }}
                onRenderSuggestionsItem={(props, _) => {
                    const castedProps = props as AvailableApproversAndLabels;

                    if(castedProps.type === GetAvailableUsersType.label)
                        return <div style={{ padding: '8px 12px', maxHeight: '24px' }}>
                            <IconTag styles={{root: {minWidth: '100px',  maxWidth: '300px', maxHeight: '24px', padding: '4px 12px'}}} label={castedProps.label.name}/>        
                        </div>

                    if(castedProps.type === GetAvailableUsersType.user)
                        return <div style={{ padding: '8px 0px', height: '24px' }}>
                            <Persona styles={{root: {padding: '4px 12px'}}} text={`${castedProps.user.firstName} ${castedProps.user.lastName}`} size={PersonaSize.size24}/>
                        </div> 
                    
                    return <></>;
                }}
                onChange={(newValues) => {                        
                    const castedItem = newValues as AvailableApproversAndLabels[];

                    if(props.updateApprovers)
                        props.updateApprovers(castedItem);
                
                    if(props.selectType){
                        if(castedItem.length === 0)
                            props.selectType(SearchType.Mixed)
                        else
                            props.selectType(castedItem[0].type === GetAvailableUsersType.label ? SearchType.OnlyLabels : SearchType.OnlyUser);
                    }
                }}
                inputProps={
                    { placeholder: props.placeholder }
                }
                defaultSelectedItems={props.selectedApprovers}
            />
            { (required || props.customError) && 
                <div style={{color: 'rgb(164, 38, 44)', fontSize: '12px', paddingTop: '5px'}}>{required && props.errorMessage} {props.customError}</div>}
        </div>
    );
}