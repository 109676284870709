import { styled } from "@fluentui/react";
import { WorkFlowLabMembersModalBase } from "./workflowLabMembersModal.base";
import { getStyles } from "./workflowLabMembersModal.styles";
import { IWorkFlowLabMembersModalPropsStyles, IWorkFlowLabMembersModalStyles, IWorkFlowLabMembersModalProps } from "./workflowLabMembersModal.types";

const WorkFlowLabMembersModal = styled<IWorkFlowLabMembersModalProps, IWorkFlowLabMembersModalPropsStyles, IWorkFlowLabMembersModalStyles>(
    WorkFlowLabMembersModalBase,
    getStyles,
    undefined,
    { scope: 'WorkFlowLabMembersModal' }
);

export default WorkFlowLabMembersModal;