/*eslint-disable sonarjs/cognitive-complexity*/
import React, { useEffect, useState } from "react";
import { ILocalFileProps, ILocalFilePropsStyles, ILocalFileStyles } from "./localFile.types";
import FileUploader from "../../../../../common/components/fileUploader/fileUploader";
import { classNamesFunction, Icon, Toggle, TooltipHost } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { Helpers } from "../../../../../utilities/helpers";

const getClassNames = classNamesFunction<ILocalFilePropsStyles, ILocalFileStyles>();

export const LocalFileBase = (props: ILocalFileProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const [item, setItems] = useState<File[]>([]);
    const [isEditable, setIsEditable] = useState(false);
    const [isPDF, setIsPDF] = useState(false);
    const { t } = useTranslation(['createWorkflow', 'common']);

    function _onChange(ev: React.MouseEvent<HTMLElement>, checked?: boolean) {
        setIsPDF(false);
        if (item.length === 0) {
            if (checked !== undefined) {
                setIsEditable(checked);
                if(props.onFileEdit)
                    props.onFileEdit(checked);
            }
        } else {
            if (checked && nonEditableFileType.includes(item[0].type)) {
                setIsEditable(false);
                if(props.onFileEdit)
                    props.onFileEdit(false);
                setIsPDF(true);
            } else {
                if (checked !== undefined) {
                    setIsEditable(checked);
                    if(props.onFileEdit)
                        props.onFileEdit(checked);
                }
            }
        }
    }

    useEffect(() => {
        if (props.onFileUploadMetadata && props.onValidateFile) {
            let isValid = false;

            if (item.length === 0 && isPDF) {
                setIsEditable(false);
                if(props.onFileEdit)
                    props.onFileEdit(false);
                setIsPDF(false);
            }

            item.forEach(f => {
                if (allowedFileType.includes(f.type) && Helpers.convertToMB(f.size) <= 100){
                    isValid = true;
                    if(props.onFileEdit)
                        props.onFileEdit(isEditable);
                }
                if (nonEditableFileType.includes(f.type)) {
                    setIsEditable(false);
                    if(props.onFileEdit)
                        props.onFileEdit(false);
                    setIsPDF(true);
                }
            });

            props.onValidateFile(isValid);
            props.onFileUploadMetadata(item);
        }
    }, [item, props]); //eslint-disable-line react-hooks/exhaustive-deps

    const allowedFileType = [
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/pdf',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        'application/vnd.ms-powerpoint'
    ];

    const nonEditableFileType = ['application/pdf'];

    return (
        <div className={classNames.root}>
            <div className={classNames.subTitle}>{t("localFile.subtitle")}</div>
            <div className={classNames.text}>
                <div>{t("localFile.text")}</div>
                <div className={classNames.iconContainer}>
                    <TooltipHost content={t("localFile.tooltip")}>
                        <Icon iconName="Info" />
                    </TooltipHost>
                </div>
            </div>
            <FileUploader
                fileTypes={allowedFileType}
                isFileDraggable
                maxNumFilesUploadable={1}
                showFileNameOnly={false}
                showMessages={true}
                showMessageError={false}
                multiUpload={false}
                multiple={false}
                onFileUploadMetadata={setItems}
            />
            <Toggle hidden={props.onlyReadable ?? false} disabled={isPDF} checked={isEditable} label={<div>{t("toggle.label")}</div>} onText={t("toggle.on")} offText={t("toggle.off")} onChange={_onChange} />
        </div>
    );
}