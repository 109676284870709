import { styled } from "@fluentui/react";
import { getStyles } from "./workflowStepHistory.styles";
import { WorkflowStepHistoryBase } from "./workflowStepHistory.base";
import { IWorkflowStepHistoryProps, IWorkflowStepHistoryPropsStyles, IWorkflowStepHistoryStyles } from "./workflowStepHistory.types";

const WorkflowStepHistory = styled<IWorkflowStepHistoryProps, IWorkflowStepHistoryPropsStyles, IWorkflowStepHistoryStyles>(
    WorkflowStepHistoryBase,
    getStyles,
    undefined,
    { scope: 'WorkflowStepHistory'}
);

export default WorkflowStepHistory;