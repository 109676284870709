import { WorkflowLabShort } from "../../models/WorkflowShort";
import { useWorkflowList } from "../features/workflowList";

export const useCurrentWorkflowLab = (): WorkflowLabShort | undefined => {
    const { workflowsLab, selectedWorkflowLab } = useWorkflowList();

    if (!selectedWorkflowLab)
        return;

    return workflowsLab.workflowsLab[selectedWorkflowLab];
}