/*eslint-disable sonarjs/cognitive-complexity*/
import React, { useState } from "react";
import { classNamesFunction, DefaultButton, PrimaryButton, Shimmer, TextField } from "@fluentui/react";
import { IEdiLinkModalProps, IEdiLinkModalPropsStyles, IEdiLinkModalStyles } from "./ediLinkModal.types";
import { useTranslation } from "react-i18next";
import EdiModal from "../../../../../common/components/ediModal/ediModal";
import teamsAuthService from "../../../../../modules/authentication/teamsAuthService";
import { workflowApi } from "../../../../services/workflow.api";
import { useAsyncApi, useOnMount } from "../../../../../utilities/hooks";
import { EdiFolderResponse } from "../../../../services/workflow.contracts";
import { ErrorDetails } from "../../../../../modules/apiClient/apiClient";
import * as microsoftTeams from "@microsoft/teams-js";

const getClassNames = classNamesFunction<IEdiLinkModalPropsStyles, IEdiLinkModalStyles>();

export const EdiLinkModalBase = (props: IEdiLinkModalProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });

    const [urlFolderLink, setUrlFolderLink] = useState<string>('');
    const [error, setError] = useState<string>('');
    const { t } = useTranslation(['ediLinkModal', 'common']);

    const { execute: getEdiFolder, loading: loadingUrl } = useAsyncApi<void, void>(
    {
        func: async () => {
            try {
                const result : EdiFolderResponse = await workflowApi.getUrlEdiFolder(props.workflowId);
                setUrlFolderLink(result.teamsUrl);
            }
            catch (er: any) {//eslint-disable-line @typescript-eslint/no-explicit-any
                const error: ErrorDetails = er as ErrorDetails;
                switch (error.code) {
                    case 403: setError(t('notPermissionsError')); break;
                    case 404: setError(t('notFoundError')); break;
                    default: setError(t('genericError')); break;
                }
            }
        }
    });

    useOnMount(() => {
        setError('');
        getEdiFolder();
    })

    const goToEdiPage = (url: string) => {
        if(!teamsAuthService.currentUserPrincipalName) {
            window.open(url, "_blank");
        }
        else {
            microsoftTeams.executeDeepLink(url);
        }
    }

    const body = (
        loadingUrl ?
            <Shimmer className={classNames.shimmer} />
            : 
            <TextField
                type="text"
                value={urlFolderLink}
                contentEditable={false}
            />
    )

    const footer = (
        <div className={classNames.root}>
            <div className={classNames.divErrorMessage}>
                {error !== '' && <span className={classNames.errorMessage}>{error}</span>}
            </div>
            <div className={classNames.divButtonFooter}>
                <DefaultButton 
                    text={t('common:leave')}
                    onClick={() => { setUrlFolderLink(''); if(props.onClose) props.onClose() }}
                    style={{ margin: '10px 4px' }}
                />
                <PrimaryButton
                    text={t('ediLinkModal:button')}
                    onClick={() => { if(urlFolderLink !== "") goToEdiPage(urlFolderLink) }}
                    style={{ margin: '10px 0px' }}
                    disabled={urlFolderLink === ""}
                />
            </div>
        </div>
    )
    
    return (
        <EdiModal
            isOpen={props.isOpen}
            showCloseIcon={false}
            onCloseClick={() => { setUrlFolderLink(''); if(props.onClose) props.onClose() }}
            title={t('ediLinkModal:title')}
            body={body}
            footer={footer}
            width={500}
        />
    );
}