import { styled } from "@fluentui/react";
import { getStyles } from "./createWorkflowLabModal.styles";
import { ICreateWorkflowLabModalProps, ICreateWorkflowLabModalPropsStyles, ICreateWorkflowLabModalStyles } from "./createWorkflowLabModal.types";
import { CreateWorkflowLabModalBase } from "./createWorkflowLabModal.base";

const CreateWorkflowLabModal = styled<ICreateWorkflowLabModalProps, ICreateWorkflowLabModalPropsStyles, ICreateWorkflowLabModalStyles>(
    CreateWorkflowLabModalBase,
    getStyles,
    undefined,
    { scope: 'CreateWorkflowLabModal' }
);

export default CreateWorkflowLabModal;