import { DefaultEffects, IStyleFunction } from "@fluentui/react"
import { IRequestFiltersPropsStyles, IRequestFiltersStatusStyles } from "./filtersDropdown.types"

export const RequestFiltersGlobalClassNames = {
    root: 'edi-graph-workflow-status-root',
    emptyGraph: 'edi-graph-workflow-status-stack-empty-graph',
    stackLeft: 'edi-graph-workflow-status-stack-left',
    stackRight: 'edi-graph-workflow-status-stack-right',
    doughnutTotal: 'edi-graph-workflow-status-doughnut-total',
    filterContainer: 'edi-graph-workflow-status-filter-container',
    filter: 'edi-graph-workflow-status-filter',
    stackItem: 'edi-dashboard-container-stack-item',
    title: 'edi-dashboard-container-title',
    doughnutTitle: 'edi-graph-workflow-status-doughnut-title',
}

export const getStyles: IStyleFunction<IRequestFiltersPropsStyles, IRequestFiltersStatusStyles> = (props: IRequestFiltersPropsStyles): IRequestFiltersStatusStyles => {
    const { palette } = props.theme!;//eslint-disable-line @typescript-eslint/no-non-null-assertion
    return {
        root: [
            RequestFiltersGlobalClassNames.root,
            {
                // CSS PROPS AND SELECTORS
                height: '82vh',
                overflowY: 'auto',
                overflowX: 'hidden'
            }
        ],
        emptyGraph: [
            RequestFiltersGlobalClassNames.emptyGraph,
            {
                height: '100%',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontWeight: 'bold'
            }
        ],
        stackLeft: [
            RequestFiltersGlobalClassNames.stackLeft,
            {
                width: '25%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative'
            }
        ],
        stackRight: [
            RequestFiltersGlobalClassNames.stackRight,
            {
                width: '25%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative'
            }
        ],
        doughnutTotal: [
            RequestFiltersGlobalClassNames.doughnutTotal,
            {
                position: 'absolute',
                userSelect: 'none',
                fontSize: '18px',
                top: '51%',

            }
        ],
        doughnutTitle: [
            RequestFiltersGlobalClassNames.doughnutTitle,
            {
                display: 'flex',
                alignItems: 'center',
                position: 'absolute',
                userSelect: 'none',
                fontSize: '13px',
                fontStyle: 'italic',
                fontWeight: 'bold',
                alignSelf: "flex-start",
                left: 0,
            }
        ],
        filterContainer: [
            RequestFiltersGlobalClassNames.filterContainer,
            {
                // position: 'absolute',
                // alignItems: 'flex-end',
                // top: 0,
                // left: '13%',
                // display: 'flex',
                // flexDirection: 'row',
                // gap: 5,
                // minWidth: 170
            }
        ],
        filter:[
            RequestFiltersGlobalClassNames.filter,
            {
                ':hover': {
                    backgroundColor: 'none',
                    borderColor: 'none'
                },
                boxShadow: 'unset',
                padding: '2px',
                height: 34,
                minWidth: 160
            }
        ],
        stackItem: [
            RequestFiltersGlobalClassNames.stackItem,
            {
                boxShadow: DefaultEffects.elevation8,
                padding: 15,
                background: palette.white
            }
        ],
        title: [
            RequestFiltersGlobalClassNames.title,
            {
                fontWeight: 'bold',
                fontSize: '15px',
                paddingBottom: '15px',
            }
        ],
    };
}