import { IStyleFunction } from "@fluentui/react"
import { ICreateCategoryModalPropsStyles, ICreateCategoryModalStyles } from "./createCategoryModal.types"

export const CreateCategoryModalGlobalClassNames = {
    root: 'ewe-workflowlab-create-category',
    footerButtons: 'ewe-workflowlab-create-category-footerButtons',
}

export const getStyles: IStyleFunction<ICreateCategoryModalPropsStyles, ICreateCategoryModalStyles> = (props: ICreateCategoryModalPropsStyles): ICreateCategoryModalStyles => {
    return {
        root: [
            CreateCategoryModalGlobalClassNames.root,
            {
                  // CSS PROPS AND SELECTORS
            }
        ]
        ,footerButtons: [
            CreateCategoryModalGlobalClassNames.footerButtons,
            {
                'margin-left': '10px'
            }
        ]
    };
}