import { IDetailsListStyleProps, IDetailsListStyles, IDetailsRowStyleProps, IDetailsRowStyles, ILabelStyles, IStyleFunction } from "@fluentui/react"
import { IWorkflowLabCategoryViewPropsStyles, IWorkflowLabCategoryViewStyles } from "./workflowLabCategoryView.types"

export const WorkflowLabCategoryGlobalClassNames = {
    root: 'ewe-workflowlab-manage-users-root',
    searchBar: 'ewe-workflowlab-manage-users-searchbar',
    categoryList: 'ewe-workflowlab-manage-users-categoryList',
    detailcategoryList: 'ewe-workflowlab-manage-users-detailcategoryList',
    accordionHeader: 'ewe-workflowlab-manage-users-accordionHeader',
    accordionStack: 'ewe-workflowlab-manage-users-accordionStack',
    templatesCountCircle: 'ewe-workflowlab-manage-users-templatesCountCircle',
    remainingIconTagContainer: 'ewe-workflowlab-manage-users-remainingIconTagContainer',
    tagCounter: 'ewe-workflowlab-manage-users-tagCounter',
    accordionListContainer: 'ewe-workflowlab-manage-users-accordionListContainer',
    emptyList: 'ewe-workflowlab-manage-users-emptylist',
    containerEmptyList: 'ewe-workflowlab-manage-users-container-emptylist',
    accordionWrapper: 'ewe-workflowlab-manage-user-category-accordionWrapper'
}

export const getStyles: IStyleFunction<IWorkflowLabCategoryViewPropsStyles, IWorkflowLabCategoryViewStyles> = (props: IWorkflowLabCategoryViewPropsStyles): IWorkflowLabCategoryViewStyles => {
    const { palette, semanticColors } = props.theme!; // eslint-disable-line @typescript-eslint/no-non-null-assertion
    return {
        root: [
            WorkflowLabCategoryGlobalClassNames.root,
            {
                height: '100%',
                padding: '15px 25px',
                position: 'relative',
                selectors: {
                    '::before': {
                        background: `linear-gradient(to right,transparent,${semanticColors.menuDivider})`,
                        content: "''",
                        opacity: '0.2',
                        pointerEvents: 'none',
                        position: 'relative',
                        right: 0,
                        width: '0.8rem',
                        height: '100%',
                        zIndex: '2'
                    }
                }
            }
        ],
        load: {
            display: 'none'
        }
        ,detailListContainer: [
            WorkflowLabCategoryGlobalClassNames.accordionListContainer,
            {
                height: 'calc(100vh - 257px)',
                paddingRight: '20px',
                marginTop: '15px',
                marginBottom: '15px',
                selectors:{
                    ' .edi-accordion-root': {
                        position: 'relative'
                    },
                    ' .edi-accordion-header': {
                        position: 'relative'
                    },
                }
            }]
        ,searchBar: [
            WorkflowLabCategoryGlobalClassNames.searchBar,
            {
                minWidth: 450,
                paddingLeft: 8,
                iconContainer: {
                    display: 'none'
                }
            }
        ]
        ,categoryList: [
            WorkflowLabCategoryGlobalClassNames.categoryList,
            {
                display:'flex',
                flexDirection:'horizontal',
                selectors: {
                    ' .icontag-container': {
                        marginLeft: '10px',
                        //width: '200px'
                    },
                    ' .edi-icon-tag-root':{
                        padding: '0 13px'
                    }
                }
            }
        ]
        ,detailcategoryList: [
            WorkflowLabCategoryGlobalClassNames.detailcategoryList,
            {
                selectors: {
                    ' .ms-DetailsHeader': {
                        paddingTop: '0px !important'
                    },
                }
            }
        ]
        ,accordionHeader: [
            WorkflowLabCategoryGlobalClassNames.accordionHeader,
            {
                height: 'none'
            }
        ]
        ,accordionStack: [
            WorkflowLabCategoryGlobalClassNames.accordionStack,
            {
                // selectors:{
                //     ' .ms-StackItem':{paddingLeft: '30px'}
                // }
            }
        ],
        accordionWrapper: [ WorkflowLabCategoryGlobalClassNames.accordionWrapper,
        {
            '& [class="edi-accordion-title"]':{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            width: '80%',
            }
        }]
        ,tagCounter: [
            WorkflowLabCategoryGlobalClassNames.tagCounter,
            {
                marginLeft:'10px !important'
            }
        ]
        ,remainingIconTagContainer: {
            padding: '0 5px',
            width: '100%',
            textAlign: 'left',
            justifyContent: 'start',
            color: palette.themePrimary
        },
        iconTagCategory: {
            width: 100,
            overflow: 'hidden'
          },
        subComponentStyles: {
            detailsList: (props: IDetailsListStyleProps): IDetailsListStyles => {
                return {
                    root: {
                        '& [role="grid"]': {
                            display: 'flex',
                            flexDirection: 'column',
                        }
                    },
                    headerWrapper: {
                        flex: '0 0 auto',
                    },
                    contentWrapper: {
                        flexGrow: '1',
                        flexShrink: '1',
                        flexBasis: 'calc(100vh - 315px)',
                        overflowY: 'overlay',
                        overflowX: 'hidden',
                        '@media(max-width: 730px)': { // 798 - 68
                            overflowX: 'auto' // show horizontal scrollbar when list is not shrinkable anymore
                        }
                    },
                    focusZone: {},
                }
            },
            templatesCountCircle: (): ILabelStyles => {
                return {
                    root: {
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '50%',
                        width: '30px',
                        height: '30px',
                        fontSize: '12px',
                        backgroundColor: semanticColors.variantBorder,
                        color: palette.themePrimary,            
                    }
                }
            },
            list: (): IDetailsListStyles => {
                return {
                    root: {
                        selectors: {
                            '& [role=grid]': {
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'start',
                                maxHeight: '65vh'
                            }
                        }
                    },
                    headerWrapper: {
                        flex: '0 0 auto',
                        '> .ms-DetailsHeader': {
                            paddingTop: 0,
                            borderBottomColor: 'transparent'
                        }
                    },
                    contentWrapper: {
                        flex: '1 1 auto',
                        overflowY: 'overlay',
                        overflowX: 'hidden',
                        width: '100%'
                    },
                    focusZone: {}
                }
            },
            row: (props: IDetailsRowStyleProps): IDetailsRowStyles => {
                return {
                    root: {
                        backgroundColor: palette.white,
                        ':hover': {
                            backgroundColor: palette.white
                        }
                    },
                    cell: {},
                    cellAnimation: {},
                    cellPadded: {},
                    cellMeasurer: {},
                    cellUnpadded: {},
                    check: {},
                    checkCell: {},
                    checkCover: {},
                    fields: {},
                    isMultiline: {},
                    isRowHeader: {},
                }
            }           
        },
        emptylist: [WorkflowLabCategoryGlobalClassNames.emptyList, {
        }],
        containerEmptyList: [WorkflowLabCategoryGlobalClassNames.containerEmptyList, {
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }]
    };
}