import { AxiosRequestConfig, CancelToken } from "axios";
import { User } from "../../models/User";
import { WorkFlow } from "../../models/WorkFlow";
import { WorkflowLabShort, WorkflowShort } from "../../models/WorkflowShort";
import { apiClient } from "../../modules/apiClient/apiClient";
import { svcPaths } from "../../modules/apiClient/config";
import QueryString from "qs";
import { GetWorkFlowLabAvailableApproversRequest, GetWorkFlowsRequest, PreviewResponse, GetWorkFlowsDashboardRequest, GetUsersRequest, CreateWorkflowLabRequest, GetAvaiableApproversAndLabelsRequest, AvailableApproversAndLabels, CreateWorkflowRequest, EdiFolderResponse } from "./workflow.contracts";
import qs from "qs";
import { FileShort, ListFile } from "../../models/fileShort";
import { WorkflowStatusGraphResponse } from "../../models/WorkflowDashboard";
import { inputFileMetaData } from "../root/workflowOverview/copyOnEdiModal/CopyFileOnEdiModal.base";


class WorkflowApiClass {
    async preview(fileId: number) {
        const response = await apiClient.get<PreviewResponse>(`${svcPaths.workflow}/files/${fileId}/preview`);
        return response.data
    }    
    
    async getWorkflowLabList() {
        const response = await apiClient.get<WorkflowLabShort[]>(`${svcPaths.workflow}/workflowLab`) 
        return response.data;
    }
    
    async getWorkflows(request: GetWorkFlowsRequest){
        const response = await apiClient.get<WorkflowShort[]>(`${svcPaths.workflow}/workflow`, {
            params: {
                pageNumber: request.pageNumber,
                pageSize: request.pageSize,
                orderBy: request.orderBy,
                isAscending: request.isAscending,
                workFlowLabId: request.workflowLabId,
                isCreator: request.isCreator,
                searchBy: request.searchBy,
                status: request.status,
                creationDateFrom: request.creationDateFrom,
                creationDateTo: request.creationDateTo
            },
            paramsSerializer: (params) => QueryString.stringify(params, { arrayFormat: 'repeat' }),
        })
        return response.data;
    }

    async getWorkflowById(id: number) {
        const response = await apiClient.get<WorkFlow>(`${svcPaths.workflow}/workflow/${id}`);
        return response.data;
    }

    async deleteWorkflow(id: number) {
        const response = await apiClient.delete(`${svcPaths.workflow}/workflow/${id}`);
        return response.data;
    }

    async getWorkFlowLabAvailableApprovers(request: GetWorkFlowLabAvailableApproversRequest, cancelToken?: CancelToken) {
        const response = await apiClient.get<User[]>(`${svcPaths.workflow}/workflowLab/${request.id}/available-approvers`, {
            params: {
                keyword: request.keyword,
                pageNumber: request.pageNumber,
                pageSize: request.pageSize,
                excludeCurrentUser: request.excludeCurrentUser,
                userIds: request.userIds
            },
            paramsSerializer: (params) => QueryString.stringify(params, { arrayFormat: 'repeat' }),
            cancelToken: cancelToken
        });
        return response.data;
    }

    async getAvaiableApproversAndLabels(request: GetAvaiableApproversAndLabelsRequest, cancelToken?: CancelToken) {
        const response = await apiClient.get<AvailableApproversAndLabels[]>(`${svcPaths.workflow}/workflowLab/${request.workflowLabId}/getavaiableusers`,{
            params: {
                searchBy: request.searchBy,
                searchType: request.searchType,
                excludeCurrentUser: request.excludeCurrentUser,
                excludeUsers: request.excludeUsers,
                excludeLabels: request.excludeLabels
            },
            paramsSerializer: (params) => QueryString.stringify(params, { arrayFormat: 'repeat' }),
            cancelToken: cancelToken
        });
        return response.data;
    }

    async getPagedListFromSPO(teamId?: string, folderId?: string, pageSize?: number, skipToken?: string) {
        const response = await apiClient.get<ListFile>(`${svcPaths.workflow}/workflow/filesteam?teamId=${teamId}&folderId=${folderId}&pageSize=${pageSize}&skipToken=${skipToken}`);
        return response.data;
    }

    async createWorkflow(request: CreateWorkflowRequest, file: File){
        const formdata: FormData = new FormData();
        formdata.append('File', file);
        formdata.append('jsonCommand', JSON.stringify(request));

        const response = await apiClient.post(`${svcPaths.workflow}/workflow/create-workflow`, formdata);
        return response.data; 
    }

    async getWorkflowDashboard(request: GetWorkFlowsDashboardRequest) {
        const response = await apiClient.get<WorkflowStatusGraphResponse>(`${svcPaths.workflow}/workflow/GetWorkFlowDashBoard`, {
            params: {
                workFlowLabId: request.workflowLabId,
                searchBy: request.searchBy,
                dateFrom: request.dateFrom,
                dateTo: request.dateTo,
            }
        });
        return response.data;        
    }

    async getEdiFiles(folderId?: string, pageSize?: number, pageNumber?: number){
        const response = await apiClient.get<FileShort[]>(`${svcPaths.workflow}/workflow/getedifoldercontent?folderId=${folderId}&pageSize=${pageSize}&pageNumber=${pageNumber}`)
        return response.data;
    }

    async getUsers(request: GetUsersRequest): Promise<User[]> {
        const response = await apiClient.get<User[]>(`${svcPaths.workflow}/users/getusersbykeyphrase`, {
            params: {
                keyword: request.keyword,
                pageNumber: request.pageNumber,
                pageSize: request.pageSize,
                excludeCurrentUser: true,
                userIds: request.userIds
            },
            paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
        });
        return response.data;
    }

    async createWorkflowLab(newWorkflowLab: CreateWorkflowLabRequest) {
        const payload = {
            name: newWorkflowLab.name,
            members: newWorkflowLab.members 
        };
        const response = await apiClient.post(`${svcPaths.workflow}/workflowlab/create`, payload);
        return response.data;
    }

    async isWorkflowLabNameAvailable(name: string) {
        const payload = { name: name };
        const response = await apiClient.post<boolean>(`${svcPaths.workflow}/workflowlab/check-name`, payload);
        return response.data;
    }

    async sendWorkflowReminder(workflowId: number | undefined)
    {
        const payload = {workflowId: workflowId}
        const response = await apiClient.post(`${svcPaths.workflow}/workflow/sendWorkflowReminder`,payload);
        return response.data
    }

    async getWorkflowFilePreviewUrl(id: number) {
        const response = await apiClient.get(`${svcPaths.workflow}/workflow/${id}/previewWorkflow`);
        return response.data;
    }

    async uploadToEdi(id: number, folderId:number, metadata: string | null) {
        const payload = { ediFolderId: folderId, metadata: metadata};        
        const response = await apiClient.post(`${svcPaths.workflow}/workflow/${id}/uploadToEdi`, payload);
        return response.data;
    }

    async uploadDocsToEdi(id: number, docs: inputFileMetaData[], folderId:number) {
        const payload = { docs: docs, ediFolderId: folderId};        
        const response = await apiClient.post(`${svcPaths.workflow}/workflow/${id}/uploadDocumentsToEdi`, payload);
        return response.data;
    }
    
    async getUrlEdiFolder(id: number) {
        const response = await apiClient.get<EdiFolderResponse>(`${svcPaths.workflow}/workflow/${id}/GetEdiFolder`);
        return response.data;
    }

    async exportHistoryData(id: number | undefined, type: string, languageId: string | undefined  ,filename: string, onDownloadProgress?: (progress: number) => void) {
        const config: AxiosRequestConfig = {
            responseType: 'arraybuffer',
        }
        const response = await apiClient.get(`${svcPaths.workflow}/workflow/${id}/exportReport?languageId=${languageId}`, config);
        const url = window.URL.createObjectURL(new Blob([response.data], { type: type }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
    }

    async exportWorkflowMonitoringData(id: number | undefined, type: string, languageId: string | undefined  ,filename: string, onDownloadProgress?: (progress: number) => void) {
        const config: AxiosRequestConfig = { responseType: 'arraybuffer' }
        const response = await apiClient.get(`${svcPaths.workflow}/workflow/${id}/exportMonitoringReport?languageId=${languageId}`, config);
        const url = window.URL.createObjectURL(new Blob([response.data], { type: type }));

        const link = document.createElement('a');
        link.href = url;
        
        const formattedDateTime = new Date().toLocaleString('it-IT', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
        });

        filename = 'Monitoring workflow ' + filename + ' report_' + formattedDateTime + '.xlsx';
        link.setAttribute('download', filename);
        
        document.body.appendChild(link);
        link.click();
    }
}

export const workflowApi = new WorkflowApiClass();