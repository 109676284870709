import { FontSizes, FontWeights, IDetailsHeaderStyleProps, IDetailsHeaderStyles, IDetailsListStyleProps, IDetailsListStyles, IDetailsRowStyleProps, IDetailsRowStyles, IStyleFunction } from "@fluentui/react";
import { IWorkflowDetailsPropsStyles, IWorkflowDetailsStyles } from "./workflowDetails.types";

export const WorkflowDetailsGlobalClassNames = {
    root: 'edi-workflow-details-root',
    extended: 'edi-workflow-details-maincontent-extended'
}

export const getStyles: IStyleFunction<IWorkflowDetailsPropsStyles, IWorkflowDetailsStyles> = (props: IWorkflowDetailsPropsStyles): IWorkflowDetailsStyles => {
    const { palette } = props.theme!; // eslint-disable-line @typescript-eslint/no-non-null-assertion
    return {
        root: [
            WorkflowDetailsGlobalClassNames.root,
            {
                height: '100%',
                padding: '15px 25px',
            }
        ],
        mainContent: {
            position: 'relative',
            height: '100%',
            maxHeight: 'inherit',
        },
        extended: {
            height: 'calc(100% - 270px)',
        },
        title: {
            fontSize: FontSizes.size18,
            fontWeight: FontWeights.bold,
            paddingBottom: 10
        },
        description: {
            fontSize: FontSizes.size16,
            fontWeight: FontWeights.regular,
            paddingBottom: 10,
            textOverflow:'Ellipsis',
            overflow:'hidden',
            width:'75%'
        },
        accordionContent: {
            height: '100%',
            padding: '0px 15px 17px 17px',
        },
        documentUrl: {
            textDecoration: 'underline',
            color: palette.themePrimary,
            fontWeight: 'bold',
            cursor: 'pointer',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap'
        },
        documentBold: {
            textDecoration: 'underline',
            // fontWeight: 'bold',
        },
        titleContainer: {
            height: '60px',
            overflow: 'auto',
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
            justifyContent: 'space-between'
        },
        titleWrapper: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
        },
        closeBtn: {
            display: 'flex',
            justifyContent: 'flex-end',
            marginRight: '17px'
        },
        step: {
            paddingBottom: '0px',
            paddingTop: '0px !important',
            height: '100%'
        },
        emptyList: {
            position: 'absolute',
            top: '60px',
            width: '150px',
            left: 'calc(50% - 88px)'
        },
        tooltip: {
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            cursor: 'context-menu'
        },
        tooltipContent: {
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            cursor: 'pointer',
        },
        commentContent: {
            fontStyle: 'italic', 
            display: 'block', 
            whiteSpace: 'pre-wrap'
        },
        subComponentStyles: {
            accordion: {
                header: {
                    paddingLeft: '0px',
                    display:'flex',
                    alignItems:'center'
                },
                title:{
                    fontWeight : FontWeights.bold,
                    display:'flex',
                    alignItems:'center',
                    paddingRight: '80%'
                }
            },
            detailsList: (props: IDetailsListStyleProps): IDetailsListStyles => {
                return {
                    root: {
                    },
                    headerWrapper: {
                    },
                    contentWrapper: {
                    },
                    focusZone: {}
                }
            },
            detailsHeader: (props: IDetailsHeaderStyleProps): IDetailsHeaderStyles => {
                return {
                    root: {
                        borderBottom: '0px'
                    },
                    cellIsCheck: {},
                    check: {},
                    cellWrapperPadded: {},
                    cellIsActionable: {},
                    cellIsEmpty: {},
                    cellSizer: {},
                    cellSizerStart: {},
                    cellSizerEnd: {},
                    cellIsResizing: {},
                    cellIsGroupExpander: {},
                    collapseButton: {},
                    checkTooltip: {},
                    sizingOverlay: {},
                    dropHintCircleStyle: {},
                    dropHintCaretStyle: {},
                    dropHintLineStyle: {},
                    dropHintStyle: {},
                    accessibleLabel: {},
                }
            },
            detailsRow: (props: IDetailsRowStyleProps): IDetailsRowStyles => {
                return {
                    root: {
                        borderBottom: '0px',
                        selectors: {
                            ':hover': {
                                backgroundColor: 'unset'
                            }
                        },
                    },
                    cell: { alignSelf: 'center', textOverflow: 'unset', paddingBottom: '0px', paddingTop: '0px !important', height: '100%' },
                    cellAnimation: {},
                    cellPadded: {},
                    cellMeasurer: {},
                    cellUnpadded: {},
                    check: {},
                    checkCell: {},
                    checkCover: {},
                    fields: {},
                    isMultiline: {},
                    isRowHeader: {},
                }
            },
        }
    };
}