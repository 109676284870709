import { styled } from "@fluentui/react";
import { getStyles } from "./createWorkflow.styles";
import { ICreateWorkflowPropsStyles, ICreateWorkflowStyles, ICreateWorkflowProps } from "./createWorkflow.types";
import { CreateWorkflowBase } from "./createWorkflow.base";

const CreateWorkflow = styled<ICreateWorkflowProps, ICreateWorkflowPropsStyles, ICreateWorkflowStyles>(
    CreateWorkflowBase,
    getStyles,
    undefined,
    { scope: 'CreateWorkflow' }
);

export default CreateWorkflow;